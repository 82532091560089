import api, { responseErrorCheck } from './api'
import {AppSettings} from "./types";
import { AxiosRequestConfig } from 'axios';

//TenantSettings
class ApplicationSetting {
  protected endpoint = 'profilemanager/v1/applicationSettings/map'

  async getApplicationSettings(axiosConfig?: AxiosRequestConfig) {
    const res = await api.get<AppSettings>(`${this.endpoint}`, {}, axiosConfig)
    return responseErrorCheck(res)
  }
}

const applicationSetting = new ApplicationSetting()
export { applicationSetting as default }
