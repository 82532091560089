import React from 'react';
import { Col, Modal as AntDModal, Row } from 'antd';
import OrderLogs from '../../api/OrderLogs';
import { __, T } from '../../config/i18n';
import { OrderLog } from '../../api/types';
import moment from 'moment/moment';
import DOMPurify from 'dompurify';

interface Props {
  orderId?: string
  onClose: () => void
}

interface State {
  orderLogs: OrderLog[]
}

export default class OrderLogsModal extends React.Component<Props, State> {
  state: State = {
    orderLogs: []
  }

  componentDidMount = async () => {
  }

  componentDidUpdate = async (prevProps: Props) => {
    if (this.props.orderId && this.props.orderId !== prevProps.orderId) {
      await this.loadOrderLogs(this.props.orderId)
    }
  }

  loadOrderLogs = async (orderId: string) => {
    const orderLogs: OrderLog[] = (await OrderLogs.getByOrderId(orderId)).content
    this.setState({orderLogs})
  }

  getIconDiv(color: string) {
    return <div style={{borderRadius:10, width:20, height: 20, backgroundColor: color}}></div>
  }

  getIconColumn(log: OrderLog) {
    switch (log.type) {
      case 'APPROVE':
        return this.getIconDiv(__(T.colors.approved))
      case 'ASSIGNED':
      case 'ASSIGN_CHILD':
        return this.getIconDiv(__(T.colors.assigned))
      case 'CANCEL':
        return this.getIconDiv(__(T.colors.cancelled))
      case 'COMPLETE':
        return this.getIconDiv(__(T.colors.completed))
      case 'DRAFT':
      case 'UNLOCK':
        return this.getIconDiv(__(T.colors.draft))
      case 'FULFILL':
        return this.getIconDiv(__(T.colors.fulfilled))
      case 'REOPEN':
      case 'DISAPPROVE':
      case 'REJECT':
        return <img style={{height: 20}} src={require('assets/images/undo.svg').default} alt={''}/>
      case 'IMPORT':
        return <img style={{height: 20}} src={require('assets/images/import.svg').default} alt={''}/>
      case 'SPLIT_NEW_ROW':
      case 'SPLIT_UPDATE':
        return <img style={{height: 20}} src={require('assets/images/Split.svg').default} alt={''}/>
    }
  }

  sanitizeObject(obj) {
    const cleanObj = {};
    for (let key in obj) {
      if (obj.hasOwnProperty(key)) {
        const value = obj[key];
        if (typeof value === 'object' && value !== null) {
          cleanObj[key] = this.sanitizeObject(value); // ricorsivamente sanifica gli oggetti nidificati
        } else if (typeof value === 'string') {
          cleanObj[key] = DOMPurify.sanitize(value); // sanifica le stringhe
        } else {
          cleanObj[key] = value; // lascia invariati gli altri tipi di dato
        }
      }
    }
    return cleanObj;
  }

  render = () => {
    const { onClose } = this.props
    const { orderLogs } = this.state
    return (
      <AntDModal visible={this.props.orderId !== undefined} footer={null} onCancel={onClose}  centered width={600}>
        <div style={{fontSize: 16, fontWeight: 'bold', marginBottom: 40}}>
          <img
               style={{marginRight: 10}}
                 src={require('assets/images/history.svg').default}
               alt={''}
          />
          {__(T.fields.history)}
        </div>
        <div style={{width: '100%', overflow: 'auto', overflowX: 'hidden', maxHeight: 500}}>
          <Row style={{fontSize: 12, fontWeight: "bold", paddingBottom: 20, paddingTop: 20}}>
            <Col span={6}>
              Date time
            </Col>
            <Col span={2}>
              Type
            </Col>
            <Col span={16}>
              Description
            </Col>
          </Row>
          {
            orderLogs.map(log =>
                <Row style={{fontSize: 12, borderBottom: '1px solid #D9D9D9', paddingBottom: 20, paddingTop: 20}} key={'key_' + orderLogs.indexOf(log)}>
                  <Col span={6}>
                    {moment(log.creationDate).format('DD/MM/YYYY HH:mm:ss')}
                  </Col>
                  <Col span={2}>
                    {this.getIconColumn(log)}
                  </Col>
                  <Col span={16} dangerouslySetInnerHTML={{__html:__('orderLog.' + log.type,  this.sanitizeObject({...log.data, user: log.user}))}}>
                  </Col>
                </Row>
            )}
        </div>

      </AntDModal>
    )
  }
}

