import React from 'react';
import AppStore from '../../../AppStore';
import TinyProgressStyleColCard from '../cards/TinyProgressStyleColCard';
import statisticApiTCare from '../../../api/StatisticApiTCare';
import { StatisticData } from '../../../api/types';
import Companies from '../../../api/Companies';
import {OrderStatusUtil} from "../ant-d-modules/tools/OrderStatusUtil";
import {isAdmin} from "../../../helpers/permission";
import { setOffGlobalSpinningOnHeader } from '../../../api/api';


interface Props {
    startYearMonthFilter: string | null;
    endYearMonthFilter: string | null;
}

interface State {
    values: any,
    isLoading: boolean,
}


export default class StatusOfTheReferencesForSuppliers extends React.Component<Props, State> {
    state: State = {
        values: [],
        isLoading: true
    };

    async componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any) {
        if (prevProps.startYearMonthFilter !== this.props.startYearMonthFilter ||
            prevProps.endYearMonthFilter !== this.props.endYearMonthFilter) {
            await this.refreshDataFromStatistic();
        }
    }

    async componentDidMount() {
        await this.refreshDataFromStatistic();
    }

    async refreshDataFromStatistic() {
        let url = 'status_order?groupBy=status&groupBy=creationCompany&';

        url += OrderStatusUtil.getCreationOrAssigneeCompanyFilter(isAdmin(), AppStore.loggedUser?.company?.id);
        url += statisticApiTCare.getDataRangeFilter(this.props.startYearMonthFilter, this.props.endYearMonthFilter);
        let result = await statisticApiTCare.getStatisticDataByUrl(url, setOffGlobalSpinningOnHeader());
        let elementsToShow: [{
            label: string,
            value: string
        }?] = []

        elementsToShow = await this.elaborateResult(result);


        this.setState({
            isLoading: false,
            values: elementsToShow
        });
    }


    getCompletedComputation(completed: number, rejected: number, disapproved: number): number {
        return completed - rejected - disapproved;
    }

    async elaborateResult(statisticData: any): Promise<any> {
        let idCompanyToValues = new Map<string, {
            completed: number
            rejected: number
            assigned: number
            disapproved: number
        }>();


        statisticData.forEach((element: StatisticData) => {

            if (!!element['key']) {
                let companyId = element['key'].creationCompany;
                if (!idCompanyToValues.get(companyId)) {
                    idCompanyToValues.set(companyId, {
                        completed: 0,
                        rejected: 0,
                        assigned: 0,
                        disapproved: 0
                    });
                }

                //mi salvo i valori prima del override
                let completed = idCompanyToValues.get(companyId)!.completed;
                let rejected = idCompanyToValues.get(companyId)!.rejected;
                let assigned = idCompanyToValues.get(companyId)!.assigned;
                let disapproved = idCompanyToValues.get(companyId)!.disapproved;


                if (element['key'].status === 'COMPLETED') {
                    completed += element['value'];
                }
                if (element['key'].status === 'REJECTED') {
                    rejected += element['value'];
                }
                if (element['key'].status === 'ASSIGNED') {
                  assigned += element['value'];
                }
                if (element['key'].status === 'DISAPPROVED') {
                    disapproved += element['value'];
                }
                idCompanyToValues.set(companyId, {completed, rejected, assigned, disapproved});

            }
        });
        let labelValueList: [{
            label: string,
            labelValue?: string,
            value: number
        }?] = [];


        for (let key of Array.from(idCompanyToValues.keys())) {
            let completed = this.getCompletedComputation(idCompanyToValues.get(key)!.completed,
              idCompanyToValues.get(key)!.rejected,
              idCompanyToValues.get(key)!.disapproved);
            let percentage = (completed / idCompanyToValues.get(key)!.assigned) * 100;
            const company = await Companies.get(key, setOffGlobalSpinningOnHeader());
            if (!!company && !!company.data && !!company.data.businessName) {
                labelValueList.push({
                    label: company.data.businessName,
                    value: parseInt(percentage + ''),
                    labelValue: completed + '/' + idCompanyToValues.get(key)!.assigned}
                    );
            }
        }


        return labelValueList;
    }

    render() {

        return (
            <TinyProgressStyleColCard
                                      labels={this.state.values}
                                      title={'Status of references completed for suppliers'}
                                      isLoading={this.state.isLoading} />
        );
    }


}






