import React, { Component } from 'react';
import { Layout, View } from 'components';
import { Schema } from 'components/AdvancedForm';
import { __, T } from 'config/i18n';
import { Avatar, Button, Empty } from 'antd';
import { getOperationsForRole } from 'helpers/permission';
import { OnBoardings } from 'api';
import { PaginationHelper } from 'PaginationHelper';
import { NotificationTCare, Page } from 'api/types';
import { OperationPermission } from '../../types';
import errorPrint from '../../tools/ErrorPrint';
import Title from 'antd/es/typography/Title';
import { BellOutlined } from '@ant-design/icons';
import Notifications from '../../api/Notifications';
import NoticeCard from '../../components/notifications/NoticeCard';
import AppStore from '../../AppStore';


interface Props {
}

interface State {
  onlyUnread: boolean;
  todayNotificationList: NotificationTCare[];
  weekNotificationList: NotificationTCare[];
  previusNotificationList: NotificationTCare[];
  page: number;

  // filteredData: NotificationTCare[],
  activeTabKey: '1' | '2';
  paginationHelper: PaginationHelper;
  filters: any;
}


export default class NotificationsReport extends Component<Props, State> {
  state: State = {
    onlyUnread: false,
    todayNotificationList: [],
    weekNotificationList: [],
    previusNotificationList: [],
    page: 0,
    // filteredData: [],
    activeTabKey: '1',
    paginationHelper: new PaginationHelper(10),
    filters: {}
  };


  async componentDidMount() {
    await this.loadNotifications(0);
    AppStore.eventEmitter.subscribe("onReadMessage",  'NotificationsReport', () => {this.loadNotifications(0)})
  }

  componentWillUnmount() {
    AppStore.eventEmitter.unSubscribe("onReadMessage", 'NotificationsReport')
  }

  private async loadNotifications(page: number) {
    let res: Page<NotificationTCare> | undefined = await Notifications.find({
      size: 10,
      page: page,
      onlyUnread: this.state.onlyUnread
    });
    this.setState({ ...this.populateNotificationLists(res!.content, page === 0), page });
  }

  async loadMore() {
    await this.loadNotifications(this.state.page + 1);
  }


  onTabChangeFilter = async (activeKey: string) => {
    if (activeKey === '1') {
      await this.applyFilters(this.state.filters, 1, '1');
    } else if (activeKey === '2') {
      await this.applyFilters(this.state.filters, 1, '2');
    }
  };

  async applyFilters(filters: {} = {}, page?: number, tab?: '1' | '2') {
    this.state.paginationHelper.setCurrentPage(page ?? 1);
    let params = {};
    if (filters['companyCode'] && filters['companyCode'] !== '') {
      params['companyCode'] = filters['companyCode'];
    }
    if (filters['user'] && filters['user'] !== '') {
      params['referentFullText'] = filters['user'];
    }
    if (filters['supplier'] && filters['supplier'] !== '') {
      params['companyId'] = JSON.parse(filters['supplier'].value).id;
    }
    if (filters['category'] && filters['category'] !== '') {
      if (this.isSentTab(tab ?? this.state.activeTabKey, getOperationsForRole().onboardingReportToConfirm)) {
        params['onboardingCategory'] = filters['category'];
      } else {
        params['category'] = filters['category'];
      }
    }
    const updatedTab = tab ?? this.state.activeTabKey;
    try {
      const onboardingPage = await OnBoardings.search({
        ...this.state.paginationHelper.getPaginationApiParams(),

        ...params,
        sort: 'creationDate,desc'
      });
      this.state.paginationHelper.setTotalElements(onboardingPage?.totalElements ?? 0);

      this.setState({
        //filteredData: onboardingPage?.content ?? [],
        activeTabKey: updatedTab,
        filters
      });
    } catch (error) {
      errorPrint.printMessage(error);
      this.setState({
        //filteredData: [],
        activeTabKey: updatedTab,
        filters
      });
    }
  }


  isSentTab(tab: '1' | '2', isAdminTenant: boolean) {
    if (isAdminTenant) {
      return tab === '2';
    }
    return tab === '1';
  }

  createFilter = (permission: OperationPermission): Schema[] => {

    return [
      {
        name: 'searchText',
        type: 'text',
        label: __(T.fields.notification_filter),
        placeholder: __(T.misc.write),
        col: { xs: 24, sm: { span: 8 } }
      }
    ];
  };

  async setOnlyRead(value: boolean) {
    this.state.todayNotificationList= []
    this.state.weekNotificationList= []
    this.state.previusNotificationList = []
    this.state.onlyUnread = value
    await this.loadNotifications(0);

  }

  async setAllAsRead() {
    await Notifications.markAllAsRead();
    this.state.todayNotificationList= []
    this.state.weekNotificationList= []
    this.state.previusNotificationList = []
    await this.loadNotifications(0);
  }

  render() {
    return (
      <Layout>
        <View
          hidden={this.state.todayNotificationList.length === 0 && this.state.weekNotificationList.length === 0 && this.state.previusNotificationList.length === 0}
          style={{ padding: 20 }}>
          <View style={{ flex: 1, display: 'flex', justifyContent: 'flex-start', marginBottom: 12 }}>
            <Avatar style={{ color: '#f56a00', marginLeft: 25, marginRight: 5 }} icon={<BellOutlined />} />
            <Title level={4}>{__(T.titles.notificationTitle)}</Title>
          </View>
          {/*<View style={{ flex: 1, display: 'flex', justifyContent: 'flex-start', marginBottom: 12 }}>*/}
          {/*  <span>This section wil allow the user to check all the notifications containing the next action to be performed in t!Care. <br></br>*/}
          {/*            This text must be customaizable by client, but we have to set a default text. <br></br>*/}
          {/*            This is the max lenght.*/}
          {/*  </span>*/}
          {/*</View>*/}
          <div style={{ flex: 1, display: 'flex', justifyContent: 'flex-end', marginBottom: 12 }}>
            <Button type="primary" onClick={() => {
              this.setAllAsRead();
            }}
                    style={{ fontWeight: 'bold' }}> {__(T.buttons.mark_all_as_read)}</Button>
          </div>

          <div style={{ flex: 1, display: 'flex', justifyContent: 'flex-start', marginBottom: 12 }}>
            <Button type="link" onClick={() => {
              this.setOnlyRead(false);
            }} style={{ fontWeight: this.state.onlyUnread ? 'normal' : 'bold' }}>{__(T.buttons.all)}</Button>
            <Button type="link" onClick={() => {
              this.setOnlyRead(true);
            }} style={{ fontWeight: this.state.onlyUnread ? 'bold' : 'normal' }}>{__(T.buttons.unread)}</Button>
          </div>

          <View className="page-table">

            {this.state.todayNotificationList.length > 0 && (
              <>
                <Title level={4}>{__(T.misc.today)}</Title>
                {this.state.todayNotificationList.map((n) => <NoticeCard notificationTCare={n}
                                                                         markAsRead={this.markAsRead} />)}
              </>
            )}
            {this.state.weekNotificationList.length > 0 && (
              <>
                <Title level={4} style={{ marginTop: 20 }}>{__(T.misc.thisWeek)}</Title>
                {this.state.weekNotificationList.map((n) => <NoticeCard notificationTCare={n}
                                                                        markAsRead={this.markAsRead} />)}
              </>
            )}
            {this.state.previusNotificationList.length > 0 && (
              <>
                <Title level={4} style={{ marginTop: 20 }}>{__(T.misc.previous)}</Title>
                {this.state.previusNotificationList.map((n) => <NoticeCard notificationTCare={n}
                                                                           markAsRead={this.markAsRead} />)}
              </>
            )}


            <Button
              hidden={this.state.todayNotificationList.length === 0 && this.state.weekNotificationList.length === 0 && this.state.previusNotificationList.length === 0}
              style={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                marginBottom: 12,
                fontWeight: 'bold'
              }} type="link" onClick={() => {
              this.loadMore();
            }}>
              load more
            </Button>
          </View>
        </View>
        <View
          hidden={this.state.todayNotificationList.length !== 0 || this.state.weekNotificationList.length !== 0 || this.state.previusNotificationList.length !== 0}
          style={{ padding: 20 }}>
          <Empty />
        </View>
      </Layout>
    );
  }

  markAsRead = async (notification: NotificationTCare) => {
    notification.read ? await Notifications.markAsUnread(notification) : await Notifications.markAsRead(notification);
    this.state.todayNotificationList = [];
    this.state.weekNotificationList = [];
    this.state.previusNotificationList = [];
    let page = this.state.page;
    for (let i = 0; i <= page; i++) {
      await this.loadNotifications(i);
    }

  };

  private populateNotificationLists(notificationList: NotificationTCare[], isToReset: boolean) {
    let todayNotificationList: NotificationTCare[] = this.state.todayNotificationList;
    let weekNotificationList: NotificationTCare[] = this.state.weekNotificationList;
    let previusNotificationList: NotificationTCare[] = this.state.previusNotificationList;
    if (isToReset) {
      todayNotificationList = [];
      weekNotificationList = [];
      previusNotificationList = [];

    }
    notificationList.forEach(n => {
      if (n.creationTimeAgo < 86400000) {
        todayNotificationList.push(n);
      } else if (n.creationTimeAgo < 86400000 * 7) {
        weekNotificationList.push(n);
      } else {
        previusNotificationList.push(n);
      }
    });

    return { todayNotificationList, weekNotificationList, previusNotificationList };

  }

}
