import {Order, OrderStatus} from '../api/types';

export class OrderRejectsTools {

  getAllReasonsByStateByStatuses(order: Order, statuses: OrderStatus[]){
    if (order.childDataList) {
      return order.childDataList
          .filter(child => !!child.reject)
          .filter(child => statuses.indexOf(child.status) >= 0)
          .filter(child => !!child.reject.reasons)
          .flatMap(child => child.reject.reasons)
    } else {
      return [];
    }
  }

  public getAllOpenReasons(order: Order): string[] {
    return this.getAllReasonsByStateByStatuses(order, ['TO_BE_ASSIGNED', 'ASSIGNED', 'DRAFT'])
  }

  public getAllClosedReasons(order: Order): string[] {
    return this.getAllReasonsByStateByStatuses(order, ['COMPLETED', 'APPROVED', 'CANCELLED'])
  }
}


const orderRejectsTools = new OrderRejectsTools();
export { orderRejectsTools as default };
