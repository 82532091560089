import React, {RefObject} from 'react'
import {UserFields} from '../../types'
import {__, T} from '../../config/i18n'
import {AdvancedForm} from '../../components'
import {ButtonProps, Schema} from '../../components/AdvancedForm'
import {FormInstance} from 'antd/lib/form'

export interface CreateOnboardingFormFields extends UserFields {
  businessName?: string
  companyCode?: string
  partitaIva?: string
  companyType?: 'supplier' | 'representative'
  categories?: string []
}

interface Props {
  formRef?: RefObject<FormInstance>
  formFields: CreateOnboardingFormFields
  onValuesChange: (changedValues: Partial<CreateOnboardingFormFields>, values: CreateOnboardingFormFields) => void
  submitButton?: ButtonProps
  secondaryButton?: ButtonProps
}

export default class CreateByVatCodeForm extends React.Component<Props> {
  getSchema = (): Schema[] => {
    const { formFields } = this.props
/*
    function getRuleForCompanyCode() {
      return  getUserRole() === admin ?
          [{required: true, message: __(T.messages.field_required)}] : []
    }
*/
    return [
      {
        name: 'partitaIva',
        label: __(T.fields.piva_cf),
        type: 'text',
        placeholder: __(T.misc.insert),
        col: { xs: 24, sm: { span: 24, pull: 26, offset: 26 } },
        rules: [{ required: true, message: __(T.messages.field_required) }],
        defaultValue: formFields.companyCode
      }
    ]
  }

  render = () => {
    return (
      <AdvancedForm
        formRef={this.props.formRef}
        schema={this.getSchema()}
        values={this.props.formFields}
        onValuesChange={this.props.onValuesChange}
        submitButton={this.props.submitButton}
        secondaryButton={this.props.secondaryButton}
      />
    )
  }
}
