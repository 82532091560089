import React, { RefObject } from 'react';
import { Col } from 'antd';
import { DetailedOrder, Order } from '../../api/types';
import { View } from '../../components';
import OrderFulfillmentForm from '../../components/orders/OrderFulfillmentForm';
import { __, T } from 'config/i18n';
import { showNotification } from '../../helpers/Notifications';
import { navigateBack } from '../../router';
import Orders from '../../api/Orders';
import ImportTraceFromAnotherPO from '../../tools/ImportTraceFromAnotherPO';

interface Props {
  match: { params: { orderId: string; thirdPart: string } };
}

interface State {
  order?: DetailedOrder
  fulfillmentOrDraftFormSubmitButtonRef: RefObject<HTMLButtonElement>
  draft: boolean
  isImportTraceModalVisible: boolean
}

export default class OrderFulfill extends React.Component<Props, State> {
  state: State = {
    fulfillmentOrDraftFormSubmitButtonRef: React.createRef<HTMLButtonElement>(),
    draft: false,
    isImportTraceModalVisible: false
  };


  componentDidMount = async () => {
    const order = await Orders.get(this.props.match.params.orderId);
    this.setState({ order });
  };

  handleFulfillmentSuccess = (order) => {
    const { draft } = this.state;
    showNotification(
      __(T.titles.order_fulfillment_success),
      draft ? __(T.messages.order_fulfillment_draft) : __(T.messages.order_fulfillment_success),
      'success',
      3
    );
    if (!draft) {
      navigateBack();
    }
    this.setState({ order: order });
  };


  hideModal = () => this.setState({ isImportTraceModalVisible: false });

  importTrace = (order: Order | undefined, overrideExistingDataInput: boolean) => this.importTraceFromAnotherPO(order, overrideExistingDataInput);

  render = () => {
    const { thirdPart } = this.props.match.params;
    const { order, fulfillmentOrDraftFormSubmitButtonRef, draft } = this.state;
    return (
      <>
        {order && (
          <View className="page-table" style={{ margin: 10, marginTop: 0, borderRadius: '0px' }}>
            <Col span={16}>
              <OrderFulfillmentForm
                order={order}
                onChange={o => {
                  this.setState({ order: o });
                }}
                onFulfillmentSuccess={this.handleFulfillmentSuccess}
                fulfillmentOrDraftFormSubmitButtonRef={fulfillmentOrDraftFormSubmitButtonRef}
                thirdPart={thirdPart === 'true'}
                draft={draft}
              />
            </Col>
          </View>
        )}
      </>
    );
  };

  private async importTraceFromAnotherPO(orderFromDialogImport: Order | undefined, overrideExistingDataInput: boolean) {
    await ImportTraceFromAnotherPO.importData(this.state.order, orderFromDialogImport, overrideExistingDataInput);
    let tempOrder = JSON.parse(JSON.stringify(this.state.order))
    this.setState({order : undefined})
    this.setState({order : tempOrder})
  }

}
