import React, {RefObject} from 'react';
import {OnBoardings, Suppliers} from '../../api';
import {Company, CreateSupplierCommand} from '../../api/types';
import {__, T} from '../../config/i18n';
import {Button} from 'antd';
import {showNotification} from '../../helpers/Notifications';
import {navigate} from '../../router';
import CompanyDataTabForm from './CompanyDataTabForm';
import {Modal} from "../../components";

interface Props {
    company: Company;
}

interface State {
    company: Company;
    loading: boolean;
    warningModalVisible: boolean
}

export default class CompanyDataTab extends React.Component<Props, State> {

    attributesFormSubmitButtonRef: RefObject<HTMLButtonElement> = React.createRef<HTMLButtonElement>();

    state = {
        company: this.props.company,
        loading: false,
        warningModalVisible: false
    };

    previousSave = async () => {
        this.attributesFormSubmitButtonRef.current?.click();
    }

    save = async () => {
        const {company} = this.state
        const id = this.props.company.id

        this.setState({loading: true})

        if (id) {
            const response = await Suppliers.update(id, company)
            this.postSave(response, T.messages.company_datas_updated)
        } else {
            const showWarningBeforeCreate = await OnBoardings.showWarningBeforeCreate(company.data.legalCode!)

            if (showWarningBeforeCreate) {
                this.setState({warningModalVisible: true})
            } else {
                await this.createSupplier()
            }
        }
    };

    createSupplier = async () => {
        this.setState({warningModalVisible: false})

        const {company} = this.state;

        const command: CreateSupplierCommand = {
            attributes: company.attributes,
            categories: company.categories,
            data: company.data
        }

        const response = await Suppliers.create(command)
        this.postSave(response, T.messages.company_added)
    }

    postSave = (response: any, successNotification: string) => {
        this.setState({loading: false})

        if (!response.state && response.message && response.message.toLowerCase().indexOf(('Business Name already exist').toLowerCase()) >= 0) {
            showNotification(__(T.messages.onboarding_business_name_already_exist_on_update), undefined, 'error')
        } else if (!response.state && response.message && response.message.toLowerCase().indexOf(('The business name cannot be identical to yours').toLowerCase()) >= 0) {
            showNotification(__(T.messages.business_name_cannot_be_identical_to_yours), undefined, 'error')
        } else if (!response.state && response.message && response.message.toLowerCase().indexOf(('Legal code already exist').toLowerCase()) >= 0) {
            showNotification(__(T.messages.onboarding_company_already_exist), undefined, 'error')
        } else {
            showNotification(__(successNotification))

            const url = window.location.href; // Ottieni l'URL corrente

            // Controlla se l'URL contiene l'elemento desiderato
            if (!url.includes('/reports/company')) {
                navigate('/reports/companies');
            }
        }
    }

    onValueChange = (company: Company) => {
        this.setState({company});
    };

    render = () => {
        return (
            <>
                <CompanyDataTabForm company={this.props.company} onValueChange={this.onValueChange}
                                    loading={this.state.loading}
                                    formSubmitButtonRef={this.attributesFormSubmitButtonRef}
                                    onComplete={this.save}
                />
                <Button onClick={this.previousSave} type="primary" style={{width: '200px'}}>
                    {this.props.company.id ? __(T.buttons.save) : __(T.buttons.create_new)}
                </Button>
                <Modal
                    visible={this.state.warningModalVisible}
                    title={__(T.confirms.warning_on_company_create)}
                    message={__(T.messages.confirm_or_go_back)}
                    closeButtonTitle={__(T.buttons.back)}
                    confirmButtonTitle={__(T.buttons.confirm)}
                    onCloseClick={() => {
                        this.setState({warningModalVisible: false, loading: false})
                    }}
                    onConfirmClick={this.createSupplier}
                />
            </>
        );
    };

}
