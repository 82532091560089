import React, { createRef } from 'react';

import OrderAssignmentForm from '../../components/orders/OrderAssignmentForm';
import { Button, List } from 'antd';
import { NotificationTCare, Page } from '../../api/types';
import NoticeCard from './NoticeCard';
import Title from 'antd/es/typography/Title';
import appStore from '../../AppStore';
import { navigate } from '../../router';
import { __, T } from '../../config/i18n';



interface Props {
  notificationList: NotificationTCare[];
  markAsRead: (notificationTCare: NotificationTCare) => void;
  markAllAsRead: () => void;
}


interface State {
  notificationPage: Page<NotificationTCare> | undefined;
}

const maxNumberOfShowed = 7;

function countShowedNotificationToRead(notificationList: NotificationTCare[]): number {
  let count: number = 0;
  if (notificationList) {
    const max = notificationList.length < maxNumberOfShowed ? notificationList.length : maxNumberOfShowed;
    for (let i = 0; i < max; i++) {
      if (!notificationList[i].read) {
        count = count + 1;
      }
    }
  }
  return count;

}

function getNumberOfNotReadButNotShowed(notificationList: NotificationTCare[]) {
  const notReadShowd = countShowedNotificationToRead(notificationList);
  return appStore.notificationsAndCount!.count - notReadShowd;
}

export default class NotificationsList extends React.Component<Props, State> {
  orderAssignmentFormRef = createRef<OrderAssignmentForm>();

  state: State = {
    notificationPage: undefined
  };




  render = () => {
    const { notificationList } = this.props;
    let unreadNotShowed = getNumberOfNotReadButNotShowed(notificationList);
    return (
      <div className="notificationList">
        <div className="notificatiinListHeader">
          <div className="notificatoinListBody">
            <div className="notificationLabelPadding">
              <Title level={4}>{__(T.titles.notification)}</Title>
            </div>

          </div>
          <div className="notificatoinListdFooter">

            {unreadNotShowed === 0 && (
              <Button onClick={() => navigate('/notificationstcare/report')}
                      type="link"> <b>{__(T.buttons.view_all_notifications)}</b> </Button>

            )}
            {unreadNotShowed > 0 && (
              <Button
                onClick={() => navigate('/notificationstcare/report')} type="link">
                <b>{__(T.buttons.view_all_notifications)}</b> ({unreadNotShowed} {__(T.buttons.more_unread)})</Button>
            )}
          </div>
          <div className="notificatoinListdFooter">
            {(
              <Button onClick={() => this.props.markAllAsRead()}
                      type="link"> <b>{__(T.buttons.mark_all_as_read)}</b> </Button>

            )}
          </div>
        </div>
        <List
          size="large"
          style={
            { width: 600 }
          }
          itemLayout="horizontal"
          dataSource={notificationList.slice(0, maxNumberOfShowed)}
          renderItem={item => (
            <List.Item>
              <NoticeCard notificationTCare={item} markAsRead={this.props.markAsRead} />
            </List.Item>
          )}
        />
      </div>
    );


  };

  toArray<T>(map: { [key: string]: T }): Array<[string, T]> {
    const mapObject = new Map<string, T>();
    for (const mapKey in map) {
      mapObject.set(mapKey, map[mapKey]);
    }
    return Array.from(mapObject);
  }

}
