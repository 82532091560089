import React from 'react'
import { Tag } from 'antd'
import { __, T } from '../../config/i18n'
import { CertificationStatus } from '../../api/types'

interface Props {
  status: CertificationStatus
  style?: React.CSSProperties
}

export default class ScopeCertificateStatusTag extends React.Component<Props> {
  render = () => {
    const { status, style } = this.props

    const certificateStatusKey = status.toLowerCase()
    return (
      <Tag
        color={__(T.colors[`scope_certificate_${certificateStatusKey}_background`])}
        style={{
          ...(style ?? {}),
          color: __(T.colors[`scope_certificate_${certificateStatusKey}_text`]),
          borderRadius: '5px',
        }}
      >
        <b>{__(T.misc[certificateStatusKey])}</b>
      </Tag>
    )
  }
}
