import React from 'react'
import {Button, InputNumber, Modal as AntDModal, Table} from 'antd'
import {ReportOrder, SplitOrderCommandItemAction} from '../../api/types'
import {__, T} from '../../config/i18n'
import {FormInstance} from 'antd/es/form'
import Orders from '../../api/Orders';
import {PaginationHelper} from "../../PaginationHelper";
import {EmptyListText} from "../index";
import {InputStatus} from "antd/lib/_util/statusUtils";
import AppStore from "../../AppStore";
import {buildColumnsByConfiguration, getValueByPath} from "./ColumnBuilderUtil";


interface Props {
  motherOrderId: string | undefined
  visible: boolean
  onConfirm: () => void
  onCancel: () => void
}

interface State {
  paginationHelper: PaginationHelper,
  originalOrderCompleted: boolean
  orders: SplittedReportOrder[]
  isPageLoading: boolean
  disableButton: boolean
  status: InputStatus
}

interface SplittedReportOrder extends ReportOrder {
  action: SplitOrderCommandItemAction
}

const initState: State = {
  paginationHelper: new PaginationHelper(1000000),
  orders: [],
  isPageLoading: true,
  disableButton: true,
  status: '',
  originalOrderCompleted: true

};
export default class SplitOrderModal extends React.Component<Props, State> {
  formRef = React.createRef<FormInstance>()

  state: State = {...initState}

  refreshState = async () => {
    this.setState({isPageLoading: true})
    if(!this.props.motherOrderId) {
      return;
    }
    const ordersPage = await Orders.search(
        {
          ...this.state.paginationHelper.getPaginationApiParams(),
          ...this.getParams(),
          sort: 'creationDate,asc'
        }
    );
    if (ordersPage.content.length === 0) {
      const motherOrder = await Orders.get(this.props.motherOrderId!);
      motherOrder.attributes!['originalQuantity'] = motherOrder.attributes!['quantity']
      ordersPage.content.push(motherOrder)
    }
    this.setState({
      originalOrderCompleted: ordersPage.content[0].status === 'COMPLETED' || ordersPage.content[0].status === 'APPROVED',
      orders: ordersPage.content.map(o => {
        return {...o, action: 'UPDATE'}
      }), isPageLoading: false
    });
  };

  async componentDidMount() {
  }

  async componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any) {
    if (prevProps.visible !== this.props.visible) {
      if (this.props.visible) {
        await this.refreshState();
      } else {
        this.setState({...initState})
      }
    }
  }

  handleOk = () => {
    Orders.saveSplit({
      motherOrderId: this.props.motherOrderId!,
      itemOrders: this.state.orders
      .filter(o => o.status !== 'COMPLETED')
      .filter(o => o.status !== 'APPROVED')
      .map(o => {
        return {
          orderId: o.id,
          action: o.action,
          attributes: {'quantity': o.attributes!['quantity']}
        }
      })
    }).then(() => this.props.onConfirm(), e => console.error(e))
  }


  getParams = () => {
    let params = {}
    params['splittedOrderId'] = this.props.motherOrderId
    return params
  }


  private updateValues(record: SplittedReportOrder, newValue: number) {
    let status: InputStatus = ''
    let orders = this.state.orders
    let disableButton = true
    let oldValue = record.attributes!['quantity']
    let oldValues = this.getOldValues(orders)
    record.attributes!['quantity'] = newValue
    if (newValue === null) {
      status = 'error'
    } else {
      let motherOrder = orders[0]
      // sottraggo dalla quantita' della madre il valore inserito
      let newSplitOrderQuantity = oldValues - oldValue + newValue;
      let newMotherQuantity = motherOrder.attributes!['originalQuantity'] - newSplitOrderQuantity
      motherOrder.attributes!['quantity'] = +newMotherQuantity.toFixed(2)
      // recupero l'indice del record che sto editando
      let indexOfEditatedValue = this.state.orders.indexOf(record)
      // aggiorno il record che sto editando
      orders[indexOfEditatedValue] = record
      if (newMotherQuantity > 0) {
        disableButton = false
      } else {
        motherOrder.attributes!['quantity'] = 'error'
        status = 'error'
      }
      if (newValue < 0.01 || !this.allQuantityGreaterThan0(orders)) {
        status = 'error'
        disableButton = true
      }


    }

    this.setState({orders: orders, disableButton, status});
  }


  addSplitOrder = () => {
    let clonedReportOrder: SplittedReportOrder = JSON.parse(JSON.stringify(this.state.orders[0]));
    clonedReportOrder.id = undefined
    clonedReportOrder.action = "INSERT"
    clonedReportOrder.attributes!['quantity'] = 0
    const newList = [...this.state.orders, clonedReportOrder]
    this.setState({orders: newList});
  };

  private getColumns() {
    let columns = buildColumnsByConfiguration(AppStore.appSettings.orderListColumnsForSplit);
    columns.forEach(c => {
      c.sorter = undefined
      c.sortDirections = undefined

      if (c.key === 'col_attributes.quantity') {
        c.render = (text, record: ReportOrder) => {
          let quantity = getValueByPath(record, 'attributes.quantity')
          let totalQuantity = getValueByPath(record, 'attributes.originalQuantity')
          if (this.props.motherOrderId !== record.id && record.status !== 'COMPLETED' && record.status !== 'APPROVED') {
            return <div style={{textAlign: "right"}} key={c.key}>
              <InputNumber style={{width: "75px"}}
                           value={Number(quantity)}
                           status={this.state.status}
                           disabled={this.state.originalOrderCompleted}
                           onChange={(value: any) => {
                             this.updateValues(record as SplittedReportOrder, value)
                           }}/> / <>{totalQuantity}</>
            </div>

          } else {
            if (quantity === 'error') {
              return <div style={{textAlign: "right", color: "red"}} key={c.key}>
                {totalQuantity + ''}
              </div>
            } else {
              return <div style={{textAlign: "right"}} key={c.key}>
                {quantity + ' / ' + totalQuantity}
              </div>
            }

          }

        }
      }
    })
    return columns

  }


  render = () => {
    const {visible} = this.props
    return (
        <AntDModal
            key={'l'}
            centered
            title={__(T.titles.split)}
            visible={visible}
            onCancel={_ => this.props.onCancel()}
            onOk={_ => this.handleOk()}
            okText={__(T.buttons.save)}
            cancelText={__(T.buttons.cancel)}
            width={'70%'}
            okButtonProps={{disabled: this.state.disableButton || this.state.originalOrderCompleted}}
        >
          <Table
              pagination={false}
              loading={this.state.isPageLoading}
              className="orders-report-table"
              bordered
              columns={this.getColumns()}
              dataSource={this.state.orders.map(order => ({
                ...order,
                key: order.id
              }))}
              locale={{emptyText: <EmptyListText message={__(T.messages.no_order_found)}/>}}
              scroll={{x: true}}
          />
          {this.state.originalOrderCompleted &&
              <div style={{
                width: '100%',
                color: 'red',
                fontWeight: 'bold',
                textAlign: 'center'
              }}>{__(T.error.splitOriginalOrderCompleted)}</div>}
          <div style={{display: 'flex', justifyContent: 'center'}}>
            <Button type="primary"
                    disabled={this.state.originalOrderCompleted}
                    style={{marginTop: '10px', display: 'flex', justifyContent: "space-between"}}
                    onClick={this.addSplitOrder}>


              <div style={{display: "flex", justifyContent: "space-between"}}>
                <img style={{
                  height: "25px",
                  justifyContent: 'center',
                  display: 'flex',
                  paddingRight: '10px'
                }} src={require('assets/images/Split.svg').default} alt={__(T.buttons.add_order)}/>
                <div>
                  {__(T.buttons.add_order)}
                </div>
              </div>
            </Button>
          </div>
        </AntDModal>
    )
  }


  private getOldValues(orders: SplittedReportOrder[]): number {
    let subList = orders.slice(1)
    return subList!.reduce((total, order) => total + Number(order.attributes!['quantity']), 0);
  }

  private allQuantityGreaterThan0(orders: SplittedReportOrder[]) {
    return orders.every(order => {
      const quantity = order.attributes?.quantity;
      return quantity && quantity > 0;
    });
  }

}
