import {StatisticData} from '../../../../api/types';
import statisticApiTCare from '../../../../api/StatisticApiTCare';
import StatisticApiTCare from '../../../../api/StatisticApiTCare';
import { setOffGlobalSpinningOnHeader } from '../../../../api/api';


export interface Statuses {
  assigned: number,
  deleteAssigned: number,
  cancelled: number,
  completed: number,
  deleteCompleted: number,
  approved: number,
  deleteApproved: number,
  draft: number
  deleteDraft: number
  fullFilled: number,
  toBeAssigned: number,
  deleteToBeAssigned: number,
  rejected: number,
  disapproved: number
}

export interface StatusesComputation {
  assignedComputation: number,
  completedComputation: number,
  approvedComputation: number,
  completedThirdPartComputation: number,
  fullFilledComputation: number,
  toBeAssignedComputation: number,
  draftComputation: number
}

export class OrderStatusUtil {

  static async getAllByComputationByIdCompany(idCompany: string | null, isAdmin: boolean, startYearMonthFilter: string | null, endYearMonthFilter: string | null):
    Promise<
      StatusesComputation
    > {
    let filter = this.getCreationOrAssigneeCompanyFilter(isAdmin, idCompany)
    filter += StatisticApiTCare.getDataRangeFilter(startYearMonthFilter, endYearMonthFilter)
    let statuses = await this.getStatuses(filter)

    return {
      approvedComputation: OrderStatusUtil.getApprovedComputation(statuses),
      assignedComputation: OrderStatusUtil.getAssignedComputation(statuses),
      completedComputation: OrderStatusUtil.getCompletedComputation(statuses),
      completedThirdPartComputation: 0,
      draftComputation: OrderStatusUtil.getDraftComputation(statuses),
      fullFilledComputation: OrderStatusUtil.getFullFilledComputation(statuses),
      toBeAssignedComputation: OrderStatusUtil.toBeAssignedComputation(statuses)
    }
  }


  private static async getStatuses(filter: string): Promise<Statuses> {
    // console.log('status_order?groupBy=status&' + filter + idCompany);
    const statisticData: StatisticData[] = await statisticApiTCare.getStatisticDataByUrl(
      "status_order?groupBy=status&" + filter, setOffGlobalSpinningOnHeader()) as StatisticData[];
    let touchedStatus: Statuses = {
      toBeAssigned: 0,
      deleteToBeAssigned: 0,
      assigned: 0,
      deleteAssigned: 0,
      cancelled: 0,
      completed: 0,
      deleteCompleted: 0,
      approved: 0,
      deleteApproved: 0,
      draft: 0,
      deleteDraft: 0,
      fullFilled: 0,
      rejected: 0,
      disapproved: 0
    }
    statisticData.forEach((item:StatisticData) => {
      switch (item['key'].status){
        case('TO_BE_ASSIGNED'): {
          touchedStatus.toBeAssigned += Number(item['value']);
          break;
        }
        case('DELETE_TO_BE_ASSIGNED'): {
          touchedStatus.deleteToBeAssigned += Number(item['value']);
          break;
        }
        case('ASSIGNED'): {
          touchedStatus.assigned += Number(item['value']);
          break;
        }
        case('DELETE_ASSIGNED'): {
          touchedStatus.deleteAssigned += Number(item['value']);
          break;
        }
        case('CANCELLED'): {
          touchedStatus.cancelled += Number(item['value']);
          break;
        }
        case('COMPLETED'): {
          touchedStatus.completed += Number(item['value']);
          break;
        }
        case('APPROVED'): {
          touchedStatus.approved += Number(item['value']);
          break;
        }
        case('DRAFT'): {
          touchedStatus.draft += Number(item['value']);
          break;
        }
        case('DELETE_DRAFT'): {
          touchedStatus.deleteDraft += Number(item['value']);
          break;
        }
        case('FULFILLED'): {
          touchedStatus.fullFilled += Number(item['value']);
          break;
        }
        case('REJECTED'): {
          touchedStatus.rejected += Number(item['value']);
          break;
        }
        case('DISAPPROVED'): {
          touchedStatus.disapproved += Number(item['value']);
          break;
        }
        case('DELETE_COMPLETED'): {
          touchedStatus.deleteCompleted += Number(item['value']);
          break;
        }
        case('DELETE_APPROVED'): {
          touchedStatus.deleteApproved += Number(item['value']);
          break;
        }
      }
    });
    return touchedStatus;

  }

  public static getCreationOrAssigneeCompanyFilter(isAdmin: boolean, companyId: string | null): string {
    if (isAdmin) {
      return 'assigneeCompany=null&';
    }
    return 'creationCompany=' + companyId + '&';
  }

  private static getCompletedComputation(statuses: Statuses): number {
    return statuses.completed - statuses.rejected - statuses.approved - statuses.deleteCompleted;
  }

  private static getAssignedComputation(statuses: Statuses): number {
    // to be assiegned -> assieng -> draft -> fulfilled -> completed
    let result: number = statuses.assigned - statuses.draft + statuses.rejected - statuses.deleteAssigned;
    return result < 0 ? 0: result
  }

  private static getApprovedComputation(statuses: Statuses): number {
    // to be assiegned -> assiegn -> draft -> fulfilled -> completed -> disapproved -> approved
    return statuses.approved - statuses.disapproved - statuses.deleteApproved;
  }

  private static getDraftComputation(statuses: Statuses): number {
    let result: number = statuses.draft - statuses.fullFilled - statuses.deleteDraft
    return result < 0 ? 0: result;
  }


  private static getFullFilledComputation(statuses: Statuses): number {
    let result: number = statuses.fullFilled - statuses.completed;
    return result < 0 ? 0: result
  }

  private static toBeAssignedComputation(statuses: Statuses): number {
    let completedThirdPart = 0
    let result: number = statuses.toBeAssigned - statuses.assigned - completedThirdPart - statuses.deleteToBeAssigned
    return result < 0 ? 0: result
  }

  static getTotalByComputation(computation: StatusesComputation) {
    return computation.toBeAssignedComputation
      + computation.assignedComputation
      + computation.draftComputation
      + computation.fullFilledComputation
      + computation.completedComputation
      + computation.approvedComputation;
  }

}


