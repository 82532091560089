import qs from 'qs'
import {ApiErrorResponse, ApiResponse, ApisauceInstance, create} from 'apisauce'
import {NotFound, Unauthorized} from 'errors'
import ApiError from 'errors/ApiError'
import {showError} from 'helpers/Notifications'
import {__, T} from 'config/i18n'
import AppStore from '../AppStore';
import { AxiosRequestConfig } from 'axios';



/**
 * Apisauce params serializer
 */
export const paramsSerializer = (params: any) =>
    qs.stringify(params, {
      // default serializer uses format ?code[]=A&code[]=B so override with option arrayFormat: repeat
      arrayFormat: 'repeat',
      // Note: this will not usually be used as date params are converted to timestamp
      // as soon as they're parsed from user input
      serializeDate: Date => Date.getTime().toString(),
  })

let api: ApisauceInstance

if (api! === undefined) {
  //api di dev
  //const backendUrl = 'https://tcare-tenant-dev.temera.it/' //file .env PORT=3000


  let backendUrl = '/' //file .env PORT=3005

  //Scommentare se non si vogliono usare i servizi locali
  // if(String(window.location.hostname).includes('localhost')) {
  //   backendUrl = 'https://tcare-dev.temera.it/'
  // }

  // Vecchia configurazione sostituita dalla url relativa '/'
  // if (String(window.location.hostname).includes('tcare-qty')) {
  //   backendUrl = 'https://tcare-qty.temera.it/'
  // } else if (String(window.location.hostname).includes('tcare.temera.it')) {
  //   backendUrl = 'https://tcare.temera.it/'
  // }

  api = create({
    baseURL: backendUrl,
    timeout: 300000,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    paramsSerializer,
  })
}


// Aggiungere un interceptor per le richieste
api.axiosInstance.interceptors.request.use(
    function(config) {
      // Esegui qui le modifiche necessarie alla richiesta, ad esempio l'aggiunta di header
      // config.headers['Authorization'] = 'Bearer token'; // Esempio di aggiunta di un token di autorizzazione
      increaseAndSendGlobalSpinningEventGlobalSpinning(config);

      return config;
    },
    function(error) {
      // Gestisci eventuali errori nella richiesta
      return Promise.reject(error);
    }
);




api.axiosInstance.interceptors.response.use(
  response => {
    decreaseAndSendGlobalSpinningEventGlobalSpinning(response);
    return response;
  },
  error => {
    decreaseAndSendGlobalSpinningEventGlobalSpinning(error);

    // if on login page respond with 401
    if (error.request.responseURL.includes('/login')) {
      return Promise.reject(error)
    } else if (error.response.status === 401) {
      // alert('session expired')
      // DeviceEventEmitter.emit('AccessTokenExpired')
    } else {
      return Promise.reject(error)
    }
  }
)



function increaseAndSendGlobalSpinningEventGlobalSpinning(config: AxiosRequestConfig) {
  if (config.headers.isActivateGlobalSpinning || config.headers.isActivateGlobalSpinning === undefined) {
    AppStore.globalSpin = AppStore.globalSpin + 1;
    sendGlobalSpinningEvent();
  }
}

function decreaseAndSendGlobalSpinningEventGlobalSpinning(error: any) {
  if (error.config.headers.isActivateGlobalSpinning || error.config.headers.isActivateGlobalSpinning === undefined) {
    AppStore.globalSpin = AppStore.globalSpin - 1;
    sendGlobalSpinningEvent();
  }
}

function sendGlobalSpinningEvent() {
  AppStore.eventEmitter.dispatch('globalSpinningChange', null);
}


/**
 * Will throw an exception if server returned an error
 * @param res
 */
export const responseErrorCheck = <T = any>(res: ApiResponse<T, any>, isLaunchError: boolean = true) => {
  if (res && res.status === 0) {
    showError('No internet connection', undefined)
    if (!res.ok) {
      throw new ApiError(res as ApiErrorResponse<any>)
    } else {
      throw new Error('No internet connection')
    }
  }

  if (res && (res.status === 200 || res.status === 204)) {
    return res.data as T
  }

  if (res.status === 403) {
    if(res.data === "USER_UNAUTHORIZED_OPERATION"){
      showError(String(res.status), __(T.error.user_unauthorized_operation))
    }else if (isLaunchError) {
      showError(String(res.status), __(T.error.unauthorized))
    }
    if (!res.ok) {
      throw new Unauthorized(res as ApiErrorResponse<any>)
    } else {
      throw new Error(__(T.error.unauthorized))
    }
  }

  if (res.status === 404) {
    if (isLaunchError) {
      showError(String(res.status), __(T.error.not_found))
    }
    if (!res.ok) {
      throw new NotFound(res as ApiErrorResponse<any>)
    } else {
      throw new Error(__(T.error.not_found))
    }
  }

  if (res.data) {
    var errorMessage = ""
    if (res.data.message) {
      errorMessage = res.data.message
    } else if (res.data.error?.message) {
      errorMessage = res.data.error?.message
    } else {
      errorMessage = res.data.error
    }
    if (errorMessage) {
      if (isLaunchError) {
        showError(errorMessage, undefined)
      }
    } else {
      if (isLaunchError) {
        showError(res.data, undefined)
      }
    }
    if (!res.ok && isLaunchError) {
      throw new ApiError(res as ApiErrorResponse<any>)
    }
  }

  if (!res || !res.data) {
    if (isLaunchError) {
      showError(__(T.error.generic_be_error), undefined)
    }
    if (!res.ok) {
      throw new ApiError(res as ApiErrorResponse<any>)
    } else {
      throw new Error(__(T.error.generic_be_error))
    }
  }

  if (res.problem) {
    if (isLaunchError) {
      showError(res.data?.message || res.problem, undefined)
    }
    if (!res.ok && isLaunchError) {
        throw new ApiError(res as ApiErrorResponse<any>)
    }
  }
  return  res.data as T
}

export function setOffGlobalSpinningOnHeader(): AxiosRequestConfig {
  return {
    headers: {
      isActivateGlobalSpinning: false
    }
  };
}

export const translateErrorMessage = (message: string) => {
  return message
}

// export default create({
//   baseURL: 'https://localhost', // this is useless, each resource will define the baseUrl
//   timeout: 30000,
// })

export default api
