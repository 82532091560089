import React, { Component } from 'react'
import { __, T } from 'config/i18n'
import { Typography, notification, Spin } from 'antd'
import { DeleteOutlined } from '@ant-design/icons'
import { View, AdvancedForm, Fibers, ModalConfirm } from 'components'
import { SupplyChain, SupplyChainStep } from 'api/types'
import { Schema } from 'components/AdvancedForm'
import theme from 'theme/theme'
import SupplyChains from 'api/SupplyChains'
import { FormInstance } from 'antd/es/form'

interface Props {
  title?: string
}

interface State {
  currentSupply?: SupplyChain
  formSchema?: Schema[]
  supplyChain: SupplyChain[]
  loading: boolean
  indexToRemove: number
  confirmModalVisible: boolean
}

const { Title, Text } = Typography
const ENABLED_DELETE_SUPPLY = true

export default class MainConfigurations extends Component<Props, State> {
  formRef = React.createRef<FormInstance>()

  state: State = {
    currentSupply: undefined,
    formSchema: [],
    supplyChain: [],
    loading: true,
    indexToRemove: 0,
    confirmModalVisible: false,
  }

  componentDidMount() {
    this._init()
  }

  getSupplyChain = async () => {
    const supply = await SupplyChains.search({
      page: 0,
      size: 100,
      sort: 'createdDate,asc',
    })
    return supply ? supply.content : []
  }

  _init = async () => {
    const fiberCodes = await SupplyChains.getFibersCode()
    const supplyChain = await this.getSupplyChain()
    this.setState({
      supplyChain: supplyChain,
      loading: false,
      currentSupply:
        supplyChain.length > 0
          ? supplyChain[0]
          : {
              id: '',
              name: '',
              fiberCodes: [],
              enabled: false,
              steps: [],
            },
      formSchema: [
        {
          name: 'name',
          type: 'text',
          label: __(T.fields.supplyChainName),
          placeholder: __(T.misc.write),
          col: { xs: 24 },
          rules: [{ required: true }],
        },
        {
          name: 'fiberCodes',
          type: 'select',
          label: __(T.fields.supplyChainFiber),
          placeholder: __(T.misc.select),
          descriptionKey: 'label',
          options: fiberCodes,
          col: { xs: 24 },
          mode: 'tags',
          maxTagCount: 8,
          rules: [{ required: true }],
        },
        {
          name: 'enabled',
          label: __(T.fields.activate),
          type: 'switch',
          defaultValue: false,
          col: { xs: 24 },
        },
        {
          name: 'SupplyChainSteps',
          type: 'supply-chain-steps',
          col: { xs: 24 },
        },
      ],
    })
  }

  onValuesChange = (changedValues: any, values: SupplyChain) => {
    this.setState({ currentSupply: {...this.state.currentSupply, ...values}})
  }

  changeSupplyChain = (supply: SupplyChain) => {
    const tmp: SupplyChain = supply || {
      id: '',
      name: '',
      fiberCodes: [],
      enabled: false,
      steps: [],
    }
    this.setState({
      currentSupply: tmp,
    })
  }

  removeStep = (index: number) => {
    this.setState({ confirmModalVisible: true, indexToRemove: index })
  }

  deleteSupply = () => {
    this.setState({ confirmModalVisible: true, indexToRemove: -1 })
  }

  confirmOperation = async (confirm: boolean) => {
    if (confirm) {
      let supply = this.state.currentSupply
      if (supply) {
        const supplyId = supply.id || ''
        const { indexToRemove } = this.state
        if (indexToRemove === -1) {
          let supplies = this.state.supplyChain

          const index = supplies.findIndex(s => s.id === supplyId)
          if (index !== -1) {
            await SupplyChains.delete(supplyId)
            supplies.splice(index, 1)
            this.setState({
              supplyChain: supplies,
              confirmModalVisible: false,
              indexToRemove: 0,
              currentSupply:
                supplies.length > 0
                  ? supplies[0]
                  : {
                      id: '',
                      name: '',
                      fiberCodes: [],
                      enabled: false,
                      steps: [],
                    },
            })
          }
        } else {
          if (supply.steps && supply.steps.length > 0) {
            supply.steps.splice(indexToRemove, 1)
            this.setState({ currentSupply: supply, confirmModalVisible: false, indexToRemove: 0 })
          }
        }
      }
    } else {
      this.setState({ confirmModalVisible: false, indexToRemove: 0 })
    }
  }

  addSteps = (steps: SupplyChainStep[]) => {
    let supply = this.state.currentSupply
    if (supply) {
      this.setState({
        currentSupply: {
          ...supply,
          steps: (supply.steps || []).concat(steps),
        },
      })
    }
  }

  reorder = (startIndex, endIndex) => {
    const tmp = this.state.currentSupply
    if (tmp && tmp.steps) {
      const [removed] = tmp.steps.splice(startIndex, 1)
      tmp.steps.splice(endIndex, 0, removed)
      this.setState({ currentSupply: tmp })
    }
  }

  save = async () => {
    const { currentSupply } = this.state
    if (currentSupply) {
      if (!currentSupply.steps || currentSupply.steps.length === 0) {
        notification.error({
          message: __(T.error.supply_chain_steps),
          placement: 'bottomRight',
          duration: 3,
        })
      } else {
        let supplies = this.state.supplyChain
        let result
        let error = true
        if (currentSupply.id === '') {
          const tmpToSave: SupplyChain = {
            id: '',
            name: currentSupply.name,
            fiberCodes: currentSupply.fiberCodes,
            steps: currentSupply.steps,
            enabled: currentSupply.enabled,
          }
          result = await SupplyChains.save(tmpToSave)
          if (result && result.id) {
            supplies.push(result) //unshift
            error = false
          }
        } else {
          const index = supplies.findIndex(s => s.id === currentSupply.id)
          if (index !== -1) {
            result = await SupplyChains.update(currentSupply.id, currentSupply)
            supplies[index] = result
            error = false
          }
        }

        if (!error) {
          this.setState({ supplyChain: supplies, currentSupply: result })
          this.showNotification()
        }
      }
    }
  }

  showNotification = () => {
    notification.success({
      message: __(T.messages.supply_chain_saved),
      placement: 'bottomRight',
      duration: 3,
    })
  }

  render = () => {
    const { currentSupply, formSchema, supplyChain, loading, confirmModalVisible, indexToRemove } = this.state
    return (
      <View style={{ marginTop: 20 }}>
        <View style={{ paddingLeft: 20 }}>
          <Title level={4}>{__(T.misc.main_configurations_title)}</Title>
          <Text>{__(T.misc.main_configurations_subtitle)}</Text>
        </View>
        {!loading ? (
          <View className="admin-box grey">
            <Fibers supplyChain={supplyChain} currentSupply={currentSupply} onclick={this.changeSupplyChain} />
            <View className="supply-chain-col">
              {currentSupply && (
                <>
                  <View style={{ display: 'flex', flexDirection: 'row', marginBottom: 25, alignItems: 'center' }}>
                    <Title level={4} style={{ flex: 1 }}>
                      {currentSupply.id === '' ? __(T.misc.new_fiber) : __(T.misc.edit_fiber)}
                    </Title>
                    {currentSupply.id !== '' && ENABLED_DELETE_SUPPLY && (
                      <View className="sc-ball">
                        <DeleteOutlined style={{ fontSize: 18, color: '#FFF' }} onClick={() => this.deleteSupply()} />
                      </View>
                    )}
                  </View>
                  <AdvancedForm
                    formRef={this.formRef}
                    schema={formSchema || []}
                    values={currentSupply}
                    onValuesChange={this.onValuesChange}
                    buttonJustify="end"
                    submitButton={{
                      text: __(T.buttons.save),
                      onPress: () => {
                        this.save()
                      },
                      col: 5,
                      style: theme.temeraPrimaryGreenButton,
                    }}
                    style={{ flex: 1 }}
                    removeElement={this.removeStep}
                    addSteps={this.addSteps}
                    reorderElement={this.reorder}
                  />
                </>
              )}
            </View>
          </View>
        ) : (
          <View style={{ paddingLeft: 20, marginTop: 25, marginBottom: 60 }}>
            <Spin />
            <Text style={{ marginLeft: 10 }}>{__(T.misc.loading)}</Text>
          </View>
        )}
        <ModalConfirm
          title={indexToRemove === -1 ? __(T.misc.remove_supply_chain_title) : __(T.misc.remove_step_title)}
          subtitle={indexToRemove === -1 ? __(T.misc.remove_supply_chain_subtitle) : __(T.misc.remove_step_subtitle)}
          callback={this.confirmOperation}
          visible={confirmModalVisible}
        />
      </View>
    )
  }
}
