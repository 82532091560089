export class JsonTools {

    /**
     *
     * Function to merge Json
     * Esempio di utilizzo
     * const jsonA = { a: 1, b: { c: 2, d: 3 }, e: 9 };
     * const jsonB = { bx: { c: 4 }, e: 5 };
     *
     * const mergedJSON = mergeJSONObjects(jsonA, jsonB);
     *
     * console.log(mergedJSON);
     *
     * @param a
     * @param b
     */
    mergeJSONObjects(a: any, b: any): any {
        // Verifica se a e b sono oggetti
        if (typeof a !== 'object' || typeof b !== 'object' || Array.isArray(a) || Array.isArray(b)) {
            // Se non sono oggetti, restituisci b
            return b;
        }

        // Crea un nuovo oggetto per il risultato
        const result: any = { ...a };

        // Itera su tutte le chiavi di b
        for (const key in b) {
            if (b.hasOwnProperty(key)) {
                // Se la chiave esiste in a e b come oggetti, fai il merge ricorsivo
                if (typeof a[key] === 'object' && typeof b[key] === 'object' && !Array.isArray(a[key]) && !Array.isArray(b[key])) {
                    result[key] = this.mergeJSONObjects(a[key], b[key]);
                } else {
                    // Altrimenti, sovrascrivi il valore di a con quello di b
                    result[key] = b[key];
                }
            }
        }

        return result;
    }



}


const jsonTools = new JsonTools()
export { jsonTools as default }
