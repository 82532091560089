import React from 'react';
import { Button, Modal as AntDModal, Upload } from 'antd';
import { AdvancedForm } from '../../components';
import { UploadChangeParam, UploadFile } from 'antd/es/upload/interface';
import { CertificationUpload } from '../../api/types';
import { __, T } from '../../config/i18n';
import { Schema } from '../AdvancedForm';
import { UploadOutlined } from '@ant-design/icons';
import { FormInstance } from 'antd/es/form';
import { showNotification } from '../../helpers/Notifications';
import OnBoardings from '../../api/OnBoardings';
import { ImportOnBoarding } from '../../types';

interface Props {
  visible: boolean;
  onConfirm: () => void;
  onCancel: () => void;
}

interface State {
  uploadTraceDataFileFile: Omit<Partial<CertificationUpload>, 'file'> & { file?: UploadFile };
  uploadTraceDataFileFileList: UploadFile[];
  isShowImport: boolean;
  isImporting: boolean;
  importOnBoarding?: ImportOnBoarding;
}

export default class UploadOnboardingFileModal extends React.Component<Props, State> {
  formRef = React.createRef<FormInstance>();

  state: State = {
    uploadTraceDataFileFile: {},
    uploadTraceDataFileFileList: [],
    isShowImport: true,
    isImporting: false
  };

  getScopeCertificateUploadSchema = (): Schema[] => {
    return [
      {
        name: 'file',
        label: __(T.fields.file),
        placeholder: __(T.misc.select_trace_order),
        rules: [{ required: true }],
        col: 24,
        valuePropName: 'file',
        getValueFromEvent: ({ fileList }: UploadChangeParam) => {
          return fileList && fileList.length > 0 ? fileList[0] : undefined;
        },
        render: () => {
          return (
            <Upload
              listType="text"
              beforeUpload={_ => false}
              fileList={this.state.uploadTraceDataFileFileList}
              onChange={info =>
                this.setState((currentState: State) => ({
                  uploadTraceDataFileFileList: info.fileList.slice(-1)
                }))
              }
            >
              <Button icon={<UploadOutlined />}>{__(T.misc.select_trace_order)}</Button>
            </Upload>
          );
        }
      }
    ];
  };

  handleCancel = () => this.setState({
    uploadTraceDataFileFile: {},
    uploadTraceDataFileFileList: [],
    isImporting: false
  }, this.props.onCancel);

  handleClose = () => {
    this.props.onCancel();
    this.setState({
      uploadTraceDataFileFile: {},
      uploadTraceDataFileFileList: [],
      isShowImport: true,
      isImporting: false
    });
  };


  handleOk = () => {
    if (this.formRef.current) {
      this.formRef.current.validateFields().then(
        async _ => {
          this.setState({ isImporting: true });
          const importOnBoarding: ImportOnBoarding = await OnBoardings.import(
            this.state.uploadTraceDataFileFile.file?.originFileObj as File);
          showNotification(__(T.messages.attachment_loaded));
          this.setState({ isShowImport: false, importOnBoarding: importOnBoarding, isImporting: false });
          // this.props.onConfirm();
        }
      );
    }
  };

  renderError(error: string) {
    switch (error) {
      case 'to_much_rows':
        return <p style={{ color: 'red' }}>
        {__(T.messages.import_onboarding_from_xls_error_count_max_line)}</p>;
      default:
        return <p style={{ color: 'red' }}>{error}</p>;
    }
  }

  getResultLength(results?: string[]) {
    if (!!results) {
      return results.length;
    }
    return 0;
  }


  render = () => {
    const { visible } = this.props;
    const { uploadTraceDataFileFile } = this.state;
    return (
      <>

        <AntDModal
          key={'uploadTraceData'}
          centered
          title={this.state.isShowImport ? ' Upload onboarding data' : ' Result'}
          visible={visible}
          onCancel={_ => this.state.isShowImport ? this.handleCancel() : this.handleClose()}
          onOk={_ => this.state.isShowImport ? this.handleOk() : this.handleClose()}
          okText={this.state.isShowImport ? __(T.buttons.confirm) : __(T.buttons.close)}
          cancelText={__(T.buttons.cancel)}
          confirmLoading={this.state.isImporting}
        >
          {this.state.isShowImport ? (
            <>
              <AdvancedForm
                formRef={this.formRef}
                schema={this.getScopeCertificateUploadSchema()}
                values={uploadTraceDataFileFile}
                onValuesChange={(changedValues, values) => this.setState({ uploadTraceDataFileFile: values })}
              />
            </>
          ) : (
            <>
              {this.state.importOnBoarding?.error ? (
                <p style={{ color: 'red' }}>
                  <b> {__(T.messages.error)}</b> {this.renderError(this.state.importOnBoarding?.error)}
                </p>
              ) : (
                <p style={{ color: 'green' }}>
                  <b>Success:</b> Data imported successfully!
                </p>
              )}
              <p>
                <b>Number of Imported
                  Businesses:</b> {this.getResultLength(this.state.importOnBoarding?.businessNameImported)}
              </p>
              <p>
                <b>Business Names Not Imported: </b>
                {
                  !!this.state.importOnBoarding?.businessNameNotImported
                  && this.state.importOnBoarding?.businessNameNotImported.length > 0
                    ? <ul> {
                      this.state.importOnBoarding?.businessNameNotImported.sort().map((name, index) => (
                        <li key={index}>{name}</li>
                      ))}
                    </ul>
                    : 0}
              </p>
            </>
          )}
        </AntDModal>


      </>
    );
  };
}
