import api, { responseErrorCheck } from './api'
import AppStore from '../AppStore';
import { AxiosRequestConfig } from 'axios';

//TenantSettings
class Translation {
  protected endpoint = 'profilemanager/v1/translations/map/'

  async getTranslations(axiosConfig?: AxiosRequestConfig) {
    const res = await api.get<Translation>(`${this.endpoint}` + AppStore.language, {}, axiosConfig);
    return responseErrorCheck(res)
  }
}

const translation = new Translation()
export { translation as default }
