import React, { Component } from 'react'
import { Modal as AntModal } from 'antd/lib'
import { Button } from 'antd'
import theme from 'theme/theme'
import View from './View'
import { __, T } from 'config/i18n'

export interface ModalProps {
  visible: boolean
  title?: string
  message?: string
  width?: number
  closeButtonTitle?: string
  confirmButtonTitle?: string
  onConfirmClick?: () => void
  onCloseClick?: () => void
  render?: () => any
}

export default class Modal extends Component<ModalProps> {
  onCancel = () => {
    const p = this.props
    this.setState({ modalVisible: false })
    p?.onCloseClick && p.onCloseClick()
    this.forceUpdate()
  }

  onOk = () => {
    const p = this.props
    this.setState({ modalVisible: false })
    p?.onConfirmClick && p.onConfirmClick()
    this.forceUpdate()
  }

  render = () => {
    const p = this.props

    return (
      <AntModal
        visible={p.visible}
        footer={null}
        width={p?.width ?? 600}
        onCancel={this.onCancel}
        onOk={this.onOk}
        style={{ textAlign: 'center' }}
        bodyStyle={{ background: 'rgba(51, 51, 51, 0.9);' }}
        centered
      >
        {p && (
          <>
            <h2 style={{ marginRight: 12, marginLeft: 12 }}>{p.title}</h2>
            <p style={{ marginRight: 12, marginLeft: 12 }}>{p.message}</p>
            <View style={{ display: 'flex', flex: 1, justifyContent: 'space-between', marginTop: 20 }}>
              {(p.closeButtonTitle || p.onCloseClick) && (
                <Button style={{ height: 40, flex: 1, marginRight: 20 }} type="default" onClick={this.onCancel}>
                  {p.closeButtonTitle ?? __(T.buttons.cancel)}
                </Button>
              )}
              {(p.confirmButtonTitle || p.onConfirmClick) && (
                <Button
                  type="primary"
                  onClick={this.onOk}
                  style={{ height: 40, flex: 1, ...theme.temeraPrimaryGreenButton }}
                >
                  {p.confirmButtonTitle ?? __(T.buttons.confirm)}
                </Button>
              )}
            </View>
          </>
        )}
      </AntModal>
    )
  }
}
