import React from "react";
import DonutPlotWithStaticsCard from "../cards/DonutPlotWithStaticsCard";
import StatisticApiTCare from "../../../api/StatisticApiTCare";
import GenericTools from "../../../tools/GenericTools";
import AppStore from "../../../AppStore";
import { StatisticData } from "../../../api/types";
import {OrderStatusUtil} from "../ant-d-modules/tools/OrderStatusUtil";
import {isAdmin} from "../../../helpers/permission";


interface Props {
  startYearMonthFilter: string|null
  endYearMonthFilter: string|null
}

interface State {
  stateNetworks: [{
    type: string
    value: number
  }?
  ]
}

export default class TrackedSupplyChain extends React.Component<Props, State> {
  state: State = {
    stateNetworks: []
  };


  async componentDidMount() {
    await this.refreshComponent();
  }

  async componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any) {
    if (prevProps.startYearMonthFilter !== this.props.startYearMonthFilter
      || prevProps.endYearMonthFilter !== this.props.endYearMonthFilter) {
      await this.refreshComponent();
    }
  }

  async refreshComponent() {
    if (!AppStore.loggedUser?.company) {
      let statisticData: StatisticData[] = await StatisticApiTCare.getTrackedSupplyChain(
        GenericTools.getTodayDate("YYYY-MM", 6)!,
          OrderStatusUtil.getCreationOrAssigneeCompanyFilter(isAdmin(), null ) +
          StatisticApiTCare.getDataRangeFilter(this.props.startYearMonthFilter, this.props.endYearMonthFilter)).then();

      let networksTmp: [{
        type: string
        value: number
      }?
      ] = [];

      statisticData.forEach((element: StatisticData) => {
        if (!!element["key"].networks) {
          networksTmp.push({
            type: element["key"].networks,
            value: element["value"]
          });
        }
      });
      this.setState({
        stateNetworks: networksTmp,

      });
    }
  }


  render() {
    const titleCard: string = "Tracked supply chain (Last 6 months)";
    const titlePlot: string = '\nSUPPLY CHAINS'
    return (
      <DonutPlotWithStaticsCard
        titleCard={titleCard}
        networks={this.state.stateNetworks}
        titlePlot={titlePlot} />
    );
  }
}




