import React, {RefObject} from 'react';
import { Company, SelectElement } from '../../api/types';
import {__, T} from '../../config/i18n';
import {Spin} from 'antd';
import {AppForm, AvatarLinkList} from '../../components';
import {getOperationsForRole, getUserRole, isAdmin} from '../../helpers/permission';
import {navigate} from '../../router';
import AppStore from '../../AppStore';


interface Props {
    company: Company,
    onValueChange: (company: Company) => void,
    loading: boolean,
    formSubmitButtonRef: RefObject<HTMLButtonElement>
    onComplete: () => void
}

interface State {
}

export default class CompanyDataTabForm extends React.Component<Props, State> {
    state: State = {};

    canAdminEditVatCode = (): boolean => isAdmin() && AppStore.appSettings.enableAdminEditVatCode
    isANewCompany = (): boolean => !this.props.company.id

    getSchema = () => {
        let schema = JSON.parse(AppStore.appSettings.companyJsonFormSchema);

        schema['properties'] = {
            legalCode: {
                title: __(T.fields.legal_code),
                type: 'string',
                span: 6,
                readOnly: !this.canAdminEditVatCode() && !this.isANewCompany()
            },
            businessName: {
                title: __(T.fields.business_name),
                type: 'string',
                span: 6
            },
            categories: {
                type: 'array',
                title: __(T.fields.category),
                items: {
                    type: 'string',
                    enum: AppStore.appSettings.categories.split("|")
                },
                readOnly: this.isCategoryReadOnly(),
                uniqueItems: true,
                span: 6
            },
            ...schema['properties']
        };
        if (!!schema.required) {
            schema.required.push('businessName');
        } else {
            schema.required = ['businessName'];
        }

        if(this.isANewCompany()){
            schema.required.push('legalCode');
        }

        return schema;
    };

    isCategoryReadOnly(): boolean {
        return !!((AppStore.appSettings.supplierDetailReadonly && !isAdmin())
            || (!isAdmin() && AppStore.appSettings.enableOnlyReadSupplierCompanyProfileCategory));

    }

    getCompanyFromFields = (company: Company, valueChanged): Company => {
        const companyFields: string[] = [
            'categories',
            'businessName',
            'address',
            'addressNumber',
            'cap',
            'country',
            'city',
            'placeId',
            'countryCode',
            'email',
            'legalCode',
            'mobilePhone',
            'pec',
            'phone',
            'quantityAllowed',
            'suppressionMethod',
            'suppressionMethod',
            'ethicalCode',
            'competenceArea',
            'companyEmail'];

        if (!company.attributes) {
            company.attributes = {};
        }
        for (const key in valueChanged) {
            if (!companyFields.includes(key)) {
                company.attributes[key] = valueChanged[key];
            } else if (key === 'categories') {
                company.categories = valueChanged[key];
            } else {
                company.data[key] = valueChanged[key];
            }
        }
        return company;
    };

    getFieldsFromCompany = (company: Company): any => {
        for (const key in company.data) {
            if (company.data[key] === null && key !== 'country') {
                company.data[key] = undefined;
            }
        }
        return {
            ...company.attributes,
            ...company.data,
            categories: company.categories,
            country: !!company.data.country ? company.data.country : undefined
        };
    };


    handleValuesChange = (changedValues) => {
        const companyToUpdate = this.getCompanyFromFields(this.props.company, changedValues);
        this.props.onValueChange(companyToUpdate);
    };

    openUserDetail = (userId: string) => {
        navigate('/reports/users/:userId', {userId});
    };


    shouldShowUsers = () =>
        getUserRole() === 'admin' && getOperationsForRole().companiesReport && this.props.company?.allUsers;

    render = () => {
        return (
            <Spin spinning={this.props.loading} delay={100}>
                {!!AppStore.appSettings.companyJsonFormSchema &&
                    <AppForm
                        formSubmitButtonRef={this.props.formSubmitButtonRef}
                        onSuccess={() => {
                            this.props.onComplete();
                        }}
                        data={this.getFieldsFromCompany(this.props.company)}
                        schema={this.getSchema()}
                        onChange={value => {
                            this.handleValuesChange(value);
                        }}
                    />}

                {this.shouldShowUsers() && (
                    <AvatarLinkList
                        title={__(T.titles.users)}
                        data={this.props.company?.allUsers.map((user: SelectElement): {description: string, id: string} => (
                          {id: user.id, description: user.enabled ? user.label : user.label + "   -   " + __(T.fields.suspended)})) ?? []}
                        onItemPressCallback={this.openUserDetail}
                    />
                )}
            </Spin>
        );
    };
}
