import React, { CSSProperties, RefObject, useEffect, useState } from 'react'
import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Radio,
  Row,
  Select,
  Spin,
  Switch,
  Tooltip,
} from 'antd'
import PlacesAutocomplete, { geocodeByAddress } from 'react-places-autocomplete'
import { FormItemProps, Rule } from 'antd/lib/form'
import TextArea from 'antd/lib/input/TextArea'
import { ColSize } from 'antd/lib/col'
import Text from 'antd/lib/typography/Text'
import api from 'api/api'
import { JsonSchema } from 'types'
import { QuestionCircleOutlined } from '@ant-design/icons'
import { __, T } from 'config/i18n'
import { FormInstance } from 'antd/es/form'
import { PropertyRules, Steps } from 'components'
import { Properties, SupplyChainStep } from 'api/types'
import AppStore from '../AppStore';
import { AxiosRequestConfig } from 'axios';


export type InputType =
  | 'checkbox'
  | 'text'
  | 'password'
  | 'number'
  | 'integer'
  | 'textarea'
  | 'select'
  | 'autocomplete'
  | 'date'
  | 'daterange'
  | 'datetime'
  | 'date-time'
  | 'multiple-file'
  | 'group'
  | 'form'
  | 'button'
  | 'array'
  | 'radio'
  | 'switch'
  | 'google-places-autocomplete'
  | 'supply-chain-steps'
  | 'properties-rule'
  | 'google-places-autocomplete-filtered-city'
  | 'autocomplete_company'

export interface ColProps {
  span?: number
  order?: number
  offset?: number
  push?: number
  pull?: number
  xs?: number | ColSize
  sm?: number | ColSize
  md?: number | ColSize
  lg?: number | ColSize
  xl?: number | ColSize
  xxl?: number | ColSize
  flex?: number
}

export interface Schema extends Partial<FormItemProps> {
  key?: string
  name: string | string[]
  type?: InputType
  decimal?: number
  defaultValue?: any
  label?: string
  placeholder?: string
  dateRangePlaceholders?: [string, string]
  col?: ColProps | number
  rules?: Rule[]
  options?: { label: string; value: any; tooltip?: string }[]
  fields?: any[]
  items?: Schema[]
  onClick?: () => void
  buttonType?: 'text' | 'link' | 'ghost' | 'default' | 'primary' | 'dashed'
  render?: () => JSX.Element
  hide?: boolean
  disabled?: boolean
  notInput?: boolean
  urlSearch?: string
  header?: AxiosRequestConfig
  descriptionKey?: string
  secondaryDescriptionKey?: string
  multipleAutocomplete?: boolean
  showSearch?: boolean
  inputAddonBefore?: string
  mode?: 'multiple' | 'tags' | undefined
  maxTagCount?: number
  onInputBlur?: () => void
  withButton?: boolean
  buttonLabel?: string
  onButtonClick?: () => void
}

export interface ButtonProps {
  text: string
  col?: number
  offset?: number
  style?: React.CSSProperties
  disabled?: boolean
  onPress?: () => void
}

interface Props {
  schema: Schema[]
  values?: any
  onValuesChange?: (changedValues: any, values: any) => void
  submitButton?: ButtonProps
  resetButton?: ButtonProps
  secondaryButton?: ButtonProps
  className?: string
  style?: CSSProperties
  title?: string
  formRef?: RefObject<FormInstance>
  buttonJustify?: 'start' | 'end' | 'center' | 'space-around' | 'space-between' | undefined
  removeElement?: (index: number) => void
  addSteps?: (steps: SupplyChainStep[]) => void
  addException?: () => void
  properties?: Properties[]
  uniqueProperty?: boolean
  reorderElement?: (start: number, end: number) => void
}

export const convertJsonSchema = (jsonSchema: JsonSchema) => {
  let schema: Schema[] = []

  function getType(type: any): InputType {
    switch (type) {
      case 'integer':
        return 'number'
      case 'date-time':
        return 'datetime'
      case 'array':
        return 'select'
      case 'boolean':
        return 'checkbox'
      default:
        return type
    }
  }

  try {
    const { properties, required } = jsonSchema
    if (properties) {
      Object.keys(properties).forEach(key => {
        const prop = properties[key] as any
        schema.push({
          name: key,
          type: getType(prop.type),
          label: prop.label ?? key,
          placeholder: prop.description,
          defaultValue: prop.default,
          options: prop.items?.enum
            ? prop.items?.enum.map(x => {
                return { value: x, label: x }
              })
            : undefined,
          rules: required?.filter(r => r === key).length === 1 ? [{ required: true }] : undefined,
        })
      })
    }
  } catch (err) {}
  return schema
}

export default function AdvancedForm({
  schema,
  values,
  onValuesChange,
  submitButton,
  resetButton,
  secondaryButton,
  style,
  className,
  title,
  formRef,
  buttonJustify,
  removeElement,
  addSteps,
  addException,
  properties,
  uniqueProperty,
  reorderElement,
  ...props
}: Props) {
  const onFinishFailed = () => {
  }

  const [refresh, setrefresh] = useState(0)
  const [autocompleteFields, setautocompleteFields] = useState(
    schema
      .filter(f => {return (f.type === 'autocomplete' || f.type === 'autocomplete_company')})
      .map(field => {
        return {
          name: field.name,
          fetching: false,
          fetch: async (x: any) => {
            var anyField : any = field
            let urlAutoCompleteCompany = '';
            if (field.type === 'autocomplete_company') {
              urlAutoCompleteCompany = '&withOnBoardingCreatorCompanyId=true'
              if(AppStore.loggedUser?.company?.id) {
                urlAutoCompleteCompany += '&onBoardingCreatorCompanyId=' + (AppStore.loggedUser?.company?.id ?? '');
              }
            }
            let data = await api.get<any>(field.urlSearch!.replace('-X-', x) + urlAutoCompleteCompany);
            let newAutocompleteFields = [...autocompleteFields]
            let newData: any[] = anyField.additionalOptions ? [...anyField.additionalOptions] : []
            if (data.data === null) {
              newAutocompleteFields.find(f => f.name === field.name)!.data = newData as any[]
              setautocompleteFields(newAutocompleteFields)
              return newData as any[]
            }
            if (data.status === 200 && Array.isArray(data.data.content)) {
              newData.push(...data.data?.content)
              newAutocompleteFields.find(f => f.name === field.name)!.data = newData
              setautocompleteFields(newAutocompleteFields)
              return newData || ([] as any[])
            } else if (data.status === 200 && Array.isArray(data.data)) {
              newData.push(...data.data)
              newAutocompleteFields.find(f => f.name === field.name)!.data = newData
              setautocompleteFields(newAutocompleteFields)
              return newData || ([] as any[])
            }
            newAutocompleteFields.find(f => f.name === field.name)!.data = newData as any[]
            setautocompleteFields(newAutocompleteFields)
            return newData as any[]
          },
          onChange: (x: any) => {
            let newAutocompleteFields = [...autocompleteFields]
            newAutocompleteFields.find(f => f.name === field.name)!.data = []
            setautocompleteFields(newAutocompleteFields)
          },
          data: [] as any[],
        }
      })
  )

  const [placesAutocompleteFields] = useState(
    schema
      .filter(f => f.type === 'google-places-autocomplete' ||  f.type === 'google-places-autocomplete-filtered-city')
      .map(field => {
        return {
          name: field.name,
          input: '',
          googleOnChange: async (selectedPlace: any, formValues: any) => {
            const result = await geocodeByAddress(selectedPlace)
            if(field.name === 'city' && result && result.length>0 && result[0].address_components){
              selectedPlace =result[0].address_components[0].long_name
            }
            if(field.name === 'country' && result && result.length>0 && result[0].address_components){
              selectedPlace = result[0].address_components[0].short_name + " - " +result[0].address_components[0].long_name

            }
            let newValues = { ...formValues }
            newValues[Array.isArray(field.name) ? field.name[field.name.length - 1] : field.name] = selectedPlace
            newValues[field.name + '-result'] = result[0]
            newValues['address-result'] = result[0]
            onValuesChange && onValuesChange({ [Array.isArray(field.name) ? field.name[field.name.length - 1] : field.name]: selectedPlace }, newValues)
          },
        }
      })
  )

  useEffect(() => {
    const loadInitialData = async () => {
      const promises = autocompleteFields.map(a => async () => {
        a.data = await a.fetch('')
      })
      await Promise.all(promises.map(p => p()))
      setautocompleteFields(autocompleteFields)
      setrefresh(refresh + 1)
    }
    if (refresh === 0) {
      loadInitialData()
    }
  }, [autocompleteFields, setrefresh, refresh, setautocompleteFields])

  const getFormItemProperties = (inputSchema: Schema) => {
    return {
      label:inputSchema.label!,
      name:inputSchema.name,
      rules:inputSchema.rules
    }
  }

  const getAutocompleteInput = (index: number, inputSchema: Schema) => {
    const { fetching, fetch, onChange, data } = autocompleteFields.find(f => f.name === inputSchema.name)!
    const autocompleteInput = (
      <Form.Item key={index} {...getFormItemProperties(inputSchema)}>
        <Select
          labelInValue
          allowClear
          showSearch={true}
          mode={inputSchema.multipleAutocomplete ? 'multiple' : undefined}
          placeholder={inputSchema.placeholder}
          notFoundContent={fetching ? <Spin size="small" /> : null}
          filterOption={false}
          onSearch={fetch}
          onChange={onChange}
          style={{ width: '100%' }}
          onFocus={_event => {
            fetch('')
          }}
        >
          {data.map(dataItem => (
            <Select.Option key={dataItem.value} value={dataItem.value}>
              {dataItem.label}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
    )
    const withAddButton = (autocomplete: JSX.Element) => (
      <Row gutter={5}>
        <Col span={22}>{autocomplete}</Col>
        <Col span={2} style={{ paddingTop: '30px' }}>
          <Button type="primary" onClick={inputSchema.onButtonClick}>
            {inputSchema.buttonLabel}
          </Button>
        </Col>
      </Row>
    )
    return inputSchema.withButton ? withAddButton(autocompleteInput) : autocompleteInput
  }

  const getButtonInput = (index: number, inputSchema: Schema) => (
    <Form.Item key={index} {...getFormItemProperties(inputSchema)}>
      <Button type={inputSchema.buttonType} onClick={inputSchema.onClick}>
        {inputSchema.placeholder}
      </Button>
    </Form.Item>
  )

  const getCheckboxInput = (index: number, inputSchema: Schema) => (
    <Form.Item key={index} {...getFormItemProperties(inputSchema)} valuePropName="checked">
      <Checkbox disabled={inputSchema.disabled} defaultChecked={inputSchema.defaultValue}>
        {inputSchema.placeholder}
      </Checkbox>
    </Form.Item>
  )

  const getDateInput = (index: number, inputSchema: Schema) => (
    <Form.Item key={index} {...getFormItemProperties(inputSchema)}>
      <DatePicker format="DD.MM.YYYY" style={{ width: '100%' }} placeholder={inputSchema.placeholder} />
    </Form.Item>
  )

  const getDateRangeInput = (index: number, inputSchema: Schema) => (
    <Form.Item key={index} {...getFormItemProperties(inputSchema)}>
      <DatePicker.RangePicker
        format="DD.MM.YYYY"
        style={{ width: '100%' }}
        placeholder={inputSchema.dateRangePlaceholders}
      />
    </Form.Item>
  )

  const getDateTimeInput = (index: number, inputSchema: Schema) => (
    <Form.Item key={index} {...getFormItemProperties(inputSchema)}>
      <DatePicker
        format="DD.MM.YYYY HH:mm"
        // showTime={{ defaultValue: moment('00:00:00', 'HH:mm:ss') }}
        disabled={inputSchema.disabled}
        style={{ width: '100%' }}
        placeholder={inputSchema.placeholder}
      />
    </Form.Item>
  )

  const getDefaultInput = (index: number, inputSchema: Schema) => (
    <Form.Item key={index} {...getFormItemProperties(inputSchema)}>
      <Input placeholder={inputSchema.placeholder || inputSchema.label} defaultValue={inputSchema.defaultValue} />
    </Form.Item>
  )

  const getDisabledInput = (index: number, inputSchema: Schema) => (
    <Form.Item key={index} {...getFormItemProperties(inputSchema)}>
      {inputSchema.type === 'password' ? <Input.Password disabled /> : <Input disabled />}
    </Form.Item>
  )

  const getGooglePlacesAutocompleteInput = (index: number, inputSchema: any) => {
    const { googleOnChange, input } = placesAutocompleteFields.find(f => f.name === inputSchema.name)!
    const searchPlaceholder = __(T.misc.insert)
    const loadingPlaceholder = __(T.misc.loading)
    return (
      <Form.Item key={index} {...getFormItemProperties(inputSchema)}>
        <PlacesAutocomplete
          value={input}
          onSelect={selectedPlace => {
            googleOnChange(selectedPlace, values)
          }}
          searchOptions={{ types: inputSchema.searchOptionsTypes ??  ['(cities)'] }}
          debounce={1200}
          key={Array.isArray(inputSchema.name) ? 'attributes-' + inputSchema.name[inputSchema.name.length - 1] : inputSchema.name}
        >
          {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
            <div>
              <input
                {...getInputProps({
                  placeholder: searchPlaceholder,
                  className: 'location-search-input ant-input',
                })}
              />
              <div className="autocomplete-dropdown-container">
                {loading && <div>{loadingPlaceholder}</div>}
                {suggestions.map((suggestion, i) => {
                  const className = suggestion.active ? 'suggestion-item--active' : 'suggestion-item'
                  // inline style for demonstration purpose
                  const style = suggestion.active
                    ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                    : { backgroundColor: '#ffffff', cursor: 'pointer' }
                  return (
                    <div
                      {...getSuggestionItemProps(suggestion, {
                        className,
                        style,
                      })}
                      key={'suggestion-' + i}
                    >
                      <span>{suggestion.description}</span>
                    </div>
                  )
                })}
              </div>
            </div>
          )}
        </PlacesAutocomplete>
      </Form.Item>
    )
  }
  // per far funzionare questo
  // abbiamo bisogno che nello schema ci sia anche il country
  const getGooglePlacesAutocompleteInputFilteredCity = (index: number, inputSchema: any) => {
    const { googleOnChange, input } = placesAutocompleteFields.find(f => f.name === inputSchema.name)!
    const searchPlaceholder = __(T.misc.insert)
    const loadingPlaceholder = __(T.misc.loading)
    return (
        <Form.Item key={index} {...getFormItemProperties(inputSchema)}>
          <PlacesAutocomplete
              value={input}
              onSelect={selectedPlace => {
                googleOnChange(selectedPlace, values)
              }}
              searchOptions= {  inputSchema.countryCode  ? { types: inputSchema.searchOptionsTypes ??  ['(cities)'],   componentRestrictions: {country: inputSchema.countryCode} }  : { types: inputSchema.searchOptionsTypes ??  ['(cities)'] }}
              debounce={1200}
              key={Array.isArray(inputSchema.name) ? 'attributes-' + inputSchema.name[inputSchema.name.length - 1] : inputSchema.name}
          >
            {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                <div>
                  <input
                      {...getInputProps({
                        placeholder: searchPlaceholder,
                        className: 'location-search-input ant-input',
                      })}
                  />
                  <div className="autocomplete-dropdown-container">
                    {loading && <div>{loadingPlaceholder}</div>}
                    {suggestions.map((suggestion, i) => {
                      const className = suggestion.active ? 'suggestion-item--active' : 'suggestion-item'
                      // inline style for demonstration purpose
                      const style = suggestion.active
                          ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                          : { backgroundColor: '#ffffff', cursor: 'pointer' }
                      return (
                          <div
                              {...getSuggestionItemProps(suggestion, {
                                className,
                                style,
                              })}
                              key={'suggestion-' + i}
                          >
                            <span>{suggestion.description}</span>
                          </div>
                      )
                    })}
                  </div>
                </div>
            )}
          </PlacesAutocomplete>
        </Form.Item>
    )
  }

  const getIntegerInput = (index: number, inputSchema: Schema) => (
    <Form.Item key={index} {...getFormItemProperties(inputSchema)}>
      <InputNumber
        defaultValue={inputSchema.defaultValue || 0}
        placeholder={inputSchema.placeholder}
        style={{ width: '100%' }}
      />
    </Form.Item>
  )

  const getPasswordInput = (index: number, inputSchema: Schema) => (
    <Form.Item key={index} {...getFormItemProperties(inputSchema)}>
      <Input.Password placeholder={inputSchema.placeholder} defaultValue={inputSchema.defaultValue} />
    </Form.Item>
  )

  const getRadioInput = (index: number, inputSchema: Schema) => (
    <Form.Item key={index} {...getFormItemProperties(inputSchema)}>
      <Radio.Group defaultValue={inputSchema.defaultValue} disabled={inputSchema.disabled}>
        {inputSchema.options?.map((option, i) => {
          return (
            <Radio key={'option-' + i} value={option.value}>
              {option.label}
              {option.tooltip && (
                <Tooltip title={option.tooltip}>
                  <QuestionCircleOutlined style={{ marginLeft: 5 }} />
                </Tooltip>
              )}
            </Radio>
          )
        })}
      </Radio.Group>
    </Form.Item>
  )

  const getSelectInput = (index: number, inputSchema: Schema) => (
    <Form.Item key={index} {...getFormItemProperties(inputSchema)}>
      <Select
        placeholder={inputSchema.placeholder}
        allowClear
        showSearch={inputSchema.showSearch}
        mode={inputSchema.mode || undefined}
        maxTagCount={inputSchema.maxTagCount || undefined}
        filterOption={(input, option) => {
          // if (option && option.children) return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          // return false
          return true;
        }}
      >
        {inputSchema.options &&
          inputSchema.options.map((opt, i) => (
            <Select.Option key={'option-select-' + i} value={opt.value}>
              {opt.label}
            </Select.Option>
          ))}
      </Select>
    </Form.Item>
  )

  const getSwitchInput = (index: number, inputSchema: Schema) => (
    <Form.Item key={index} {...getFormItemProperties(inputSchema)}>
      <Switch
        checked={values && values[Array.isArray(inputSchema.name) ? 'attributes-' + inputSchema.name[inputSchema.name.length - 1] : inputSchema.name]}
        defaultChecked={inputSchema.defaultValue}
        disabled={inputSchema.disabled}
        style={inputSchema.style}
      />
    </Form.Item>
  )

  const getTextInput = (index: number, inputSchema: Schema) => {
    return <Form.Item key={index} {...getFormItemProperties(inputSchema)}>
      <Input
          placeholder={inputSchema.placeholder || inputSchema.label}
          value={inputSchema.initialValue}
          disabled={inputSchema.disabled}
          onBlur={inputSchema.onInputBlur}
          addonBefore={inputSchema.inputAddonBefore}
      />
    </Form.Item>
  }

  const getTextAreaInput = (index: number, inputSchema: Schema) => (
    <Form.Item key={index} {...getFormItemProperties(inputSchema)}>
      <TextArea rows={4} disabled={inputSchema.disabled} placeholder={inputSchema.placeholder} />
    </Form.Item>
  )

  const getInput = (index: number, inputSchema: Schema) => {
    if (inputSchema.disabled) {
      return getDisabledInput(index, inputSchema)
    }
    switch (inputSchema.type) {
      case 'button':
        return getButtonInput(index, inputSchema)
      case 'text':
        return getTextInput(index, inputSchema)
      case 'password':
        return getPasswordInput(index, inputSchema)
      case 'checkbox':
        return getCheckboxInput(index, inputSchema)
      case 'number':
        if (inputSchema.disabled) {
          return <Text children={inputSchema.defaultValue} />
        } else {
          return getIntegerInput(index, inputSchema)
        }
      case 'integer':
        return getIntegerInput(index, inputSchema)
      case 'textarea':
        return getTextAreaInput(index, inputSchema)
      case 'date':
        return getDateInput(index, inputSchema)
      case 'datetime':
      case 'date-time':
        return getDateTimeInput(index, inputSchema)
      case 'daterange':
        return getDateRangeInput(index, inputSchema)
      case 'select':
        return getSelectInput(index, inputSchema)
      case 'radio':
        return getRadioInput(index, inputSchema)
      case 'autocomplete':
      case 'autocomplete_company':
        return getAutocompleteInput(index, inputSchema)
      case 'google-places-autocomplete':
        return getGooglePlacesAutocompleteInput(index, inputSchema)
      case 'google-places-autocomplete-filtered-city':
        return getGooglePlacesAutocompleteInputFilteredCity(index, inputSchema)
      case 'switch':
        return getSwitchInput(index, inputSchema)
      case 'supply-chain-steps':
        return (
          <Steps
            key={index}
            addSteps={addSteps}
            reorderElement={reorderElement}
            removeStep={removeElement}
            steps={values && values.steps ? values.steps : []}
          />
        )
      default:
        return getDefaultInput(index, inputSchema)
    }
  }

  // formRef?.current?.setFieldsValue(values)
  let [form] = Form.useForm()
  if (formRef?.current) {
    form = formRef?.current
  }
  //TODO: errore in console
  form.setFieldsValue(values)

  return (
    <Form
      ref={formRef}
      form={form}
      layout="vertical"
      onFinish={submitButton?.onPress}
      onFinishFailed={onFinishFailed}
      onValuesChange={onValuesChange}
      className={className}
      style={style}
      {...props}
    >
      {title && <h2>{title}</h2>}
      <Row gutter={24}>
        {schema
          .filter(s => !s.hide)
          .map((schema, index) => {
            const span = typeof schema.col === 'number' ? schema.col : 24
            const colProps: ColProps = schema.col && typeof schema.col === 'object' ? schema.col : {}
            return (
              <Col key={index} span={span} {...colProps}>
                {schema.render && (
                  <Form.Item key={index} {...schema}>
                    {schema.render()}
                  </Form.Item>
                )}
                {schema.type === 'properties-rule' && (
                  <PropertyRules
                    addException={addException}
                    remove={removeElement}
                    properties={properties || []}
                    rule={values}
                    uniqueProperty={uniqueProperty}
                  />
                )}
                {!schema.notInput && !schema.render && getInput(index, schema)}
              </Col>
            )
          })}
      </Row>
      {(submitButton || secondaryButton || resetButton) && (
        <Row gutter={24} justify={buttonJustify || 'start'}>
          {secondaryButton && (
            <Col span={secondaryButton.col ?? 24} offset={secondaryButton.offset ?? 0}>
              <Form.Item style={{ marginBottom: 0 }}>
                <Button
                  type="default"
                  htmlType="button"
                  style={{ ...{ width: '100%' }, ...secondaryButton.style }}
                  onClick={secondaryButton.onPress}
                  disabled={secondaryButton.disabled}
                >
                  {secondaryButton.text}
                </Button>
              </Form.Item>
            </Col>
          )}
          {resetButton && (
            <Col span={resetButton.col ?? 24} offset={resetButton.offset ?? 0}>
              <Form.Item style={{ marginBottom: 0 }}>
                <Button
                  type="default"
                  htmlType="reset"
                  style={{ ...{ width: '100%' }, ...resetButton.style }}
                  onClick={resetButton.onPress}
                  disabled={resetButton.disabled}
                >
                  {resetButton.text}
                </Button>
              </Form.Item>
            </Col>
          )}
          {submitButton && (
            <Col span={submitButton.col ?? 24} offset={submitButton.offset ?? 0}>
              <Form.Item style={{ marginBottom: 0 }}>
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{ ...{ width: '100%' }, ...submitButton.style }}
                  disabled={submitButton.disabled}
                >
                  {submitButton.text}
                </Button>
              </Form.Item>
            </Col>
          )}
        </Row>
      )}
    </Form>
  )
}
