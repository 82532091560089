import {Company, CreateSupplierCommand} from 'api/types'
import {AxiosRequestConfig} from 'axios'
import api, {responseErrorCheck} from './api'

class Suppliers {
  protected endpoint = 'profilemanager/v1/suppliers'

  async create(command: CreateSupplierCommand, axiosConfig?: AxiosRequestConfig): Promise<any> {
    const res = await api.post(`${this.endpoint}`, command, axiosConfig)
    return responseErrorCheck(res, false)
  }

  async update(id: string, company: Partial<Company>, axiosConfig?: AxiosRequestConfig): Promise<any> {
    const res = await api.put(`${this.endpoint}/${id}`, company, axiosConfig)
    return responseErrorCheck(res, false)
  }

  async findTenantCompanyCodeByCompanyId(companyId: string, axiosConfig?: AxiosRequestConfig) {
    const res = await api.get<string>(`${this.endpoint}/${companyId}/companycode`, axiosConfig)
    return responseErrorCheck(res)
  }
}

const suppliers = new Suppliers()
export { suppliers as default }

