import React, { Component } from 'react'
import { Line } from '@ant-design/plots';



interface State {
    onBoardingId?: string
    currentStep: number
    loading: boolean
}



export default class StepLinePlot extends Component<{}, State> {
    state: State = {
        currentStep: 0,
        loading: false,
    }


    shoot = () => {
        this.setState({});
    }

    demoLine = () => {
        let num = Math.floor(Math.random() * 100)
        let data = [
            {
                year: '1991',
                value: num,
            },
            {
                year: '1992',
                value: num * 2,
            },
            {
                year: '1993',
                value: num * 4,
            },
            {
                year: '1994',
                value: num - 2,
            },
            {
                year: '1995',
                value: num * 1.1,
            },
            {
                year: '1996',
                value: num * 2,
            },
            {
                year: '1997',
                value: num * num,
            },
            {
                year: '1998',
                value: 9,
            },
            {
                year: '1999',
                value: 13,
            },
            {
                year: '1999',
                value: 8,
            },
        ];
        const config = {
            data,
            xField: 'year',
            yField: 'value',
            stepType: 'vh',
        };
        return <>
                <button onClick={this.shoot}>Take the Shot!</button>
                <Line {...config} />
        </>
    };

    render() {
        return (
            this.demoLine()
        )
    }
}






