import React, { createRef } from 'react';
import { __, T } from '../../config/i18n';
import OrderAssignmentForm from '../../components/orders/OrderAssignmentForm';
import { Button, Progress } from 'antd';
import { Download, DownloadStatus } from '../../api/types';
import DOMPurify from 'dompurify';


const renderHTML = (rawHTML: string) => React.createElement('div', { dangerouslySetInnerHTML: { __html: DOMPurify.sanitize(rawHTML) } });

interface Props {
  download: Download;
  runDownload: (id: string) => void;
}

export function getIconByType(downloadStatus: DownloadStatus) {
  if (downloadStatus) {
    if (downloadStatus === 'IN_PROGRESS') {
      return require('assets/images/Assignement-reminder.svg').default;
    }
    if (downloadStatus === 'COMPLETED') {
      return require('assets/images/Reference-Completed.svg').default;
    }
    if (downloadStatus === 'IN_ERROR') {
      return require('assets/images/Scope-Cert-Expiring.svg').default;
    }
    if (downloadStatus === 'NEW') {
      return require('assets/images/Welcome.svg').default;
    }
  }
  return '';
}


interface State {
}


export default class DownloadCard extends React.Component<Props, State> {
  orderAssignmentFormRef = createRef<OrderAssignmentForm>();

  getStatusLabel(status: string): string {
    switch (status) {
      case 'NEW':
        return __(T.fields['new']);
      case 'IN_PROGRESS':
        return __(T.fields['inProgress']);
      case 'inError':
        return __(T.fields['inError']);
      case 'COMPLETED':
        return __(T.fields['completed']);
      default:
        return __(T.fields['unknownState']);
    }
  }

  render = () => {
    // this.props.download.lastPage  : this.props.download.totalPage =  x : 100
    let percentCompletion: number = 0;
    if (this.props.download.totalPage !== 0) {
      percentCompletion = Math.floor((this.props.download.lastPage * 100) / this.props.download.totalPage);
    }
    return (
      <div className="noticeCard">
        <div className="noticeCardIcon">
          <img src={getIconByType(this.props.download.status)} alt={__(T.misc.logo)} />
        </div>
        <div className="noticeCardData">
          <div className="noticeCardHeader">
            <div className="noticeCardTitle">
              {this.props.download.fileName}
            </div>
          </div>
          <div className="noticeCardBody">
            {renderHTML(this.getStatusLabel(this.props.download.status))}
            <div>
              {__(T.fields.createdAt)} {this.props.download.creationDate}
            </div>
            <div>
              {__(T.fields.updatedAt)} {this.props.download.lastUpdate}
            </div>
            <div style={{ marginRight: '0', width: '80%' }}>
              <Progress percent={percentCompletion} status="active" />
            </div>
            <Button
              type="primary"
              disabled={this.props.download.status !== 'COMPLETED'}
              onClick={() => this.props.runDownload(this.props.download.id)}
            >
              {__(T.buttons.download)}
            </Button>
          </div>
        </div>
      </div>
    );


  };

}
