import React from 'react'
import { Button, Table, Tooltip } from 'antd'
import { EmptyListText, Text } from '../../components'
import { __, T } from '../../config/i18n'
import { ColumnsType } from 'antd/lib/table'
import { Certification } from '../../api/types'
import moment from 'moment'
import ScopeCertificateStatusTag from './ScopeCertificateStatusTag'
import { Certifications, Files } from '../../api'
import { KeyGenerator } from "../../tools/KeyGenerator";

interface Props {
  certifications: Certification[]
}

interface State {
  currentPage: number
}

export default class ScopeCertificatesTab extends React.Component<Props, State> {
  state: State = {
    currentPage: 1,
  }

  private keyGenerator: KeyGenerator = new KeyGenerator();

  downloadFile = async (certification: Certification) => {
    try {
      const certificationUrl = certification.data.fileId
        ? ((await Files.getTemporaryUrl(certification.data.fileId)) as string)
        : ((await Certifications.getDownloadLink(certification.id)).data as string)
      window.open(certificationUrl)
    } catch (error) {}
  }

  getColumns = (): ColumnsType<Certification> => {
    return [
      {
        title: __(T.fields.filename),
        render: (text, record: Certification) => {
          return (
            <Tooltip title={__(T.misc.download_file)}>
              <Button
                type="link"
                onClick={async _ => {
                  await this.downloadFile(record)
                }}
              >
                {record.data.filename}
              </Button>
            </Tooltip>
          )
        },
      },
      {
        title: __(T.fields.type),
        render: (text, record: Certification) => <Text>{__(record.data.certificateType)}</Text>,
      },
      {
        title: __(T.fields.upload_date),
        render: (text, record: Certification) => <Text>{moment(record.lastUpdate).format('DD.MM.YYYY')}</Text>,
      },
      {
        title: __(T.fields.expiration_date),
        render: (text, record: Certification) => <Text>{moment(record.data.expiration).format('DD.MM.YYYY')}</Text>,
      },
      {
        title: __(T.fields.certificate_number),
        render: (text, record: Certification) => <Text>{record.data.certificateNumber}</Text>,
      },
      {
        title: __(T.fields.company_name),
        render: (text, record: Certification) => <Text>{record.data.companyName}</Text>,
      },
      {
        title: __(T.fields.status),
        render: (text, record: Certification) => (
          <ScopeCertificateStatusTag
            status={record.data.certificateStatus}
            style={{ width: '100%', paddingTop: '5px', paddingBottom: '5px', textAlign: 'center' }}
          />
        ),
      },
    ]
  }

  render = () => {
    const { certifications } = this.props
    const { currentPage } = this.state
    return (
      <Table
        key={this.keyGenerator.getKey()}
        bordered
        columns={this.getColumns()}
        dataSource={certifications}
        locale={{ emptyText: <EmptyListText message={__(T.messages.no_certificate_found)} /> }}
        pagination={{
          total: certifications.length,
          current: currentPage,
          pageSize: 5,
          onChange: this.switchPage,
        }}
        scroll={{ x: true }}
      />
    )
  }

  switchPage = (currentPage: number) => this.setState({ currentPage })
}
