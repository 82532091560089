import React, { Component } from 'react'
import { __, T } from 'config/i18n'
import { Button, Typography } from 'antd'
import { View, Step, StepModal } from 'components'
import { SupplyChainStep } from 'api/types'
import theme from 'theme/theme'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'

interface Props {
  steps: SupplyChainStep[]
  removeStep?: (index: number) => void
  addSteps?: (steps: SupplyChainStep[]) => void
  reorderElement?: (start: number, end: number) => void
}

interface State {
  modalVisible: boolean
}

const { Text } = Typography

export default class Steps extends Component<Props> {
  state: State = {
    modalVisible: false,
  }

  managementModal = () => {
    this.setState({ modalVisible: !this.state.modalVisible })
  }

  confirmSteps = (steps: SupplyChainStep[]) => {
    const { addSteps } = this.props
    this.managementModal()
    addSteps && addSteps(steps)
  }

  onDragEnd = result => {
    const { reorderElement } = this.props
    if (result.destination && reorderElement) {
      reorderElement(result.source.index, result.destination.index)
    }
  }

  getItemStyle = (isDragging, draggableStyle) => {
    return {
      // some basic styles to make the items look a bit nicer
      userSelect: 'none',

      // change background colour if dragging
      background: isDragging ? 'white' : 'white',

      // styles we need to apply on draggables
      ...draggableStyle,
    }
  }

  getListStyle = isDraggingOver => ({
    background: isDraggingOver ? 'white' : 'white',
  })

  render = () => {
    const { steps, removeStep } = this.props
    const { modalVisible } = this.state
    return (
      <>
        <View>
          <View style={{ marginBottom: 25 }}>
            <Text>{__(T.misc.supply_chain_network)}</Text>
            <Button
              style={{ ...{ marginLeft: 25, paddingLeft: 25, paddingRight: 25 }, ...theme.temeraSecondaryDarkButton }}
              type="default"
              onClick={() => this.managementModal()}
            >
              {__(T.misc.add_step)}
            </Button>
          </View>
          {steps.length > 0 && (
            <View style={{ marginLeft: 30, width: '60%' }}>
              <DragDropContext onDragEnd={this.onDragEnd}>
                <Droppable droppableId="droppable">
                  {(provided, snapshot) => (
                    <div
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                      style={this.getListStyle(snapshot.isDraggingOver)}
                    >
                      {steps.map((step, i) =>
                        step && step !== null ? (
                          <Draggable key={step.id} draggableId={step.id} index={i}>
                            {(provided1, snapshot1) => (
                              <div
                                ref={provided1.innerRef}
                                {...provided1.draggableProps}
                                {...provided1.dragHandleProps}
                                style={this.getItemStyle(snapshot1.isDragging, provided1.draggableProps.style)}
                              >
                                <Step key={'step_' + step.id} index={i} step={step} removeStep={removeStep} />
                              </div>
                            )}
                          </Draggable>
                        ) : null
                      )}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </View>
          )}
        </View>
        <StepModal
          confirm={this.confirmSteps}
          currentSteps={steps}
          closed={this.managementModal}
          visible={modalVisible}
        />
      </>
    )
  }
}
