import React, {RefObject} from 'react';
import {withTheme} from '@rjsf/core';
import {Theme as AntDTheme} from '@rjsf/antd';
import {ArrayFieldTemplate, ObjectFieldTemplate} from '../JsonSchemaObjectFieldTemplate';
import Companies from '../../api/Companies';
import AppStore from '../../AppStore';
import {Company} from '../../api/types';
import {__, __NoDefault} from '../../config/i18n';
import {AutoComplete, Checkbox, DatePicker, Input, InputNumber, Select, Spin} from 'antd'
import TCareUploadWidget from '../jsonSchemaWidget/TCareUploadWidget';
import {
    validateMaxDate,
    validateMinDate,
    validateSplitSumLeq,
    validateSum,
    validateSumLeq
} from "./AppFormValidatorUtils";
import TextArea from "antd/es/input/TextArea";
import {setOffGlobalSpinningOnHeader} from "../../api/api";
import moment from "moment";


const Form = withTheme(AntDTheme);

interface State {
    data: any,
    schema: any,
    startValidating: boolean,
    isInitLoading: boolean
}

interface Props {
    data: any,
    schema: any,
    onSuccess?: (data?: any) => void
    onChange?: (data?: any) => void
    onError?: () => void
    formSubmitButtonRef?: RefObject<HTMLButtonElement>
    readonly?: boolean
    noValidate?: boolean
    ownerCompanyId?: string
    formContext?: any
    filters?: { [key: string]: any }
}


const TCareFileWidget = (inputProps) => {

    return (
        <TCareUploadWidget inputProps={inputProps}/>
    );
};


const TCareSelectWidget = (inputProps: any) => {
    let allOptions: { label: string, value: string }[];
    let mode: 'multiple' | 'tags' | undefined = undefined;
    if (inputProps.schema.type === 'string') {
        allOptions = inputProps.schema.enum
            .map(v => {
                var label = v;
                if (inputProps.schema.enumLabel && inputProps.schema.enumLabel[v]) {
                    label = inputProps.schema.enumLabel[v];
                }
                return {label: __NoDefault(label) ?? __NoDefault(v) ?? label, value: v};
            });
    } else if (inputProps.schema.type === 'array') {
        allOptions = inputProps.schema.items.enum
            .map(v => {
                var label = v;
                if (inputProps.schema.items.enumLabel && inputProps.schema.items.enumLabel[v]) {
                    label = inputProps.schema.items.enumLabel[v];
                }
                return {label: __NoDefault(label) ?? __NoDefault(v) ?? label, value: v};
            });
        mode = 'multiple';
    } else {
        return <>type {inputProps.schema.type ?? 'undefined'} not managed in
            SelectWidget: {JSON.stringify(inputProps.schema)}</>
    }

    allOptions = allOptions.sort((a, b) => (a.label > b.label) ? 1 : ((b.label > a.label) ? -1 : 0))

    return (
        <Select
            mode={mode}
            showSearch
            filterOption={(key, opt) => {
                return !!opt && opt.label.toLowerCase().indexOf(key.toLowerCase()) > -1
            }}
            style={{width: '100%'}}
            onChange={(value) => {
                if (!!value) {
                    inputProps.onChange(value);
                } else {
                    inputProps.onChange(undefined);
                }
            }}
            maxLength={inputProps.schema.maxLength}
            options={allOptions}
            value={inputProps.value}
            disabled={inputProps.schema.readOnly || inputProps.disabled}
            allowClear
        />
    );
};

const TCareCheckboxWidget = (inputProps: any) => {
    return <Checkbox checked={inputProps.value}
                     onChange={(value) => {
                         inputProps.onChange(value.target.checked);
                     }}
                     disabled={inputProps.schema.readOnly || inputProps.disabled}
                     style={{width: '100%'}}>
        {!!inputProps.value && inputProps.schema.checkedColor && <label
            style={{color: inputProps.schema.checkedColor}}>{inputProps.label}</label>}
        {!inputProps.value && inputProps.schema.uncheckedColor && <label
            style={{color: inputProps.schema.uncheckedColor}}>{inputProps.label}</label>}
        {!inputProps.schema.checkedColor && !inputProps.schema.uncheckedColor && inputProps.label}
    </Checkbox>
}

const TCareTextWidget = (inputProps: any) => {
    if (!!inputProps.schema.suggestions) {
        let allOptions: [] = inputProps.schema.suggestions
            .filter(v => {
                if (!!inputProps.value) {
                    return v.toLowerCase().includes(inputProps.value.toLowerCase());
                }
                return true;
            })
            .map(v => {
                return {label: __(v), value: v};
            });

        return (
            <AutoComplete
                dropdownMatchSelectWidth={500}
                style={{width: '100%'}}
                options={allOptions}
                onChange={(value) => {
                    if (!!value) {
                        inputProps.onChange(value);
                    } else {
                        inputProps.onChange(undefined);
                    }
                }}
                value={inputProps.value}
                disabled={inputProps.schema.readOnly || inputProps.disabled}
            >
                <Input.Search size="large" allowClear />
            </AutoComplete>
        );
    }

    if (inputProps.schema.type === 'integer') {
        return (
            <InputNumber value={inputProps.value}
                         onChange={(value) => {
                             if (!!value || value === 0) {
                                 inputProps.onChange(parseInt(value, 10));
                             } else {
                                 inputProps.onChange(undefined);
                             }
                         }}
                         disabled={inputProps.schema.readOnly || inputProps.disabled}
                         style={{width: '100%'}}
            />);

    }

    if (inputProps.schema.height) {
        return <TextArea
            value={inputProps.value}
            showCount={!!inputProps.schema.maxLength}
            maxLength={inputProps.schema.maxLength}
            onChange={(event) => {
                if (!!event.target.value) {
                    inputProps.onChange(event.target.value);
                } else {
                    inputProps.onChange(undefined);
                }
            }}
            style={{height: inputProps.schema.height, resize: 'none'}}
            disabled={inputProps.schema.readOnly || inputProps.disabled}
        />
    }
    if(inputProps.schema.isDate){
        return TCareDateWidget(inputProps);
    }

    return (
        <Input
            value={inputProps.value}
            onChange={(event) => {
                if (!!event.target.value) {
                    inputProps.onChange(event.target.value);
                } else {
                    inputProps.onChange(undefined);
                }
            }}
            maxLength={inputProps.schema.maxLength}
            disabled={inputProps.schema.readOnly || inputProps.disabled}
        />
    );
};


const TCareDateWidget = (inputProps) => {
    console.log(inputProps.value )
    const dateFormat = AppStore.appSettings.dateFormat ? AppStore.appSettings.dateFormat : "YYYY-MM-DD";
    return (
      <DatePicker
        value={inputProps.value ? moment(inputProps.value, "YYYY-MM-DD") : undefined}
        format={dateFormat}
        onChange={(date, dateString) => {
            const defaultFormattedDate = date != null ? date.format("YYYY-MM-DD") : null;
            inputProps.onChange(defaultFormattedDate);
        }}
        disabled={inputProps.schema.readOnly || inputProps.disabled}
        style={{ width: '100%' }}
      />
    );
};

const customWidgets = {
    TextWidget: TCareTextWidget,
    CheckboxWidget: TCareCheckboxWidget,
    SelectWidget: TCareSelectWidget,
    FileWidget: TCareFileWidget,
    DateWidget: TCareDateWidget
};

export default class AppForm extends React.Component<Props, State> {

    constructor(props) {
        super(props);
        this.state = {data: {}, schema: {}, startValidating: false, isInitLoading: true};
    }

    componentDidMount() {
        this.refreshSchemaAndData();
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any) {
        if (JSON.stringify(prevProps.data) !== JSON.stringify(this.props.data)) {
            this.refreshSchemaAndData();
        }
    }

    recurseValidate = (currentData, currentNode, currentError) => {
        if (currentNode.type === 'object') {
            if (currentNode.validation && currentNode.validation.length > 0) {
                currentNode.validation.forEach(validationRule => {
                    switch (validationRule.type) {
                        case "SUM": {
                            validateSum(validationRule, currentData, currentError)
                            break
                        }
                        case "SUM_LEQ": {
                            validateSumLeq(validationRule, currentData, currentError)
                            break
                        }
                        case "SPLIT_SUM_LEQ": {
                            validateSplitSumLeq(validationRule, currentData, currentError)
                            break
                        }
                        case "MIN_DATE": {
                            validateMinDate(validationRule, currentData, currentError)
                            break
                        }
                        case "MAX_DATE": {
                            validateMaxDate(validationRule, currentData, currentError)
                            break
                        }
                    }
                });
            }

            Object.keys(currentNode.properties).forEach(name => {
                let dataItem;
                let errorItem;
                if (currentData) {
                    dataItem = currentData[name];
                    errorItem = currentError[name];
                }
                this.recurseValidate(dataItem, currentNode.properties[name], errorItem);
            });
        } else if (currentNode.type === 'array') {

            if (!!currentData) {
                let index = 0;
                for (let dataItem of currentData) {
                    const errorItem = currentError[index];
                    this.recurseValidate(dataItem, currentNode.items, errorItem);
                    index++;
                }
            }

        }
    };

    transformErrors(errors) {
        return errors.filter(error => {
            if (error.name === "oneOf") {
                return false;
            }
            if (error.message === "should match \"then\" schema") {
                return false;
            }

            if (error.name === "const" && error.params && (error.params.allowedValue === true || error.params.allowedValue === false)) {
                return false;
            }
            return true;
        });
    }

    validate(formData, errors) {
        let schema = JSON.parse(JSON.stringify(this.props.schema));
        this.recurseValidate(formData, schema, errors);
        return this.clearErrors(errors)
    }

    clearErrors(errors): any {
        const result = {}
        for (let k of Object.keys(errors)) {
            if (k !== '__errors' && k !== 'addError') {
                result[k] = this.clearErrors(errors[k])
            }
            if (!!errors.__errors && errors.__errors.length > 0) {
                result['__errors'] = errors.__errors
            }
        }

        return result
    }

    refreshData() {
        this.setState({data: this.props.data ?? {}});
    }

    async refreshSchema() {
        const company = this.props.ownerCompanyId ? await Companies.get(this.props.ownerCompanyId, setOffGlobalSpinningOnHeader()) : undefined;

        let schemaElaborated = this.props.schema;
        if (!schemaElaborated) {
            schemaElaborated = {
                properties: {}
            };
        }
        await this.refreshSchemaRecursive(schemaElaborated, company);
        // console.log("refreshed schema:", JSON.stringify(schemaElaborated))
        this.setState({schema: schemaElaborated, isInitLoading: false});
    }

    refreshSchemaRecursive = async (schema: any, company?: Company) => {

        if (Array.isArray(schema)) {

            let array: any[] = schema;
            for (const item of array) {
                await this.refreshSchemaRecursive(item, company);
            }

        } else if (typeof schema === 'object') {
            if (schema.title) {
                schema.title = __(schema.title);
            }

            //Gestione per popolamento lista di companies
            if (schema.type === 'string' && schema.extra && schema.extra.companyCategoriesEnum) {
                if (!schema.enum) {
                    schema.enum = [];
                }

                const creationCompanyId = this.props.filters ? this.props.filters['creationCompanyId'] : null
                const onBoardingCreatorCompanyId = creationCompanyId ?? (company ? company.id : AppStore.loggedUser?.company?.id)

                let companyList = await Companies.searchAvailableCompanies({
                    withOnBoardingCreatorCompanyId: true,
                    onBoardingCreatorCompanyId,
                    categories: schema.extra.companyCategoriesEnum,
                    page: 0,
                    size: 100
                }, setOffGlobalSpinningOnHeader());
                for (let c of companyList) {
                    if (schema.enum.indexOf(c.label) < 0) {
                        schema.enum.push(c.label);
                    }
                }
                if(schema.enum.length === 0){
                    schema.enum.push("Undefined");
                }
            }

            if (schema.required) {
                schema.required = this.props.readonly ? [] : schema.required;
            }

            for(let key of Object.keys(schema)){
                if(schema[key]) {
                    await this.refreshSchemaRecursive(schema[key], company)
                }
            }

        } else {
            // value field, skip
        }

    }

    async refreshSchemaAndData() {
        this.setState({}, () => {
            this.refreshSchema();
            this.refreshData();
        });
    }

    handleSubmit = () => {
        if (this.props.onSuccess) {
            const {data} = this.state;
            delete data.currentUser
            this.props.onSuccess(data);
        }
    };

    async onChange(change
                       :
                       any
    ) {
        if (!change) {
            return;
        }

        const {schema} = this.props;
        const {data} = this.state;
        delete data.currentUser
        await this.mergeData(change.formData, data, schema.properties, schema.definitions);

        if (this.props.onChange) {
            delete data.currentUser
            this.props.onChange(data);
        }

        this.setState({data: data});
    }

    async mergeData(source, target, schemaProperties, definitions) {
        const changedKeys: string[] = [];
        for (let key of Object.keys(source)) {
            let valueSource = source[key];
            // Don't remove, it is right.
            if (valueSource === null) {
                valueSource = undefined;
            }
            if (Array.isArray(valueSource)) {
                if (!target[key]) {
                    target[key] = [];
                }
                for (let index = 0; index < valueSource.length; index++) {
                    if (!!schemaProperties[key] && !!schemaProperties[key].items && !!schemaProperties[key].items.properties) {
                        let targetItem;
                        if (index + 1 > target[key].length) {
                            targetItem = {};
                            target[key].push(targetItem);
                        } else {
                            targetItem = target[key][index];
                        }
                        await this.mergeData(valueSource[index], targetItem, schemaProperties[key].items.properties, definitions);
                    } else {
                        target[key] = valueSource;
                    }
                }
                if (target[key].length > valueSource.length) {
                    target[key].pop();
                }
            } else if (typeof valueSource === 'object') {
                if (!target[key]) {
                    target[key] = {};
                }
                if (!!schemaProperties[key]) {
                    if (!schemaProperties[key].properties && schemaProperties[key].$ref) {
                        let defName = schemaProperties[key].$ref.substring(14) // rimouvo #/definitions/
                        await this.mergeData(valueSource, target[key], definitions[defName].properties, definitions);
                    } else {
                        await this.mergeData(valueSource, target[key], schemaProperties[key].properties, definitions);
                    }
                }
            } else if (target[key] !== valueSource) {
                if (changedKeys.indexOf(key) === -1) {
                    const schemaProp = schemaProperties[key];
                    target[key] = valueSource;
                    changedKeys.push(...await this.manageSchemaPropertiesChange(key, target, schemaProp));
                } else {
                    console.log('key ' + key + ' ignored due to automatic change');
                }
            }
        }
    }

    async manageSchemaPropertiesChange(key, values, schemaProp): Promise<string[]> {
        const changedKeys: string[] = [];
        if (schemaProp && schemaProp.type === 'string' && schemaProp.extra && schemaProp.extra.mapField) {

            const value = values[key];
            if (value) {
                let result = await Companies.searchAvailableFullCompanies({
                    size: 1,
                    page: 0,
                    businessName: value,
                    withOnBoardingCreatorCompanyId: true,
                    onBoardingCreatorCompanyId: AppStore.loggedUser?.company?.id
                });
                if (result.length === 0) {
                    return changedKeys;
                }

                const company = result.find((item: Company) => item.data.businessName === value);

                if (!!company) {

                    schemaProp.extra.mapField
                        // .filter(mapping => !order.data[mapping.target])
                        .filter(mapping => {
                            const paths = mapping.source.split('.');
                            let currentObj = company;
                            for (let path of paths) {
                                currentObj = currentObj[path];
                                if (!currentObj) {
                                    return false;
                                }
                            }
                            return true;
                        })
                        // .filter(mapping => values[mapping.target])
                        .forEach(mapping => {
                            const paths = mapping.source.split('.');
                            let currentObj = company;
                            for (let path of paths) {
                                currentObj = currentObj[path];
                                if (!currentObj) {
                                    break;
                                }
                            }
                            if (currentObj) {
                                changedKeys.push(mapping.target);
                                values[mapping.target] = currentObj;
                            }
                        });
                }
            }
        }
        return changedKeys;
    }

    render = () => {


        const {formSubmitButtonRef, readonly, noValidate, formContext} = this.props;
        const {data, schema} = this.state;

        let role = AppStore.loggedUser?.lastUsedRole
        let categories = AppStore.loggedUser?.company?.categories ?? []

        let dataWithCurrentUser = {...data, currentUser: {role, categories}}

        return (
            <div className={'appForm'}>
                <Spin spinning={this.state.isInitLoading}>
                    <Form
                        ObjectFieldTemplate={ObjectFieldTemplate}
                        ArrayFieldTemplate={ArrayFieldTemplate}
                        schema={schema}
                        formData={dataWithCurrentUser}
                        showErrorList={false}
                        onSubmit={this.handleSubmit}
                        onChange={change => (this.onChange(change))}
                        disabled={readonly}
                        liveValidate={this.state.startValidating}
                        validate={(formData, errors) => this.validate(formData, errors)}
                        noValidate={noValidate}
                        widgets={customWidgets}
                        formContext={formContext}
                        transformErrors={(errorMessage) => this.transformErrors(errorMessage)}

                    >
                        <button style={{visibility: 'hidden', display: 'none'}} ref={formSubmitButtonRef} onClick={() => {
                            this.setState({startValidating: true})
                        }}/>
                    </Form>
                </Spin>
            </div>
        );
    };
}
