import React from 'react';
import {Button, Col, Row} from "antd";
import {CaretDownOutlined, CaretUpOutlined, CopyOutlined, DeleteOutlined} from "@ant-design/icons";
import {__, T} from "../config/i18n";


export function ArrayFieldTemplate(props: any) {
    const {TitleField, title, schema} = props
    const disableButtons = schema.disableButtons
    const showDuplicateButton = schema.showDuplicateButton ?? false;
    const numberedTitle = schema.numberedTitle ?? false;
    return (
        <div>
            {!numberedTitle && <TitleField title={title}/>}
            {props.items.map(element =>
                <>
                    {numberedTitle && <TitleField title={`${title} ${element.index+1}`}/>}
                    <div key={element.key} style={{width: "100%"}}>
                        <div style={{display: 'flex', flexDirection: 'row'}}>
                            <div style={{flexGrow: 1, padding: '0 20px'}}>
                                {element.children}
                            </div>
                            <div style={{flexGrow: 0, display: 'flex', flexDirection: 'column', gap: '8px'}}>
                                <Button type="primary"
                                        disabled={!element.hasRemove || disableButtons || props.disabled}
                                        onClick={element.onDropIndexClick(element.index)}>
                                    <DeleteOutlined/> {__(T.buttons.removeItem)}
                                </Button>
                                {showDuplicateButton &&
                                    <Button type="primary"
                                            disabled={!element.hasRemove || disableButtons || props.disabled}
                                            onClick={element.onAddIndexClick(element.index)}>
                                        <CopyOutlined/>{__("Duplicate")}
                                    </Button>
                                }

                                <div style={{display: 'flex', flexDirection: 'row', gap: '10px'}}>

                                    <Button type="ghost" style={{flexGrow: 1, backgroundColor: 'rgb(208, 208, 208)'}}
                                            disabled={!element.hasMoveUp || disableButtons || props.disabled}
                                            onClick={element.onReorderClick(element.index, element.index - 1)}>
                                        <CaretUpOutlined/>
                                    </Button>

                                    <Button type="ghost" style={{flexGrow: 1, backgroundColor: 'rgb(208, 208, 208)'}}
                                            disabled={!element.hasMoveDown || disableButtons || props.disabled}
                                            onClick={element.onReorderClick(element.index, element.index + 1)}>
                                        <CaretDownOutlined/>
                                    </Button>
                                </div>
                            </div>
                        </div>
                        <div style={{borderBottom: "1px solid #ececec", width: "100%", marginBottom: '20px'}}/>
                    </div>
                </>)}
            <div style={{display: 'flex', flexFlow: 'row-reverse'}}>
                <Button type="primary" onClick={props.onAddClick}
                        disabled={!props.canAdd || disableButtons || props.disabled}>{__(T.buttons.addItem)}</Button>
            </div>
        </div>

    );
}

export function ObjectFieldTemplate(input: any) {
    const {TitleField, properties, title, description} = input
    return (
        <div>
            <TitleField title={title}/>
            <Row gutter={16}>
                {properties.map(prop => (
                    <Col className="gutter-row"
                         span={!!prop.content.props.schema.span ? prop.content.props.schema.span * 2 : 12}
                         key={prop.content.key}>
                        {prop.content}

                    </Col>
                ))}
            </Row>
            {description}
        </div>
    );
}


