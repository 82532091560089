import AppStore from '../AppStore';
import { isAdmin } from '../helpers/permission';
import { EnquiryRejectReasons } from '../types';

export class EnquiryRejectReasonsSettingsTools {


  public getColorByCode(code: string): string | undefined {
    if (!AppStore.appSettings.enquiryRejectReasons) {
      return undefined;
    }
    const elementoTrovato = JSON.parse(AppStore.appSettings.enquiryRejectReasons).find(item => item['code'] === code);
    return elementoTrovato ? elementoTrovato['colorOpen'] : null;

  }


  public getEnquiryRejectReasons(traceType: string): string[] | undefined {
    if (!AppStore.appSettings.enquiryRejectReasons) {
      return undefined;
    }
    const enquiryRejectReasons: EnquiryRejectReasons[] = JSON.parse(AppStore.appSettings.enquiryRejectReasons);
    let rejectReasons: string [] = [];
    for (let i = 0; i < enquiryRejectReasons.length; i++) {
      if (isAdmin() && enquiryRejectReasons[i].visibleForAdmin && this.isPresentTraceType(enquiryRejectReasons[i], traceType)) {
        rejectReasons.push(enquiryRejectReasons[i].code);
      } else if (!isAdmin()
        && enquiryRejectReasons[i].visibleForSupplier
        && this.isPresentCategory(enquiryRejectReasons[i])
        && this.isPresentTraceType(enquiryRejectReasons[i], traceType)) {
        rejectReasons.push(enquiryRejectReasons[i].code);
      }
    }
    return rejectReasons;
  }


  private isPresentTraceType(enquiryRejectReasons: EnquiryRejectReasons, traceType: string): boolean {
    if (!!enquiryRejectReasons.traceTypes) {
      return enquiryRejectReasons.traceTypes.includes(traceType);
    }
    return true;
  }

  private isPresentCategory(enquiryRejectReasons: EnquiryRejectReasons): boolean {
    if (!!enquiryRejectReasons.categories) {
      if (!!AppStore.loggedUser && !!AppStore.loggedUser.company && !!AppStore.loggedUser.company.categories) {
        return AppStore.loggedUser?.company?.categories.some(element => enquiryRejectReasons.categories!.includes(element));
      }
    }
    return true;
  }
}


const enquiryRejectReasonsTools = new EnquiryRejectReasonsSettingsTools();
export { enquiryRejectReasonsTools as default };


