const labels = {
  languages: {
    it: 'Italiano',
    en: 'English',
    fr: 'Français'
  },
  modal: {
    downloads: {
      title: 'Downloads',
      empty: 'No downloads available'
    }
  },
  buttons: {
    yes: 'Yes',
    no: 'No',
    addItem: 'Add item',
    removeItem: 'Remove',
    all: 'All',
    unread: 'Unread',
    libraryNew_TRACE: 'New item',
    libraryNew_DOCUMENT: 'New document',
    library_upload_document: 'Upload document',
    libraryEdit_TRACE: 'Item’s detail',
    libraryEdit_DOCUMENT: 'Document’s detail',
    libraryList_TRACE: 'Library item list',
    libraryList_DOCUMENT: 'Documentation list',
    add: 'Add',
    add_certificate: 'Add Certificate',
    add_user: 'Add user',
    add_new_order: 'Add new enquiry',
    analytic: 'Analytics',
    reopen: 'Reopen',
    all_reopen: 'All reopen',
    reject: 'Reject',
    reassign: 'Re-assign',
    rejectSubOrder: 'Reject sub-order',
    all_reject: 'All reject',
    reset: 'Reset',
    approve: 'Approve',
    all_approve: 'All approve',
    assign: 'Assign',
    assign_now: 'Assign Now',
    base_template_xls: 'Base template .xls',
    bulk: 'Bulk actions',
    actions: 'actions',
    close: 'Close',
    edit: 'Edit',
    modify_attributes: 'Edit attributes',
    enquiries_xls: 'Enquiries .xls',
    export: 'Export',
    export_xls: 'Export .xls',
    from_library: 'From library',
    import_from_template_xls: 'From template .xls',
    import_from_library: 'Import from library',
    import_from_trace: 'From trace',
    import_trace_xls: 'Import Trace from .xls',
    items_library: 'Items library',
    download_xls_template: 'Download Template',
    download_qr_code: 'Download QR Code',
    back: 'Back',
    cancel: 'Cancel',
    unlock: 'Unlock',
    companies_detail: 'Companies Detail',
    companies_management: 'Suppliers Management',
    company_detail: 'Company Detail',
    company_management: 'Company Profile',
    confirm: 'Save',
    downloads_management: 'Downloads Management',
    next: 'Confirm',
    create: 'Create',
    create_new: 'Create new',
    create_new_by_vat_code: 'Create/Find by Vat code',
    find_or_create_by_vatCode: 'Create/Find by Vat code',
    generate: 'Generate',
    save_without_generate: 'Close',
    create_trace: 'Create trace',
    complete_trace: 'Complete trace',
    complete_all_trace: 'Complete all traces',
    dashboard: 'Home',
    delete: 'Delete',
    disable: 'Disable',
    disapprove: 'Disapprove',
    all_disapprove: 'All dispprove',
    documentation: 'Documentation',
    download_pdf: 'Download pdf',
    enable: 'Enable',
    export_trace_in_CSV: 'Export trace in CSV',
    forgot_pwd: 'Forgot Password',
    fulfill: 'Fulfill',
    invite: 'Invite',
    invite_new: 'Invite New',
    new: 'New',
    modify: 'Modify',
    modify_library: 'Modify library',
    login: 'Login',
    login_now: 'Login now!',
    logout: 'Logout',
    exit: 'Exit',
    modify_trace: 'Modify trace',
    mark_as_read: 'Mark as read',
    mark_as_unread: 'Mark as unread',
    new_subsupplier: 'New Subcontractor',
    new_user: 'New user',
    update_user: 'Update user',
    onboarding: 'Onboarding',
    open: 'Open',
    order_assignment: 'Enquiry Assignment',
    order_detail: 'Enquiry Detail',
    order_fulfillment: 'Enquiry Fulfillment',
    order_handmade: 'Edit enquiry',
    order_handmade_new: 'Create enquiry',
    orders_report: 'Enquiry Management',
    orders_report_dynamic: 'Management',
    preview: 'Preview',
    customer_orders_report: 'Customer Enquiry',
    raw_material_source_orders_report: 'Customer Enquiry',
    profile_managment: 'Profile',
    proceed: 'Proceed',
    registration_submit: 'Next',
    save: 'Save',
    import_and_complete: 'Import and Complete',
    import_and_save_draft: 'Import and Save Draft',
    select_file:'Select file',
    send: 'Send',
    open_downloads: 'Downloads',
    import: 'Import',
    import_library: 'Import From library',
    import_trace: 'Import Trace',
    save_draft: 'Save Draft',
    send_by_email_pdf: 'Send pdf by e.mail',
    subsuppliers: 'Subcontractors',
    suspend: 'Suspend',
    trace_detail: 'Trace detail',
    traces_report: 'Traces report',
    users_detail: 'User detail',
    users_management: 'Users management',
    view: 'View',
    show_logs: 'History',
    split: 'Split',
    remove: 'Remove',
    addAssignation: 'Add assignation',
    reset_filter: 'Reset filter',
    reset_password: 'Reset password',
    hide_trace: 'Hide trace',
    show_trace: 'Show trace',
    form_builder: 'Form builder',
    new_invitation: 'New Invitation',
    traceability_settings: 'Traceability Settings',
    all_notifications: 'All notifications',
    clone_order: "Clone this order",
    delete_order: "Delete this order",
    mark_all_as_read: 'Mark all as read',
    view_all_notifications: "View all notifications",
    overrideExistingDataInput: "Override existing data input in the trace",
    more_unread: "more unread",
    override_existing_data_input_sub_title: "The new trace will replace all existing data inserted",
    add_order: "Create new split",
    validation_test: 'Validation Test',
    import_onboarding_from_xls: 'Import',
    export_onboarding_template: 'Export onboarding template',
    block_order: 'Block order',
    unlock_order: 'Unlock Order',
    download: 'Download'
  },
  colors: {
    errorValidation: '#da7b28',
    rejected: '#D3ADF7',
    reopen: '#00FF00',
    handmade: '#19706d',
    split: '#9ED7FF',
    assigned: '#AED8FF',
    assigned_text: '#2A667C',
    cancelled: '#da6767',
    cancelled_text: '#571a1a',
    completed: '#75EBA8',
    completed_text: '#2C6A47',
    approved: '#0c29e7',
    approved_text: '#fff',
    draft: '#696969',
    draft_text: '#FFFFFF',
    fulfilled: '#FFEF61',
    fulfilled_text: '#755D2E',
    new: '#d0d0d0',
    new_text: '#828282',
    network_background: '#1890FF',
    network_text: '#FFFFFF',
    scope_certificate_expired_background: '#FF8474',
    scope_certificate_expired_text: '#71322A',
    scope_certificate_expiring_background: '#FFCD6C',
    scope_certificate_expiring_text: '#755D2E',
    scope_certificate_valid_background: '#75EBA8',
    scope_certificate_valid_text: '#2C6A47',
    scope_certificate_valid_true_background: '#75EBA8',
    scope_certificate_valid_true_text: '#2C6A47',
    scope_certificate_valid_false_background: '#FF8474',
    scope_certificate_valid_false_text: '#71322A',
    to_be_assigned: '#F4B75C',
    to_be_assigned_text: '#6A6A6A',
    gray_button: '#d0d0d0'
  },
  confirms: {
    add_user: 'Are you sure you want to add the user?',
    cancel_subsupplier: 'Confirm the cancellation of the Subcontractor',
    activate_subsupplier: 'Confirm the activation of the Subcontractor',
    confirm_operation: 'Are you sure you want to complete the operation?',
    confirm_or_go_back_to_change_the_data: 'Confirm or go back to change the data',
    delete_user: 'Are you sure you want to delete the user?',
    delete_users: 'Are you sure you want to delete the users?',
    delete_certificate: 'Are you sure you want to delete the certificate?',
    invite_company: 'You are creating a company. Verify the VAT number and insert a new record.',
    delete_assignment: 'Are you sure you want to delete this order from assignment? All other orders sharing the same parent order will be removed also.',
    delete_order: 'Are you sure you want to delete this order?',
    reset_password: 'Are you sure you want to reset password?',
    warning_on_company_create: 'La partita iva è già presente nella tua lista. Sei sicuro di voler continuare?',
    cancel_order: 'Are you sure you want to block this order?',
    unlock_order: 'Are you sure you want to unlock this order?'
  },
  errors: {
    no_file_selected: 'Please load a file to continue',
    password_not_valid: 'Password needs to be 6 to 20 characters which contain at least one numeric digit, one uppercase and one lowercase letter.',
    the_passwords_are_different: 'The password and confirmation password must be the same',
    insert_company_code_or_piva: 'You must specify at least one of Company Code or P. IVA / C.F.',
    identifier_creation_failed: 'Trace Identifier creation failed',
    trace_creation_failed: 'Trace creation failed',
    max_file_size_exceeded: 'Max file size exceeded',
    file_not_found: 'File not found',
    file_upload_failed: 'File upload failed',
    select_almost_one_reason: 'Select almost one reason'
  },
  network: {
    CO: 'Cotton',
    WO: 'Wool',
    PL: 'Poliestere',
    AF: 'Autres fibres',
    WD: 'Super kid mohair',
    WM: 'Mohair',
    NY: 'Nylon'
  },
  orderType: {
    BUY_FINISH_PRODUCT: 'Buy',
    MAKE_RAW_MATERIAL: 'Make material',
    MAKE_FINISH_PRODUCT: 'Make product',
    SINGLE_MATERIAL: 'Single material'
  },
  fields: {
    reassign: 'Re-assign',
    currentCompany: 'Current company',
    fileGeneratedOnComplete: 'File automatically generated on complete',
    lastModify:'Last modification date',
    lastModifiedUsername: 'Last user',
    version: 'Version',
    tag: 'Tag',
    productMatchCode: 'Match code',
    supplier: 'Supplier',
    orderType: 'Order Type',
    enquiryId: 'Enquiry ID',
    firstname: 'Firstname',
    lastname: 'Lastname',
    inviteCompany: 'Invite Company',
    action: 'Action',
    actions: 'Actions',
    address: 'Street / square or avenue',
    address_number: 'N°',
    adherence_ethics_code: 'Adherence to the code of ethics',
    assignee_company: 'Assignee',
    business_name: 'Business Name',
    legal_code: 'Legal Code',
    category: 'Category',
    categories: 'Categories',
    certifications: 'Certificazioni',
    city: 'City',
    certificate_number: 'Certificate Number',
    code: 'Code',
    collector: 'Collector',
    color: 'Color',
    color_code: 'Color Code',
    company: 'Company',
    companies: 'Companies',
    company_datas: 'Company datas',
    company_name: 'Company name',
    company_name_vat: 'Company Name - VAT',
    company_type: 'Company type',
    washington_attestation_data: 'Washington attestation data',
    country: 'Country',
    last_login_date: 'Last login date',
    creation_company: 'Supplier',
    creation_date: 'Creation Date',
    destination: 'Destination',
    document_expiration_date: 'Expiration date',
    document_item_article_name: 'Item’s name',
    document_item_name: 'Name',
    document_number: 'Document number',
    documents_and_certifications: 'Documents e Certifications',
    documents_for_nutrition: 'Documents for Nutrition',
    document_type: 'Document type',
    email: 'Email',
    email_address: 'Email address',
    email_pec: 'Email Pec',
    error_validation: "Error validation",
    es_10: 'es. 10',
    expiration_date: 'Expiration date',
    farmer: 'Farmer',
    feeding_documents: 'Documents for feeding',
    file: 'File',
    filename: 'Filename',
    health_certificate: 'Health certificate',
    hunter: 'Hunter',
    id: 'Id',
    intermediary_role: 'Intermediary role',
    insert_reference_data: 'Insert reference data',
    insert_file_name: 'Insert file name',
    history: 'History',
    library_item_name: 'Item’s name',
    label: 'Label',
    last_modify: 'Last modify',
    license_concession: 'Documents / Certifications',
    load_certificates: 'Load Certificates',
    load_scan_certificates: 'Load Certificates',
    load_ethic_certificate: 'Load Ethic Certificate',
    load_here: 'Click or drag file to this area to upload',
    lot: 'Lot',
    note: 'Note',
    not_matching_farmer: 'Not matching farmers',
    not_matching_slaughter: 'Not matching slaughters',
    manual: 'Manual',
    product: 'Material Descr. and Code',
    product_code: 'Material Code',
    product_composition: 'Material Composition',
    product_description: 'Material Description',
    rejected: 'Rejected',
    excel_row_counter: 'Number excel row read',
    updated_enquiry_counter: 'Number enquiries updated',
    not_updated_enquiry_counter: 'Number enquiries not updated',
    validation_error_enquiry_counter: 'Number row updated',
    not_update_number_row: 'Numbers list of rows not updated',
    season: 'Season',
    shared: 'Shared',
    shared_with_brand: 'Shared with brand',
    splitted: 'Splitted',
    event: 'Event',
    membership_association: 'Membership association',
    message: 'Message',
    name: 'Name',
    name_and_surname: 'Name and Surname',
    order_fiber: 'Component',
    order_networks: 'Supply Chain',
    status: 'Status',
    order_step: 'Step',
    other_certificates: 'Other certificates',
    password: 'Password',
    password_confirm: 'Confirm Password',
    phone: 'Phone',
    phone_number: 'Mobile Phone',
    piva_cf: 'Vat Number',
    postal_code: 'Postal Code',
    purchase_order: 'Purchase Order',
    parent_order_code: 'Parent Order Code',
    purchase_order_code: 'Purchase Order Code',
    purchase_order_date: 'Purchase Order Date',
    purchase_order_type: 'Type',
    purchase_date: 'Purchase Date',
    quantity: 'Quantity',
    quantity_allowed: 'Quantity allowed',
    referent: 'Referent',
    t1_filter: 'Onboarded By',
    sent_date: 'Sent Date',
    slaughterhouse: 'Slaughterhouse',
    style_code: 'Style Code',
    style_description: 'Style Description',
    subsupplier_name: 'Subcontractor Name',
    suppression_method: 'Suppression method (optional: write followed relevant legislation)',
    surname: 'Surname',
    tannery: 'Tannery',
    territory_of_competence: 'Territory of competence',
    trace_identifier: 'Trace Identifier',
    trace_type: 'Trace Type',
    type: 'Type',
    typology: 'Typology',
    upload_date: 'Upload Date',
    user: 'User',
    username: 'Username',
    veterinary: 'Veterinary',
    previous_traces: 'Previous Traces',
    existing_company: 'Existing Company',
    existing_trace: 'Existing Trace',
    new_trace_identifier: 'New Trace Identifier',
    company_code: 'Company Code',
    notification_filter: 'Search',
    or: 'Or',
    personal: 'Personal',
    third_part: 'Third Part',
    new_id: 'New ID',
    existing_id: 'Existing ID',
    supplyChainName: 'Generic component name',
    supplyChainFiber: 'Specific component codes',
    activate: 'Activated',
    start_date: 'Start date',
    end_date: 'End date',
    rule_description: 'Rule description',
    property: 'Property',
    value: 'Value',
    data: 'Data',
    date: 'Date',
    material_description: 'Material Description',
    material_code: 'Material Code',
    cancel_order_modal_title: 'Cancel order',
    cancel_order_modal_body: 'Do you want to cancel the order?',
    unlock_order_modal_title: 'Unlock order',
    unlock_order_modal_body: 'Do you want to unlock the order?',
    approve_order_modal_title: 'Approve order',
    approve_orders_modal_title: 'Approve orders',
    approve_order_modal_body: 'Do you want to approve the order?',
    approve_order_with_children_modal_body: 'Do you want to approve the order?<br> Proceeding will also approve all completed child enquiries.',
    approve_orders_modal_body: 'Do you want to approve <b>{{count}}</b> orders?',
    complete_orders_modal_title: 'Complete orders',
    complete_orders_modal_body: 'Do you want to complete <b>{{count}}</b> orders?',
    reopen_order_modal_title: 'You are reopen the enquiry',
    reopen_orders_modal_title: 'You are reopen {{count}} enquiries',
    reopen_order_modal_body: 'Do you want to reopen the enquiry?',
    reopen_orders_modal_body: 'Do you want to reopen <b>{{count}}</b> enquiries?',
    reject_order_modal_title: 'You are rejecting the enquiry<br>Please leave a feedback',
    reject_orders_modal_title: 'You are rejecting {{count}} enquiries<br>Please leave a feedback',
    reject_order_modal_body: 'Insert your feedback here (Max 400 characters)',
    reject_orders_modal_body: 'Insert your feedback here (Max 400 characters)',
    delete_order_modal_title: 'Remove order',
    delete_order_modal_body: 'Do you want to remove the enquiry?',
    disapprove_order_modal_title: 'Disapprove enquiry',
    disapprove_orders_modal_title: 'Disapprove enquiries',
    disapprove_order_modal_body: 'Do you want to disapprove the enquiry?',
    disapprove_orders_modal_body: 'Do you want to disapprove <b>{{count}}</b> enquiries?',
    confirmModalTitle: 'Confirm',
    confirmModalBody: 'You are submitting the declaration,  you will not be able to change it. Confirm?',
    confirmModalButton: 'Confirm Now',
    cancelModalButton: 'Continue editing',
    remove_library_title: 'Delete element',
    remove_library: 'Do you want to remove this element?',
    productionCity: 'Production City',
    count_to_do: 'To do',
    count_rejected: 'Rejected',
    inviteCompanyAndUser: 'Invite company and user by onboarding',
    addCompany: 'Add a company',
    addCompanyAndUser: 'add a new company and a user. The new user will fill in the data on the platform on behalf of the added company',
    addCompanyNoAccess: 'add a new company without any access to the platform. The current users will fill in the data on behalf of the new company',
    createdAt: 'Created at:',
    updatedAt: 'Updated at:',
    new: 'NEW',
    inProgress: 'IN PROGRESS',
    inError: 'IN ERROR',
    completed: 'COMPLETED',
    unknownState: 'UNKNOWN STATE',
    withoutUsers: 'Without Users',
    neverLoggedUsers: 'Never Logged Users',
    suspended: 'Suspended'
  },
  messages: {
    cloned: '[Cloned] ',
    account_login: 'Log in to your account',
    complete_order_by_bulk_title: 'Completion result',
    complete_order_by_bulk_body: '<b>Completed</b> enquiries: {{completedEnquiries}}<br><b>Not valid</b> enquiries: {{notValidEnquiries}}<br>',
    company_added: 'Company added',
    company_confirmed: 'Company confirmed',
    company_datas_updated: 'Company datas updated',
    company_disabled: 'Company disabled',
    company_enabled: 'Company enabled',
    confirm_or_go_back: 'Confirm or go back to check the data',
    field_required: 'Required field',
    email_sent: 'Email sent',
    order_assignment_error: 'There was an error assigning your purchase order',
    order_assignment_success: 'Your purchase order has been assigned',
    order_assignment_draft: 'Your purchase order has been saved',
    order_fulfillment_error: 'There was an error submitting your trace',
    order_fulfillment_success: 'Your trace has been submitted',
    order_fulfillment_draft: 'Your trace has been saved',
    order_complete_success: 'Your trace has been completed',
    order_completes_success: 'Your traces has been completed',
    order_reopen_success: 'Your trace has been reopened',
    order_remove_success: 'Your trace has been removed',
    orders_reopen_success: 'Your traces have been reopened',
    order_reject_success: 'Your trace has been rejected',
    orders_reject_success: 'Your traces have been rejected',
    order_approve_success: 'Your trace has been approved',
    orders_approve_success: 'Your traces have been approved',
    nothing_to_approve: 'No traces to approve',
    orders_disapprove_success: 'Your traces have been disapproved',
    order_disapprove_success: 'Your trace have been disapproved',
    orders_saved: 'Your trace has been saved',
    order_saved_draft_with_errors: 'Your trace has been saved on draft with validation errors',
    password_required: 'Password is required',
    subsupplier_deleted: 'Subcontractor deleted',
    subsupplier_disabled: 'Subcontractor disabled',
    subsupplier_enabled: 'Subcontractor enabled',
    successfully_registered: 'You have successfully registered.',
    successfully_registered_email: 'You will receive a confirmation email.',
    successfully_import_trace: 'Your trace has been imported',
    successfully_import_library: 'Your library has been imported',
    user_added: 'User added',
    user_confirmed: 'User confirmed',
    user_datas_updated: 'User datas updated',
    user_deleted: 'User deleted',
    user_disabled: 'User disabled',
    user_enabled: 'User enabled',
    user_updated: 'User updated',
    user_already_exist: 'Username already exist',
    username_required: 'Username is required',
    users_deleted: 'Users deleted',
    no_certificate_found: 'No Certificate Found',
    no_data_default: 'No Data Found',
    no_trace_found: 'No Trace Found',
    no_order_found: 'No Order Found',
    invitation_successfully_created: 'Invitation successfully created',
    onboarding_approved: 'Onboarding approved!',
    onboarding_business_name_already_exist_on_create: 'You can not add this supplier since one with the same business name already exists. If you want to add it to your favorites please refer to the “Supplier Management” section.',
    onboarding_company_exists: 'Onboarding company already exists',
    onboarding_user_already_exist: 'There is already a supplier with the same email.',
    onboarding_company_already_exist: 'There is already a supplier with the same vat code.',
    onboarding_byVatCode_company_already_exist: 'This VAT Number already exists, please check the current data to use it.',
    onboarding_business_name_already_exist_on_update: 'Esiste già un fornitore con la stessa ragione sociale.',
    business_name_cannot_be_identical_to_yours: 'The business name cannot be identical to yours.',
    profile_in_revision: 'Profile in revision',
    email_invitation_sent: 'Onboarding invitation sent again',
    onboarding_loaded: 'Onboarding created with success',
    attachment_loaded: 'Attachment loaded',
    select_trace_identifier_to_continue: 'Select trace identifier to continue',
    create_trace_expired_certifications: 'At least one of your certificates is expired. You can still create this trace but the system will keep track of this issue.',
    create_trace_subsupplier_expired_certifications: 'At least one of your subcontractor certificates is expired. You can still create this trace but the system will keep track of this issue.',
    new_id_tooltip: 'This type is used when you want to insert a track with new information.\nIt can also be related to the new track, of the tracks previously entered in the system',
    existing_id_tooltip: 'This type is used when you want to update the information relating to a previously created track.\nSearch for the identifier from the drop-down menu.',
    trace_created: 'Trace created!',
    certificate_deleted: 'Certificate deleted',
    supply_chain_saved: 'Supply chain data successfully saved',
    rule_saved: 'New rule was successfully saved',
    new_item: 'New item',
    new_item_saved: 'Creation item done',
    item_saved_title: 'Saving item',
    item_saved: 'Item was successfully updated',
    import_onboarding_from_xls_error_count_max_line: 'The file has more than 5 lines',
    error: 'Error'
  },
  category: {
    BREEDER: 'Breeder',
    HUNTER: 'Hunter',
    TANNERY: 'Tannery',
    SLAUGHTERHOUSE: 'Slaughterhouse',
    FARMER: 'Farmer',
    TRADER: 'Trader',
    PICKER: 'Picker',
    VETERINARY: 'Veterinary',
    MANUFACTURER: 'Manufacturer',
    PROCESSOR: 'Processor',
    WEAVER: 'Weaver',
    SPINNER: 'Spinner',
    GINNER: 'Ginner',
    RAW_MATERIAL_SOURCE: 'Raw material source',
    CUSTOMER: 'Customer',
    SCOURER: 'Scourer',
    TRASPORTATORE: 'Trasportatore',
    LAVANDERIA: 'Lavanderia',
    FORNITORE: 'Fornitore',
    TESSITORE: 'Tessitore',
    MANIFATTORE: 'Manifattore',
    FILATORE: 'Filatore',
    SMINUZZATORE: 'Sminuzzatore',
  },
  misc: {
    today: 'Today',
    previous: 'Previous',
    thisWeek: 'This week',
    minutesAgo: 'minutes Ago',
    hoursAgo: 'hours Ago',
    daysAgo: 'days ago',
    monthsAgo: 'months Ago',
    active: 'Active',
    all_orders: 'All Orders',
    assigned: 'Assigned',
    attached_max_sixe: 'Max 10MB (pdf, jpg, word, eps)',
    cancelled: 'Locked',
    completed: 'Completed',
    approved: 'Approved',
    choose_rejects_reasons: 'Choose the reason for rejection',
    create_new_document: 'Create new document',
    create_new_library_item: 'Create new library’s item',
    confirmed: 'Confirmed',
    congratulations: 'Congratulations',
    delete_selected: 'Delete selected',
    deselect: 'Deselect',
    draft: 'Draft',
    new: 'New',
    expiration: 'Expiration',
    expired: 'Expired',
    expiring: 'Expiring',
    error_change_current_user: 'The company has been changed, the page will be reloading on 10s with other company',
    free_search: 'Free search',
    fulfilled: 'Fulfilled',
    id_lot_etc: 'ID, Lot, etc.',
    insert: 'Insert',
    logo: 'Logo',
    no: 'No',
    personal_datas: 'Personal datas',
    powered_by: 'powered by',
    remember_me: 'Remember me',
    select: 'Select',
    select_all: 'Select All',
    select_an_option_and_proceed: 'Select an option and proceed',
    select_the_type_of_track: 'Select the type of trace',
    select_or_insert_new: 'Select or Insert new',
    select_scope_certificate: 'Select PDF, JPG, Word or EPS file (Max 5MB)',
    select_trace_order: 'Select file xlsx (Max 5MB)',
    show_selected: 'Show selected',
    supplier: 'Supplier',
    temera_com: 'temera.com',
    to_be_assigned: 'To be assigned',
    users: 'Users',
    valid: 'Valid',
    write: 'Write',
    yes: 'Yes',
    username_name_email: 'Username, name, email, etc.',
    start: 'Start',
    end: 'End',
    role: 'Role',
    subsupplier: 'Subcontractor',
    representative: 'Representative',
    business_name_email_etc: 'Business Name, email, etc.',
    invited: 'Invited',
    sent: 'Sent',
    disabled: 'Disabled',
    to_confirm: 'To confirm',
    or: 'Or',
    personal: 'Company',
    third_part: 'Third part',
    expired_certifications: 'Expired Certifications',
    warning: 'Warning',
    click_to_download: 'Click to download',
    download_file: 'Download file',
    show_filtered: 'Show filtered',
    show_all: 'Show all',
    loading: 'Loading...',
    error: 'Error',
    empty: 'Empty',
    insertSupplierMsg: 'Insert Supplier and PO informations for the assignment',
    main_configurations: 'Main configurations',
    main_configurations_title: 'Supply chain set up',
    main_configurations_subtitle: 'In this section you can manage t!Care supply chain',
    style_traceability: 'Style traceability',
    style_traceability_title: 'Style traceability',
    style_traceability_subtitle: 'In this section you can create different exceptions to select what to trace',
    new_fiber: 'New component',
    edit_fiber: 'Edit component',
    edit_attributes: 'Edit attributes',
    add_fiber: '+ Add component',
    add_step: 'Add step',
    add_step_selected: 'Add selected step',
    modal_step_title: 'Add new steps to the supply chain',
    search: 'Search',
    create_new_rule: 'Create new rule',
    add_new_property: 'Add new property',
    rules_active: 'Active',
    rules_archived: 'Archived',
    to: 'to',
    archive_rule_title: 'Archive rule',
    archive_rule_subtitle: 'Are you sure you want to archive this rule?',
    reopen_rule_title: 'Rule activation',
    reopen_rule_subtitle: 'Are you sure you want to activate this rule?',
    delete_rule_title: 'Delete rule',
    delete_rule_subtitle: 'Are you sure you want to delete this rule?',
    modal_detail_rule_title: 'Rule detail',
    supply_chain_network: 'Supply chain network',
    remove_step_title: 'Remove step',
    remove_step_subtitle: 'Are you sure you want to remove this step?',
    remove_supply_chain_title: 'Remove Supply chain',
    remove_supply_chain_subtitle: 'Are you sure you want to remove this Supply chain?',
    seasonEvents: 'Season event',
    styleCodes: 'Style code',
    companyIds: 'Supplier Name',
    percentage: 'Percentage',
    fiberCodes: 'Specific component',
    supplyChains: 'Supply chain',
    traceTypeIds: 'Step',
    chooseSupplier: 'Choose the supplier',
    insertPurchaseOrderAndDate: 'Insert purchase order codes and dates',
    insert_new_element: 'Insert new element'
  },
  steps: {
    trace_data: 'Trace data',
    complete_trace: 'Complete trace',
    confirm_data: 'Confirm your data',
    fill_form: 'Fill in the form',
    new_company: 'New Company',
    company: 'Company',
    user: 'User',
    new_user: 'New User',
    summary: 'Summary',
  },
  titles: {
    split: 'Split Enquiry',
    library_item_section_main: 'Item\'s detail',
    library_item_section_new_main: 'Insert reference data',
    library_item_section_data: 'Trace’s form',
    actions: 'Actions',
    attach_document: 'Attach Document',
    companies_to_confirm: 'Companies to confirm',
    company_data: 'Company Data',
    company_profile: 'Company Profile',
    company: 'Company',
    create_personal_trace: 'Create your personal trace',
    create_third_part_trace: 'You are creating a trace on behalf of ',
    document_item_section_main: 'Reference data',
    document_item_section_new_main: 'Insert reference data',
    document_item_section_data: 'Insert attachment',
    downloads: 'Downloads',
    order_assignment_error: 'Error',
    order_assignment_success: 'Success',
    order_fulfillment_error: 'Error',
    order_fulfillment_success: 'Success',
    order_complete_success: 'Success',
    purchase_order_details: 'Purchase order details',
    purchase_order_fulfillment: 'Trace data',
    scope_certificates: 'Certificates',
    scope_certificate: 'Scope Certificate',
    success: 'Success',
    trace_identifier: 'Trace identifier',
    trace_informations: 'Trace informations',
    trace_report: 'Trace report',
    users: 'Users',
    notification: 'Notification',
    notificationTitle: 'Notifications',
    import_trace_dialog: 'Select the enquiry to duplicate trace data',
    import_library_dialog: 'Select the library item to duplicate trace data',
    insert_email: 'Insert email address',
    new_library_dialog: 'Select item’s type to insert'
  },
  error: {
    splitOriginalOrderCompleted: 'Can\'t spit order because the original is completed!',
    forbidden: 'You don’t have the required permissions to access this resource',
    not_found: 'The requested resource does not exist',
    unauthorized: 'Authentication failed. Please log in and try again',
    missing_trace_identifier: 'Missing trace identifier',
    identifier_already_existing: 'This identifier already exist',
    generic_be_error: 'Something went wrong, retry later or contact the administrator',
    supply_chain_steps: 'To continue you must select at least one step',
    user_unauthorized_operation: 'The user not have the permission to modify the order'
  },
  success: {
    export_xls_title: 'Export',
    export_xls: 'The export has been taken care of; you will receive the notification shortly.'
  },
  warnings: {
    feedback_reject: 'This trace has been rejected, read the feedback:',
  },
  user_not_found: {
    login_failed: 'Login failed',
    user_does_not_exist: 'The system couldn\'t find your user.',
    user_not_found_title: 'Authentication can fail because of several reasons:',
    user_not_found_reason1: 'The username is not correctly activated',
    user_not_found_reason2: 'The credentials are not ready in the system',
    user_not_found_contact_admin: 'Please contact your system’s administrator.'
  },
  tags: {
    tag: "Tag",
    split: "Split",
    rejected: "Rejected",
    handmade: "Manual",
    errorValidation: "Not valid"
  },
  orderLog: {
    IMPORT: 'Enquiry <b>Imported</b>',
    SPLIT_NEW_ROW: '{{user}} <b>Splitted</b> enquiry with quantity {{quantity}}',
    SPLIT_UPDATE: '{{user}} <b>Updated split</b> quantity to {{quantity}}',
    REJECT: '{{user}} <b>Rejected</b> the enquiry<div class="order_log_citation">{{ message }}</div>',
    ASSIGN_CHILD: '{{user}} <b>Assigned</b> child enquiries',
    ASSIGNED: '{{user}} <b>Assigned</b> the enquiry to {{company}}',
    DRAFT: '{{user}} <b>Saved as draft</b> the enquiry',
    DRAFT_THIRD_PARTY: '{{user}} <b>Saved as draft</b> the enquiry',
    FULFILL: '{{user}} <b>Fulfilled</b> the enquiry',
    FULFILL_THIRD_PARTY: '{{user}} <b>Fulfilled</b> the enquiry',
    COMPLETE: '{{user}} <b>Completed</b> the enquiry',
    CANCEL: '{{user}} <b>Cancelled</b> the enquiry',
    UNLOCK: '{{user}} <b>Unlock</b> the enquiry',
    APPROVE: '{{user}} <b>Approved</b> the enquiry',
    REOPEN: '{{user}}  <b>Reopened</b> the enquiry',
    DISAPPROVE: '{{user}}  <b>Disapproved</b> the enquiry',
  },
  bulkApproveDialog: {
    title: "Bulk Approve",
    all_order_approved: "All {{approved}} enquiries have been approved",
    approved: " orders approved on ",
    notApproved: " orders not be approved, because the child are not",
    approved_key: "APPROVED",
    forceApproveQuestion: "Do you want Force the approval (and their child)?"
  },
  GOLD: "Leather Working Group (LWG) - Gold",
  SILVER: "Leather Working Group (LWG) - Silver",
  BRONZE: "Leather Working Group (LWG) - Bronze",
  UNI_11427: "Eco-leathers certification UNI 11427",
  EMAS: "EMAS Environmental Declaration",
  UNI_EN_ISO_14001_2015: "Environmental management systems UNI EN ISO 14001:2015",
  UNI_EN_ISO_45001: "Occupational health and safety management systems UNI EN ISO 45001",
  UNI_CEI_EN_ISO_50001_2018: "Energy management systems UNI CEI EN ISO 50001:2018",
  SA8000: "Social Accountability System SA8000",
  UNIC: "UNIC code of conduct and social accountability",
  ICEC: "ICEC code of conduct and social accountability",
  UNI_EN_16484: "Denomination of origin of leather production UNI EN 16484",
  ICEC_TS_SC_410___ICEC_TS_PC412: "Leather traceability certification ICEC TS SC 410 / ICEC TS PC412",
  CENTRIC: "CENTRIC Circularity measurement tool",
  UNI_EN_ISO_14040__14044_o_14072: "Life Cycle Assesment (LCA) according UNI EN ISO 14040, 14044 or 14072",
  other_certification: "Other certification",
}

export default labels
