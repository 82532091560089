import React from 'react'
import {Card, Col} from "antd";
import DropLiquidPlot from "../ant-d-modules/liquid-plot/DropLiquidPlot";


interface Props {
    xl: number
    sm: number
    lg: number
}

interface State {
}


export default class DropLiquidPlotCard extends React.Component<Props, State> {
    state: State = {}


    render() {
        return (
            <Col xl={this.props.xl} sm={this.props.sm} lg={this.props.lg}>
                <Card
                    title='Status of the references'
                    bordered={true}
                    style={{width: "100%", height: 500}}

                >
                    <DropLiquidPlot/>
                </Card>
            </Col>
        )
    }
}




