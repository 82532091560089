import { Page, QueryParams, Company } from 'api/types'
import { AxiosRequestConfig } from 'axios'
import api, { responseErrorCheck } from './api'
import { showError } from '../helpers/Notifications';

class Companies<T = Company> {
  protected endpoint = 'profilemanager/v1/companies'

  async findAll(axiosConfig?: AxiosRequestConfig) {
    const res = await api.get<Page<T>>(`${this.endpoint}`, {}, axiosConfig)
    return responseErrorCheck(res)
  }

  async get(id: string, axiosConfig?: AxiosRequestConfig) {
    const res = await api.get<T>(`${this.endpoint}/${id}`, {}, axiosConfig)
    return responseErrorCheck(res)
  }

  async findAllSubsupplier(companyId: string, axiosConfig?: AxiosRequestConfig) {
    const res = await api.get<{ id: string; label: string }[]>(
      `${this.endpoint}/select?referrerId=${companyId}`, {},
      axiosConfig
    )
    return responseErrorCheck(res)
  }

  async search(params: QueryParams, axiosConfig?: AxiosRequestConfig) {
    const res = await api.get<Page<T>>(`${this.endpoint}`, params, axiosConfig)
    return responseErrorCheck(res)
  }

  async searchAvailableCompanies(params: QueryParams, axiosConfig?: AxiosRequestConfig) {
    const res = await api.get<any>(`${this.endpoint}/selectAvailableCompany`, params, axiosConfig)
    return responseErrorCheck(res)
  }

  async searchAvailableFullCompanies(params: QueryParams, axiosConfig?: AxiosRequestConfig) {
    const res = await api.get<any>(`${this.endpoint}/selectAvailableFullCompany`, params, axiosConfig)
    return responseErrorCheck(res)
  }


  async enable(id: string, axiosConfig?: AxiosRequestConfig) {
    const res = await api.put<T>(`${this.endpoint}/${id}/enabled`, {}, axiosConfig)
    return responseErrorCheck(res)
  }

  async disable(id: string, axiosConfig?: AxiosRequestConfig) {
    const res = await api.delete<T>(`${this.endpoint}/${id}`, {}, axiosConfig)
    return responseErrorCheck(res)
  }

  getEndpoint() {
    return this.endpoint
  }

  getAutocompleteQueryParam() {
    return 'fullText'
  }

  getEndpointDownloadCompanyXls() {
    return this.endpoint + '/onbordingBy';
  }

  async downloadCompanyXls(params: QueryParams, axiosConfig?: AxiosRequestConfig) {

    let axiosConfigMerged: AxiosRequestConfig = {
      responseType: 'blob',
      timeout: 200000,
      headers: {
        ... axiosConfig
      }
    }

    await api.get(this.endpoint + '/downloadCompanyXls', params, axiosConfigMerged)
      .then(response => {
        let filename;
        const disposition = response.headers!['content-disposition'];
        filename = disposition.split('filename=')[1];
        const a = document.createElement('a');
        a.href = window.URL.createObjectURL(response.data);
        a.download = filename;
        document.body.appendChild(a);
        a.click();
      })
      .catch(error => {
        // console.error(error);
        showError("Error", error.message)
      });
  }
}

const companies = new Companies()
export { companies as default }
