import React from 'react';
import { LibraryConfig } from 'api/types';
import { __, T } from 'config/i18n';
import { ColumnsType } from 'antd/es/table';
import { Schema } from '../AdvancedForm';
import LibraryConfigs from '../../api/LibraryConfigs';
import ModalTableSelectRow from "../ModalTableSelectRow";
import {buildColumnsByConfiguration} from "../orders/ColumnBuilderUtil";
import {buildFiltersByConfiguration} from "../orders/FilterBuilderUtil";
import AppStore from '../../AppStore'



interface Props {
  isVisible: boolean
  onCancelCallback: () => void,
  onSuccessCallback: (libraryConfig: LibraryConfig) => void,
  libraryType: string
}

interface State {
}


export default class NewLibraryItemModal extends React.Component<Props, State> {

  state: State = {
  };

  async componentDidMount() {
  }


  getFilters = ():Schema[] => {
    return buildFiltersByConfiguration([
      {
        name: 'name',
        type: 'text',
        label: T.fields.type,
        placeholder: T.misc.write,
      },
    ])
  }

  getColumns = (): ColumnsType<LibraryConfig> => {
    return buildColumnsByConfiguration([
      {
        title: T.fields.type,
        dataIndex: 'name',
        sorter: true,
        sortDirections:[ 'ascend', 'descend', 'ascend'],
      },
      {
        title: T.fields.order_step,
        dataIndex: 'traceType.code',
        sorter: true,
        sortDirections:[ 'ascend', 'descend', 'ascend']
      }
    ])
  };


  search = async (params: any, currentPage: number, sorField: string, sortDirection: 'asc' | 'desc', size: number) => {
    params['type'] = this.props.libraryType;
    const searchResults = await LibraryConfigs.search({
      ...params,
      page: currentPage,
      size:size,
      sort: sorField + ',' + sortDirection
    });

    const userCategories = AppStore.loggedUser?.company?.categories;

    // Get all content that has a category
    const filteredContent =  searchResults.content.filter(l => {
      return userCategories?.includes(l.traceType.category) || l.traceType.category === null
    });



    return  {
      content: filteredContent,
      totalElements: filteredContent.length,
      totalPages: searchResults.totalPages,
      page: searchResults.page
    };
  };


  render() {
    return (
        <ModalTableSelectRow<LibraryConfig>
            isVisible={this.props.isVisible}
            onCancel={this.props.onCancelCallback}
            onSuccess={this.props.onSuccessCallback}
            onSearchElements={this.search}
            getColumnsConfig={this.getColumns}
            getFiltersConfig={this.getFilters}
            title={__(T.titles.new_library_dialog)} />
    );
  }
}
