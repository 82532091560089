import React, { Component } from 'react'
import { __, T } from 'config/i18n'
import { Typography, Button, Tabs, notification, Spin } from 'antd'
import { View, AddRuleModal, RulesList, ModalConfirm } from 'components'
import ImportRule from 'api/ImportRule'
import SupplyChains from 'api/SupplyChains'
import { Rule, Properties, RuleApi } from 'api/types'
import theme from 'theme/theme'
import moment from 'moment'

interface Props {
  title?: string
}

interface State {
  addModalVisible: boolean
  addModalDestroy: boolean
  confirmModalVisible: boolean
  rules: Rule[]
  archived: Rule[]
  activeTabKey: string
  indexToArchive: number
  ruleId: string
  operationInProgress: boolean
  properties: Properties[]
  loading: boolean
  confirmModalTitle: string
  confirmModalSubtitle: string
}

const { Title, Text } = Typography

const ENABLED_REOPEN_RULE = true
const ENABLED_DELETE_RULE = true

export default class StyleTraceability extends Component<Props, State> {
  state: State = {
    addModalVisible: false,
    addModalDestroy: true,
    confirmModalVisible: false,
    rules: [],
    archived: [],
    activeTabKey: 'rules',
    indexToArchive: 0,
    operationInProgress: false,
    properties: [],
    ruleId: '',
    loading: true,
    confirmModalTitle: '',
    confirmModalSubtitle: '',
  }

  componentDidMount() {
    this._init()
  }

  getRules = async () => {
    const rules = await ImportRule.search({
      page: 0,
      size: 100,
      sort: 'createdDate,asc',
    })
    return rules ? rules.content : []
  }

  convertRule = (rule: RuleApi, properties: Properties[]) => {
    let obj: Rule = {
      id: rule.id,
      name: rule.name || '---',
      startDate: rule.startDate,
      endDate: rule.endDate,
      version: rule.version,
      exceptions: [],
    }
    for (let p = 0; p < properties.length; p++) {
      if (rule[properties[p].value]) {
        obj.exceptions.push({
          property: properties[p].value,
          values: rule[properties[p].value],
        })
      }
    }
    return obj
  }

  getRulesData = (all: RuleApi[], properties: Properties[]) => {
    let enabled: Rule[] = []
    let disabled: Rule[] = []
    for (let a = 0; a < all.length; a++) {
      if (all[a].enabled) {
        enabled.push(this.convertRule(all[a], properties))
      } else {
        disabled.push(this.convertRule(all[a], properties))
      }
    }
    return { enabled: enabled, disabled: disabled }
  }

  _init = async () => {
    const all: RuleApi[] = await this.getRules()
    const properties: Properties[] = await SupplyChains.getProperties()
    const rules = await this.getRulesData(all, properties)
    this.setState({
      rules: rules.enabled,
      archived: rules.disabled,
      activeTabKey: rules.enabled.length > 0 ? 'rules' : 'archived',
      properties: properties,
      loading: false,
    })
  }

  changeTab = (key: string) => {
    this.setState({ activeTabKey: key })
  }

  openAddRuleModal = () => {
    this.setState({ addModalVisible: true, addModalDestroy: false })
  }

  closeAddRuleModal = () => {
    this.setState({ addModalVisible: false }, this.destroyModal)
  }

  destroyModal = () => {
    setTimeout(() => {
      this.setState({ addModalDestroy: true })
    }, 600)
  }

  archivedRule = (index: number) => {
    this.setState({
      confirmModalVisible: true,
      indexToArchive: index,
      ruleId: '',
      confirmModalTitle: __(T.misc.archive_rule_title),
      confirmModalSubtitle: __(T.misc.archive_rule_subtitle),
    })
  }

  reopenRule = (index: number) => {
    this.setState({
      confirmModalVisible: true,
      indexToArchive: index,
      ruleId: '',
      confirmModalTitle: __(T.misc.reopen_rule_title),
      confirmModalSubtitle: __(T.misc.reopen_rule_subtitle),
    })
  }

  deleteRule = (id: string) => {
    this.setState({
      confirmModalVisible: true,
      indexToArchive: -1,
      ruleId: id,
      confirmModalTitle: __(T.misc.delete_rule_title),
      confirmModalSubtitle: __(T.misc.delete_rule_subtitle),
    })
  }

  confirmArchivedRule = (confirm: boolean) => {
    const { operationInProgress } = this.state
    if (confirm) {
      if (!operationInProgress) {
        this.setState({ operationInProgress: true }, this.completeArchive)
      }
    } else {
      this.setState({ confirmModalVisible: false, indexToArchive: 0, ruleId: '' })
    }
  }

  completeArchive = async () => {
    const { indexToArchive, ruleId, activeTabKey } = this.state
    if (indexToArchive === -1) {
      let archived = this.state.archived
      const index = archived.findIndex(s => s.id === ruleId)
      if (index !== -1) {
        await ImportRule.delete(ruleId)
        archived.splice(index, 1)
        this.setState({
          archived: archived,
          confirmModalVisible: false,
          operationInProgress: false,
          indexToArchive: 0,
          ruleId: '',
        })
      }
    } else {
      let rules = activeTabKey === 'rules' ? this.state.rules : this.state.archived
      const rule = rules[indexToArchive]
      rules.splice(indexToArchive, 1)
      if (activeTabKey === 'rules') {
        rule.id && (await ImportRule.disable(rule.id))
        this.setState({
          rules: rules,
          archived: [...this.state.archived, rule],
          confirmModalVisible: false,
          operationInProgress: false,
          activeTabKey: rules.length > 0 ? 'rules' : 'archived',
          indexToArchive: 0,
          ruleId: '',
        })
      } else {
        rule.id && (await ImportRule.enable(rule.id))
        this.setState({
          rules: [...this.state.rules, rule],
          archived: rules,
          confirmModalVisible: false,
          operationInProgress: false,
          activeTabKey: 'rules',
          indexToArchive: 0,
          ruleId: '',
        })
      }
    }
  }

  saveRule = async (rule: Rule) => {
    const { properties } = this.state
    let ruleSave: RuleApi = {
      name: rule.name,
      startDate: moment(rule.startDate._d).format('YYYY-MM-DD HH:mm:ss'),
      endDate: moment(rule.endDate._d).format('YYYY-MM-DD HH:mm:ss'),
      enabled: true,
    }
    for (let e = 0; e < rule.exceptions.length; e++) {
      if (rule.exceptions[e].property) {
        const prop = rule.exceptions[e].property || ''
        ruleSave[prop] = rule.exceptions[e].values || ''
      }
    }

    const result = await ImportRule.save(ruleSave)
    if (result && result.id) {
      let rules = this.state.rules
      rules.unshift(this.convertRule(result, properties))
      this.setState({ rules: rules, addModalVisible: false, activeTabKey: 'rules' })

      notification.success({
        message: __(T.messages.rule_saved),
        placement: 'bottomRight',
        duration: 3,
      })
    }
  }

  render = () => {
    const {
      addModalVisible,
      addModalDestroy,
      activeTabKey,
      rules,
      archived,
      confirmModalVisible,
      properties,
      loading,
      confirmModalTitle,
      confirmModalSubtitle,
    } = this.state
    return (
      <>
        <View style={{ marginTop: 20 }}>
          <View style={{ paddingLeft: 20, paddingBottom: 25 }}>
            <Title level={4}>{__(T.misc.style_traceability_title)}</Title>
            <Text>{__(T.misc.style_traceability_subtitle)}</Text>
            {!loading ? (
              <View style={{ marginTop: 25 }}>
                <Button
                  style={{ ...{ paddingLeft: 25, paddingRight: 25 }, ...theme.temeraSecondaryDarkButton }}
                  type="default"
                  onClick={() => this.openAddRuleModal()}
                >
                  {__(T.misc.create_new_rule)}
                </Button>
              </View>
            ) : (
              <View style={{ marginTop: 25, marginBottom: 60 }}>
                <Spin />
                <Text style={{ marginLeft: 10 }}>{__(T.misc.loading)}</Text>
              </View>
            )}
          </View>
          {!loading && (rules.length > 0 || archived.length > 0) && (
            <Tabs className="tabs-padding" activeKey={activeTabKey} onChange={key => this.changeTab(key)}>
              {rules.length > 0 && (
                <Tabs.TabPane key={'rules'} tab={__(T.misc.rules_active)}>
                  <RulesList rules={rules} archived={this.archivedRule} />
                </Tabs.TabPane>
              )}
              {archived.length > 0 && (
                <Tabs.TabPane key={'archived'} tab={__(T.misc.rules_archived)}>
                  <RulesList
                    rules={archived}
                    reopenRule={ENABLED_REOPEN_RULE ? this.reopenRule : undefined}
                    deleteRule={ENABLED_DELETE_RULE ? this.deleteRule : undefined}
                  />
                </Tabs.TabPane>
              )}
            </Tabs>
          )}
        </View>
        {!addModalDestroy && !loading && (
          <AddRuleModal
            confirm={this.saveRule}
            closed={this.closeAddRuleModal}
            visible={addModalVisible}
            properties={properties}
          />
        )}
        <ModalConfirm
          title={confirmModalTitle}
          subtitle={confirmModalSubtitle}
          callback={this.confirmArchivedRule}
          visible={confirmModalVisible}
        />
      </>
    )
  }
}
