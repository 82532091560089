import React, {PropsWithChildren} from 'react'
import {Modal} from 'antd'
import {ColumnsType} from "antd/es/table";
import {Schema} from "./AdvancedForm";
import {BaseEntity, Page} from "../api/types";
import FilteredTable from "./FilteredTable";

interface Props<T> {
    isVisible: boolean
    onCancel: () => void, //deve chiudere il componente.
    onSuccess: (entity: T) => void, //deve chiudere il componente + mandare i dati al padre.
    onSearchElements: (params: any, currentPage: number, sorField: string, sortDirection: 'asc' | 'desc', size: number) => Promise<Page<T>>,
    getColumnsConfig: () => ColumnsType<T>,
    getFiltersConfig: () => Schema[],
    title: string,
    rowsSelected?: any[]
}

interface State<T> {
    enableOkButton: boolean,
    selectedRow?: T,
    askToUpdateData: number
}


export default class ModalTableSelectRow<T extends BaseEntity> extends React.Component<PropsWithChildren<Props<T>>, State<T>> {

    state: State<T> = {
        enableOkButton: false,
        askToUpdateData: 0
    }

    timer: any

    async componentDidMount() {
    }

    askToUpdateData = () => {
        this.setState({askToUpdateData: this.state.askToUpdateData + 1})
    }

    componentDidUpdate(prevProps: Readonly<Props<any>>, prevState: Readonly<State<any>>, snapshot?: any) {
        if (this.props.isVisible && this.props.isVisible !== prevProps.isVisible) {
            this.askToUpdateData();
        }
    }

    handleOk = () => {
        this.props.onSuccess(this.state.selectedRow!);
    };

    handleCancel = () => {
        this.props.onCancel();
    };


    onSearchElements = async (params: any, currentPage: number, sortField: string, sortDirection: 'asc' | 'desc', size: number, activeTabKey?: string): Promise<Page<T>> => {
        return await this.props.onSearchElements(
            params,
            currentPage,
            sortField,
            sortDirection,
            10)
    }

    onChangeSelection = (elements: T[]) => {
        if(elements.length > 0){
            this.setState({enableOkButton: true, selectedRow: elements[0]})
        } else {
            this.setState({enableOkButton: false, selectedRow: undefined})
        }
    }


    render() {

        return (
            <Modal title={this.props.title}
                   visible={this.props.isVisible}
                   onOk={this.handleOk}
                   onCancel={this.handleCancel}
                   okButtonProps={{disabled: !this.state.enableOkButton}}
                   width={window.innerWidth / 7 * 5}>
                <FilteredTable
                  rowsSelected={this.props.rowsSelected}
                    onSearchElements={this.props.onSearchElements}
                    getColumnsConfig={this.props.getColumnsConfig}
                    getFiltersConfig={this.props.getFiltersConfig}
                    rowSelection={{
                        onChangeSelection: this.onChangeSelection
                    }}
                    askToUpdateData={this.state.askToUpdateData}
                />
                <>{this.props.children}</>
            </Modal>
        )
    }
}
