import React from 'react'
import {Card, List} from 'antd'

import {OrderStatus, SubOrder, SubOrderWithOwnerCompanyInfo} from '../../api/types'
import {Companies} from '../../api'
import OrderCard from './OrderCard'

interface Props {
    subOrders: SubOrder[]
    onViewSubOrderDetail?: (subOrderId: string) => void
    onRejectOrder?: (orderId: string) => void
    onCancel?: (orderId: string) => void
    onUnlock?: (orderId: string) => void
    onReassignOrder?: (orderId: string) => void
    onCloneOrder?: (orderId: string) => void
    onShowLogs?: (orderId: string) => void
    hideSupplier: boolean,
    askToUpdate: number,
    parentOrderStatus?: OrderStatus
    pageType?: string
    parentOrderId?: string
}

interface State {
    subOrdersWithOwnerCompanyInfo: SubOrderWithOwnerCompanyInfo[]
}

export default class OrderTraceReportSubOrderCardListBody extends React.Component<Props, State> {
    state: State = {subOrdersWithOwnerCompanyInfo: []}

    componentDidMount = async () => {
        await this.loadOrdersWithCompanyInfo()
    }

    componentDidUpdate = async (prevProps: Props) => {
        if (this.props.askToUpdate !== prevProps.askToUpdate) {
            await this.loadOrdersWithCompanyInfo()
        }
    }

    getCompanyInfoById = async (orders: SubOrder[]) => {
        const ownerCompanyIds: string[] = orders
            .map(order => order.ownerCompany)
            .filter(companyName => companyName)

        const creationCompanyIds: string[] = orders
            .map(order => order.creationCompany)
            .filter(companyName => companyName)

        const companyIds = [
            ...ownerCompanyIds,
            ...creationCompanyIds
        ]

        return Companies.search({
            page: 0,
            size: companyIds.length,
            ids: companyIds,
        })
            .then(response => response?.content ?? [])
            .then(companies =>
                companies.reduce(
                    (companiesById, company) => companiesById.set(company.id, {
                        certificationValidity: company.certificationValidity,
                        companyName: company.data.businessName
                    }),
                    new Map<string, { certificationValidity: boolean, companyName: string }>()
                )
            )
    }

    loadOrdersWithCompanyInfo = async (): Promise<void> => {
        const companyInfo = await this.getCompanyInfoById(this.props.subOrders)
        const ordersWithOwnerCompanyInfo = this.props.subOrders.map(order => this.withCompanyInfo(order, companyInfo))
        this.setState({subOrdersWithOwnerCompanyInfo: ordersWithOwnerCompanyInfo})
    }

    withCompanyInfo = (
        subOrder: SubOrder,
        ownerCompanyInfoById: Map<string, { certificationValidity: boolean, companyName: string }>
    ): SubOrderWithOwnerCompanyInfo =>
        ({
            ...subOrder,
            certificationValidity: ownerCompanyInfoById.get(subOrder.creationCompany)?.certificationValidity,
            ownerCompanyName: ownerCompanyInfoById.get(subOrder.ownerCompany)?.companyName,
            creationCompanyName: ownerCompanyInfoById.get(subOrder.creationCompany)?.companyName
        })

    render = () => {
        return (
            <Card style={{backgroundColor: 'rgb(245, 245, 245)'}}>
                <List
                    grid={{column: 1}}
                    dataSource={this.state.subOrdersWithOwnerCompanyInfo}
                    renderItem={this.renderListItem}
                />
            </Card>
        )
    }

    renderListItem = (subOrderWithOwnerCompanyName: SubOrderWithOwnerCompanyInfo) => (
        <List.Item>
            <OrderCard
                isSubOrder={true}
                parentOrderStatus={this.props.parentOrderStatus}
                askToUpdate={this.props.askToUpdate}
                order={subOrderWithOwnerCompanyName}
                onViewDetail={this.props.onViewSubOrderDetail}
                onRejectOrder={this.props.onRejectOrder}
                onCancel={this.props.onCancel}
                onUnlock={this.props.onUnlock}
                onReassignOrder={this.props.onReassignOrder}
                onCloneOrder={this.props.onCloneOrder}
                onShowLogs={this.props.onShowLogs}
                showAssignmentDate
                hideSupplier={this.props.hideSupplier}
                pageType={this.props.pageType}
                parentOrderId={this.props.parentOrderId}
            />
        </List.Item>
    )

    subOrdersChanged = (currentSubOrders: SubOrder[], previousSubOrders: SubOrder[]): boolean => {
        const maxSubOrdersLength = Math.max(currentSubOrders.length, previousSubOrders.length)
        return Array(maxSubOrdersLength)
            .fill(0)
            .some((_, subOrderIndex) => currentSubOrders[subOrderIndex]?.id !== previousSubOrders[subOrderIndex]?.id ||
                currentSubOrders[subOrderIndex]?.status !== previousSubOrders[subOrderIndex]?.status)
    }
}
