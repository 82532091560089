import React from 'react'
import { Layout, AdvancedForm, View, Modal, Text } from 'components'
import { Schema } from 'components/AdvancedForm'
import { __, T } from 'config/i18n'
import { Table, Space, Button, Tabs } from 'antd'

import { showNotification } from 'helpers/Notifications'
import { Subsuppliers, HardCoded } from 'api'
import AppStore from 'AppStore'
import { Subsupplier } from 'api/types'
import { describeCategory } from 'helpers/describer'
import { PaginationHelper } from 'PaginationHelper'
import errorPrint from "../../tools/ErrorPrint";

interface Props {
  navigation?: any
}
interface State {
  filteredSubsupplier: Subsupplier[]
  showConfirmDialog: boolean
  activeTabKey: '1' | '2'
  paginationHelper: PaginationHelper
  filters: any
}

export default class SubSupplierReport extends React.Component<Props, State> {
  state: State = {
    filteredSubsupplier: [],
    showConfirmDialog: false,
    activeTabKey: '1',
    paginationHelper: new PaginationHelper(5),
    filters: {},
  }

  subsupplierToDisable?: string
  subsupplierToEnable?: string

  componentDidMount() {
    this.fetchSubsuppliers()
  }

  fetchSubsuppliers = async () => {
    this.applyFilters()
  }

  async applyFilters(filters: {} = {}, page?: number, tab?: '1' | '2') {
    this.state.paginationHelper.setCurrentPage(page ?? 1)
    let params = {}
    if (filters['free_search'] && filters['free_search'] !== '') {
      params['fullText'] = filters['free_search']
    }
    if (filters['category'] && filters['category'] !== '') {
      params['category'] = filters['category']
    }
    const updatedTab = tab ?? this.state.activeTabKey
    try {
      const companyId =  (AppStore.loggedUser?.company && AppStore.loggedUser?.company.id) ? AppStore.loggedUser?.company.id : undefined
      const subsupplierPage = await Subsuppliers.findAll(companyId, {
        ...this.state.paginationHelper.getPaginationApiParams(),
        enabled: tab ? tab === '1' : this.state.activeTabKey === '1',
        ...params,
      })
      this.state.paginationHelper.setTotalElements(subsupplierPage?.totalElements ?? 0)

      this.setState({
        filteredSubsupplier: subsupplierPage?.content ?? [],
        activeTabKey: updatedTab,
        filters,
      })
    } catch (error) {
      errorPrint.printMessage(error)
      this.setState({
        filteredSubsupplier: [],
        activeTabKey: updatedTab,
        filters,
      })
    }
  }

  onTabChangeFilter = async (activeKey: string) => {
    if (activeKey === '1') {
      await this.applyFilters(this.state.filters, 1, '1')
    } else if (activeKey === '2') {
      await this.applyFilters(this.state.filters, 1, '2')
    }
  }

  toggleSupplierState = subsupplierId => {
    if (this.state.activeTabKey === '1') {
      this.subsupplierToDisable = subsupplierId
      this.setState({ showConfirmDialog: true })
    } else {
      this.subsupplierToEnable = subsupplierId
      this.setState({ showConfirmDialog: true })
    }
  }

  openSubsupplierDetails = (subsupplierId: string) => {
    // navigate('/reports/subsupplier/details/:subsupplierId', { subsupplierId })
  }

  render() {
    const { filteredSubsupplier, activeTabKey } = this.state
    const filterSchema: Schema[] = [
      {
        name: 'free_search',
        label: __(T.misc.free_search),
        type: 'text',
        placeholder: __(T.misc.business_name_email_etc),
        col: { xs: 24, sm: { span: 9 } },
      },
      // {
      //   name: 'supplier',
      //   type: 'select',
      //   label: __(T.misc.supplier),
      //   placeholder: __(T.misc.select),
      //   options: [{ label: 'Esempio', value: 'Esempio' }],
      //   col: { xs: 24, sm: { span: 5 } },
      // },
      {
        name: 'category',
        type: 'select',
        label: __(T.fields.category),
        placeholder: __(T.misc.select),
        options: HardCoded.getCategoryOptions(),
        col: { xs: 24, sm: { span: 5 } },
      },
    ]

    const columns = [
      {
        title: __(T.fields.subsupplier_name),
        dataIndex: 'companyName',
        render: (text, record: any) => <Text>{record?.data?.businessName}</Text>,
      },
      {
        title: __(T.fields.category),
        dataIndex: 'category',
        render: (text, record: any) => <Text>{describeCategory(record?.data?.category)}</Text>,
      },
      {
        title: __(T.fields.referent),
        dataIndex: 'referent',
        render: (text, record: any) => <Text>{record?.referee.referent.firstName}</Text>,
      },
      {
        title: __(T.fields.phone),
        dataIndex: 'phone',
        render: (text, record: any) => <Text>{record?.data?.phone}</Text>,
      },
      {
        title: __(T.fields.email),
        dataIndex: 'email',
        render: (text, record: any) => <Text>{record?.data?.email}</Text>,
      },
      {
        title: __(T.fields.sent_date),
        dataIndex: 'creationDate',
        key: 'creationDate',
      },
      {
        title: __(T.titles.actions),
        key: 'action',
        render: (text, record) => (
          <Space size="middle">
            <Button type="link" onClick={() => this.openSubsupplierDetails(record.id)}>
              {__(T.buttons.open)}
            </Button>

            <Button type="link" onClick={() => this.toggleSupplierState(record.id)} style={{ maxWidth: 60 }}>
              {activeTabKey === '1' ? __(T.buttons.suspend) : __(T.buttons.enable)}
            </Button>
          </Space>
        ),
      },
    ]

    const pagination = {
      onChange: async page => {
        await this.applyFilters(this.state.filters, page)
      },
      total: this.state.paginationHelper.totalElements,
      current: this.state.paginationHelper.currentPage,
      pageSize: this.state.paginationHelper.pageSize,
      showSizeChanger: false,
    }

    const scroll: { x: true } = { x: true }

    return (
      <Layout>
        <View style={{ padding: 20 }}>
          <View style={{ flex: 1, display: 'flex', justifyContent: 'flex-end', marginBottom: 12 }}>
            <Button
              type="primary"
              onClick={() => {
                // navigate('/reports/subsupplier/create')
              }}
              style={{ minWidth: 200 }}
            >
              {__(T.buttons.new_subsupplier)}
            </Button>
          </View>
          <View className="filter-box">
            <AdvancedForm
              schema={filterSchema}
              onValuesChange={(changedValues, values) => this.applyFilters(changedValues)}
              style={{ flex: 1 }}
            />
          </View>

          <View className="page-table">
            <Tabs defaultActiveKey={this.state.activeTabKey} onChange={this.onTabChangeFilter}>
              <Tabs.TabPane tab={__(T.misc.active)} key="1">
                <Table
                  columns={columns}
                  dataSource={filteredSubsupplier}
                  bordered
                  pagination={pagination}
                  scroll={scroll}
                />
              </Tabs.TabPane>
              <Tabs.TabPane tab={__(T.misc.disabled)} key="2">
                <Table
                  columns={columns}
                  dataSource={filteredSubsupplier}
                  bordered
                  pagination={pagination}
                  scroll={scroll}
                />
              </Tabs.TabPane>
            </Tabs>
          </View>
        </View>
        <Modal
          title={activeTabKey === '1' ? __(T.confirms.cancel_subsupplier) : __(T.confirms.activate_subsupplier)}
          visible={this.state.showConfirmDialog}
          confirmButtonTitle={__(T.misc.yes)}
          closeButtonTitle={__(T.misc.no)}
          onConfirmClick={async () => {
            this.setState({ showConfirmDialog: false })
            try {
              if (activeTabKey === '1' && this.subsupplierToDisable !== undefined) {
                await Subsuppliers.delete(this.subsupplierToDisable)
                showNotification(__(T.messages.subsupplier_disabled))
              }
              if (activeTabKey === '2' && this.subsupplierToEnable !== undefined) {
                await Subsuppliers.enable(this.subsupplierToEnable)
                showNotification(__(T.messages.subsupplier_enabled))
              }
              this.fetchSubsuppliers()
            } catch (e) {
              console.log(e)
            }
          }}
          onCloseClick={() => {
            this.setState({ showConfirmDialog: false })
          }}
        />
      </Layout>
    )
  }
}
