import React from 'react';
import { LibraryConfig, Page } from 'api/types';
import { __, T } from 'config/i18n';
import ModalTableSelectRow from "../ModalTableSelectRow";
import {buildColumnsByConfiguration} from "../orders/ColumnBuilderUtil";
import {buildFiltersByConfiguration} from "../orders/FilterBuilderUtil";
import { Companies, HardCoded } from '../../api';
import AppStore from '../../AppStore';



interface Props {
  isVisible: boolean
  onCancelCallback: () => void,
  onSuccessCallback: (company: any) => void,
  libraryType: string
}

interface State {
}


export default class AddUserModal extends React.Component<Props, State> {

  state: State = {
  };

  async componentDidMount() {
  }


  getFilters() {
    return buildFiltersByConfiguration([
      {
        name: 'fullText',
        label: T.misc.free_search,
        type: 'text',
        placeholder: T.misc.business_name_email_etc
      },
      {
        name: 'category',
        type: 'select',
        label: T.fields.category,
        placeholder: T.misc.select,
        options: HardCoded.getCategoryOptions()
      }
    ]);
  }

  getColumns() {
    let columns = buildColumnsByConfiguration([
      {
        title: __(T.fields.company_name),
        dataIndex: 'data.businessName'
      },
      {
        title: __(T.fields.categories),
        dataIndex: 'categories',
        renderType: 'categories'
      },
      {
        title: __(T.fields.creation_date),
        dataIndex: 'creationDate',
        renderType: 'dateTime'
      },
      {
        title: __(T.fields.country),
        dataIndex: 'data.country'
      }]);

    return columns;
  }


  search = async (params: any, currentPage: number, sorField: string, sortDirection: 'asc' | 'desc', size: number): Promise<Page<any>> => {
    params['excludeOnboardedBySupplier'] = AppStore.loggedUser?.company.id;
    return await Companies.search({
      ...params,
      page: currentPage,
      size: size
    });


  };


  render() {
    return (
        <ModalTableSelectRow<LibraryConfig>
            isVisible={this.props.isVisible}
            onCancel={this.props.onCancelCallback}
            onSuccess={this.props.onSuccessCallback}
            onSearchElements={this.search}
            getColumnsConfig={this.getColumns}
            getFiltersConfig={this.getFilters}
            title={__(T.titles.new_library_dialog)} />
    );
  }
}
