import React, {Component, RefObject} from 'react';
import {Alert, Button, Col, Modal, Row} from 'antd';
import {AppForm, View} from '../index';
import theme from "../../theme/theme";
import {__, T} from "../../config/i18n";

interface Props {
    visible: boolean;
    onCancel: () => void;
    schema: any;
    values: any;
    filters?: { [key: string]: any }
}

interface State {
    showSuccessMessage: boolean,
    previewSubmitButtonRef: RefObject<HTMLButtonElement>
}

export default class ModalConfirm extends Component<Props, State> {
    state: State = {
        previewSubmitButtonRef: React.createRef<HTMLButtonElement>(),
        showSuccessMessage: false,
    };

    render() {
        const {visible, onCancel, schema, values} = this.props;

        return (
            <Modal centered visible={visible} closable={false} footer={null} onCancel={() => onCancel()} width={1200} destroyOnClose={true}>

                <AppForm
                    schema={schema}
                    data={values}
                    formSubmitButtonRef={this.state.previewSubmitButtonRef}
                    filters={this.props.filters}
                    onSuccess={() => this.setState({showSuccessMessage: true})}
                    onChange={()=> {this.setState({showSuccessMessage: false})}}
                />
              <View center style={{ marginTop: 25 }}>
                <Row>
                    <Col>
                      <Button
                        style={{fontWeight: 700, marginLeft: 25, ...theme.temeraSecondaryDarkButton}}
                        type="default"
                        onClick={() => {this.props.onCancel()}}>
                        {__(T.buttons.cancel)}
                      </Button>
                      <Button
                        style={{fontWeight: 700, marginLeft: 25, ...theme.temeraPrimaryGreenButton}}
                        type="default"
                        onClick={() => {
                          this.setState({showSuccessMessage: false});
                          this.state.previewSubmitButtonRef.current?.click();
                        }}>
                        {__(T.buttons.validation_test)}
                      </Button>
                    </Col>
                    {this.state.showSuccessMessage &&
                        <Col span={8} offset={8}>
                            <Alert message={__(T.buttons.valid)} type="success" showIcon />
                        </Col>
                    }
                </Row>
              </View>
            </Modal>
        );
    }
}
