import React, { Component } from 'react'
import { __, T } from 'config/i18n'
import { Button, Typography, Modal, Input, Row, Col } from 'antd'
import { PlusCircleOutlined } from '@ant-design/icons'
import { View, ModalHeader } from 'components'
import { SupplyChainStep } from 'api/types'
import theme from 'theme/theme'
import SupplyChains from 'api/SupplyChains'

interface Props {
  currentSteps: SupplyChainStep[]
  visible: boolean
  closed: () => void
  confirm: (steps: SupplyChainStep[]) => void
}

interface State {
  selectable: SupplyChainStep[] | undefined
  filterSelectable: SupplyChainStep[] | undefined
  selected: SupplyChainStep[]
  steps: SupplyChainStep[]
  maxHeightStepList: number
}

const { Text } = Typography

export default class StepModal extends Component<Props> {
  state: State = {
    selected: [],
    steps: [],
    selectable: undefined,
    filterSelectable: undefined,
    maxHeightStepList: 0,
  }

  componentDidMount() {
    this._init()
    window.addEventListener('resize', this.handleResize)
  }

  getMaxHeightList = () => {
    const { innerHeight: height } = window
    return height - 278
  }

  handleResize = () => {
    this.setState({ maxHeightStepList: this.getMaxHeightList() })
  }

  getSteps = async () => {
    const steps = await SupplyChains.steps({
      page: 0,
      size: 100,
      sort: 'code,asc',
    })
    return steps ? steps.content : []
  }

  filterSteps = (steps: SupplyChainStep[]) => {
    const { currentSteps } = this.props
    let selectable: SupplyChainStep[] = []
    for (let s = 0; s < steps.length; s++) {
      const supply: SupplyChainStep = steps[s]
      if (supply && supply !== null) {
        const index = currentSteps.findIndex(s => s && supply && s.id === supply.id)
        if (index === -1) {
          selectable.push(steps[s])
        }
      }
    }
    return selectable
  }

  _init = async () => {
    const steps = await this.getSteps()
    const selectable = this.filterSteps(steps)
    this.setState({
      steps: steps,
      maxHeightStepList: this.getMaxHeightList(),
      selectable: selectable,
      filterSelectable: selectable,
      selected: [],
    })
  }

  shouldComponentUpdate = nextProps => {
    const { visible } = this.props
    if (nextProps.visible !== visible && nextProps.visible) {
      const selectable = this.filterSteps(this.state.steps)
      this.setState({ selectable: selectable, filterSelectable: selectable, selected: [] })
    }
    return true
  }

  handleChange = event => {
    this.search(event.target.value)
  }

  search = (value: string) => {
    const { selectable } = this.state
    if (selectable) {
      const res: any[] = []
      let o = 0
      while (o < selectable.length) {
        if (value !== '') {
          if (selectable[o].code.toLowerCase().indexOf(value.toLowerCase()) !== -1) {
            res.push(selectable[o])
          } else if (selectable[o].name && selectable[o].name?.toLowerCase().indexOf(value.toLowerCase()) !== -1) {
            res.push(selectable[o])
          }
        } else {
          res.push(selectable[o])
        }
        o++
      }
      this.setState({ filterSelectable: res })
    }
  }

  check = () => {
    const { confirm } = this.props
    confirm(this.state.selected)
  }

  managementStepSelection = (step: SupplyChainStep) => {
    let tmp = this.state.selected
    const index = tmp.findIndex(s => s.id === step.id)
    if (index !== -1) {
      tmp.splice(index, 1)
    } else {
      tmp.push(step)
    }
    this.setState({ selected: tmp })
  }

  isStepSelected = (id: string) => {
    const { selected } = this.state
    const index = selected.findIndex(s => s.id === id)
    return index !== -1
  }

  render = () => {
    const { visible, closed } = this.props
    const { filterSelectable, selected, maxHeightStepList } = this.state
    const num = selected.length
    return maxHeightStepList > 0 ? (
      <Modal
        title={<ModalHeader close={closed} title={__(T.misc.modal_step_title)} />}
        centered
        visible={visible}
        footer={null}
        closable={false}
        onCancel={() => closed()}
        width="60%"
      >
        {filterSelectable && (
          <>
            <View className="sc-modal-input-box">
              <Input id="search-key" onChange={e => this.handleChange(e)} placeholder={__(T.misc.search)} />
            </View>
            {filterSelectable.length > 0 && (
              <Row className="sc-modal-steps" gutter={[10, 10]} style={{ maxHeight: maxHeightStepList }}>
                {filterSelectable.map((step, i) => (
                  <Col xs={24} lg={6} sm={12} md={12} xl={6} key={'step_' + i}>
                    <View
                      onClick={() => this.managementStepSelection(step)}
                      className={'sc-modal-step-box' + (this.isStepSelected(step.id) ? ' selected' : '')}
                    >
                      <Text>{step.name || step.code}</Text>
                    </View>
                  </Col>
                ))}
              </Row>
            )}
            <View center style={{ marginTop: 25 }}>
              <Button
                style={{ ...{ fontWeight: 700 }, ...theme.temeraPrimaryGreenButton }}
                type="default"
                onClick={() => this.check()}
                disabled={num === 0}
              >
                <PlusCircleOutlined /> {__(T.misc.add_step_selected)} ({num})
              </Button>
            </View>
          </>
        )}
      </Modal>
    ) : null
  }
}
