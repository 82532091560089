import React, { Component } from 'react';
import { Modal, Button, Typography } from 'antd';
import { __, T } from 'config/i18n';
import {View} from 'components';
import theme from 'theme/theme';
import Orders from '../../api/Orders';
import {Company, DetailedOrder} from '../../api/types';
import {Companies} from "../../api";
import AdvancedForm2 from "../AdvancedForm2";
import api from "../../api/api";
import AppStore from "../../AppStore";

interface Props {
  visible: boolean;
  orderId: string;
  onSuccess: (order: DetailedOrder) => void;
  onCancel: () => void;
}

const { Title } = Typography;

interface State {
  currentCompany?: Company
  oldCompany?: Company
  category?: string
}

export default class ReassignModal extends Component<Props, State> {

  state: State = {
  };

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any) {
    if(prevProps.orderId !== this.props.orderId){
      this.loadCurrentCompany();
    }
  }

  componentDidMount() {
    this.loadCurrentCompany();
  }

  private async loadCurrentCompany (){
    if(!this.props.orderId){
      return;
    }
    let order = await Orders.get(this.props.orderId);
    if (order.creationCompany != null) {
      let company = await Companies.get(order.creationCompany);
      this.setState({currentCompany: company, oldCompany: company, category: order.traceType.category})
    } else {
      this.setState({currentCompany: undefined, oldCompany: undefined, category: order.traceType.category})
    }
  }

  private async onSuccess() {
    let order = await Orders.reassign(this.props.orderId, this.state.currentCompany?.id)
    this.props.onSuccess(order);
  }

  private onCancel(){
    this.props.onCancel()
  }

  private async onValueChanges(value) {
    if(!value.company){
      this.setState({
        currentCompany: undefined
      })
    } else if(!!value.company && !!value.company.value) {
      let company = await Companies.get(value.company.value);
      this.setState({currentCompany: company})
    } else {
      //ricerca in corso non fare nulla
    }
  }

  render() {
    const {visible} = this.props;
    const {category} = this.state;

    const url = `${api.getBaseURL()}${Companies.getEndpoint()}/selectAvailableCompany?categories=${
      category
    }&withOnBoardingCreatorCompanyId=true&onBoardingCreatorCompanyId=${
      AppStore.loggedUser?.company?.id //
    }&${Companies.getAutocompleteQueryParam()}=-X-`;


    return (
      <Modal centered visible={visible} closable={false} footer={null}
             onCancel={() => this.onSuccess()}>
        <Title style={{textAlign: 'center'}} level={4}>
          {__(T.fields.reassign)}
        </Title>

        {this.state.oldCompany && (
          <h3>
            {__(T.fields.currentCompany)}:
            <b>{this.state.oldCompany.data.businessName}</b>
          </h3>
        )}

        <View center style={{marginTop: 25}}>

          {this.state.category &&
            <AdvancedForm2
              style={{width: '100%'}}
              onValuesChange={(values) => this.onValueChanges(values)}
              schema={[
                {
                  name: "company",
                  type: "autocomplete",
                  label: __(T.fields.company_name_vat),
                  placeholder: __(T.misc.select),
                  descriptionKey: "label",
                  urlSearch: url,
                  col: 24,
                  rules: [{required: true}]
                }
              ]}
              values={{company: {value: this.state.currentCompany?.id, label: this.state.currentCompany?.data?.businessName}}}
            />
          }

        </View>
        <Button
          style={{...{fontWeight: 700}, ...theme.temeraSecondaryDarkButton}}
          type="default"
          onClick={() => this.onCancel()}
        >
          {__(T.buttons.cancel)}
        </Button>
        <Button
          style={{...{fontWeight: 700, marginLeft: 25}, ...theme.temeraPrimaryGreenButton}}
          type="default"
          onClick={async () => {
            await this.onSuccess();
          }}
        >
          {__(T.buttons.confirm)}
        </Button>
      </Modal>
    );
  }
}
