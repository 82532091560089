import { Page, QueryParams, TraceType } from 'api/types'
import { AxiosRequestConfig } from 'axios'
import api, { responseErrorCheck } from './api'

class TraceTypes<T = TraceType> {
  protected endpoint = 'tracemanager/v1/tracetypes'

  async findAll(axiosConfig?: AxiosRequestConfig) {
    const res = await api.get<Page<T>>(`${this.endpoint}?page=0&size=100`, axiosConfig)
    return responseErrorCheck(res)
  }

  async get(id: string, axiosConfig?: AxiosRequestConfig) {
    const res = await api.get<T>(`${this.endpoint}/${id}`, axiosConfig)
    return responseErrorCheck(res)
  }

  async search(params: QueryParams, axiosConfig?: AxiosRequestConfig) {
    const res = await api.get<Page<T>>(`${this.endpoint}`, params, axiosConfig)
    return responseErrorCheck(res)
  }

  getEndpoint() {
    return this.endpoint
  }
}

const traceTypes = new TraceTypes()
export { traceTypes as default }
