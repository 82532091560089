import appStore from '../AppStore';


export class ImportTraceFromAnotherPO {

  public importData(entityWithData: any, dataToImport: any, overrideExistingDataInput: boolean) {
    let fieldToExcludeList : string[] = appStore.appSettings.traceImportFieldsToExclude.split(',')
    if (entityWithData.data === null) {
      entityWithData.data = {};
    }
    for (let key in dataToImport) {
      if(!fieldToExcludeList.includes(key)){
        if(!entityWithData.data[key] || Object.keys(entityWithData.data[key]).length === 0 || overrideExistingDataInput){
          entityWithData.data[key] = dataToImport[key]
        }
      }
    }
    }
}


const importTraceFromAnotherPO = new ImportTraceFromAnotherPO()
export { importTraceFromAnotherPO as default }
