import React, { Component } from 'react'
import { Layout, View } from 'components'
import { __, T } from 'config/i18n'
import { Table, Space, Button, Tabs } from 'antd'
import { navigate } from 'router'
import { getOperationsForRole } from 'helpers/permission'
import { OnBoardings, HardCoded, Companies } from 'api'
import { PaginationHelper } from 'PaginationHelper'
import { OnBoarding } from 'api/types'
import api, { setOffGlobalSpinningOnHeader } from 'api/api';
import { showNotification } from 'helpers/Notifications'
import errorPrint from "../../tools/ErrorPrint";
import {buildFiltersByConfiguration, buildParamsByConfiguration} from "../../components/orders/FilterBuilderUtil";
import {buildColumnsByConfiguration} from "../../components/orders/ColumnBuilderUtil";
import AdvancedForm2 from "../../components/AdvancedForm2";
import AppStore from "../../AppStore";
import UploadOnboardingFileModal from '../../components/modals/UploadOnboardingFileModal';

interface Props {}
interface State {
  filteredData: OnBoarding[]
  activeTabKey: '1' | '2'
  paginationHelper: PaginationHelper
  filters: any,
  pageLoading: boolean,
  isUploadOnboardingModalVisible: boolean
}

export default class OnboardingReport extends Component<Props, State> {
  state: State = {
    filteredData: [],
    activeTabKey: '1',
    paginationHelper: new PaginationHelper(5),
    filters: {},
    pageLoading: true,
    isUploadOnboardingModalVisible: false
  }

  timer: any;

  async componentDidMount() {
    try {
      await this.search()
    } catch (error) {
      errorPrint.printMessage(error)
    }
  }

  onTabChangeFilter = async (activeKey: string) => {
    if (activeKey === '1') {
      await this.search(this.state.filters, 1, '1')
    } else if (activeKey === '2') {
      await this.search(this.state.filters, 1, '2')
    }
  }

  getFiltersConfig = () => {
    const permission = getOperationsForRole()
    return [
      {
        name: 'companyCode',
        type: 'text',
        label: T.fields.company_code,
        placeholder: T.misc.write,
        hide: !permission.onboardingReportToConfirm,
      },
      {
        name: 'referentFullText',
        type: 'text',
        label: T.fields.referent,
        placeholder: T.misc.write,
      },
      {
        name: 'companyId',
        type: 'autocomplete_company',
        label: T.fields.company_name,
        placeholder: T.misc.select,
        urlSearch: `${api.getBaseURL()}${Companies.getEndpoint()}/select?${Companies.getAutocompleteQueryParam()}=-X-`,
        header: setOffGlobalSpinningOnHeader(),
        descriptionKey: 'label',
        hide: this.isSentTab(this.state.activeTabKey, permission.onboardingReportToConfirm),
      },
      {
        name: 'category',
        type: 'select',
        label: T.fields.category,
        placeholder: T.misc.select,
        options: HardCoded.getCategoryOptions()
      }
    ]
  }

  applyFilters = (
      filters: any,
  ) => {
    if (this.state.pageLoading) {
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        this.search(filters);
      }, 500);
    } else {
      this.setState({
        filters: filters,
        pageLoading: true,
      }, () => {
        // Clears running timer and starts a new one each time the user types
        clearTimeout(this.timer);
        this.timer = setTimeout(() => {
          this.search(filters);
        }, 500);
      });
    }
  };

  async search(filters: {} = {}, page?: number, tab?: '1' | '2') {
    this.state.paginationHelper.setCurrentPage(page ?? 1)
    let params = buildParamsByConfiguration(filters, this.getFiltersConfig())
    if (params['category'] && params['category'] !== '') {
      if (this.isSentTab(tab ?? this.state.activeTabKey, getOperationsForRole().onboardingReportToConfirm)) {
        params['onboardingCategory'] = params['category']
        params['category'] = undefined
      }
    }
    let loggedUSer = AppStore.loggedUser;
    params['creatorId'] = loggedUSer?.id
    const updatedTab = tab ?? this.state.activeTabKey
    try {
      const onboardingPage = await OnBoardings.search({
        ...this.state.paginationHelper.getPaginationApiParams(),
        state: this.getStateFilterValue(updatedTab),
        ...params,
        sort: 'creationDate,desc',
      }, setOffGlobalSpinningOnHeader())
      this.state.paginationHelper.setTotalElements(onboardingPage?.totalElements ?? 0)

      this.setState({
        filteredData: onboardingPage?.content ?? [],
        activeTabKey: updatedTab,
        filters,
      })
    } catch (error) {
      errorPrint.printMessage(error)
      this.setState({
        filteredData: [],
        activeTabKey: updatedTab,
        filters,
      })
    }
  }

  getTableColumns = (tab: number,  showTypology: boolean, sentState: boolean) => {
    let columns: any[] = []
      if (showTypology) {
        columns.push({
          title: T.fields.company_code,
          dataIndex: 'data.companyCode'
        })
    }
    if (!sentState) {
      columns.push({
        title: T.fields.company_name,
        dataIndex: 'company.data.businessName'
      })
    }

    // showTypology &&
    //   !sentState &&
    //   columns.push({
    //     title: T.fields.typology,
    //     dataIndex: 'company.type'
    //   })

    !sentState &&
      columns.push({
        title: __(T.fields.categories),
        dataIndex: 'categories',
        renderType: 'categories'
      })

    columns = columns.concat([
      {
        title: __(T.fields.referent),
        dataIndex: 'guest.lastname'
      },
      {
        title: T.fields.email,
        dataIndex: 'guest.email',
      },
      {
        title: T.fields.creation_date,
        dataIndex: 'creationDate',
        renderType: 'dateTime'
      }
    ])
    columns = buildColumnsByConfiguration(columns)

    columns.push({
      title: __(T.titles.actions),
      key: 'action',
      render: (text, record) => (
          <>
            <Space size="middle">
              {record.state !== 'SENT' && (
                  <Button
                      type="link"
                      onClick={() => {
                        navigate('/reports/onboarding/company/:companyId', { companyId: record.company.id })
                      }}
                  >
                    {__(T.buttons.view)}
                  </Button>
              )}
              {record.state === 'ON_APPROVAL' && (
                  <Button
                      type="link"
                      onClick={async () => {
                        try {
                          await OnBoardings.approve(record.id)
                          showNotification(__(T.messages.onboarding_approved))
                        } catch (error) {
                          errorPrint.printMessage(error)
                        } finally {
                          this.onTabChangeFilter(this.state.activeTabKey)
                        }
                      }}
                  >
                    {__(T.buttons.approve)}
                  </Button>
              )}
              {record.state === 'SENT' && (
                  <Button
                      type="link"
                      onClick={async () => {
                        try {
                          await OnBoardings.resendEmail(record.id)
                          showNotification(__(T.messages.email_invitation_sent))
                        } catch (error) {
                          errorPrint.printMessage(error)
                        } finally {
                          this.onTabChangeFilter(this.state.activeTabKey)
                        }
                      }}
                  >
                    {__(T.buttons.new_invitation)}
                  </Button>
              )}
            </Space>
          </>
      ),
    })

    return columns
  }

  getStateFilterValue(tab?: '1' | '2') {
    const permission = getOperationsForRole()
    if (permission.onboardingReportToConfirm) {
      return tab === '1' ? 'ON_APPROVAL' : 'SENT'
    } else {
      return tab === '1' ? 'SENT' : 'APPROVED'
    }
  }

  isSentTab(tab: '1' | '2', isAdminTenant: boolean) {
    if (isAdminTenant) {
      return tab === '2'
    }
    return tab === '1'
  }

  handleUploadOrderTraceModalConfirm = async () => {
    this.setState({isUploadOnboardingModalVisible: false}, () =>  this.search());
  };

  handleUploadOrderTraceModalCancel = () => {
    this.setState({isUploadOnboardingModalVisible: false});
  };

  private exportTemplate() {
    OnBoardings.downloadOnBoardingTemplate();
  }

  render() {
    const { filteredData } = this.state
    const permission = getOperationsForRole()
    // const confirmableColumns = this.getTableColumns(permission.onboardingReportToConfirm, false)
    // const columns = this.getTableColumns(permission.onboardingReportToConfirm, false)
   // const sentColumns = this.getTableColumns(permission.onboardingReportToConfirm, true)

    const pagination = {
      onChange: async page => {
        await this.search(this.state.filters, page)
      },
      total: this.state.paginationHelper.totalElements,
      current: this.state.paginationHelper.currentPage,
      pageSize: this.state.paginationHelper.pageSize,
      showSizeChanger: false,
    }

    const scroll: { x: true } = { x: true }

    return (
      <Layout>
        <View style={{ padding: 20 }}>
          <View style={{ flex: 1, display: 'flex', justifyContent: 'flex-end', marginBottom: 12 }}>
            <Button type="primary" onClick={() => navigate('/reports/onboarding/create')} style={{ minWidth: 200, marginRight: 10 }}>
              {__(T.buttons.invite_new)}
            </Button>
            <Button type="primary" onClick={() => this.setState({isUploadOnboardingModalVisible: true})} style={{ minWidth: 200, marginRight: 10 }}>
              {__(T.buttons.import_onboarding_from_xls)}
            </Button>
            <Button type="primary" onClick={() => this.exportTemplate()} style={{ minWidth: 200 }}>
              {__(T.buttons.export_onboarding_template)}
            </Button>
          </View>
          <View className="filter-box">
            <AdvancedForm2
              schema={buildFiltersByConfiguration(this.getFiltersConfig())}
              onValuesChange={(changedValues, values) => this.applyFilters(values)}
              values={{}}
              style={{ flex: 1 }}
            />
          </View>
          <View className="page-table">
            {permission.onboardingReportToConfirm && (
              <Tabs defaultActiveKey={this.state.activeTabKey} onChange={this.onTabChangeFilter}>
                <Tabs.TabPane tab={__(T.misc.sent)} key="1">
                  <Table
                    columns={this.getTableColumns(1, permission.onboardingReportToConfirm, true)}
                    dataSource={filteredData.map(d=>{return {...d,key:d.id}})}
                    bordered
                    pagination={pagination}
                    scroll={scroll}
                  />
                </Tabs.TabPane>
                <Tabs.TabPane tab={__(T.misc.to_confirm)} key="2">
                  <Table
                    columns={this.getTableColumns(2, permission.onboardingReportToConfirm, false)}
                    dataSource={filteredData.map(d=>{return {...d,key:d.id}})}
                    bordered
                    pagination={pagination}
                    scroll={scroll}
                  />
                </Tabs.TabPane>
              </Tabs>
            )}
            {!permission.onboardingReportToConfirm && (
              <Tabs defaultActiveKey={this.state.activeTabKey} onChange={this.onTabChangeFilter}>
                <Tabs.TabPane tab={__(T.misc.sent)} key="1">
                  <Table
                    columns={this.getTableColumns(1, permission.onboardingReportToConfirm, true)}
                    dataSource={filteredData}
                    bordered
                    pagination={pagination}
                    scroll={scroll}
                  />
                </Tabs.TabPane>
                <Tabs.TabPane tab={__(T.misc.confirmed)} key="2">
                  <Table columns={this.getTableColumns(2, permission.onboardingReportToConfirm, false)}
                         dataSource={filteredData}
                         bordered pagination={pagination}
                         scroll={scroll} />
                </Tabs.TabPane>
              </Tabs>
            )}
          </View>
        </View>
        <UploadOnboardingFileModal
          visible={this.state.isUploadOnboardingModalVisible}
          onConfirm={this.handleUploadOrderTraceModalConfirm}
          onCancel={this.handleUploadOrderTraceModalCancel}
        />
      </Layout>
    )
  }
}
