import React from 'react'
import { Layout, View, StyleTraceability, MainConfigurations } from 'components'
import { __, T } from 'config/i18n'
import { Typography, Tabs } from 'antd'

interface Props {}

interface State {
  activeTabKey: string
}

const { Title } = Typography

export default class Settings extends React.Component<Props, State> {
  state: State = {
    activeTabKey: 'main',
  }

  render() {
    const { activeTabKey } = this.state
    return (
      <Layout>
        <View style={{ padding: 20 }}>
          <View className="admin-page">
            <Title style={{ paddingLeft: 20 }} level={3}>
              {__(T.buttons.traceability_settings)}
            </Title>
            <Tabs className="tabs-padding" defaultActiveKey={activeTabKey}>
              <Tabs.TabPane key={'main'} tab={__(T.misc.main_configurations)}>
                <MainConfigurations />
              </Tabs.TabPane>
              <Tabs.TabPane key={'style'} tab={__(T.misc.style_traceability)}>
                <StyleTraceability />
              </Tabs.TabPane>
            </Tabs>
          </View>
        </View>
      </Layout>
    )
  }
}
