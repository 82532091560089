import React from 'react'
import { CompanyFormLayout, Layout, Modal, View } from 'components'
import { __, T } from 'config/i18n'
import { showNotification } from 'helpers/Notifications'
import { CompanyFields } from 'types'
import { Button, Spin } from 'antd'
import { Subsuppliers } from 'api'
import theme from 'theme/theme'
import AppStore from 'AppStore'
import { Subsupplier } from 'api/types'
import {
  getCompanyFieldsFromCompany,
} from 'helpers/mapper'
// import { geocodeByPlaceId } from 'react-places-autocomplete'

interface Props {
  match: { params: { subsupplierId: string } }
}

interface State {
  companyFields: CompanyFields
  showConfirmDialog: boolean
  loading: boolean
}

export default class SubSupplierDetail extends React.Component<Props, State> {
  state: State = {
    companyFields: {},
    loading: false,
    showConfirmDialog: false,
  }
  subsupplier?: Subsupplier

  async componentDidMount() {
    const subsuppliers = (
      await Subsuppliers.findOne(AppStore.loggedUser?.company?.id, this.props.match.params.subsupplierId)
    )?.content
    if (!subsuppliers || subsuppliers?.length === 0) return
    const subsupplier = subsuppliers[0]
    this.subsupplier = subsupplier
    // const googlePlaceDetail = subsupplier?.data.placeId ? await geocodeByPlaceId(subsupplier?.data.placeId) : undefined
    const companyFields = getCompanyFieldsFromCompany(subsupplier)

    this.setState({ companyFields: companyFields })
  }

  goBack = () => {
    // navigate('/reports/subsupplier')
  }

  save = async () => {
    const subsupplier = this.subsupplier
    if (!subsupplier) return
    const companyFields = this.state.companyFields
    subsupplier.data = {
      businessName: companyFields.businessName ?? '',
      legalCode: companyFields.pivaCf ?? '',
      cap: companyFields.postalCode ?? '',
      address: companyFields.address ?? '',
      addressNumber: companyFields.addressNumber ?? '',
      phone: companyFields.companyPhone ?? '',
      mobilePhone: companyFields.companyMobilePhone ?? '',
      email: companyFields.companyEmail ?? '',
      pec: companyFields.companyEmailPec ?? '',
      ethicalCode: companyFields.adherenceEthicsCode,
      suppressionMethod: companyFields.suppressionMethod,
      country: companyFields.country,
      city: companyFields.city
    }
    subsupplier.companyCode = companyFields.companyCode ?? ''
    // subsupplier.certifications = this.state.companyFields.certifications ?? []
    try {
      this.setState({ loading: true })
      // const companyRes = (await Subsuppliers.update(subsupplier)) as Company
      showNotification(__(T.messages.company_datas_updated))
      // TODO WHAT TO DO HERE
      // await uploadCertificates(companyRes.id, this.state.companyFields)
      // navigate('/reports/subsupplier')
    } catch (e) {
      console.log(e)
    }
    this.setState({ loading: false })
  }

  enable = async () => {
    this.setState({ showConfirmDialog: false })
    try {
      await Subsuppliers.enable(this.subsupplier?.id)
      showNotification(__(T.messages.company_enabled))
      // navigate('/reports/subsupplier')
    } catch (e) {
      console.log(e)
    }
  }

  render() {
    const { companyFields } = this.state
    return (
      <Layout>
        <Spin spinning={this.state.loading} delay={500}>
          <View style={{ padding: 20 }}>
            <View className="page-table" style={{ position: 'relative' }}>
              {!this.subsupplier?.enabled && (
                <Button
                  style={{
                    position: 'absolute',
                    top: 0,
                    right: 0,
                    margin: 40,
                    minWidth: 200,
                    ...theme.temeraPrimaryGreenButton,
                  }}
                  onClick={() => this.setState({ showConfirmDialog: true })}
                >
                  {__(T.buttons.enable)}
                </Button>
              )}
              <CompanyFormLayout
                fieldsVisibility={{ companyCategory: true, companyDatas: true, certifications: true }}
                fieldsDisabled={{ companyCategory: true, companyDatas: false }}
                onValuesChange={(changedValues, values) => {
                  this.setState({ companyFields: values })
                }}
                companyFields={companyFields}
                submitButton={{
                  text: __(T.buttons.save),
                  col: 6,
                  style: theme.temeraPrimaryGreenButton,
                  onPress: this.save,
                }}
              />
            </View>
          </View>
        </Spin>
        <Modal
          title={__(T.confirms.confirm_operation)}
          message={__(T.confirms.confirm_or_go_back_to_change_the_data)}
          visible={this.state.showConfirmDialog}
          confirmButtonTitle={__(T.buttons.confirm)}
          closeButtonTitle={__(T.buttons.back)}
          onConfirmClick={this.enable}
          onCloseClick={() => {
            this.setState({ showConfirmDialog: false })
          }}
        />
      </Layout>
    )
  }
}
