import React from 'react'
import { View, Text } from 'components'
import { Avatar, Button } from 'antd'

interface Props {
  title: string
  data: { id: string; description: string }[]
  onItemPressCallback?: (id: string) => void
}

const AvatarLinkList: React.FC<Props> = ({ title, data, onItemPressCallback }) => {
  return (
    <View className="white-box" style={{ paddingBottom: 20 }}>
      <Text h1>{title}</Text>
      {data.map(item => (
        <View>
          <Avatar style={{ backgroundColor: '#CEFF00', color: '#000000' }}>
            {item.description.charAt(0).toUpperCase()}
          </Avatar>
          <Button
            type="link"
            onClick={() => {
              onItemPressCallback && onItemPressCallback(item.id)
            }}
          >
            {item.description}
          </Button>
        </View>
      ))}
    </View>
  )
}

export default AvatarLinkList
