import { AxiosRequestConfig } from 'axios'
import api, { responseErrorCheck } from './api'
import { __, T } from 'config/i18n'
import { QueryParams, Page, Properties, SupplyChain, SupplyChainStep } from 'api/types'
import { Companies } from 'api'

class SupplyChains<T = any> {
  protected endpoint = 'tracemanager/v1/supplychains'

  async search(params?: QueryParams, axiosConfig?: AxiosRequestConfig) {
    const res = await api.get<Page<SupplyChain>>(`${this.endpoint}`, params, axiosConfig)
    return responseErrorCheck(res)
  }

  async update(id: string, data: any, axiosConfig?: AxiosRequestConfig) {
    const res = await api.put<T>(`${this.endpoint}/${id}`, data, axiosConfig)
    return responseErrorCheck(res)
  }

  async save(data: any, axiosConfig?: AxiosRequestConfig) {
    const res = await api.post<T>(`${this.endpoint}`, data, axiosConfig)
    return responseErrorCheck(res)
  }

  async delete(id: string, axiosConfig?: AxiosRequestConfig) {
    const res = await api.delete<T>(`${this.endpoint}/${id}`, {}, axiosConfig)
    return responseErrorCheck(res)
  }

  async steps(params?: QueryParams, axiosConfig?: AxiosRequestConfig) {
    const res = await api.get<Page<SupplyChainStep>>(`${this.endpoint}/steps/not-generated`, params, axiosConfig)
    return responseErrorCheck(res)
  }

  async getFibersCode() {
    return [
    ]
  }

  async getSupplyChains() {
    let values: Properties[] = []
    const supply = await this.search({
      page: 0,
      size: 100,
      sort: 'createdDate,asc',
    })
    if (supply && supply.content) {
      for (let s = 0; s < supply.content.length; s++) {
        values.push({
          value: supply.content[s].id,
          label: supply.content[s].name,
        })
      }
    }
    return values
  }

  async getSupplyChainSteps() {
    let values: Properties[] = []
    const steps = await this.steps({
      page: 0,
      size: 100,
      sort: 'code,asc'
    })
    if (steps && steps.content) {
      for (let s = 0; s < steps.content.length; s++) {
        values.push({
          value: steps.content[s].id,
          label: steps.content[s].code,
        })
      }
    }
    return values
  }

  async getCompanies() {
    let values: any[] = []
    const companies = await Companies.search({
      withOnBoardingCreatorCompanyId: true,
      page: 0,
      size: 100,
    })
    if (companies && companies.content) {
      for (let s = 0; s < companies.content.length; s++) {
        values.push({
          value: companies.content[s].id,
          label: companies.content[s].data?.businessName || companies.content[s].companyCode,
        })
      }
    }

    return values
  }

  async getProperties() {
    const fiberCodes = await this.getFibersCode()
    const supplies = await this.getSupplyChains()
    const steps = await this.getSupplyChainSteps()
    const companies = await this.getCompanies()
    const prop: Properties[] = [
      {
        value: 'seasonEvents',
        type: 'SELECT',
        label: __(T.misc.seasonEvents),
        values: [],
      },
      { value: 'styleCodes', type: 'SELECT', label: __(T.misc.styleCodes), values: [] },
      {
        value: 'companyIds',
        type: 'SELECT',
        label: __(T.misc.companyIds),
        values: companies,
        mode: 'multiple',
        maxTagCount: 1,
      },
      { value: 'percentage', type: 'NUMBER', label: __(T.misc.percentage), values: [] },
      { value: 'fiberCodes', type: 'SELECT', label: __(T.misc.fiberCodes), values: fiberCodes, maxTagCount: 4 },
      {
        value: 'supplyChains',
        type: 'SELECT',
        label: __(T.misc.supplyChains),
        values: supplies,
        mode: 'multiple',
        maxTagCount: 1,
      },
      {
        value: 'traceTypeIds',
        type: 'SELECT',
        label: __(T.misc.traceTypeIds),
        values: steps,
        mode: 'multiple',
        maxTagCount: 1,
      },
    ]

    return prop
  }
  getEndpoint() {
    return this.endpoint
  }
}

const supplyChains = new SupplyChains()
export { supplyChains as default }
