import React, {RefObject} from 'react';
import {AppForm, Layout, View} from 'components';
import {LibraryConfigType, LibraryItem, LibraryItemWithCompanyInfo, Order} from '../../api/types';
import {Button, Col, Divider, Dropdown, Menu, MenuProps, Row, Space} from 'antd';
import {__, T} from 'config/i18n';
import Title from 'antd/es/typography/Title';
import {navigate} from '../../router';
import EntityPageHeader, {EntityPageHeaderConfig} from '../../components/orders/EntityPageHeader';
import {Companies} from '../../api';
import AppStore from '../../AppStore';
import {showNotification} from '../../helpers/Notifications';
import LibraryConfigs from '../../api/LibraryConfigs';
import LibraryItems from '../../api/LibraryItems';
import {admin, getUserRole} from '../../helpers/permission';
import JsonFormOnReadView from '../../components/views/JsonFormOnReadView';
import ImportTraceDialog from '../../components/orders/ImportTraceDialog';
import ImportLibraryDialog from '../../components/orders/ImportLibraryDialog';
import ImportTraceFromAnotherPO from '../../tools/ImportTraceFromAnotherPO';
import {DownOutlined} from '@ant-design/icons';
import Orders from '../../api/Orders';
import moment from "moment/moment";


interface Props {
  match: { params: { type: LibraryConfigType, libraryItemId?: string, libraryConfigId?: string } };
}

interface State {
  libraryItem?: LibraryItemWithCompanyInfo;
  attributesFormSubmitButtonRef: RefObject<HTMLButtonElement>;
  dataFormSubmitButtonRef: RefObject<HTMLButtonElement>;
  isImportTraceModalVisible: boolean;
  isImportLibraryModalVisible: boolean;
}

export default class LibraryDetail extends React.Component<Props, State> {

  private dataFormComplete: boolean = false;
  private attributeFormComplete: boolean = false;

  state: State = {
    attributesFormSubmitButtonRef: React.createRef<HTMLButtonElement>(),
    dataFormSubmitButtonRef: React.createRef<HTMLButtonElement>(),
    isImportTraceModalVisible: false,
    isImportLibraryModalVisible: false
  };

  componentDidMount = async () => {
    if (this.props.match.params.libraryItemId) {
      await this.reloadComponent(this.props.match.params.libraryItemId);
    } else {
      let libraryConfig = await LibraryConfigs.get(this.props.match.params.libraryConfigId!);

      let data = await Orders.retrieveDataSchema({
        productMatchCode: libraryConfig.traceType.code
      })

      this.setState({
        libraryItem: {
          id: undefined,
          creationCompanyName: undefined,
          creationDate: 0,
          lastUpdate: 0,
          libraryConfig: libraryConfig,
          traceType: libraryConfig.traceType,
          visibleForAdmin: this.props.match.params.type === 'DOCUMENT',
          data,
          attributes: {},
          creationCompany: AppStore.loggedUser?.company?.id
        }
      });
    }
  };

  componentDidUpdate = async (prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any) => {
    if (prevProps.match.params.libraryItemId !== prevState.libraryItem?.id) {
      if (!window.location.pathname.includes(prevProps.match.params.libraryItemId!)) {
        try {
          let libraryItemId = window.location.pathname.split('/')[3];
          if (!!libraryItemId) {
            console.log('reloadComponent');
            //  await this.reloadComponent(libraryItemId);
          }
        } catch (e) {
        }
      }
    }
  };

  isOnNewMode(): boolean {
    return !this.props.match.params.libraryItemId;
  }

  reloadComponent = async (libraryItemId: string) => {
    const libraryItem: LibraryItemWithCompanyInfo = await LibraryItems.get(libraryItemId);
    if (!libraryItem.data) {
      libraryItem.data = {};
    }
    if (libraryItem.creationCompany) {
      const company = await Companies.get(libraryItem.creationCompany);
      libraryItem.creationCompanyName = company?.data.businessName;
    }

    if (!libraryItem.attributes) {
      libraryItem.attributes = {};
    }
    if(libraryItem.startDate) {
      libraryItem.startDate = moment(libraryItem.startDate).format('YYYY-MM-DD')
    }
    if(libraryItem.endDate) {
      libraryItem.endDate = moment(libraryItem.endDate).format('YYYY-MM-DD')
    }

    this.setState({ libraryItem });
  };


  getPageHeaderExtras = (libraryItem: LibraryItem | undefined): JSX.Element[] => {
    let extras: JSX.Element[] = [];
    if (libraryItem === undefined) {
      return extras;
    }
    if (getUserRole() !== admin) {
      if (this.props.match.params.type === 'TRACE') {

        const items: MenuProps['items'] = [
          {
            label: __(T.buttons.import_from_trace),
            key: 'importTrace',
            onClick: this.openImportTraceModal
            //  icon: <UserOutlined />,
          },
          {
            label: __(T.buttons.from_library),
            key: 'importLibrary',
            onClick: this.openImportLibraryModal
            // icon: <UserOutlined />,
          }
        ];

        const menuProps = {
          items
          // onClick: handleImportButtonClick
        };


        extras.push(
          <Dropdown overlay={<Menu {...menuProps} />}>
            <Button>
              <Space>
                {__(T.buttons.import)}
                <DownOutlined />
              </Space>
            </Button>
          </Dropdown>
        );
      } else {
        extras.push(
          <Button type="default" style={{ width: '200px' }} onClick={() => this.openImportLibraryModal()}>
            {__(T.buttons.import_from_library)}
          </Button>);
      }
      extras.push(
        <Button type="primary" style={{ width: '200px' }} onClick={this.save}>
          {__(T.buttons.save)}
        </Button>);
    }
    return extras;
  };

  openImportLibraryModal = () => {
    this.setState({ isImportLibraryModalVisible: true });
  };

  openImportTraceModal = () => {
    this.setState({ isImportTraceModalVisible: true });
  };

  onCancelImportLibrary = () => {
    this.setState({ isImportLibraryModalVisible: false });
  };

  onCancelImportTrace = () => {
    this.setState({ isImportTraceModalVisible: false });
  };

  onConfirmImportTrace = async (order: Order, override: boolean) => {
    let tempOrder = JSON.parse(JSON.stringify(this.state.libraryItem));
    const orderToImportSchema = await Orders.get(order.id!);
    ImportTraceFromAnotherPO.importData(tempOrder, orderToImportSchema.data, override);
    this.setState({ libraryItem: tempOrder, isImportTraceModalVisible: false });
  };

  onConfirmImportLibrary = (libraryItem: LibraryItem, override: boolean) => {
    let tempOrder = JSON.parse(JSON.stringify(this.state.libraryItem));
    ImportTraceFromAnotherPO.importData(tempOrder, libraryItem.data, override);
    this.setState({ libraryItem: tempOrder, isImportLibraryModalVisible: false });
  };


  save = () => {
    this.dataFormComplete = false;
    this.attributeFormComplete = false;
    this.state.attributesFormSubmitButtonRef.current?.click();
    this.state.dataFormSubmitButtonRef.current?.click();
  };

  getPageHeaderConfig = (): EntityPageHeaderConfig => {
    return this.props.match.params.libraryItemId ? {
        header: [{
          path: 'name',
          type: 'BIG'
        }]
      } :
      {
        header: [{
          label: this.props.match.params.type === 'TRACE' ? __(T.misc.create_new_library_item) : __(T.misc.create_new_document),
          type: 'LABEL'
        }]
      };
  };

  handleAttributeSuccess = (values: any) => {
    this.attributeFormComplete = true;
    this.state.libraryItem!.name = values.name + '';
    this.state.libraryItem!.startDate = !!values.startDate ? values.startDate +"T00:00:00.000Z" : undefined;
    this.state.libraryItem!.endDate = !!values.endDate ? values.endDate +"T23:59:59.000Z" : undefined;
    if (this.props.match.params.type === 'TRACE') {
      this.state.libraryItem!.visibleForAdmin = values.visibleForAdmin;
    } else {
      this.state.libraryItem!.visibleForAdmin = true;
    }
    this.state.libraryItem!.attributes = values.attributes;
    this.performSave();
  };

  handleDataSuccess = (values: any) => {
    this.dataFormComplete = true;
    this.state.libraryItem!.data = values;
    this.performSave().then(() => console.log('item saved'));
  };

  performSave = async () => {
    if (this.attributeFormComplete && this.dataFormComplete) {
      if (this.props.match.params.libraryItemId) {
        let res = await LibraryItems.update(this.state.libraryItem!.id, this.state.libraryItem!);
        this.setState({ libraryItem: res }, () => {
          showNotification(
              __(T.messages.item_saved_title),
              __(T.messages.item_saved),
              'success',
              3
          );
        });


      } else {
        let res = await LibraryItems.save(this.state.libraryItem!);
        this.setState({ libraryItem: res }, () => {
          navigate('/reports/libraries/:type/edit/:libraryItemId', {
            libraryItemId: res.id,
            type: this.props.match.params.type
          });
          showNotification(
            __(T.messages.new_item),
            __(T.messages.new_item_saved),
            'success',
            3
          );
        });
      }
    }
  };

  getAttributesSchema = () => {

    let value = this.state.libraryItem?.libraryConfig.schema ? this.getSchema(this.state.libraryItem?.libraryConfig.schema) : {
      type: 'object',
      required: [],
      properties: {}
    };


    value.required.push('name');

    for (let key of Object.keys(value.properties)) {
      let prop = value.properties[key];
      prop.name = prop.name ? 'attributes.' + prop.name : undefined;
    }


    let tmpProperty: any = {
      name: {
        type: 'string',
        span: 8,
        name: 'name',
        title: this.props.match.params.type === 'DOCUMENT' ? __(T.fields.document_item_name) : __(T.fields.document_item_article_name)
      }
    };

    if (this.props.match.params.type === 'TRACE') {
      tmpProperty.visibleForAdmin = {
        type: 'boolean',
        span: 12,
        name: 'visibleForAdmin',
        title: __(T.fields.shared_with_brand)
      };
    } else {
      tmpProperty.startDate = {
        type: 'string',
        isDate: true,
        span: 6,
        name: 'startDate',
        title: __(T.fields.start_date)
      };
      tmpProperty.endDate = {
        type: 'string',
        isDate: true,
        span: 6,
        name: 'endDate',
        title: __(T.fields.end_date)
      };
    }


    if (Object.keys(value.properties).length > 0) {
      tmpProperty.attributes = {
        type: 'object',
        title: __(T.fields.insert_reference_data),
        span: 12,
        properties: value.properties
      };
    }


    value.properties = tmpProperty;

    return value;


  };

  getSchema = (schema: string) => {
    const schemaTmp = JSON.parse(schema);
    if (schemaTmp) {
      schemaTmp.title = undefined;
    }
    return schemaTmp;
  };

  getMain() {
    if (this.isOnNewMode()) {
      switch (this.props.match.params.type) {
        case 'DOCUMENT':
          return __(T.titles.document_item_section_new_main);
        case 'TRACE':
          return __(T.titles.library_item_section_new_main);
      }
    }
    switch (this.props.match.params.type) {
      case 'DOCUMENT':
        return __(T.titles.document_item_section_main);
      case 'TRACE':
        return __(T.titles.library_item_section_main);
    }
    return '';
  }

  getDataTitle() {
      switch (this.props.match.params.type) {
        case 'DOCUMENT':
          return __(T.titles.document_item_section_data);
        case 'TRACE':
          return __(T.titles.library_item_section_data);
      }
    return '';
  }

  isToValidateSchemaSection():boolean {
    switch (this.props.match.params.type) {
      case 'DOCUMENT':
        return true;
      case 'TRACE':
        return false;
    }
    return false;
  }

  render = () => {
    const { libraryItem } = this.state;
    if (!libraryItem) {
      return (
        <Layout children={null} />
      );
    }
    return (
      <Layout>
        <View style={{ margin: 0, marginBottom: 1 }}>
          <EntityPageHeader
            data={libraryItem}
            extras={this.getPageHeaderExtras(libraryItem)}
            onBack={() => navigate('/reports/libraries/:type', { type: this.props.match.params.type })}
            config={this.getPageHeaderConfig()} />
        </View>
        <View className="page-table" style={{ margin: 10, marginTop: 0, borderRadius: '0px' }}>
         {/*<Title level={4}>{this.getMain()}</Title>*/}
         {/* <Divider />*/}
          <Row>
            <Col span={16}>
              {getUserRole() === admin ? (
                <JsonFormOnReadView schema={this.getAttributesSchema()}
                                    data={libraryItem} />
              ) : (
                <AppForm
                  schema={this.getAttributesSchema()}
                  data={{
                    name: libraryItem.name,
                    startDate: libraryItem.startDate,
                    endDate: libraryItem.endDate,
                    visibleForAdmin: libraryItem.visibleForAdmin,
                    attributes: libraryItem.attributes
                  }}
                  onSuccess={this.handleAttributeSuccess}
                  formSubmitButtonRef={this.state.attributesFormSubmitButtonRef}
                  ownerCompanyId={libraryItem?.creationCompany!}
                  onChange={item => {
                    let libraryItem = this.state.libraryItem;
                    if (!!libraryItem) {
                      libraryItem.name = item.name;
                      libraryItem.startDate = item.startDate;
                      libraryItem.endDate = item.endDate;
                      libraryItem.visibleForAdmin = item.visibleForAdmin;
                      libraryItem.attributes = item.attributes;
                    }
                    this.setState({
                      libraryItem
                    });
                  }}
                />
              )}
            </Col>
          </Row>
        </View>
        <View className="page-table" style={{ margin: 10, marginTop: 0, borderRadius: '0px' }}>
          <Title level={4}>{this.getDataTitle()}</Title>
          <Divider />
          <Row>
            {getUserRole() === admin ?
              (<Col span={24}>
                  <JsonFormOnReadView schema={this.getSchema(libraryItem?.traceType.schema)}
                                      data={libraryItem.data} />
                </Col>
              ) : (<Col span={16}>
                <AppForm
                  schema={this.getSchema(libraryItem?.traceType.schema)}
                  data={libraryItem?.data}
                  onSuccess={this.handleDataSuccess}
                  readonly={false}
                  noValidate={!this.isToValidateSchemaSection()}
                  formSubmitButtonRef={this.state.dataFormSubmitButtonRef}
                  ownerCompanyId={libraryItem?.creationCompany!}
                />
              </Col>)}
          </Row>
          {!!libraryItem && !!libraryItem.data &&
            <ImportTraceDialog
              isVisible={this.state.isImportTraceModalVisible}
              onCancel={this.onCancelImportTrace}
              onSuccess={this.onConfirmImportTrace}
              traceTypeCode={libraryItem.traceType.code}
              title={__(T.titles.import_trace_dialog)}
            />

          }
          {!!libraryItem && !!libraryItem.data &&
            <ImportLibraryDialog
              isVisible={this.state.isImportLibraryModalVisible}
              onCancel={this.onCancelImportLibrary}
              onSuccess={this.onConfirmImportLibrary}
              traceTypeId={libraryItem.traceType.id}
              title={__(T.titles.import_library_dialog)} />
          }
        </View>
      </Layout>
    );
  };

}
