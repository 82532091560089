import React, { Component } from 'react';
import { Layout, View } from 'components';
import { __, T } from 'config/i18n';
import { Avatar, Empty } from 'antd';
import { Download, Page } from 'api/types';
import Title from 'antd/es/typography/Title';
import { DownloadOutlined } from '@ant-design/icons';
import DownloadCard from '../../components/downloads/DownloadCard';
import exportData from '../../api/ExportData';
import { setOffGlobalSpinningOnHeader } from '../../api/api';
import { AxiosRequestConfig } from 'axios';

interface Props {}

interface State {
  downloadPage?: Page<Download>;
}


export default class DownloadsReport extends Component<Props, State> {

  private pollingInterval: NodeJS.Timeout | null = null;
  state: State = {};

  async componentDidMount() {

    await this.downloadData();
    this.pollingInterval = setInterval(this.fetchDownloadData, 3000); // 5000ms -> 5s

  }

  componentWillUnmount() {
    // Pulisci l'intervallo quando il componente viene smontato
    if (this.pollingInterval) {
      clearInterval(this.pollingInterval);
    }
  }

  downloadData = async (header?: AxiosRequestConfig) => {
    try {
      let downloadPage: Page<Download> = await exportData.search({
        // ...params,
        page: 0,
        size: 50,
        sort: 'creationDate,desc' // 'asc' | 'desc'
      }, header);

      this.setState({ downloadPage });
    } catch (error) {
      console.error('Errore durante il recupero dei dati di download', error);
    }
  };

  fetchDownloadData = async () => {
    await this.downloadData(setOffGlobalSpinningOnHeader())
  };

   download(downloadId: string) {
    exportData.getFile(downloadId).then();
  }



  render() {
    return (
      <Layout>
        {!!this.state.downloadPage &&
          <>
            <View
              hidden={this.state.downloadPage?.content.length === 0}
              style={{ padding: 20 }}>
              <View style={{ flex: 1, display: 'flex', justifyContent: 'flex-start', marginBottom: 12 }}>
                <Avatar style={{ color: '#f56a00', marginLeft: 25, marginRight: 5 }} icon={<DownloadOutlined />} />
                <Title level={4}>{__(T.titles.downloads)}</Title>
              </View>


              <View className="page-table">
                    {this.state.downloadPage.content.map((n: Download) =>
                      <DownloadCard download={n}  runDownload={this.download}/>)}
              </View>
            </View>
            <View
              hidden={this.state.downloadPage?.content.length !== 0}
              style={{ padding: 20 }}>
              <Empty />
            </View></>
        }
      </Layout>
    );
  }


}
