import {__, T} from 'config/i18n'

export const AuthorizedRoutes = {}

export const getPublicRoutes = () => {
  return {
    '/': { component: require('pages/Dashboard').default, breadcrumbName: () => __(T.buttons.dashboard) },
    '/login': { component: require('pages/login/Login').default, breadcrumbName: () => __(T.buttons.login) },
    '/registration': { component: require('pages/registration/Registration').default },
     '/reports/traces': {
       component: require('pages/traces/TracesReport').default,
       breadcrumbName: () => __(T.buttons.traces_report),
     },
     '/reports/traces/:traceId': {
       component: require('pages/traces/TraceDetail').default,
       breadcrumbName: () => __(T.buttons.trace_detail),
     },
     '/traces/create': {
       component: require('pages/traces/CreateTrace').default,
       breadcrumbName: () => __(T.buttons.create_trace),
     },
    // ******************************** REPORTS *******************************
    '/reports/onboarding': {
      component: require('pages/onboarding/OnboardingReport').default,
      breadcrumbName: () => __(T.buttons.onboarding),
    },
    '/reports/onboarding/create': {
      component: require('pages/onboarding/CreateOnboarding').default,
      breadcrumbName: () => __(T.buttons.create_new),
    },
    '/reports/companies/newByVatCode': {
      component: require('pages/companies/CreateOnboardingByVatCode').default,
      breadcrumbName: () => __(T.buttons.create_new_by_vat_code),
    },
    '/reports/libraries/:type': { // libreria di tipo trace e di tipo documents (type sempre popolato)
      component: require('pages/libraries/LibrariesReport').default,
      breadcrumbName: (params) => __(T.buttons['libraryList_' + params.type]),
    },
    '/reports/libraries/:type/edit/:libraryItemId': { // libreria di tipo trace e di tipo documents (type sempre popolato)
      component: require('pages/libraries/LibraryDetail').default,
      breadcrumbName: (params) => __(T.buttons['libraryEdit_' + params.type]),
    },
    '/reports/libraries/:type/new/:libraryConfigId': {
      component: require('pages/libraries/LibraryDetail').default,
      breadcrumbName: (params) => __(T.buttons['libraryNew_' + params.type]),
    },
    '/reports/orders': {
      component: require('pages/orders/OrdersReport').default,
      breadcrumbName: () => __(T.buttons.orders_report),
    },
    '/reports/enquiry/:type': {
      component: require('pages/orders/OrdersReport').default,
      breadcrumbName: (params) => __(T.buttons.orders_report_dynamic) + ' ' + params.type,
    },
    '/reports/orders/detail/:orderId': {
      component: require('pages/orders/OrderDetail').default,
      breadcrumbName: () => __(T.buttons.order_detail),
    },
    '/reports/enquiry/:type/detail/:orderId': {
      component: require('pages/orders/OrderDetail').default,
      breadcrumbName: () => __(T.buttons.order_detail),
    },
    '/reports/customerorders': {
      component: require('pages/customerorders/CustomerOrdersReport').default,
      breadcrumbName: () => __(T.buttons.customer_orders_report),
    },
    '/reports/customerorders/detail/:orderId': {
      component: require('pages/customerorders/CustomerOrderDetail').default,
      breadcrumbName: () => __(T.buttons.order_detail),
    },
    '/reports/rawmaterialorders': {
      component: require('pages/rawmaterialsourceorders/RawMaterialSourceOrdersReport').default,
      breadcrumbName: () => __(T.buttons.raw_material_source_orders_report),
    },
    '/reports/rawmaterialorders/detail/:orderId': {
      component: require('pages/rawmaterialsourceorders/RawMaterialSourceOrderDetail').default,
      breadcrumbName: () => __(T.buttons.order_detail),
    },
    '/reports/orders/handmade/new/:orderHandmadeConfigCode': {
      component: require('pages/orders/OrderHandmadeEdit').default,
      breadcrumbName: () => __(T.buttons.order_handmade_new),
    },
    '/reports/enquiry/:type/handmade/new/:orderHandmadeConfigCode': {
      component: require('pages/orders/OrderHandmadeEdit').default,
      breadcrumbName: () => __(T.buttons.order_handmade_new),
    },
    '/reports/orders/handmade/edit/:orderId': {
      component: require('pages/orders/OrderHandmadeEdit').default,
      breadcrumbName: () => __(T.buttons.order_handmade),
    },
    '/reports/enquiry/:type/handmade/edit/:orderId': {
      component: require('pages/orders/OrderHandmadeEdit').default,
      breadcrumbName: () => __(T.buttons.order_handmade),
    },
    '/reports/ordersMultiAssign': {
      component: require('pages/orders/OrderMultiAssign').default,
      breadcrumbName: () => __(T.buttons.order_detail),
    },
    '/reports/enquiryMultiAssign/:type': {
      component: require('pages/orders/OrderMultiAssign').default,
      breadcrumbName: () => __(T.buttons.order_detail),
    },
    '/reports/orders/detail/:orderId/assign': {
      component: require('pages/orders/OrderAssign').default,
      breadcrumbName: () => __(T.buttons.order_assignment),
    },
    '/reports/enquiry/:type/detail/:orderId/assign': {
      component: require('pages/orders/OrderAssign').default,
      breadcrumbName: () => __(T.buttons.order_assignment),
    },
    '/reports/orders/detail/:orderId/fulfill/:childId': {
      component: require('pages/orders/OrderFulfill').default,
      breadcrumbName: () => __(T.buttons.order_fulfillment),
    },
    '/reports/enquiry/:type/detail/:orderId/fulfill/:childId': {
      component: require('pages/orders/OrderFulfill').default,
      breadcrumbName: () => __(T.buttons.order_fulfillment),
    },
    '/reports/orders/detail/:orderId/fulfillThirdpart/:childId/:thirdPart': {
      component: require('pages/orders/OrderFulfill').default,
      breadcrumbName: () => __(T.buttons.order_fulfillment),
    },
    '/reports/enquiry/:type/detail/:orderId/fulfillThirdpart/:childId/:thirdPart': {
      component: require('pages/orders/OrderFulfill').default,
      breadcrumbName: () => __(T.buttons.order_fulfillment),
    },
    '/reports/companies': {
      component: require('pages/companies/CompanyReport').default,
      breadcrumbName: () => __(T.buttons.companies_management),
    },
    '/reports/companies/new': {
      component: require('pages/companies/CompanyDetail').default,
      breadcrumbName: () => __(T.buttons.company_detail),
    },
    '/reports/companies/:companyId': {
      component: require('pages/companies/CompanyDetail').default,
      breadcrumbName: () => __(T.buttons.company_detail),
    },
    '/reports/company/:companyId': {
      component: require('pages/companies/CompanyDetail').default,
      breadcrumbName: () => __(T.buttons.company_detail),
    },
    '/reports/onboarding/company/:companyId': {
      component: require('pages/companies/CompanyDetail').default,
      breadcrumbName: () => __(T.buttons.company_detail),
    },
    '/reports/company': {
      component: require('pages/companies/CompanyDetail').default,
      breadcrumbName: () => __(T.buttons.company_management),
    },
    '/reports/users': {
      component: require('pages/users/UsersReport').default,
      breadcrumbName: () => __(T.buttons.users_management),
    },
    '/reports/profileManagment': {
      component: require('pages/users/ProfileManagment').default,
      breadcrumbName: () => __(T.buttons.profile_managment),
    },
    '/reports/users/new': {
      component: require('pages/users/UserEdit').default,
      breadcrumbName: () => __(T.buttons.create_new),
    },
    '/reports/users/:userId': {
      component: require('pages/users/UserEdit').default,
      breadcrumbName: () => __(T.buttons.users_management),
    },
    '/userNotFound': {component: require('pages/users/UserNotFound').default},
    // '/reports/subsupplier': {
    //   component: require('pages/subsuppliers/SubSupplierReport').default,
    //   breadcrumbName: __(T.buttons.subsuppliers),
    // },
    // '/reports/subsupplier/create': {
    //   component: require('pages/subsuppliers/CreateSubSupplier').default,
    //   breadcrumbName: __(T.buttons.new_subsupplier),
    // },
    // '/reports/subsupplier/details/:subsupplierId': {
    //   component: require('pages/subsuppliers/SubSupplierDetail').default,
    //   breadcrumbName: __(T.buttons.subsuppliers),
    // },
    '/reports/downloads': {
      component: require('pages/downloads/DownloadsReport').default,
      breadcrumbName: () => __(T.buttons.downloads_management),
    },
    '/form/builder': {
      component: require('pages/form/FormBuilder').default,
      breadcrumbName: () => __(T.buttons.form_builder),
    },
    '/admin/settings': {
      component: require('pages/admin/Settings').default,
      breadcrumbName: () => __(T.buttons.traceability_settings),
    },
    '/analytics': {
      component: require('pages/analytics/Analytics').default,
      breadcrumbName:() => __(T.buttons.analytic),
    },
    '/notificationstcare/report': {
      breadcrumbName: () => __(T.buttons.all_notifications),
      component: require('pages/notifications/NotificationsReport').default,
    }

  }
}

export type Route = {
  component: React.ComponentClass | React.FC
  breadcrumbName?: ((params: any) => string)
}

const PublicRoutes = getPublicRoutes()
export type routes = keyof typeof AuthorizedRoutes | keyof typeof PublicRoutes

export type routesMenu = routes
  // These routes are dynamical (with enum)
  | '/reports/libraries/TRACE' | '/reports/libraries/DOCUMENT'

export const getRoutesMap = (): RoutesMap => {
  return {...getPublicRoutes(), ...AuthorizedRoutes }
}

export type RoutesMap = Record<routes, Route>

export const resolveReportOrdersBasePath = (type?: string): string => {
  return type ? `/reports/enquiry/${type}` : '/reports/orders'
}