import React from 'react'
import { Layout, AdvancedForm, View, EmptyListText, Text } from 'components'
import { Schema } from 'components/AdvancedForm'
import { __, T } from 'config/i18n'
import { Table, Space, Button } from 'antd'
import { Traces, HardCoded, TraceTypes, Companies } from 'api'
import { Trace } from 'api/types'
import { PaginationHelper } from 'PaginationHelper'
import { describeCategory } from 'helpers/describer'
import { getOperationsForRole } from 'helpers/permission'
import moment from 'moment-timezone'
import AppStore from 'AppStore'
import { navigate } from "../../router";

interface Props {}

interface State {
  filteredTraces: Trace[]
  paginationHelper: PaginationHelper
  traceTypesOptions: { label: string; value: any }[]
  filters: any
}

export default class TracesReport extends React.Component<Props, State> {
  state: State = {
    filteredTraces: [],
    paginationHelper: new PaginationHelper(5),
    traceTypesOptions: [],
    filters: {},
  }
  subsuppliers: { [id: string]: string } = {}
  fetchTraceTypes = async () => {
    const traceTypes = await TraceTypes.findAll()
    if (!traceTypes) return
    const traceTypesOptions = traceTypes.content.map(traceType => {
      return { value: traceType.id, label: traceType.network }
    })
    if (traceTypes !== undefined) this.setState({ traceTypesOptions })
  }
  async componentDidMount() {
    try {
      await Promise.all([this.applyFilters(), this.fetchTraceTypes(), this.fetchSubsuppliers()])
    } catch (e) {}
  }

  fetchSubsuppliers = async () => {
    if (!AppStore.loggedUser?.company) return
    let subsuppliers = await Companies.findAllSubsupplier(AppStore.loggedUser?.company.id)
    subsuppliers?.forEach(subsupplier => {
      this.subsuppliers[subsupplier.id] = subsupplier.label
    })
  }

  async applyFilters(filters: {} = {}, page?: number) {
    this.state.paginationHelper.setCurrentPage(page ?? 1)
    let params = {}
    if (filters['free_search'] && filters['free_search'] !== '') {
      params['codeRegex'] = filters['free_search']
    }
    if (filters['creationDate'] && filters['creationDate'].length > 0) {
      params['creationDateFrom'] = filters['creationDate'][0]._d
      params['creationDateTo'] = filters['creationDate'][1]._d
    }
    if (filters['company'] && filters['company'] !== '') {
      params['companyId'] = filters['company']
    }
    if (filters['traceType'] && filters['traceType'] !== '') {
      params['traceType'] = filters['traceType']
    }
    if (filters['category'] && filters['category'] !== '') {
      params['category'] = filters['category']
    }
    const tracesPage = await Traces.search({
      ...this.state.paginationHelper.getPaginationApiParams(),
      ...params,
      sort: 'creationDate,desc',
    })

    this.state.paginationHelper.setTotalElements(tracesPage?.totalElements ?? 0)

    this.setState({
      filteredTraces: tracesPage?.content ?? [],
      filters,
    })
  }

  async hideShowTrace(idTrace: string, hideTrace: boolean) {

    const trace: Trace = await Traces.changeHideTraceFlag(idTrace, hideTrace);
    if (!trace) {
      return
    }

    this.state.filteredTraces.find(trace => {
      return trace.id === idTrace
    })!.hideTrace = trace.hideTrace

    this.setState({
      filteredTraces: this.state.filteredTraces,
      filters: this.state.filters
    })

  }

  render() {
    const { filteredTraces, traceTypesOptions } = this.state
    const filterSchema: Schema[] = [
      {
        name: 'free_search',
        label: __(T.misc.free_search),
        type: 'text',
        placeholder: __(T.misc.id_lot_etc),
        col: { xs: 24, sm: { span: 8 } },
      },
      {
        name: 'traceType',
        type: 'select',
        label: __(T.fields.trace_type),
        placeholder: __(T.misc.select),
        options: traceTypesOptions,
        col: { xs: 24, sm: { span: 6 } },
      },
      {
        name: 'category',
        type: 'select',
        label: __(T.fields.category),
        placeholder: __(T.misc.select),
        options: HardCoded.getCategoryOptions(),
        col: { xs: 24, sm: { span: 5 } },
      },
      {
        name: 'creationDate',
        type: 'daterange',
        label: __(T.fields.creation_date),
        dateRangePlaceholders: [__(T.misc.start), __(T.misc.end)],
        col: { xs: 24, sm: { span: 5 } },
      },
    ]


    const columns = [
      {
        title: __(T.fields.trace_identifier),
        dataIndex: 'id',
        href: '#',
        render: (text, record: Trace) => <Text>{record.identifier.code}</Text>,
      },

      {
        title: __(T.fields.trace_type),
        dataIndex: 'traceType',
        render: (text, record: Trace) => <Text>{record.traceType?.network}</Text>,
      },
      {
        title: __(T.fields.company),
        dataIndex: 'company',
        render: (text, record: Trace) => (
          <Text>
            {record.creationCompany && this.subsuppliers.hasOwnProperty(record.creationCompany)
              ? this.subsuppliers[record.creationCompany]
              : record.creationUser}
          </Text>
        ),
      },
      {
        title: __(T.fields.category),
        dataIndex: 'category',
        render: (text, record: Trace) => <Text>{describeCategory(record.traceType?.category)}</Text>,
      },

      {
        title: __(T.fields.creation_date),
        dataIndex: 'creationDate',
        render: (text, record: Trace) => (
          <Text>
            {moment
              .utc(record.creationDate)
              .clone()
              .tz('Europe/Rome')
              .format('DD-MM-YYYY HH:mm')}
          </Text>
        ),
      },
      {
        title: __(T.fields.actions),
        key: 'action',
        render: (text, record) => (
          <Space size="middle">
            <Button
              type="link"
              onClick={() => {
                navigate('/reports/traces/:traceId', { traceId: record.id })
              }}
            >
              {__(T.buttons.view)}
            </Button>

            {AppStore.loggedUser?.company ===  null ?   <Button
              type="link"
              onClick={() => {
                this.hideShowTrace(record.id, !record.hideTrace).then()
              }}
            >
              {record.hideTrace ? __(T.buttons.show_trace): __(T.buttons.hide_trace)}
            </Button> : ''}

          </Space>
        ),
      },
    ]

    const pagination = {
      onChange: async page => {
        await this.applyFilters(this.state.filters, page)
      },
      total: this.state.paginationHelper.totalElements,
      current: this.state.paginationHelper.currentPage,
      pageSize: this.state.paginationHelper.pageSize,
      showSizeChanger: false,
    }

    const operations = getOperationsForRole()
    const canCreateTrace = operations.traceCreatePersonal || operations.traceCreateThirdParts

    return (
      <Layout>
        <View style={{ padding: 20 }} key={'createTraceKey'}>
          {canCreateTrace && (
            <View style={{ flex: 1, display: 'flex', justifyContent: 'flex-end', marginBottom: 12 }}>
              <Button
                type="primary"
                onClick={() => {
                  navigate('/traces/create')
                }}
                style={{ minWidth: 200 }}
              >
                {__(T.buttons.create_trace)}
              </Button>
            </View>
          )}
          <View className="filter-box">
            <AdvancedForm
              schema={filterSchema}
              onValuesChange={(changedValues, values) => this.applyFilters(values)}
              style={{ flex: 1 }}
            />
          </View>

          <Table
            id={'traceReportKey'}
            columns={columns}
            dataSource={filteredTraces}
            bordered
            locale={{ emptyText: <EmptyListText message={__(T.messages.no_trace_found)} /> }}
            pagination={pagination}
            rowClassName={(record, index) => (record.hideTrace  ? "trace_hide" : "")}
            scroll={{ x: true }}
          />
        </View>
      </Layout>
    )
  }
}
