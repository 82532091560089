import React from 'react'
import { OrderShipment } from '../../api/types'

interface Props {
    orderShipment: OrderShipment
    style: React.CSSProperties
}

interface State {}


export default class OrderCardShipment extends React.Component<Props, State> {
    state: State = {}

  render = () => {
    const { orderShipment } = this.props
    return (
        <>
        <div className={"trace_type_order"} style={{
            ...(this.props.style ?? {})}}>
            <div style={{width: '180px'}} className={"trace_type_order_cell"}>
                <span className={"trace_type_order_label"}>Serial Number</span>
                <div className={"trace_type_order_content"}><b>{orderShipment.serialNumber}</b></div>
            </div>
            <div style={{width: '120px'}} className={"trace_type_order_cell"}>
                <span className={"trace_type_order_label"}>Bagno</span>
                <div className={"trace_type_order_content"}>{orderShipment.bagno}</div>
            </div>
            <div style={{width: '120px'}} className={"trace_type_order_cell"}>
                <span className={"trace_type_order_label"}>DDT</span>
                <div className={"trace_type_order_content"}>{orderShipment.ddtNumber}</div>
            </div>
            <div style={{width: '100px'}} className={"trace_type_order_cell"}>
                <span className={"trace_type_order_label"}>DDT date</span>
                <div className={"trace_type_order_content"}>{orderShipment.ddtDate}</div>
            </div>
            <div style={{width: '60px'}} className={"trace_type_order_cell"}>
                <span className={"trace_type_order_label"}>Q.ty</span>
                <div className={"trace_type_order_content"}>{orderShipment.quantity}</div>
            </div>
            <div style={{width: '120px'}} className={"trace_type_order_cell"}>
                <span className={"trace_type_order_label"}>Unit</span>
                <div className={"trace_type_order_content"}>{orderShipment.unit}</div>
            </div>
        </div>
    </>
    )
  }
}
