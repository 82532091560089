import React from "react";
import AppStore from "../../../AppStore";
import {StatisticData} from "../../../api/types";
import statisticApiTCare from "../../../api/StatisticApiTCare";
import StatisticApiTCare from "../../../api/StatisticApiTCare";
import {getCanvasPattern} from "@ant-design/charts";
import {deepMix} from "@antv/util";
import VerticalBasicBulletPlotCard from "../cards/VerticalBasicBulletPlotCard";
import {OrderStatusUtil} from "../ant-d-modules/tools/OrderStatusUtil";
import { setOffGlobalSpinningOnHeader } from '../../../api/api';


interface Props {
  startYearMonthFilter: string | null;
  endYearMonthFilter: string | null;
  statisticCode: string
  attribute: string
  title: string
  creationCompany?: string
}

interface State {
  data: [{
    state: string,
    type: string,
    value: number,
  }?];
  isLoading: boolean
}


export default class VerticalBarsOrderData extends React.Component<Props, State> {
  state: State = {
    data: [],
    isLoading: true
  };

  async componentDidMount() {
    await this.refreshComponent();
  }

  async componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any) {
    if (prevProps.startYearMonthFilter !== this.props.startYearMonthFilter ||
        prevProps.endYearMonthFilter !== this.props.endYearMonthFilter ||
        prevProps.creationCompany !== this.props.creationCompany) {
      await this.refreshComponent();
    }
  }

  async refreshComponent() {
    if (!!AppStore.loggedUser?.company) {
      await this.loadData(AppStore.loggedUser?.company.id, false);
    } else {
      await this.loadData(null, true);
    }

  }

  async loadData(idCompany: string | null, isAdmin: boolean) {

    let data: [{
      state: string,
      type: string,
      value: number
    }?] = [];
    const statisticData: StatisticData =
        await statisticApiTCare.getStatisticDataByUrl(this.props.statisticCode + "?"
            + OrderStatusUtil.getCreationOrAssigneeCompanyFilter(isAdmin, idCompany)
            + StatisticApiTCare.getDataRangeFilter(this.props.startYearMonthFilter, this.props.endYearMonthFilter)
            + StatisticApiTCare.getCreationCompanyT1Filter(this.props.creationCompany)
            + '&groupBy=status&groupBy=' + this.props.attribute,
            setOffGlobalSpinningOnHeader());
    if (!!statisticData) {
      for (let key in statisticData) {
        data.push({
          state: statisticData[key].key[this.props.attribute] ?? "Empty",
          type: statisticData[key].key[this.props.attribute] ?? "Empty",
          value: statisticData[key].key.status === 'REJECTED' ? -statisticData[key].value : statisticData[key].value
        });

      }
      // console.log('data: ', data)
      // console.log('statistic data: ', statisticData)
    }
    this.setState({ data: data, isLoading: false });
  }


  getConfigData(): any {
    const PATTERN_MAP = {
      Gas: {
        type: "dot"
      },
      Wasserkraft: {
        type: "line"
      },
      Biomasse: {
        type: "square"
      },
      Wind: {
        type: "line",
        cfg: {
          rotation: 90
        }
      },
      Solar: {
        type: "square",
        cfg: {
          size: 5,
          padding: 2,
          rotation: 45,
          isStagger: false
        }
      }
    };
    const pattern = ({ type }, color) =>
      getCanvasPattern(
        deepMix({}, PATTERN_MAP[type], {
          cfg: {
            backgroundColor: color
          }
        })
      );
    return {
      data: this.state.data,
      xField: "state",
      yField: "value",
      seriesField: "type",
      isPercent: false,
      isStack: true,
      pattern: ({ type }, color) =>
        pattern(
          {
            type
          },
          color
        ),
      legend: {
        marker: (text, index, item) => {
          const color = item.style.fill;
          return {
            style: {
              fill: pattern(
                {
                  type: text
                },
                color
              ),
              r: 8
            }
          };
        }
      },
      interactions: [
        {
          type: "element-highlight-by-color"
        },
        {
          type: "element-link"
        }
      ]
    };
  }

  render() {
    return (
      <VerticalBasicBulletPlotCard
                                   config={this.getConfigData()}
                                   isLoading={this.state.isLoading}
                                   title={this.props.title}/>
    );
  }
}




