import {DetailedOrder, SubOrderWithOwnerCompanyInfo} from '../../api/types'
import {Card, Layout} from 'antd'
import OrderTraceReportSubOrderCardHeader from './OrderTraceReportSubOrderCardHeader'
import OrderTraceReportSubOrderCardBody from './OrderTraceReportSubOrderCardBody'
import React from 'react'
import Text from '../Text'
import OrderCard from "./OrderCard";

const {Content} = Layout

interface Props {
    parentOrder: DetailedOrder
    hideSupplier: boolean
    subOrders: SubOrderWithOwnerCompanyInfo[]
    onViewSubOrderDetail?: (subOrderId: string) => void
    weavingOrder?: SubOrderWithOwnerCompanyInfo
    viewDetail: (orderId: string) => void
    onRejectOrder?: (orderId: string) => void
    onReassignOrder?: (orderId: string) => void
    onCloneOrder?: (orderId: string) => void
    onCancel?: (orderId: string) => void
    onUnlock?: (orderId: string) => void
    onShowLogs?: (orderId: string) => void
    askToUpdate: number
    pageType?: string
}

interface State {
    askToUpdate: number
}

export default class OrderTraceReportSubOrderCard extends React.Component<Props, State> {

    state = {
        askToUpdate: 0
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<{}>, snapshot?: any) {
        if(this.props.askToUpdate !== prevProps.askToUpdate){
            this.refresh();
        }
    }

    refresh(){
        this.setState({askToUpdate: this.props.askToUpdate})
    }

    render = () => {
        const {parentOrder, subOrders, onViewSubOrderDetail, weavingOrder, onRejectOrder} = this.props

        const orderedSubOrders = subOrders.sort((a, b) => a.step < b.step ? -1 : 1)

        return (
            <Content style={{margin: '0 16px', width: '100%'}}>
                <div style={{
                    padding: orderedSubOrders.length > 0 ? '15px 24px 15px 24px' : '0 24px',
                    minHeight: orderedSubOrders.length > 0 ? 360 : 'unset'
                }}>
                    <Text h2 style={{margin: 'margin: 5px 0 5px 0'}}>
                        {orderedSubOrders[0]?.productCompositionDesc}
                    </Text>

                    {weavingOrder && (
                        <OrderCard
                            isSubOrder={false}
                            askToUpdate={this.props.askToUpdate}
                            order={weavingOrder!}
                            showFulfillmentDate
                            showAssignmentDate
                            hideSupplier={this.props.hideSupplier}
                            style={{
                                margin: '20px 0',
                                width: 'auto',
                                backgroundColor: '#efefef',
                            }}
                            pageType={this.props.pageType}
                        />
                    )}

                    {orderedSubOrders.map(subOrder => (
                        <Card key={subOrder.id} style={{marginBottom: 20}}
                              bodyStyle={{backgroundColor: '#F5F5F5', padding: 0, borderRadius: 5}}>
                            <OrderTraceReportSubOrderCardHeader subOrder={subOrder}/>
                            <OrderTraceReportSubOrderCardBody
                                askToUpdate={this.props.askToUpdate}
                                hideSupplier={this.props.hideSupplier}
                                parent={parentOrder}
                                subOrder={subOrder}
                                onViewSubOrderDetail={onViewSubOrderDetail}
                                onRejectOrder={onRejectOrder}
                                onCancel={this.props.onCancel}
                                onUnlock={this.props.onUnlock}
                                onReassignOrder={this.props.onReassignOrder}
                                onCloneOrder={this.props.onCloneOrder}
                                onShowLogs={this.props.onShowLogs}
                                pageType={this.props.pageType}
                            />
                        </Card>
                    ))}
                </div>
            </Content>
        )
    }
}
