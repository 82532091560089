import React, { useState } from 'react'
import { Layout, View, AdvancedForm, Text } from 'components'
import { Card, Button } from 'antd'
import { Schema } from 'components/AdvancedForm'
import theme from 'theme/theme'
import { navigate } from 'router'
import { __, T } from 'config/i18n'

const Login: React.FC = () => {
  const loginSchema: Schema[] = [
    {
      name: 'username',
      type: 'text',
      placeholder: __(T.fields.username),
      rules: [{ required: true, message: __(T.messages.username_required) }],
    },
    {
      name: 'password',
      type: 'password',
      placeholder: __(T.fields.password),
      rules: [{ required: true, message: __(T.messages.password_required) }],
    },
    {
      col: 13,
      name: 'remember',
      valuePropName: 'checked',
      type: 'checkbox',
      placeholder: __(T.misc.remember_me),
    },
    {
      col: 8,
      name: '',
      render: () => (
        <Button type={'link'} onClick={() => alert('Forgot password')} style={{ paddingLeft: 0 }}>
          {__(T.buttons.forgot_pwd)}
        </Button>
      ),
    },
  ]
  const [auth, setAuth] = useState({ username: '', password: '' })
  const c: Record<string, React.CSSProperties> = {
    container: {
      alignItems: 'center',
      justifyContent: 'center',
      flex: 1,
    },
    logo: { width: '135px', height: 'auto', marginBottom: 50 },
    title: { textAlign: 'center', fontSize: '20px' },
    subtitle: {
      textAlign: 'center',
      marginTop: -30,
      marginBottom: '15%',
      fontWeight: 300,
      fontSize: '1rem',
    },
    form: {
      width: 360,
    },
    page: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: '#eee',
      padding: '0 0 50px 0',
    },
    registrationText: { textAlign: 'center', fontSize: '14px' },
  }

  const login = async () => {
    console.log(auth)
    navigate('/')
  }

  return (
    <Layout fullScreen>
      <View column full center style={{ backgroundColor: theme.bgColor, flex: 1, display: 'flex' }}>
        <View style={c.container} column>
          <img src={require('assets/images/Logo_w.png').default} alt={__(T.misc.logo)} style={c.logo} />
          <Card style={c.form}>
            <Text h1 bold style={c.title}>
              {__(T.messages.account_login)}
            </Text>
            <AdvancedForm
              submitButton={{ text: __(T.buttons.login), onPress: login }}
              schema={loginSchema}
              onValuesChange={(changedValues, values) => {
                setAuth(values)
              }}
            />
          </Card>
        </View>
        <View>
          <Text style={{ color: 'white', marginBottom: 10 }}>
            {__(T.misc.powered_by)}
            <b>{__(T.misc.temera_com)}</b>
          </Text>
        </View>
      </View>
    </Layout>
  )
}

export default Login
