import React, { Component } from 'react'


interface State {
    onBoardingId?: string
    currentStep: number
    loading: boolean
}


export default class CircleLiquidPlot extends Component<{}, State> {
    state: State = {
        currentStep: 0,
        loading: false,
    }


    shoot = () => {
        this.setState({});
    }

    demoGauge = () => {

        return (
            <>
                <div style={{

                }}>
                    <img src={require('../../../../assets/images/drop.png')} alt='drop'/>
                    <div style={{
                        color: 'black',
                        top: 8,
                        left: '50%',
                    }}>Your text
                    </div>
                </div>

            </>
        )
    };

    render() {
        return (
            this.demoGauge()
        )
    }
}




