import React, {Component} from "react";
import {Layout, Text, View} from "components";
import {Button, Col} from "antd";
import JSONInput from "react-json-editor-ajrm";
import locale from "react-json-editor-ajrm/locale/en";
import Modal from "antd/lib/modal/Modal";
import {withTheme} from "@rjsf/core";
import {Theme as AntDTheme} from "@rjsf/antd";
import TextArea from "antd/es/input/TextArea";
import {ObjectFieldTemplate} from "../../components/JsonSchemaObjectFieldTemplate";
import AppStore from "../../AppStore";


const Form = withTheme(AntDTheme);

interface State {
    jsonHeight?: number;
    jsonWidth?: number;
    jsonSchema?: any;
    tempJsonSchema?: any;
    visibleAddFieldModal: boolean;
    newField?: any;
    jsonString: string;
}

export default class FormBuilder extends Component<{}, State> {
    timeoutEvent: any;
    form: any;

    state: State = {
        visibleAddFieldModal: false,
        jsonSchema: {
            $id: "https://example.com/person.schema.json",
            $schema: "http://json-schema.org/draft-07/schema#",
            title: "",
            type: "object",
            required: [],
            properties: {}
        },
        jsonString: this.getStringFromJson({
            $id: "https://example.com/person.schema.json",
            $schema: "http://json-schema.org/draft-07/schema#",
            title: "",
            type: "object",
            required: [],
            properties: {}
        })
    };

    calcDimension = () => {
        this.setState({jsonHeight: undefined, jsonWidth: undefined});
        setTimeout(() => {
            this.setState({
                jsonHeight: document.getElementById("jsonContainer")?.clientHeight,
                jsonWidth: document.getElementById("jsonContainer")?.clientWidth
            });
        }, 1);
    };

    renderForm = () => {
        const {jsonSchema} = this.state;
        if (!!jsonSchema.properties) {
            Object.keys(jsonSchema.properties).forEach(key => {
                if (!jsonSchema.properties[key].span) {
                    jsonSchema.properties[key].span = 6
                }
            });

        }
        // return <AdvancedForm title={jsonSchema.title} schema={convertJsonSchema(jsonSchema)} />
        return (
          <Form
            ObjectFieldTemplate={ObjectFieldTemplate}
            liveValidate
            onSubmit={x => console.log(x)}
            schema={jsonSchema}>
              <>
              </>
          </Form>
        );
    };

    updateDimensions = () => {
        this.calcDimension();
    };

    componentDidMount() {
        this.updateDimensions();
        window.addEventListener("resize", this.updateDimensions);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.updateDimensions);
    }

    renderJSON = () => {
        const {jsonHeight, jsonWidth, jsonSchema} = this.state;
        if (!!jsonHeight)
            return (
              <JSONInput
                id="a_unique_id"
                placeholder={jsonSchema}
                locale={locale}
                height={jsonHeight}
                width={jsonWidth}
                onChange={({jsObject}) => this.setState({tempJsonSchema: jsObject})}
              />
            );
    };

    showAddFieldModal = () => this.setState({visibleAddFieldModal: true, newField: {span: 6}});

    hideAddFieldModal = () => this.setState({visibleAddFieldModal: false, newField: undefined});

    newFieldToSchema(field: any) {
        function getType(field) {
            if (field.type === "date-time") {
                return {type: "string", format: "date-time"};
            }
            if (field.type === "date") {
                return {type: "string", format: "date"};
            }
            if (field.type === "array") {
                return {
                    type: "array",
                    items: {
                        type: "object",
                        properties: {
                            name: {
                                title: "Nome",
                                type: "string"
                            }
                        }
                    },
                };
            }
            if (field.type === "companies") {
                return {
                    type: 'string',
                    enum: [],
                    extra: {
                        companyCategoriesEnum: field.categories,
                        mapField: field.mapping
                    }
                }
            }
            return {...field};
        }

        let newSchema;
        if (field && field.name) {
            switch (field.type) {
                case "file":
                    newSchema = {type: "string", format: "data-url"};
                    break;
                case "multiple-file":
                    newSchema = {
                        type: "array",
                        items: {
                            type: "string",
                            format: "data-url"
                        }
                    };
                    break;
                case "select":
                    newSchema = {
                        type: "string",
                        enum: field.options.split(",").map(s => `${s}`)
                    };
                    break;
                case "array":
                    let properties = {};
                    let required: string[] = [];
                    field.fields.forEach(field => {
                        properties[field.name] = this.newFieldToSchema(field);
                        if (field.required) {
                            required.push(field.name);
                        }
                    });
                    newSchema = {
                        type: "array",
                        items: {
                            type: "object",
                            required,
                            properties
                        }
                    };
                    break;


                default:
                    newSchema = {
                        ...getType(field)
                    };
                    break;
            }
        }

        newSchema.title = field.title;
        newSchema.default = field.default;
        newSchema.span = field.span;
        return newSchema;
    }

    addField = () => {
        const {newField, jsonSchema} = this.state;
        if (!newField.name || (newField.type === "select" && !newField.options)) return;
        jsonSchema.properties[newField.name] = this.newFieldToSchema(newField);
        if (newField.required) {
            jsonSchema.required.push(newField.name);
        }
        const jsonString = this.getStringFromJson(jsonSchema);
        this.setState({jsonSchema, jsonString});
        this.calcDimension();
        this.hideAddFieldModal();
    };

    getStringFromJson(json: any): string {
        return JSON.stringify(json).split("\"").join("\\\"");
    }

    reloadFromString(str: string) {
        const s = str.split("\\\"").join("\"");
        this.setState({jsonString: s});
    }


    render() {
        const {jsonSchema, tempJsonSchema, visibleAddFieldModal, newField, jsonString} = this.state;
        let fieldsSchema, optionsSchema;
        let addFieldSchema: any = {
            type: "object",
            title: "Nuovo campo",
            properties: {
                type: {
                    title: "Tipo",
                    type: "string",
                    default: "string",
                    enum: ["string", "date-time", "date", "integer", "boolean", "file", "multiple-file", "select", "companies", "array"]
                },
                name: {
                    title: "Nome",
                    type: "string"
                },
                title: {
                    title: "Titolo",
                    type: "string"
                },
                required: {
                    title: "Required",
                    type: "boolean"
                },
                span: {
                    title: "span",
                    enum: [2, 3, 4, 6, 8, 9, 10, 12]
                }
            },
            required: ["name"]
        };

        if (newField && newField.type === "select") {
            optionsSchema = {
                type: "object",
                title: "Nuovo campo",
                required: ["options"],
                span: {
                    title: "span",
                    enum: [2, 3, 4, 6, 8, 9, 10, 12]
                },
                properties: {
                    options: {title: "Options (separeted comma)", type: "string"}
                }
            };
        }

        if (newField && newField.type === "companies") {
            optionsSchema = {
                type: "object",
                title: "Nuovo campo",
                required: ["categories"],
                span: {
                    title: "span",
                    enum: [2, 3, 4, 6, 8, 9, 10, 12]
                },
                properties: {
                    categories: {
                        "type": "array",
                        uniqueItems: true, // Here
                        "items": {
                            "type": "string",
                            enum: AppStore.appSettings.categories.split("|")
                        },
                        title: "Categories"
                    },
                    mapping: {
                        title: 'Map company fields to json field',
                        type: 'array',
                        items: {
                            type: 'object',
                            required: [
                                'source',
                                'target'
                            ],
                            properties: {
                                source: {
                                    type: 'string',
                                    name: 'source',
                                    title: 'source',
                                    required: true,
                                    enum: ['data.businessName', 'data.legalCode', 'data.country', 'data.city', 'data.email']
                                },
                                target: {
                                    type: 'string',
                                    name: 'target',
                                    title: 'target',
                                    required: true,
                                }
                            }
                        }
                    }
                }
            }
        }

        if (newField && newField.type === "array") {
            fieldsSchema = {
                title: "Campi multipli",
                type: "array",
                items: {
                    type: "object",
                    properties: {
                        name: {
                            title: "Nome",
                            type: "string"
                        },
                        title: {
                            title: "Titolo",
                            type: "string"
                        },
                        required: {
                            title: "Required",
                            type: "boolean"
                        },
                        span: {
                            title: "span",
                            enum: [2, 3, 4, 6, 8, 9, 10, 12]
                        },
                        type: {
                            title: "Tipo",
                            type: "string",
                            default: "string",
                            enum: ["string", "date-time", "date", "integer", "boolean", "file", "multiple-file"]
                        }
                    },
                    required: ["name"]
                }
            };
        }

        return (
          <Layout style={{minHeight: "100vh", height: "auto"}}>
              <View className="page-table" style={{margin: 10, marginTop: 0, borderRadius: "0px"}}>
                  <Col span={16}>
                      <View className="flex box" style={{height: "auto"}}>
                          <View className="form-builder-row center bottom-border">
                              <Text bold black className="flex">
                                  Form
                              </Text>
                              <Button onClick={this.showAddFieldModal}>Add</Button>
                          </View>
                          <View style={{maxWidth: "100%", padding: "20px"}}>{jsonSchema && this.renderForm()}</View>
                      </View>
                  </Col>
              </View>
              <View className="flex form-builder-box">
                  <View className="flex box" style={{width: "400px"}}>
                      <View className="form-builder-row center bottom-border">
                          <Text bold black className="flex">
                              JSON SCHEMA
                          </Text>
                          <Button
                            style={tempJsonSchema && {backgroundColor: "rgba(255, 125, 0, 0.1)"}}
                            disabled={!tempJsonSchema}
                            onClick={() => this.setState({
                                jsonSchema: tempJsonSchema,
                                jsonString: this.getStringFromJson(tempJsonSchema),
                                tempJsonSchema: undefined
                            })}
                          >
                              Refresh
                          </Button>
                      </View>
                      <View id="jsonContainer" className="json-editor-container">
                          {this.renderJSON()}
                      </View>
                  </View>
                  <View className="spacer"/>
                  <View className="flex box" style={{width: "400px"}}>
                      <View className="form-builder-row center bottom-border">
                          <Text bold black className="flex">
                              JSON STRING
                          </Text>
                          <Button
                            style={tempJsonSchema && {backgroundColor: "rgba(255, 125, 0, 0.1)"}}
                            onClick={() => this.setState({
                                jsonSchema: JSON.parse(jsonString),
                                tempJsonSchema: undefined
                            })}
                          >
                              Refresh
                          </Button>
                      </View>
                      <View id="jsonContainer" className="json-editor-container">
                          <TextArea value={jsonString} style={{height: "100%"}}
                                    onChange={(str) => this.reloadFromString(str.target.value)}></TextArea>
                      </View>
                  </View>
              </View>
              {visibleAddFieldModal && (
                <Modal closable={false} width={1000} visible onCancel={this.hideAddFieldModal} onOk={this.addField}>
                    <View className="form-builder-row">
                        <View className={`flex`}>
                            <Form
                              formData={newField}
                              onChange={x => this.setState({newField: x.formData})}
                              schema={addFieldSchema}
                              liveValidate
                              showErrorList={false}
                            >
                                <></>
                            </Form>
                        </View>
                        {optionsSchema || fieldsSchema ? (
                          <View
                            style={{
                                width: 1,
                                marginLeft: 20,
                                marginRight: 20,
                                marginTop: 10,
                                marginBottom: 10,
                                backgroundColor: "#ccc"
                            }}
                          />
                        ) : (
                          <></>
                        )}
                        {!optionsSchema && !fieldsSchema && <View className="flex"/>}
                        {optionsSchema && (
                          <View className="flex">
                              <Form
                                onChange={x => this.setState({newField: x.formData})}
                                formData={newField}
                                schema={optionsSchema}
                                liveValidate
                                showErrorList={false}
                                uiSchema={{options: {"ui:widget": "textarea"}}}
                              >
                                  <></>
                              </Form>
                          </View>
                        )}

                        {fieldsSchema && (
                          <View className="flex">
                              <div>
                                  <Form
                                    onChange={x => {
                                        const {newField} = this.state;
                                        newField.fields = x.formData;
                                        this.setState({newField});
                                    }}
                                    formData={newField.fields || []}
                                    liveValidate
                                    showErrorList={false}
                                    schema={fieldsSchema}
                                  >
                                      <></>
                                  </Form>
                              </div>
                          </View>
                        )}
                    </View>
                </Modal>
              )}
          </Layout>
        );
    }
}
