import React, { createRef } from 'react';
import { __, T } from '../../config/i18n';
import OrderAssignmentForm from '../../components/orders/OrderAssignmentForm';
import { Button } from 'antd';
import { NoticeType, NotificationTCare } from '../../api/types';
import DOMPurify from 'dompurify';


const backGroundOfNotRead: string = '#EEF5FC';

const renderHTML = (rawHTML: string) => React.createElement('div', { dangerouslySetInnerHTML: { __html: DOMPurify.sanitize(rawHTML) } });

interface Props {
  notificationTCare: NotificationTCare;
  markAsRead?: (notificationTCare: NotificationTCare) => void;
}

export function getIconByType(noticeType: NoticeType) {

  if (noticeType) {
    if (noticeType === 'ASSIGNMENT_REMINDER') {
      return require('assets/images/Assignement-reminder.svg').default;
    }
    if (noticeType === 'FULFILLMENT_REMINDER') {
      return require('assets/images/Fullfillment-reminder.svg').default;
    }
    if (noticeType === 'NEW_ASSIGNMENT') {
      return require('assets/images/New-assignement.svg').default;
    }
    if (noticeType === 'ON_BOARDING_APPROVAL') {
      return require('assets/images/Onboarding-Approval.svg').default;
    }
    if (noticeType === 'NEW_COMPANY_CREATED') {
      return require('assets/images/Onboarding-Approval.svg').default;
    }
    if (noticeType === 'COMPLETED_REFERENCE') {
      return require('assets/images/Reference-Completed.svg').default;
    }
    if (noticeType === 'REJECTED_REFERENCE') {
      return require('assets/images/Reject-icon.png').default;
    }
    if (noticeType === 'EXPIRED_SCOPE_CERIFICATE') {
      return require('assets/images/Scope-Cert-Expired.svg').default;
    }
    if (noticeType === 'EXPIRING_SCOPE_CERTIFICATE') {
      return require('assets/images/Scope-Cert-Expiring.svg').default;
    }
    if (noticeType === 'END_GENERATION_EXPORT') {
      return require('assets/images/Reference-Completed.svg').default;
    }
    if (noticeType === 'WELCOME_MESSAGE') {
      return require('assets/images/Welcome.svg').default;
    }
    if (noticeType === 'CREATION_COMPANY') {
      return require('assets/images/Reference-Completed.svg').default;
    } else {
      console.error('notification type not found');
    }
  }
  return '';
}


interface State {
}


export default class NoticeCard extends React.Component<Props, State> {
  orderAssignmentFormRef = createRef<OrderAssignmentForm>();


  render = () => {
    return (
      <div className="noticeCard" style={{
        background: this.props.notificationTCare.read ? '' : backGroundOfNotRead
      }}>
        <div className="noticeCardIcon">
          <img src={getIconByType(this.props.notificationTCare.noticeType)} alt={__(T.misc.logo)} />
        </div>
        <div className="noticeCardData">
          <div className="noticeCardHeader">
            <div className="noticeCardTitle" style={{ color: this.getTextColor(this.props.notificationTCare) }}>
              {this.props.notificationTCare.title}
            </div>
            {this.props.markAsRead && (
              <div className="noticeCardMarkAsRead">
                <Button type="link" hidden={this.props.notificationTCare.read}
                        onClick={() => this.props.markAsRead!(this.props.notificationTCare)}>
                  <b>{__(T.buttons.mark_as_read)}</b>
                </Button>
                <Button type="link" hidden={!this.props.notificationTCare.read}
                        onClick={() => this.props.markAsRead!(this.props.notificationTCare)}>
                  <b>{__(T.buttons.mark_as_unread)}</b>
                </Button>
              </div>

            )}
          </div>
          <div className="noticeCardBody">
            {renderHTML(this.props.notificationTCare.body)}

          </div>
          <div className="noticeCardFooter">
            <Button type="link"> {this.getTimeAgo(this.props.notificationTCare)} </Button>
          </div>
        </div>
      </div>
    );


  };

  private getTimeAgo(notificationTCare: NotificationTCare) {
    if (notificationTCare.creationTimeAgo < 60000) {
      return 'now';
    } else {
      let seconds = notificationTCare.creationTimeAgo / 1000;
      if (seconds < 3600) {
        // simao nell'orine dei minuti
        const minutes = ~~(seconds / 60);
        return minutes + ' ' + __(T.misc.minutesAgo);
      } else if (seconds < 86400) {
        // simao nell'ordine delle ore
        const hours = ~~((seconds / 60) / 60);
        return hours + ' ' + __(T.misc.hoursAgo);
      } else if (seconds < 2592000) {
        // siamo nell'ordine dei giorni
        const days = ~~(((seconds / 60) / 60) / 24);
        return days + ' ' + __(T.misc.daysAgo);
      } else if (seconds < 31104000) {
        // siamo nell'ordine dei mesi
        const months = ~~((((seconds / 60) / 60) / 24) / 30);
        return months + ' ' + __(T.misc.monthsAgo);
      }


    }
  }

  getTextColor(notificationTCare: NotificationTCare) {
    if (notificationTCare.noticeType === 'EXPIRING_SCOPE_CERTIFICATE') {
      return '#ff8b0f';
    } else if (notificationTCare.noticeType === 'EXPIRED_SCOPE_CERIFICATE') {
      return '#ea1c1c';
    } else {
      return '#000';
    }
  }
}
