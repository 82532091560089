import React from 'react';
import {Button, Modal as AntDModal, Upload} from 'antd';
import {AdvancedForm} from '../../components';
import {UploadChangeParam, UploadFile} from 'antd/es/upload/interface';
import {CertificationUpload, ImportOrderTraceExcelResult} from '../../api/types';
import {__, T} from '../../config/i18n';
import {Schema} from '../AdvancedForm';
import {UploadOutlined} from '@ant-design/icons';
import {FormInstance} from 'antd/es/form';
import {showNotification} from '../../helpers/Notifications';
import Orders from '../../api/Orders';

interface Props {
  visible: boolean;
  configurationUpload: string;
  onConfirm: () => void;
  onCancel: () => void;
}

interface State {
  uploadTraceDataFileFile: Omit<Partial<CertificationUpload>, 'file'> & { file?: UploadFile };
  uploadTraceDataFileFileList: UploadFile[];
  isShowImport: boolean;
  importOrderTraceExcelResult?: ImportOrderTraceExcelResult;
}

export default class UploadTraceDataFileModal extends React.Component<Props, State> {
  formRef = React.createRef<FormInstance>();

  state: State = {
    uploadTraceDataFileFile: {},
    uploadTraceDataFileFileList: [],
    isShowImport: true
  };

  getScopeCertificateUploadSchema = (): Schema[] => {
    return [
      {
        name: 'file',
        label: __(T.fields.file),
        placeholder: __(T.misc.select_trace_order),
        rules: [{ required: true }],
        col: 24,
        valuePropName: 'file',
        getValueFromEvent: ({ fileList }: UploadChangeParam) => {
          return fileList && fileList.length > 0 ? fileList[0] : undefined;
        },
        render: () => {
          return (
            <Upload
              listType="text"
              beforeUpload={_ => false}
              fileList={this.state.uploadTraceDataFileFileList}
              onChange={info =>
                this.setState((currentState: State) => ({
                  uploadTraceDataFileFileList: info.fileList.slice(-1)
                }))
              }
            >
              <Button icon={<UploadOutlined />}>{__(T.misc.select_trace_order)}</Button>
            </Upload>
          );
        }
      }
    ];
  };

  handleCancel = () => this.setState({
    uploadTraceDataFileFile: {},
    uploadTraceDataFileFileList: []
  }, this.props.onCancel);

  handleClose = () => {
    this.props.onCancel();
    this.setState({
      uploadTraceDataFileFile: {},
      uploadTraceDataFileFileList: [],
      isShowImport: true
    });
  }


  handleOk = () => {
    if (this.formRef.current) {
      this.formRef.current.validateFields().then(
        async _ => {
          const importOrderTraceExcelResult: ImportOrderTraceExcelResult = await Orders.uploadTraceData(
            this.state.uploadTraceDataFileFile.file?.originFileObj as File,
            this.props.configurationUpload);
          showNotification(__(T.messages.attachment_loaded));
          this.setState({ isShowImport: false, importOrderTraceExcelResult: importOrderTraceExcelResult });
          // this.props.onConfirm();
        }
      );
    }
  };

  render = () => {
    const { visible } = this.props;
    const { uploadTraceDataFileFile } = this.state;
    return (
      <>

        <AntDModal
          key={'uploadTraceData'}
          centered
          title={this.state.isShowImport ? ' Upload trace data' : ' Upload trace data result'}
          visible={visible}
          onCancel={_ => this.state.isShowImport ? this.handleCancel() : this.handleClose() }
          onOk={_ => this.state.isShowImport ? this.handleOk() : this.handleClose()}
          okText={this.state.isShowImport ? __(T.buttons.confirm) : __(T.buttons.close)}
          cancelText={__(T.buttons.cancel)}
        >
          {this.state.isShowImport ? (
            <AdvancedForm
              formRef={this.formRef}
              schema={this.getScopeCertificateUploadSchema()}
              values={uploadTraceDataFileFile}
              onValuesChange={(changedValues, values) => this.setState({ uploadTraceDataFileFile: values })}
            />) : (
              <>
                <p>
                  <b>{__(T.fields.excel_row_counter)}</b>
                  {' '}
                  {this.state.importOrderTraceExcelResult?.excelRowCounter}
                </p>
                <p>
                  <b>{__(T.fields.updated_enquiry_counter)}</b>
                  {' '}
                  {this.state.importOrderTraceExcelResult?.updatedEnquiryCounter}
                </p>
                <p>
                  <b>{__(T.fields.not_updated_enquiry_counter)}</b>
                  {' '}
                  {this.state.importOrderTraceExcelResult?.notUpdateRowCounter}
                </p>
                {/*<p>*/}
                {/*  <b>{__(T.fields.validation_error_enquiry_counter)}</b>*/}
                {/*  {' '}*/}
                {/*  {this.state.importOrderTraceExcelResult?.validationErrorEnquiryCounter}*/}
                {/*</p>*/}
                {/*{*/}
                {/*    this.state.importOrderTraceExcelResult &&*/}
                {/*    this.state.importOrderTraceExcelResult.notUpdateNumberRow &&*/}
                {/*    this.state.importOrderTraceExcelResult.notUpdateNumberRow.length > 0 &&*/}
                {/*    <p>*/}
                {/*      <b>{__(T.fields.not_update_number_row)}</b>*/}
                {/*      {' '}*/}
                {/*      {this.state.importOrderTraceExcelResult.notUpdateNumberRow.join(' - ')}*/}
                {/*    </p>*/}
                {/*}*/}
              </>
          )}
        </AntDModal>


      </>
    );
  };
}
