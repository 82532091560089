import React from 'react';
import QRCode from 'react-qr-code';
import { DetailedOrder } from '../../api/types';
import { Button } from 'antd';
import { __, T } from '../../config/i18n';

interface Props {
  order: DetailedOrder,
  size: number,
  setting: string,
  format?: string
}

interface State {}

export default class TCareQrCode extends React.Component<Props, State> {

  state: State = {};

  getValue(setting: string) {
    return this.sostituisciPlaceholder(setting, this.props.order);
  }

  // Funzione per sostituire i placeholder con i valori corrispondenti dall'oggetto
  sostituisciPlaceholder(stringa: string, oggetto: any): string {
    return stringa.replace(/\{(.*?)\}/g, (match, path) => {
      // Divide il path in un array di chiavi
      const keys = path.split('.');
      // Itera sull'oggetto usando le chiavi per navigare
      let value = oggetto;
      for (const key of keys) {
        if (value.hasOwnProperty(key)) {
          value = value[key];
        } else {
          // Se una delle chiavi non è presente, restituisce una stringa vuota
          return '';
        }
      }
      return value;
    });
  }

  onImageDownload = () => {
    const svg = document.getElementById('QRCode');
    if (!svg) {
      return;
    }

    const format = this.props.format || 'png'; // Usa PNG come formato predefinito
    if (format === 'svg') {
      const svgData = new XMLSerializer().serializeToString(svg);
      const svgBlob = new Blob([svgData], { type: 'image/svg+xml;charset=utf-8' });
      const svgUrl = URL.createObjectURL(svgBlob);
      const downloadLink = document.createElement('a');
      downloadLink.href = svgUrl;
      downloadLink.download = 'QRCode.svg';
      downloadLink.click();
    } else {
      const svgData = new XMLSerializer().serializeToString(svg);
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      const img = new Image();
      img.onload = () => {
        canvas.width = img.width;
        canvas.height = img.height;
        if (!ctx) {
          return;
        }
        ctx.drawImage(img, 0, 0);
        const pngFile = canvas.toDataURL('image/png');
        const downloadLink = document.createElement('a');
        downloadLink.download = 'QRCode.png';
        downloadLink.href = pngFile;
        downloadLink.click();
      };
      img.src = `data:image/svg+xml;base64,${btoa(svgData)}`;
    }
  };

  render = () => {
    if (!!this.props.order && !!this.props.order.traceType.code) {
      return <>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <QRCode
            id="QRCode"
            size={this.props.size} // Imposta la dimensione del QR code
            style={{ height: 'auto', maxWidth: '100%', width: '100%' }}
            value={this.getValue(this.props.setting)}
            viewBox={`0 0 256 256`}
          />
          <div style={{ marginTop: 10 }}>
            <Button value="Download QR" onClick={this.onImageDownload}>{__(T.buttons.download_qr_code)}</Button>
          </div>
        </div>
      </>;
    }
    return (<></>);
  };
}
