import React from 'react';
import { Modal as AntDModal, Select, Spin, Card } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { __, T } from '../../config/i18n';
import { FormInstance } from 'antd/lib/form';
import CreateOnboardingForm, { CreateOnboardingFormFields } from './CreateOnboardingForm';
import { OnBoardings } from '../../api';
import { showNotification } from '../../helpers/Notifications';
import CreateCompanyWithoutUserForm from './CreateCompanyWithoutUserForm';

const { Option } = Select;

interface Props {
  visible: boolean;
  onConfirm: (companyId?: string) => void;
  onCancel: () => void;
}

interface State {
  formFields: CreateOnboardingFormFields,
  selectedComponent?: string,
  isLoading: boolean
}

export default class CreateOnboardingModal extends React.Component<Props> {
  formRef = React.createRef<FormInstance>();

  state: State = {
    formFields: { companyType: 'supplier' },
    selectedComponent: 'createOnboardingForm',
    isLoading: false
  };

  handleCancel = () => this.setState(
    {
      formFields: {
        name: null,
        surname: null,
        email: null,
        phone: null,
        businessName: null,
        partitaIva: null,
        companyCode: null,
        categories: []
      }
    }, this.props.onCancel);

  handleOk = () => {
    if (this.state.selectedComponent === 'createOnboardingForm') {
      this.createOnboarding();
    } else if (this.state.selectedComponent === 'createByVatCode') {
      this.createByVatCode();
    }
  };

  createOnboarding() {
    if (this.formRef.current) {
      this.formRef.current.validateFields().then(
        async _ => {
          const { formFields } = this.state;
          this.setState({ isLoading: true });
          const onboarding = await OnBoardings.save({
            user: {
              firstName: formFields.name,
              lastName: formFields.surname,
              email: formFields.email,
              phone: formFields.phone
            },
            businessName: formFields.businessName,
            partitaIva: formFields.partitaIva,
            companyCode: formFields.companyCode,
            companyType: 'supplier',
            categories: formFields.categories
          });
          this.setState({ isLoading: false });
          if (!onboarding.state && onboarding.message.indexOf('Entity Company already exists with ref') >= 0) {
            showNotification(__(T.messages.onboarding_company_already_exist), undefined, 'error');
            // this.handleCancel()
          } else if (onboarding.errorCode >= 400) {
            showNotification(onboarding.status, undefined, 'error');
            // this.handleCancel()
          } else {
            if (onboarding?.state === 'APPROVED') {
              showNotification(__(T.messages.onboarding_company_exists));
            } else {
              showNotification(__(T.messages.invitation_successfully_created));
            }
            this.props.onConfirm(onboarding?.company.id ?? undefined);
          }
        },
        _ => ({})
      );
    }
  }

  createByVatCode() {
    if (this.formRef.current) {
      this.formRef.current.validateFields().then(
        async _ => {
          const { formFields } = this.state;
          const onboarding = await OnBoardings.createByVatCode({
            businessName: formFields.businessName,
            partitaIva: formFields.partitaIva,
            companyCode: formFields.companyCode,
            companyType: 'supplier',
            categories: formFields.categories
          });

          if (onboarding.errorCode >= 400) {
            showNotification(onboarding.status, onboarding.message, 'error');
          } else {
            showNotification(__(T.messages.invitation_successfully_created));
          }
          this.props.onConfirm(onboarding?.companyId ?? undefined);
        },
        _ => ({})
      );
    }
  }

  onValuesChange = (changedValues: Partial<CreateOnboardingFormFields>, values: CreateOnboardingFormFields) => {
    this.setState({
      formFields: {
        ...this.state.formFields,
        ...values
      }
    });
  };

  render = () => {
    const { visible } = this.props;
    const { formFields, selectedComponent } = this.state;
    return (
      <AntDModal
        centered
        title={__(T.fields.inviteCompany)}
        visible={visible}
        onCancel={_ => this.handleCancel()}
        onOk={_ => this.handleOk()}
        okText={__(T.buttons.confirm)}
        cancelText={__(T.buttons.cancel)}
      >
        <Spin spinning={this.state.isLoading}>
          <Select
            style={{ width: '100%', paddingBottom: '10px' }}
            value={selectedComponent}
            onChange={(value) => this.setState({ selectedComponent: value })}
            defaultValue={'createOnboardingForm'}
          >
            <Option key="createOnboardingForm" value="createOnboardingForm">
              {__(T.fields.inviteCompanyAndUser)}
            </Option>
            <Option key="createByVatCode" value="createByVatCode">
              {__(T.fields.addCompany)}
            </Option>
          </Select>

          <Card style={{ marginBottom: '10px', backgroundColor: '#f0f2f5', border: '1px solid #d9d9d9' }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <InfoCircleOutlined style={{ fontSize: '20px', color: '#1890ff', marginRight: '10px' }} />
              {selectedComponent === 'createOnboardingForm' ? (
                <div>
                  <b>{__(T.fields.inviteCompanyAndUser)}:</b> {__(T.fields.addCompanyAndUser)}.
                </div>
              ) : (
                <div>
                  <b>{__(T.fields.addCompany)}:</b> {__(T.fields.addCompanyNoAccess)}.
                </div>
              )}
            </div>
          </Card>

          {selectedComponent === 'createOnboardingForm' && (
            <CreateOnboardingForm
              formRef={this.formRef}
              formFields={formFields}
              onValuesChange={this.onValuesChange}
            />
          )}
          {selectedComponent === 'createByVatCode' && (
            <CreateCompanyWithoutUserForm
              formRef={this.formRef}
              formFields={formFields}
              onValuesChange={this.onValuesChange}
            />
          )}
        </Spin>
      </AntDModal>
    );
  };
}
