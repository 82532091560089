export class PdfTools {

  public async downloadPdf(blobBase64: any, fileName: string) {
      // Decodifica il Base64 in un array di byte
      var byteCharacters = atob(blobBase64);
      var byteNumbers = new Array(byteCharacters.length);
      for (var i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      var byteArray = new Uint8Array(byteNumbers);

      // Crea un oggetto Blob dal byteArray
      var blob = new Blob([byteArray], { type: 'application/pdf' });

      // Crea un URL per il download
      var url = URL.createObjectURL(blob);

      // Crea un elemento di ancoraggio <a> per il download
      var downloadLink = document.createElement('a');
      downloadLink.href = url;
      downloadLink.download = fileName // Imposta il nome del file (default: file.pdf)
      downloadLink.style.display = 'none';

      // Aggiungi il link all'elemento body
      document.body.appendChild(downloadLink);

      // Simula un clic sul link per avviare il download
      downloadLink.click();

      // Rimuovi il link dopo il download
      document.body.removeChild(downloadLink);

      // Rilascia l'URL dopo il download
      URL.revokeObjectURL(url);
    }

}


const pdfTools = new PdfTools()
export { pdfTools as default }
