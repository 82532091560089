import React from 'react';
import {
  Order,
  Page,
  ReportOrder
} from '../../api/types';
import Orders from '../../api/Orders';

import { Badge, Button, Dropdown, Menu, Space } from 'antd';
import { Layout } from 'components';
import { __, T } from 'config/i18n';
import AppStore from '../../AppStore';
import { addQueryParams, getQueryParams, navigate } from '../../router';
import { Props } from '../../components/layouts/CompanyFormLayout';
import { ColumnsType } from 'antd/es/table';
import { buildColumnsByConfiguration } from 'components/orders/ColumnBuilderUtil';
import { buildFiltersByConfiguration } from 'components/orders/FilterBuilderUtil';
import { DownOutlined } from '@ant-design/icons';
import FilteredTable, { SHOW_SELECTED_KEY } from '../../components/FilteredTable';

const ORDER_REPORT_SESSION_STORAGE = 'rawMaterialSourceOrderReportSessionStorage';
const ALL_ORDERS_KEY = 'ALL_ORDERS';

const INITIAL_STATE: State = {
  componentMount: false,
  lastParams: {},
  askToUpdateData: 1
};

interface State {
  activeTabKey?: string
  componentMount: boolean
  lastParams: any
  askToUpdateData: number
}

export default class RawMaterialSourceOrdersReport extends React.Component<Props, State> {

  private orderStatusTabKeys: string[] = [];

  state: State = { ...INITIAL_STATE };

  componentDidMount = async () => {
    //******************************** Configuration filter
    // Configuration status
    this.orderStatusTabKeys.push(ALL_ORDERS_KEY);
    const orderAvailableStatuses: string[] = AppStore.appSettings.orderAvailableStatuses.split(',');
    orderAvailableStatuses.forEach((item: string) => {
      this.orderStatusTabKeys.push(item);
    });

    let activeTab = getQueryParams().status;
    if (!!activeTab) {
      addQueryParams({ 'status': activeTab }, false);
      this.setState({
        activeTabKey: activeTab,
        componentMount: true
      });
    } else {
      this.setState({ componentMount: true });
    }
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.props['location']['search'] !== prevProps.location.search) {
      // this.onRouteChanged();
    }
  }

  refreshData(){
    this.setState({
      askToUpdateData: this.state.askToUpdateData + 1
    });
  }

  timeoutStarted: boolean = false;

  getColumns = (): ColumnsType<ReportOrder> => {
    const columns: any = buildColumnsByConfiguration(AppStore.appSettings.orderListRawMaterialSourceColumns);
    if (this.state.activeTabKey === ALL_ORDERS_KEY || this.state.activeTabKey === SHOW_SELECTED_KEY) {
      columns.push({
        key: 'col_status',
        title: __(T.fields.status),
        width: 150,
        ellipsis: true,
        dataIndex: 'status',
        render: (text, record: Order) => {
          return (
              <Badge
                  color={__(T.colors[`${record.status.toLowerCase()}`])}
                  text={__(T.misc[record.status.toLowerCase()])}
              />
          );
        },
        sorter: true,
        sortDirections: ['ascend', 'descend', 'ascend']
      });
    }

    columns.push({
      title: __(T.fields.actions),
      key: 'col_action',
      align: 'left',
      fixed: 'right',
      width: 1,
      render: (text, record: Order) => {
        return (<>
          <Dropdown.Button overlay={

            <Menu>
              <Menu.Item
                  onClick={() => navigate('/reports/rawmaterialorders/detail/:orderId', { orderId: record.id })}
              >
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <img style={{ height: '20px', justifyContent: 'center', display: 'flex' }}
                       src={require('assets/images/View.svg').default}
                  alt={__(T.buttons.view)}/>
                  <a style={{ minWidth: '150px', justifyContent: 'center', display: 'flex' }}>
                    {__(T.buttons.view)}
                  </a>
                </div>
              </Menu.Item>

            </Menu>
          }>
          </Dropdown.Button>
        </>);
      }
    });

    return columns;
  };


  addExtraParams(params: any, activeTabKey?: string) {
    params['status'] = activeTabKey !== ALL_ORDERS_KEY && activeTabKey !== SHOW_SELECTED_KEY ? activeTabKey : AppStore.appSettings.orderAvailableStatuses.split(',');
    params['rootOrder'] = true;
    params['customerCompany'] = AppStore.loggedUser?.company?.id ?? undefined;
  }

  onSearch = async (params: any, currentPage: number, sortField: string, sortDirection: 'asc' | 'desc', size: number, activeTabKey?: string): Promise<Page<ReportOrder>> => {
    this.addExtraParams(params, activeTabKey);

    this.setState({ lastParams: params });

    let orderPage = await Orders.search({
      page: currentPage,
      size: 10,
      ...params,
      sort: sortField + ',' + sortDirection
    });
    const companies = await Orders.getCompanies(orderPage);
    orderPage.content = orderPage.content.map(order => ({
      ...order,
      assigneeCompanyName: companies[order.assigneeCompany],
      creationCompanyName: companies[order.creationCompany]
    }));
    return orderPage;
  };

  private checkCurrentActiveTab(tabs: string | string[]): boolean {
    if(!this.state.activeTabKey){
      return false
    }
    return (typeof tabs) === 'string' ?
        this.state.activeTabKey === tabs
        :
        tabs.indexOf(this.state.activeTabKey) >= 0
  }

  getTabBarExtraContent = (activeTab?: string): JSX.Element[] => {
    const buttons: JSX.Element[] = [];

    buttons.push(
        <Dropdown overlay={<Menu items={[
          {
            label: `${__(T.buttons.export_xls)}`,
            key: 'enquiriesXlsExport',
            onClick: this.handleXlsExport,
            style: { display: (AppStore.appSettings.enableXlsExport) ? 'block' : 'none' }
          }
        ]} />}>
          <Button>
            <Space>
              {__(T.buttons.export)}
              <DownOutlined />
            </Space>
          </Button>
        </Dropdown>
    );

    return buttons;
  };

  handleXlsExport = () => {
    Orders.downloadXls({
      ...this.state.lastParams,
      page: 0,
      size: 0
    });
  };

  isOrderStatusTabKey = (activeKey: string): activeKey is string =>
      this.orderStatusTabKeys.some(orderStatusTabKey => orderStatusTabKey === activeKey);

  render = () => {
    return (
        <Layout key={'orderReportView'}>
          {!!this.state.componentMount && <FilteredTable<ReportOrder>
              onSearchElements={this.onSearch}
              getColumnsConfig={this.getColumns}
              getExtraButtons={this.getTabBarExtraContent}
              askToUpdateData={this.state.askToUpdateData}
              tab={{
                tabKeys: this.orderStatusTabKeys,
                initialActiveTabKey: this.state.activeTabKey,
                onTabChange: (tab) => {
                  this.setState({
                    activeTabKey: tab
                  }, () => {
                    addQueryParams({ 'status': tab }, false);
                  });
                }
              }}
              getFiltersConfig={() => buildFiltersByConfiguration(AppStore.appSettings.orderListRawMaterialSourceFilters)}
              storeKey={ORDER_REPORT_SESSION_STORAGE}
          />
          }
        </Layout>
    );
  };


}
