import React, { Component } from 'react'
import { Line } from '@ant-design/plots';



interface State {
    onBoardingId?: string
    currentStep: number
    loading: boolean
}
/*
const data = [
    {
        year: '1991',
        value: 3,
    },
    {
        year: '1992',
        value: 4,
    },
    {
        year: '1993',
        value: 3.5,
    },
    {
        year: '1994',
        value: 5,
    },
    {
        year: '1995',
        value: 4.9,
    },
    {
        year: '1996',
        value: 6,
    },
    {
        year: '1997',
        value: 7,
    },
    {
        year: '1998',
        value: 9,
    },
    {
        year: '1999',
        value: 13,
    },
    {
        year: '1999',
        value: 8,
    },
];
*/

export default class StepLineMultiplePlot extends Component<{}, State> {
    state: State = {
        currentStep: 0,
        loading: false,
    }


    shoot = () => {
        this.setState({});
    }

    demoLine = () => {
        let data = [
            {
                month: 'Jan',
                key: 'series1',
                value: 125,
            },
            {
                month: 'Jan',
                key: 'series2',
                value: 51,
            },
            {
                month: 'Feb',
                key: 'series1',
                value: 132,
            },
            {
                month: 'Feb',
                key: 'series2',
                value: 91,
            },
            {
                month: 'Mar',
                key: 'series1',
                value: 141,
            },
            {
                month: 'Mar',
                key: 'series2',
                value: 34,
            },
            {
                month: 'Apr',
                key: 'series1',
                value: 158,
            },
            {
                month: 'Apr',
                key: 'series2',
                value: 47,
            },
            {
                month: 'May',
                key: 'series1',
                value: 133,
            },
            {
                month: 'May',
                key: 'series2',
                value: 63,
            },
            {
                month: 'June',
                key: 'series1',
                value: 143,
            },
            {
                month: 'June',
                key: 'series2',
                value: 58,
            },
            {
                month: 'July',
                key: 'series1',
                value: 176,
            },
            {
                month: 'July',
                key: 'series2',
                value: 56,
            },
            {
                month: 'Aug',
                key: 'series1',
                value: 194,
            },
            {
                month: 'Aug',
                key: 'series2',
                value: 77,
            },
            {
                month: 'Sep',
                key: 'series1',
                value: 115,
            },
            {
                month: 'Sep',
                key: 'series2',
                value: 99,
            },
            {
                month: 'Oct',
                key: 'series1',
                value: 134,
            },
            {
                month: 'Oct',
                key: 'series2',
                value: 106,
            },
            {
                month: 'Nov',
                key: 'series1',
                value: 110,
            },
            {
                month: 'Nov',
                key: 'series2',
                value: 88,
            },
            {
                month: 'Dec',
                key: 'series1',
                value: 91,
            },
            {
                month: 'Dec',
                key: 'series2',
                value: 56,
            },
        ];
        /*
        const config1 = {
            data,
            xField: 'year',
            yField: 'value',
            stepType: 'vh',
        };*/

        const config = {
            data,
            xField: 'month',
            yField: 'value',
            stepType: 'vh',
            seriesField: 'key',
        };
        /*
        const configx = {
            data,
            xField: 'month',
            yField: 'value',
            legend: false,
            seriesField: 'key',
            stepType: 'hvh',
        };*/

        return <>
                <button onClick={this.shoot}>Take the Shot!</button>
                <Line {...config} />
        </>
    };

    render() {
        return (
            this.demoLine()
        )
    }
}




