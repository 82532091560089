import React from 'react'
import { Layout, View, CompanyFormLayout, Modal } from 'components'
import { __, T } from 'config/i18n'
import { showNotification } from 'helpers/Notifications'
import { UserFields } from 'types'
import theme from 'theme/theme'
import AppStore from 'AppStore'
import { Users } from 'api'
import errorPrint from "../../tools/ErrorPrint";

interface Props {}
interface State {
  modalVisible: boolean
  userFields: UserFields
}

export default class UserDetail extends React.Component<Props, State> {
  state: State = {
    modalVisible: false,
    userFields: {
      name: AppStore.loggedUser?.firstName,
      surname: AppStore.loggedUser?.lastName,
      email: AppStore.loggedUser?.email,
      phone: AppStore.loggedUser?.phone,
    },
  }

  save = async () => {
    const { userFields } = this.state
    try {
      if (AppStore.loggedUser) {
        if (userFields.name) AppStore.loggedUser.firstName = userFields.name
        if (userFields.surname) AppStore.loggedUser.lastName = userFields.surname
        if (userFields.email) AppStore.loggedUser.email = userFields.email
        if (userFields.phone) AppStore.loggedUser.phone = userFields.phone

        await Users.update(AppStore.loggedUser?.id, AppStore.loggedUser)
        showNotification(__(T.messages.user_datas_updated))
      }
    } catch (error) {
      errorPrint.printMessage(error)
    } finally {
      this.setState({ modalVisible: false })
    }
  }

  renderModal = () => {
    return (
      <Modal
        visible={this.state.modalVisible}
        title={__(T.confirms.confirm_operation)}
        message={__(T.messages.confirm_or_go_back)}
        closeButtonTitle={__(T.buttons.back)}
        confirmButtonTitle={__(T.buttons.confirm)}
        onCloseClick={() => {
          this.setState({ modalVisible: false })
        }}
        onConfirmClick={this.save}
      />
    )
  }

  render() {
    const { userFields } = this.state
    return (
      <Layout>
        <View style={{ padding: 20 }}>
          <View className="page-table" style={{ position: 'relative' }}>
            <CompanyFormLayout
              fieldsVisibility={{ userData: true }}
              onValuesChange={(changedValues, values) => {
                this.setState({ userFields: values })
              }}
              userFields={userFields}
              submitButton={{
                text: __(T.buttons.save),
                col: 12,
                style: theme.temeraPrimaryGreenButton,
                onPress: () => this.setState({ modalVisible: true }),
              }}
            />
          </View>
        </View>
        {this.renderModal()}
      </Layout>
    )
  }
}
