import { AxiosRequestConfig } from 'axios';
import api, { responseErrorCheck, setOffGlobalSpinningOnHeader } from './api';
import { StatisticData } from './types';
import AppStore from '../AppStore';

class StatisticApiTCare {
  protected static ENDPOINT: string = 'statistics/v1/statisticdata/smart';
  //
  // get URL_GET_ASSIGNED(): string {
  //   return 'status_order?groupBy=status&status=ASSIGNED&';
  // }
  //
  // get URL_GET_CANCELLED(): string {
  //   return 'status_order?groupBy=status&status=CANCELLED&';
  // }
  //
  // get URL_GET_COMPLETED_THIRD_PART_TRUE(): string {
  //   return 'status_order?groupBy=status&thirdPart=true&status=COMPLETED&';
  // }
  //
  // get URL_GET_COMPLETED(): string {
  //   return 'status_order?groupBy=status&status=COMPLETED&';
  // }
  //
  // get URL_GET_DRAFT(): string {
  //   return 'status_order?groupBy=status&status=DRAFT&';
  // }
  //
  // get URL_GET_FULLFILLED(): string {
  //   return 'status_order?groupBy=status&status=FULFILLED&';
  // }
  //
  // get URL_GET_TO_BE_ASSIGNED(): string {
  //   return 'status_order?groupBy=status&status=TO_BE_ASSIGNED&';
  // }

  get URL_GET_TO_DOWN_BOTTOM_UP(): string {
    return 'average_completed_order?';
  }

  get STATUS_ORDER(): string {
    return 'status_order';
  }

  get COUNT(): string {
    return 'count';
  }

  get LAST_MODIFIED(): string {
    return 'lastModified';
  }

  get GREATER(): string {
    return '$greater$';
  }

  get GROUP_BY(): string {
    return 'groupBy';
  }

  get OWNER_COMPANY(): string {
    return 'ownerCompany';
  }

  get ASSIGNEE_COMPANY(): string {
    return 'assigneeCompany';
  }

  get CREATION_COMPANY(): string {
    return 'creationCompany';
  }

  async getStatisticDataByUrl(urlQuery: string, axiosConfig?: AxiosRequestConfig) {
    const res = await api.get<StatisticData>(`${(StatisticApiTCare.ENDPOINT)}/${urlQuery}`, {}, axiosConfig);
    return responseErrorCheck(res);
  }

  async getTrackedSupplyChain(date: string, filter: string): Promise<StatisticData> {
    return await statisticApiTCare.getStatisticDataByUrl(
      'import-order?groupBy=networks&lastModified=$greater$'
      + date
      + '&'
      + filter,
        setOffGlobalSpinningOnHeader());
  }

  async getSpecificFiber(date: string, filter: string): Promise<StatisticData> {
    return await statisticApiTCare.getStatisticDataByUrl(
      'import-order?groupBy=specificFibers&lastModified=$greater$'
      + date
      + '&'
      + filter,
        setOffGlobalSpinningOnHeader());
  }


  async getNumberOfOnBoarding(idCompany: string, filter: string, isAdmin: boolean = false): Promise<string> {
    let categories: string = '';

    if (isAdmin) {
      AppStore.appSettings.categoriesT1.split(',').forEach(category => {
        if (category !== '') {
          if (categories.length !== 0) {
            categories = categories + '&payload.categories=$EQ$' + category;
          } else {
            categories = categories + 'payload.categories=$EQ$' + category;
          }
        }
      });
    }
    const onBoarding: StatisticData = await statisticApiTCare.getStatisticDataByUrl(
      'insert_onboarding?groupBy=_ALL&payload.creator.company.id=' + idCompany + '&' + categories + filter,
        setOffGlobalSpinningOnHeader());
    if (!!onBoarding[0] && !!onBoarding[0].value) {
      return onBoarding[0].value;
    }
    return '0';
  }

  async getOnBoarding(idCompany: string): Promise<StatisticData> {
  return await statisticApiTCare.getStatisticDataByUrl(
      '/insert_onboarding?groupBy=payload.categories&payload.creator.company.id=' + idCompany,
      setOffGlobalSpinningOnHeader());
  }

  public getSmartCount(compareOperator: string, date: string, fieldCompany: string, idCompany: string): string {
    return this.STATUS_ORDER
      + '/'
      + this.COUNT
      + '?'
      + this.LAST_MODIFIED
      + '='
      + compareOperator
      + date
      + '&'
      + this.GROUP_BY
      + '='
      + 'ownerCompany'
      + '&'
      + fieldCompany
      + '='
      + idCompany
      + '&';
  }

  public getDataRangeFilter(startYearMonthFilter: string | null, endYearMonthFilter: string | null): string {
    let filter: string = '';
    if (!!startYearMonthFilter) {
      filter = filter + 'lastModified=$greaterEq$' + startYearMonthFilter + '&';
    }
    if (!!endYearMonthFilter) {
      filter = filter + 'lastModified=$lesserEq$' + endYearMonthFilter + '&';
    }
    return filter;
  }

  public getCreationCompanyT1Filter(creationCompany: string|undefined): string {
    let filter: string = '';
    if (creationCompany !== undefined) {
      filter = 'creationCompany=' + creationCompany + '&'
    }
    return filter;
  }

}

const statisticApiTCare = new StatisticApiTCare();
export { statisticApiTCare as default };
