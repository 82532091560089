import React from 'react';
import { Button, Modal as AntDModal, Upload } from 'antd';
import { AdvancedForm } from '../../components';
import { UploadChangeParam, UploadFile } from 'antd/es/upload/interface';
import { certificationTypes, CertificationUpload } from '../../api/types';
import { __, T } from '../../config/i18n';
import { Schema } from '../../components/AdvancedForm';
import { UploadOutlined } from '@ant-design/icons';
import { FormInstance } from 'antd/es/form';
import { Certifications } from '../../api';
import { showError, showNotification } from '../../helpers/Notifications';
import { setOffGlobalSpinningOnHeader } from '../../api/api';
import { Spin } from 'antd/es';

interface Props {
  companyId: string;
  visible: boolean;
  onConfirm: () => void;
  onCancel: () => void;
}

interface State {
  scopeCertificate: Omit<Partial<CertificationUpload>, 'file'> & { file?: UploadFile }
  scopeCertificateUploadFileList: UploadFile[],
  isSpinningRunning: boolean
}

export default class ScopeCertificateUploadModal extends React.Component<Props, State> {
  formRef = React.createRef<FormInstance>();

  state: State = {
    scopeCertificate: {},
    scopeCertificateUploadFileList: [],
    isSpinningRunning: false
  };

  getMapCertifications(): { label: string, value: string }[] {
    let certifications: { label: string, value: string }[];
    certifications = certificationTypes.map(scopeCertificateType => ({
      label: __(scopeCertificateType),
      value: scopeCertificateType
    }));

    return certifications;
  }


  getScopeCertificateUploadSchema = (): Schema[] => {
    return [
      {
        name: 'file',
        label: __(T.fields.file),
        placeholder: __(T.misc.select_scope_certificate),
        rules: [{ required: true }],
        col: 24,
        valuePropName: 'file',
        getValueFromEvent: ({ fileList }: UploadChangeParam) => {
          return fileList && fileList.length > 0 ? fileList[0] : undefined;
        },
        render: () => {
          return (
            <Upload
              listType="text"
              beforeUpload={_ => false}
              fileList={this.state.scopeCertificateUploadFileList}
              onChange={info =>
                this.setState((currentState: State) => ({
                  scopeCertificateUploadFileList: info.fileList.slice(-1)
                }))
              }
            >
              <Button icon={<UploadOutlined />}>{__(T.misc.select_scope_certificate)}</Button>
            </Upload>
          );
        }
      },
      {
        name: 'certificateType',
        label: __(T.fields.type),
        type: 'select',
        options: this.getMapCertifications(),
        placeholder: __(T.misc.select),
        rules: [{ required: true }]
      },
      {
        name: 'companyName',
        label: __(T.fields.company_name),
        type: 'text',
        placeholder: __(T.misc.insert),
        rules: [{ required: true }]
      },
      {
        name: 'expiration',
        label: __(T.fields.expiration_date),
        type: 'date',
        placeholder: __(T.misc.select),
        rules: [{ required: true }]
      },
      {
        name: 'certificateNumber',
        label: __(T.fields.certificate_number),
        type: 'text',
        placeholder: __(T.misc.insert),
        rules: [{ required: true }]
      }
    ];
  };

  handleCancel = () => this.setState({ scopeCertificate: {}, scopeCertificateUploadFileList: [] }, this.props.onCancel);

  handleOk = () => {
    if (this.formRef.current) {
      this.formRef.current.validateFields().then(
        async _ => {
          try {
            this.setState({
              isSpinningRunning: true
            });
            await Certifications.add({
              ...(this.state.scopeCertificate as CertificationUpload),
              companyId: this.props.companyId,
              file: (this.state.scopeCertificate.file?.originFileObj ?? undefined) as File | undefined
            }, setOffGlobalSpinningOnHeader());
            this.setState({
              isSpinningRunning: false
            });
            showNotification(__(T.messages.attachment_loaded) + this.state.scopeCertificate.certificateNumber);
            this.props.onConfirm();
          } catch (error) {
            showError(__(T.errors.file_upload_failed));
          }
        },
        _ => ({})
      );
    }
  };

  render = () => {
    const { visible } = this.props;
    const { scopeCertificate } = this.state;
    return (
      <AntDModal
        centered
        title="Upload Certificate"
        visible={visible}
        onCancel={_ => this.handleCancel()}
        onOk={_ => this.handleOk()}
        okText={__(T.buttons.confirm)}
        cancelText={__(T.buttons.cancel)}
      >
        <Spin spinning={this.state.isSpinningRunning} size="large" style={{ bottom: '1000px' }}>

          <AdvancedForm
            formRef={this.formRef}
            schema={this.getScopeCertificateUploadSchema()}
            values={scopeCertificate}
            onValuesChange={(changedValues, values) => this.setState({ scopeCertificate: values })}
          />
        </Spin>
      </AntDModal>
    );
  };
}
