import React from 'react'
import { Card, Col, Spin } from "antd";
import GradientGaugePlot from "../ant-d-modules/gauge/GradientGaugePlot";

interface Props {
    total: number
    active: number
    forCent: number
    isLoading: boolean
    title: string
    footerLabel: string
}

interface State {
}


export default class GradientGaugePlotCard extends React.Component<Props, State> {
    state: State = {}
    render() {
        return (
            <Col xl={8} sm={24} lg={12}>
                <Card
                    title={this.props.title}
                    bordered={true}
                    style={{width: "100%", height: 500}}
                >
                    <Spin spinning={this.props.isLoading} size="large">
                        <GradientGaugePlot
                            active={this.props.active}
                            total={this.props.total}
                            forCent={this.props.forCent}
                            footerLabel={this.props.footerLabel}/>
                    </Spin>
                </Card>
            </Col>
        )
    }
}




