import typesSettingsTools from './TypesSettingsTools';
import { isAdmin, isViewer } from '../helpers/permission';
import AppStore from '../AppStore';

export class OrderTools {

  addDisableRootFilterOption(type: string | undefined, params: {}) {
    const typeSetting = typesSettingsTools.getSettingByMatchParamsType(type, 'disableEnquiryDefaultFilter');
    if (!typeSetting) {
      if (isAdmin() || isViewer()) {
        params['rootOrder'] = true;
      } else {
        params['ownerCompany'] = AppStore.loggedUser?.company?.id ?? undefined;
      }
    }
  }

}



const orderTools = new OrderTools();
export { orderTools, orderTools as default };


