import { Page, Identifier, QueryParams } from 'api/types'
import { AxiosRequestConfig } from 'axios'
import api, { responseErrorCheck } from './api'

class Identifiers<T = Identifier> {
  protected endpoint = 'tracemanager/v1/identifiers'

  async findAll(axiosConfig?: AxiosRequestConfig) {
    const res = await api.get<Page<T>>(`${this.endpoint}`, {}, axiosConfig)
    return responseErrorCheck(res)
  }

  async get(id: string, axiosConfig?: AxiosRequestConfig) {
    const res = await api.get<T>(`${this.endpoint}/${id}`, {}, axiosConfig)
    return responseErrorCheck(res)
  }

  async search(params: QueryParams, axiosConfig?: AxiosRequestConfig) {
    const res = await api.get<Page<T>>(`${this.endpoint}`, params, axiosConfig)
    return responseErrorCheck(res)
  }

  async create(identifier: Partial<Identifier>, axiosConfig?: AxiosRequestConfig) {
    const res = await api.post<T>(`${this.endpoint}`, identifier, axiosConfig)
    return responseErrorCheck(res)
  }

  getEndpoint() {
    return this.endpoint
  }

  getIdentifierAutocompleteQueryParam(identifierTypes: string[], companyId: string) {
    if (identifierTypes) {
      let ids = 'company=' + companyId + '&'
      identifierTypes.forEach(id => (ids += `identifierType=${id}&`))
      return `${ids}codeRegex`
    }
    return `codeRegex`
  }

  getTraceAutocompleteQueryParam() {
    return `codeRegex`
  }
}

const identifiers = new Identifiers()
export { identifiers as default }
