import React, { Component, RefObject } from 'react';
import { Modal, Button, Typography } from 'antd';
import { __, T } from 'config/i18n';
import { AppForm, View } from 'components';
import theme from 'theme/theme';
import DOMPurify from 'dompurify';

interface Props {
  visible: boolean;
  title: string;
  subtitle?: string;
  onCancel: () => void;
  onSuccess: (e: Map<string, string>) => void;
  onDraft: (e: Map<string, string>) => void;
  schema: any;
  data?: any;
  onValueChange?: (value: any) => void;
}

const { Title, Text } = Typography;

interface State {
  feedback?: string;
}

export default class ModalJsonSchema extends Component<Props, State> {
  formSubmitButtonRef: RefObject<HTMLButtonElement> = React.createRef<HTMLButtonElement>();
  state: State = {};

  handleChange = (event): void => {
    this.setState({ feedback: event.target.value });
  };

  render() {
    const { title, visible, subtitle } = this.props;
    return (
      <Modal centered visible={visible} closable={false} footer={null} onCancel={() => this.props.onCancel} width={800}>
        <Title style={{ textAlign: 'center' }} level={4}>
          <div dangerouslySetInnerHTML={{ __html: __('modal-confirm-title', DOMPurify.sanitize(title)) }} />
        </Title>
        {subtitle && subtitle !== '' && (
          <Text style={{ display: 'inline-block', width: '100%' }}>
            {subtitle}
          </Text>
        )}
        <AppForm
          schema={this.props.schema}
          data={this.props.data}
          onSuccess={(e: Map<string, string>) => {
            this.props.onSuccess(e);
          }}
          formSubmitButtonRef={this.formSubmitButtonRef}
        />

        <View center style={{ marginTop: 25, display: 'flex', justifyContent: 'space-between' }}>
          <Button
            style={{ fontWeight: 700, ...theme.temeraSecondaryDarkButton }}
            type="default"
            onClick={() => this.props.onCancel()}
          >
            {__(T.buttons.cancel)}
          </Button>
          <span>
            <Button
              type="ghost"
              style={{ fontWeight: 700, marginLeft: 25, backgroundColor: __(T.colors.gray_button) }}
              onClick={() => {
                this.props.onDraft(this.props.data);
              }}
            >
            {__(T.buttons.save_without_generate)}
            </Button>
            <Button
              style={{ fontWeight: 700, marginLeft: 25, ...theme.temeraPrimaryGreenButton }}
              type="default"
              onClick={() => {
                this.formSubmitButtonRef.current?.click();
              }}
            >
            {__(T.buttons.generate)}
            </Button>
          </span>
        </View>
      </Modal>
    );
  }
}
