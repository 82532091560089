import { AxiosRequestConfig } from 'axios';
import api, { responseErrorCheck } from './api';
import { LibraryItem, Page, QueryParams } from './types';



class LibraryItems {
  protected endpoint = 'tracemanager/v1/libraryitems'

  async get(id: string, axiosConfig?: AxiosRequestConfig) {
    const res = await api.get<LibraryItem>(`${this.endpoint}/${id}`, {}, axiosConfig);
    return responseErrorCheck(res);
  }

  async search(params: QueryParams, axiosConfig?: AxiosRequestConfig) {
    const res = await api.get<Page<LibraryItem>>(`${this.endpoint}`, params, axiosConfig);
    return responseErrorCheck(res);
  }

  async save(LibraryItem: LibraryItem, axiosConfig?: AxiosRequestConfig) {
    const res = await api.post<LibraryItem>(`${this.endpoint}`, LibraryItem, axiosConfig);
    return responseErrorCheck(res);
  }

  async update(id: string, LibraryItem: LibraryItem, axiosConfig?: AxiosRequestConfig) {
    const res = await api.put<LibraryItem>(`${this.endpoint}/${id}`, LibraryItem, axiosConfig);
    return responseErrorCheck(res);
  }

  async delete(id: string, axiosConfig?: AxiosRequestConfig) {
    const res = await api.delete<LibraryItem>(`${this.endpoint}/${id}`, {}, axiosConfig);
    return responseErrorCheck(res);
  }

}

const libraryItems = new LibraryItems()
export { libraryItems as default }
