import React from 'react'
import { Layout, Menu } from 'antd'

const { Sider } = Layout

interface Props {
  composants: string[]
  onComposantSelection: (composant: string) => void
}

export default class OrderTraceReportComposantMenu extends React.Component<Props> {
  render = () => {
    const { composants, onComposantSelection } = this.props
    return (
      <Sider
        style={{
          backgroundColor: 'white',
          borderRight: '1px solid #D6D6D6',
        }}
        className="order-trace-report-composant-menu-sider"
      >
        <Menu
          className={'order-trace-report-composant-menu'}
          defaultSelectedKeys={composants.length > 0 ? [composants[0]] : []}
          multiple={false}
          onSelect={({ key: composant }) => {
            if (typeof composant === 'string') {
              onComposantSelection(composant)
            }
          }}
        >
          {composants.map(composant => (
            <Menu.Item key={composant}>
              {/*<Checkbox />*/}
              {composant}
            </Menu.Item>
          ))}
        </Menu>
      </Sider>
    )
  }
}
