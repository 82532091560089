import React from 'react'
import {
    Company,
    DetailedOrder,
    DetailedOrderWithOwnerCompanyInfo,
    OrderStatus,
    SubOrder,
    SubOrderCardConfiguration,
    SubOrderWithOwnerCompanyInfo
} from '../../api/types'
import {Button, Dropdown, Menu, Space, Tag} from 'antd'
import {__, T} from '../../config/i18n'
import {getUserRole, isAdmin, isSupplier, isViewer} from '../../helpers/permission'
import {navigate} from '../../router'
import AppStore from "../../AppStore";
import moment from 'moment'
import OrderStatusTag from "./OrderStatusTag";
import {DownOutlined, UpOutlined} from "@ant-design/icons";
import {Companies} from "../../api";


type CompatibleOrder = SubOrderWithOwnerCompanyInfo | DetailedOrderWithOwnerCompanyInfo
type SourceOrder = SubOrder | DetailedOrder


export const getOrderCardCompanyMap = async (orderList: SourceOrder[]) => {
    const ids = orderList.flatMap(order =>
        [order.ownerCompany, order.creationCompany, order.assigneeCompany]
            .filter((val) => val !== null) as string[])
        .filter((value, index, self) => {
            return self.indexOf(value) === index;
        })

    if (ids.length === 0) {
        return {}
    }

    return (await Companies.search({
                ids: ids,
                size: ids.length,
                page: 0
            }
        )
    ).content.reduce((acc: { [id: string]: Company }, company: Company) => {
        acc[company.id] = company;
        return acc;
    }, {});
}
export const getOrderCardDetail = async (order: SubOrder | DetailedOrder): Promise<CompatibleOrder> => {

    const companyMap = await getOrderCardCompanyMap([order]);

    return getOrderCardDetailWithCompanyMap(order, companyMap);
}

export const getOrderCardDetailWithCompanyMap = async (order: SubOrder | DetailedOrder, companyMap: {
    [p: string]: Company
}): Promise<CompatibleOrder> => {

    const ownerCompany = order.ownerCompany ? companyMap[order.ownerCompany] : undefined
    const creationCompany = order.creationCompany ? companyMap[order.creationCompany] : undefined
    const assigneeCompany = order.assigneeCompany ? companyMap[order.assigneeCompany] : undefined
    return {
        ...order,
        ownerCompanyName: ownerCompany?.data.businessName,
        certificationValidity: creationCompany?.certificationValidity,
        creationCompanyName: creationCompany?.data.businessName,
        assigneeCompanyName: assigneeCompany?.data.businessName
    }
}

interface OrderCardProps {
    order: CompatibleOrder
    parentOrderStatus?: OrderStatus
    onViewDetail?: (orderId: string) => void
    onRejectOrder?: (orderId: string) => void
    onReassignOrder?: (orderId: string) => void
    onCloneOrder?: (orderId: string) => void
    onCancel?: (orderId: string) => void
    onUnlock?: (orderId: string) => void
    onShowLogs?: (orderId: string) => void
    style?: React.CSSProperties
    noFulfillment?: boolean
    showFulfillmentDate?: boolean
    showAssignmentDate?: boolean
    onCappelletto?: (orderId: string, open: boolean) => void
    showCappellettoOpen?: boolean
    showPurchaseOrder?: boolean
    hideSupplier?: boolean
    askToUpdate: number
    isSubOrder: boolean
    pageType?: string
    parentOrderId?: string
}

interface OrderCardState {
    cardConfigurations: SubOrderCardConfiguration []
}

export default class OrderCard extends React.Component<OrderCardProps, OrderCardState> {
    state: OrderCardState = {
        cardConfigurations: []
    }

    componentDidMount() {
        this.setState({
            cardConfigurations: this.retrieveSubOrderCardConfiguration(this.props)
        })
    }

    componentDidUpdate(prevProps: Readonly<OrderCardProps>, prevState: Readonly<OrderCardState>, snapshot?: any) {
        if (
            prevProps.askToUpdate !== this.props.askToUpdate
        ) {
            this.setState({
                cardConfigurations: this.retrieveSubOrderCardConfiguration(this.props)
            })
        }
    }

    private retrieveSubOrderCardConfiguration = (props: OrderCardProps): SubOrderCardConfiguration [] => {
        const traceReportFieldsType = 'traceReportFields-' + props.order.traceType.code
        return AppStore.appSettings[traceReportFieldsType] ?
            JSON.parse(AppStore.appSettings[traceReportFieldsType]) :
            AppStore.appSettings.traceReportFields ?
                JSON.parse(AppStore.appSettings.traceReportFields) :
                buildDefaultSubOrderCardConfiguration(this.props)
    }

    actions = (order: CompatibleOrder) => {
        const {noFulfillment, isSubOrder, parentOrderStatus} = this.props

        const showActionLogsButton = this.showActionLogsButton(order)

        const baseActions = isSubOrder && !isViewer() ? [showActionLogsButton] : []
        const extraActions = this.getExtraActions(order, isSubOrder, parentOrderStatus, noFulfillment);

        return [...baseActions, ...extraActions]
    }

    private getExtraActions(
        order: CompatibleOrder,
        isSubOrder: boolean,
        parentOrderStatus?: OrderStatus,
        noFulfillment?: boolean
    ) {
        const cancelOrderButton = this.cancelOrderButton(order)
        const cloneOrderButton = this.cloneOrderButton(order)
        const viewDetailButton = this.viewDetailButton(order)
        const rejectOrderButton = this.rejectOrderButton(order)
        const reassignOrderButton = this.reassignOrderButton(order)
        const thirdPartFulfillmentButton = this.thirdPartFulfillmentButton(order)
        const unlockOrderButton = this.unlockOrderButton(order)

        const canCancel = isSubOrder &&
            AppStore.appSettings.enableCancelSubOrders &&
            parentOrderStatus &&
            (parentOrderStatus === 'ASSIGNED' || parentOrderStatus === 'DRAFT' || parentOrderStatus === 'FULFILLED') &&
            (isAdmin() || AppStore.loggedUser?.company?.id === order.assigneeCompany)

        const canReject = isSubOrder && AppStore.appSettings.enableRejectOrder && (isAdmin() || AppStore.loggedUser?.company?.id === order.assigneeCompany)
        const canReassign = isSubOrder && isSupplier() && AppStore.loggedUser?.company?.id === order.assigneeCompany

        const enableAddAssignByTraceType = AppStore.appSettings['enableAddAssign-' + order.traceType.code]
        const enableAddAssignSetting = enableAddAssignByTraceType ?? AppStore.appSettings.enableAddAssign ?? false

        const canClone = isSubOrder &&
        enableAddAssignSetting &&
        parentOrderStatus ? (parentOrderStatus === 'ASSIGNED' || parentOrderStatus === 'DRAFT') : false

        switch (getUserRole()) {
            case 'admin':
                switch (order.status) {
                    case 'ASSIGNED':
                        return [
                            ...(canCancel ? [cancelOrderButton] : [])
                        ]
                    case 'DRAFT':
                        return [
                            viewDetailButton,
                            ...(canCancel ? [cancelOrderButton] : [])
                        ]
                    case 'COMPLETED':
                        return [viewDetailButton, ...(canReject ? [rejectOrderButton] : [])]
                    case 'FULFILLED':
                        return [viewDetailButton]
                    case 'APPROVED':
                        return [viewDetailButton]
                    case "CANCELLED":
                        return [
                            ...(canCancel ? [unlockOrderButton] : [])
                        ]
                    default:
                        return []
                }
            case 'supplier':
            case 'admin-supplier':
                switch (order.status) {
                    case 'TO_BE_ASSIGNED':
                    case 'ASSIGNED':
                    case 'DRAFT':
                        return [
                            ...(AppStore.appSettings.disableFullfilledComplete ? [] : !noFulfillment ? [thirdPartFulfillmentButton] : []),
                            ...(noFulfillment ? [viewDetailButton] : []),
                            ...(canReassign ? [reassignOrderButton] : []),
                            ...(canCancel ? [cancelOrderButton] : []),
                            ...(canClone ? [cloneOrderButton] : [])
                        ]
                    case 'FULFILLED':
                    case 'COMPLETED':
                        return [
                            viewDetailButton,
                            ...(canReject ? [rejectOrderButton] : []),
                            ...(canClone ? [cloneOrderButton] : [])]
                    case 'APPROVED':
                        return [viewDetailButton]
                    case "CANCELLED":
                        return [
                            ...(canCancel ? [unlockOrderButton] : [])
                        ]
                    default:
                        return []
                }
            case "viewer":
                return [viewDetailButton]
            case "viewer-supplier":
                return [viewDetailButton]
            default:
                return []
        }
    }

    thirdPartFulfillmentButton = (order: CompatibleOrder): any => {
        return {
            key: 'complete_trace',
            onClick: () => {
                if (this.props.pageType) {
                    navigate('/reports/enquiry/:type/detail/:parentOrderId/fulfillThirdpart/:orderId/:thirdPart', {
                        orderId: order.id,
                        parentOrderId: this.props.parentOrderId,
                        thirdPart: true,
                        type: this.props.pageType
                    })

                } else {
                    navigate('/reports/orders/detail/:parentOrderId/fulfillThirdpart/:orderId/:thirdPart', {
                        orderId: order.id,
                        parentOrderId: this.props.parentOrderId,
                        thirdPart: true,
                    })
                }
            },
            label: __(T.buttons.complete_trace)
        }

    }

    viewDetailButton = (order: CompatibleOrder): any => {
        return {
            key: 'view',
            onClick: () => {
                const {onViewDetail} = this.props
                if (onViewDetail) {
                    onViewDetail(order.id!)
                }
            },
            label: __(T.buttons.view)
        }
    }

    rejectOrderButton = (order: CompatibleOrder): any => {
        return {
            key: 'reject',
            onClick: () => {
                const {onRejectOrder} = this.props
                if (onRejectOrder) {
                    onRejectOrder(order.id!)
                }
            },
            label: __(T.buttons.reject)
        }
    }


    reassignOrderButton = (order: CompatibleOrder): any => {
        return {
            key: 'reassign',
            onClick: () => {
                const {onReassignOrder} = this.props
                if (onReassignOrder) {
                    onReassignOrder(order.id!)
                }
            },
            label: __(T.buttons.reassign)
        }

    }

    cancelOrderButton = (order: CompatibleOrder): any => {
        return {
            key: 'cancel',
            onClick: () => {
                const {onCancel} = this.props
                if (onCancel) {
                    onCancel(order.id!)
                }
            },
            label: __(T.buttons.cancel)
        }

    }

    unlockOrderButton = (order: CompatibleOrder): any => {
        return {
            key: 'restore',
            onClick: () => {
                const {onUnlock} = this.props
                if (onUnlock) {
                    onUnlock(order.id!)
                }
            },
            label: __(T.buttons.unlock)
        }

    }

    cloneOrderButton = (order: CompatibleOrder): any => {
        return {
            key: 'clone',
            onClick: () => {
                const {onCloneOrder} = this.props
                if (onCloneOrder) {
                    onCloneOrder(order.id!)
                }
            },
            label: __(T.buttons.addAssignation)
        }

    }

    showActionLogsButton = (order: CompatibleOrder): any => {
        return {
            key: 'actionlogs',
            onClick: () => {
                const {onShowLogs} = this.props
                if (onShowLogs) {
                    onShowLogs(order.id!)
                }
            },
            label: __(T.buttons.show_logs)
        }
    }

    getValueByConfiguration = (conf: SubOrderCardConfiguration): JSX.Element => {
        const {order} = this.props
        switch (conf.type) {
            case "GENERIC":
                const genericValue = this.retrieveValueElementFromOrder(conf.field!)
                if (!genericValue) {
                    return <></>
                }
                return !!conf.bold ? <b>{genericValue}</b> : <>{genericValue}</>
            case "PO":
                return <>
                    <div>{order.orderDate && moment(order.orderDate).format('DD.MM.YYYY')}</div>
                    <div>{order.code}</div>
                </>
            case "DATE":
                const dateValue = this.retrieveValueElementFromOrder(conf.field!)
                if (!dateValue) {
                    return <></>
                }
                return <>{moment(dateValue).format(conf.dateFormat!)}</>
            case "STATUS":
                return <OrderStatusTag status={order.status}/>
            case "TAG":
                return order.reject ?
                    <Tag key={order.id + '_tag_reject'}
                         color={__(T.colors.rejected)}>{__(T.tags.rejected)}</Tag>
                    : <></>
            case "SCOPE":
                return <>
                    <span className={"trace_type_order_label"}>Scope Certificate</span>
                    <div className={"trace_type_order_content"}>
                        <Tag
                            color={__(T.colors[`scope_certificate_valid_${order.certificationValidity}_background`])}
                            style={{
                                fontWeight: "bold",
                                textAlign: 'center',
                                color: __(T.colors[`scope_certificate_valid_${order.certificationValidity}_text`]),
                                borderRadius: '5px'
                            }}>
                            <b>{order.certificationValidity ? 'valid' : 'not valid'}</b>
                        </Tag>
                    </div>
                </>
            default:
                return <>Type {conf.type} not managed</>
        }
    }

    private retrieveValueElementFromOrder = (fieldKey: string): string | undefined => {
        const {order} = this.props
        let currentValue: any = order

        const fields = fieldKey.split('.')
        for (let field of fields) {
            currentValue = currentValue[field]
            if (currentValue === undefined || currentValue === null) {
                return undefined
            }
        }

        return currentValue
    }

    render = () => {
        const {order} = this.props
        return (
            <>
                <div className={"trace_type_order"} style={{
                    ...(this.props.style ?? {})
                }}>
                    {this.state.cardConfigurations.map(
                        (conf, index) =>
                            <div
                                key={conf.label + index}
                                style={conf.width ? {width: conf.width} : {flexGrow: 1}}
                                className={"trace_type_order_cell"}
                            >
                                <span className={"trace_type_order_label"}>{conf.label}</span>
                                <div
                                    className={"trace_type_order_content"}>{this.getValueByConfiguration(conf)}</div>
                            </div>
                    )}
                    <div style={{display: 'flex', alignItems: 'center', flexDirection: 'row'}}
                         className={"trace_type_order_cell"}>
                        {this.actions(order).length > 1 ?
                            <Dropdown key="bulk_actions" overlay={
                                <Menu items={this.actions(order)}/>}>
                                <Button>
                                    <Space>
                                        {__(T.buttons.actions)}
                                        <DownOutlined/>
                                    </Space>
                                </Button>
                            </Dropdown>
                            :
                            this.actions(order).map(item => <Button key={item.key}
                                                                    onClick={() => item.onClick()}>{item.label}</Button>)}
                    </div>
                    {this.props.onCappelletto &&
                        <div
                            style={{display: 'flex', alignItems: 'center', width: '50px', flexDirection: 'row'}}
                            className={"trace_type_order_cell"}>

                            <Button
                                type="text"
                                style={{fontSize: "14px"}}
                                onClick={() => {
                                    const {onCappelletto} = this.props
                                    if (onCappelletto) {
                                        onCappelletto(order.id!, !this.props.showCappellettoOpen)
                                    }
                                }
                                }
                            >
                                {this.props.showCappellettoOpen && <><UpOutlined/> </>}
                                {!this.props.showCappellettoOpen && <><DownOutlined/> </>}
                            </Button>
                        </div>}
                </div>
            </>
        )
    }
}

export interface SubOrderCardConfigurationProps {
    readonly showPurchaseOrder?: boolean
    readonly hideSupplier?: boolean
    readonly showAssignmentDate?: boolean
    readonly showFulfillmentDate?: boolean
}

export const buildDefaultSubOrderCardConfiguration = (props: SubOrderCardConfigurationProps): SubOrderCardConfiguration [] => {
    const configurations: SubOrderCardConfiguration[] = []

    if (!!props.showPurchaseOrder) {
        configurations.push({
            type: 'GENERIC',
            label: 'Purchase order',
            bold: true,
            field: 'code',
            visibleIntoAssignedMode: true,
            width: '160px'
        })
    } else {
        configurations.push(
            {
                type: 'GENERIC',
                label: 'Step',
                bold: true,
                field: 'step',
                visibleIntoAssignedMode: true,
                width: '160px'
            }
        )
    }

    if (!props.hideSupplier) {
        configurations.push(
            {
                type: 'GENERIC',
                label: 'Supplier',
                field: 'creationCompanyName'
            }
        )
    }

    if (AppStore.appSettings.enableExpiringCertificationManagement) {
        configurations.push(
            {
                type: 'SCOPE',
                label: 'Scope Certificate',
                width: '120px'
            }
        )
    }

    configurations.push(
        {
            type: 'PO',
            label: 'PO (date/code)',
            dateFormat: 'DD.MM.YYYY',
            width: '200px'
        }
    )

    if (!!props.showAssignmentDate) {
        configurations.push(
            {
                type: 'DATE',
                label: 'Assignement Date',
                field: 'assignmentDate',
                dateFormat: 'DD.MM.YYYY',
                width: '120px'
            }
        )
    }

    if (!!props.showFulfillmentDate) {
        configurations.push(
            {
                type: 'DATE',
                label: 'Fulfillment Date',
                field: 'fulfillmentDate',
                dateFormat: 'DD.MM.YYYY',
                width: '120px'
            }
        )
    }

    configurations.push(
        {
            type: 'STATUS',
            label: 'Status',
            width: '103px'
        }
    )

    configurations.push(
        {
            type: 'TAG',
            label: 'Tag',
            width: '103px'
        }
    )

    return configurations
}
