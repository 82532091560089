import React from 'react';
import { Affix, PageHeader, Space, Typography } from 'antd';
import { Text } from '../index';
import moment from 'moment';
import OrderStatusTag from './OrderStatusTag';
import { __ } from '../../config/i18n';
import {getGenericOrSpecifiedSetting} from "../../tools/TypesSettingsTools";

export interface ItemPageHeaderType {
  path?: string,
  dataType?: 'DATE',
  type: 'STATUS' | 'BIG' | 'VERTICAL_LABEL_VALUE' | 'LABEL_VALUE' | 'LABEL',
  divider?: boolean,
  label?: string
}

export interface EntityPageHeaderConfig {
  header: ItemPageHeaderType[],
  footer?: ItemPageHeaderType[]
}

interface Props {
  data?: any;
  extras?: JSX.Element[];
  onBack?: (e?: React.MouseEvent<HTMLDivElement>) => void;
  config: string | EntityPageHeaderConfig;
  extraConfigType?: string;
}

interface State {
  shadowBoxStyle: React.CSSProperties;
}

export default class EntityPageHeader extends React.Component<Props, State> {
  state: State = {
    shadowBoxStyle: { backgroundColor: 'white', boxShadow: '0px 0px 10px 4px rgba(0,0,0,0.0)' }
  };

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll = (event) => {
    if (window.scrollY < 10) {
      this.setState({ shadowBoxStyle: { backgroundColor: 'white', boxShadow: '0px 0px 10px 4px rgba(0,0,0,0.0)' } });
    } else if (window.scrollY > 10 && window.scrollY < 50) {
      this.setState({ shadowBoxStyle: { backgroundColor: 'white', boxShadow: '0px 0px 10px 4px rgba(0,0,0,0.05)' } });
    } else if (window.scrollY > 50 && window.scrollY < 80) {
      this.setState({ shadowBoxStyle: { backgroundColor: 'white', boxShadow: '0px 0px 10px 4px rgba(0,0,0,0.10)' } });
    } else if (window.scrollY > 80 && window.scrollY < 100) {
      this.setState({ shadowBoxStyle: { backgroundColor: 'white', boxShadow: '0px 0px 10px 4px rgba(0,0,0,0.20)' } });
    } else if (window.scrollY > 100 && window.scrollY < 200) {
      this.setState({ shadowBoxStyle: { backgroundColor: 'white', boxShadow: '0px 0px 10px 4px rgba(0,0,0,0.30)' } });
    }
  };

  private getField(data: any, config: ItemPageHeaderType) {
    let value = config.path ? this.getValue(data, config.path) : config.label;
    if (config.dataType === 'DATE') {
      value = moment(value).format('DD/MM/YYYY');
    }
    if (!value) {
      return <div key={'key_' + config.path}/>;
    }

    switch (config.type) {
      case "BIG":
        return <Text black key={'key_'+config.path}>{__(value)}</Text>
      case "LABEL":
        return <div style={{ width: 'auto', lineHeight: 'initial', marginTop: '-4px' }} key={'key_'+config.path}>{value}</div>
      case "LABEL_VALUE":
        return <Typography.Text style={{ fontSize: 15, fontWeight: 'normal' }} key={'key_'+config.path}>{__(config.label ?? '')}: <Text bold style={{
          fontSize: 15,
          margin: 0
        }}>{value}</Text></Typography.Text>
      case "VERTICAL_LABEL_VALUE":
        return <div style={{ width: 'auto', lineHeight: 'initial', marginTop: '-4px' }}  key={'key_'+config.path}>
              <span className={'trace_hide'}
                    style={{ fontSize: 15, margin: 0, lineHeight: 'initial' }}>{__(config.label ?? '')}</span>
          <div className={'trace_type_order_label'} style={{ fontSize: 15, lineHeight: 'initial' }}>{value}</div>
        </div>
      case "STATUS":
        return <OrderStatusTag status={value} style={{ display: 'flex' }}  key={'key_'+config.path}/>
      default:
        return <div key={'key_type_'+config.type}>Can't render element of type {config.type}</div>
    }
  }

  private getValue(obj: any, path: string) {
    const paths = path.split('.');
    let value = obj;
    for (let key of paths) {
      value = value[key];
      if (!value) {
        return null;
      }
    }
    return value;
  }

  render = () => {
    const { data, extras } = this.props;
    let config: EntityPageHeaderConfig;
    try {
      if (typeof this.props.config === 'string') {
        const configStr:string = this.props.config
        config = JSON.parse(getGenericOrSpecifiedSetting(configStr, this.props.extraConfigType).replaceAll('\'', '"'));
      } else {
        config = this.props.config;
      }
    } catch (e) {
      console.log('There is not orderPageHeader configuration');
      config = {
        header: [],
        footer: []
      };
    }

    return (
      <Affix>
        <PageHeader
          style={this.state.shadowBoxStyle}
          title={
            <>
              <Space size="middle">
                {config.header.map(c => this.getField(data, c))}
              </Space>
              {config.footer && config.footer.length > 0 &&
                <>
                  <br />
                  <Space size="middle">
                    {config.footer.map(c => this.getField(data, c))}
                  </Space>
                </>
              }
            </>
          }
          extra={extras}
          onBack={this.props.onBack}
        />
      </Affix>
    );
  };
}

