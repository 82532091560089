import React, {Component, createRef} from 'react';
import {Button, Modal} from 'antd';
import {__, T} from 'config/i18n';
import {View} from 'components';
import OrderAssignmentForm from "../../components/orders/OrderAssignmentForm";
import {DetailedOrder} from "../../api/types";
import {AssignmentForm} from "./OrderAssign";
import {FormInstance} from "antd/es/form";
import {Companies} from "../../api";
import AppStore from "../../AppStore";

interface Props {
    order: DetailedOrder
    subOrderId: string
    orderCode: string
    orderDate: string
    companyId: string
    onJsonSchemaSubmit: (value: AssignmentForm) => void
    onClickButton: (value?: AssignmentForm) => void
    attributes: {
        [key: string]: any
    }
}

interface State {
    assignment?: { [subOrderId: string]: AssignmentForm[] }
}

export default class OrderCloneModal extends Component<Props, State> {
    orderAssignmentFormRef = createRef<OrderAssignmentForm>();

    state: State = {
        assignment: undefined
    }

    componentDidMount = async () => {
        await this.refresh();
    }

    componentDidUpdate = async (prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any) => {
        if (prevProps.order && this.props.order && prevProps.order.id !== this.props.order.id) {
            await this.refresh();
        }
    }

    refresh = async () => {
        const {
            attributes,
            orderCode,
            orderDate,
            subOrderId,
            companyId
        } = this.props

        const assignments: { [subOrderId: string]: AssignmentForm[] } = {}
        assignments[subOrderId] = [{
            originalOrderId: subOrderId,
            company: await Companies.get(companyId),
            formRef: React.createRef<FormInstance>(),
            buttonFormRef: React.createRef<HTMLButtonElement>(),
            buttonFormRefValid: false,
            orderCode,
            orderDate,
            attributes: {
                ...attributes
            }
        }]

        this.setState({assignment: assignments})
    }

    render() {
        const {order, onClickButton, subOrderId} = this.props
        const {assignment} = this.state

        return (
            <Modal centered visible={true} closable={false} footer={null} onCancel={() => onClickButton(undefined)}>
                {assignment && <OrderAssignmentForm
                    ref={this.orderAssignmentFormRef}
                    order={order}
                    assignments={assignment}
                    onAssignmentChange={this.handleAssignmentChange}
                    onInviteCompanyClick={this.handleInviteCompanyClick}
                    onJsonSchemaFormSuccess={this.onJsonSchemaSubmit}
                    width={24}
                    disableInviteButton={AppStore.appSettings.disableOnboardingInviteOnAssignment}
                />}

                <View center style={{marginTop: 25}}>
                    <Button
                        style={{...{fontWeight: 700}}}
                        type="default"
                        onClick={() => onClickButton(undefined)}
                    >
                        {__(T.buttons.cancel)}
                    </Button>
                    <Button
                        style={{...{fontWeight: 700, marginLeft: 25}}}
                        type="primary"
                        onClick={() => {
                            onClickButton(assignment![subOrderId][0])
                        }}
                    >
                        {__(T.buttons.confirm)}
                    </Button>
                </View>
            </Modal>
        )
    }

    handleAssignmentChange = async (assignmentForm: AssignmentForm, changedValues, values) => {
        const changedValueKeys = Object.keys(changedValues)
        if (changedValueKeys.length > 0) {
            const { assignment } = this.state
            if (changedValueKeys.includes('company')) {
                const companyId = changedValues.company ? changedValues.company.value : undefined
                assignmentForm.company = companyId ? await Companies.get(companyId) : undefined
            }
            this.setState({assignment})
        }

    }

    handleInviteCompanyClick = ()=> {
        // TODO do nothing for now, to handle in future
    }

    onJsonSchemaSubmit = (assignment: AssignmentForm) => {
        this.props.onJsonSchemaSubmit(assignment)
    }
}
