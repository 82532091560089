import React, { Component } from 'react'
import { __, T } from 'config/i18n'
import { DeleteOutlined, EyeOutlined, CheckCircleOutlined, MinusCircleOutlined } from '@ant-design/icons'
import { View } from 'components'
import { Typography } from 'antd'
import { Rule } from 'api/types'
import moment from 'moment'

interface Props {
  rule: Rule
  index: number
  view: (rule: Rule) => void
  archived?: (index: number) => void
  reopenRule?: (index: number) => void
  deleteRule?: (id: string) => void
}

const { Text } = Typography

export default class RuleElement extends Component<Props> {
  getValuesCounter = () => {
    const { rule } = this.props
    let counter = 0
    for (let r = 0; r < rule.exceptions.length; r++) {
      if (Array.isArray(rule.exceptions[r].values)) {
        counter += rule.exceptions[r].values.length
      } else {
        counter++
      }
    }
    return counter
  }

  render = () => {
    const { rule, view, archived, index, deleteRule, reopenRule } = this.props
    return (
      <View className="sc-rule-box">
        <Text className="sc-rule-col">{rule.name}</Text>
        <Text className="sc-rule-col ml">
          {moment(rule.startDate).format('DD.MM.YYYY')}
          {rule.endDate && rule.endDate !== null && (
            <Text style={{ marginLeft: 5 }}>
              {__(T.misc.to)} {moment(rule.endDate).format('DD.MM.YYYY')}
            </Text>
          )}
        </Text>
        <View className="sc-rule-col counter">
          <Text>{__(T.fields.property)}</Text>
          <View className="sc-ball counter">
            <Text style={{ fontWeight: 700, color: '#FFF' }}>{rule.exceptions.length}</Text>
          </View>
        </View>
        <View className="sc-rule-col counter">
          <Text>{__(T.fields.value)}</Text>
          <View className="sc-ball counter">
            <Text style={{ fontWeight: 700, color: '#FFF' }}>{this.getValuesCounter()}</Text>
          </View>
        </View>
        <View className="sc-rule-col action">
          {archived && (
            <MinusCircleOutlined
              style={{ fontSize: 18, marginLeft: 10, marginRight: 10 }}
              onClick={() => archived(index)}
            />
          )}
          {reopenRule && (
            <CheckCircleOutlined
              style={{ fontSize: 18, marginLeft: 10, marginRight: 10 }}
              onClick={() => reopenRule(index)}
            />
          )}
          {deleteRule && (
            <DeleteOutlined
              style={{ fontSize: 18, marginLeft: 10, marginRight: 10 }}
              onClick={() => deleteRule(rule.id || '')}
            />
          )}
          <EyeOutlined style={{ fontSize: 22, marginLeft: 9 }} onClick={() => view(rule)} />
        </View>
      </View>
    )
  }
}
