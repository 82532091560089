import React from 'react'
import { DeleteOutlined } from "@ant-design/icons";
import { Button, Col, DatePicker, Form, Input, Row, Tooltip } from 'antd'
import { SubOrderAssignment, SubOrderMultiAssignment } from '../../api/types'
import {__, T} from "../../config/i18n";

interface Props {
  assignments: SubOrderMultiAssignment[]
  onDeleteAssignment: (subOrderAssignment: SubOrderMultiAssignment) => void
  onAssignmentsValuesChange: (subOrderAssignment: SubOrderAssignment, changedValues, values) => void
}

export default class OrderMultiAssignmentTable extends React.Component<Props> {

  render = () => {
    const {assignments, onDeleteAssignment} = this.props
    return (
      <>
        {assignments.map(assignment => (
        <Form
          ref={assignment.parentFormRef}
          initialValues={assignment}
          layout="horizontal"
          onValuesChange={(changedValues, values) => this.props.onAssignmentsValuesChange(assignment, changedValues, values)}
        >
          <Row>
            <Col span={7} style={{paddingRight: 10 }}>
              <b>{__(T.fields.parent_order_code)}</b>
              <Form.Item name={'parentOrderCode'}>
                <Input placeholder='insert' disabled={true}/>
              </Form.Item>
            </Col>
            <Col span={7} style={{paddingRight: 10}}>
              <b>{__(T.fields.purchase_order_code)}</b>
              <Form.Item name={'orderCode'} rules={[{required: true, message: __(T.messages.field_required)}]}>
                <Input placeholder='insert'/>
              </Form.Item>
            </Col>
            <Col span={7} style={{paddingRight: 10}}>
              <b>{__(T.fields.purchase_order_date)}</b>
              <Form.Item key={2} name={'orderDate'} rules={[{required: true, message: __(T.messages.field_required)}]}>
                <DatePicker style={{width: '100%'}} placeholder='select a date'/>
              </Form.Item>
            </Col>
            <Col span={3} style={{paddingLeft: 10, paddingRight: 10}}>
              <br/>
              <Form.Item>
                <Tooltip title="Delete">
                  <Button shape="circle" icon={<DeleteOutlined/>} onClick={() => onDeleteAssignment(assignment)}/>
                </Tooltip>
                {/*<DeleteOutlined onClick={() => onDeleteAssignment(assignment)}/>*/}
              </Form.Item>
            </Col>
          </Row>
        </Form>
        ))}
      </>
    )
  }

}
