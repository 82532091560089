import { FileInformation } from 'api/types';
import { AxiosRequestConfig } from 'axios'
import api, { responseErrorCheck } from './api'
import errorPrint from "../tools/ErrorPrint";

const endpoint = 'filemanager/v1/files'

class Files {

  async downloadFileByTraceUrl(url: string, onDownloadProgress?: (ProgressEvent) => void, axiosConfig?: AxiosRequestConfig) {
    await this.downloadFile(this.getFileId(url), onDownloadProgress)
  }

  getFileId = (traceUrl: string | undefined) => {
    if (!traceUrl) {
      return 'NA';
    }

    return traceUrl.substring(traceUrl.indexOf('fileId,') + 7);
  };

  async downloadFile(fileId: string, onDownloadProgress?: (ProgressEvent) => void, axiosConfig?: AxiosRequestConfig) {

    let axiosConfigMerged: AxiosRequestConfig = {
      responseType: 'blob',
      timeout: 200000,
      headers: {
        ... axiosConfig,
        Accept: 'application/octet-stream'
      }
    }

    await api.get(`${endpoint}/${fileId}/download`, {}, axiosConfigMerged)
        .then(response => {
          let filename;
          const disposition = response.headers!['content-disposition'];
          filename = disposition.split('filename=')[1];
          const a = document.createElement('a');
          a.href = window.URL.createObjectURL(response.data);
          a.download = filename;
          document.body.appendChild(a);
          a.click();
        })
        .catch(error => {
          console.error(error);
        });

  }

  async upload(file: any, onUploadProgress?: (ProgressEvent) => void, axiosConfig?: AxiosRequestConfig ): Promise<FileInformation | undefined> {
    const formData = new FormData();
    formData.append('file', file);

    let axiosConfigMerged: AxiosRequestConfig = {
      responseType: 'blob',
      timeout: 200000,
      headers: {
        ... axiosConfig,
        'content-type': 'multipart/form-data',
        onUploadProgress
      }
    }

    const res = await api.post<FileInformation>(`${endpoint}`, formData, axiosConfigMerged)
    return responseErrorCheck(res)
  }

  async uploadBase64(
    base64File: string,
    onUploadProgress?: (ProgressEvent) => void
  ): Promise<FileInformation | undefined> {
    let startIndex = base64File.indexOf('name')
    if (startIndex === -1) throw Error('No such file')
    startIndex += 5
    const endIndex = base64File.indexOf(';', startIndex)
    const filename = base64File.substr(startIndex, endIndex - startIndex)
    try {
      const start = new Date().getTime()
      const res = await fetch(base64File)
      const blob = await res.blob()
      const file = new File([blob], filename)
      const end = new Date().getTime()
      console.log('TEMPO IMPIEGATO ' + (end - start))
      return this.upload(file, onUploadProgress)
    } catch (e) {
      throw Error('Something goes wrong during file upload\n' +  errorPrint.returnMessage(e))
    }
  }

  async download(fileId: string, onDownloadProgress?: (ProgressEvent) => void, axiosConfig?: AxiosRequestConfig) {

    let axiosConfigMerged: AxiosRequestConfig = {
      onDownloadProgress,
      headers: {
        ... axiosConfig
      }
    }


    const res = await api.get(`${endpoint}/${fileId}/download`, {}, axiosConfigMerged)
    return responseErrorCheck(res)
  }

  async downloadBase64(fileId: string, onDownloadProgress?: (ProgressEvent) => void, axiosConfig?: AxiosRequestConfig) {
    let axiosConfigMerged: AxiosRequestConfig = {
      onDownloadProgress,
      headers: {
        ... axiosConfig
      }
    }
    const res = await api.get(`${endpoint}/${fileId}/downloadBase64`, {}, axiosConfigMerged)
    return responseErrorCheck(res)
  }

  async getInformations(fileId: string, axiosConfig?: AxiosRequestConfig) {
    const res = await api.get(`${endpoint}/${fileId}`, {}, axiosConfig)
    return responseErrorCheck(res)
  }

  async getTemporaryUrl(fileId: string, axiosConfig?: AxiosRequestConfig): Promise<string | undefined> {
    const res = await api.get<string>(`${endpoint}/${fileId}/downloadLink`, {}, axiosConfig)
    return responseErrorCheck(res)
  }



}

const filemanager = new Files()
export { filemanager as default }
