import React from 'react'
import Orders from '../../api/Orders'
import {DetailedOrder, DetailedOrderWithOwnerCompanyInfo} from "../../api/types";
import {Modal as AntDModal, Spin} from "antd";
import TraceDetailForm from '../traces/TraceDetailForm'
import {Companies} from "../../api";
import AppStore from '../../AppStore';
import {LoadingOutlined} from '@ant-design/icons';

interface Props {
    visible: boolean
    orderId?: string
    onCancel: () => void
    hideSupplier?: boolean
}

interface State {
    order?: DetailedOrder
}

export default class OrderTraceDetailModal extends React.Component<Props, State> {
    state: State = {}

    componentDidMount = async () => {
    }

    componentDidUpdate = async (prevProps: Props) => {
        if (this.props.orderId !== prevProps.orderId || this.props.visible !== prevProps.visible) {
            if (this.props.visible) {
                await this.loadOrder(this.props.orderId)
            } else {
                this.setState({order: undefined})
            }
        }
    }

    getSchema = (order: DetailedOrder) => {
        const schema = JSON.parse(order.traceType.schema)
        if (schema) {
            schema.title = undefined
        }
        return schema
    }

    loadOrder = async (orderId?: string) => {
        const order: DetailedOrderWithOwnerCompanyInfo | undefined = await Orders.get(orderId!)
        if (order && !order.data) {
            order.data = {}
        }
        if (order && order.ownerCompany) {
            // http://localhost:3000/profilemanager/v1/companies/202207151337-9227cae5-7924-4121-9d3e-822af8119519
            const company = await Companies.get(order.ownerCompany)
            order.ownerCompanyName = company?.data.businessName
            order.certificationValidity = company?.certificationValidity
        }
        this.setState({order: order})
    }

    render = () => {
        const {visible, onCancel} = this.props
        const {order} = this.state
        return (
            <AntDModal visible={visible} footer={null} onCancel={onCancel} centered width={1000}>
                {order ?
                    <TraceDetailForm
                        isModal={true}
                        schema={this.getSchema(order)} order={order}
                        isShowExportCsvButton={AppStore.appSettings.enableExportCSVOnTrace}
                        hideSupplier={this.props.hideSupplier || false}/>
                    :
                    <div style={{display: "flex", justifyContent: "center"}}>
                        <Spin indicator={<LoadingOutlined style={{fontSize: 24}} spin/>}/>
                    </div>
                }
            </AntDModal>
        )
    }
}
