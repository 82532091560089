import { OrderDetailQrCodeSetting } from '../api/types';
import typesSettingsTools from './TypesSettingsTools';

export class QrCodeTools {

  getParsedOrderDetailQrCodeSetting(orderTraceTypeCode: string | undefined): OrderDetailQrCodeSetting | undefined {
    // Ottieni l'impostazione utilizzando la funzione esistente
    let orderDetailQrCodeSetting = typesSettingsTools.getSettingByTraceType(orderTraceTypeCode, 'orderDetailQrCode');

    // Verifica se l'impostazione è definita e se è una stringa JSON valida
    if (typeof orderDetailQrCodeSetting === 'string' && orderDetailQrCodeSetting.trim() !== '') {
      try {
        // Parsea la stringa JSON
        return JSON.parse(orderDetailQrCodeSetting) as OrderDetailQrCodeSetting;
      } catch (e) {
        console.error('Errore nel parsing della stringa JSON:', e);
        return undefined;
      }
    }

    // Restituisci undefined se l'impostazione non è definita o non è una stringa JSON
    return undefined;
  }

}



const qrCodeTools = new QrCodeTools();
export { qrCodeTools, qrCodeTools as default };


