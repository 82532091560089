import React from 'react'
import { DetailedOrderWithOwnerCompanyInfo, OrderShipment, SubOrderWithOwnerCompanyInfo } from '../../api/types'
import OrderCard from "./OrderCard";
import OrderCardShipment from "./OrderCardShipment";

type CompatibleOrder = SubOrderWithOwnerCompanyInfo | DetailedOrderWithOwnerCompanyInfo

interface Props {
    order: CompatibleOrder
    shipmentList: OrderShipment[]
    showPurchaseOrder: boolean
    navigateToDetail: (orderId: string) => void
    pageType?:string
}

interface State {
    showShipment: boolean
}

export default class OrderCardShipments extends React.Component<Props, State> {
    state: State = {
        showShipment: false
    }

    onclickToDetail = (orderId: string, open: boolean): void => {
        this.setState({showShipment: open});
    }

    // Weaving
  render = () => {
    const { order } = this.props
    return (
      <>
          <OrderCard
              askToUpdate={0}
              showPurchaseOrder={this.props.showPurchaseOrder}
              onViewDetail={this.props.navigateToDetail}
              onCappelletto={this.onclickToDetail}
              order={order}
              noFulfillment
              showFulfillmentDate
              showAssignmentDate
              showCappellettoOpen={this.state.showShipment}
              style={{
                  margin: '30px 40px 20px 40px',
                  width: 'auto',
                  backgroundColor: '#efefef',
              }}
              isSubOrder={false}
              pageType={this.props.pageType}
          />
          {this.state.showShipment &&
              <>
                  {this.props.shipmentList.map(orderShipment => (
                      <>
                          <OrderCardShipment
                              orderShipment={orderShipment}
                              style={{
                                  margin: '0 60px 20px 40px',
                                  width: 'auto',
                                  backgroundColor: '#ffffff',
                              }}
                          />
                      </>
                  ))}
              </> }
      </>
    )
  }
}
