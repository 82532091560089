import React from 'react'
import { Layout, AdvancedForm, View, Text } from 'components'
import { Schema } from 'components/AdvancedForm'
import { __, T } from 'config/i18n'
import { navigate } from 'router'
import theme from 'theme/theme'
import { Modal, Divider, Typography } from 'antd'
import { StartTraceCreationFields } from "types";
import { TraceType, Identifier, Trace } from 'api/types'
import { TraceTypes, Subsuppliers, Identifiers, Traces, Companies, Files } from 'api'
import api from 'api/api'
import { Theme as AntDTheme } from '@rjsf/antd'
import moment from 'moment'

import { withTheme } from '@rjsf/core'
import { showError, showNotification } from 'helpers/Notifications'
import AppStore from 'AppStore'
import { getOperationsForRole, getUserRole } from 'helpers/permission'
import { getCertificatesKeysByCategory } from 'helpers/certifications'
import { ObjectFieldTemplate } from "../../components/JsonSchemaObjectFieldTemplate";

const { Title } = Typography
const Form = withTheme(AntDTheme)
interface State {
  showConfirmDialog: boolean
  startCreationFields: StartTraceCreationFields
  traceTypes: TraceType[]
  subsuppliers: any[]
  selectedTraceType?: TraceType
  selectedIdentifier?: Identifier
  previousTraces?: string[]
  showAll: boolean
  previousIdentifiers: string[]
  newIdentifier?: string
  identifierAlreadyExistError: boolean
  expiredCertificateWarningMessage?: string
  isNewIdentifier: boolean
}

interface Props {}

export default class CreateTrace extends React.Component<Props, State> {
  creationType = getUserRole() === 'representative' ? 'thirdPart' : 'own'
  subsupplierCompanyId = undefined
  getBaseTraceId = async () => {
    let countryCode = AppStore.loggedUser?.company.data.country
    const subsupplierId = this.state.startCreationFields.subsupplier
    if (subsupplierId) {
      const subsupplier = (await Subsuppliers.findOne(AppStore.loggedUser?.company.id, subsupplierId))?.content[0]
      this.subsupplierCompanyId = subsupplier.referee.id
      countryCode = subsupplier.data.countryCode
    }
    const today = moment(new Date()).format('DDMMYY')

    return countryCode + today
  }

  baseTraceId = ''
  state: State = {
    showConfirmDialog: false,
    startCreationFields: { creationType: getUserRole() === 'representative' ? 'thirdPart' : 'own' },
    traceTypes: [],
    subsuppliers: [],
    showAll: false,
    previousIdentifiers: [],
    identifierAlreadyExistError: false,
    isNewIdentifier: true,
    expiredCertificateWarningMessage: undefined,
  }
  traceCreateThirdParts = getOperationsForRole().traceCreateThirdParts

  fetchSubsuppliers = async () => {
    if (!this.traceCreateThirdParts) return []

    let subsuppliers = await Companies.findAllSubsupplier(AppStore.loggedUser?.company.id)

    const subsuppliersOptions = subsuppliers?.map(subsupplier => {
      return { value: subsupplier.id, label: subsupplier.label }
    })
    this.setState({ subsuppliers: subsuppliersOptions ?? [] })
  }

  fetchTraceTypes = async () => {
    if (!AppStore.loggedUser?.company) return
    let traces = (await TraceTypes.findAll())?.content
    const subsupplierId = this.state.startCreationFields.subsupplier
    let showAll = this.state.showAll
    let categories = AppStore.loggedUser?.company.categories
    if (subsupplierId && this.state.startCreationFields.creationType === 'thirdPart') {
      const subsuppliers = (await Subsuppliers.findOne(AppStore.loggedUser?.company.id, subsupplierId))?.content
      if (!subsuppliers || subsuppliers?.length === 0) return
      categories = subsuppliers[0].categories
    }
    if (
      (!categories || categories.length === 0) &&
      AppStore.loggedUser?.company.type === 'it.temera.stw.tcare.profilemanagerservice.dto.RepresentativeDto'
    ) {
      showAll = true
    }

    if (!showAll) traces = traces?.filter(trace => categories!.filter(c => c === trace.category).length > 0)
    this.setState({ traceTypes: traces ?? [] })
  }

  componentDidMount() {
    if (AppStore.refreshCreateTracePageRequired) {
      AppStore.saveRefreshCreateTracePageRequired(false)
      window.location.reload()
    } else {
      const permission = getOperationsForRole()
      if (permission.traceCreatePersonal || permission.traceCreateThirdParts) {
        Promise.all([this.fetchTraceTypes(), this.fetchSubsuppliers()])
      } else navigate('/')
    }
  }

  formData = {}
  saveTrace = async (data: {}) => {
    if (this.state.identifierAlreadyExistError) {
      showError(__(T.error.identifier_already_existing))
      return
    }

    if (this.state.selectedIdentifier === undefined) {
      showError(__(T.error.missing_trace_identifier), __(T.messages.select_trace_identifier_to_continue))
      return
    }

    this.formData = data
    this.setState({ showConfirmDialog: true })
  }

  async uploadFile(data: any, key: string) {
    if (!data[key]) return
    const base64File = data[key]
    if (Array.isArray(base64File)) {
      data[key] = []
      for (let i = 0; i < base64File.length; i++) {
        try {
          const info = await Files.uploadBase64(base64File[i], (ev: ProgressEvent) => {
            console.log({ pecentual: ev.loaded / ev.total, loaded: ev.loaded, total: ev.total })
          })
          data[key].push(info)
        } catch (e) {
          console.log(e)
        }
      }
    } else {
      try {
        const info = await Files.uploadBase64(base64File, (ev: ProgressEvent) => {
          console.log({ pecentual: ev.loaded / ev.total, loaded: ev.loaded, total: ev.total })
        })
        data[key] = info
      } catch (e) {
        console.log(e)
      }
    }
  }

  async uploadFiles(trace: Partial<Trace>) {
    if (!trace.data) return trace
    const keys: { key: string; nested?: boolean }[] = []
    const schema = JSON.parse(this.state.selectedTraceType!.schema)
    // 1. estrazione dei file da caricare
    for (let key in schema.properties) {
      const value = schema.properties[key]
      if (value.type === 'string' && value.format === 'data-url') {
        keys.push({ key })
      }
      // array of files
      if (value.type === 'array' && value.items?.format === 'data-url') {
        keys.push({ key })
      }
      // array of properties
      if (value.type === 'array' && value.items?.properties !== undefined) {
        for (let nestedKey in value.items.properties) {
          const nestedValue = value.items.properties[nestedKey]
          if (nestedValue.type === 'string' && nestedValue.format === 'data-url') {
            keys.push({ key: key + ';' + nestedKey, nested: true })
          }
        }
      }
    }

    // 2. carimento dei file e mettere fileInformation dentro la traccia

    for await (let key of keys) {
      if (!key.nested) {
        await this.uploadFile(trace.data, key.key)
      } else {
        const explodedKeys = key.key.split(';')
        const arrayObj = trace.data[explodedKeys[0]] ?? []
        for await (let obj of arrayObj) {
          await this.uploadFile(obj, explodedKeys[1])
        }
      }
    }
    return trace
  }

  addTrace = async () => {
    const { selectedTraceType, isNewIdentifier, expiredCertificateWarningMessage } = this.state
    if (!selectedTraceType || !this.state.selectedIdentifier) return undefined
    let selectedIdentifier: Identifier | undefined = { ...this.state.selectedIdentifier }

    if (isNewIdentifier) {
      selectedIdentifier.previousIdentifiers = this.state.previousTraces
      selectedIdentifier.code = this.baseTraceId + selectedIdentifier.code
      try {
        selectedIdentifier = await Identifiers.create(selectedIdentifier)
      } catch (e) {
        console.log(e)
        showError(__(T.errors.identifier_creation_failed))
        return
      }
    }
    let trace: Partial<Trace> = {
      code: 'human friendly code', // TODO
      identifier: { id: selectedIdentifier?.id },
      traceType: selectedTraceType,
      data: this.formData,
      creationCompany: this.state.startCreationFields.subsupplier,
      certificateExpired: expiredCertificateWarningMessage ? true : false,
    }
    try {
      // 1. Estrazione file e caricamento
      trace = await this.uploadFiles(trace)

      // 2. Caricamento della traccia
      await Traces.add(trace)
      showNotification(__(T.messages.trace_created))
      // navigate('/reports/traces')
    } catch (e) {
      console.log(e)
      showError(__(T.errors.trace_creation_failed))
    }
  }

  selectIdentifier = (changedValues, values) => {
    const { selectedTraceType, startCreationFields } = this.state
    if (changedValues.previousTraces) {
      const previousTraces: string[] = changedValues.previousTraces.map(t => JSON.parse(t.value).id) ?? []
      this.setState({ previousTraces })
    }
    if (changedValues.identifier) {
      const selectedIdentifier = JSON.parse(changedValues.identifier.value)
      const previousIdentifiers = selectedIdentifier.previousIdentifiers
        ? selectedIdentifier.previousIdentifiers.map(identifier => identifier.code)
        : []

      const id = selectedIdentifier.id
      const code = changedValues.identifier.label
      this.setState({
        selectedIdentifier: {
          id: id,
          creationDate: 0,
          lastUpdate: 0,
          code: code,
          identifierType: selectedTraceType!.identifierType,
        },
        previousIdentifiers,
      })
    } else if (changedValues.newIdentifier !== undefined) {
      this.setState({
        newIdentifier: changedValues.newIdentifier.trim(),
        selectedIdentifier: {
          id: 0,
          creationDate: 0,
          lastUpdate: 0,
          code: changedValues.newIdentifier.trim(),
          identifierType: selectedTraceType!.identifierType,
        },
      })
    } else if (!values.identifier && (!values.newIdentifier || values.newIdentifier === '')) {
      this.setState({ selectedIdentifier: undefined })
    }
    this.setState({
      startCreationFields: { ...startCreationFields, ...values },
      isNewIdentifier: values.isNewIdentifier,
    })
  }

  checkCertificationsExpiration = async () => {
    const { startCreationFields } = this.state
    try {
      if (startCreationFields.creationType === 'own') {
        const categories = AppStore.loggedUser?.company.categories
        if (!categories || categories.length === 0) {
          showError('Error', 'Category not found')
          return
        }
        // const expiredCertificates = AppStore.loggedUser?.company.certifications.filter(
        //   cert =>
        //     getCertificatesKeysByCategory(category).includes(cert.data.certificateKey) &&
        //     moment(cert.data.expiration).isBefore(moment(), 'day')
        // )
        // if (expiredCertificates && expiredCertificates.length > 0) {
        //   const warningMessage = __(T.messages.create_trace_expired_certifications)
        //   showNotification(__(T.misc.warning), warningMessage, 'warning', 0)
        //   this.setState({ expiredCertificateWarningMessage: warningMessage })
        // }
      } else if (startCreationFields.subsupplier && startCreationFields.creationType === 'thirdPart') {
        const subsuppliers = (
          await Subsuppliers.findOne(AppStore.loggedUser?.company.id, startCreationFields.subsupplier)
        )?.content
        if (!subsuppliers || subsuppliers?.length === 0) {
          showError('Error', 'Subsupplier not found')
          return
        }
        const category = subsuppliers[0].data.category
        const expiredCertificates = subsuppliers[0].certifications.filter(
          cert =>
            getCertificatesKeysByCategory(category).includes(cert.data.certificateKey) &&
            moment(cert.data.expiration).isBefore(moment(), 'day')
        )
        if (expiredCertificates && expiredCertificates.length > 0) {
          const warningMessage = __(T.messages.create_trace_subsupplier_expired_certifications)
          showNotification(__(T.misc.warning), warningMessage, 'warning', 0)
          this.setState({ expiredCertificateWarningMessage: warningMessage })
        }
      }
    } catch (error) {
      console.log(error)
      showError('Error', 'Certificates validation error')
    }
  }

  renderForm = () => {
    const { selectedTraceType } = this.state
    if (selectedTraceType === undefined) return undefined
    const schema = JSON.parse(selectedTraceType.schema)
    schema.title = undefined
    const hasData = Object.keys(this.formData).length !== 0
    return (
      <Form
        ObjectFieldTemplate={ObjectFieldTemplate}
        onSubmit={x => this.saveTrace(x.formData)}
        formData={hasData ? this.formData : undefined}
        showErrorList={false}
        onChange={x => (this.formData = x.formData)}
        schema={schema}
      >
        <button
          style={{
            width: '100%',
            ...theme.temeraPrimaryGreenButton,
            padding: 8,
            borderWidth: 0,
            borderRadius: 6,
            cursor: 'pointer',
          }}
        >
          {__(T.buttons.create_trace)}
        </button>
      </Form>
    )
  }

  creationSchema = (): Schema[] => {
    const { selectedTraceType, startCreationFields, subsuppliers, previousIdentifiers, isNewIdentifier } = this.state
    const previousIdentifierType = selectedTraceType?.identifierType.previousIdentifierTypes?.map(el => el.id)
    const subsupplier = subsuppliers.find(subsupplier => subsupplier.value === startCreationFields.subsupplier)
    const createTraceTitle =
      subsupplier === undefined ? __(T.titles.create_personal_trace) : `${__(T.titles.create_third_part_trace)}`
    const companyId = subsupplier === undefined ? AppStore.loggedUser?.company.id : this.subsupplierCompanyId
    const canHavePrevious =
      selectedTraceType?.identifierType.previousIdentifierTypes &&
      selectedTraceType?.identifierType.previousIdentifierTypes.length > 0
    return [
      {
        name: '',
        notInput: true,
        render: () => <Title level={4}>{`${selectedTraceType?.network}`}</Title>,
        hidden: !selectedTraceType,
      },
      {
        name: '',
        notInput: true,
        render: () => {
          return (
            <View style={{ flex: 1, display: 'flex', flexDirection: 'row' }}>
              <Text h1>{createTraceTitle}</Text>
              {subsupplier && (
                <Text h1 bold style={{ marginLeft: 2 }}>
                  {subsupplier.label}
                </Text>
              )}
            </View>
          )
        },
      },
      {
        name: '',
        notInput: true,
        render: () => <Divider />,
      },
      {
        name: '',
        notInput: true,
        render: () => (
          <Text h1 bold>
            {__(T.titles.trace_identifier)}
          </Text>
        ),
      },
      {
        name: 'isNewIdentifier',
        type: 'radio',
        options: [
          { label: __(T.fields.new_id), value: true, tooltip: __(T.messages.new_id_tooltip) },
          { label: __(T.fields.existing_id), value: false, tooltip: __(T.messages.existing_id_tooltip) },
        ],
        initialValue: isNewIdentifier,
        col: { xs: 24, sm: { span: 24 } },
        rules: [{ required: true }],
      },
      {
        name: 'identifier',
        label: __(T.fields.trace_identifier),
        type: 'autocomplete',
        placeholder: __(T.misc.select),
        urlSearch: `${api.getBaseURL()}${Identifiers.getEndpoint()}?${Identifiers.getIdentifierAutocompleteQueryParam(
          ['' + selectedTraceType?.identifierType.id],
          companyId
        )}=-X-&traceType=${selectedTraceType?.id}`,
        descriptionKey: 'code',
        col: { xs: 24, sm: { span: 24 } },
        rules: [
          {
            required: true,
          },
        ],
        hide: isNewIdentifier,
      },
      {
        name: 'newIdentifier',
        label: __(T.fields.new_trace_identifier),
        type: 'text',
        placeholder: __(T.misc.insert),
        inputAddonBefore: this.baseTraceId,
        col: { xs: 24, sm: { span: 24 } },
        rules: [
          {
            required: true,
          },
        ],
        onInputBlur: async () => {
          const codeRegex = (await this.getBaseTraceId()) + (this.state.newIdentifier ?? '')
          const res = await Identifiers.search({
            page: 0,
            size: 10000,
            codeRegex,
          })
          let alreadyExist = false
          if (res && res?.totalElements > 0) {
            res.content.forEach(el => {
              if (el.code === codeRegex) alreadyExist = true
            })
          }
          this.setState({ identifierAlreadyExistError: alreadyExist })
        },
        hide: !isNewIdentifier,
      },
      {
        name: '',
        notInput: true,
        render: () => <View style={{ color: 'red' }}>{__(T.error.identifier_already_existing)}</View>,
        hide: !this.state.identifierAlreadyExistError,
      },
      {
        name: 'previousTraces',
        label: __(T.fields.previous_traces),
        type: 'autocomplete',
        multipleAutocomplete: true,
        placeholder: __(T.misc.select),
        urlSearch: `${api.getBaseURL()}${Identifiers.getEndpoint()}?${Identifiers.getIdentifierAutocompleteQueryParam(
          previousIdentifierType ?? [],
          companyId
        )}=-X-`,
        descriptionKey: 'code',
        col: { xs: 24, sm: { span: 24 } },
        hide: !canHavePrevious || !isNewIdentifier,
      },
      {
        name: 'previousTracesSelected',
        label: __(T.fields.previous_traces),
        type: 'select',
        multipleAutocomplete: true,
        col: { xs: 24, sm: { span: 24 } },
        hide: previousIdentifiers.length === 0,
        disabled: true,
      },
      {
        name: '',
        notInput: true,
        render: () => (
          <Text h1 bold style={{ marginTop: 20, marginBottom: 20 }}>
            {__(T.titles.trace_informations)}
          </Text>
        ),
      },
    ]
  }

  render() {
    const {
      startCreationFields,
      selectedTraceType,
      showAll,
      previousIdentifiers,
      newIdentifier,
      subsuppliers,
      expiredCertificateWarningMessage,
    } = this.state
    const showNoSubsupplierMessage = subsuppliers.length === 0 && getUserRole() === 'representative'

    const startCreationSchema: Schema[] = [
      {
        name: '',
        notInput: true,
        render: () => <Text h1>{__(T.misc.select_an_option_and_proceed)}</Text>,
        hide: !this.traceCreateThirdParts || getUserRole() === 'representative',
      },
      {
        name: 'creationType',
        type: 'radio',
        options: [
          { label: __(T.fields.personal), value: 'own' },
          { label: __(T.fields.third_part), value: 'thirdPart' },
        ],
        initialValue: startCreationFields.creationType,
        col: { xs: 24, sm: { span: 24 } },
        rules: [{ required: true, message: __(T.messages.field_required) }],
        hide: !this.traceCreateThirdParts || getUserRole() === 'representative',
      },
      {
        name: '',
        notInput: true,
        render: () => <Text h1>{__(T.misc.select_an_option_and_proceed)}</Text>,
      },
      {
        name: 'subsupplier',
        label: __(T.misc.subsupplier),
        type: 'select',
        placeholder: __(T.misc.select),
        options: this.state.subsuppliers,
        initialValue: startCreationFields.subsupplier,
        col: { xs: 24, sm: { span: 24 } },
        rules: [{ required: true, message: __(T.messages.field_required) }],
        hide: !this.traceCreateThirdParts || startCreationFields.creationType === 'own',
      },
      {
        name: 'traceType',
        label: __(T.fields.type),
        type: 'select',
        placeholder: __(T.misc.select),
        options: this.state.traceTypes?.map((traceType, index) => {
          return { label: traceType.category + ' ' + traceType.network, value: index }
        }),
        showSearch: true,
        initialValue: startCreationFields.traceType,
        col: { xs: 24, sm: { span: 24 } },
        rules: [{ required: true, message: __(T.messages.field_required) }],
        disabled: startCreationFields.creationType === 'thirdPart' && startCreationFields.subsupplier === undefined,
      },
    ]

    return (
      <Layout>
        {!selectedTraceType && false && (
          <button
            onClick={() => {
              this.setState({ showAll: !showAll })
              this.fetchTraceTypes()
            }}
            style={{ marginLeft: 30 }}
          >
            {showAll ? __(T.misc.show_filtered) : __(T.misc.show_all)}
          </button>
        )}
        <View style={{ padding: 30, flex: 1, display: 'flex' }}>
          <View className="page-table" style={{ flex: 1 }}>
            {showNoSubsupplierMessage && (
              <>
                <Text h2 style={{ textAlign: 'center' }}>
                  Non ci sono subfornitori associati alla tua utenza
                </Text>
                <button
                  style={{
                    width: '100%',
                    ...theme.temeraPrimaryGreenButton,
                    padding: 8,
                    borderWidth: 0,
                    borderRadius: 6,
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    // navigate('/reports/subsupplier')
                  }}
                >
                  {__(T.buttons.create_new)}
                </button>
              </>
            )}

            {!selectedTraceType && !showNoSubsupplierMessage && (
              <AdvancedForm
                schema={startCreationSchema}
                onValuesChange={(changedValues, values) => {
                  if (changedValues.creationType)
                    this.setState({
                      startCreationFields: { ...values, traceType: undefined },
                    })
                  else this.setState({ startCreationFields: values })
                  this.fetchTraceTypes()
                }}
                values={this.state.startCreationFields}
                submitButton={{
                  text: __(T.buttons.registration_submit),
                  onPress: async () => {
                    this.baseTraceId = await this.getBaseTraceId()
                    const selectedTraceType = this.state.traceTypes[startCreationFields.traceType ?? 0]
                    AppStore.saveRefreshCreateTracePageRequired(true)
                    this.setState({ selectedTraceType })
                    await this.checkCertificationsExpiration()
                  },
                  col: 24,
                  style: theme.temeraPrimaryGreenButton,
                }}
              />
            )}
            {selectedTraceType && (
              <View style={{ flex: 1, flexDirection: 'row' }}>
                <AdvancedForm
                  schema={this.creationSchema()}
                  onValuesChange={this.selectIdentifier}
                  values={{ previousTracesSelected: previousIdentifiers, newIdentifier: newIdentifier }}
                />
                {this.renderForm()}
              </View>
            )}
          </View>
          <View style={{ flex: 1 }}></View>
        </View>
        <Modal
          title={__(T.confirms.confirm_operation)}
          visible={this.state.showConfirmDialog}
          onOk={() => {
            this.setState({ showConfirmDialog: false })
            this.addTrace()
          }}
          onCancel={() => {
            this.setState({ showConfirmDialog: false })
          }}
        >
          <Text>{__(T.messages.confirm_or_go_back)}</Text>
          <View style={{ marginTop: 10 }}>
            {expiredCertificateWarningMessage !== undefined && <Text inline>{expiredCertificateWarningMessage}</Text>}
          </View>
        </Modal>
      </Layout>
    )
  }
}
