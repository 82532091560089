import React, { Component } from 'react';
import { Menu } from 'antd';
import { __, T } from 'config/i18n';
import { getUserRole } from '../../helpers/permission';
import AppStore from '../../AppStore';
import { CheckOutlined } from '@ant-design/icons';

interface Props {
  selectedKeys?: any;
}



interface State {
}


const getLanguages = () => {
  let languageBlocks:any[] = [];
  AppStore.appSettings.languages.split(',').forEach((language: any) => {
    languageBlocks.push({
      key: language,
      label: __(T.languages[language]),  // la vado a prenere da settings.
      icon:  AppStore.language === language ? <CheckOutlined/> : '',
      onClick: async () => {
        await AppStore.setLanguage(language);
        window.location.reload();
      }
    });
  });
  return languageBlocks;
}

const getHeaderDropDownMenu = () => {
  let headerDropDownMenu: any[] = [];

  headerDropDownMenu.push({
    key:'user',
    label: __(T.misc.role) + ': ' + getUserRole(),
    disabled: true
  });

  headerDropDownMenu = headerDropDownMenu.concat(getLanguages());

  headerDropDownMenu.push( {
    key: 'logout',
    label: __(T.buttons.logout),
    onClick: async () => {
      logout();
    },
    danger: true
  });

  return headerDropDownMenu;
}

const logout = async () => {
  await AppStore.setGoToDashboard(true);
  sessionStorage.clear();
  AppStore.keyCloak?.logout();
};


export default class MenuLanguage extends Component<Props, State> {


  state: State = {};

  render() {
    return (<Menu selectedKeys={this.props.selectedKeys} items={getHeaderDropDownMenu()} />);
  }
}
