import {describeCategory} from 'helpers/describer'
import {category} from 'types'
import appStore from "../AppStore";

class HardCoded {
    getCategoryKeys(): category[] {
        return appStore.appSettings.categories.split('|');
    }

    getCompanyTypes() {
        return [
            {label: 'Fornitore', value: 'supplier'},
            {label: 'Rappresentante', value: 'representative'},
        ]
    }

    getCategoryOptions(): { label: string; value: string; }[] {
        return this.getCategoryKeys().map(value => {
            return {label: describeCategory(value), value: value};
        });
    }

    getOnBoardingCategoryOptions(): { label: string; value: string; }[] {
        const categoriesUsableOnOnBoarding = appStore.appSettings.categoriesUsableOnOnBoarding
        if (categoriesUsableOnOnBoarding) {
            return categoriesUsableOnOnBoarding
              .split('|')
              .map(value => {
                  return {label: describeCategory(value), value: value};
              })
        } else {
            return this.getCategoryOptions()
        }
    }

}

const hardCoded = new HardCoded()
export {hardCoded as default}
