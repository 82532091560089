import React, { Component } from 'react'
import { AdvancedForm } from 'components'
import { CertificationFields, CompanyFields, UserFields } from '../../types'
import FilePicker, { FilePickerProps } from 'components/FilePicker'
import { getCompanySchema, getCompanyTypeSchema, getUserSchema } from './schemas'
import { ButtonProps } from 'components/AdvancedForm'
import { describeCategories } from 'helpers/describer';
import { __, T } from 'config/i18n'

export interface FormFieldsVisibility {
  userData?: boolean
  userPassword?: boolean
  userMail?: boolean
  companyType?: boolean
  companyCategory?: boolean
  companyDatas?: boolean
  certifications?: boolean
}

export interface Props {
  userFields?: UserFields
  companyFields?: CompanyFields

  fieldsVisibility?: FormFieldsVisibility
  fieldsDisabled?: FormFieldsVisibility
  submitButton?: ButtonProps
  resetButton?: ButtonProps
  secondaryButton?: ButtonProps

  onValuesChange?: (changedValues: any, values: any) => void
}

export default class CompanyFormLayout extends Component<Props> {
  full = { xs: 24, sm: { span: 12, offset: 12, pull: 12 } }
  leftHalf = { xs: 24, sm: { span: 6 } }
  rightHalf = { xs: 24, sm: { span: 6, offset: 12, pull: 12 } }

  onFilesLoaded = (name: string, files: CertificationFields[]) => {
    const fields = {
      ...(this.props.userFields ?? {}),
      ...(this.props.companyFields ?? {}),
    }
    if (fields.certifications) {
      // TODO WHAT TO DO HERE?
      // fields.certifications = fields.certifications?.filter(cert => cert.certificateKey !== name)
      fields.certifications = fields.certifications?.concat(files)
    } else {
      fields.certifications = files
    }

    this.props.onValuesChange && this.props.onValuesChange({}, fields)
  }

  onDocumentRemoved = (documentId: string) => {
    const fields = {
      ...(this.props.userFields ?? {}),
      ...(this.props.companyFields ?? {}),
    }

    if (!fields.certifications) return
    fields.certifications = fields.certifications?.filter(cert => cert.id !== documentId)
    this.props.onValuesChange && this.props.onValuesChange({}, fields)
  }

  renderFilePicker = (props: FilePickerProps) => {
    if (props.initialValue === undefined && props.disabled) return <></>
    return (
      <FilePicker
        key={props.certificateKey}
        expirationIsOptional={props.certificateKey === 'ethicsCertificate'}
        onFilesLoaded={this.onFilesLoaded}
        onDocumentRemoved={this.onDocumentRemoved}
        {...props}
      />
    )
  }

  render() {
    const { fieldsVisibility, fieldsDisabled, submitButton, resetButton, secondaryButton } = this.props

    const fields = {
      ...(this.props.userFields ?? {}),
      ...(this.props.companyFields ?? {}),
    }

    if (fieldsDisabled?.companyType) {
      fields.companyTypeDescription =
        fields.companyType === 'supplier' ? __(T.misc.supplier) : __(T.misc.representative)
    }

    if (fieldsDisabled?.companyCategory) {
      fields.categoryDescription = describeCategories(fields.categories)
    }

    if (fieldsDisabled?.companyDatas) {
      fields.adherenceEthicsCodeDescription = fields.adherenceEthicsCode ? __(T.misc.yes) : __(T.misc.no)
    }

    const userSchema = getUserSchema(fields, fieldsVisibility, fieldsDisabled)
    const companyTypeSchema = getCompanyTypeSchema(fields, fieldsVisibility, fieldsDisabled)
    const companySchema = getCompanySchema(fields, fieldsVisibility, this.renderFilePicker, fieldsDisabled)

    const schema = userSchema.concat(companyTypeSchema.concat(companySchema))

    return (
      <AdvancedForm
        schema={schema}
        values={fields}
        onValuesChange={this.props.onValuesChange}
        submitButton={submitButton}
        resetButton={resetButton}
        secondaryButton={secondaryButton}
      />
    )
  }
}
