import { Page } from 'api/types'
import api, { responseErrorCheck } from './api'
import { AxiosRequestConfig } from "axios";

class GeneralProfileApi<T = boolean> {
  protected endpoint = 'profilemanager/v1/users'

  async resetPassword(userId: string, axiosConfig?: AxiosRequestConfig) {
    const res = await api.get<Page<T>>(this.endpoint + '/' + userId + '/resetPassword'  , {}, axiosConfig)
    return responseErrorCheck(res)
  }

}

const generalProfileApi = new GeneralProfileApi()
export { generalProfileApi as default }
