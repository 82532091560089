import React from 'react'
import { Layout, View } from 'components'
import { Files, Traces } from 'api'
import { FileInformation, Trace, TraceRelation, TraceRelationNetworks, TraceRelations } from "api/types";
import { Button, Col, Row, Typography } from "antd";
import { showError } from 'helpers/Notifications'
import { T, __ } from 'config/i18n'
import TracesRelations from "../../api/TracesRelations";
import { navigate } from "../../router";
import AppStore from "../../AppStore";
const { Title, Text } = Typography

interface Props {
  match: { params: { traceId: string } }
}
interface State {
  trace?: Trace
  traceRelations?: TraceRelations
}
type TraceData = { datas?: TraceData[]; title: string; type: 'string' | 'file' | 'array'; value?: string | any }

export default class TraceDetail extends React.Component<Props, State> {
  state: State = {}
  async componentDidMount() {
    const trace: Trace = await Traces.get(this.props.match.params.traceId)
    const traceRelations: TraceRelations = await TracesRelations.findRelation(this.props.match.params.traceId)
    this.setState({ trace: trace, traceRelations: traceRelations })
  }

  extractTraceDatas = (schema: any, data: any): TraceData[] => {
    let datas: TraceData[] = []
    for (const [key, v] of Object.entries(schema)) {
      const value = v as any
      const title = value.title
      if (value.type === 'array' && data[key]) {
        if (schema[key].items.format && schema[key].items.format === 'data-url') {
          // GESTIONE ARRAY DI FILE
          const files: TraceData[] = []
          data[key].forEach(file => {
            files.push({ title: '', type: 'file', value: file })
          })
          datas.push({ datas: files, title, type: 'array' })
        } else {
          data[key] &&
            data[key].forEach(itemArray => {
              const d = this.extractTraceDatas(schema[key].items.properties, itemArray)
              datas.push({ datas: d, title, type: 'array' })
            })
        }
      } else {
        const type = value.format === 'data-url' ? 'file' : 'string'
        datas.push({ title, type: type, value: data[key] })
      }
    }
    return datas
  }

  dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n)

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n)
    }

    return new File([u8arr], filename, { type: mime })
  }

  fileDownload = async (file: FileInformation) => {
    try {
      const url = await Files.getTemporaryUrl(file.id)
      window.open(url)
    } catch (e) {
      showError(__(T.errors.file_not_found))
    }
  }

  renderTable = (traceRelations: TraceRelationNetworks[] | undefined) => {
    if (traceRelations === undefined) {
      return (<View></View>)
    }
    return (
     <View>
       {
         traceRelations.map((traceRelationNetworks: TraceRelationNetworks) => {
           return (
             <>
               <b>
                 {traceRelationNetworks.network}
               </b>
               {
                 traceRelationNetworks.traces.map((traceRelation: TraceRelation) => {
                   return (
                     <li key={traceRelation.id}>
                       {traceRelation.id !== this.state.trace?.id ?
                         <a
                           className='blueLink'
                           href='#'
                           style={{padding: 0}}
                           type="link"
                           onClick={async () => {
                             navigate('/reports/traces/:traceId', { traceId: traceRelation.id })
                             const trace: Trace = await Traces.get(traceRelation.id)
                             const traceRelations: TraceRelations = await TracesRelations.findRelation(traceRelation.id)
                             this.setState({ trace: trace, traceRelations: traceRelations })
                           }}
                         >
                           {traceRelation.category}
                         </a>
                       : <span>
                           {traceRelation.category}
                         </span>}
                       <br/>
                   </li>)
                 })
               }
             </>
           )

         }) }
     </View>
    )
  }

  renderForm = (datas: TraceData[], tab: boolean) => {
    return (
      <View>

        {datas.map(data => {
          if (data.type === 'array' && data.datas) {
            return (
              <>
                <Text style={{ fontSize: 16, fontWeight: 700, color: '#000000', marginTop: 10 }}>{data.title}</Text>
                {this.renderForm(data.datas, true)}
              </>
            )
          } else {
            const fileData = data.value
            const isFile = data.type === 'file' && fileData
            return (
              <div style={{ display: 'flex', flexDirection: 'column', marginLeft: tab ? 12 : 0 }}>
                <Text style={{ fontSize: 16, fontWeight: 700, color: '#000000', marginTop: 10 }}>{data.title}</Text>
                {isFile && (
                  <Button
                    style={{ width: 250, overflow: 'auto' }}
                    onClick={() => {
                      this.fileDownload(fileData)
                    }}
                  >
                    {fileData.fileName}
                  </Button>
                )}
                {!isFile && <Text>{data.value}</Text>}
              </div>
            )
          }
        })}
      </View>
    )
  }

  realRender = () => {
    const { trace } = this.state
    if (trace === undefined) return undefined
    const schema = JSON.parse(trace.traceType.schema)
    const title = schema.title + ' - ' + trace.identifier.code
    schema.title = schema.title + ' - ' + trace.identifier.code
    const datas: TraceData[] = this.extractTraceDatas(schema.properties, trace.data)
    return (
      <View>
        <Title level={4}>{title}</Title>
        {!AppStore.loggedUser?.company &&
          <Row>
            <Col span={6}>
              <h3> Next Traces </h3>
              <ul style={{padding: 0}}>
                {this.renderTable(this.state.traceRelations?.nextTraces)}
              </ul>
            </Col>

            <Col span={6}>
              <h3> Identifier traces </h3>
              <ul style={{padding: 0}}>
                {this.renderTable(this.state.traceRelations?.identifierTraces)}
              </ul>
            </Col>
            <Col span={6}>
              <h3> Previous traces </h3>
              <ul style={{padding: 0}}>
                {this.renderTable(this.state.traceRelations?.previousTraces)}
              </ul>
            </Col>
          </Row>
        }
        {this.renderForm(datas, false)}
      </View>
    )
  }
  render() {
    return (
      <Layout>
        <View className="page-table" style={{ margin: 10 }}>
          {this.realRender()}
        </View>
      </Layout>
    )
  }
}
