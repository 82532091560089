import React, { Component } from 'react'
import { __, T } from 'config/i18n'
import { Form, Row, Col, Select, InputNumber } from 'antd'
import { DeleteOutlined } from '@ant-design/icons'
import { View } from 'components'
import { Properties, RuleForm, RuleException } from 'api/types'

interface Props {
  properties: Properties[]
  rule: RuleForm
  indexRule: number
  exception: RuleException
  remove?: (index: number) => void
  uniqueProperty?: boolean
}

interface State {
  values: Properties[]
  property: string
  valueType: string
  selectValueMode: 'multiple' | 'tags'
  selectValueMaxTagCount: number
}

const { Option } = Select

export default class PropertyRule extends Component<Props, State> {
  state: State = {
    property: '',
    values: [],
    valueType: 'SELECT',
    selectValueMode: 'tags',
    selectValueMaxTagCount: 2,
  }

  propertyChange = (value: any) => {
    const { properties } = this.props
    const index_prop = properties.findIndex(object => object.value === value)
    if (index_prop >= 0) {
      this.setState({
        property: properties[index_prop].value || '',
        values: properties[index_prop].values || [],
        valueType: properties[index_prop].type || 'SELECT',
        selectValueMode: properties[index_prop].mode || 'tags',
        selectValueMaxTagCount: properties[index_prop].maxTagCount || 2,
      })
    }
  }

  getPropertiesSelected = () => {
    const { rule, indexRule } = this.props
    const keys = Object.keys(rule)
    let selected: string[] = []
    for (let k = 0; k < keys.length; k++) {
      if (
        keys[k].indexOf('exception' + indexRule) === -1 &&
        keys[k].indexOf('exception') !== -1 &&
        keys[k].indexOf('exceptions') === -1 &&
        rule[keys[k]].property &&
        !rule[keys[k]].deleted
      ) {
        selected.push(rule[keys[k]].property)
      }
    }
    return selected
  }

  getProperties = () => {
    const { properties, uniqueProperty } = this.props
    let list: Properties[] = uniqueProperty ? [] : properties
    if (uniqueProperty) {
      const selected = this.getPropertiesSelected()
      for (let p = 0; p < properties.length; p++) {
        const index = selected.findIndex(property => property === properties[p].value)
        if (index === -1) {
          list.push(properties[p])
        }
      }
    }
    return list
  }

  render = () => {
    const { remove, indexRule } = this.props
    const { values, valueType, property, selectValueMode, selectValueMaxTagCount } = this.state
    const filterProperties = this.getProperties()
    return (
      <Row gutter={[24, 0]} key={'exception_' + indexRule}>
        <Col xs={24} sm={12}>
          <Form.Item
            name={('exception' + indexRule + '.property').split('.')}
            rules={[{ required: true }]}
            label={__(T.fields.property)}
          >
            <Select
              placeholder={__(T.misc.select)}
              allowClear
              onChange={value => this.propertyChange(value)}
              filterOption={(input, option) => {
                if (option) return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                return false
              }}
            >
              {filterProperties.map(opt => (
                <Option key={opt.value} value={opt.value}>
                  {opt.label}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col xs={24} sm={12}>
          <View style={{ display: 'flex', flexDirection: 'row' }}>
            {valueType === 'SELECT' && (
              <Form.Item
                name={('exception' + indexRule + '.values').split('.')}
                rules={[{ required: true }]}
                label={__(T.fields.value)}
                style={{ flex: 1 }}
              >
                <Select
                  placeholder={__(T.misc.select)}
                  allowClear
                  mode={selectValueMode}
                  maxTagCount={selectValueMaxTagCount}
                  disabled={property === ''}
                  filterOption={(input, option) => {
                    if (option && option.children)
                      return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    return false
                  }}
                >
                  {values &&
                    values.map((opt, i) => (
                      <Option key={'option-' + i} value={opt.value}>
                        {opt.label}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
            )}

            {valueType === 'NUMBER' && (
              <Form.Item
                name={('exception' + indexRule + '.values').split('.')}
                rules={[{ required: true }]}
                label={__(T.fields.value)}
                style={{ flex: 1 }}
              >
                <InputNumber
                  disabled={property === ''}
                  defaultValue={0}
                  placeholder={__(T.misc.insert)}
                  style={{ width: '100%' }}
                  min={0}
                  max={100}
                />
              </Form.Item>
            )}
            {indexRule > 0 && (
              <View className="sc-ball" style={{ marginTop: 34 }}>
                <DeleteOutlined
                  style={{ fontSize: 18, color: '#FFF' }}
                  onClick={() => (remove ? remove(indexRule) : {})}
                />
              </View>
            )}
          </View>
        </Col>
      </Row>
    )
  }
}
