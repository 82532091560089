import React, { Component } from 'react';
import { Pie } from '@ant-design/plots';


interface Props {
    data: {
        type: string,
        value: number
    }[]
}

interface State {
    onBoardingId?: string
    currentStep: number
    loading: boolean
}


export default class PiePlot extends Component<Props, State> {
    state: State = {
        currentStep: 0,
        loading: false,
    }

    shoot = () => {
        this.setState({});
    }

    getPiePlot = () => {
        let config: any = {
            appendPadding: 10,
            data: this.props.data.map(d => {return {...d, type: d.type + ': ' + d.value}}),
            angleField: 'value',
            colorField: 'type',
            radius: 0.75,
            label: {
                type: 'inner',
                offset: '-30%',
                content: ({ percent }) => `${(percent * 100).toFixed(0)}%`,
                style: {
                    fontSize: 14,
                    textAlign: 'center',
                },
            },
            interactions: [
                {
                    type: 'element-selected',
                },
                {
                    type: 'element-active',
                },
            ],
        };

        return <Pie {...config} />;
    };



    render() {
        return (
            this.getPiePlot()
        )
    }
}
