import React from "react";
import AppStore from "../../../AppStore";
import {StatisticData} from "../../../api/types";
import statisticApiTCare from "../../../api/StatisticApiTCare";
import StatisticApiTCare from "../../../api/StatisticApiTCare";
import {OrderStatusUtil} from "../ant-d-modules/tools/OrderStatusUtil";
import {Card, Col} from "antd";
import DonutPlotWithStatics from "../ant-d-modules/donut/DonutPlotWithStatics";
import { setOffGlobalSpinningOnHeader } from '../../../api/api';


interface Props {
  startYearMonthFilter: string | null;
  endYearMonthFilter: string | null;
  statisticCode: string
  attribute: string
  title: string
  innerDonutLabel?: string
  creationCompany?: string
}

interface State {
  data: any;
  isLoading: boolean
}


export default class DonutOrderData extends React.Component<Props, State> {
  state: State = {
    data: [],
    isLoading: true
  };

  async componentDidMount() {
    await this.refreshComponent();
  }

  async componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any) {
    if (prevProps.startYearMonthFilter !== this.props.startYearMonthFilter ||
        prevProps.endYearMonthFilter !== this.props.endYearMonthFilter ||
        prevProps.creationCompany !== this.props.creationCompany) {
      await this.refreshComponent();
    }
  }

  async refreshComponent() {
    if (!!AppStore.loggedUser?.company) {
      await this.loadData(AppStore.loggedUser?.company.id, false);
    } else {
      await this.loadData(null, true);
    }

  }

  async loadData(idCompany: string | null, isAdmin: boolean) {

    let data: [{
      type: string,
      value: number
    }?] = [];
    const statisticData: StatisticData =
        await statisticApiTCare.getStatisticDataByUrl(this.props.statisticCode + "?"
            + OrderStatusUtil.getCreationOrAssigneeCompanyFilter(isAdmin, idCompany)
            + StatisticApiTCare.getDataRangeFilter(this.props.startYearMonthFilter, this.props.endYearMonthFilter)
            + StatisticApiTCare.getCreationCompanyT1Filter(this.props.creationCompany)
            + '&groupBy=status&groupBy=' + this.props.attribute,
            setOffGlobalSpinningOnHeader())
    ;
    if (!!statisticData) {
      for (let key in statisticData) {
        data.push({
          type: statisticData[key].key[this.props.attribute] ?? "Empty",
          value: statisticData[key].key.status === 'REJECTED' ? - statisticData[key].value : statisticData[key].value
        });
      }
      // console.log('data: ', data)
      // console.log('statistic data: ', statisticData)
    }


    let res: any = {}

    data.forEach(d => {
      if(!res[d!.type]) {
        res[d!.type] = {
          type: d!.type,
          value: d!.value
        };
      } else {
        res[d!.type].value += d!.value
      }
    })

    this.setState({ data: Object.keys(res).map( key => res[key]), isLoading: false });
  }

  render() {
    return (
        <Col xl={8} sm={24} lg={12}>
          <Card
              title={this.props.title}
              bordered={true}
              style={{ width: "100%", height: 500 }}
          >
            <DonutPlotWithStatics data={this.state.data} title={this.state.isLoading ? 'Loading...' : this.props.innerDonutLabel && ''}/>
          </Card>
        </Col>
    );
  }
}




