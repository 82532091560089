import React from 'react'
import {SubOrder, SubOrderCardConfiguration} from '../../api/types'
import {Steps} from 'antd'
import AppStore from "../../AppStore";
import {buildDefaultSubOrderCardConfiguration} from "./OrderCard";

const {Step} = Steps

interface Props {
    subOrders: SubOrder[]
}

export default class OrderTraceReportSubOrderCardStepsBody extends React.Component<Props> {
    getStepClassName = (order: SubOrder): string => {
        switch (order.status) {
            case 'TO_BE_ASSIGNED':
                return 'order-trace-report-step-to_be_assigned'
            case 'ASSIGNED':
                return 'order-trace-report-step-assigned'
            case 'FULFILLED':
                return 'order-trace-report-step-fulfilled'
            case 'COMPLETED':
            case 'APPROVED':
                return 'order-trace-report-step-completed'
            default:
                return 'order-trace-report-step-draft'
        }
    }

    private getStepTitle = (order: SubOrder): string => {
        const values = this.retrieveSubOrderCardConfiguration(order.traceType.code)
            .filter(conf => !!conf.visibleIntoAssignedMode)
            .map(conf => this.retrieveValueElementFromOrder(order, conf))
        if (values.length > 0) {
            return values.reduce((previousValue, currentValue) => previousValue + "  " + currentValue)
        }

        return ''
    }

    private retrieveSubOrderCardConfiguration = (traceTypeCode: string): SubOrderCardConfiguration [] => {
        const traceReportFieldsType = 'traceReportFields-' + traceTypeCode
        return AppStore.appSettings[traceReportFieldsType] ?
            JSON.parse(AppStore.appSettings[traceReportFieldsType]) :
            AppStore.appSettings.traceReportFields ?
                JSON.parse(AppStore.appSettings.traceReportFields) :
                buildDefaultSubOrderCardConfiguration({})
    }

    private retrieveValueElementFromOrder = (order: SubOrder, conf: SubOrderCardConfiguration): string => {
        if (conf.type === "GENERIC") {
            let currentValue: any = order

            const fields = conf.field!.split('.')
            for (let field of fields) {
                currentValue = currentValue[field]
                if (currentValue === undefined || currentValue === null) {
                    return ''
                }
            }

            return currentValue
        }

        return ''
    }

    render = () => {
        const {subOrders} = this.props
        return (
            <Steps
                className={'order-trace-report-steps'}
                progressDot
                style={{marginBottom: 20, marginTop: 60, paddingRight: 40}}
                current={5}
            >
                <Step status={'process'} className={'order-trace-report-step-delimiter'}/>
                {subOrders.map((subOrder: SubOrder) => (
                    <Step status={'process'} title={this.getStepTitle(subOrder)}
                          className={this.getStepClassName(subOrder)}/>
                ))}
                <Step status={'process'} className={'order-trace-report-step-delimiter'}/>
            </Steps>
        )
    }
}
