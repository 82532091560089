import { showError, showNotification } from 'helpers/Notifications'
import { Certifications } from 'api'
import { category, CertificationFields, CompanyFields } from 'types'
import { __, T } from 'config/i18n'

export const uploadCertificates = async (companyId: string, company: CompanyFields) => {
  if (!company.certifications) return
  const certifications = company.certifications.filter(certification => !certification.fileId) ?? []
  for (const certification of certifications) {
    await uploadCertificate(companyId, certification)
  }
}

export const uploadCertificate = async (companyId: string, certification: CertificationFields) => {
  try {
    await Certifications.add({
      companyId: companyId,
      file: certification.file,
      expiration: certification.expiration,
      certificateType: certification.certificateType,
      certificateNumber: certification.certificateNumber,
      companyName: certification.companyName,
    })
    showNotification(__(T.messages.attachment_loaded) + certification.certificateNumber)
  } catch (error) {
    showError(__(T.errors.file_upload_failed))
  }
}

export const getCertificatesKeysByCategory = (category: category) => {
  switch (category) {
    case 'BREEDER':
      return [
        'ethicsCertificate',
        'membershipCertificate',
        'healthCertificate',
        'otherCertificates',
        'feedingDocuments',
      ]
    case 'HUNTER':
      return ['ethicsCertificate', 'licenseCertificate', 'otherCertificates']
    case 'TANNER':
      return ['ethicsCertificate', 'membershipCertificate', 'otherCertificates', 'tanneryCertifications']
    case 'SLAUGHTER':
      return ['ethicsCertificate', 'licenseCertificate', 'healthCertificate', 'otherCertificates']
    case 'PICKER':
      return ['ethicsCertificate', 'licenseCertificate', 'otherCertificates']
    case 'VET':
      return ['licenseCertificate', 'otherCertificates']
    default:
      return []
  }
}

export const maxFileSize = 5000000 // 5000000 bytes = 5 MB
