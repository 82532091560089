import React, { Component } from 'react'
import { InboxOutlined, UploadOutlined } from '@ant-design/icons'
import Text from 'antd/lib/typography/Text'
import Modal from 'antd/lib/modal/Modal'
import { __, T } from 'config/i18n'
import theme from 'theme/theme'
import { Button, Tooltip } from 'antd'
import moment from 'moment'

import AdvancedForm, { Schema } from './AdvancedForm'
import Dragger from 'antd/lib/upload/Dragger'
import { UploadProps } from 'antd/lib/upload'
import { UploadFile } from 'antd/lib/upload/interface'
import { showError, showNotification } from 'helpers/Notifications'
import { Certifications, Files } from 'api'
import { maxFileSize } from 'helpers/certifications'
import { CertificationFields } from '../types'

export interface FilePickerProps {
  certificateKey: string
  label?: string
  placeHolder?: string
  initialValue?: CertificationFields[]
  onFilesLoaded?: (key: string, files: CertificationFields[]) => void
  onDocumentRemoved?: (documentId: string) => void
  disabled?: boolean
  expirationIsOptional?: boolean
  style?: any
}

interface State {
  modalOpened: boolean
  workingDoc: CertificationFields
  documentsLoaded: CertificationFields[]
  fileList: UploadFile[]
}

export default class FilePicker extends Component<FilePickerProps, State> {
  constructor(props: FilePickerProps) {
    super(props)
    let documentsLoaded = props.initialValue ?? []

    this.state = {
      modalOpened: false,
      workingDoc: {},
      documentsLoaded,
      fileList: [],
    }
  }

  dowloadFile = async (certification: CertificationFields) => {
    if (!certification.fileId && !certification.id) {
      showError(__(T.errors.file_not_found))
      return
    }
    try {
      let certificateURL = ''
      if (certification.fileId) certificateURL = (await Files.getTemporaryUrl(certification.fileId)) as string
      else certificateURL = (await Certifications.getDownloadLink(certification.id)).data as string

      window.open(certificateURL)
    } catch (error) {}
  }

  renderFiles = () => {
    const { documentsLoaded } = this.state
    const { disabled } = this.props
    return documentsLoaded.map(document => (
      <>
        <li key={document.filename} style={document.fileId || document.id ? c.li : c.liGreen}>
          <div style={c.preview} />
          <Tooltip title={__(T.misc.click_to_download)}>
            <div
              style={{ flexDirection: 'column', flex: 1, cursor: 'pointer', width: '100%' }}
              onClick={() => {
                document.id && this.dowloadFile(document)
              }}
            >
              {document.expiration && (
                <div style={{ marginLeft: 8, flex: 1 }}>{`${__(T.misc.expiration)} ${moment(document.expiration).format(
                  'DD/MM/YYYY'
                )}`}</div>
              )}
              <div
                style={{
                  marginLeft: 8,
                  flex: 1,
                  color: '#BDBDBD',
                  flexWrap: 'wrap',
                  maxWidth: 200,
                  overflow: 'auto',
                }}
              >
                {this.truncText(document.filename ?? '')}
              </div>
            </div>
          </Tooltip>
          {!disabled && (
            <div
              onClick={() => this.removeDocument(document)}
              style={{ cursor: 'pointer', marginLeft: 8, fontSize: 20, marginRight: 6 }}
            >
              {'X'}
            </div>
          )}
        </li>
      </>
    ))
  }

  removeDocument = async (document: CertificationFields) => {
    const { onFilesLoaded, certificateKey, onDocumentRemoved } = this.props
    const { documentsLoaded } = this.state

    let docs: CertificationFields[] = documentsLoaded
    if (document.id) {
      try {
        await Certifications.delete(document.id)
        docs = this.state.documentsLoaded.filter(doc => doc.id !== document.id)
        onDocumentRemoved && onDocumentRemoved(document.id)
        showNotification(__(T.messages.certificate_deleted))
      } catch (e) {
        showError('[BE-ERROR] Cancellazione fallita')
      }
    } else {
      docs = this.state.documentsLoaded.filter(doc => doc.filename !== document.filename)
    }

    this.setState({ documentsLoaded: docs })
    onFilesLoaded && onFilesLoaded(certificateKey, docs)
  }

  // DO NOT DELETE THIS
  // Could be used to upload files encoded with BASE64
  arrayBufferToBase64 = (buffer: any) => {
    var binary = ''
    var bytes = new Uint8Array(buffer)
    var len = bytes.byteLength
    for (var i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i])
    }
    return window.btoa(binary)
  }

  handleOk = async () => {
    const { workingDoc, documentsLoaded } = this.state
    const { onFilesLoaded, certificateKey } = this.props
    if (workingDoc.file) {
      // workingDoc.filename = workingDoc.file.name
      onFilesLoaded && onFilesLoaded(certificateKey, documentsLoaded.concat(workingDoc))
      this.setState({
        documentsLoaded: documentsLoaded.concat(workingDoc),
        workingDoc: {},
        fileList: [],
        modalOpened: false,
      })
    } else {
      showError(__(T.errors.no_file_selected))
    }
  }

  handleCancel = () => {
    this.setState({
      workingDoc: {},
      fileList: [],
      modalOpened: false,
    })
    this.forceUpdate()
  }

  getFormSchema = (): Schema[] => {
    const { expirationIsOptional } = this.props
    const dateRequired = expirationIsOptional !== undefined ? !expirationIsOptional : true
    return [
      {
        name: 'documentNumber',
        label: __(T.fields.document_number),
        type: 'text',
        placeholder: __(T.misc.insert),
      },
      {
        name: 'expirationDate',
        label: __(T.fields.expiration_date),
        type: 'date',
        placeholder: __(T.misc.write),
        rules: [{ required: dateRequired, message: __(T.messages.field_required) }],
      },
      {
        name: 'description',
        label: __(T.fields.message),
        type: 'textarea',
        placeholder: __(T.misc.write),
      },
    ]
  }

  renderDocForm = () => {
    const { workingDoc } = this.state
    const __isNew = Object.keys(workingDoc).length === 0

    const empty = { message: '', expirationDate: null, name: '' }
    return (
      <AdvancedForm
        schema={this.getFormSchema()}
        values={__isNew ? empty : undefined}
        onValuesChange={(changed, values) => this.setState({ workingDoc: { ...values, ...workingDoc } })}
        submitButton={{
          text: __(T.buttons.registration_submit),
          col: 12,
          onPress: this.handleOk,
          style: theme.temeraPrimaryGreenButton,
        }}
        secondaryButton={{
          text: __(T.buttons.cancel),
          col: 12,
          onPress: this.handleCancel,
          style: theme.temeraSecondaryDarkButton,
        }}
      />
    )
  }

  truncText(text: string) {
    const LIMIT = 30

    if (text.length < LIMIT) return text
    return `${text.substr(0, LIMIT - 10)}...${text.substr(text.length - 10)}`
  }

  render() {
    const { label, placeHolder, disabled, style } = this.props
    const { documentsLoaded, modalOpened, fileList } = this.state

    const props: UploadProps = {
      multiple: false,
      beforeUpload: file => {
        if (file.size > maxFileSize) {
          showError(__(T.errors.max_file_size_exceeded))
          return false
        }

        this.setState({
          fileList: [{ ...file, name: this.truncText(file.name) }],
          workingDoc: { ...this.state.workingDoc, file },
        })

        return false
      },
      onRemove: file => {
        this.setState({ fileList: [] })
      },
    }

    const files = this.renderFiles()
    return (
      <div style={{ width: '100%', marginBottom: 30 }}>
        {label && (!disabled || documentsLoaded.length > 0) && (
          <Text style={{ lineHeight: 3, fontSize: 14 }}>{label}</Text>
        )}
        {!disabled && (
          <Button
            onClick={() => this.setState({ modalOpened: true })}
            style={c.openModal}
            size="large"
            icon={<UploadOutlined />}
          >
            {placeHolder}
          </Button>
        )}
        <section className="container" style={style}>
          {files.length > 0 && (
            <aside style={{ paddingBottom: 4 }}>
              <ul style={{ padding: 0 }}>{files}</ul>
            </aside>
          )}
        </section>
        <Modal visible={modalOpened} onOk={this.handleOk} onCancel={this.handleCancel} footer={null} width={800}>
          <h2>{__(T.titles.attach_document)}</h2>
          <p>{__(T.misc.attached_max_sixe)}</p>
          <div style={c.container}>
            <div style={c.dropzone}>
              <Dragger {...props} style={{ width: 400, flex: 1, padding: 10, marginBottom: -50 }} fileList={fileList}>
                <p className="ant-upload-drag-icon">
                  <InboxOutlined />
                </p>
                <p className="ant-upload-text">{__(T.fields.load_here)}</p>
                <p className="ant-upload-hint"></p>
              </Dragger>
            </div>
            <div style={{ flex: 3 }}>{this.renderDocForm()}</div>
          </div>
        </Modal>
      </div>
    )
  }
}

const c: Record<string, React.CSSProperties> = {
  container: {
    flexDirection: 'row',
    display: 'flex',
    flex: 1,
  },
  li: {
    listStyleType: 'none',
    listStylePosition: 'inside',
    padding: 8,
    border: '1px solid #D9D9D9',
    flexDirection: 'row',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'spaceBetween',
    boxSizing: 'border-box',
    borderRadius: 2,
    backgroundColor: '#fff',
    marginTop: 9,
  },
  liGreen: {
    listStyleType: 'none',
    listStylePosition: 'inside',
    padding: 8,
    border: '1px solid #ceff00',
    flexDirection: 'row',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'spaceBetween',
    boxSizing: 'border-box',
    borderRadius: 2,
    backgroundColor: '#fff',
    marginTop: 9,
  },
  preview: {
    width: 48,
    height: 48,
    background: '#E8E8E8',
  },
  openModal: {
    width: '100%',
    background: '#F2F2F2',
    borderColor: '#F2F2F2',
    borderRadius: 2,
    color: '#595959',
  },
  dropzone: {
    flex: 2,
    display: 'flex',
    marginRight: 30,
  },
}
