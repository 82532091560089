import {
    BulkApproveOrderDetailDto,
    Company,
    DetailedOrder,
    FulfilResult,
    ImportFromLibraryBulkCommand,
    ImportFromTraceBulkCommand,
    ImportOrderTraceExcelResult,
    Order,
    OrderAssignments, OrderCloneAssignment,
    OrderDraft,
    OrderFulfillment,
    Page,
    QueryParams,
    RetrieveDataSchemaCommand,
    RetrieveFirstTraceTypeCommand,
    SimpleQueryParams,
    SplitOrderCommand,
    TraceType
} from './types';
import {AxiosRequestConfig} from 'axios'
import api, {responseErrorCheck} from './api'
import OrderDetail from '../pages/orders/OrderDetail'
import {showError} from "../helpers/Notifications";
import {Companies} from "./index";

class Orders {
    public endpoint = 'tracemanager/v1/orders'
    public publicEndPoint = 'tracemanager/v1/public/orders'


    async get(id: string, axiosConfig?: AxiosRequestConfig) {
        const res = await api.get<DetailedOrder>(`${this.endpoint}/${id}`, {}, axiosConfig)
        return responseErrorCheck(res)
    }

    async getOrderDetails(ids: string[], axiosConfig?: AxiosRequestConfig) {
        const res = await api.get<DetailedOrder[]>(`${this.endpoint}/orderDetails`, {ids: ids}, axiosConfig);
        return responseErrorCheck(res);
    }


    async search(params: QueryParams, axiosConfig?: AxiosRequestConfig) {

        const res = await api.get<Page<any>>(`${this.endpoint}`, params, axiosConfig)
        return responseErrorCheck(res)
    }

    async deleteOrder(orderId: string, axiosConfig?: AxiosRequestConfig) {
        const res = await api.delete<Order>(`${this.endpoint}/${orderId}`, axiosConfig)
        return responseErrorCheck(res)
    }

    getEndpointSearchOrderApi() {
        return this.publicEndPoint
    }

    async count(params: SimpleQueryParams, axiosConfig?: AxiosRequestConfig) {

        const res = await api.get<Page<any>>(`${this.endpoint}/count`, params, axiosConfig)
        return responseErrorCheck(res)
    }

    async downloadXls(params: QueryParams, axiosConfig?: AxiosRequestConfig) {

        let axiosConfigMerged: AxiosRequestConfig = {
            responseType: 'blob',
            timeout: 200000,
            headers: {
                ...axiosConfig
            }
        }


        var filename
        await api.get(`${this.endpoint}/downloadXls`,
            params,
            axiosConfigMerged)
            .then(response => {
                const disposition = response.headers!['content-disposition'];
                filename = disposition.split('filename=')[1];
                return response.data;
            })
            .then(blob => {
                var url = window.URL.createObjectURL(blob);
                var a = document.createElement('a');
                a.href = url;
                a.download = filename;
                document.body.appendChild(a);
                a.click();
            })
            .catch(error => {
                console.error(error);
                showError("Error", error.message)
            });
    }

    async downloadTraceTemplate(params: QueryParams, axiosConfig?: AxiosRequestConfig) {

        let axiosConfigMerged: AxiosRequestConfig = {
            responseType: 'blob',
            timeout: 200000,
            headers: {
                ...axiosConfig
            }
        }

        await api.get(this.endpoint + '/downloadTraceTemplate', params, axiosConfigMerged)
            .then(response => {
                let filename;
                const disposition = response.headers!['content-disposition'];
                filename = disposition.split('filename=')[1];
                const a = document.createElement('a');
                a.href = window.URL.createObjectURL(response.data);
                a.download = filename;
                document.body.appendChild(a);
                a.click();
            })
            .catch(error => {
                // console.error(error);
                showError("Error", error.message)
            });
    }

    async uploadTraceData(traceDatafile: File, configurationFile: string, axiosConfig?: AxiosRequestConfig) {
        const formData = new FormData()
        traceDatafile && formData.append('file', traceDatafile)
        let axiosConfigMerged: AxiosRequestConfig = {
            headers: {
                'content-type': 'multipart/form-data',
                timeout: 200000,
                ...axiosConfig
            }
        }
        const res = await api.post<ImportOrderTraceExcelResult>(
          this.endpoint + '/importTraceData?configurationFile=' + configurationFile, formData, axiosConfigMerged)
        return responseErrorCheck(res)
    }

    async assign(id: string, orderAssignments: OrderAssignments, axiosConfig?: AxiosRequestConfig) {
        const res = await api.post<OrderDetail>(`${this.endpoint}/${id}/assign`, {
            ...orderAssignments,
            assignments: orderAssignments.assignments.map(({company, ...subOrderAssignment}) => ({
                    ...subOrderAssignment,
                    companyId: company?.id ?? undefined,
                }),
                axiosConfig),
        })
        return responseErrorCheck(res)
    }

    async cloneAssigned(id: string, assignment: OrderCloneAssignment, axiosConfig?: AxiosRequestConfig) {
        const res = await api.post<any>(`${this.endpoint}/${id}/cloneAssigned`, assignment, axiosConfig)
        responseErrorCheck(res)
    }

    async multiAssign(orderAssignments: OrderAssignments, axiosConfig?: AxiosRequestConfig) {
        const res = await api.post<OrderDetail>(`${this.endpoint}/multiAssign`, {
            ...orderAssignments,
            assignments: orderAssignments.assignments.map(({company, ...subOrderAssignment}) => ({
                ...subOrderAssignment,
                companyId: company?.id ?? undefined,
            }), axiosConfig),
        })
        return responseErrorCheck(res)
    }

    async fulfill(id: string, fulfillment: OrderFulfillment, axiosConfig?: AxiosRequestConfig) {
        const res = await api.post<DetailedOrder>(`${this.endpoint}/${id}/fulfill`, fulfillment, axiosConfig)
        return responseErrorCheck(res)
    }

    async fulfills(params: SimpleQueryParams, axiosConfig?: AxiosRequestConfig) {
        let axiosConfigMerged: AxiosRequestConfig = {
            params,
            headers: {
                ...axiosConfig
            }
        }
        const res = await api.post<FulfilResult>(`${this.endpoint}/fulfills/`, {}, axiosConfigMerged)
        return responseErrorCheck(res)
    }

    async draft(id: string, draft: OrderDraft, axiosConfig?: AxiosRequestConfig) {
        const res = await api.post<DetailedOrder>(`${this.endpoint}/${id}/draft`, draft, axiosConfig)
        return responseErrorCheck(res)
    }

    async complete(id: string, axiosConfig?: AxiosRequestConfig) {
        const res = await api.post<DetailedOrder>(`${this.endpoint}/${id}/complete`, {}, axiosConfig)
        return responseErrorCheck(res)
    }

    async reject(id: string, rejectedMessage?: string, rejectReasons?: string[], axiosConfig?: AxiosRequestConfig) {
        const res = await api.post<DetailedOrder>(`${this.endpoint}/${id}/reject`,
            {note: rejectedMessage, rejectReasons: rejectReasons}, axiosConfig)
        return responseErrorCheck(res)
    }

    async reassign(id: string, companyId: string, rejectedMessage?: string, rejectReasons?: string[], axiosConfig?: AxiosRequestConfig) {
        const res = await api.post<DetailedOrder>(`${this.endpoint}/${id}/reassign/${companyId}`, {}, axiosConfig)
        return responseErrorCheck(res)
    }

    async rejects(params: SimpleQueryParams, message?: string, axiosConfig?: AxiosRequestConfig) {
        let axiosConfigMerged: AxiosRequestConfig = {
            params,
            headers: {
                ...axiosConfig
            }
        }
        const res = await api.post<DetailedOrder[]>(`${this.endpoint}/rejects`, {message: message}, axiosConfigMerged)
        return responseErrorCheck(res)
    }

    async reopen(id: string, axiosConfig?: AxiosRequestConfig) {
        const res = await api.post<DetailedOrder>(`${this.endpoint}/${id}/reopen`, {}, axiosConfig)
        return responseErrorCheck(res)
    }

    async reopens(params: SimpleQueryParams, axiosConfig?: AxiosRequestConfig) {
        let axiosConfigMerged: AxiosRequestConfig = {
            params,
            headers: {
                ...axiosConfig
            }
        }
        const res = await api.post<DetailedOrder[]>(`${this.endpoint}/reopens`, {}, axiosConfigMerged)
        return responseErrorCheck(res)
    }


    async approve(id: string, axiosConfig?: AxiosRequestConfig) {
        const res = await api.post<DetailedOrder>(`${this.endpoint}/${id}/approve`, {}, axiosConfig)
        return responseErrorCheck(res)
    }


    async cancel(id: string, axiosConfig?: AxiosRequestConfig) {
        const res = await api.post<DetailedOrder>(`${this.endpoint}/${id}/cancel`, {}, axiosConfig)
        return responseErrorCheck(res)
    }

    async unlock(id: string, axiosConfig?: AxiosRequestConfig) {
        const res = await api.post<DetailedOrder>(`${this.endpoint}/${id}/unlock`, {}, axiosConfig)
        return responseErrorCheck(res)
    }

    async approveList(params: SimpleQueryParams, axiosConfig?: AxiosRequestConfig) {
        let axiosConfigMerged: AxiosRequestConfig = {
            params,
            headers: {
                ...axiosConfig
            }
        }

        const res = await api.post<BulkApproveOrderDetailDto>(`${this.endpoint}/approve`, {}, axiosConfigMerged)
        return responseErrorCheck(res)
    }

    async disapprove(id: string, axiosConfig?: AxiosRequestConfig) {
        const res = await api.post<DetailedOrder>(`${this.endpoint}/${id}/disapprove`, {}, axiosConfig)
        return responseErrorCheck(res)
    }

    async disapproveList(params: SimpleQueryParams, axiosConfig?: AxiosRequestConfig) {
        let axiosConfigMerged: AxiosRequestConfig = {
            params,
            headers: {
                ...axiosConfig
            }
        }
        const res = await api.post<DetailedOrder>(`${this.endpoint}/disapprove`, {}, axiosConfigMerged)
        return responseErrorCheck(res)
    }

    async retrieveTraceType(command: RetrieveFirstTraceTypeCommand, axiosConfig?: AxiosRequestConfig) {
        const res = await api.post<TraceType>(`${this.endpoint}/retrieveFirstTraceType`, command, axiosConfig)
        return responseErrorCheck(res)
    }

    async retrieveDataSchema(command: RetrieveDataSchemaCommand, axiosConfig?: AxiosRequestConfig) {
        const res = await api.post<any>(`${this.endpoint}/retrieveDataSchema`, command, axiosConfig)
        return responseErrorCheck(res)
    }

    async saveSplit(command: SplitOrderCommand, axiosConfig?: AxiosRequestConfig) {
        const res = await api.post<void>(`${this.endpoint}/split`, command, axiosConfig)
        return responseErrorCheck(res)
    }

    async importBulkFromTrace(command: ImportFromTraceBulkCommand, axiosConfig?: AxiosRequestConfig) {
        const res = await api.post<void>(`${this.endpoint}/importFromTraceBulk`, command, axiosConfig)
        return responseErrorCheck(res)
    }


    async importBulkFromLibrary(command: ImportFromLibraryBulkCommand, axiosConfig?: AxiosRequestConfig) {
        const res = await api.post<void>(`${this.endpoint}/importFromLibraryBulk`, command, axiosConfig)
        return responseErrorCheck(res)
    }

    getCompanies = async (orders: Page<Order>, axiosConfig?: AxiosRequestConfig): Promise<{
        [companyId: string]: string
    }> => {
        const companyIds = this.getCompanyIdsFilteringDuplicates(orders);
        return await Companies.search({
                page: 0,
                size: companyIds.length,
                ids: companyIds
            },
            axiosConfig)
            .then(companiesResponse => companiesResponse?.content ?? [])
            .then(companies =>
                companies.reduce((_companiesWithName, company) => {
                    _companiesWithName[company.id] = company.data.businessName;
                    return _companiesWithName;
                }, {})
            );
    };

    getCompaniesWithAdminCompanyCode = async (orders: Page<Order>, axiosConfig?: AxiosRequestConfig): Promise<Company[]> => {
        const companyIds = this.getCompanyIdsFilteringDuplicates(orders);
        return await Companies.search({
                page: 0,
                size: companyIds.length,
                ids: companyIds,
                checkAdminCompanyCodes: true
            },
            axiosConfig)
            .then(companiesResponse => companiesResponse?.content ?? []);
    };

    getCompanyIdsFilteringDuplicates = (orders: Page<Order>) => {
        const assigneeCompanyIds = this.getCompanyIds(orders, 'assigneeCompany');
        const creationCompanyIds = this.getCompanyIds(orders, 'creationCompany');
        return Array.from(new Set([...assigneeCompanyIds, ...creationCompanyIds]));
    };


    async updateAttributes(idOrder: string, attributes: { [key: string]: any }, axiosConfig?: AxiosRequestConfig) {
        const res = await api.post<void>(`${this.endpoint}/` + idOrder + '/attributes', attributes, axiosConfig)
        return responseErrorCheck(res)
    }

    getCompanyIds = (orders: Page<Order>, companyIdProperty: string) =>
        orders.content.map(order => order[companyIdProperty]).filter(companyId => companyId);
}

const orders = new Orders()
export {orders as default}
