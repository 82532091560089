import { Company } from 'api/types'
import { AxiosRequestConfig } from 'axios'
import api, { responseErrorCheck } from './api'

class Representatives {
  protected endpoint = 'profilemanager/v1/representatives'

  async update(id: string, company: Partial<Company>, axiosConfig?: AxiosRequestConfig) {
    const res = await api.put(`${this.endpoint}/${id}`, company, axiosConfig)
    return responseErrorCheck(res)
  }
}

const representatives = new Representatives()
export { representatives as default }
