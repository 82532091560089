import React, { Component } from "react";
import { Pie } from '@ant-design/charts';



interface Props {
    data: [{
        type: string
        value: number
    }?]
    title
}


export default class DonutPlotWithStatics extends Component<Props, {} > {

    demoGauge = () => {
        const data = this.props.data
        let config: any = {
            // color: ['#0066FF', '#0085FF', '#0096FF', '#0106FF'],
            appendPadding: 50,
            data,
            angleField: 'value',
            colorField: 'type',
            radius: 1,
            innerRadius: 0.9,
            label: {
                style: {
                    position: 'middle',
                    opacity: 0.6,
                    fontSize: 12,
                },
                rotate: true,
            },
            legend: {
                layout: 'horizontal',
                position: 'bottom',
                maxRow: 10
            },
            interactions: [
                {
                    type: 'element-selected',
                },
                {
                    type: 'element-active',
                },
            ],
            statistic: {
                title: {
                    customHtml: () => { return <b>{this.props.title}</b> }
                }
            },
            tooltip: {
                fields: [],
            }
        };
        return <Pie {...config} />;
    };

    render() {
        return (
            this.demoGauge()
        )
    }
}




