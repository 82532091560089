import {
    NotificationsAndCount,
    NotificationTCare,
    Page,
    QueryParams
} from './types';
import { AxiosRequestConfig } from 'axios';
import api, { responseErrorCheck } from './api';
import appStore from '../AppStore';



class Notifications {
    protected endpoint = 'notification/v1/notifications';

    async getNumberOfNotReadAndNews(timestamp: number | null, axiosConfig?: AxiosRequestConfig) {

        let timestampString = '';
        if (timestamp) {
            timestampString = timestamp + '';
        }
        const res = await api.get<NotificationsAndCount>(`${this.endpoint}/getNumberOfNotReadAndNews/3/${timestampString}`, {}, axiosConfig);
        return responseErrorCheck(res);
    }

    async find(parmas?: QueryParams, axiosConfig?: AxiosRequestConfig) {
        const res = await api.get<Page<NotificationTCare>>(`${this.endpoint}/`, {
            ...parmas,
            sort: 'creationDate,desc'
        }, axiosConfig);
        return responseErrorCheck(res);
    }

    async markAsRead(notification: NotificationTCare, axiosConfig?: AxiosRequestConfig) {
        const res = await api.post<NotificationTCare>(`${this.endpoint}/${notification.id}/markAsRead`, {}, axiosConfig);
        notification = responseErrorCheck(res);
        appStore.onReadMessageEvent(notification);
        return notification;
    }

    async markAsUnread(notification: NotificationTCare, axiosConfig?: AxiosRequestConfig) {
        const res = await api.post<NotificationTCare>(`${this.endpoint}/${notification.id}/markAsUnread`,{}, axiosConfig);
        notification = responseErrorCheck(res);
        appStore.onReadMessageEvent(notification);
        return notification;
    }

    async markAllAsRead(axiosConfig?: AxiosRequestConfig) {
        const res = await api.post(`${this.endpoint}/markAllAsRead`, {}, axiosConfig);
        const rescheck = responseErrorCheck(res);
        appStore.onReadMessageEvent();
        return rescheck;
    }

}

const notifications = new Notifications();
export { notifications as default };
