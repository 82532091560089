import React, {RefObject} from 'react'
import {UserFields} from '../../types'
import {HardCoded} from '../../api'
import {__, T} from '../../config/i18n'
import {admin, getUserRole} from '../../helpers/permission';
import {FormInstance} from 'antd/lib/form'
import AdvancedForm2 from "../../components/AdvancedForm2";
import {Schema} from "../../components/AdvancedForm";

export interface CreateOnboardingFormFields extends UserFields {
  businessName?: string
  companyCode?: string
  partitaIva?: string
  companyType?: 'supplier' | 'representative'
  categories?: string []
}

interface Props {
  formRef: RefObject<FormInstance>
  formFields: CreateOnboardingFormFields
  onValuesChange: (changedValues: Partial<CreateOnboardingFormFields>, values: CreateOnboardingFormFields) => void
}

export default class CreateOnboardingForm extends React.Component<Props> {
  getSchema = (): Schema[] => {
    const { formFields } = this.props

    function getRuleForCompanyCode() {
      return  getUserRole() === admin ?
          [{required: true, message: __(T.messages.field_required)}] : []
    }

    return [
      {
        name: 'name',
        type: 'text',
        label: __(T.fields.name),
        placeholder: __(T.misc.insert),
        col: { xs: 24, sm: { span: 12 } },
        rules: [{ required: true, message: __(T.messages.field_required) }]
      },
      {
        name: 'surname',
        type: 'text',
        label: __(T.fields.surname),
        placeholder: __(T.misc.insert),
        col: { xs: 24, sm: { span: 12, pull: 26, offset: 26 } },
        rules: [{ required: true, message: __(T.messages.field_required) }]
      },
      {
        name: 'phone',
        type: 'text',
        label: __(T.fields.phone),
        placeholder: __(T.misc.insert),
        col: { xs: 24, sm: { span: 12 } },
      },
      {
        name: 'email',
        type: 'text',
        label: __(T.fields.email),
        placeholder: __(T.misc.insert),
        defaultValue: formFields.email,
        col: { xs: 24, sm: { span: 12, pull: 26, offset: 26 } },
        rules: [{ required: true, message: __(T.messages.field_required) }]
      },
      {
        name: 'businessName',
        label: __(T.fields.business_name),
        type: 'text',
        placeholder: __(T.misc.insert),
        col: { xs: 24, sm: { span: 24, pull: 26, offset: 26 } },
        rules: [{ required: true, message: __(T.messages.field_required) }],
        defaultValue: formFields.businessName
      },
      {
        name: 'companyCode',
        label: __(T.fields.company_code),
        type: 'text',
        placeholder: __(T.misc.insert),
        col: { xs: 24, sm: { span: 24, pull: 26, offset: 26 } },
        rules: getRuleForCompanyCode(),
        defaultValue: formFields.companyCode
      },
      {
        name: 'partitaIva',
        label: __(T.fields.piva_cf),
        type: 'text',
        placeholder: __(T.misc.insert),
        col: { xs: 24, sm: { span: 24, pull: 26, offset: 26 } },
        rules: [{ required: true, message: __(T.messages.field_required) }],
        defaultValue: formFields.companyCode
      },
      {
        name: 'companyType',
        type: 'radio',
        label: __(T.fields.company_type),
        options: HardCoded.getCompanyTypes(),
        col: { xs: 24, sm: { span: 24, pull: 26, offset: 26 } },
        defaultValue: 'supplier', // If it is change, see export template onboarding and import template because can be hardwired in the code.
        hide: true // If it is change, see export template onboarding and import template because can be hardwired in the code.
      },
      {
        name: 'categories',
        label: __(T.fields.categories),
        type: 'select',
        placeholder: __(T.misc.select),
        options: HardCoded.getOnBoardingCategoryOptions(),
        initialValue: formFields.categories,
        col: { xs: 24, sm: { span: 24, pull: 26, offset: 26 } },
        rules: [
          form => ({
            required: form.getFieldValue('companyType') === 'supplier',
            message: __(T.messages.field_required)
          }),
        ],
        hide: formFields.companyType === 'representative',
        mode: 'tags'
      },
    ]
  }

  render = () => {
    return (
      <AdvancedForm2
        formRef={this.props.formRef}
        schema={this.getSchema()}
        values={this.props.formFields}
        onValuesChange={this.props.onValuesChange}
      />
    )
  }
}
