import { DetailedOrder, SubOrder } from '../api/types'

export const noComposant = '__NO_COMPOSANT__'

export const getSubOrdersByComponentArray = (order: DetailedOrder): Array<[string, SubOrder[]]> => {
  return Array.from(getSubOrdersByComponentMap(order))
}

export const getSubOrdersByComponentMap = (order: DetailedOrder): Map<string, SubOrder[]> => {
  return order.orders.reduce((subOrdersByComposant, subOrder) => {
    const composant = subOrder.materialGroup ?? noComposant
    subOrdersByComposant.set(composant, [...(subOrdersByComposant.get(composant) ?? []), subOrder])
    return subOrdersByComposant
  }, new Map<string, SubOrder[]>())
}
