import React, { Component } from 'react';
import { Modal, Button, Typography, Select, Spin } from 'antd';
import { __, T } from 'config/i18n';
import { View } from 'components';
import theme from 'theme/theme';
import TextArea from 'antd/lib/input/TextArea';
import enquiryRejectReasonsTools from '../../tools/EnquiryRejectReasonsSettingsTools';
import Orders from '../../api/Orders';
import { DetailedOrder } from '../../api/types';
import DOMPurify from 'dompurify';
import { setOffGlobalSpinningOnHeader } from '../../api/api';

interface Props {
  visible: boolean;
  title: string;
  subtitle?: string;
  confirm?: string;
  cancel?: string;
  hasNote?: boolean;
  order?: any;
  callbackRejectOrder: (order: DetailedOrder) => void;
  callbackCancelRejectOrder: () => void;
}

const { Title, Text } = Typography;

interface State {
  note?: string;
  rejects: string[];
  isSpinningRunning: boolean;
  isOnError: boolean;
}

export default class RejectModal extends Component<Props, State> {

  state: State = {
    rejects: [],
    isSpinningRunning: false,
    isOnError: false
  };

  componentWillUnmount(): void {
    this.cleanupModal();
  }

  // Aggiunta della funzione per pulire la modale
  private cleanupModal(): void {
    this.setState({ note: undefined, rejects: [], isSpinningRunning: false });
  }

  private callbackCancelRejectOrder() {
    this.cleanupModal();
    this.props.callbackCancelRejectOrder();
  }

  private async callbackRejectOrder() {
    this.setState({
      isSpinningRunning: true
    });
    const detailedOrder: DetailedOrder =
      await Orders.reject(this.props.order.id!, this.state.note, this.state.rejects, setOffGlobalSpinningOnHeader());
    this.cleanupModal();
    this.props.callbackRejectOrder(detailedOrder);
  }

  private getEnquiryRejectReasons(traceType: string): string[] | undefined {
    return enquiryRejectReasonsTools.getEnquiryRejectReasons(traceType);
  }

  private getReasonSelectionRender() {

    if (!!this.props.order && !!this.props.order.step) {
      let reasons = this.getEnquiryRejectReasons(this.props.order.step);

      if (!!reasons && reasons.length > 0) {
        return (
          <>
            <div style={{ marginTop: '10px', marginBottom: '10px' }}>
              <Text style={{ display: 'inline-block', width: '100%' }}>
                {__(T.misc.choose_rejects_reasons)}
              </Text>

              <Select
                style={{ width: '100%' }}
                mode={'multiple'}
                placeholder={__(T.misc.select)}
                allowClear
                value={this.state.rejects}
                onChange={value => {
                  if (value.length > 0) {
                    this.setState({
                      rejects: value,
                      isOnError: false
                    });
                  } else {
                    this.setState({
                      rejects: value
                    });
                  }
                }}
              >
                {reasons.map(opt => (
                  <Select.Option key={opt} value={opt}>
                    {__(opt)}
                  </Select.Option>
                ))}
              </Select>
              { this.state.isOnError &&
                <p style={{color: 'red'}}>
                  {__(T.errors.select_almost_one_reason)}
                </p>
              }
            </div>
          </>
        );
      }
    }
    return (<></>);

  }

  handleChange = (event): void => {
    this.setState({ note: event.target.value });
  };

  render() {
    const { title, visible, subtitle, hasNote } = this.props;
    let rejectReasons: string[] | undefined = undefined;
    if (!!this.props.order && !!this.props.order.step) {
      rejectReasons = this.getEnquiryRejectReasons(this.props.order.step)
    }
    return (
      <Modal centered visible={visible} closable={false} footer={null}
             onCancel={() => this.callbackCancelRejectOrder()}>
        <Title style={{ textAlign: 'center' }} level={4}>
          <div dangerouslySetInnerHTML={{ __html: __('modal-confirm-subtitle', DOMPurify.sanitize(title)) }} />
        </Title>
        <Spin spinning={this.state.isSpinningRunning} size="large" style={{ bottom: '1000px' }}>


          {this.getReasonSelectionRender()}

          {subtitle && subtitle !== '' && (
            <Text style={{ display: 'inline-block', width: '100%' }}>
              {subtitle}
            </Text>
          )}

          {hasNote && (
            <TextArea rows={4}
                      showCount
                      maxLength={400}
                      value={this.state.note}
                      onChange={this.handleChange}
            />
          )}
          <View center style={{ marginTop: 25 }}>
            <Button
              style={{ ...{ fontWeight: 700 }, ...theme.temeraSecondaryDarkButton }}
              type="default"
              onClick={() => this.callbackCancelRejectOrder()}
            >
              {this.props.cancel ?? __(T.buttons.cancel)}
            </Button>
            <Button
              style={{ ...{ fontWeight: 700, marginLeft: 25 }, ...theme.temeraPrimaryGreenButton }}
              type="default"
              onClick={async () => {
                if (this.state.rejects.length === 0 && !!rejectReasons && rejectReasons.length > 0) {
                  this.setState({
                    isOnError: true
                    });
                } else {
                  await this.callbackRejectOrder();
                }
              }}
            >
              {this.props.confirm ?? __(T.buttons.confirm)}
            </Button>
          </View>
        </Spin>
      </Modal>
    );
  }
}
