import React, { Component } from 'react';
import { Modal, Button, Typography, Input } from 'antd';
import { __, T } from 'config/i18n';
import { View } from 'components';
import theme from 'theme/theme';
import DOMPurify from 'dompurify';

interface Props {
  visible: boolean;
  title: string;
  subtitle?: string;
  confirm?: string;
  cancel?: string;
  defaultText?: string;
  placeholder?: string;
  callback: (confirm: boolean, fileName?: string) => void;
}

const { Title, Text } = Typography;

interface State {
  text?: string;
}

export default class ModalInsertText extends Component<Props, State> {

  state: State = {
    text: this.props.defaultText
  };

  handleChange = (event): void => {
    this.setState({ text: event.target.value });
  };

  render() {
    const { title, visible, subtitle, callback, placeholder } = this.props;
    return (
      <Modal centered visible={visible} closable={false} footer={null} onCancel={() => callback(false)}>
        <Title style={{ textAlign: 'center' }} level={4}>
          <div dangerouslySetInnerHTML={{ __html: __('modal-confirm-title', DOMPurify.sanitize(title)) }} />
        </Title>
        {subtitle && subtitle !== '' && (
          <Text style={{ display: 'inline-block', width: '100%' }}>
            {subtitle}
          </Text>
        )}
        <Input
          showCount
          placeholder={placeholder}
          maxLength={400}
          value={this.state.text}
          onChange={this.handleChange}
        />
        <View center style={{ marginTop: 25 }}>
          <Button
            style={{ ...{ fontWeight: 700 }, ...theme.temeraSecondaryDarkButton }}
            type="default"
            onClick={() => {
              callback(false);
              this.setState({text: this.props.defaultText});
            }}
          >
            {this.props.cancel ?? __(T.buttons.cancel)}
          </Button>
          <Button
            style={{ ...{ fontWeight: 700, marginLeft: 25 }, ...theme.temeraPrimaryGreenButton }}
            type="default"
            onClick={() => {
              callback(true, this.state.text);
              this.setState({text: this.props.defaultText});
            }}
          >
            {this.props.confirm ?? __(T.buttons.confirm)}
          </Button>
        </View>
      </Modal>
    );
  }
}
