import React, {RefObject} from 'react'
import {__, T} from '../../config/i18n'
import Orders from '../../api/Orders'
import {DetailedOrder} from '../../api/types'
import {showNotification} from '../../helpers/Notifications'
import {AppForm} from "../index";
import AppStore from "../../AppStore";
import ModalConfirm from "../ModalConfirm";

interface State {
  confirmModalVisible: boolean,
  // data?: any
}

interface Props  {
  order: DetailedOrder,
  onChange?: (data:DetailedOrder) => void,
  onFulfillmentSuccess: (order?: DetailedOrder) => void
  fulfillmentOrDraftFormSubmitButtonRef?: RefObject<HTMLButtonElement>
  readonly?: boolean
  thirdPart?: boolean
  draft?: boolean
}

export default class OrderFulfillmentForm extends React.Component<Props, State> {

  state: State = {
    confirmModalVisible: false,
  }

  componentDidMount() {
    this.loadData();
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any) {
    if (this.props.order.version !== prevProps.order.version) {
      this.loadData();
    }
  }

  loadData(){
    this.setState({})
  }

  getFulfillmentOrDraftFormSchema = () => {
    const fulfillmentOrDraftFormSchema = JSON.parse(this.props.order.traceType.schema)
    // if (fulfillmentFormSchema !== undefined) {
    //   fulfillmentFormSchema.title = undefined
    //   fulfillmentFormSchema.required = this.props.readonly ? [] : fulfillmentFormSchema.required
    // }
    return fulfillmentOrDraftFormSchema
  }

  handleFulfillmentError = () => {
    showNotification(__(T.titles.order_fulfillment_error), __(T.messages.order_fulfillment_error), 'error', 3)
  }

  handleFulfillmentOrDraftSubmit = (data) => {
    const { draft, order, onFulfillmentSuccess, thirdPart } = this.props

    data = {
        ...data,
        orderAttributes: undefined
    }

    if (!draft) {
      if(AppStore.appSettings.enableAutoComplete) {
        this.setState({confirmModalVisible: true})
      } else {
        Orders.fulfill(order.id!, {
          data: data,
          thirdPart
        }).then(onFulfillmentSuccess, this.handleFulfillmentError)
      }
    } else {
      Orders.draft(order.id!, {
        data: data,
        thirdPart
      }).then(onFulfillmentSuccess, this.handleFulfillmentError)
    }
  }
0
  realConfirm(result: boolean) {
    this.setState({confirmModalVisible: false}, () => {
      if(result){
        Orders.fulfill(this.props.order.id!, {
          data: this.props.order.data,
          thirdPart: this.props.thirdPart
        }).then(this.props.onFulfillmentSuccess, this.handleFulfillmentError)
      }
    })
  }

  onChange(data: any) {
    this.props.order.data = data;
  }

  // onChange(change: any){
  //   const { order } = this.props
  //
  //   console.log(change)
  //
  //   const schema = change.schema
  //
  //   for(let key of Object.keys(change.formData)) {
  //     order.data[key] = change.formData[key]
  //   }
  //
  //   for(let key of Object.keys(schema.properties)) {
  //     const item = schema.properties[key];
  //     if(item.type === 'string' && item.extra && item.extra.companyEnum) {
  //
  //       const value = change.formData ? change.formData[key] : null;
  //       if(value) {
  //         //TODO: recupero la company tramite il valore
  //         const company = {
  //           name: value,
  //           country: 'paese ' + value,
  //           cane:'gatto'
  //         }
  //
  //         item.extra.mapField
  //             // .filter(mapping => !order.data[mapping.target])
  //             .forEach(mapping => order.data[mapping.target] = company[mapping.source])
  //       }
  //     }
  //   }
  //   this.props.onChange(order)
  // }

  render = () => {


    const {order, fulfillmentOrDraftFormSubmitButtonRef, readonly, draft} = this.props;
    // order.data = this.state.data;

    return <>
      <AppForm
          schema={this.getFulfillmentOrDraftFormSchema()}
          data={{...order.data, orderAttributes: {...this.props.order.attributes}}}
          onSuccess={this.handleFulfillmentOrDraftSubmit}
          onChange={value => this.onChange(value)}
          readonly={readonly}
          noValidate={draft}
          formSubmitButtonRef={fulfillmentOrDraftFormSubmitButtonRef}
          ownerCompanyId={order.ownerCompany!}
          formContext={order}
      />
      <ModalConfirm
          visible={this.state.confirmModalVisible}
          title={__(T.fields.confirmModalTitle)}
          subtitle={__(T.fields.confirmModalBody)}
          confirm={__(T.fields.confirmModalButton)}
          cancel={__(T.fields.cancelModalButton)}
          callback={confirm => {
            this.realConfirm(confirm)
          }}
      />
    </>

  }
}
