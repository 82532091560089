import { Row, Col, Typography } from 'antd'
import { CloseCircleOutlined } from '@ant-design/icons'
import React, { Component } from 'react'

interface Props {
  close: () => void
  title: string
}

const { Title } = Typography

export default class ModalHeader extends Component<Props> {
  render() {
    const { close, title } = this.props
    return (
      <Row gutter={22} style={{ marginTop: 5 }}>
        <Col span={18}>
          <Title level={4} style={{ marginBottom: 0, fontWeight: 700 }}>
            {title}
          </Title>
        </Col>
        <Col span={6} style={{ justifyContent: 'flex-end', display: 'flex' }}>
          <CloseCircleOutlined style={{ fontSize: 22 }} onClick={() => close()} />
        </Col>
      </Row>
    )
  }
}
