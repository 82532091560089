import React from "react";
import AppStore from "../../../AppStore";
import StatisticApiTCare from "../../../api/StatisticApiTCare";
import genericTools from "../../../tools/GenericTools";
import GradientGaugePlotCard from "../cards/GradientGaugePlotCard";
import {isAdmin} from "../../../helpers/permission";
import { setOffGlobalSpinningOnHeader } from '../../../api/api';


interface Props {
  startYearMonthFilter: string|null
  endYearMonthFilter: string|null
}

interface State {
  total: number
  active: number
  isLoading: boolean
}


export default class ActiveT1Supplier extends React.Component<Props, State> {
  state: State = {
    total: 0,
    active: 0,
    isLoading: true
  }

  async componentDidMount() {
    await this.refreshComponent();
  }

  async componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any) {
    if (prevProps.startYearMonthFilter !== this.props.startYearMonthFilter
      || prevProps.endYearMonthFilter !== this.props.endYearMonthFilter) {
      await this.refreshComponent();
    }
  }

  async refreshComponent() {
    let url: string
    let onBoardingUrl: string
    if (!!AppStore.loggedUser?.company) {
      url = StatisticApiTCare.getSmartCount(
        StatisticApiTCare.GREATER,
        genericTools.getTodayDate('yyyy-mm', 6)!,
        StatisticApiTCare.ASSIGNEE_COMPANY,
        AppStore.loggedUser!.company.id)
      onBoardingUrl = AppStore.loggedUser!.company.id
    } else {
     url = StatisticApiTCare.getSmartCount(
       StatisticApiTCare.GREATER,
       genericTools.getTodayDate('yyyy-mm', 6)!,
       StatisticApiTCare.CREATION_COMPANY, 'null')
      onBoardingUrl = 'null'
    }

    let filter = StatisticApiTCare.getDataRangeFilter(this.props.startYearMonthFilter, this.props.endYearMonthFilter);

    this.setState({
      total: Number(await StatisticApiTCare.getNumberOfOnBoarding(onBoardingUrl, filter, isAdmin())),
      active: (await StatisticApiTCare.getStatisticDataByUrl(url + filter, setOffGlobalSpinningOnHeader()).then()) ?? 0,
      isLoading: false
    })
  }



  render() {
    return (
     <GradientGaugePlotCard
         active={this.state.active}
         total={this.state.total}
         forCent={this.state.total === 0 ? 0 : 100 * this.state.active / this.state.total}
         isLoading={this.state.isLoading} title={'T1 supplier (last 6 months)'}
         footerLabel={'T1 supplier are currently working'}/>
    )
  }
}




