import { Page, Trace, QueryParams } from "api/types";
import { AxiosRequestConfig } from 'axios'
import api, { responseErrorCheck } from './api'

class Traces<T = Trace > {
  protected endpoint = 'tracemanager/v1/traces'

  async findAll(axiosConfig?: AxiosRequestConfig) {
    const res = await api.get<Page<T>>(`${this.endpoint}`, axiosConfig)
    return responseErrorCheck(res)
  }

  async get(id: string, axiosConfig?: AxiosRequestConfig) {
    const res = await api.get<T>(`${this.endpoint}/${id}`, axiosConfig)
    return responseErrorCheck(res)
  }

  async search(params: QueryParams, axiosConfig?: AxiosRequestConfig) {
    const res = await api.get<Page<T>>(`${this.endpoint}`, params, axiosConfig)
    return responseErrorCheck(res)
  }

  async add(trace: Partial<Trace>, axiosConfig?: AxiosRequestConfig) {
    const res = await api.post<T>(`${this.endpoint}`, trace, axiosConfig)
    return responseErrorCheck(res)
  }

  async changeHideTraceFlag(idTrace: string, hideTrace: boolean, axiosConfig?: AxiosRequestConfig) {
    const res = await api.put<T>(this.endpoint + '/' + idTrace + '/hideShowTraceFlag', {
      hideTrace: hideTrace
    }, axiosConfig)
    return responseErrorCheck(res)
  }

}

const traces = new Traces()
export { traces as default }
