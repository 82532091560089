import React from "react";
import {Card, Col} from "antd";
import PiePlot from '../ant-d-modules/pie-plot/PiePlot';
import {Companies} from "../../../api";
import { setOffGlobalSpinningOnHeader } from '../../../api/api';


interface Props {
  startYearMonthFilter: string | null;
  endYearMonthFilter: string | null;
  categories: {
    category: string,
    label: string
  }[]
  title: string
  creationCompany?: string
}

interface State {
  data: any;
  isLoading: boolean
}


export default class CompanyCategoriesCounters extends React.Component<Props, State> {
  state: State = {
    data: [],
    isLoading: true
  };

  async componentDidMount() {
    await this.refreshComponent();
  }

  async componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any) {
    if (prevProps.startYearMonthFilter !== this.props.startYearMonthFilter ||
        prevProps.endYearMonthFilter !== this.props.endYearMonthFilter ||
        prevProps.creationCompany !== this.props.creationCompany) {
      await this.refreshComponent();
    }
  }

  async refreshComponent() {
    await this.loadData();
  }

  async loadData() {

    let params: any = {
      size: 1,
      page: 0,
      enabled: true
    }

    if(this.props.creationCompany) {
      params.onBoardingCreatorCompanyId = this.props.creationCompany
      params.withOnBoardingCreatorCompanyId = true
    }

    const data = await Promise.all(this.props.categories.map(async cat => {
      const res = await Companies.search({
        ...params,
        categories: cat.category
      }, setOffGlobalSpinningOnHeader())
      return {type: cat.label, value: res.totalElements}
    }))

    this.setState({ data: data, isLoading: false });
  }

  render() {
    return (
        <Col xl={8} sm={24} lg={12}>
          <Card
              title={this.props.title}
              bordered={true}
              style={{ width: "100%", height: 500 }}
          >
            <PiePlot data={this.state.data}/>
          </Card>
        </Col>
    );
  }
}




