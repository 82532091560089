import React, {CSSProperties, ReactNode, RefObject} from 'react';

import {
    Button,
    Checkbox,
    Col,
    DatePicker,
    Divider,
    Form,
    Input,
    InputNumber,
    Radio,
    Row,
    Select,
    Spin,
    Switch,
    Tooltip
} from 'antd';
import Text from "antd/lib/typography/Text";
import {QuestionCircleOutlined} from "@ant-design/icons";
import TextArea from "antd/lib/input/TextArea";
import {FormInstance} from "antd/es/form";
import AppStore from "../AppStore";
import api from "../api/api";
import {ColProps, Schema} from "./AdvancedForm";
import {__} from "../config/i18n";

const getFormItemProperties = (inputSchema: Schema) => {
    return {
        label: inputSchema.label!,
        name: inputSchema.name,
        rules: inputSchema.rules
    }
}

const getAutocompleteInput = (index: number, inputSchema: Schema, autocompleteFields: AutocompleteField[]): ReactNode => {
    let autoComplete = autocompleteFields.find(f => f.name === inputSchema.name);
    if (!autoComplete) {
        return <></>
    }
    const {fetching, fetch, onChange, data} = autoComplete
    const autocompleteInput = (
        <Form.Item key={index} {...getFormItemProperties(inputSchema)}>
            <Select
                labelInValue
                allowClear
                showSearch={true}
                disabled={inputSchema.disabled}
                mode={inputSchema.multipleAutocomplete ? 'multiple' : undefined}
                placeholder={inputSchema.placeholder}
                notFoundContent={fetching ? <Spin size="small"/> : null}
                filterOption={false}
                onSearch={fetch}
                onChange={onChange}
                style={{width: '100%'}}
                onFocus={_event => {
                    fetch('')
                }}
            >
                {data.map(dataItem => (
                    <Select.Option key={dataItem.value} value={dataItem.value}>
                        {dataItem.label}
                    </Select.Option>
                ))}
            </Select>
        </Form.Item>
    )
    const withAddButton = (autocomplete: JSX.Element) => (
        <Row gutter={5}>
            <Col span={22}>{autocomplete}</Col>
            <Col span={2} style={{paddingTop: '30px'}}>
                <Button type="primary" onClick={inputSchema.onButtonClick} disabled={inputSchema.disabled}>
                    {inputSchema.buttonLabel}
                </Button>
            </Col>
        </Row>
    )
    return inputSchema.withButton ? withAddButton(autocompleteInput) : autocompleteInput
}

const renderArray = (index: number, inputSchema: Schema, autocompleteFields: AutocompleteField[]): ReactNode => (
    <>
        <h3>{inputSchema.label}</h3>
        <Form.List key={index} name={inputSchema.name}>
            {fields => {
                return (
                    <Row gutter={24} style={{paddingLeft: '40px'}}>
                        {fields.map((field, index) => {
                            return <>{inputSchema.items?.map(schema => {
                                const span = typeof schema.col === 'number' ? schema.col : 24
                                const colProps: ColProps = schema.col && typeof schema.col === 'object' ? schema.col : {}
                                let name;
                                if(Array.isArray(schema.name)){
                                    name = [index, ...schema.name]
                                } else {
                                    name = [index, schema.name]
                                }
                                return (
                                    <Col key={index} span={span} {...colProps}>
                                        {schema.render && (
                                            <Form.Item key={index} {...schema}>
                                                {schema.render()}
                                            </Form.Item>
                                        )}
                                        {!schema.notInput && !schema.render && getInput(index, {...schema, name: name}, autocompleteFields)}
                                    </Col>
                                )
                            })}
                                <Divider></Divider>
                            </>
                        })}
                    </Row>
                )
            }}
        </Form.List>
    </>
)

const getButtonInput = (index: number, inputSchema: Schema): ReactNode => (
    <Form.Item key={index} {...getFormItemProperties(inputSchema)}>
        <Button type={inputSchema.buttonType} onClick={inputSchema.onClick} disabled={inputSchema.disabled}>
            {inputSchema.placeholder}
        </Button>
    </Form.Item>
)

const getCheckboxInput = (index: number, inputSchema: Schema): ReactNode => (
    <Form.Item key={index} {...getFormItemProperties(inputSchema)} valuePropName="checked">
        <Checkbox disabled={inputSchema.disabled} defaultChecked={inputSchema.defaultValue}>
            {inputSchema.placeholder}
        </Checkbox>
    </Form.Item>
)

const getDateInput = (index: number, inputSchema: Schema): ReactNode => (
    <Form.Item key={index} {...getFormItemProperties(inputSchema)}>
        <DatePicker format="DD.MM.YYYY" style={{width: '100%'}} placeholder={inputSchema.placeholder} disabled={inputSchema.disabled}/>
    </Form.Item>
)

const getDateRangeInput = (index: number, inputSchema: Schema): ReactNode => (
    <Form.Item key={index} {...getFormItemProperties(inputSchema)}>
        <DatePicker.RangePicker
            format={["YYYY-MM-DD"]}
            style={{width: '100%'}}
            placeholder={inputSchema.dateRangePlaceholders}
            disabled={inputSchema.disabled}
        />
    </Form.Item>
)

const getDateTimeInput = (index: number, inputSchema: Schema): ReactNode => (
    <Form.Item key={index} {...getFormItemProperties(inputSchema)}>
        <DatePicker
            format="DD.MM.YYYY HH:mm"
            // showTime={{ defaultValue: moment('00:00:00', 'HH:mm:ss') }}
            disabled={inputSchema.disabled}
            style={{width: '100%'}}
            placeholder={inputSchema.placeholder}
        />
    </Form.Item>
)

// const getDefaultInput = (index: number, inputSchema: Schema): ReactNode => (
//     <Form.Item key={index} {...getFormItemProperties(inputSchema)}>
//         <Input placeholder={inputSchema.placeholder || inputSchema.label} defaultValue={inputSchema.defaultValue} />
//     </Form.Item>
// )

const getUnmanagedInput = (index: number, inputSchema: Schema): ReactNode => (
    <label style={{color: 'red'}}>Type <b>{inputSchema.type}</b> not managed</label>
)

/*
const getDisabledInput = (index: number, inputSchema: Schema): ReactNode => (
    <Form.Item key={index} {...getFormItemProperties(inputSchema)}>
        {inputSchema.type === 'password' ? <Input.Password disabled/> : <Input disabled/>}
    </Form.Item>
)*/

const getIntegerInput = (index: number, inputSchema: Schema): ReactNode => (
    <Form.Item key={index} {...getFormItemProperties(inputSchema)}>
        <InputNumber
            defaultValue={inputSchema.defaultValue || 0}
            placeholder={inputSchema.placeholder}
            style={{width: '100%'}}
            disabled={inputSchema.disabled}
        />
    </Form.Item>
)

const getPasswordInput = (index: number, inputSchema: Schema): ReactNode => (
    <Form.Item key={index} {...getFormItemProperties(inputSchema)}>
        <Input.Password placeholder={inputSchema.placeholder} defaultValue={inputSchema.defaultValue} disabled={inputSchema.disabled}/>
    </Form.Item>
)

const getRadioInput = (index: number, inputSchema: Schema): ReactNode => (
    <Form.Item key={index} {...getFormItemProperties(inputSchema)}>
        <Radio.Group defaultValue={inputSchema.defaultValue} disabled={inputSchema.disabled}>
            {inputSchema.options?.map((option, i) => {
                return (
                    <Radio key={'option-' + i} value={option.value}>
                        {option.label}
                        {option.tooltip && (
                            <Tooltip title={option.tooltip}>
                                <QuestionCircleOutlined style={{marginLeft: 5}}/>
                            </Tooltip>
                        )}
                    </Radio>
                )
            })}
        </Radio.Group>
    </Form.Item>
)

const getSelectInput = (index: number, inputSchema: Schema): ReactNode => (
    <Form.Item key={index} {...getFormItemProperties(inputSchema)}>
        <Select
            placeholder={inputSchema.placeholder}
                allowClear
                showSearch={inputSchema.showSearch}
                mode={inputSchema.mode || undefined}
                maxTagCount={inputSchema.maxTagCount || undefined}
                disabled={inputSchema.disabled}
                filterOption={(input, option) => {
                    // if (option && option.children) return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    // return false
                    return true;
                }}
            >
                {inputSchema.options &&
                    inputSchema.options.map((opt, i) => (
                        <Select.Option key={'option-select-' + i} value={opt.value}>
                            {__(opt.label)}
                        </Select.Option>
                    ))}
        </Select>
    </Form.Item>
)

const getSwitchInput = (index: number, inputSchema: Schema): ReactNode => (
    <Form.Item key={index} {...getFormItemProperties(inputSchema)}>
        <Switch
            // checked={values && values[Array.isArray(inputSchema.name) ? 'attributes-' + inputSchema.name[inputSchema.name.length - 1] : inputSchema.name]}
            defaultChecked={inputSchema.defaultValue}
            disabled={inputSchema.disabled}
            style={inputSchema.style}
        />
    </Form.Item>
)

const getTextInput = (index: number, inputSchema: Schema): ReactNode => {
    return <Form.Item key={index} {...getFormItemProperties(inputSchema)}>
        <Input
            placeholder={inputSchema.placeholder || inputSchema.label}
            value={inputSchema.initialValue}
            disabled={inputSchema.disabled}
            onBlur={inputSchema.onInputBlur}
            addonBefore={inputSchema.inputAddonBefore}
        />
    </Form.Item>
}

const getTextAreaInput = (index: number, inputSchema: Schema): ReactNode => (
    <Form.Item key={index} {...getFormItemProperties(inputSchema)}>
        <TextArea rows={4} disabled={inputSchema.disabled} placeholder={inputSchema.placeholder}/>
    </Form.Item>
)

const getInput = (index: number, inputSchema: Schema, autocompleteFields: AutocompleteField[]) => {
    // if (inputSchema.disabled) {
    //     if (inputSchema.type === 'array') {
    //         return renderArray(index, inputSchema, autocompleteFields)
    //     }
    //     return getDisabledInput(index, inputSchema)
    // }
    switch (inputSchema.type) {
        case 'array':
            //TODO: test
            return renderArray(index, inputSchema, autocompleteFields)
        case 'button':
            return getButtonInput(index, inputSchema)
        case 'text':
            return getTextInput(index, inputSchema)
        case 'password':
            return getPasswordInput(index, inputSchema)
        case 'checkbox':
            return getCheckboxInput(index, inputSchema)
        case 'number':
            if (inputSchema.disabled) {
                return <Text children={inputSchema.defaultValue}/>
            } else {
                return getIntegerInput(index, inputSchema)
            }
        case 'integer':
            return getIntegerInput(index, inputSchema)
        case 'textarea':
            return getTextAreaInput(index, inputSchema)
        case 'date':
            return getDateInput(index, inputSchema)
        case 'datetime':
        case 'date-time':
            return getDateTimeInput(index, inputSchema)
        case 'daterange':
            return getDateRangeInput(index, inputSchema)
        case 'select':
            return getSelectInput(index, inputSchema)
        case 'radio':
            return getRadioInput(index, inputSchema)
        case 'autocomplete':
        case 'autocomplete_company':
            return getAutocompleteInput(index, inputSchema, autocompleteFields)
        case 'switch':
            return getSwitchInput(index, inputSchema)
        default:
            return getUnmanagedInput(index, inputSchema)
    }
}

type AutocompleteField = {
    name: string | string[],
    data: {
        value: string,
        label: string
    }[],
    onChange: (x: any) => void
    fetch: (x: any) => Promise<any[]>
    fetching: boolean
}

interface State {
    autocompleteFields: AutocompleteField[]
}

interface Props {
    values: any,
    schema: Schema[]
    onValuesChange: (changedValues: any, values: any) => void
    style?: CSSProperties
    title?: string
    formRef?: RefObject<FormInstance>
}

export default class AdvancedForm2 extends React.Component<Props, State> {

    private INITIAL_STATE: State = {
        autocompleteFields: []
    }

    state: State = {...this.INITIAL_STATE}
    private formRef: React.RefObject<FormInstance>;

    constructor(props: Props) {
        super(props)
        this.formRef = this.props.formRef ?? React.createRef<FormInstance>()
    }

    componentDidMount = async () => {
        this.formRef.current!.setFieldsValue(this.props.values)

        let autocompleteFields: AutocompleteField[] = this.props.schema
            .filter(f => {
                return (f.type === 'autocomplete' || f.type === 'autocomplete_company')
            })
            .map(field => {
                return {
                    name: field.name,
                    fetching: false,
                    fetch: async (x: any) => {
                        var anyField: any = field
                        let urlAutoCompleteCompany = '';
                        if (field.type === 'autocomplete_company') {
                            urlAutoCompleteCompany = '&withOnBoardingCreatorCompanyId=true'
                            if (AppStore.loggedUser?.company?.id) {
                                urlAutoCompleteCompany += '&onBoardingCreatorCompanyId=' + (AppStore.loggedUser?.company?.id ?? '');
                            }
                        }
                        let data = await api.get<any>(field.urlSearch!.replace('-X-', x) + urlAutoCompleteCompany
                        , {}, field.header ?? undefined);
                        const autoCompleteElement = this.state.autocompleteFields.find(f => f.name === field.name)

                        let newData: any[] = anyField.additionalOptions ? [...anyField.additionalOptions] : []
                        // if (data.data === null) {
                        //     autoCompleteElement!.data = newData as any[]
                        //     this.setState({autocompleteFields: this.state.autocompleteFields})
                        //     return newData as any[]
                        // }
                        if (data.status === 200 && Array.isArray(data.data.content)) {
                            newData.push(...data.data?.content)
                            autoCompleteElement!.data = newData
                            this.setState({autocompleteFields: this.state.autocompleteFields})
                            return newData || ([] as any[])
                        } else if (data.status === 200 && Array.isArray(data.data)) {
                            newData.push(...data.data)
                            autoCompleteElement!.data = newData
                            this.setState({autocompleteFields: this.state.autocompleteFields})
                            return newData || ([] as any[])
                        }
                        autoCompleteElement!.data = newData as any[]
                        this.setState({autocompleteFields: this.state.autocompleteFields})
                        return newData as any[]
                    },
                    onChange: (x: any) => {
                        const autoCompleteElement = this.state.autocompleteFields.find(f => f.name === field.name)
                        autoCompleteElement!.data = []
                        this.setState({autocompleteFields: this.state.autocompleteFields})
                    },
                    data: [] as any[],
                }
            })

        this.setState({autocompleteFields: autocompleteFields})
    };

    componentDidUpdate = async (prevProps: Readonly<Props>) => {
        this.formRef.current!.setFieldsValue(this.props.values)
    }

    render = () => {
        const {style, title, schema, onValuesChange} = this.props
        const {autocompleteFields} = this.state

        return (
            <Form
                ref={this.formRef}
                form={this.formRef.current ?? undefined}
                layout="vertical"
                onValuesChange={onValuesChange}
                style={style}
            >
                {title && <h2>{title}</h2>}
                <Row gutter={24}>
                    {schema
                        .filter(s => !s.hide)
                        .map((schema, index) => {
                            const span = typeof schema.col === 'number' ? schema.col : 24
                            const colProps: ColProps = schema.col && typeof schema.col === 'object' ? schema.col : {}
                            return (
                                <Col key={index} span={span} {...colProps}>
                                    {schema.render && (
                                        <Form.Item key={index} {...schema}>
                                            {schema.render()}
                                        </Form.Item>
                                    )}
                                    {!schema.notInput && !schema.render && getInput(index, schema, autocompleteFields)}
                                </Col>
                            )
                        })}
                </Row>
            </Form>
        )
    };

}
