import { Card, Col, Row, Spin } from 'antd';
import React, { Component } from 'react';
import AppStore from '../../../AppStore';
import StatisticApiTCare from '../../../api/StatisticApiTCare';
import {OrderStatusUtil, StatusesComputation} from "../ant-d-modules/tools/OrderStatusUtil";
import {isAdmin} from "../../../helpers/permission";
import { setOffGlobalSpinningOnHeader } from '../../../api/api';

interface Props {}

interface State {
    referencesImported: number;
    referencesImportedLoading: boolean;
    completedComputation: number;
    rating: number;
    ratingLoading: boolean;
}


export default class AnalyticsSummariesSupplierManagement extends Component<Props, State> {
    state: State = {
        referencesImported: 0,
        referencesImportedLoading: true,
        completedComputation: 0,
        rating: 0,
        ratingLoading: true

    };

    async componentDidMount() {
        this.refreshOrder().then(_ => console.log('refresh order stop'));
        this.refreshRating().then(_ => console.log('refresh rating stop'));
    }


    async refreshOrder() {
        let orderData: StatusesComputation;
        if (!isAdmin()) {
            orderData = await OrderStatusUtil.getAllByComputationByIdCompany(
                AppStore.loggedUser!.company?.id,
                false,
                null,
                null);

        } else {
            orderData = await OrderStatusUtil.getAllByComputationByIdCompany(
                'null',
                true,
                null,
                null);
        }
        this.setState({
            completedComputation: orderData.completedComputation + orderData.approvedComputation,
            referencesImported: OrderStatusUtil.getTotalByComputation(orderData),
            referencesImportedLoading: false,
        });
    }

    async refreshRating() {

        let rating = 0;
        if (!isAdmin()) {
            const ratingTmp = await StatisticApiTCare.getStatisticDataByUrl(
                'average_rating_order?groupBy=&creationCompany='
                + AppStore.loggedUser!.company?.id,
                setOffGlobalSpinningOnHeader());
            if (!!ratingTmp && !!ratingTmp[0]) {
                rating = ratingTmp[0].value.value;
            }

        } else {
            const ratingTmp = (await StatisticApiTCare.getStatisticDataByUrl(
                'average_rating_order?groupBy=',
                setOffGlobalSpinningOnHeader()));
            if (!!ratingTmp && !!ratingTmp[0]) {
                rating = ratingTmp[0].value.value;
            }
        }
        this.setState({
            rating: parseInt(rating + ''),
            ratingLoading: false
        });
    }


    render() {
        return (
            <>
                <Card style={{ marginBottom: 10 }}>
                    <Row gutter={[10, 20]}>
                        <Col xl={4} style={{ minWidth: 300 }}>
                            <Spin spinning={this.state.ratingLoading} size="large">
                                <Row>
                                    <Col style={{ minWidth: 100 }}>
                                        <img
                                            alt="example"
                                            style={{ height: 75, justifyContent: 'center', display: 'flex' }}
                                            src={require('./../../../assets/images/T1-supplier-onboarded.svg').default}
                                        />
                                    </Col>
                                    <Col style={{ minWidth: 10 }}>
                                        <Row>
                                            <Col span={24}>
                                                <div style={{ fontSize: '2em' }}>{this.state.rating}%</div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col span={24}>
                                                Supplier rating trace
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Spin>
                        </Col>
                        <Col xl={4} style={{ minWidth: 300 }}>
                            <Spin spinning={this.state.referencesImportedLoading} size="large">
                                <Row>
                                    <Col style={{ minWidth: 100 }}>
                                        <img
                                            alt="example"
                                            style={{ height: 75, justifyContent: 'center', display: 'flex' }}
                                            src={require('./../../../assets/images/references-imported.svg').default}
                                        />
                                    </Col>
                                    <Col style={{ minWidth: 10 }}>
                                        <Row>
                                            <Col span={24}>
                                                <div
                                                    style={{ fontSize: '2em' }}>{parseInt(((this.state.completedComputation / this.state.referencesImported) * 100) + '')}%
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col span={24}>
                                                Trace completed
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Spin>
                        </Col>
                    </Row>
                </Card>
            </>
        );
    }
}


/*
Dividendo per mille i millesecondi ottieni i secondi totali, chiamiamo st il numero di secondi totali.
La parte intera di st/3600 ti dà il numero di ore, chiamiamola h;
il numero di minuti è dato dalla parte intera di (st/3600−h)⋅60, chiamiamola m;
il numero di secondi è dato dalla parte intera di (((st/3600−h)⋅60)−m)⋅60, chiamiamola s.
La parte decimale di s è il resto.

Esempio: 137982282 millisecondi.
Secondi totali = 1379822821000=137982.282
Ore = parte intera di 137982.2823600=38
Minuti = parte intera di (parte decimale del risultato di prima⋅60)=19
Secondi = parte decimale del risultato di prima⋅60=42,282
Risultato:  137982282=38 ore, 19 minuti, 42 secondi, 282 millisecondi.
 */




