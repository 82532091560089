import React, { Component } from 'react'
import { View, RuleElement, DetailRuleModal } from 'components'
import { Rule } from 'api/types'

interface Props {
  rules: Rule[]
  archived?: (index: number) => void
  reopenRule?: (index: number) => void
  deleteRule?: (id: string) => void
}

interface State {
  modalVisible: boolean
  selectedRule: Rule | undefined
}

export default class RulesList extends Component<Props> {
  state: State = {
    modalVisible: false,
    selectedRule: undefined,
  }

  openViewDetail = (rule: Rule) => {
    this.setState({ modalVisible: true, selectedRule: rule })
  }

  closeViewDetail = () => {
    this.setState({ modalVisible: false })
  }

  render = () => {
    const { rules, archived, deleteRule, reopenRule } = this.props
    const { modalVisible, selectedRule } = this.state
    return (
      <>
        <View style={{ marginLeft: 20, marginBottom: 30 }}>
          {rules.map((rule, i) => (
            <RuleElement
              key={'rules_' + rule.id}
              rule={rule}
              index={i}
              view={this.openViewDetail}
              archived={archived}
              deleteRule={deleteRule}
              reopenRule={reopenRule}
            />
          ))}
        </View>
        {selectedRule && <DetailRuleModal rule={selectedRule} closed={this.closeViewDetail} visible={modalVisible} />}
      </>
    )
  }
}
