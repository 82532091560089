import React from 'react'
import { Divider, PageHeader, Space } from 'antd'
import { Text } from '../index'
import { orderStatuses } from '../../api/types'
import OrderStatusTag from './OrderStatusTag'

interface Props {
  nrOrders: number
  extras?: JSX.Element[]
  onBack?: (e: React.MouseEvent<HTMLDivElement>) => void
}

export default class OrderMultiPageHeader extends React.Component<Props> {
  render = () => {
    const { nrOrders, extras, onBack } = this.props
    return (
      <PageHeader
        style={{ backgroundColor: 'white' }}
        title={
          <Space size="middle">
            <Text black>{nrOrders} PO selected</Text>
            <Divider type="vertical" style={{ margin: 0 }} />
            {/*<Text black>{order.orderDate && moment(order.orderDate).format('DD/MM/YYYY')}</Text>*/}
            <Divider type="vertical" style={{ margin: 0 }} />
            <OrderStatusTag status={orderStatuses[1]} style={{ display: 'flex' }} />
          </Space>
        }
        extra={extras}
        onBack={() => onBack}
      />
    )
  }
}
