import { DatePicker, Row, Select, Tabs } from 'antd';
import React, { Component } from 'react';
import Layout from '../../components/layouts/Layout';
import StatusOfTheReferences from '../../components/analytics/middleware/StatusOfTheReferences';
import ActiveT1Supplier from '../../components/analytics/middleware/ActiveT1Supplier';
import SpecificFiberTracked from '../../components/analytics/middleware/SpecificFiberTracked';
import TraceRating from '../../components/analytics/middleware/TraceRating';
import moment, { Moment } from 'moment';
import { View } from '../../components';
import VerticalBarsOrderData from '../../components/analytics/middleware/VerticalBarsOrderData';
import AppStore from '../../AppStore';
import StatusOfTheReferencesForSuppliers
  from '../../components/analytics/middleware/StatusOfTheReferencesForSuppliers';
import Companies from '../../api/Companies';
import { Company, Page } from '../../api/types';
import AnalyticsSummariesGeneral from 'components/analytics/summaries/AnalyticsSummariesGeneral';
import AnalyticsSummariesSupplierManagement
  from '../../components/analytics/summaries/AnalyticsSummariesSupplierManagement';
import PieOrderData from '../../components/analytics/middleware/PieOrderData';
import CompanyCategoriesCounters
  from '../../components/analytics/middleware/CompanyCategoriesCounters';
import { HardCoded } from '../../api';
import { describeCategory } from '../../helpers/describer';
import MultiStatPieOrderData from 'components/analytics/middleware/MultiStatPieOrderData';
import { isAdmin } from '../../helpers/permission';
import { setOffGlobalSpinningOnHeader } from '../../api/api';


const { RangePicker } = DatePicker;

interface State {
  startYearMonthFilter: string | null,
  endYearMonthFilter: string | null,
  creationCompany?: string,
  data: {
    value: string,
    label: string
  }[]
}


export default class Analytics extends Component<{}, State> {
  state: State = {
    startYearMonthFilter: null,
    endYearMonthFilter: null,
    data: []
  };


  componentDidMount() {
    this.handleSearch('');
  }

  shoot = () => {
    this.setState({});
  };

  private setDefaultValueDataPicker(): [Moment, Moment] | null {
    if (!!this.state.startYearMonthFilter && !!this.state.endYearMonthFilter) {
      return [moment(this.state.startYearMonthFilter, 'YYYY-MM-DD'),
        moment(this.state.endYearMonthFilter, 'YYYY-MM-DD')];
    }
    return null;
  }


  handleSearch = async (newValue: string) => {
    let params: any = {
      withOnBoardingCreatorCompanyId: true,
      enabled: true,
      sort: 'creationDate,desc',
      fullText: newValue,
      size: 1000
    };
    let company: Page<Company> = await Companies.search(params, setOffGlobalSpinningOnHeader());


    this.setState({
      data: company.content.sort((c1, c2) => {
        if (c1.data.businessName > c2.data.businessName) {
          return 1;
        }
        if (c1.data.businessName < c2.data.businessName) {
          return -1;
        }
        return 0;
      }).map(company => {
        return {
          label: company.data.businessName,
          value: company.id
        };
      }),
      creationCompany: (company.content.length > 0) ? company.content[0].id : this.state.creationCompany
    });

  };

  render() {
    const handleChange = (newValue: string) => {
      this.setState({
        creationCompany: newValue
      });
    };

    const onChange = (key: string) => {
      //console.log(key);
    };
    // It is mandatory, a function doesn't work.
    const onChangeDataPicker = (date: any, dateString: string[]) => {
      this.setState({
        startYearMonthFilter: dateString[0],
        endYearMonthFilter: dateString[1]
      });
    };

    const categoriesAnalytics = (AppStore.loggedUser?.tenant?.code !== 'gucci' && AppStore.loggedUser?.tenant?.code !== 'gucci-dev' ? HardCoded.getCategoryKeys() : ['FARMER', 'SLAUGHTER'])
      .map(c => {
        return {
          category: c,
          label: describeCategory(c)
        };
      });

    return (
      <Layout>
        <div style={{ padding: '20px' }}>
          <Tabs defaultActiveKey="1" onChange={onChange}>
            <Tabs.TabPane tab="General Overview" key="1">
              <AnalyticsSummariesGeneral />
              <View style={{ marginBottom: 10 }}>
                <RangePicker onChange={onChangeDataPicker}
                             value={this.setDefaultValueDataPicker()}
                             picker="month" />
              </View>
              <Row gutter={[10, 20]}>
                <StatusOfTheReferences
                  startYearMonthFilter={this.state.startYearMonthFilter}
                  endYearMonthFilter={this.state.endYearMonthFilter}
                  key={'analytics1'} />

                <CompanyCategoriesCounters
                  startYearMonthFilter={this.state.startYearMonthFilter}
                  endYearMonthFilter={this.state.endYearMonthFilter}
                  categories={categoriesAnalytics}
                  title={'Declared sub-suppliers'}
                />

                {(AppStore.loggedUser?.tenant?.code === 'gucci-dev' || AppStore.loggedUser?.tenant?.code === 'gucci') &&
                  <MultiStatPieOrderData
                    startYearMonthFilter={this.state.startYearMonthFilter}
                    endYearMonthFilter={this.state.endYearMonthFilter}
                    statistics={[{
                      statisticName: 'matching_farmers',
                      label: 'matching'
                    }, {
                      statisticName: 'not_matching_farmers',
                      label: 'not matching'
                    }]}
                    title={'Farmers - name/country match'}
                  />
                }
                {(AppStore.loggedUser?.tenant?.code === 'gucci-dev' || AppStore.loggedUser?.tenant?.code === 'gucci') &&
                  <MultiStatPieOrderData
                    startYearMonthFilter={this.state.startYearMonthFilter}
                    endYearMonthFilter={this.state.endYearMonthFilter}
                    statistics={[{
                      statisticName: 'at_least_one_country_null_farmers',
                      label: 'missing farmer'
                    }, {
                      statisticName: 'at_least_one_country_different_farmers',
                      label: 'wrong country'
                    }]}
                    title={'Farmers - mismatching data'}
                  />
                }
                {(AppStore.loggedUser?.tenant?.code === 'gucci-dev' || AppStore.loggedUser?.tenant?.code === 'gucci') &&
                  <MultiStatPieOrderData
                    startYearMonthFilter={this.state.startYearMonthFilter}
                    endYearMonthFilter={this.state.endYearMonthFilter}
                    statistics={[{
                      statisticName: 'matching_slaughters',
                      label: 'matching'
                    }, {
                      statisticName: 'not_matching_slaughters',
                      label: 'not matching'
                    }]}
                    title={'Slaughters - name/country match'}
                  />
                }
                {(AppStore.loggedUser?.tenant?.code === 'gucci-dev' || AppStore.loggedUser?.tenant?.code === 'gucci') &&
                  <MultiStatPieOrderData
                    startYearMonthFilter={this.state.startYearMonthFilter}
                    endYearMonthFilter={this.state.endYearMonthFilter}
                    statistics={[{
                      statisticName: 'at_least_one_country_null_slaughters',
                      label: 'missing slaughter'
                    }, {
                      statisticName: 'at_least_one_country_different_slaughters',
                      label: 'wrong country'
                    }]}
                    title={'Slaughters - mismatching data'}
                  />
                }


                {(AppStore.loggedUser?.tenant?.code === 'gucci-dev' || AppStore.loggedUser?.tenant?.code === 'gucci') &&
                  <VerticalBarsOrderData
                    startYearMonthFilter={this.state.startYearMonthFilter}
                    endYearMonthFilter={this.state.endYearMonthFilter}
                    statisticCode="animal_birth_country"
                    attribute="data.birthCountry"
                    title={'Animal birth country'} />
                }

                {(AppStore.loggedUser?.tenant?.code !== 'gucci-dev' && AppStore.loggedUser?.tenant?.code !== 'gucci') &&
                  <VerticalBarsOrderData
                    startYearMonthFilter={this.state.startYearMonthFilter}
                    endYearMonthFilter={this.state.endYearMonthFilter}
                    statisticCode="completed_order"
                    attribute="data.processingCountry"
                    title={'Raw Material processing countries'} />
                }

                {AppStore.loggedUser?.tenant?.code !== 'gucci-dev' && AppStore.loggedUser?.tenant?.code !== 'gucci' && isAdmin() &&
                  <ActiveT1Supplier
                    startYearMonthFilter={this.state.startYearMonthFilter}
                    endYearMonthFilter={this.state.endYearMonthFilter}
                  />}
                {isAdmin() &&
                  <PieOrderData startYearMonthFilter={this.state.startYearMonthFilter}
                                endYearMonthFilter={this.state.endYearMonthFilter}
                                statisticCode="import-order"
                                attribute="networks"
                                title={'Tracked supply chain (Last 6 months)'}
                  />}
                {AppStore.loggedUser?.tenant?.code !== 'gucci-dev' && AppStore.loggedUser?.tenant?.code !== 'gucci' &&
                  <VerticalBarsOrderData
                    startYearMonthFilter={this.state.startYearMonthFilter}
                    endYearMonthFilter={this.state.endYearMonthFilter}
                    statisticCode="insert_onboarding"
                    attribute="payload.categories"
                    title={'Types of suppliers'} />}
                {(AppStore.loggedUser?.tenant?.code !== 'gucci-dev' && AppStore.loggedUser?.tenant?.code !== 'gucci' && isAdmin()) &&
                  <SpecificFiberTracked
                    startYearMonthFilter={this.state.startYearMonthFilter}
                    endYearMonthFilter={this.state.endYearMonthFilter}
                  />}
                <StatusOfTheReferencesForSuppliers
                  startYearMonthFilter={this.state.startYearMonthFilter}
                  endYearMonthFilter={this.state.endYearMonthFilter}
                  key={'analytics2'} />
                {(AppStore.loggedUser?.tenant?.code === 'gucci-dev' || AppStore.loggedUser?.tenant?.code === 'gucci') &&
                  <PieOrderData
                    startYearMonthFilter={this.state.startYearMonthFilter}
                    endYearMonthFilter={this.state.endYearMonthFilter}
                    statisticCode="animal_type"
                    attribute="data.animalType"
                    title={'Animal types'}
                  />
                }
                {(AppStore.loggedUser?.tenant?.code === 'gucci-dev' || AppStore.loggedUser?.tenant?.code === 'gucci') &&
                  <VerticalBarsOrderData
                    startYearMonthFilter={this.state.startYearMonthFilter}
                    endYearMonthFilter={this.state.endYearMonthFilter}
                    statisticCode="business-unit"
                    attribute="attributes.businessUnit"
                    title={'Business units'} />
                }
                {/*
                                {(AppStore.loggedUser?.tenant?.code === 'gucci-dev' || AppStore.loggedUser?.tenant?.code === 'gucci') &&
                                    <VerticalBarsOrderData
                                        startYearMonthFilter={this.state.startYearMonthFilter}
                                        endYearMonthFilter={this.state.endYearMonthFilter}
                                        statisticCode="product-description"
                                        attribute="attributes.productDescription"
                                        title={'Product description'}/>
                                }
                                */}
                {(AppStore.loggedUser?.tenant?.code === 'gucci-dev' || AppStore.loggedUser?.tenant?.code === 'gucci') &&
                  <VerticalBarsOrderData
                    startYearMonthFilter={this.state.startYearMonthFilter}
                    endYearMonthFilter={this.state.endYearMonthFilter}
                    statisticCode="commodity-class"
                    attribute="attributes.commodityClass"
                    title={'Commodity Class'} />
                }
              </Row>
            </Tabs.TabPane>
            <Tabs.TabPane tab="Supplier Management" key="2">
              <AnalyticsSummariesSupplierManagement />
              <View style={{ marginBottom: 10 }}>
                <RangePicker onChange={onChangeDataPicker} picker="month"
                             value={this.setDefaultValueDataPicker()}
                             style={{ top: -1 }}
                />
                <Select
                  style={{ width: 300, paddingLeft: 10 }}
                  placeholder={'Supplier'}
                  defaultActiveFirstOption={true}
                  showArrow={false}
                  filterOption={false}
                  onSearch={this.handleSearch}
                  onChange={handleChange}
                  notFoundContent={null}
                  options={this.state.data}
                  value={this.state.creationCompany}
                />
              </View>
              <Row gutter={[10, 20]}>

                <StatusOfTheReferences
                  startYearMonthFilter={this.state.startYearMonthFilter}
                  endYearMonthFilter={this.state.endYearMonthFilter}
                  creationCompany={this.state.creationCompany}
                  key={'analytics3'} />
                <TraceRating
                  startYearMonthFilter={this.state.startYearMonthFilter}
                  endYearMonthFilter={this.state.endYearMonthFilter}
                  creationCompany={this.state.creationCompany} />

                <CompanyCategoriesCounters
                  startYearMonthFilter={this.state.startYearMonthFilter}
                  endYearMonthFilter={this.state.endYearMonthFilter}
                  creationCompany={this.state.creationCompany}
                  categories={categoriesAnalytics}
                  title={'Declared sub-suppliers'}
                />
                {(AppStore.loggedUser?.tenant?.code === 'gucci-dev' || AppStore.loggedUser?.tenant?.code === 'gucci') &&
                  <MultiStatPieOrderData
                    startYearMonthFilter={this.state.startYearMonthFilter}
                    endYearMonthFilter={this.state.endYearMonthFilter}
                    creationCompany={this.state.creationCompany}
                    statistics={[{
                      statisticName: 'matching_farmers',
                      label: 'matching'
                    }, {
                      statisticName: 'not_matching_farmers',
                      label: 'not matching'
                    }]}
                    title={'Farmers - name/country match'}
                  />
                }
                {(AppStore.loggedUser?.tenant?.code === 'gucci-dev' || AppStore.loggedUser?.tenant?.code === 'gucci') &&
                  <MultiStatPieOrderData
                    startYearMonthFilter={this.state.startYearMonthFilter}
                    endYearMonthFilter={this.state.endYearMonthFilter}
                    creationCompany={this.state.creationCompany}
                    statistics={[{
                      statisticName: 'at_least_one_country_null_farmers',
                      label: 'missing farmer'
                    }, {
                      statisticName: 'at_least_one_country_different_farmers',
                      label: 'wrong country'
                    }]}
                    title={'Farmers - mismatching data'}
                  />
                }

                {(AppStore.loggedUser?.tenant?.code === 'gucci-dev' || AppStore.loggedUser?.tenant?.code === 'gucci') &&
                  <MultiStatPieOrderData
                    startYearMonthFilter={this.state.startYearMonthFilter}
                    endYearMonthFilter={this.state.endYearMonthFilter}
                    creationCompany={this.state.creationCompany}
                    statistics={[{
                      statisticName: 'matching_slaughters',
                      label: 'matching'
                    }, {
                      statisticName: 'not_matching_slaughters',
                      label: 'not matching'
                    }]}
                    title={'Slaughters - name/country match'}
                  />
                }
                {(AppStore.loggedUser?.tenant?.code === 'gucci-dev' || AppStore.loggedUser?.tenant?.code === 'gucci') &&
                  <MultiStatPieOrderData
                    startYearMonthFilter={this.state.startYearMonthFilter}
                    endYearMonthFilter={this.state.endYearMonthFilter}
                    creationCompany={this.state.creationCompany}
                    statistics={[{
                      statisticName: 'at_least_one_country_null_slaughters',
                      label: 'missing slaughter'
                    }, {
                      statisticName: 'at_least_one_country_different_slaughters',
                      label: 'wrong country'
                    }]}
                    title={'Slaughters - mismatching data'}
                  />
                }

                {(AppStore.loggedUser?.tenant?.code === 'gucci-dev' || AppStore.loggedUser?.tenant?.code === 'gucci') &&
                  <VerticalBarsOrderData
                    startYearMonthFilter={this.state.startYearMonthFilter}
                    endYearMonthFilter={this.state.endYearMonthFilter}
                    creationCompany={this.state.creationCompany}
                    statisticCode="animal_birth_country"
                    attribute="data.birthCountry"
                    title={'Animal birth country'} />
                }
                {(AppStore.loggedUser?.tenant?.code === 'gucci-dev' || AppStore.loggedUser?.tenant?.code === 'gucci') &&
                  <PieOrderData
                    startYearMonthFilter={this.state.startYearMonthFilter}
                    endYearMonthFilter={this.state.endYearMonthFilter}
                    creationCompany={this.state.creationCompany}
                    statisticCode={'animal_type'}
                    attribute={'data.animalType'}
                    title={'Animal types'}
                  />
                }
                {(AppStore.loggedUser?.tenant?.code === 'gucci-dev' || AppStore.loggedUser?.tenant?.code === 'gucci') &&
                  <VerticalBarsOrderData
                    startYearMonthFilter={this.state.startYearMonthFilter}
                    endYearMonthFilter={this.state.endYearMonthFilter}
                    creationCompany={this.state.creationCompany}
                    statisticCode="business-unit"
                    attribute="attributes.businessUnit"
                    title={'Business units'} />
                }
                {/*
                                {(AppStore.loggedUser?.tenant?.code === 'gucci-dev' || AppStore.loggedUser?.tenant?.code === 'gucci')  &&
                                    <VerticalBarsOrderData
                                        startYearMonthFilter={this.state.startYearMonthFilter}
                                        endYearMonthFilter={this.state.endYearMonthFilter}
                                        creationCompany={this.state.creationCompany}
                                        statisticCode="product-description"
                                        attribute="attributes.productDescription"
                                        title={'Product description'}/>
                                }
                                */}
                {(AppStore.loggedUser?.tenant?.code === 'gucci-dev' || AppStore.loggedUser?.tenant?.code === 'gucci') &&
                  <VerticalBarsOrderData
                    startYearMonthFilter={this.state.startYearMonthFilter}
                    endYearMonthFilter={this.state.endYearMonthFilter}
                    creationCompany={this.state.creationCompany}
                    statisticCode="commodity-class"
                    attribute="attributes.commodityClass"
                    title={'Commodity Class'} />
                }
              </Row>
            </Tabs.TabPane>
            {/*
                           <Tabs.TabPane tab="Environmental impact" key="3">
                            Content of Tab Pane 3
                        </Tabs.TabPane>
                        */}
          </Tabs>
        </div>
      </Layout>
    );
  }
}






