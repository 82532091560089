import React from 'react';
import {Layout} from 'components';
import {__, T} from 'config/i18n';
import {Button, Space} from 'antd';
import {navigate} from 'router';
import {Company, Page} from 'api/types';
import {Companies, HardCoded, OnBoardings, Users} from 'api';
import api, {setOffGlobalSpinningOnHeader} from 'api/api';
import AppStore from '../../AppStore';
import {isAdmin, isSupplier} from '../../helpers/permission';
import {buildFiltersByConfiguration} from '../../components/orders/FilterBuilderUtil';
import {buildColumnsByConfiguration} from '../../components/orders/ColumnBuilderUtil';
import FilteredTable from '../../components/FilteredTable';
import AddUserModal from '../../components/suppliers/AddUserModal';
import {showNotification} from '../../helpers/Notifications';
import exportData from '../../api/ExportData';


interface Props {
}

interface State {
  addSupplierModalToShow: boolean,
  askToUpdateData: number,
  lastSearchParams: any
}

export default class CompanyReport extends React.Component<Props, State> {
  state: State = {
    addSupplierModalToShow: false,
    askToUpdateData: 1,
    lastSearchParams: {}
  };

  async componentDidMount() {
  }

  getFilters() {
    return buildFiltersByConfiguration([
      {
        name: 'onBoardingCompanyCode',
        label: T.fields.code,
        type: 'text',
        placeholder: T.fields.code,
      },
      {
        name: 'freeText',
        label: T.misc.free_search,
        type: 'text',
        placeholder: T.misc.business_name_email_etc,
      },
      {
        name: 'onboarded_by',
        type: 'autocomplete',
        additionalOptions: [{ value: 'Admin', label: 'Admin' }, { value: 'Show All', label: 'Show All' }],
        label: T.fields.t1_filter,
        placeholder: T.misc.select,
        urlSearch: `${api.getBaseURL()}${Companies.getEndpoint()}/select?${Users.getAutocompleteQueryParam()}=-X-&withOnBoardingCreatorCompanyId=true`,
        header: setOffGlobalSpinningOnHeader(),
        descriptionKey: 'label',
        hideForSupplier: true
      },
      {
        name: 'category',
        type: 'select',
        label: T.fields.category,
        placeholder: T.misc.select,
        options: HardCoded.getCategoryOptions()
      },
      {
        name: 'companiesWithoutUsers',
        type: 'checkbox',
        label: T.fields.withoutUsers,
      },
      {
        name: 'companiesWithUsersNeverLoggedIn',
        type: 'checkbox',
        label: T.fields.neverLoggedUsers,
      }
    ]);
  }

  getColumns() {
    let columns = buildColumnsByConfiguration([
      {
        title: __(T.fields.code),
        dataIndex: 'onBoardingCompanyCode'
      },
      {
        title: __(T.fields.company_name),
        dataIndex: 'data.businessName'
      },
      {
        title: __(T.fields.categories),
        dataIndex: 'categories',
        renderType: 'categories'
      },
      {
        title: __(T.fields.creation_date),
        dataIndex: 'creationDate',
        renderType: 'dateTime'
      },
      {
        title: __(T.fields.country),
        dataIndex: 'data.country'
      },]);

    columns.push({
      title: __(T.fields.actions),
      key: 'action',
      render: (text, record) => (
        <Space size="middle">
          <Button type="link" onClick={() => navigate('/reports/companies/:companyId', { companyId: record.id })}>
            {__(T.buttons.view)}
          </Button>
        </Space>
      )
    });
    return columns;
  }


  newItem = () => {
    this.setState({
      addSupplierModalToShow: true
    });
  };
  onSuccessNewItem = async (company: Company) => {
    const onboarding = await OnBoardings.createByVatCode({
      partitaIva: company.companyCode, // is VatNumber
      categories: []
    });

    if (onboarding.errorCode >= 400) {
      showNotification(onboarding.status, onboarding.message, 'error');
    } else {
      showNotification(__(T.messages.invitation_successfully_created));
      this.setState({
        addSupplierModalToShow: false,
        askToUpdateData: this.state.askToUpdateData + 1
      });
    }
  };


  onCancelNewItem = () => {
    this.setState({
      addSupplierModalToShow: false
    });
  };
  getTabBarExtraContent = (): JSX.Element[] => {

    let buttons: JSX.Element[] = [];
    if (isSupplier()) {
      if (AppStore.appSettings.supplierDetailReadonly) {
        buttons.push(
          <Button
              key={"key_" + buttons.length}
            type="primary"
            onClick={this.newItem}
            style={{ minWidth: 200 }}>
            {__(T.buttons.add)}
          </Button>);
      } else {
        const isCompanyCheckByVatCode = AppStore.appSettings.enableCompanyCheckByVatCode;
        const redirectTo = isCompanyCheckByVatCode ? '/reports/companies/newByVatCode' : '/reports/companies/new'
        const params = isCompanyCheckByVatCode ? undefined : { 'newCompany': true };
        buttons.push(<Button type="primary"
          onClick={() => navigate(redirectTo, params)}
                             key={"key_" + buttons.length}
                             style={{ minWidth: 200 }}>
          {__(T.buttons.invite_new)}
        </Button>);
      }
    }

    if (isAdmin()) {

      if(AppStore.appSettings.enableExportCompanyOnboardedBy){
        buttons.push(<Button type="primary"
                             key={"key_" + buttons.length}
                             onClick={() => this.exportXls()}
                             style={{ minWidth: 200 }}>
          {__(T.buttons.export_xls)}
        </Button>);
      }

    }

    return buttons;
  };


  exportXls = async () => {
    exportData.newFileDownload('companyExportXlsSchema',
      'tCare-company Export_data.xls','XLS',
      Companies.getEndpointDownloadCompanyXls(),
      50,
      { ...this.state.lastSearchParams
    })
    // await Companies.downloadCompanyXls(this.state.lastSearchParams);
  };




  onSearch = async (params: any, currentPage: number, sortField: string, sortDirection: 'asc' | 'desc', size: number, activeTabKey?: string): Promise<Page<Company>> => {
    const loggedUserCompanyId = AppStore.loggedUser?.company?.id ?? undefined;

    if (!isAdmin()) {
      params['withOnBoardingCreatorCompanyId'] = true;
      params['onBoardingCreatorCompanyId'] = loggedUserCompanyId;
    } else {
      if (params['onboarded_by']) {
        var filterValue = params['onboarded_by'];
        switch (filterValue) {
          case 'Admin':
            params['onBoardingCreatorCompanyId'] = undefined;
            params['withOnBoardingCreatorCompanyId'] = true;
            break;
          case 'Show All':
            params['onBoardingCreatorCompanyId'] = undefined;
            params['withOnBoardingCreatorCompanyId'] = false;
            break;
          default:
            params['onBoardingCreatorCompanyId'] = filterValue;
            params['withOnBoardingCreatorCompanyId'] = true;
            break;
        }
        params['onboarded_by'] = undefined;
      }
    }

    if (activeTabKey === 'active' || activeTabKey === 'disabled') {
      params['enabled'] = activeTabKey === 'active';
    }
    if (activeTabKey === 'expiring') {
      params['expiring'] = true;
    }
    if (activeTabKey === 'expired_certifications') {
      params['expired'] = true;
    }

    this.setState({
      lastSearchParams: params
    });
    return await Companies.search({
      ...params,
      page: currentPage,
      size: size,
      sort: sortField + ',' + sortDirection
    },
       setOffGlobalSpinningOnHeader(),);
  };


  getTabs = (): string[] => {
    let tabs: string[] = [];
    tabs.push('active');
    if (AppStore.appSettings.enableExpiringCertificationManagement) {
      tabs.push('expiring');
    }
    if (AppStore.appSettings.enableExpiringCertificationManagement) {
      tabs.push('expired_certifications');
    }
    tabs.push('disabled');
    return tabs;
  };

  render() {

    return (
      <Layout>
        <FilteredTable
          onSearchElements={this.onSearch}
          getColumnsConfig={this.getColumns}
          getFiltersConfig={this.getFilters}
          getExtraButtons={this.getTabBarExtraContent}
          sorterField={'creationDate'}
          sorterOrder={'descend'}
          askToUpdateData={this.state.askToUpdateData}
          tab={{ tabKeys: this.getTabs() }}
          storeKey={"companyManagement"}
        />
        <AddUserModal
          isVisible={this.state.addSupplierModalToShow}
          libraryType={'doc'}
          onCancelCallback={this.onCancelNewItem}
          onSuccessCallback={this.onSuccessNewItem} />
      </Layout>
    );
  }

}

