import React from 'react'
import {Layout, Modal, View} from 'components'
import {__, T} from 'config/i18n'
import {Col, Spin} from 'antd'
import {navigate} from 'router'
import theme from 'theme/theme'
import {OnBoardings} from 'api'
import {showNotification} from 'helpers/Notifications'
import {CreateOnboardingFormFields} from '../onboarding/CreateOnboardingForm'
import CreateByVatCodeForm from "../onboarding/CreateByVatCodeForm";

interface Props {}

interface State {
  modalVisible: boolean
  formFields: CreateOnboardingFormFields
  loading: boolean
}

export default class CreateOnboardingByVatCode extends React.Component<Props, State> {
  state: State = {
    modalVisible: false,
    formFields: { companyType: 'supplier' },
    loading: false,
  }

  renderModal = () => {
    return (
      <Modal
          visible={this.state.modalVisible}
          title={__(T.confirms.invite_company)}
          message={__(T.messages.confirm_or_go_back)}
          closeButtonTitle={__(T.buttons.back)}
          confirmButtonTitle={__(T.buttons.next)}
          onCloseClick={() => {
            this.setState({modalVisible: false})
          }}
          onConfirmClick={this.createOnboardingByVatCode}
      />
    )
  }

  createOnboardingByVatCode = async () => {
    const { formFields } = this.state
    this.setState({ modalVisible: false, loading: true })
    if (!formFields.companyType) {
      formFields.companyType = 'supplier'
    }
    const onboarding = await OnBoardings.createByVatCode({
      partitaIva: formFields.partitaIva?.trim(),
      categories: [],
    })


    if (onboarding.companyAlredyPresent) {
      showNotification(__(T.messages.onboarding_byVatCode_company_already_exist), undefined, 'info')
      navigate('/reports/companies/:companyId', { companyId: onboarding.companyId })
    } else if (onboarding.errorCode >= 400) {
      showNotification(onboarding.status, onboarding.message, 'error')
    }else{
      showNotification(__(T.messages.invitation_successfully_created))
      navigate('/reports/companies/:companyId', { companyId: onboarding.companyId })
    }
    this.setState({ loading: false })

  }

  onValuesChange = (changedValues: Partial<CreateOnboardingFormFields>, values: CreateOnboardingFormFields) => {
    this.setState({
      formFields: { ...values, categories: changedValues.companyType ? undefined : values.categories },
    })
  }

  render() {
    const { loading, formFields } = this.state
    return (


      <Layout>
        <Spin spinning={loading} delay={500}>
          <View style={{ padding: 20 }}>
            <View className="page-table">
              <Col span={10}>
                <CreateByVatCodeForm
                  formFields={formFields}
                  onValuesChange={this.onValuesChange}
                  submitButton={{
                    text: __(T.buttons.find_or_create_by_vatCode),
                    onPress: () => {
                      this.setState({ modalVisible: true })
                    },
                    col: 12,
                    style: theme.temeraPrimaryGreenButton,
                  }}
                  secondaryButton={{
                    text: __(T.buttons.back),
                    onPress: () => {
                      navigate('/reports/companies')
                    },
                    col: 12,
                    style: theme.temeraSecondaryDarkButton,
                  }}
                />
              </Col>
            </View>
          </View>
        </Spin>
        {this.renderModal()}
      </Layout>
    )
  }
}
