import {category, role} from 'types';

export interface AppSettings {
    enableAdminJsonSchemPreview?: boolean;
    bigLogo: string
    smallLogo: string
    headerTitle: string
    enableAutoComplete: boolean
    enableRejectOrder: boolean
    enableCancelSubOrders: boolean
    enableBulkReject: boolean
    enableApproveOrder: boolean
    enableExpiringCertificationManagement: boolean
    enableTraceManagement: boolean
    enableOrderManagement: boolean
    enableCompanyOnboarding: boolean
    enableCompanySuppliersManagement: boolean
    enableOrderDetailSmart: boolean
    traceImportFieldsToExclude: string
    categoriesT1: ''
    categories: string,
    categoriesUsableOnOnBoarding?: string,
    orderDetailInfo: string
    orderListColumnsForSplit: string
    orderListColumns: string
    orderListFilters: string
    orderListRawMaterialSourceColumns: string
    orderListRawMaterialSourceFilters: string
    orderListCustomerColumns: string
    orderListCustomerFilters: string
    orderAvailableStatuses: string
    orderPageHeader: string
    enableBulkAssign: boolean
    enableSplit: boolean
    enableXlsImport: boolean
    enableXlsExport: boolean
    enableExportCSVOnTrace: boolean
    enableTraceLibrary: boolean
    enableDocumentLibrary: boolean
    enableHandmade: boolean
    handmadeSupplierCompany: string
    supplierDetailReadonly: boolean
    extraMenu: string
    enableBulkComplete: boolean
    companyJsonFormSchema: string
    enableExportCompanyOnboardedBy: boolean
    enableEnquiryDisapprove: boolean
    enquireAttributesEditJsonSchema: string
    enableOrderPdf: boolean
    languages: string
    enableAdminDeleteEnquiry: boolean
    enableAdminEditVatCode: boolean
    menuConfiguration?: string
    enquiryDownloads?: string
    enquiryRejectReasons?: string
    traceReportFields?: string
    enableOnlyReadSupplierCompanyProfileCategory?: boolean
    enableReopenOrder?: boolean
    enableDraftParentOnReject?: boolean
    enableEnquiryBlockIfChildNotCompletedOrCancelled?: boolean
    disableFullfilledComplete?: boolean
    enableAddAssign?: boolean
    disableOnboardingInviteOnAssignment?: boolean
    orderImportableFormTraceStatuses?: string
    enableCompanyCheckByVatCode?: boolean
    dateFormat?: string
    enableLockIfChildrenAreNotApproved?: boolean
    enableDashBoardCounter: boolean,
    isDisabledSupplierTabCertification?: boolean
    visibleRoles: string
    formatDownloadQrCode?: string
}

export interface BaseMenuConfiguration {
    type: MenuConfigurationType,
    url: string,
    label: string,
    visibleInDashboard: boolean,
    visibleInMenu: boolean,
    roles: role[],
    iconMenu?: string,
    iconDashboard?: string
}

export interface MenuConfiguration extends Partial<BaseMenuConfiguration> {
    type: MenuConfigurationType,
    categories?: string[],
    extra?: {
        code?: string,
        path?: string
    }
}

export type MenuConfigurationMapping = Record<MenuConfigurationType, BaseMenuConfiguration>

export type MenuConfigurationType =
    'HOME'
    | 'TRACE_CREATE'
    | 'REPORT_TRACES'
    | 'ENQUIRY_MANAGEMENT'
    | 'DYNAMIC_ENQUIRY_MANAGEMENT'
    | 'CUSTOMER_ORDERS_REPORT'
    | 'RAW_MATERIAL_ORDERS'
    | 'COMPANY_REPORT'
    | 'ON_BOARDING'
    | 'COMPANIES_REPORT'
    | 'USER_REPORT'
    | 'PROFILE_MANAGEMENT'
    | 'FORM_BUILDER'
    | 'TRACE_LIBRARY'
    | 'DOCUMENT_LIBRARY'
    | 'ANALYTICS'
    | 'DOWNLOADS_MANAGEMENT'
    | 'PATH'
    | 'TINSIGHT'
    | 'GENERIC_LINK'

export interface BaseEntity {
    id: any;
    creationDate: number;
    lastUpdate: number;
}

export interface SelectElement {
    id: string;
    label: string;
    enabled: boolean;
}

export interface Tenant extends BaseEntity {
    code: string;
    multiNetwork: boolean;
}

export interface User extends BaseEntity {
    type: string;
    version: number;
    userName: string;
    email: string;
    firstName: string;
    lastName: string;
    phone?: string;
    company?: Company;
    companies?: Company[];
    onBoardings: any[];
    enabled: boolean;
    companyConfirmed: boolean;
    password?: string;
    tenant?: Tenant;
    lastUsedRole?: string;
}

export interface Page<T> {
    content: T[];
    totalElements: number;
    page: number;
    totalPages: number;
}

export interface PaginationParams {
    page: number;
    size: number;
}

export interface QueryParams extends PaginationParams {
    [index: string]: any;
}

export interface SimpleQueryParams {
    [index: string]: any;
}

export interface CompanyData {
    businessName: string;
    address?: string;
    addressNumber?: string;
    // category: category
    cap?: string;
    country?: string;
    city?: string;
    email?: string;
    legalCode?: string;
    mobilePhone?: string;
    pec?: string;
    phone?: string;
    quantityAllowed?: number;
    suppressionMethod?: string;
    ethicalCode?: boolean;
    competenceArea?: string;
    placeId?: any;
}

export interface Certification extends BaseEntity {
    data: CertificationData;
    versions: any[];
}

export interface CertificationData {
    expiration: Date;
    certificateHash: string;
    filename: string;
    fileId: string;
    certificateType: CertificationType;
    certificateNumber: string;
    companyName: string;
    certificateStatus: CertificationStatus;
}

export interface CertificationUpload extends Partial<BaseEntity> {
    id?: string;
    companyId: string;
    file?: File;
    expiration?: string;
    certificateType?: CertificationType;
    certificateNumber?: string;
    companyName?: string;
}

export interface FileUpload extends Partial<BaseEntity> {
    file?: File;
}

export const certificationTypes = ['GOTS', 'RCS', 'FAIRTRADE', 'GRS', 'OCS', 'RWS', 'ZQ', 'NATIVA',
    'GOLD', 'SILVER', 'BRONZE', 'UNI_11427', 'EMAS', 'UNI_EN_ISO_14001_2015', 'UNI_EN_ISO_45001',
    'UNI_CEI_EN_ISO_50001_2018', 'SA8000', 'UNIC', 'ICEC', 'UNI_EN_16484', 'ICEC_TS_SC_410___ICEC_TS_PC412',
    'CENTRIC', 'UNI_EN_ISO_14040__14044_o_14072', 'other_certification'];
export type CertificationType = typeof certificationTypes[number]

export const certificationStatuses = ['VALID', 'EXPIRING', 'EXPIRED'];
export type CertificationStatus = typeof certificationStatuses[number]

export interface Company extends BaseEntity {
    onBoardingCompanyCode?: string
    adminCompanyCode?: string
    companyCode?: string
    users: SelectElement[]
    categoryAttributes: string
    certifications: Certification[]
    referent: User
    enabled: boolean
    onBoardings: string[]
    data: CompanyData
    referee?: Partial<Company>
    type: string
    certificationValidity: boolean
    categories?: category [],
    attributes: { [key: string]: string }
    allUsers: SelectElement[]
}

export interface CreateSupplierCommand {
    data: CompanyData
    categories?: category []
    attributes: { [key: string]: string }
}

export interface Trace extends BaseEntity {
    code: string;
    identifier: Partial<Identifier>;
    traceType: TraceType;
    creationCompany?: string;
    ownerCompany?: string;
    data: {};
    creationUser: string;
    certificateExpired: boolean;
    hideTrace: boolean;
}

export interface TraceType extends BaseEntity {
    tenant: string;
    code: string;
    version: number;
    identifierType: IdentifierType;
    network: string;
    sequenceCode: number;
    schema: string;
    attributesSchema: string;
    category: category;
}

export interface IdentifierType extends BaseEntity {
    tenant?: string;
    code: string;
    previousIdentifierTypes?: any[];
    description: string;
}

export interface Identifier extends BaseEntity {
    code: string;
    identifierType: IdentifierType | string;
    previousIdentifiers?: string[];
}

export interface OnBoarding extends BaseEntity {
    guest: User;
    creator: User;
    company: Company;
    state: 'CREATED' | 'SENT' | 'ON_APPROVAL' | 'APPROVED' | 'DISABLED';
    data: { partitaIva?: string; companyCode?: string };
    categories: category[];
}

export interface Subsupplier extends BaseEntity {
    companyCode?: string;
    type: string; // 'it.temera.stw.tcare.profilemanagerservice.dto.SubSupplierDto'
    data: CompanyData;
    enabled?: boolean;
    certifications: any[];
    referee?: Partial<Company>;
}

export interface FileInformation {
    id: string;
    creationDate: string;
    lastUpdate: string;
    fileName: string;
    hash: string;
    uri: string;
}

export type SplitOrderCommandItemAction = 'INSERT' | 'UPDATE' | 'DELETE';

export interface SplitOrderCommandItem {
    action: SplitOrderCommandItemAction;
    orderId?: string;
    attributes: { [key: string]: any };
}

export interface SplitOrderCommand {
    motherOrderId: string;
    itemOrders: SplitOrderCommandItem[];
}

export interface RetrieveFirstTraceTypeCommand {
    orderType: string;
    productMatchCode: string;
}

export interface RetrieveDataSchemaCommand {
    productMatchCode: string;
    tenant?: string;
}

export interface ImportFromTraceBulkCommand {
    orderIdToImport: string;
    orderIds: string[];
    override: boolean;
}

export interface ImportFromLibraryBulkCommand {
    libraryIdToImport: string;
    orderIds: string[];
    override: boolean;
}


export interface Download extends BaseEntity {
    fileName: string;
    type: DownloadType;
    status: DownloadStatus;
    lastPage: number;
    totalPage: number;
}

export interface ModalDownloadConfig {
    type: 'GENERATE' | 'TRACE_FILE',
    templateCode?: string,
    tracePath?: string,
    label: string,
    roles?: role[],
    categories?: string[],
    order?: number,
    filename?: string,
    enquiry?: {
        statuses?: string[],
        traceTypes?: string[]
    },
    sendMail: boolean
}

export interface ModalDownloadData {
    id: string,
    label: string,
    payload: any
}

export interface Order extends BaseEntity {
    operativeUsername: string;
    code: string;
    orderDate: number;
    assignmentDate: number;
    fulfillmentDate: number;
    networks?: string[];
    step: string;
    version: number;
    // productDescription: string
    // productCode: string
    status: OrderStatus;
    // season: string
    // event: string
    assigneeCompany: string;
    orderType: string;
    creationCompany?: string;
    attributes?: {
        [key: string]: any
    };
    originalAttributes?: {
        [key: string]: any
    };
    splittedOrderId: string | undefined;
    handmade: boolean;
    errorValidation: { string: 'REQUIRED' | 'TYPE_MISMATCH' };

    completionVersion: number;
    userCompletion: string;
    lastModifiedUsername?: string;
    reject: Reject;
    childDataList?: ChildData[]
}

export interface ChildData {
    code: string,
    status: OrderStatus,
    reject: Reject
}

export interface ChildrenReject extends Reject {
    childOrderId: string;
    status: 'OPEN' | 'CLOSE';
}


export interface NotificationsAndCount {
    count: number;
    timstamp: number;
    notifications: NotificationTCare[];
}

export interface NotificationTCare {
    id: string;
    userId: string;
    title: string;
    body: string;
    read: boolean;
    creationTimeAgo: number;
    noticeType: NoticeType;
}

export type DownloadStatus = 'NEW' | 'IN_PROGRESS' | 'IN_ERROR' | 'COMPLETED';

export type DownloadType = 'XLS' | 'CSV';

export type NoticeType = 'ASSIGNMENT_REMINDER' | 'FULFILLMENT_REMINDER' |
    'NEW_ASSIGNMENT' | 'ON_BOARDING_APPROVAL' | 'COMPLETED_REFERENCE' |
    'EXPIRED_SCOPE_CERIFICATE' | 'EXPIRING_SCOPE_CERTIFICATE' | 'WELCOME_MESSAGE' | 'REJECTED_REFERENCE'
    | 'END_GENERATION_EXPORT'

export type OrderLogType =
    'IMPORT'
    | 'SPLIT_NEW_ROW'
    | 'SPLIT_UPDATE'
    | 'REJECT'
    | 'ASSIGN_CHILD'
    | 'ASSIGNED'
    | 'DRAFT'
    | 'DRAFT_THIRD_PARTY'
    | 'FULFILL'
    | 'FULFILL_THIRD_PARTY'
    | 'COMPLETE'
    | 'CANCEL'
    | 'UNLOCK'
    | 'APPROVE'
    | 'REOPEN'
    | 'DISAPPROVE'

export interface OrderLog extends BaseEntity {
    orderId: string;
    data: any;
    type: OrderLogType;
    user: string;
}

export interface BulkApproveOrderDetailDto {
    orders: DetailedOrder[];
    totalToApprove: number;
    approved: number;
}


export interface DetailedOrder extends Order {
    materialGroup: string;
    // styleDescription: string
    // styleCode: string
    // colorDescription: string
    // colorCode: string
    // quantity: number
    // unitOfMeasure: string
    productComposition: string;
    orders: SubOrder[];
    certificateExpired: boolean;
    traceType: TraceType;
    orderType: string;
    creationUser: string;
    creationCompany?: string;
    ownerCompany?: string;
    data: any;
    root: boolean;
    orderHandmadeConfig: OrderHandmadeConfig;
    shipmentMap: {
        [orderId: string]: OrderShipment[]
    };
}

export interface OrderShipment {
    ddtNumber: String;
    ddtDate: String;
    bagno: String;
    nuance: String;
    quantity: String;
    unit: String;

    serialNumber: String;
    color: String;
    supplier: String;
    rawMaterialCode: String;
    dtt: String;
}

export type DetailedOrderWithOwnerCompanyInfo = DetailedOrder & {
    ownerCompanyName?: string
    certificationValidity?: boolean
}

export interface SubOrder {
    id: string;
    originalOrderId: string;
    version: number;
    code: string;
    orderDate: number;
    assignmentDate: number;
    fulfillmentDate: number;
    reject?: Reject;
    childDataList?: ChildrenReject[];
    ownerCompany: string;
    creationCompany: string;
    assigneeCompany?: string;
    network: string;
    materialGroup?: string;
    productComposition: string;
    productCompositionDesc: string;
    step: string;
    category: category;
    status: OrderStatus;
    orders: SubOrder[];
    traceType: TraceType;
    attributes: {
        [key: string]: any
    };
}

export type SubOrderWithOwnerCompanyInfo = SubOrder & {
    ownerCompanyName?: string
    creationCompanyName?: string
    certificationValidity?: boolean,
    assigneeCompanyName?: string
}

export interface OrderAssignments {
    draft?: boolean;
    assignments: SubOrderAssignment[];
}

export interface OrderCloneAssignment {
    originalOrderId?: string;
    companyId?: string;
    orderCode?: string;
    orderDate?: any;
    attributes?: { [key: string]: any }
}

export interface SubOrderAssignment {
    orderId?: string;
    originalOrderId?: string;
    company?: Company;
    orderCode?: string;
    orderDate?: any;
    deleted?: boolean;
    attributes?: { [key: string]: any };
    cancelOrder?: boolean;
}

export type SubOrderMultiAssignment = SubOrderAssignment & {
    parentOrderCode: string
    parentOrderId: string
    parentFormRef: any
}

export interface OrderFulfillment {
    data: { [key: string]: any };
    thirdPart?: boolean;
}

export interface OrderDraft {
    data: { [key: string]: any };
    thirdPart?: boolean;
}

export interface OrderDraft {
    data: { [key: string]: any };
}

export const orderStatuses = ['DRAFT', 'TO_BE_ASSIGNED', 'ASSIGNED', 'FULFILLED', 'COMPLETED', 'APPROVED', 'CANCELLED'] as const;
export type OrderStatus = typeof orderStatuses[number]

export interface SupplyChainStep {
    id: string;
    code: string;
    name?: string;
}

export interface SupplyChain {
    id: string;
    name: string;
    fiberCodes: string[];
    enabled: boolean;
    steps?: SupplyChainStep[];
    creationDate?: string;
    lastModifyDate?: string;
    tenant?: string;
    version?: number;
}

export interface Properties {
    value: string;
    label: string;
    type?: 'SELECT' | 'NUMBER' | 'TEXT';
    values?: Properties[];
    mode?: 'multiple' | 'tags';
    maxTagCount?: number;
}

export interface RuleException {
    property: string | undefined;
    values?: any | undefined;
}

interface BaseRule {
    id?: string;
    name: string;
    startDate: any;
    endDate: any;
    enabled?: boolean;
    version?: number;
}

export interface Rule extends BaseRule {
    exceptions: RuleException[];
}

export interface RuleExceptionForm extends RuleException {
    index?: number;
}

export interface RuleForm {
    [key: string]: any;
}

export interface RuleApi extends BaseRule {
    seasonEvents?: string[];
    styleCodes?: string[];
    companyIds?: string[];
    percentage?: number;
    fiberCodes?: string[];
    supplyChains?: string[];
    traceTypeIds?: string[];
}

export interface StatisticData {
    values: { [key: string]: any };

}

export interface TraceRelations {
    nextTraces: TraceRelationNetworks[];
    identifierTraces: TraceRelationNetworks[];
    previousTraces: TraceRelationNetworks[];
}

export interface TraceRelationNetworks {
    network: string;
    identifierId: string;
    traces: TraceRelation[];
}

export interface TraceRelation {
    id: string;
    category: string;
}

export type ReportOrder =
    Order
    & {
    assigneeCompanyName: string,
    creationCompanyName: string,
    orderNumber: number,
    traceTypeId: string,
    traceTypeCode: string
}

export interface Reject {
    note: string,
    date: any,
    username: string,
    reasons: string[]
}

export interface LibraryConfig extends BaseEntity {
    name: string;
    type: LibraryConfigType;
    traceType: TraceType;
    schema: string;
    creationCompany: string;
}

export interface LibraryItem extends BaseEntity {
    name?: string;
    libraryConfig: LibraryConfig;
    traceType: TraceType;
    // Only the data will be copied on Orders
    data: any;
    attributes: any;
    creationCompany: string;
    visibleForAdmin: boolean | string; // string in order to print on antd table
    startDate?: string;
    endDate?: string;
}

export type LibraryConfigType = 'TRACE' | 'DOCUMENT';

export type LibraryItemWithCompanyInfo = LibraryItem & {
    creationCompanyName?: string
}

export interface OrderHandmadeConfig extends BaseEntity {
    code: string
    label?: string;
    orderType: string;
    productMatchCodes: string[];
    schema: string;
    extraAttributes: any;
    productMatchCode: string;
    showRawMaterialSupplier: boolean;
    categories: string[];
    supplierFulfillEnable: boolean;
}

export interface FulfilResult {
    completeOrder: number;
    notValidOrder: number;
}

export interface ImportOrderTraceExcelResult {
    excelRowCounter: number;
    updatedEnquiryCounter: number;
    validationErrorEnquiryCounter: number;
    notUpdateRowCounter: number;
    notUpdateNumberRow: number[];
}

export interface Translation {
    mapMap: Map<string, any>;
}

export interface TemplateSendEmailCommand {
    readonly emailAddress: string[]
    readonly fileName: string
    readonly payload: any
}

export type SubOrderCardConfigurationType = 'GENERIC' | 'DATE' | 'STATUS' | 'TAG' | 'PO' | 'SCOPE'

export interface SubOrderCardConfiguration {
    type: SubOrderCardConfigurationType,
    label: string,
    field?: string,
    visibleIntoAssignedMode?: boolean,
    bold?: boolean,
    dateFormat?: string
    width?: string
}

export interface Mail {
    fileBase64Encoded?: string,
    fileName?: string,
    fileType?: string,
    params?: { [key: string]: any },
    emailAddress: string[],
    codeTemplate,
    fileId?
}

export interface CreateUserCommand {
    companyIds: string[]
    email: string
    name: string
    surname: string
    phone?: string
    role: string
}

export interface UpdateUserCommand {
    userId: string
    companyIds: string[]
    name: string
    surname: string
    phone?: string
    role: string
}

export interface UserRole {
    name: role
}

export interface OrderDetailQrCodeSetting {
    onlyRoot: boolean,
    value: string
}