import {OnBoarding, Page, QueryParams} from 'api/types';
import {AxiosRequestConfig} from 'axios'
import api, {responseErrorCheck, setOffGlobalSpinningOnHeader} from './api';
import {ImportOnBoarding, OnboardingProgress} from '../types';
import {showError} from '../helpers/Notifications';

class OnBoardings<T = OnBoarding> {
  protected endpoint = 'profilemanager/v1/onBoardings'

  async findAll(axiosConfig?: AxiosRequestConfig) {
    const res = await api.get<Page<T>>(`${this.endpoint}?size=1000`, {}, axiosConfig)
    return responseErrorCheck(res)
  }

  async progress(id: string, data: OnboardingProgress, axiosConfig?: AxiosRequestConfig) {
    const res = await api.put<T>(`${this.endpoint}/${id}/progress`, data, axiosConfig)
    return responseErrorCheck(res)
  }

  async approve(id: string, axiosConfig?: AxiosRequestConfig) {
    const res = await api.put<T>(`${this.endpoint}/${id}/completed`, {}, axiosConfig)
    return responseErrorCheck(res)
  }

  async showWarningBeforeCreate(vatCode: string, axiosConfig?: AxiosRequestConfig): Promise<boolean> {
    const res = await api.get<boolean>(`${this.endpoint}/showWarningBeforeCreate/${vatCode}`, axiosConfig)
    return responseErrorCheck(res, false)
  }

  async save(data: any, axiosConfig?: AxiosRequestConfig): Promise<any> {
    const res = await api.post<T>(`${this.endpoint}`, data, axiosConfig)
    return responseErrorCheck(res, false)
  }

  async import(traceDatafile: File, axiosConfig?: AxiosRequestConfig) {
    const formData = new FormData()
    traceDatafile && formData.append('file', traceDatafile)
    let axiosConfigMerged: AxiosRequestConfig = {
      headers: {
        'content-type': 'multipart/form-data',
        timeout: 200000,
        ...setOffGlobalSpinningOnHeader
      }
    }
    const res = await api.post<ImportOnBoarding>(this.endpoint + '/import', formData, axiosConfigMerged)
    return responseErrorCheck(res)
  }

  async downloadOnBoardingTemplate(axiosConfig?: AxiosRequestConfig) {

    let axiosConfigMerged: AxiosRequestConfig = {
      responseType: 'blob',
      timeout: 200000,
      headers: {
        ...axiosConfig
      }
    }

    await api.get(this.endpoint + '/downloadOnBoardingTemplate', {}, axiosConfigMerged)
      .then(response => {
        let filename;
        const disposition = response.headers!['content-disposition'];
        filename = disposition.split('filename=')[1];
        const a = document.createElement('a');
        a.href = window.URL.createObjectURL(response.data);
        a.download = filename;
        document.body.appendChild(a);
        a.click();
      })
      .catch(error => {
        // console.error(error);
        showError("Error", error.message)
      });
  }


  async createByVatCode(data: any, axiosConfig?: AxiosRequestConfig): Promise<any> {
    const res = await api.post<T>(`${this.endpoint}/createByVatCode`, data, axiosConfig)
    return responseErrorCheck(res, false)
  }


  async search(params: QueryParams, axiosConfig?: AxiosRequestConfig) {
    const res = await api.get<Page<T>>(`${this.endpoint}`, params, axiosConfig)
    return responseErrorCheck(res)
  }

  async resendEmail(id: string, axiosConfig?: AxiosRequestConfig) {
    const res = await api.get<T>(`${this.endpoint}/${id}/sent`, {}, axiosConfig)
    return responseErrorCheck(res)
  }
}

const onboardings = new OnBoardings()
export { onboardings as default }
