import React from 'react'
import { SubOrderWithOwnerCompanyInfo } from '../../api/types'
import { __, T } from '../../config/i18n'
import OrderNetworkTag from './OrderNetworkTag'
import { PageHeader } from 'antd'

interface Props {
  subOrder: SubOrderWithOwnerCompanyInfo
}

export default class OrderTraceReportSubOrderCardHeader extends React.Component<Props> {
  render = () => {
    const { subOrder } = this.props
    return (
      <PageHeader
        title={
          <table>
              <tbody>
            <tr style={{ fontSize: '12px', lineHeight: '12px' }}>
              {/*<th style={{ paddingRight: '10px', borderRight: '1px solid #C7C7C7' }}>{__(T.fields.order_fiber)}</th>*/}
              <th style={{ paddingLeft: '10px', paddingRight: '10px', borderRight: '1px solid #C7C7C7' }}>
                {__(T.fields.order_networks)}
              </th>
              <th style={{ paddingLeft: '10px' }}>{__(T.fields.product_composition)}</th>
            </tr>
            <tr style={{ marginTop: '0px', paddingTop: '0px' }}>
              {/*<td style={{ paddingRight: '10px', borderRight: '1px solid #C7C7C7' }}>*/}
              {/*  <Text>{subOrder.network}</Text>*/}
              {/*</td>*/}
              <td
                valign={'middle'}
                style={{
                  paddingLeft: '10px',
                  paddingRight: '10px',
                  paddingBottom: '5px',
                  borderRight: '1px solid #C7C7C7',
                }}
              >
                <OrderNetworkTag
                  network={subOrder.network}
                  style={{ width: '100%', textAlign: 'center' }}
                />
              </td>
              <td style={{ paddingLeft: '10px', paddingRight: '10px', fontSize: '14px' }}>
                {subOrder.productComposition}
              </td>
            </tr>
          </tbody>
          </table>
        }
        style={{
          backgroundColor: '#E9E9E9',
          borderTopLeftRadius: 5,
          borderTopRightRadius: 5,
          padding: 10,
          paddingLeft: 15,
        }}
      />
    )
  }
}
