import React from 'react';
import { Modal, Button } from 'antd';
import { __, T } from 'config/i18n';
import { BulkApproveOrderDetailDto } from '../../api/types';
import theme from '../../theme/theme'; // Cambia il percorso al file corretto

interface Props {
  isVisible: boolean;
  onCancel: () => void; // Deve chiudere il componente
  onForceApprove: () => void; // Deve approvare con o senza forzatura
  bulkApproveOrderDetail: BulkApproveOrderDetailDto;
  canUserForceApprove: boolean;
}

interface State {
  forceApprove: boolean;
}

export default class BulkApproveDialog extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      forceApprove: props.canUserForceApprove
    };
  }


  handleApprove = () => {
    this.props.onForceApprove();
  };

  render() {
    const { isVisible, onCancel, bulkApproveOrderDetail } = this.props;
    const { totalToApprove, approved } = bulkApproveOrderDetail;
    const { forceApprove } = this.state;

    return (
      <Modal
        visible={isVisible}
        onCancel={onCancel}
        footer={null}
        centered
        title={__(T.bulkApproveDialog.title)}
      >
        <div style={{ textAlign: 'left' }}>

          {approved === totalToApprove && totalToApprove === 0 && (
            <p><strong>{__(T.messages.nothing_to_approve)}</strong></p>
          )
          }

          {approved !== 0 && approved === totalToApprove && (
            <p><strong>{__(T.bulkApproveDialog.all_order_approved, { approved })}</strong></p>
          )
          }

          {approved < totalToApprove && (
            <p><strong>{approved}</strong> {__(T.bulkApproveDialog.approved)} <strong>{totalToApprove}</strong></p>
          )
          }

          {approved < totalToApprove && (
            <div>
              <p><strong>{totalToApprove - approved}</strong> {__(T.bulkApproveDialog.notApproved)}
                <strong>{__(T.bulkApproveDialog.approved_key)}</strong></p>
            </div>
          )}
          {(forceApprove && totalToApprove !== approved) ? (
            <>
              <strong>{__(T.bulkApproveDialog.forceApproveQuestion)}</strong>
            </>
          ) : <></>}

          <div style={{ marginTop: '20px', display: 'flex', justifyContent: 'center' }}>
            {approved < totalToApprove && forceApprove ? (
              <>
                <Button type="default" onClick={onCancel}
                        style={{ ...{ marginRight: '10px' }, ...theme.temeraSecondaryDarkButton }}>
                  {__(T.buttons.no)}
                </Button>
                <Button type="primary" onClick={this.handleApprove}>
                  {__(T.buttons.yes)}
                </Button>
              </>
            ) : (
              <Button type="default" onClick={onCancel} style={{ ...theme.temeraSecondaryDarkButton }}>
                {__(T.buttons.close)}
              </Button>
            )}
          </div>
        </div>
      </Modal>
    );
  }
}
