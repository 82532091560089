import React, { Component } from 'react';
import { Button, Modal, Spin, Typography } from 'antd';
import { __, T } from 'config/i18n';
import { AppForm, View } from 'components';
import theme from 'theme/theme';
import AppStore from '../../AppStore';
import Orders from '../../api/Orders';
import { Order } from '../../api/types';
import { showNotification } from '../../helpers/Notifications';

interface Props {
  visible: boolean;
  orderId: string;
  onConfirm: () => void;
  onCancel: () => void;
}

const { Title } = Typography;

interface State {
  attributes?: any,
  order?: Order,
  hasPushRest: boolean
  loading: boolean
}


export default class ModalModifyAttributes extends Component<Props, State> {

  state: State = {
    hasPushRest: false,
    loading: true
  };

  private propertiesToChange: string[] = [];


  componentDidMount = async () => {
  };

  componentDidUpdate = async () => {
    if (!!this.props.orderId
      && this.props.visible && (!this.state.order || !this.state.order.id || this.props.orderId !== this.state.order.id)) {
      // La prop è cambiata, esegui il tuo metodo qui
      let order: Order;
      order = await Orders.get(this.props.orderId);
      this.setState({
        attributes: this.mergeMapsWithoutDuplicates(order.originalAttributes, order.attributes),
        order: order,
        hasPushRest: false,
        loading: false
      });
    }
  };


  getSchema = () => {
    const schemaTmp = JSON.parse(AppStore.appSettings.enquireAttributesEditJsonSchema.replaceAll('\'', '"'));
    if (schemaTmp) {
      schemaTmp.title = undefined;
    }
    return this.duplicatePropertiesWithXPrefix(schemaTmp);

  };

  mergeMapsWithoutDuplicates(originalAttributes, attributes): Record<string, string> {
    const mergedMap: Record<string, string> = { ...attributes };

    for (const key in originalAttributes) {
        mergedMap['_' + key] = originalAttributes[key];
    }
    return mergedMap;
  }


  duplicatePropertiesWithXPrefix(schema): any {
    const newProperties: {} = {};
    this.propertiesToChange = [];

    for (const propertyName in schema.properties) {
      if (Object.prototype.hasOwnProperty.call(schema.properties, propertyName)) {
        const property = schema.properties[propertyName];
        const newXPropertyName = `_${propertyName}`;
        this.propertiesToChange.push(propertyName);
        newProperties[newXPropertyName] = {
          ...property,
          name: newXPropertyName,
          readOnly: true
        };
        newProperties[propertyName] = { ...property, title: ' ' };
      }
    }

    return {
      ...schema,
      properties: newProperties
    };
  }



  onReset = () => {
    if (!!this.state.order) {
      this.setState({
        attributes:
          this.mergeMapsWithoutDuplicates(this.state.order.originalAttributes, this.state.order.originalAttributes),
        hasPushRest: true
      });
    }
  };

  onConfirm = async () => {
    this.setState({loading: true});
    const filteredMap: Record<string, any> = {};
    for (const key in this.state.attributes) {
      if (!key.startsWith('_')) {
        if (this.state.attributes[key] !== this.state.attributes['_' + key]) {
          filteredMap[key] = this.state.attributes[key];
        } else if (this.state.hasPushRest &&
          this.propertiesToChange.includes(key) &&
          this.state.order?.attributes![key] !== this.state.attributes[key]) {
          filteredMap[key] = this.state.attributes[key];
        }
      }
    }

    await Orders.updateAttributes(this.state.order?.id, filteredMap);
    showNotification(
      __(T.titles.success),
      __(T.messages.orders_saved),
      'success',
      3
    );
    this.setState({ attributes: undefined, hasPushRest: false, order: undefined, loading: false});
    this.props.onConfirm();
  };

  private onCancel() {
    this.setState({ attributes: undefined, hasPushRest: false, order: undefined});
    this.props.onCancel();
  }
  render() {
    if (!this.props.visible || !this.state.order) {
      return <></>;
    }

    return (
      <Modal centered visible={this.props.visible} closable={false} footer={null} onCancel={() => this.props.onCancel}>
        <Title style={{ textAlign: 'center' }} level={4}>
          {__(T.misc.edit_attributes)}
        </Title>
        <Spin spinning={this.state.loading}>
        <AppForm
          data={this.state.attributes}
          schema={this.getSchema()}
          onChange={attributes => {
            Object.keys(attributes).forEach(key => {
              if (!key.includes('_', 0)) {
                this.state.attributes![key] = attributes[key] !== undefined ? attributes[key] : null;
              }
            });
            this.setState({ attributes: this.state.attributes });
          }} />

        <View center style={{ marginTop: 25 }}>
          <Button
            style={{ ...{ fontWeight: 700 }, ...theme.temeraSecondaryDarkButton }}
            type="default"
            onClick={() => this.onReset()}
          >
            {__(T.buttons.reset)}
          </Button>
          <Button
            style={{ ...{ fontWeight: 700, marginLeft: 25 }, ...theme.temeraSecondaryDarkButton }}
            type="default"
            onClick={() => this.onCancel()}
          >
            {__(T.buttons.cancel)}
          </Button>
          <Button
            style={{ ...{ fontWeight: 700, marginLeft: 25 }, ...theme.temeraPrimaryGreenButton }}
            type="default"
            onClick={() => this.onConfirm()}
          >
            {__(T.buttons.confirm)}
          </Button>
        </View>
        </Spin>
      </Modal>
    );
  }

}
