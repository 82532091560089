import React from "react";
import StatisticApiTCare from "../../../api/StatisticApiTCare";
import GradientGaugePlotCard from "../cards/GradientGaugePlotCard";
import statisticApiTCare from "../../../api/StatisticApiTCare";
import {StatisticData} from "../../../api/types";
import { setOffGlobalSpinningOnHeader } from '../../../api/api';


interface Props {
  startYearMonthFilter: string | null;
  endYearMonthFilter: string | null;
  creationCompany?: string;
}

interface State {
  value: number
  isLoading: boolean
}


export default class TraceRating extends React.Component<Props, State> {
  state: State = {
    value: 0,
    isLoading: true
  }

  async componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any) {
    if (prevProps.startYearMonthFilter !== this.props.startYearMonthFilter ||
        prevProps.endYearMonthFilter !== this.props.endYearMonthFilter ||
        prevProps.creationCompany !== this.props.creationCompany) {
      await this.refreshData();
    }
  }


  async componentDidMount() {
    await this.refreshData();
  }


  async refreshData() {
    this.setState({
      value: parseInt(Number(await this.getTraceRating()) + ''),
      isLoading: false
    })
  }


  async getTraceRating(): Promise<string> {
    const res: StatisticData = await statisticApiTCare.getStatisticDataByUrl(
        'average_rating_order?groupBy=&'
        + statisticApiTCare.getDataRangeFilter(this.props.startYearMonthFilter, this.props.endYearMonthFilter)
        + StatisticApiTCare.getCreationCompanyT1Filter(this.props.creationCompany),
        setOffGlobalSpinningOnHeader());
    if (!!res[0] && !!res[0].value) {
      return res[0].value.value;
    }
    return '0';
  }


  render() {
    return (
        <GradientGaugePlotCard
            active={this.state.value}
            total={100}
            forCent={this.state.value}
            isLoading={this.state.isLoading}
            title={'Trace rating'}
            footerLabel={' Average Completed Trace'}/>
    )
  }
}




