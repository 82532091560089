import React from 'react';
import theme from "../../theme/theme";
import {__DEF_VALUE, T} from "../../config/i18n";
import {View} from "../../components";
import {Button, Layout, Result, Space, Typography} from "antd";
import {CloseCircleOutlined} from '@ant-design/icons';
import AppStore from "../../AppStore";

const {Paragraph, Text} = Typography;


const {Header} = Layout

const logout = async () => {
  await AppStore.setGoToDashboard(true);
  sessionStorage.clear();
  AppStore.keyCloak?.logout();
};

const UserNotFound: React.FC = () => {
  return (
      <Layout style={{backgroundColor: '#FFFFFF'}}>
        <Header style={{
          backgroundColor: theme.bgColor,
          padding: 10,
          display: 'inline-flex',
          alignItems: 'center'
        }}>
          {/*<img src={require('assets/images/Create Trace.svg')} alt={__(T.misc.logo)}*/}
          {/*     style={c.logo}/>*/}
          <View style={{
            display: 'flex',
            flex: 1,
            justifyContent: 'flex-end',
            alignItems: 'center',
            color: '#FFF'
          }}>
            {/* <QuestionCircleOutlined onClick={() => window.open('https://www.temera.it/it/solutions', '_blank')} /> */}
          </View>
        </Header>

        <Result
            status="error"
            title={__DEF_VALUE(T.user_not_found.login_failed, 'Login failed')}
            subTitle={__DEF_VALUE(T.user_not_found.user_does_not_exist, 'The system couldn\'t find your user.')}
        >
          <div className="desc">
            <Paragraph>
              <Text
                  strong
                  style={{
                    fontSize: 16,
                  }}
              >
                {__DEF_VALUE(T.user_not_found.user_not_found_title, 'Authentication can fail because of several reasons:')}
              </Text>
            </Paragraph>
            <Paragraph>
              <CloseCircleOutlined className="site-result-demo-error-icon"/>
              {__DEF_VALUE(T.user_not_found.user_not_found_reason1, 'The username is not correctly activated')}
            </Paragraph>
            <Paragraph>
              <CloseCircleOutlined className="site-result-demo-error-icon"/>
              {__DEF_VALUE(T.user_not_found.user_not_found_reason2, 'The credentials are not ready in the system')}

            </Paragraph>

            <Paragraph>
              <Text
                  strong
                  style={{
                    fontSize: 16,
                  }}
              >
                {__DEF_VALUE(T.user_not_found.user_not_found_contact_admin,'Please contact your system’s administrator.')}
              </Text>


            </Paragraph>


          </div>
        </Result>
        <div style={{textAlign: 'center'}}>
          <Space>
            <Button danger onClick={logout}>
              {__DEF_VALUE(T.buttons.exit,'Exit')}
            </Button>
          </Space>
        </div>


      </Layout>
  );
};


export default UserNotFound;
