import React, {Component} from 'react';
import {Modal, Button} from 'antd';
import {__, T} from 'config/i18n';
import Title from "antd/es/typography/Title";
import {DownloadOutlined, MailOutlined} from '@ant-design/icons';
import {getUserRole, isSupplier} from "../../helpers/permission";
import AppStore from "../../AppStore";
import filemanager from "../../api/Files";
import {ModalDownloadConfig, ModalDownloadData} from "../../api/types";

interface Props {
    visible: boolean;
    config: ModalDownloadConfig[],
    data: ModalDownloadData[]
    onClose: () => void
    onDownloadGenerated: (downloadConfig: ModalDownloadConfig, data: ModalDownloadData) => void,
    sendMailGenerated: () => void
    getDownloadGenerateFileName: (downloadConfig: ModalDownloadConfig, data: ModalDownloadData) => Promise<string>
    getDownloadGeneratedBase64: (downloadConfig: ModalDownloadConfig, data: ModalDownloadData) => Promise<string | undefined>
    sendMail: (base64: string) => void
    sendMailFileId: (fileObject: unknown) => void
}

interface State {
    filenames: any
}

export default class ModalDownloads extends Component<Props, State> {

    state: State = {
        filenames: {}
    };
    handleDownload = (config: ModalDownloadConfig, data: ModalDownloadData): void => {
        console.log('download: ', config)
        if (config.type === 'GENERATE') {
            console.log('TODO: GENERATE')
        } else {
            const pdfFile = this.getDataValue(config, data)
            window.open(pdfFile, '_blank', 'noreferrer');
        }
    };

    componentDidMount() {
        this.refreshFilenames();
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any) {
        if (JSON.stringify(prevProps.data) !== JSON.stringify(this.props.data)) {
            this.refreshFilenames();
        }
    }

    refreshFilenames = async () => {
        let filenames = {}
        for (let config of this.props.config) {
            for (let data of this.props.data) {
                let fileName = await this.getFileName(config, data);
                filenames[config.label + '-' + data.id] = !!fileName ? fileName : 'N/A'
            }
        }
        this.setState({filenames})
    }

    getDataValue = (config: ModalDownloadConfig, data: ModalDownloadData): string => {
        let paths = config.tracePath!.split(".")
        let currentValue = data.payload.data;
        //TODO: gestione file in array
        for (let path of paths) {
            currentValue = currentValue[path]
        }
        return currentValue;
    };

    getSendEmailRenderBase64 = (base64: string): any => {
        return (
            <Button
                style={{fontWeight: 700, border: 'none', display: 'flex', alignItems: 'center', marginRight: '6px'}}

                type="primary"
                onClick={() => this.props.sendMail(base64)}
                target="_blank"
            >
                <MailOutlined
                    style={{
                        height: '15px',
                        justifyContent: 'center',
                        display: 'flex'
                    }}
                    alt={__(T.buttons.send_by_email_pdf)}/>
            </Button>
        );

    };

    getSendEmailRenderFileId = (value: string): any  => {
        const urlPath = value.split(",")
        const fileId = urlPath[1];


        return (
            <Button
                style={{fontWeight: 700, border: 'none', display: 'flex', alignItems: 'center', marginRight: '6px'}}

                type="primary"
                onClick={() => this.props.sendMailFileId(fileId)}
                target="_blank"
            >
                <MailOutlined
                    style={{
                        height: '15px',
                        justifyContent: 'center',
                        display: 'flex'
                    }}
                    alt={__(T.buttons.send_by_email_pdf)}/>
            </Button>
        );

    };

    sendMailGenerated = (): any => {
        return (
            <Button
                style={{fontWeight: 700, border: 'none', display: 'flex', alignItems: 'center', marginRight: '6px'}}

                type="primary"
                onClick={() => this.props.sendMailGenerated()}
                target="_blank"
            >
                <MailOutlined
                    style={{
                        height: '15px',
                        justifyContent: 'center',
                        display: 'flex'
                    }}
                    alt={__(T.buttons.send_by_email_pdf)}/>
            </Button>
        );

    };


    getBaseUrl = (): string => {
        const baseUrl = window.location.origin;
        return baseUrl;
    };
    openInPage = async (config: ModalDownloadConfig, data: ModalDownloadData): Promise<void> => {
        if (config.type === 'TRACE_FILE') {
            //file da traccia
            const value = this.getDataValue(config, data);
            if (value != null) {
                if (this.isUrlBase64(value)) {
                    const base64Data = value.split(',')[1];
                    this.openNewTabWithPDF(base64Data)
                } else {
                    const urlPath = value.split(",")
                    const fileId = urlPath[1];
                    const file: any = await filemanager.downloadBase64(fileId)
                    this.openNewTabWithPDF(file.base64)
                }
            }
        } else {
            //file generato
            const base64 = await this.props.getDownloadGeneratedBase64(config, data)
            if (base64) {
                this.openNewTabWithPDF(base64)
            } else {
                console.error('file not found')
            }
        }
    }

    openNewTabWithPDF = (base64Data: string) => {

// Crea un oggetto Blob dal base64 del PDF
        const byteCharacters = atob(base64Data);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], {type: 'application/pdf'});

// Crea un URL oggetto per il Blob
        const blobUrl = URL.createObjectURL(blob);

// Apri un nuovo tab con l'URL del Blob
        window.open(blobUrl, '_blank');

        URL.revokeObjectURL(blobUrl);
    };

    getFileName = async (config: ModalDownloadConfig, data: ModalDownloadData) => {
        if (config.type === "GENERATE") {
            return await this.props.getDownloadGenerateFileName(config, data) ?? 'N/A'
        } else if (config.type === 'TRACE_FILE') {
            let url = this.getDataValue(config, data);

            if (!url) {
                return null
            }

            if (this.isUrlBase64(url)) {
                //base64
                //data:application/pdf;name=ATTEST_sup-tenant_01_1.pdf;base64,JVBERi0xLjQKJaqrrK0KMS
                return url.substring(url.indexOf(";name=") + 6, url.indexOf(";base64,"))
            } else {
                //file
                //data:application/pdf;name=ATTEST_sup-tenant_01_1.pdf;fileId,202401251124-c08a803a-438d-4031-a4be-88f84418d02d
                return url.substring(url.indexOf(";name=") + 6, url.indexOf(";fileId,"))
            }
        }
    };

    isUrlBase64 = (url: string | undefined) => {
        if (!url) {
            return true;
        }

        // Verifica se la stringa sembra essere una stringa Base64 valida
        const base64Regex = /^data:([^;]+);name=([^;]+);base64,([a-zA-Z0-9+/]+={0,2})$/;
        return base64Regex.test(url);
    };

    render() {
        const {visible} = this.props;
        let configs = (this.props.data && this.props.config) ? this.props.config
                .filter(downloadConfig => downloadConfig.roles ? downloadConfig.roles.includes(getUserRole()!) : true)
                .filter(downloadConfig =>
                    !isSupplier()
                    || !downloadConfig.enquiry
                    || !downloadConfig.categories
                    || (
                        AppStore.loggedUser?.company!.categories &&
                        AppStore.loggedUser?.company!.categories!.filter(cat => downloadConfig.categories!.indexOf(cat) >= 0).length > 0))
                .map(config => {
                    return {...config, order: config.order ?? 1}
                })
                .sort((a, b) =>
                    a.order > b.order ? 1 : -1)

            : undefined;
        return (
            <Modal centered visible={visible} closable={true} footer={null} onCancel={() => this.props.onClose()}
                   width={'600px'}>
                <Title style={{textAlign: 'center'}} level={4}>
                    {__('modal.downloads.title')}
                </Title>
                {!!configs && configs.length > 0 && !!this.props.data &&
                    this.props.data.map(data => {
                        const filteredConfigs = configs!
                            .filter(downloadConfig =>
                                !downloadConfig.enquiry
                                || !downloadConfig.enquiry.statuses
                                || downloadConfig.enquiry.statuses.indexOf(data.payload.status) >= 0
                            )
                            .filter(downloadConfig =>
                                !downloadConfig.enquiry
                                || !downloadConfig.enquiry.traceTypes
                                || downloadConfig.enquiry.traceTypes.indexOf(data.payload.step) >= 0
                            );
                        return filteredConfigs.length > 0 ? <div key={data.id}>
                                <div
                                    style={{
                                        width: '100%', textAlign: 'center', fontStyle: 'italic',
                                        marginTop: '30px'
                                    }}>{__(data.label)}</div>
                                {
                                    filteredConfigs!
                                        .filter(downloadConfig =>
                                            !downloadConfig.enquiry
                                            || !downloadConfig.enquiry.statuses
                                            || downloadConfig.enquiry.statuses.indexOf(data.payload.status) >= 0
                                        )
                                        .filter(downloadConfig =>
                                            !downloadConfig.enquiry
                                            || !downloadConfig.enquiry.traceTypes
                                            || downloadConfig.enquiry.traceTypes.indexOf(data.payload.step) >= 0
                                        )
                                        .map(downloadConfig => {
                                            return <div style={{marginBottom: '10px', marginTop: '10px'}}
                                                        key={data.id + '-' + downloadConfig.label}>
                                                <div style={{display: 'flex', flexDirection: 'row'}}
                                                     key={downloadConfig.tracePath}>
                                                    <div style={{
                                                        textAlign: 'left',
                                                        flexGrow: '1'
                                                    }}>{__(downloadConfig.label)}
                                                        <div
                                                            style={{
                                                                width: '385px',
                                                                whiteSpace: 'nowrap',
                                                                overflow: 'hidden',
                                                                textOverflow: 'ellipsis'
                                                            }}
                                                        ><b>{
                                                            this.state.filenames[downloadConfig.label + '-' + data.id]
                                                        }</b></div>
                                                    </div>
                                                    <div style={{
                                                        display: "flex",
                                                        flexDirection: "row",
                                                        alignItems: "center"
                                                    }}>
                                                        {downloadConfig.type === 'GENERATE' && <>
                                                            {
                                                                downloadConfig.sendMail &&
                                                                // TODO invio mail file generato
                                                                this.sendMailGenerated()
                                                            }
                                                            <Button
                                                                style={{fontWeight: 700, marginRight: '6px'}}
                                                                type="primary"
                                                                onClick={() => this.openInPage(downloadConfig, data)}
                                                            >
                                                                <img style={{
                                                                    height: '15px',
                                                                    justifyContent: 'center',
                                                                    display: 'flex'
                                                                }}
                                                                     src={require('assets/images/View_black.svg').default}
                                                                     alt={__(T.buttons.view)}/>
                                                            </Button>
                                                            <Button
                                                                style={{fontWeight: 700}}
                                                                type="primary"
                                                                onClick={() => {
                                                                    this.props.onDownloadGenerated(downloadConfig, data);
                                                                }}
                                                            >
                                                                <DownloadOutlined
                                                                    alt={__(T.buttons.download_pdf)}/>
                                                            </Button>
                                                        </>

                                                        }

                                                        {downloadConfig.type === 'TRACE_FILE' && !!this.getDataValue(downloadConfig, data) && this.isUrlBase64(this.getDataValue(downloadConfig, data)) && !!this.state.filenames[downloadConfig.label + '-' + data.id] &&
                                                            <>
                                                                {downloadConfig.sendMail &&
                                                                    this.getSendEmailRenderBase64(data.payload.data[downloadConfig.tracePath!])}
                                                                <Button
                                                                    style={{fontWeight: 700, marginRight: '6px'}}
                                                                    type="primary"
                                                                    onClick={() => this.openInPage(downloadConfig, data)}
                                                                >
                                                                    <img style={{
                                                                        height: '15px',
                                                                        justifyContent: 'center',
                                                                        display: 'flex'
                                                                    }}
                                                                         src={require('assets/images/View_black.svg').default}
                                                                         alt={__(T.buttons.view)}/>
                                                                </Button>
                                                                <Button
                                                                    style={{fontWeight: 700}}
                                                                    type="primary"
                                                                    href={this.getDataValue(downloadConfig, data)}
                                                                    target="_blank"
                                                                    download={this.state.filenames[downloadConfig.label + '-' + data.id]}
                                                                >
                                                                    <DownloadOutlined
                                                                        alt={__(T.buttons.download_pdf)}/>
                                                                </Button>
                                                            </>}

                                                        {downloadConfig.type === 'TRACE_FILE' && !!this.getDataValue(downloadConfig, data) && !this.isUrlBase64(this.getDataValue(downloadConfig, data)) && !!this.state.filenames[downloadConfig.label + '-' + data.id] &&
                                                            <>
                                                                {downloadConfig.sendMail &&
                                                                    this.getSendEmailRenderFileId(data.payload.data[downloadConfig.tracePath!])}
                                                                <Button
                                                                    style={{fontWeight: 700, marginRight: '6px'}}
                                                                    type="primary"
                                                                    onClick={() => this.openInPage(downloadConfig, data)}
                                                                >
                                                                    <img style={{
                                                                        height: '15px',
                                                                        justifyContent: 'center',
                                                                        display: 'flex'
                                                                    }}
                                                                         src={require('assets/images/View_black.svg').default}
                                                                         alt={__(T.buttons.view)}/>
                                                                </Button>
                                                                <Button
                                                                    style={{fontWeight: 700}}
                                                                    type="primary"
                                                                    onClick={() => filemanager.downloadFileByTraceUrl(this.getDataValue(downloadConfig, data))}
                                                                    target="_blank"
                                                                    download={this.state.filenames[downloadConfig.label + '-' + data.id]}
                                                                >
                                                                    <DownloadOutlined
                                                                        alt={__(T.buttons.download_pdf)}/>
                                                                </Button>
                                                            </>}

                                                    </div>
                                                </div>
                                                <div
                                                    style={{
                                                        width: "90%",
                                                        borderBottom: "1px solid #ececec",
                                                        marginTop: "10px",
                                                        marginLeft: 'auto',
                                                        marginRight: 'auto'
                                                    }}/>
                                            </div>
                                        })}
                            </div> :
                            <>
                                <div
                                    style={{
                                        width: '100%',
                                        textAlign: 'center',
                                        fontStyle: 'italic',
                                        marginTop: '30px'
                                    }}>{__(data.label)}</div>
                                <label>{__('modal.downloads.empty')}</label>
                            </>
                    })
                }
            </Modal>
        );
    }
}
