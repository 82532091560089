import React from 'react';
import {
  Order,
  Page,
  ReportOrder
} from '../../api/types';
import Orders from '../../api/Orders';

import { Button, Dropdown, Menu, Space } from 'antd';
import { Layout } from 'components';
import { __, T } from 'config/i18n';
import AppStore from '../../AppStore';
import { navigate } from '../../router';
import { Props } from '../../components/layouts/CompanyFormLayout';
import { ColumnsType } from 'antd/es/table';
import { buildColumnsByConfiguration } from 'components/orders/ColumnBuilderUtil';
import { buildFiltersByConfiguration } from 'components/orders/FilterBuilderUtil';
import { DownOutlined } from '@ant-design/icons';
import FilteredTable from '../../components/FilteredTable';

const ORDER_REPORT_SESSION_STORAGE = 'customerOrderReportSessionStorage';

const INITIAL_STATE: State = {
  componentMount: false,
  lastParams: {},
  askToUpdateData: 1
};

interface State {
  activeTabKey?: string
  componentMount: boolean
  lastParams: any
  askToUpdateData: number
}

export default class CustomerOrdersReport extends React.Component<Props, State> {

  state: State = { ...INITIAL_STATE };

  componentDidMount = async () => {
    this.setState({ componentMount: true });
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.props['location']['search'] !== prevProps.location.search) {
      // this.onRouteChanged();
    }
  }

  refreshData(){
    this.setState({
      askToUpdateData: this.state.askToUpdateData + 1
    });
  }

  timeoutStarted: boolean = false;

  getColumns = (): ColumnsType<ReportOrder> => {
    const columns: any = buildColumnsByConfiguration(AppStore.appSettings.orderListCustomerColumns);

    columns.push({
      title: __(T.fields.actions),
      key: 'col_action',
      align: 'left',
      fixed: 'right',
      width: 1,
      render: (text, record: Order) => {
        return (<>
          <Dropdown.Button overlay={

            <Menu>
              <Menu.Item
                  onClick={() => navigate('/reports/customerorders/detail/:orderId', { orderId: record.id })}
              >
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <img style={{ height: '20px', justifyContent: 'center', display: 'flex' }}
                       src={require('assets/images/View.svg').default}
                       alt={__(T.buttons.view)}
                  />
                  <a style={{ minWidth: '150px', justifyContent: 'center', display: 'flex' }}>
                    {__(T.buttons.view)}
                  </a>
                </div>
              </Menu.Item>
            </Menu>
          }>
          </Dropdown.Button>
        </>);
      }
    });
    return columns;
  };

  addExtraParams(params: any) {
    params['status'] = ['COMPLETED', 'APPROVED'];
    params['rootOrder'] = true;
  }

  onSearch = async (params: any, currentPage: number, sortField: string, sortDirection: 'asc' | 'desc', size: number): Promise<Page<ReportOrder>> => {
    this.addExtraParams(params);

    this.setState({ lastParams: params });

    let orderPage = await Orders.search({
      page: currentPage,
      size: 10,
      ...params,
      sort: sortField + ',' + sortDirection
    });
    const companies = await Orders.getCompanies(orderPage);
    orderPage.content = orderPage.content.map(order => ({
      ...order,
      assigneeCompanyName: companies[order.assigneeCompany],
      creationCompanyName: companies[order.creationCompany]
    }));
    return orderPage;
  };

  getTabBarExtraContent = (activeTab?: string): JSX.Element[] => {
    const buttons: JSX.Element[] = [];

    if (AppStore.appSettings.enableXlsExport) {
      buttons.push(
        <Dropdown overlay={<Menu items={[
          {
            label: `${__(T.buttons.export_xls)}`,
            key: 'enquiriesXlsExport',
            onClick: this.handleXlsExport,
            style: { display: (AppStore.appSettings.enableXlsExport) ? 'block' : 'none' }
          },
        ]} />}>
          <Button>
            <Space>
              {__(T.buttons.export)}
              <DownOutlined />
            </Space>
          </Button>
        </Dropdown>
      );
    }
    return buttons;
  };

  handleXlsExport = () => {
    Orders.downloadXls({
      ...this.state.lastParams,
      page: 0,
      size: 0
    });
  };

  render = () => {
    return (
        <Layout key={'orderReportView'}>
          {!!this.state.componentMount && <FilteredTable<ReportOrder>
              onSearchElements={this.onSearch}
              getColumnsConfig={this.getColumns}
              getExtraButtons={this.getTabBarExtraContent}
              askToUpdateData={this.state.askToUpdateData}
              getFiltersConfig={() => buildFiltersByConfiguration(AppStore.appSettings.orderListCustomerFilters)}
              storeKey={ORDER_REPORT_SESSION_STORAGE}
          />
          }
        </Layout>
    );
  };


}
