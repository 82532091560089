import React from 'react'
import {Layout, Modal, View} from 'components'
import {__, T} from 'config/i18n'
import {Button, Col, Form, Row, Spin} from 'antd'
import {navigate} from 'router'
import theme from 'theme/theme'
import {OnBoardings} from 'api'
import {showNotification} from 'helpers/Notifications'
import CreateOnboardingForm, {CreateOnboardingFormFields} from './CreateOnboardingForm'
import {FormInstance} from "antd/es/form";

interface Props {}

interface State {
  confirmModalVisible: boolean
  warningModalVisible: boolean
  formFields: CreateOnboardingFormFields
  loading: boolean
}

export default class CreateOnboarding extends React.Component<Props, State> {
  state: State = {
    confirmModalVisible: false,
    warningModalVisible: false,
    formFields: { companyType: 'supplier' },
    loading: false,
  }

  private formRef: React.RefObject<FormInstance>;

  constructor(props: Props) {
    super(props);
    this.formRef = React.createRef<FormInstance>()
  }

  createOnboarding = async () => {
    const {formFields} = this.state
    this.setState({confirmModalVisible: false})

    const showWarningBeforeCreate = await OnBoardings.showWarningBeforeCreate(formFields.partitaIva!)

    if(showWarningBeforeCreate){
      this.setState({warningModalVisible: true})
    } else {
      await this.doCreateOnBoarding()
    }
  }

  private doCreateOnBoarding = async () => {
    const {formFields} = this.state

    this.setState({warningModalVisible: false, loading: true})

    if (!formFields.companyType) {
      formFields.companyType = 'supplier'
    }
    const onboarding = await OnBoardings.save({
      user: {
        firstName: formFields.name,
        lastName: formFields.surname,
        email: formFields.email,
        phone: formFields.phone,
      },
      businessName: formFields.businessName,
      partitaIva: formFields.partitaIva,
      companyCode: formFields.companyCode,
      companyType: formFields.companyType,
      categories: formFields.companyType === 'supplier' ? formFields.categories : undefined,
    })

    if (!onboarding.state && onboarding.message && onboarding.message.toLowerCase().indexOf(('Entity Company already exists with ref').toLowerCase()) >= 0) {
      showNotification(__(T.messages.onboarding_company_already_exist), undefined, 'error')
    } else if (!onboarding.state && onboarding.message && onboarding.message.toLowerCase().indexOf(('Entity User already exists with ref').toLowerCase()) >= 0) {
      showNotification(__(T.messages.onboarding_user_already_exist), undefined, 'error')
    } else if (!onboarding.state && onboarding.message && onboarding.message.toLowerCase().indexOf(('Business Name already exist').toLowerCase()) >= 0) {
      showNotification(__(T.messages.onboarding_business_name_already_exist_on_create), undefined, 'error')
    } else if (onboarding.errorCode >= 400) {
      showNotification(onboarding.status, undefined, 'error')
    } else if (!!onboarding.id) {
      showNotification(__(T.messages.invitation_successfully_created))
      navigate('/reports/onboarding')
    }
    this.setState({loading: false})
  }

  onValuesChange = (changedValues: Partial<CreateOnboardingFormFields>, values: CreateOnboardingFormFields) => {
    this.setState({
      formFields: { ...values, categories: changedValues.companyType ? undefined : values.categories },
    })
  }

  render() {
    const { loading, formFields } = this.state
    return (
      <Layout>
        <Spin spinning={loading} delay={500}>
          <View style={{ padding: 20 }}>
            <View className="page-table">
              <Col span={10}>
                <CreateOnboardingForm
                  formFields={formFields}
                  onValuesChange={this.onValuesChange}
                  formRef={this.formRef}
                />
                <Row gutter={24} justify={'start'}>
                      <Col span={12} >
                        <Form.Item style={{ marginBottom: 0 }}>
                          <Button
                              type="default"
                              htmlType="button"
                              style={{ ...{ width: '100%' }, ...theme.temeraSecondaryDarkButton }}
                              onClick={() => {
                                navigate('/reports/onboarding')
                              }}
                          >
                            {__(T.buttons.cancel)}
                          </Button>
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item style={{ marginBottom: 0 }}>
                          <Button
                              type="primary"
                              htmlType="button"
                              style={{ ...{ width: '100%' }, ...theme.temeraPrimaryGreenButton }}
                              onClick={() => {
                                this.formRef.current?.validateFields().then(
                                    () => this.setState({ confirmModalVisible: true })
                                )
                              }}
                          >
                            {__(T.buttons.invite_new)}
                          </Button>
                        </Form.Item>
                      </Col>
                </Row>
              </Col>
            </View>
          </View>
        </Spin>
        <Modal
            visible={this.state.confirmModalVisible}
            title={__(T.confirms.invite_company)}
            message={__(T.messages.confirm_or_go_back)}
            closeButtonTitle={__(T.buttons.back)}
            confirmButtonTitle={__(T.buttons.invite)}
            onCloseClick={() => {
              this.setState({ confirmModalVisible: false })
            }}
            onConfirmClick={this.createOnboarding}
        />
        <Modal
            visible={this.state.warningModalVisible}
            title={__(T.confirms.warning_on_company_create)}
            message={__(T.messages.confirm_or_go_back)}
            closeButtonTitle={__(T.buttons.back)}
            confirmButtonTitle={__(T.buttons.confirm)}
            onCloseClick={() => {
              this.setState({ warningModalVisible: false })
            }}
            onConfirmClick={this.doCreateOnBoarding}
        />
      </Layout>
    )
  }
}
