import React, {createRef, RefObject} from 'react'
import {Layout, View} from '../../components'
import {Button, Modal} from 'antd'
import Title from 'antd/es/typography/Title'
import {__, T} from '../../config/i18n'
import {
    Company,
    DetailedOrder,
    OrderAssignments,
    SubOrder,
    SubOrderAssignment,
    SubOrderMultiAssignment
} from '../../api/types'
import {FormInstance} from 'antd/es/form'
import {getQueryParams, navigate} from '../../router'
import {showNotification} from '../../helpers/Notifications'
import {Companies} from '../../api'
import moment from 'moment'
import CreateOnboardingModal from '../onboarding/CreateOnboardingModal'
import OrderMultiPageHeader from "../../components/orders/OrderMultiPageHeader";
import Orders from "../../api/Orders";
import OrderMultiAssignmentBox from "../../components/orders/OrderMultiAssignmentBox";
import {resolveReportOrdersBasePath} from "../../routes";

interface Props {
  match: { params: { type?: string } };
}

interface State {
  orderMultiAssignItemSelected?: OrderMultiAssignItem
  companyOnboardingModalVisible: boolean
  assignmentsMap: Map<string, Map<string, OrderMultiAssignItem>>
  nrOrders: number
}

export interface OrderMultiAssignItem {
  company?: Company;
  parentFormRef: RefObject<FormInstance>
  category: string;
  assignments: SubOrderMultiAssignment[];
}

export default class OrderMultiAssign extends React.Component<Props, State> {

  state: State = {
    nrOrders: 0,
    companyOnboardingModalVisible: false,
    assignmentsMap: new Map<string, Map<string, OrderMultiAssignItem>>()
  }

  getOrders = async (orderIds: string | string[] ): Promise<DetailedOrder[]> => {
    orderIds = Array.isArray(orderIds) ? orderIds : [orderIds]
    const orders: DetailedOrder[] = []

    for (let orderId of orderIds){
      const order: DetailedOrder | undefined = await Orders.get(orderId)
      if (order !== undefined) {
        orders.push(order)
      }else{
        console.warn("The order with id: " + orderId + " not found" )
      }
    }
    return orders;
  }

  getComposant = (assignmentsMap: Map<string, Map<string, OrderMultiAssignItem>>, composant: string): Map<string, OrderMultiAssignItem> => {
    if (!assignmentsMap.get(composant)) {
      let orderMultiAssignMap = new Map<string, OrderMultiAssignItem>()
      assignmentsMap.set(composant, orderMultiAssignMap)
      return orderMultiAssignMap
    }

    return assignmentsMap.get(composant)!;
  }

  getProductComposition = (assignments: Map<string, OrderMultiAssignItem>, productComposition: string, assignment: SubOrderAssignment, category: string): OrderMultiAssignItem => {
    if (!assignments.get(productComposition)) {
      let newAssignment:OrderMultiAssignItem = {
            company: assignment.company,
            category: category,
            assignments: [],
            parentFormRef: createRef<FormInstance>()
          };
      assignments.set(productComposition, newAssignment)
      return newAssignment
    }

    return assignments.get(productComposition)!;
  }

  componentDidMount = async () => {
    const {assignmentsMap} = this.state
    const orderIds : string | string [] = getQueryParams().ids
    const orders:DetailedOrder[] = await this.getOrders(orderIds)
    const nrOrders = orders.length;

    for (let i = 0; i < (orders && orders.length); i++) {
      const order = orders[i]
      const companies = await this.getCompanies(order?.orders ?? [])
      order?.orders.forEach(subOrder => {
        const composant = subOrder.materialGroup ?? '__NO_COMPOSANT__';
        const newComposant = this.getComposant(assignmentsMap, composant);
        const productComposition = subOrder.productComposition;
        const assignment:SubOrderMultiAssignment = {
          orderId: subOrder.id,
          company: subOrder.ownerCompany ? companies[subOrder.ownerCompany] : undefined,
          orderCode: subOrder.code,
          orderDate: subOrder.orderDate ? moment(subOrder.orderDate) : moment(),
          parentOrderId: order.id,
          parentOrderCode: order.code,
          parentFormRef: createRef<FormInstance>(),
          originalOrderId: subOrder.originalOrderId
        }
        const newProductComposition = this.getProductComposition(newComposant, productComposition, assignment, subOrder.category);
        newProductComposition.assignments.push(assignment);
      })
    }
    this.setState({ assignmentsMap, nrOrders })
    console.info(orders.length +  " orders. assignmentsMap", assignmentsMap)

  }

  getCompanies = async (subOrders: SubOrder[]): Promise<{ [companyId: string]: Company }> => {
    const companyIds = this.getCompanyIds(subOrders)
    return Companies.search({
      page: 0,
      size: companyIds.length,
      ids: companyIds,
    })
      .then(companiesResponse => companiesResponse?.content ?? [])
      .then(companies =>
        companies.reduce((_companies, company) => {
          _companies[company.id] = company
          return _companies
        }, {})
      )
  }

  getCompanyIds = (subOrders: SubOrder[]) =>
    Array.from(new Set(subOrders.map(subOrder => subOrder.ownerCompany).filter(ownerCompany => ownerCompany)))

  getPageHeaderExtras = () => [
    <Button
      type="ghost"
      style={{ width: '200px', backgroundColor: __(T.colors.gray_button) }}
      onClick={() => this.handleAssignmentsSubmit(true)}
    >
      {__(T.buttons.save_draft)}
    </Button>,
    <Button type="primary" style={{ width: '200px' }} onClick={() => this.handleAssignmentsSubmit(false)}>
      {__(T.buttons.assign_now)}
    </Button>,
  ]

  handleSupplierChange = async (orderMultiAssignItem: OrderMultiAssignItem, changedValues, values) => {
    const changedValueKeys = Object.keys(changedValues)
    if (changedValueKeys.length > 0) {
      const {assignmentsMap} = this.state
      try {
        const companyId = changedValues.company ? JSON.parse(changedValues.company.value)?.id ?? undefined : undefined
        const company = await Companies.get(companyId)
        orderMultiAssignItem.company = company
      } catch (_e) {
      }
      this.setState({assignmentsMap})
      console.info("new map after handleSupplierChange", assignmentsMap)
    }
  }

  handleAssignmentsValuesChange = (subOrderAssignment: SubOrderAssignment, changedValues, values) => {
    const changedValueKeys = Object.keys(changedValues)
    if (changedValueKeys.length > 0) {
      const {assignmentsMap} = this.state
      if (changedValueKeys.includes('orderCode')) {
        subOrderAssignment.orderCode = changedValues.orderCode
      }
      if (changedValueKeys.includes('orderDate')) {
        subOrderAssignment.orderDate = changedValues.orderDate ? changedValues.orderDate._d : undefined
      }
      this.setState({assignmentsMap})
      console.info("new map after handleValuesChange", assignmentsMap)
    }
  }

  doDeleteAssignment = (delSubOrderAssignment: SubOrderMultiAssignment) => {
    const {assignmentsMap} = this.state
    assignmentsMap.forEach((orderMultiAssignMap: Map<string, OrderMultiAssignItem>, composant: string) => {
      orderMultiAssignMap.forEach((orderMultiAssignItem: OrderMultiAssignItem, productComposition: string) => {
        orderMultiAssignItem.assignments = orderMultiAssignItem.assignments.filter(item => item.parentOrderId !== delSubOrderAssignment.parentOrderId)
        if(orderMultiAssignItem.assignments.length === 0){
          orderMultiAssignMap.delete(productComposition)
        }
      })

      if(orderMultiAssignMap.size === 0){
        assignmentsMap.delete(composant)
      }
    })

    if(assignmentsMap.size === 0) {
      this.showPopupNavigateBack()
    }

    this.setState({assignmentsMap})
    console.info("after deleting: ", delSubOrderAssignment, "new map: ", assignmentsMap)
  }

  showPopupNavigateBack = () => {
    Modal.confirm({
      title: 'No more orders to show. We will navigate to the order list',
      okText: __(T.misc.yes),
      cancelText: __(T.misc.no),
      okType: 'primary',
      onOk: () => {
        this.navigateBackToOrders()
      }
    })
  }

  handleDeleteAssignment = (delSubOrderAssignment: SubOrderMultiAssignment) => {
    Modal.confirm({
      title: __(T.confirms.delete_assignment),
      okText: __(T.misc.yes),
      cancelText: __(T.misc.no),
      okType: 'primary',
      onOk: () => {
        this.doDeleteAssignment(delSubOrderAssignment)
      }
    })
  }

  handleAssignmentsSubmit = (draft: boolean) => {
    const orderAssignments: OrderAssignments = {
        draft: draft,
        assignments: []
    }

    let assignmentFormValidations:RefObject<FormInstance>[] = []
    this.state.assignmentsMap.forEach((orderMultiAssignMap:Map<string, OrderMultiAssignItem>, composant:string) => {
      orderMultiAssignMap.forEach((orderMultiAssignItem:OrderMultiAssignItem, productComposition:string) => {
        const company = orderMultiAssignItem.company
        orderMultiAssignItem.assignments.forEach((assignment:SubOrderMultiAssignment)=> {
          assignment.company = company
          orderAssignments.assignments.push(assignment)
          assignmentFormValidations.push(assignment.parentFormRef)
        })
      })
    })

    const formValidationsResult = !draft
      ? assignmentFormValidations
        .map((formInstanceRef) => formInstanceRef.current)
        .map(formInstance => formInstance?.validateFields())
        .filter(formInstanceValidation => formInstanceValidation !== undefined)
      : []

    console.debug("formValidationsResult:", formValidationsResult)
    Promise.all(formValidationsResult).then(async () => {
      console.info("Calling Orders.multiAssign()", orderAssignments)
      Orders.multiAssign(orderAssignments).then(
        () => this.handleAssignmentSuccess(draft),
        () => this.handleAssignmentError()
      )
    })
  }

  handleAssignmentSuccess = (draft: boolean) => {
      showNotification(
        __(T.titles.order_assignment_success),
        draft ? __(T.messages.order_assignment_draft) : __(T.messages.order_assignment_success),
        'success',
        3
      )
      if (!draft) {
          this.navigateBackToOrders()
      }
  }

  handleAssignmentError = () => {
      showNotification(__(T.titles.order_assignment_error), __(T.messages.order_assignment_error), 'error', 3)
  }

  navigateBackToOrders = () => navigate(resolveReportOrdersBasePath(this.props.match.params.type), { ids:getQueryParams().ids} )

handleCompanyOnboardingModalCancel = async () => {
    this.setState({ orderMultiAssignItemSelected: undefined, companyOnboardingModalVisible: false  })
  }

  handleCompanyOnboardingModalConfirm = async (companyId?: string) => {
    const { assignmentsMap, orderMultiAssignItemSelected } = this.state
    if (orderMultiAssignItemSelected) {
      const company = companyId ? await Companies.get(companyId) : undefined
      orderMultiAssignItemSelected.company = company
    }
    this.setState({
      assignmentsMap,
      orderMultiAssignItemSelected: undefined,
      companyOnboardingModalVisible: false,
    })
    console.info("new map after handleCompanyOnboarding: ", assignmentsMap)
  }

  handleInviteCompanyClick = async (orderMultiAssignItem: OrderMultiAssignItem) => {
    this.setState({ orderMultiAssignItemSelected: orderMultiAssignItem, companyOnboardingModalVisible: true })
  }

  toArray<T>(map: { [key: string]: T }): Array<[string, T]> {
    const mapObject = new Map<string, T>()
    for (const mapKey in map) {
      mapObject.set(mapKey, map[mapKey])
    }
    return Array.from(mapObject)
  }

  render = () => {
    const {assignmentsMap, nrOrders} = this.state
    return (
      <Layout>
        <View style={{margin: 10, marginBottom: 10}}>
          <OrderMultiPageHeader
            nrOrders={nrOrders}
            extras={this.getPageHeaderExtras()}
            onBack={() => this.navigateBackToOrders()}
          />
        </View>

        <View className="page-table" style={{margin: 10, marginTop: 0, borderRadius: '0px'}}>
          <Title level={3} style={{ fontWeight: 'bold' }}>
            {__(T.misc.insertSupplierMsg)}
          </Title>

          {
            Array.from(assignmentsMap).map(([composant, orderMultiAssignMap]) => (
              <>
                {composant !== '__NO_COMPOSANT__' && <Title level={4}>{composant}</Title>}
                {
                  Array.from(orderMultiAssignMap).map(([productDescription, orderMultiAssignItem]) => (
                    <OrderMultiAssignmentBox
                      title={productDescription}
                      orderMultiAssignItem={orderMultiAssignItem}
                      onInviteCompanyClick={this.handleInviteCompanyClick}
                      onSupplierChange={this.handleSupplierChange}
                      onDeleteAssignment={this.handleDeleteAssignment}
                      onAssignmentsValuesChange = {this.handleAssignmentsValuesChange}
                    />
                  ))
                }
              </>
            ))
          }

          <CreateOnboardingModal
            visible={this.state.companyOnboardingModalVisible}
            onConfirm={this.handleCompanyOnboardingModalConfirm}
            onCancel={this.handleCompanyOnboardingModalCancel}
          />
        </View>
      </Layout>
    )
  }
}
