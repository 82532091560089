import { changeLanguage, initI18nX } from 'config/i18n';
import Storage from 'helpers/Storage';
import { KeycloakTokenParsed } from 'keycloak-js';
import { Auth } from 'api';
import { AppSettings, BaseMenuConfiguration, NotificationsAndCount, NotificationTCare, User } from 'api/types';
import ApplicationSetting from './api/ApplicationSetting';
import UserNotFoundError from './errors/UserNotFoundError';
import { getDefaultMenu, mapMenuConfiguration } from './helpers/menuHelper';


type EventType = 'onReadMessage' | 'notificationChange' | 'globalSpinningChange';


class AppStore {
  collapsedBarMenu: boolean = false;
  tokenParsed?: KeycloakTokenParsed;
  keyCloak?: Keycloak.KeycloakInstance;
  language: string = 'en';
  loggedUser?: User;
  goToDashboard?: boolean;
  userNotFound?: boolean;
  refreshCreateTracePageRequired?: boolean;
  notificationsAndCount?: NotificationsAndCount;
  globalSpin: number = 0;
  appSettings: AppSettings = {
    enableCancelSubOrders: false,
    enquiryDownloads: '',
    traceReportFields: '',
    enableBulkReject: false,
    bigLogo: 'defaultBigLogo',
    smallLogo: 'defaultSmallLogo',
    headerTitle: '',
    enableAutoComplete: false,
    enableRejectOrder: false,
    enableExpiringCertificationManagement: false,
    enableTraceManagement: false,
    enableOrderManagement: false,
    enableCompanyOnboarding: true,
    enableCompanySuppliersManagement: false,
    enableOrderDetailSmart: false,
    traceImportFieldsToExclude: '',
    categoriesT1: '',
    categories: '',
    orderDetailInfo: '',
    orderAvailableStatuses: '',
    orderListColumnsForSplit: '',
    orderListColumns: '',
    orderListFilters: '',
    orderListRawMaterialSourceColumns: '',
    orderListRawMaterialSourceFilters: '',
    orderListCustomerColumns: '',
    orderListCustomerFilters: '',
    orderPageHeader: '',
    enableBulkAssign: false,
    enableSplit: false,
    enableXlsImport: false,
    enableXlsExport: false,
    enableApproveOrder: false,
    enableExportCSVOnTrace: false,
    enableTraceLibrary: false,
    enableDocumentLibrary: false,
    enableHandmade: false,
    supplierDetailReadonly: false,
    extraMenu: '',
    enableBulkComplete: false,
    enableExportCompanyOnboardedBy: false,
    enableEnquiryDisapprove: false,
    companyJsonFormSchema: '',
    enquireAttributesEditJsonSchema: '',
    enableOrderPdf: false,
    handmadeSupplierCompany: '',
    languages: '',
    enableAdminDeleteEnquiry: false,
    enableAdminEditVatCode: false,
    enquiryRejectReasons: '',
    enableOnlyReadSupplierCompanyProfileCategory: false,
    enableReopenOrder: false,
    enableDraftParentOnReject: false,
    enableEnquiryBlockIfChildNotCompletedOrCancelled: false,
    enableCompanyCheckByVatCode: false,
    enableDashBoardCounter: true,
    isDisabledSupplierTabCertification: false,
    visibleRoles: 'admin,admin-supplier,supplier',
    formatDownloadQrCode: ''
  };

  menuConfiguration: BaseMenuConfiguration[] = [];

  requireMapImages = {
    'defaultBigLogo': require('assets/images/logo.svg').default,
    'defaultSmallLogo': require('assets/images/small_logo.svg').default,
  };

  getImage(nameImage: string): string {
    return this.requireMapImages[nameImage] ?? nameImage;
  }

  async loadInitalData(keyCloak: Keycloak.KeycloakInstance) {
    this.eventEmitter.subscribe('onReadMessage', 'updateCounter', (e) => {
      this.updateNotificatonCounter(e);
    });

    this.keyCloak = keyCloak;
    if (keyCloak.token) {
      Auth.setHeaderAccessToken(keyCloak.token);

      try {
        this.loggedUser = await Auth.loggedUser();
      } catch (error) {
        throw new UserNotFoundError();
      }
      let appSettings = await ApplicationSetting.getApplicationSettings();
      if (!!appSettings) {
        for (let key in appSettings) {
          let value = appSettings[key];
          if (value === 'true' || value === 'false') {
            this.appSettings[key] = Boolean(JSON.parse(String(appSettings[key])));
          } else {
            this.appSettings[key] = appSettings[key];
          }
        }
      }
    }
    await this.loadLanguage();
    await this.loadGoToDashboard();
    this.collapsedBarMenu = await this.loadCollapsedMenuBar();
    this.refreshCreateTracePageRequired = await this.loadRefreshCreateTracePageRequired();
    await this.getTenantFromStorage(); // questo caricamento è fatto anche su App.tsx

    this.menuConfiguration = this.appSettings.menuConfiguration ?
      mapMenuConfiguration(JSON.parse(this.appSettings.menuConfiguration)) :
      getDefaultMenu();
  }

  async setCollapsedMenuBar(collapsed: boolean) {
    this.collapsedBarMenu = collapsed;
    await Storage.save('collapsedBarMenu', collapsed);
  }

  async loadCollapsedMenuBar() {
    return await Storage.load('collapsedBarMenu', false);
  }

  async loadRefreshCreateTracePageRequired() {
    return await Storage.load('refreshCreateTracePageRequired', false);
  }

  async saveRefreshCreateTracePageRequired(needRefresh: boolean) {
    this.refreshCreateTracePageRequired = needRefresh;
    return await Storage.save('refreshCreateTracePageRequired', needRefresh);
  }

  async setLanguage(lang: string) {
    this.language = lang;
    await Storage.save('language', lang);
    changeLanguage(lang);
  }


  async loadLanguage() {
    this.language = await Storage.load('language', 'en');
    if (!this.appSettings.languages.includes(this.language)) {
      this.language = 'en';
    }
    await initI18nX();
    changeLanguage(this.language);
    return this.language;
  }

  async setGoToDashboard(goToDashboard: boolean) {
    this.goToDashboard = goToDashboard;
    await Storage.save('goToDashboard', goToDashboard);
  }

  async loadGoToDashboard() {
    this.goToDashboard = await Storage.load('goToDashboard', false);
    return this.goToDashboard;
  }

  async setTenant(tenant: string) {
    await Storage.save('tenant', tenant);
  }

  async getTenantFromStorage() {
    return await Storage.load('tenant', undefined);
  }

  onReadMessageEvent(notification?: NotificationTCare) {
    this.eventEmitter.dispatch('onReadMessage', notification);
  }

  setNotificationsCounter(res: NotificationsAndCount, firstCall: boolean) {
    this.notificationsAndCount = res;
    this.eventEmitter.dispatch('notificationChange', { ...this.notificationsAndCount, firstCall });
  }

  updateNotificatonCounter(newNotificationTCare?: NotificationTCare) {
    if (!!newNotificationTCare) {
      if (newNotificationTCare.read) {
        this.notificationsAndCount!.count = this.notificationsAndCount!.count - 1;
      } else {
        this.notificationsAndCount!.count = this.notificationsAndCount!.count + 1;
      }
    } else {
      this.notificationsAndCount!.count = 0;
    }
  }


  eventEmitter = {
    events: {},
    dispatch: function(event: EventType, data) {
      if (!this.events[event]) {
        return;
      }
      this.events[event].forEach(def => def.callback(data));
    },

    subscribe: function(event: EventType, name: string, callback: (body: any) => void) {
      if (!this.events[event]) {
        this.events[event] = [];
      }
      this.unSubscribe(event, name);
      this.events[event].push({ name, callback });
      // console.log(event + ' subscribe[' + name + ']: ', this.events[event])
    },

    unSubscribe: function(event: EventType, name: string) {
      if (this.events[event]) {
        this.events[event] = this.events[event].filter(def => def.name !== name);
      }
      // console.log(event + ' unSubscribe[' + name + ']: ' + event, this.events[event])
    }

  };
}

export default new AppStore();
