import { LibraryConfig, Page, QueryParams } from './types';
import { AxiosRequestConfig } from 'axios';
import api, { responseErrorCheck } from './api';

class LibraryConfigs {
  protected endpoint = 'tracemanager/v1/libraryconfigs';

  async get(id: string, axiosConfig?: AxiosRequestConfig) {
    const res = await api.get<LibraryConfig>(`${this.endpoint}/${id}`, {}, axiosConfig);
    return responseErrorCheck(res);
  }

  async search(params: QueryParams, axiosConfig?: AxiosRequestConfig) {
    const res = await api.get<Page<LibraryConfig>>(`${this.endpoint}`, params, axiosConfig);
    return responseErrorCheck(res);
  }

  async save(libraryConfig: LibraryConfig, axiosConfig?: AxiosRequestConfig) {
    const res = await api.post<LibraryConfig>(`${this.endpoint}`, libraryConfig, axiosConfig);
    return responseErrorCheck(res);
  }

  async update(id: string, libraryConfig: LibraryConfig, axiosConfig?: AxiosRequestConfig) {
    const res = await api.post<LibraryConfig>(`${this.endpoint}/${id}`, libraryConfig, axiosConfig);
    return responseErrorCheck(res);
  }

  async delete(id: string, axiosConfig?: AxiosRequestConfig) {
    const res = await api.delete<LibraryConfig>(`${this.endpoint}/${id}`, {}, axiosConfig);
    return responseErrorCheck(res);
  }

}

const libraryConfigs = new LibraryConfigs();
export { libraryConfigs as default };
