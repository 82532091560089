import React from 'react';

import {Button, Space, Table, Tabs} from 'antd';
import {EmptyListText, View} from 'components';
import {__, T} from 'config/i18n';
import {TablePaginationConfig} from 'antd/lib/table/interface';
import {ColumnsType} from 'antd/es/table';
import {buildParamsByConfiguration} from 'components/orders/FilterBuilderUtil';
import {Schema} from "./AdvancedForm";
import {BaseEntity, Page} from "../api/types";
import AdvancedForm2 from "./AdvancedForm2";
import moment from "moment/moment";

const dateFormatRegex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z$/;

interface StateToSave<E> {
    activeTabKey?: string;
    filters: any;
    currentPage: number;
    pageSize: number;
    selectedElements: E[];
    sorterField: string;
    sorterOrder: 'ascend' | 'descend';
}

interface State<E> extends StateToSave<E> {
    elements: E[];
    totalElements: number
    isPageLoading: boolean
    lastRequestId: number
}

interface Props<E extends BaseEntity> {
    onSearchElements: (params: any, currentPage: number, sortField: string, sortDirection: 'asc' | 'desc', size: number, activeTabKey?: string) => Promise<Page<E>>,
    getColumnsConfig: (activeTabKey?: string) => ColumnsType<E>,
    getFiltersConfig?: () => Schema[],
    askToUpdateData?: number
    sorterField?: string
    sorterOrder?: 'ascend' | 'descend'
    tab?: {
        tabKeys: string[],
        initialActiveTabKey?: string,
        onTabChange?: (activeTabKey: string) => void
    }
    rowSelection?: {
        multipleSelection?: boolean,
        onChangeSelection?: (elements: E[]) => void
    }
    storeKey?: string,
    getExtraButtons?: (activeTabKey?: string) => JSX.Element[],
    refreshSelectedElements?: (elements: E[]) => Promise<E[]>
    rowsSelected?: any[]
}

export const SHOW_SELECTED_KEY = 'SHOW_SELECTED';

export default class FilteredTable<E extends BaseEntity> extends React.Component<Props<E>, State<E>> {

    private INITIAL_STATE: State<E> = {
        elements: [],
        selectedElements: [],
        totalElements: 0,
        filters: {},
        isPageLoading: true,
        currentPage: 1,
        pageSize: 10,
        sorterField: 'lastModified',
        sorterOrder: 'descend',
        lastRequestId: 0
    }

    private timer: any = null
    // private timeoutStarted: boolean = false

    state: State<E> = {...this.INITIAL_STATE}

    loadComponent = async () => {
        const storedState = this.getOnSessionStorage()

        if (!!storedState) {
            let initialStatus = this.props.tab?.initialActiveTabKey ?? storedState.activeTabKey

            if(!this.props.tab?.initialActiveTabKey && !!this.props.tab?.onTabChange) {
                this.props.tab?.onTabChange(initialStatus!)
            }

            let selectedElements = storedState.selectedElements;
            if (!!this.props.refreshSelectedElements) {
                selectedElements = await this.props.refreshSelectedElements(selectedElements);
            }

            if(!!storedState.filters) {
                Object.keys(storedState?.filters).forEach(key => {
                    let value = storedState.filters[key]
                    if(Array.isArray(value) && value.length === 2){
                        if(this.isDateStringValid(value[0]) && this.isDateStringValid(value[1])) {
                            storedState.filters[key] = [moment(value[0]), moment(value[1])]
                        }
                    } else if(this.isDateStringValid(value)){
                       storedState.filters[key] = moment(value)
                    }
                })
            }

            this.setState({
                ...storedState,
                selectedElements: selectedElements,
                activeTabKey: initialStatus
            }, () => {
                if (selectedElements) {
                    if (this.props.rowSelection?.onChangeSelection) {
                        this.props.rowSelection?.onChangeSelection(selectedElements)
                    }
                }
                this.search(storedState.filters)
            })
            return;
        }

        let activeStatus = this.props.tab?.initialActiveTabKey ?? this.props.tab?.tabKeys[0]
        if(!this.props.tab?.initialActiveTabKey && !!this.props.tab?.onTabChange) {
            this.props.tab?.onTabChange(activeStatus!)
        }

        let filters = this.state.filters
        for(let key of Object.keys(filters)){
            filters[key]=undefined
        }
        this.setState({
            ...this.INITIAL_STATE,
            filters,
            activeTabKey: activeStatus,
            sorterOrder: this.props.sorterOrder ?? 'descend',
            sorterField: this.props.sorterField ?? 'lastModified'
        }, () => this.search(this.state.filters))
    }

    isDateStringValid = (inputString: string): boolean => {
        return dateFormatRegex.test(inputString);
    }

    componentDidMount = async () => {
        await this.loadComponent();
    };

    componentDidUpdate = async (prevProps: Readonly<Props<E>>) => {
        if(prevProps.askToUpdateData && prevProps.askToUpdateData !== this.props.askToUpdateData) {
            await this.loadComponent();
        }
    }

    getTabKeys = ():string[] => {
      let tabKeys: string[] = []
      if (!!this.props.tab) {
        tabKeys = [...this.props.tab.tabKeys];
        if (this.props.rowSelection) {
          tabKeys.push(SHOW_SELECTED_KEY)
        }
      }
      return tabKeys;
    }

    search = async (filters: any) => {
        const lastRequestId = this.state.lastRequestId + 1;
        this.setState({isPageLoading: true, lastRequestId: lastRequestId}, async () => {

            const {sorterField, sorterOrder, pageSize} = this.state

            let elementPage = await this.props.onSearchElements(this.getParams(filters), this.state.currentPage - 1, sorterField, (sorterOrder === 'descend' ? 'desc' : 'asc'), pageSize, this.state.activeTabKey)

            if (!!elementPage && elementPage.totalPages < this.state.currentPage) {
                elementPage = await this.props.onSearchElements(this.getParams(filters), elementPage.totalPages - 1, sorterField, (sorterOrder === 'descend' ? 'desc' : 'asc'), pageSize, this.state.activeTabKey)
            }

            if (lastRequestId !== this.state.lastRequestId) {
                return;
            }

            this.saveOnSessionStorage({
                activeTabKey: this.state.activeTabKey,
                currentPage: elementPage.page + 1,
                filters: this.state.filters,
                selectedElements: this.state.selectedElements,
                sorterField: this.state.sorterField,
                sorterOrder: this.state.sorterOrder,
                pageSize: this.state.pageSize
            });

            this.setState({
                elements: elementPage.content,
                totalElements: elementPage.totalElements,
                currentPage: elementPage.page + 1,
                isPageLoading: false
            });

        });
    };

    resetPage = () => {
        let newSearch = this.state.filters
        Object.keys(newSearch).forEach(key => newSearch[key] = undefined)
        this.setState({
            selectedElements: [],
            currentPage: 1
        }, () => {
            if(this.props.rowSelection?.onChangeSelection){
                this.props.rowSelection?.onChangeSelection([])
            }
            this.search(newSearch)
        })
    };


    applyFilters = (
        filters: any
    ) => {

            this.setState({
                filters: filters,
                isPageLoading: true
            }, () => {
                // Clears running timer and starts a new one each time the user types
                clearTimeout(this.timer)
                this.timer = setTimeout(() => {
                    this.search(filters)
                }, 500)
            });

    };

    applyPage = (
        page: number,
        pageSize?: number,
        sorterField?: string,
        sorterOrder?: 'ascend' | 'descend'
    ) => {
        if (pageSize !== undefined) {
            this.setState({
                isPageLoading: true,
                currentPage: page,
                pageSize: pageSize,
                sorterField: sorterField ?? this.state.sorterField,
                sorterOrder: sorterOrder ?? this.state.sorterOrder
            }, () => {
                this.search(this.state.filters)
            });
        } else {
            this.setState({
                isPageLoading: true,
                currentPage: page,
                sorterField: sorterField ?? this.state.sorterField,
                sorterOrder: sorterOrder ?? this.state.sorterOrder
            }, () => {
                this.search(this.state.filters)
            });
        }
    };

    handleTableChange = async (
        pagination: TablePaginationConfig,
        sorter: any): Promise<void> => {
        await this.applyPage(
            pagination.current!,
          pagination.pageSize,
            sorter.field,
            sorter.order)
    };

    getParams = (filters: any): any => {
        let params = !!this.props.getFiltersConfig && buildParamsByConfiguration(filters, this.props.getFiltersConfig());

        if (this.state.selectedElements && this.state.selectedElements.length > 0 && this.state.activeTabKey === SHOW_SELECTED_KEY) {
            params = {ids: this.state.selectedElements.map(sel => sel.id)};
        }
        return params
    };

    getTabBarExtraContent = () => {
        return <Space direction="horizontal" key="tabExtraContent">
            {this.props.getFiltersConfig && <Button
                key={T.buttons.reset_filter}
                type={'default'}
                style={{width: '200px'}}
                onClick={this.resetPage}
            >
                {__(T.buttons.reset_filter)}
            </Button>}
            {!!this.props.getExtraButtons && this.props.getExtraButtons(this.state.activeTabKey)}
        </Space>;
    };

    onTabChangeFilter = (activeKey: string) => {
        this.setState({
            activeTabKey: activeKey
        }, async () => {
            if(!!this.props.tab?.onTabChange){
                this.props.tab?.onTabChange(activeKey)
            }
            await this.applyPage(1, this.state.pageSize, this.state.sorterField, this.state.sorterOrder);
        })
    };

    getTable = (pagination: TablePaginationConfig, filteredOrders: E[], isPageLoading: boolean, key?: string) => {
        let rowSelection: any
        if(!!this.props.rowSelection) {
            if(this.props.rowSelection.multipleSelection) {
                rowSelection = {
                    preserveSelectedRowKeys: true,
                    selectedRowKeys: this.state.selectedElements.map(e => e.id),
                    onChange: async (selectedRowKeys, selectedRows) => {
                        let elements: E[] = []
                        selectedRowKeys.forEach(key => {
                            let oldElement = this.state.selectedElements.find(ele => ele.id === key + '')
                            if (oldElement) {
                                elements.push(oldElement)
                            } else {
                                let newElement = selectedRows
                                    .filter(ele => !!ele)
                                    .find(ele => ele.id === key + '')
                                elements.push(newElement!)
                            }
                        });

                        if(this.props.rowSelection!.onChangeSelection){
                            this.props.rowSelection!.onChangeSelection(elements)
                        }
                       let storageSession = this.getOnSessionStorage();
                        if (!!storageSession) {
                            storageSession = {...storageSession,
                              selectedElements: elements };
                            this.saveOnSessionStorage(storageSession);
                        }
                        this.setState({
                            selectedElements: elements
                        });
                    },
                    renderCell: (value, record, index, originNode) => originNode
                }
            } else {
                rowSelection = {
                    preserveSelectedRowKeys: true,
                    selectedRowKeys: !!this.props.rowsSelected ? this.props.rowsSelected!.map(e => e.id) : undefined,
                    onChange: (selectedRowKeys, selectedRows) => {
                        let selection: E[] = [selectedRows[0]]
                        if(this.props.rowSelection!.onChangeSelection){
                            this.props.rowSelection!.onChangeSelection(selection)
                        }
                        this.setState({selectedElements: [selectedRows[0]]})
                    },
                    getCheckboxProps: (record) => ({
                        disabled: false,
                        // Column configuration not to be checked
                        name: record.name,
                    }),
                    type: 'radio'
                }
            }
        }

        let columns: any[] = [...this.props.getColumnsConfig()]
        columns
            .filter(c => c.dataIndex && c.dataIndex === this.state.sorterField)
            .forEach(c => {
                c.sortOrder = this.state.sorterOrder
                c.key = 'col_' + c.dataIndex
            });

        return <Table
            key={key}
            loading={isPageLoading}
            className="orders-report-table"
            bordered
            columns={columns}
            dataSource={filteredOrders.map(filteredOrder => ({
                ...filteredOrder,
                key: filteredOrder.id
            }))}
            locale={{emptyText: <EmptyListText message={__(T.messages.no_data_default)}/>}}
            pagination={pagination}
            scroll={{x: true}}
            sortDirections={['descend', 'ascend']}
            onChange={(pagination, filters, sorter) => {
                this.handleTableChange(pagination, sorter)
            }}
            rowSelection={rowSelection}
        />
    }

    getTab = (tab: string, pagination: TablePaginationConfig, filteredOrders: E[], isPageLoading: boolean): JSX.Element | null => {

        // Manage the visible of show selected tab (to view or hide).
        if (tab === SHOW_SELECTED_KEY && this.state.selectedElements.length === 0) {
            return null;
        }

        return <Tabs.TabPane key={tab}
                          tab={__(T.misc[tab.toLowerCase()]) +
                          this.getCountOrderSelectedString(tab)}>

                {this.getTable(pagination, filteredOrders, isPageLoading, tab)}
            </Tabs.TabPane>
    };

    private getCountOrderSelectedString(key: string) {
        if (this.state.selectedElements.length > 0 && key === SHOW_SELECTED_KEY) {
            return '(' + this.state.selectedElements.length + ')';
        }
        return '';
    }


    render = () => {
        const {elements, activeTabKey, filters, isPageLoading} = this.state;
        const pagination: TablePaginationConfig = {
            total: this.state.totalElements,
            current: this.state.currentPage,
            pageSize: this.state.pageSize,
            showSizeChanger: true,
            pageSizeOptions: ['10', '25', '50'],
        };
        return (
            <View style={{padding: 20}}  key='mainview'>
                {(!!this.props.getFiltersConfig && this.state.activeTabKey !== SHOW_SELECTED_KEY) && <View className="filter-box">
                    <AdvancedForm2
                        key='filter_form'
                        values={filters}
                        schema={this.props.getFiltersConfig()}
                        onValuesChange={(changedValues, values) => this.applyFilters(values)}
                        style={{flex: 1}}
                    />
                </View>}

                {!!this.props.tab && <View className="page-table" key={'keyTabsOrderReportView'}>
                    <Tabs
                        key={'keyTabsOrderReport'}
                        activeKey={activeTabKey}
                        defaultActiveKey={activeTabKey}
                        onChange={this.onTabChangeFilter}
                        tabBarExtraContent={this.getTabBarExtraContent()}
                    >
                        {this.getTabKeys().map(orderStatusTabKey => (
                            this.getTab(orderStatusTabKey, pagination, elements, isPageLoading)
                        ))
                            .filter(tab => !!tab)}
                    </Tabs>
                </View>}

                {!this.props.tab && <>
                    <div style={{ display: 'flex', justifyContent: 'flex-end', gap: 10, marginBottom: 20 }} key='extraBarContent'>
                        {this.getTabBarExtraContent()}
                    </div>
                    {this.getTable(pagination, elements, isPageLoading)}
                    </>}
            </View>
        );
    };

    private getOnSessionStorage = (): StateToSave<E> | null => {
        if (!this.props.storeKey) {
            return null
        }
        const data = sessionStorage.getItem(this.props.storeKey);
        if (!!data) {
            return JSON.parse(data);
        }
        return null
    };

    private saveOnSessionStorage = (data: StateToSave<E>) => {
        if (!this.props.storeKey) {
            return
        }
        sessionStorage.setItem(this.props.storeKey, JSON.stringify({
            ...data
        }));
    };


}
