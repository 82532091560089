
export const noComposant = '__NO_COMPOSANT__'
//
// export const getSubOrdersByComponentArray = (assignmentsMap: Map<string, Map<string, OrderMultiAssignItem>>): Map<string, OrderMultiAssignItem> => {
//   return assignmentsMap.reduce((productComposition, assignments) => {
//     console.info("productComposition" + productComposition)
//     console.info( assignments)
//     // const composant = subOrder.materialGroup ?? noComposant
//     //   subOrdersByComposant.set(composant, [...(subOrdersByComposant.get(composant) ?? []), subOrder])
//     // return assignments;
//     new Map<string, OrderMultiAssignItem>()
//   }, new Map<string, OrderMultiAssignItem>())
//   // return Array.from(getSubOrdersByComponentMap(orders))
//
//
// }
//
// export const getSubOrdersByComponentMap = (orders: DetailedOrder[]): Map<string, SubOrder[]> => {
//   if (orders && orders.length) {
//     console.info(orders[0])
//     var a = getSubOrdersForOrderByComponentMap(orders[0])
//     console.info(a)
//     return a
//   } else {
//     return new Map<string, SubOrder[]>()
//   }
// }
//
// export const getSubOrdersForOrderByComponentMap = (order: DetailedOrder): Map<string, SubOrder[]> => {
//   return order?.orders.reduce((subOrdersByComposant, subOrder) => {
//     console.info("subOrder")
//     console.info( subOrder)
//     const composant = subOrder.materialGroup ?? noComposant
//       subOrdersByComposant.set(composant, [...(subOrdersByComposant.get(composant) ?? []), subOrder])
//     return subOrdersByComposant
//   }, new Map<string, SubOrder[]>())
// }

// export const getSubOrdersByComponentMap = (order: DetailedOrder): Map<string, SubOrder[]> => {
//   return order.orders.reduce((subOrdersByComposant, subOrder) => {
//     const composant = subOrder.materialGroup ?? noComposant
//       subOrdersByComposant.set(composant, [...(subOrdersByComposant.get(composant) ?? []), subOrder])
//     return subOrdersByComposant
//   }, new Map<string, SubOrder[]>())
// }
