import AppStore from '../AppStore';

export class TypesSettingsTools {

  public getSettingByMatchParamsType(matchParamsType: string | undefined, setting: string) {
    return matchParamsType !== undefined && AppStore.appSettings[setting + '.' + matchParamsType] !== undefined ? AppStore.appSettings[setting + '.' + matchParamsType] : AppStore.appSettings[setting]
  }

  public getSettingByTraceType(matchParamsType: string | undefined, setting: string) {
    return matchParamsType !== undefined && AppStore.appSettings[setting + '-' + matchParamsType] !== undefined ? AppStore.appSettings[setting + '-' + matchParamsType] : AppStore.appSettings[setting]
  }


  public isSettingEnablePageByMatchParamsType(matchParamsType: string, setting: string): any {
    if (!!matchParamsType) {
      if (AppStore.appSettings[setting + '.' + matchParamsType] === undefined) {
        return AppStore.appSettings[setting];
      }
      return AppStore.appSettings[setting + '.' + matchParamsType];
    }
    return AppStore.appSettings[setting];
  }


  public isSettingEnableTraceByMatchParamsType(matchParamsType: string, setting: string): any {
    if (!!matchParamsType) {
      if (AppStore.appSettings[setting + '-' + matchParamsType] === undefined) {
        return AppStore.appSettings[setting];
      }
      return AppStore.appSettings[setting + '-' + matchParamsType];
    }
    return AppStore.appSettings[setting];
  }
}

const getGenericOrSpecifiedSetting = (setting: string, extra?: string) => {
  return !!extra && !!AppStore.appSettings[setting + '-' + extra] ? AppStore.appSettings[setting + '-' + extra] : AppStore.appSettings[setting]
}


const typesSettingsTools = new TypesSettingsTools();
export { getGenericOrSpecifiedSetting, typesSettingsTools as default };


