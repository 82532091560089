import React from 'react';
import { AdvancedForm, Layout, View } from 'components';
import { DetailedOrder, DetailedOrderWithOwnerCompanyInfo, OrderDetailQrCodeSetting } from '../../api/types';
import { Col, Divider, Row } from 'antd';
import { __, T } from 'config/i18n';
import Title from 'antd/es/typography/Title';
import { navigate } from '../../router';
import Orders from '../../api/Orders';
import EntityPageHeader from '../../components/orders/EntityPageHeader';
import { getOrderDetailSchema } from '../../components/layouts/schemas';
import OrderTraceReport from '../../components/orders/OrderTraceReport';
import OrderCard from '../../components/orders/OrderCard';
import { Companies } from '../../api';
import OrderTraceDetailModal from '../../components/orders/OrderTraceDetailModal';
import AppStore from '../../AppStore';
import TraceDetailForm from '../../components/traces/TraceDetailForm';
import {DownloadOutlined} from '@ant-design/icons';
import { CSVLink } from 'react-csv';
import generalExports from '../../tools/GeneralExports';
import TCareQrCode from '../../components/tCareQrCodes/TCareQrCode';
import qrCodeTools from '../../tools/QrCodeTools';



interface Props {
  match: { params: { orderId: string } };
}

interface State {
  order?: DetailedOrderWithOwnerCompanyInfo;
  orderTraceDetailModalVisible: boolean;
  orderTraceDetailModalOrderId?: string;
  isDraft: boolean;
}

export default class RawMaterialSourceOrderDetail extends React.Component<Props, State> {

  state: State = {
    orderTraceDetailModalVisible: false,
    isDraft: false,
  };

  componentDidMount = async () => {
    await this.reloadComponent(this.props.match.params.orderId);
  };

  componentDidUpdate = async (prevProps: Readonly<Props>) => {
    if (!window.location.pathname.includes(prevProps.match.params.orderId)) {
      try {
        let orderId = window.location.pathname.split('/')[3];
        if (!!orderId) {
          await this.reloadComponent(orderId);
        }
      } catch (e) {
      }
    }
  };

  reloadComponent = async (orderId: string) => {
    const order: DetailedOrderWithOwnerCompanyInfo | undefined = await Orders.get(orderId);
    if (order && !order.data) {
      order.data = {};
    }
    if (order && order.ownerCompany) {
      const company = await Companies.get(order.ownerCompany);
      order.ownerCompanyName = company?.data.businessName;
      order.certificationValidity = company?.certificationValidity;
    }
    this.setState({ order: order });
  };

  getPageHeaderExtras = (order: DetailedOrder | undefined): JSX.Element[] => {
    let extras: JSX.Element[] = [];
    if (order === undefined) {
      return extras;
    }

    if ((order.status === 'COMPLETED' || order.status === 'APPROVED') && AppStore.appSettings.enableExportCSVOnTrace) {
      let csvData: any = [];
      generalExports.exportViewCsv(csvData, this.getSchema(order), order);
      // TODO: add system to create csv on runtime (when click on button).
      extras.push(
        <CSVLink data={csvData}
                 style={{ borderColor: '#2F80ED', color: '#2F80ED' }}
                 className="ant-btn ant-btn-default">{__(T.buttons.export_trace_in_CSV)}<DownloadOutlined /> </CSVLink>
      );
    }

    return extras;
  };

  viewDetail = (orderId: string): void => {
    this.setState({ orderTraceDetailModalOrderId: orderId, orderTraceDetailModalVisible: true });
  };

  navigateToDetail = async (orderId: string): Promise<void> => {
    navigate('/reports/rawmaterialorders/detail/:orderId', { orderId: orderId });
  };


  render = () => {
    const { order, orderTraceDetailModalVisible, orderTraceDetailModalOrderId } = this.state;
    if (!order) {
      return (
        <Layout children={null} />
      );
    }

    const orderDetailQrCodeSetting: OrderDetailQrCodeSetting|undefined =
      qrCodeTools.getParsedOrderDetailQrCodeSetting(order.traceType.code);

    return (
      <Layout>
        <View style={{ margin: 0, marginBottom: 1 }}>
          <EntityPageHeader
            config={'orderPageHeader'}
            data={order}
            extras={this.getPageHeaderExtras(order)}
            onBack={() => navigate('/reports/rawmaterialorders')}
            extraConfigType={order.traceType.code}
          />
        </View>
        {this.shouldShowOrderDetail(order) &&
          <View className="page-table" style={{ margin: 10, marginTop: 0, borderRadius: '0px' }}>
            <Title level={4}>{__(T.titles.purchase_order_details)}</Title>
            <Divider />
            <Row>
              <Col span={16}>
                <AdvancedForm schema={getOrderDetailSchema(order.traceType.code)} values={order} />
              </Col>
              {!!orderDetailQrCodeSetting && orderDetailQrCodeSetting.onlyRoot === order.root &&
                <Col span={8} style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                  <TCareQrCode order={order} size={160} setting={orderDetailQrCodeSetting.value} format={AppStore.appSettings.formatDownloadQrCode}/>
                </Col>
              }
            </Row>
          </View>
        }
        {!(AppStore.appSettings.enableOrderDetailSmart && order.orders.length === 0) && (
            <>
              <View className="page-table" style={{ margin: 10, marginTop: 0, paddingLeft: 0, borderRadius: '0px' }}>
                <Title level={4} style={{ paddingLeft: 20 }}>
                  {__(T.titles.trace_report)}
                </Title>
                {order.orderType !== 'MAKE_FINISH_PRODUCT' && <>
                  <OrderCard
                      askToUpdate={0}
                      isSubOrder={false}
                      order={order}
                    onViewDetail={this.viewDetail}
                    noFulfillment
                    showFulfillmentDate
                    showAssignmentDate
                    style={{
                      margin: '30px 40px 20px 40px',
                      width: 'auto',
                      backgroundColor: '#efefef'
                    }}
                  />
                  <Divider style={{ marginBottom: 0 }} />
                  <OrderTraceReport order={order}
                                    askToUpdate={0}
                                    onViewSubOrderDetail={this.viewDetail}
                                    viewDetail={this.viewDetail} />
                  <OrderTraceDetailModal
                    orderId={orderTraceDetailModalOrderId}
                    visible={orderTraceDetailModalVisible}
                    onCancel={() => this.setState({ orderTraceDetailModalVisible: false })}
                  />
                </>}
              </View>
            </>)}

        {(AppStore.appSettings.enableOrderDetailSmart && order.orders.length === 0) && (
          <>
            <View className="page-table" style={{ margin: 10, marginTop: 0, borderRadius: '0px' }}>
              <Col span={24}>
                <TraceDetailForm schema={this.getSchema(order)} order={order} isShowExportCsvButton={false} />
              </Col>
            </View>
          </>)}

      </Layout>
    );
  };

  private shouldShowOrderDetail = (order: DetailedOrder) => {
    return order.attributes && Object.keys(order.attributes).length > 0;
  };

  private getSchema = (order: DetailedOrder) => {
    const schema = JSON.parse(order.traceType.schema);
    if (schema) {
      schema.title = undefined;
    }
    return schema;
  };
}
