import {OrderHandmadeConfig, Page, QueryParams} from './types';
import {AxiosRequestConfig} from 'axios';
import api, {responseErrorCheck} from './api';

class OrderHandmades {
  protected endpoint = 'tracemanager/v1/orderHandmadeConfig';

  async get(id: string, axiosConfig?: AxiosRequestConfig) {
    const res = await api.get<OrderHandmadeConfig>(`${this.endpoint}/${id}`, {}, axiosConfig);
    return responseErrorCheck(res);
  }

  async getByCode(code: string, axiosConfig?: AxiosRequestConfig) {
    const res = await api.get<OrderHandmadeConfig>(`${this.endpoint}/code/${code}`, {}, axiosConfig);
    return responseErrorCheck(res);
  }

  async search(params: QueryParams, axiosConfig?: AxiosRequestConfig) {
    const res = await api.get<Page<OrderHandmadeConfig>>(`${this.endpoint}`, params, axiosConfig);
    return responseErrorCheck(res);
  }

  async count(params: QueryParams, axiosConfig?: AxiosRequestConfig) {
    const res = await api.get<Page<OrderHandmadeConfig>>(`${this.endpoint}/count`, params, axiosConfig);
    return responseErrorCheck(res);
  }

/* For now (11 Avril 2023) these interfaces are not used.
  async save(enquiryHandmadeConfig: EnquiryHandmadeConfig) {
    const res = await api.post<EnquiryHandmadeConfig>(`${this.endpoint}`, enquiryHandmadeConfig);
    return responseErrorCheck(res);
  }

  async update(id: string, enquiryHandmadeConfig: EnquiryHandmadeConfig) {
    const res = await api.post<EnquiryHandmadeConfig>(`${this.endpoint}/${id}`, enquiryHandmadeConfig);
    return responseErrorCheck(res);
  }

  async delete(id: string, axiosConfig?: AxiosRequestConfig) {
    const res = await api.delete<EnquiryHandmadeConfig>(`${this.endpoint}/${id}`, axiosConfig);
    return responseErrorCheck(res);
  }
*/
}

const orderHandmade = new OrderHandmades();
export { orderHandmade as default };
