import {Company, CreateUserCommand, Page, QueryParams, UpdateUserCommand, User, UserRole} from 'api/types';
import {AxiosRequestConfig} from 'axios'
import api, {responseErrorCheck} from './api'

class Users<T = User> {
  protected endpoint = 'profilemanager/v1/users'

  async findAll(axiosConfig?: AxiosRequestConfig) {
    const res = await api.get<Page<T>>(`${this.endpoint}`, axiosConfig)
    return responseErrorCheck(res)
  }

  async select(input: string, axiosConfig?: AxiosRequestConfig) {
    const res = await api.get<T[]>(`${this.endpoint}/select?${this.getAutocompleteQueryParam()}=${input}`, axiosConfig)
    return responseErrorCheck(res)
  }

  async get(id: string, axiosConfig?: AxiosRequestConfig) {
    const res = await api.get<T>(`${this.endpoint}/${id}`, axiosConfig)
    return responseErrorCheck(res)
  }

  async findByUsername(userName: string, axiosConfig?: AxiosRequestConfig) {
    const res = await api.get<T>(`${this.endpoint}/username/${userName}`, axiosConfig)
    return responseErrorCheck(res)
  }

  async update(id: string, user: User, axiosConfig?: AxiosRequestConfig) {
    const res = await api.put<T>(`${this.endpoint}/${id}`, user, axiosConfig)
    return responseErrorCheck(res)
  }

  async search(params: QueryParams, axiosConfig?: AxiosRequestConfig) {
    const res = await api.get<Page<T>>(`${this.endpoint}`, params, axiosConfig)
    return responseErrorCheck(res)
  }

  async enable(id: string, axiosConfig?: AxiosRequestConfig) {
    const res = await api.put<T>(`${this.endpoint}/${id}/enabled`, axiosConfig)
    return responseErrorCheck(res)
  }

  async disable(id: string, axiosConfig?: AxiosRequestConfig) {
    const res = await api.delete<T>(`${this.endpoint}/${id}`, axiosConfig)
    return responseErrorCheck(res)
  }

  async create(data: Partial<T>, axiosConfig?: AxiosRequestConfig) {
    const res = await api.post<T>(`${this.endpoint}`, data, axiosConfig)
    return responseErrorCheck(res)
  }

  async createByCommand(command: CreateUserCommand, axiosConfig?: AxiosRequestConfig) {
    const res = await api.post<T>(`${this.endpoint}/createByCommand`, command, axiosConfig)
    return responseErrorCheck(res)
  }

  async updateByCommand(command: UpdateUserCommand, axiosConfig?: AxiosRequestConfig) {
    const res = await api.post<T>(`${this.endpoint}/updateByCommand`, command, axiosConfig)
    return responseErrorCheck(res)
  }

  async findRoleByUserId(userId: string, axiosConfig?: AxiosRequestConfig) {
    const res = await api.get<UserRole>(`${this.endpoint}/role/${userId}`, {}, axiosConfig)
    return responseErrorCheck(res)
  }

  async updateCurrentCompany(companyId: string, axiosConfig?: AxiosRequestConfig) {
    const res = await api.put<T>(`${this.endpoint}/updateCurrentCompany`, {
      companyId: companyId
    }, axiosConfig)
    return responseErrorCheck(res)
  }

  async getCurrentCompany(axiosConfig?: AxiosRequestConfig) {
    const res = await api.get<Company>(`${this.endpoint}/currentCompany`, {}, axiosConfig)
    return responseErrorCheck(res)
  }

  getEndpoint() {
    return this.endpoint
  }

  getAutocompleteQueryParam() {
    return 'fullText'
  }
}

const users = new Users()
export { users as default }
