import React from "react";
import { Card, Col } from "antd";
import DonutPlotWithStatics from "../ant-d-modules/donut/DonutPlotWithStatics";


interface Props {
  titleCard: string
  titlePlot: string
  networks: [{
    type: string
    value: number
  }?]
}

interface State {
}


export default class DonutPlotWithStaticsCard extends React.Component<Props, State> {


  render() {
    return (
      <Col xl={8} sm={24} lg={12}>
        <Card
          title={this.props.titleCard}
          bordered={true}
          style={{ width: "100%", height: 500 }}
        >
          <DonutPlotWithStatics data={this.props.networks} title={this.props.titlePlot}/>
        </Card>
      </Col>
    );
  }
}




