import { Col, Row } from 'antd';
import React, { Component } from 'react'
import { ShoppingCartOutlined } from '@ant-design/icons';


interface State {
}


export default class AnalyticsSummary extends Component<{}, State> {
    state: State = {}

    shoot = () => {
        this.setState({});
    }

    render() {
        return (
            <>
                <Col xl={4} style={{minWidth: 200}}>
                    <Row>
                        <Col style={{minWidth: 100}}>
                            <ShoppingCartOutlined style={{
                                display: 'inline-block',
                                borderRadius: 60,
                                boxShadow: '0 0 2px #888',
                                padding: '0.2em 0.3em',
                                color: '#BB6BD9', fontSize: '3em'}}/>
                        </Col>
                        <Col style={{minWidth: 10}}>
                            <Row>
                                <Col span={24}>
                                    <div style={{fontSize: '2em'}} >57</div>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={24}>
                                    Label
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>
            </>
        )
    }
}






