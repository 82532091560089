import { TraceRelations } from "api/types";
import api, { responseErrorCheck } from './api'
import { AxiosRequestConfig } from 'axios';

class TracesRelations<T = TraceRelations> {
  protected endpoint = 'tracemanager/v1/traces'
  async findRelation(idTrace: string, axiosConfig?: AxiosRequestConfig) {
    const res = await api.get<T>(this.endpoint + '/' + idTrace + '/relations', {}, axiosConfig)
    return responseErrorCheck(res)
  }

}

const traces = new TracesRelations()
export { traces as default }
