import React from 'react'
import {DetailedOrder, SubOrder, SubOrderWithOwnerCompanyInfo} from '../../api/types'
import {Col, Layout} from 'antd'
import {getSubOrdersByComponentMap, noComposant} from '../../helpers/orders'
import OrderTraceReportComposantMenu from './OrderTraceReportComposantMenu'
import OrderTraceReportSubOrderCard from './OrderTraceReportSubOrderCard'
import {getOrderCardCompanyMap, getOrderCardDetail, getOrderCardDetailWithCompanyMap} from "./OrderCard";

interface Props {
    order: DetailedOrder
    hideSupplier?: boolean
    onViewSubOrderDetail: (subOrderId: string) => void
    viewDetail: (orderId: string) => void
    onRejectOrder?: (orderId: string) => void
    onReassignOrder?: (orderId: string) => void
    onCloneOrder?: (orderId: string) => void
    onCancel?: (orderId: string) => void
    onUnlock?: (orderId: string) => void
    onShowLogs?: (orderId: string) => void
    askToUpdate: number
    pageType?: string
}

export interface OrderSubOrderModel {
    subOrders: SubOrderWithOwnerCompanyInfo[]
    weavingOrder?: SubOrderWithOwnerCompanyInfo
}

interface State {
    subOrdersByComposant: Map<string, SubOrder[]>
    selectedComposant: string
    orderSubOrderModelList: OrderSubOrderModel[]
}

export default class OrderTraceReport extends React.Component<Props, State> {

    state = {
        subOrdersByComposant: new Map<string, SubOrder[]>(),
        selectedComposant: noComposant,
        orderSubOrderModelList: [
            {
                subOrders: [],
                weavingOrder: undefined
            }
        ]
    }


    componentDidMount = async () => {
        await this.initOrReloadRender();
    }

    componentDidUpdate = async (prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any) => {
        if (prevProps.askToUpdate !== this.props.askToUpdate){
            await this.initOrReloadRender();
        }
    }

    handleComposantSelection = async (selectedComponent: string) => {
        await this.initOrReloadRender(selectedComponent);
    }

    getComposants = (subOrdersByComposant: Map<string, SubOrder[]>): string[] => {
        return Array.from(subOrdersByComposant.keys())
    }

    getWeavingOrder = async (subOrders: SubOrder[], index: number): Promise<SubOrderWithOwnerCompanyInfo | undefined> => {
        let weavingOrder = subOrders.length > index && subOrders[index].materialGroup ? subOrders[index] : undefined

        if (weavingOrder) {
            return await getOrderCardDetail(weavingOrder) as SubOrderWithOwnerCompanyInfo
        }

        return weavingOrder;
    }

    getSubOrders = async (subOrders: SubOrder[], index: number): Promise<SubOrderWithOwnerCompanyInfo[]> => {
        subOrders = subOrders.length > index && subOrders[index].materialGroup ? subOrders[index].orders : subOrders
        const subOrdersResult: SubOrderWithOwnerCompanyInfo[] = [];
        const companyMap = await getOrderCardCompanyMap(subOrders);

        for (const elem of subOrders) {
            subOrdersResult.push(await getOrderCardDetailWithCompanyMap(elem, companyMap) as SubOrderWithOwnerCompanyInfo);
        }

        return subOrdersResult
    }


    render = () => {
        const {order, onViewSubOrderDetail, viewDetail, onRejectOrder} = this.props
        const {orderSubOrderModelList, subOrdersByComposant} = this.state
        return (
            <>
                <Layout style={{backgroundColor: 'white', margin: 0, borderTop: '1px solid #D6D6D6'}}>
                    {this.shouldRenderComposantMenu(subOrdersByComposant) && (
                        <Col span={4}>
                            <OrderTraceReportComposantMenu
                                composants={this.getComposants(subOrdersByComposant)}
                                onComposantSelection={this.handleComposantSelection}
                            />
                        </Col>
                    )}
                    <div>
                        {
                            orderSubOrderModelList.map(orderSubOrderModel =>
                                <>
                                <OrderTraceReportSubOrderCard
                                    pageType={this.props.pageType}
                                    key={orderSubOrderModelList.indexOf(orderSubOrderModel)}
                                    askToUpdate={this.props.askToUpdate}
                                    parentOrder={order}
                                    subOrders={orderSubOrderModel.subOrders}
                                    onViewSubOrderDetail={onViewSubOrderDetail}
                                    weavingOrder={orderSubOrderModel.weavingOrder}
                                    hideSupplier={this.props.hideSupplier || false}
                                    viewDetail={viewDetail}
                                    onRejectOrder={onRejectOrder}
                                    onReassignOrder={this.props.onReassignOrder}
                                    onCloneOrder={this.props.onCloneOrder}
                                    onCancel={this.props.onCancel}
                                    onUnlock={this.props.onUnlock}
                                    onShowLogs={this.props.onShowLogs}
                                />
                                </>
                            )
                        }
                    </div>
                </Layout>
            </>

        )
    }

    shouldRenderComposantMenu = (subOrdersByComposant: Map<string, SubOrder[]>) => {
        return this.getComposants(subOrdersByComposant).filter(composant => composant !== noComposant).length > 1
    }

    /**
     * The method reloads all the elements used to render the DOM.
     *
     * @param selectedComposant which component is to be loaded (it is not the id order)
     * @private
     */
    private async initOrReloadRender(selectedComposant?: string) {
        const {order} = this.props

        const subOrdersByComposant = getSubOrdersByComponentMap(order)
        if (!selectedComposant) {
            selectedComposant = this.getComposants(subOrdersByComposant)[0] ?? noComposant
        }
        const orderSubOrderModelList: OrderSubOrderModel[] = [];
        let subOrdersList = subOrdersByComposant.get(selectedComposant) ?? []

        if (subOrdersByComposant.size === 1) {
            const weavingOrder = await this.getWeavingOrder(subOrdersList, 0)
            const subOrders = await this.getSubOrders(subOrdersList, 0)
            orderSubOrderModelList.push({subOrders, weavingOrder})
            this.setState({subOrdersByComposant, selectedComposant, orderSubOrderModelList})
            return;
        }

        for (let index = 0; index < subOrdersList.length; index++) {
            const weavingOrder = await this.getWeavingOrder(subOrdersList, index)
            const subOrders = await this.getSubOrders(subOrdersList, index)
            orderSubOrderModelList.push({subOrders, weavingOrder})
        }

        this.setState({subOrdersByComposant, selectedComposant, orderSubOrderModelList})
    }
}
