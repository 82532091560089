import i18n, { TOptions } from 'i18next';
import { initReactI18next } from 'react-i18next';

import en from './translations/en';
import it from './translations/it';
import fr from './translations/fr';
import Translation from '../api/Translation';
import Storage from '../helpers/Storage';

interface TranslationCategory {
  [key: string]: string;
}

let TRANSLATION_MAP: Record<string, any> = en;
let IS_SEE_TRANSLATION_KEY: boolean = false;

let serverTranslation: Record<string, string> = {};

export const hideShowKeys = async () => {
  await Storage.save('IS_SEE_TRANSLATION_KEY', !IS_SEE_TRANSLATION_KEY);
  IS_SEE_TRANSLATION_KEY = !IS_SEE_TRANSLATION_KEY;
};


Object.keys(en).forEach((catKey, idx) => {
  const subCat: TranslationCategory = {};
  Object.keys(en[catKey]).forEach((key) => {
    subCat[key] = catKey + '.' + key;
  });

  if (idx === 0) {
    TRANSLATION_MAP = {};
  }

  TRANSLATION_MAP[catKey] = subCat;
});

export { TRANSLATION_MAP as T };

export const __ = (key: string, interpolationMap?: TOptions, defaultValue?: string) => {
  return __NoDefault(key, interpolationMap) ?? (defaultValue ?? key)
};

export const __NoDefault = (key: string, interpolationMap?: TOptions) => {
  if (IS_SEE_TRANSLATION_KEY) {
    return key;
  }
  return serverTranslation[key] ?? i18n.t(key, interpolationMap)
};

export const __UP = (key: string, interpolationMap?: TOptions) => i18n.t(key, interpolationMap).toUpperCase();

export const __DEF_VALUE = (key: string, defaultValue: string, interpolationMap?: TOptions) => {
  const val = __(key, interpolationMap, defaultValue);
  if (!val) {
    return defaultValue;
  }
  return val;
};

export const changeLanguage = (locale: string) => {
  i18n.changeLanguage(locale);
};


const loadTranslationsFromServer = async (): Promise<any> => {
  try {
    return await Translation.getTranslations();
  } catch (error) {
    console.error('Errore nel caricamento delle traduzioni dal server', error);
    return {};
  }
};


export const initI18nX = async () => {

  IS_SEE_TRANSLATION_KEY = await Storage.load('IS_SEE_TRANSLATION_KEY', false);

  const localResources = {
    en: { translation: en },
    it: { translation: it },
    fr: { translation: fr }
  };
  serverTranslation = await loadTranslationsFromServer();
  // newTranslations[AppStore.language] = {
  //   translation: newTranslations
  // };
  // const mergedResources = JsonTools.mergeJSONObjects(localResources, newTranslations);

  await i18n
    .use(initReactI18next)
    .init({
      resources: localResources,
      lng: 'en',
      fallbackLng: 'en',
      interpolation: {
        escapeValue: false
      }
    });

};


// Funzione che gestisce l'evento della pressione dei tasti
function handleKeyPress(event: KeyboardEvent): void {
  // Verifica se è stato premuto il tasto "Ctrl" e "K"
  if (event.ctrlKey && event.key === 'K') {
    hideShowKeys();
    window.location.reload();
  }
}

// Aggiungi l'ascoltatore di eventi al documento
document.addEventListener('keydown', handleKeyPress);


export default i18n;
