import React, {RefObject} from 'react';
import {Alert, Button, Col, Divider, Dropdown, Menu, Row, Tag} from 'antd';
import { DetailedOrder, LibraryItem, Order, OrderDetailQrCodeSetting, SubOrder } from '../../api/types';
import {Layout, View} from '../../components';
import EntityPageHeader from '../../components/orders/EntityPageHeader';
import OrderFulfillmentForm from '../../components/orders/OrderFulfillmentForm';
import {__, T} from 'config/i18n';
import {showNotification} from '../../helpers/Notifications';
import {navigate, navigateBack} from '../../router';
import Orders from '../../api/Orders';
import ImportTraceFromAnotherPO from '../../tools/ImportTraceFromAnotherPO';
import ImportTraceDialog from '../../components/orders/ImportTraceDialog';
import AppStore from "../../AppStore";
import ImportLibraryDialog from "../../components/orders/ImportLibraryDialog";
import {resolveReportOrdersBasePath} from "../../routes";
import Title from "antd/es/typography/Title";
import AdvancedForm2 from "../../components/AdvancedForm2";
import {getOrderDetailSchema} from "../../components/layouts/schemas";
import TCareQrCode from '../../components/tCareQrCodes/TCareQrCode';
import DOMPurify from 'dompurify';
import EnquiryRejectReasonsSettingsTools from '../../tools/EnquiryRejectReasonsSettingsTools';
import qrCodeTools from '../../tools/QrCodeTools';

interface Props {
    match: { params: { childId: string, orderId:string, thirdPart: string, type?: string } };
}

interface State {
    order?: DetailedOrder
    fulfillmentOrDraftFormSubmitButtonRef: RefObject<HTMLButtonElement>
    draft: boolean
    isImportTraceModalVisible: boolean,
    isImportLibraryModalVisible: boolean,
}

export default class OrderFulfill extends React.Component<Props, State> {
    state: State = {
        fulfillmentOrDraftFormSubmitButtonRef: React.createRef<HTMLButtonElement>(),
        draft: false,
        isImportTraceModalVisible: false,
        isImportLibraryModalVisible: false
    };


    componentDidMount = async () => {
        const order = await Orders.get(this.props.match.params.childId);
        this.setState({order});
    };

    getPageHeaderExtras = (order: DetailedOrder) => {
        const buttons: JSX.Element[] = [];
        if (AppStore.appSettings.enableTraceLibrary) {
            buttons.push(
                <Dropdown.Button
                    key={"btn-" + buttons.length}
                    type="default"
                    className={"btn-secondary"}
                    overlay={
                        <Menu>
                            <Menu.Item onClick={() => this.openImporTraceModal()}>
                                {__(T.buttons.import_trace)}
                            </Menu.Item>
                            <Menu.Item onClick={() => this.openImportLibraryModal()}>
                                {__(T.buttons.import_library)}
                            </Menu.Item>
                        </Menu>}>
                    <span className="ant-dropdown-link" style={{marginLeft: 8}}
                          onClick={e => e.preventDefault()}>{__(T.buttons.import)}</span>
                </Dropdown.Button>)
        } else {
            buttons.push(
                <Button
                    key={"btn-importtrace"}
                    type="ghost"
                    style={{width: '200px', backgroundColor: __(T.colors.gray_button)}}
                    onClick={() => this.openImporTraceModal()}
                >
                    {__(T.buttons.import_trace)}
                </Button>);
        }
        if (this.shouldShowSaveDraftButton(order)) {
            buttons.push(
                <Button
                    key={"btn-savedraft"}
                    type="ghost"
                    style={{width: '200px', backgroundColor: __(T.colors.gray_button)}}
                    onClick={() => this.handleFulfillmentOrDraftSubmit(true)}
                >
                    {__(T.buttons.save_draft)}
                </Button>
            );
        }
        if (this.shouldShowCompleteTraceButton(order)) {
            buttons.push(
                <Button type="primary"
                        key={"btn-complete"}
                        style={{width: '200px'}}
                        disabled={!this.isCompleteTraceEnable(order)}
                        onClick={() => this.handleFulfillmentOrDraftSubmit(false)}>
                    {__(T.buttons.complete_trace)}
                </Button>
            );
        }
        if (this.shouldShowModifyTraceButton(order)) {
            buttons.push(
                <Button
                    type="primary"
                    key={"btn-modify"}
                    style={{width: '200px'}}
                        onClick={() => this.handleFulfillmentOrDraftSubmit(false)}>
                    {__(T.buttons.modify_trace)}
                </Button>
            );
        }
        return buttons;
    };

    isCompleteTraceEnable(order: DetailedOrder): boolean {
        if (AppStore.appSettings.enableEnquiryBlockIfChildNotCompletedOrCancelled) {
            return this.allChildrenAreCompletedOrCancelledOrApproved(order.orders);
        }
        return true;
    }

    allChildrenAreCompletedOrCancelledOrApproved(subOrders: SubOrder[]): boolean {
        for (let i = 0; i < subOrders.length; i++) {
            if (!(subOrders[i].status === "COMPLETED"
                || subOrders[i].status === "CANCELLED"
                || subOrders[i].status === "APPROVED")) {
                return false;
            }
            if (!this.allChildrenAreCompletedOrCancelledOrApproved(subOrders[i].orders)) {
                return false;
            }
        }
        return true;
    }

    onCancelImportLibrary = () => {
        this.setState({isImportLibraryModalVisible: false});
    }

    openImportLibraryModal = () => {
        this.setState({isImportLibraryModalVisible: true});
    };

    openImporTraceModal = () => {
        this.setState({isImportTraceModalVisible: true});
    };

    onConfirmImportLibrary = (item: LibraryItem, override) => {
        let tempOrder = JSON.parse(JSON.stringify(this.state.order));
        ImportTraceFromAnotherPO.importData(tempOrder, item.data, override);
        this.setState({order: undefined});
        this.setState({order: tempOrder, isImportLibraryModalVisible: false});
    }

    handleFulfillmentOrDraftSubmit = (draft: boolean) => {
        this.setState({draft}, () => this.state.fulfillmentOrDraftFormSubmitButtonRef.current?.click());
    };

    handleFulfillmentSuccess = (order) => {
        const {draft} = this.state;
        showNotification(
            __(T.titles.order_fulfillment_success),
            draft ? __(T.messages.order_fulfillment_draft) : __(T.messages.order_fulfillment_success),
            'success',
            3
        );
        if (!draft) {
            navigateBack();
        }
        this.setState({order: order});
    };

    navigateBackToDetail = () => {
        // TODO handle third party fulfillment navigation
        if (this.props.match.params.thirdPart) {
            navigateBack();
        } else {
            navigate(resolveReportOrdersBasePath(this.props.match.params.type) + '/detail/:orderId', {orderId: this.props.match.params.orderId});
        }
    };

    hideModal = () => this.setState({isImportTraceModalVisible: false});

    importTrace = (order: Order, overrideExistingDataInput: boolean) => {
        this.importTraceFromAnotherPO(order, overrideExistingDataInput);
    };

    private shouldShowOrderDetail = (order: DetailedOrder) => {
        return order.attributes && Object.keys(order.attributes).length > 0;
    };

    render = () => {
        const {thirdPart} = this.props.match.params;
        const {order, fulfillmentOrDraftFormSubmitButtonRef, draft} = this.state;
        let orderDetailQrCodeSetting: OrderDetailQrCodeSetting | undefined;
        if (!!order) {
            orderDetailQrCodeSetting = qrCodeTools.getParsedOrderDetailQrCodeSetting(order.traceType.code);
        }
        return (
            <Layout>
                {order && (
                    <>
                        <View style={{margin: 0, marginBottom: 10}}>
                            <EntityPageHeader
                                data={order}
                                extras={this.getPageHeaderExtras(order)}
                                onBack={_ => this.navigateBackToDetail()}
                                config={'orderPageHeader'}
                                extraConfigType={order.traceType.code}
                            />
                        </View>
                        {this.shouldShowOrderDetail(order) &&
                            <View className="page-table" style={{margin: 10, marginTop: 0, borderRadius: '0px'}}>
                                <Title level={4}>{__(T.titles.purchase_order_details)}</Title>
                                <Divider/>
                                <Row>
                                    <Col span={16}>
                                        <AdvancedForm2 schema={getOrderDetailSchema(order.traceType.code)}
                                                       values={order}
                                                       onValuesChange={() => {
                                                       }}/>
                                    </Col>
                                    {!!orderDetailQrCodeSetting && orderDetailQrCodeSetting.onlyRoot === order.root &&
                                      <Col span={8}
                                           style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                          <TCareQrCode order={order} size={160} setting={orderDetailQrCodeSetting.value} format={AppStore.appSettings.formatDownloadQrCode} />
                                      </Col>
                                    }
                                </Row>
                            </View>
                        }
                        {order.status === 'DRAFT' && !!order.reject /*&& order.reject.note*/ && (
                            <View className="page-table" style={{margin: 10, marginTop: 0, borderRadius: '0px'}}>
                                <Alert
                                    message={(__(T.warnings.feedback_reject))}
                                    description={
                                        <div>
                                            <div style={{whiteSpace: 'pre-wrap'}}>
                                                {DOMPurify.sanitize(order.reject.note)}
                                            </div>
                                            {order.reject.reasons.map(item => (
                                                <Tag key={item + '_tag_errorValidation'}
                                                     color={EnquiryRejectReasonsSettingsTools.getColorByCode(item)}>
                                                    {item}
                                                </Tag>
                                            ))}
                                        </div>
                                    }
                                    type="warning"
                                    showIcon
                                />
                            </View>)}
                        {(!this.shouldShowOrderDetail(order) && !!orderDetailQrCodeSetting && orderDetailQrCodeSetting.onlyRoot === order.root) &&
                          <View className="page-table" style={{margin: 10, marginTop: 0, borderRadius: '0px'}}>
                              <Row>
                                  <Col span={8} style={{ display: 'flex'}}>
                                      <TCareQrCode order={order} size={160} setting={orderDetailQrCodeSetting.value} format={AppStore.appSettings.formatDownloadQrCode}/>
                                  </Col>
                              </Row>
                          </View>
                        }
                        <View className="page-table" style={{margin: 10, marginTop: 0, borderRadius: '0px'}}>
                            <Col span={16}>
                                <OrderFulfillmentForm
                                    order={order}
                                    onChange={o => {
                                        this.setState({order: o});
                                    }}
                                    onFulfillmentSuccess={this.handleFulfillmentSuccess}
                                    fulfillmentOrDraftFormSubmitButtonRef={fulfillmentOrDraftFormSubmitButtonRef}
                                    thirdPart={thirdPart === 'true'}
                                    draft={draft}
                                />
                            </Col>
                        </View>
                      <ImportTraceDialog
                          isVisible={this.state.isImportTraceModalVisible}
                          onCancel={this.hideModal}
                          onSuccess={this.importTrace}
                          traceTypeCode={order!.traceType.code}
                          title={__(T.titles.import_trace_dialog)}
                      />
                      <ImportLibraryDialog
                          isVisible={this.state.isImportLibraryModalVisible}
                          onCancel={this.onCancelImportLibrary}
                          onSuccess={this.onConfirmImportLibrary}
                          traceTypeCode={order.traceType.code}
                          title={__(T.titles.import_library_dialog)}/>
                    </>
                )}
            </Layout>

        );
    };

    shouldShowSaveDraftButton = (order: DetailedOrder) => {
        return order.status !== 'FULFILLED' && order.status !== 'COMPLETED' && order.status !== 'APPROVED';
    };

    shouldShowCompleteTraceButton = (order: DetailedOrder) => order.status !== 'FULFILLED' && order.status !== 'COMPLETED' && order.status !== 'APPROVED';

    shouldShowModifyTraceButton = (order: DetailedOrder) => order.status === 'FULFILLED' || order.status === 'COMPLETED';

    private async importTraceFromAnotherPO(orderFromDialogImport: Order, overrideExistingDataInput: boolean) {
        let tempOrder = JSON.parse(JSON.stringify(this.state.order));
        const orderToImportSchema = await Orders.get(orderFromDialogImport.id!);
        ImportTraceFromAnotherPO.importData(tempOrder, orderToImportSchema.data, overrideExistingDataInput);
        //Non rimuovere il set undefined
        this.setState({order: undefined});
        this.setState({order: tempOrder, isImportTraceModalVisible: false});
    }


}
