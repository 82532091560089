import React from 'react';
/*
import { Button, Col, Row } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import Text from '../Text';
import { __, T } from '../../config/i18n';
 */
import { AppForm } from '../index';

interface Props {
  schema: any;
  data: any;
}

//TODO: integrazione definitions
export default class JsonFormOnReadView extends React.Component<Props> {

  /*
    renderFormRecursive(data: any, props: any) {
      let keys = Object.keys(props)

      return keys.map(key => {
        const field = props[key]
        const value = data[key]

        if (props[key].type === 'array') {
          //fai un for sull'array
          return <Col span={!!field.span ? field.span * 2 : 12} style={{marginTop: 0, marginBottom: 20}} key={'col_' + field.title}>
            {field.title && field.title.length > 0 ?
                <h3>{field.title}</h3> : <></>}
            {value && value.map(item =>
              <Row style={{paddingLeft:'12px'}} key={'key_' + value.indexOf(item)}>{this.renderFormRecursive(item, props[key].items.properties)}</Row>
            )}
          </Col>
        } if (props[key].type === 'object') {
          return <Col span={!!field.span ? field.span * 2 : 12} style={{marginTop: 0, marginBottom: 20}} key={'col_' + field.title}>
            {field.title && field.title.length > 0 ?
                <h3 >{field.title}</h3> : <></>}
            <Row style={{paddingLeft:'12px'}}>{value && this.renderFormRecursive(value, props[key].properties)}</Row>
          </Col>

        } else {
              return (
                  <Col span={!!field.span ? field.span * 2 : 12} key={'col_' + field.title}>
                    {field.title && field.title.length > 0 ?
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                          {field.title && field.title.length > 0 ?
                              <Text style={{ marginTop: 10 }}>{field.title}</Text> : <></>}
                          {field.type === 'string' && field.format === 'data-url' && value ? (
                              <>
                                <Row justify="space-between" style={{ height: 38 }}>
                                  <Col>
                                    <Text style={{
                                      fontSize: 16,
                                      fontWeight: 700,
                                      color: '#000000'
                                    }}>{this.extractFilename(value)}</Text>
                                  </Col>
                                  <Col style={{ marginTop: -8 }}>
                                    <Button style={{ width: 250, color: '#000000' }}
                                            href={value} target="_blank" download={this.extractFilename(value)}>
                                      {__(T.misc.download_file)} <DownloadOutlined />
                                    </Button>
                                  </Col>
                                </Row>
                              </>
                          ) : (
                              this.renderSingleValue(value, field)
                          )}
                        </div>
                        : <></>}
                  </Col>
              );
            }
          }
      )
    }

    renderSingleValue(value, field) {
      if (!value) {
        return <Text style={{ color: '#B0B0B0', fontSize: 16, height: 38 }}> {'Undefined'}</Text>
      }

      if(field.type === 'boolean'){
        value = value ? 'Si' : 'No';
      }

      if(field.type === 'string' && field.enumLabel) {
        value = !!field.enumLabel[value] ? field.enumLabel[value] : value
      }

      return <Text style={{
        fontSize: 16,
        fontWeight: 700,
        color: '#000000',
        height: 38
      }}> {value + ''}</Text>
    }


    renderForm = (data: any, schema: any) => {
      return (
          <Row key={'row_detail'}>
            <Col span={16}>
              <Row>
                {this.renderFormRecursive(data, schema)}
              </Row>
            </Col>
          </Row>
      )
    }

    private extractFilename(value: any) {
      if (!!value) {
        const index = value.indexOf(';name=');
        if (index > 0) {
          const startIndex = index + 6;
          const endIndex = value.substr(startIndex).indexOf(';');
          return decodeURIComponent(value.substr(startIndex, endIndex));
        }
      }
      return '';
    }

  */

  render = () => {
    const { data, schema } = this.props;


    return (
      <AppForm readonly={true} schema={schema} data={data}/>
    );
  };
}
