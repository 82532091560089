import React, { Component, RefObject } from 'react';
import { AppForm, Text, View } from 'components';
import { Button, Col, Dropdown, Layout, Row, Spin, Steps, Typography } from 'antd';
import theme from 'theme/theme';
import { __, T } from 'config/i18n';
import AppStore from 'AppStore';
import { OnBoardings } from 'api';
import { DownOutlined } from '@ant-design/icons';
import { Company } from '../../api/types';
import { showNotification } from '../../helpers/Notifications';
import CompanyDataTabForm from '../companies/CompanyDataTabForm';
import MenuLanguage from '../../components/menu/MenuLanguage';
import CompanyMenu from 'components/menu/CompanyMenu';

const { Header } = Layout;

interface UserFields {
  name?: string;
  surname?: string;
  phone?: string;
  email?: string;
}


type FormFields = UserFields

interface State {
  onBoardingId?: string;
  company?: Company;
  fields: FormFields;
  currentStep: number;
  loading: boolean;
  attributesFormSubmitButtonRef: RefObject<HTMLButtonElement>;
}

export default class Registration extends Component<{}, State> {
  state: State = {
    fields: {},
    currentStep: 1,
    loading: false,
    attributesFormSubmitButtonRef: React.createRef<HTMLButtonElement>()
  };

  back = async () => {
    this.setState({ currentStep: this.state.currentStep - 1 });
    window.scrollTo(0, 0);
  };

  next = async () => {
    this.setState(
      ({ currentStep }) => ({ currentStep: currentStep + 1 }),
      () => window.scrollTo(0, 0)
    );
  };

  componentDidMount = async () => {
    const onBoarding = await this.getOnboarding();
    if (onBoarding) {
      this.setState({
        onBoardingId: onBoarding.id ?? undefined,
        company: onBoarding.company ?? undefined,
        fields: {
          name: onBoarding.guest.firstName ?? undefined,
          surname: onBoarding.guest.lastName ?? undefined,
          email: onBoarding.guest.email ?? undefined,
          phone: onBoarding.guest.phone ?? undefined
        }
      });
    }
  };

  getOnboarding = async () => {
    const companyId = AppStore.loggedUser?.company?.id ?? undefined;
    return OnBoardings.search({ companyId, page: 0, size: 1 })
      .then(response => response?.content ?? [])
      .then(onBoardings => (onBoardings.length > 0 ? onBoardings[0] : undefined));
  };

  handleStepSelection = selectedStep => {
    if (selectedStep < this.state.currentStep) {
      this.setState({ currentStep: selectedStep });
    }
  };

  logout = async () => {
    await AppStore.setGoToDashboard(true);
    AppStore.keyCloak?.logout();
  };

  render() {
    const { currentStep } = this.state;
    const dropdownMenu = (
      <MenuLanguage/>
    );
    return (
      <Layout style={{ backgroundColor: '#FFFFFF' }}>
        <Header style={{ backgroundColor: theme.bgColor, padding: 10, display: 'inline-flex', alignItems: 'center' }}>
          <img src={require('../../assets/images/Logo_w.png').default} alt={__(T.misc.logo)} style={c.logo} />
          <View style={{ display: 'flex', flex: 1, justifyContent: 'flex-end', alignItems: 'center', color: '#FFF' }}>
            {/* <QuestionCircleOutlined onClick={() => window.open('https://www.temera.it/it/solutions', '_blank')} /> */}
            <Dropdown overlay={<CompanyMenu></CompanyMenu>}>
              <span className="ant-dropdown-link" style={{ marginLeft: 8 }} onClick={e => e.preventDefault()}>
                {AppStore.loggedUser?.company?.data.businessName}
                <DownOutlined />
              </span>
            </Dropdown>
            <Dropdown overlay={dropdownMenu}>
              <span className="ant-dropdown-link" style={{ marginLeft: 8 }} onClick={e => e.preventDefault()}>
                {AppStore.loggedUser?.userName} <DownOutlined />
              </span>
            </Dropdown>
          </View>
        </Header>
        <Spin spinning={this.state.loading} delay={500}>
          <View style={{ backgroundColor: '#FFFFFF' }}>
            <Steps
              style={{ maxWidth: 800, paddingLeft: 40, paddingTop: 20 }}
              current={currentStep}
              onChange={this.handleStepSelection}
            >
              <Steps.Step title={__(T.steps.company)} description={__(T.steps.fill_form)} />
              <Steps.Step title={__(T.steps.summary)} description={__(T.steps.confirm_data)} />
            </Steps>
            <View style={{ padding: 40 }}>
              {currentStep < 3 && this.renderStepForm()}
              {currentStep === 3 && (
                <div
                  style={{
                    textAlign: 'center',
                    marginTop: 100,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center'
                  }}
                >
                  <Text h1>{__(T.misc.congratulations)}</Text>
                  <Text>{__(T.messages.successfully_registered)}</Text>
                  <Text style={{ marginBottom: 15 }}>{__(T.messages.successfully_registered_email)}</Text>
                  <Button onClick={this.logout} style={{ ...theme.temeraPrimaryGreenButton, width: 300 }}>
                    {__(T.buttons.login_now)}
                  </Button>
                </div>
              )}
            </View>
          </View>
        </Spin>
      </Layout>
    );
  }

  onCompanyValueChange = (company: Company) => {
    this.setState({ company });
  };


  clickNext = async () => {
    if (this.state.currentStep === 1 || this.state.currentStep === 0) {
      this.state.attributesFormSubmitButtonRef.current?.click();
    } else if (this.state.currentStep === 2) {
      await this.uploadOnboarding();
    }
  };


  onUserValueChange = (values: FormFields) => {
    this.setState({ fields: { ...this.state.fields, ...values } });
  };


  renderStepForm = () => {
    const { currentStep, company } = this.state;
    return (
      <>
        {currentStep === 2 && <Typography.Title level={3}>{__(T.steps.confirm_data)}</Typography.Title>}
        {currentStep === 1 && company &&
          <Row>
            <Col span={12}>
              <CompanyDataTabForm
                company={company!}
                loading={false}
                onValueChange={this.onCompanyValueChange}
                formSubmitButtonRef={this.state.attributesFormSubmitButtonRef}
                onComplete={this.next}
              />
            </Col>
          </Row>}
        {currentStep === 2 && <Row>
          <Col span={12}><AppForm
            data={{
              company: { categories: company?.categories, ...company?.data, ...company?.attributes }
            }}
            schema={this.getSummarySchema()}
          />
          </Col></Row>}
        <Row>
          <Col span={12}>
            <div style={{ display: 'flex', gap: '20px' }}>
              <Button onClick={currentStep > 0 ? this.back : this.logout} type="default"
                      style={{ flexGrow: '1' }}> {__(T.buttons.back)}</Button>
              <Button onClick={this.clickNext} type="primary"
                      style={{ flexGrow: '1' }}>{__(T.buttons.registration_submit)}</Button>
            </div>
          </Col>
        </Row>

      </>
    );
  };

  getCompanySchema = () => {
    let schema = JSON.parse(AppStore.appSettings.companyJsonFormSchema);

    schema['properties'] = {
      legalCode: {
        title: __(T.fields.legal_code),
        type: 'string',
        span: 6,
        readOnly: true
      },
      businessName: {
        title: __(T.fields.business_name),
        type: 'string',
        span: 6
      },
      categories: {
        type: 'array',
        title: __(T.fields.category),
        items: {
          type: 'string',
          enum: AppStore.appSettings.categories.split('|')
        },
        uniqueItems: true,
        span: 6
      },
      ...schema['properties']
    };
    if (!!schema.required) {
      schema.required.push('businessName');
    } else {
      schema.required = ['businessName'];
    }
    return schema;
  };

  getSummarySchema = (): any => {

    let userProperties = {}
    Object.keys(this.getUserSchema().properties).forEach(key =>
      userProperties[key] = {...this.getUserSchema().properties[key],
        readOnly: true
      }
    )

    let companyProperties = {}
    Object.keys(this.getCompanySchema().properties).forEach(key =>
        companyProperties[key] = {...this.getCompanySchema().properties[key],
          readOnly: true
        }
    )

    return {
      required: [],
      type: 'object',
      title: '',
      properties: {
        company: {
          title: __(T.fields.company),
          type: 'object',
          span: 12,
          properties: companyProperties
        }
      }
    };
  };

  getUserSchema = (): any => {
    return {
      required: ['name', 'surname', 'email'],
      type: 'object',
      title: '',
      properties: {
        name: {
          title: __(T.fields.name),
          type: 'string',
          span: 6,
          name: 'name'
        },
        surname: {
          title: __(T.fields.surname),
          type: 'string',
          span: 6,
          name: 'surname'
        },
        phone: {
          title: __(T.fields.phone),
          type: 'string',
          span: 6,
          name: 'phone'
        },
        email: {
          title: __(T.fields.email),
          type: 'string',
          span: 6,
          name: 'email'
        }
      }
    };

  };

  uploadOnboarding = async () => {
    this.setState({ loading: true });
    if (this.state.onBoardingId) {
      const { fields, company } = this.state;
      // const googlePlaceDetail = await this.getGooglePlaceDetailFromCity(city)
      await OnBoardings.progress(this.state.onBoardingId, {
        address: company?.data.address,
        addressNumber: company?.data.addressNumber,
        postalCode: company?.data.cap,
        businessName: company?.data.businessName,
        city: company?.data.city,
        country: company?.data.country,
        phone: fields.phone,
        name: fields.name,
        surname: fields.surname,
        companyEmail: company?.data.email,
        companyEmailPec: company?.data.pec,
        companyMobilePhone: company?.data.mobilePhone,
        companyPhone: company?.data.phone,
        attributes: company?.attributes,
        categories: company?.categories
      });
      showNotification(__(T.messages.onboarding_loaded));
      await this.next();
    }
    this.setState({ loading: false });
  };
}

const c: Record<string, React.CSSProperties> = {
  container: {
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1
  },
  logo: { width: '135px', height: 'auto', marginBottom: 20, marginTop: 15 },
  title: { textAlign: 'center', fontSize: '20px' },
  subtitle: {
    textAlign: 'center',
    marginTop: -30,
    marginBottom: '15%',
    fontWeight: 300,
    fontSize: '1rem'
  },
  form: {
    width: 360
  },
  page: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#eee',
    padding: '0 0 50px 0'
  },
  registrationText: { textAlign: 'center', fontSize: '14px' }
};
