import {Layout, Modal, View} from 'components'
import {__, T} from 'config/i18n'
import {Button, Col, Form, List, Row, Select, Spin, Typography} from 'antd'
import {navigate} from 'router'
import theme from 'theme/theme'
import {Companies, Users} from 'api'
import {showNotification} from 'helpers/Notifications'
import {FormInstance} from "antd/es/form"
import AdvancedForm2 from "../../components/AdvancedForm2"
import {Schema} from "../../components/AdvancedForm"
import {role, UserFields} from "../../types"
import {QueryParams, User} from "../../api/types"
import { getOperationsForRole, isAdmin, isAdminSupplier, isSupplier } from "../../helpers/permission";
import AppStore from "../../AppStore";
import errorPrint from "../../tools/ErrorPrint";
import api, { setOffGlobalSpinningOnHeader } from 'api/api'
import { DeleteOutlined } from '@ant-design/icons'
import React from 'react'

const {Text} = Typography

interface UserEditCompany {
    label: string
    value: string
}

interface Props {
    match: { params: { userId?: string } };
}

interface UserEditFormFields extends UserFields {
    role: role
    companies: UserEditCompany[]
}

interface State {
    companies: UserEditCompany[]
    formFields: UserEditFormFields
    loading: boolean
    showConfirmDialog: boolean
    user?: User
    userRole?: role

    defaultCompanyValue?: any // Campo tecnico
}

export default class UserEdit extends React.Component<Props, State> {
    state: State = {
        companies: [],
        formFields: {
            role: 'supplier',
            companies: []
        },
        loading: false,
        showConfirmDialog: false,
        defaultCompanyValue: null
    }

    formRef: React.RefObject<FormInstance>

    constructor(props: Props) {
        super(props)
        this.formRef = React.createRef<FormInstance>()
    }

    componentDidMount = async () => {
        const userId = this.props.match.params.userId;

        if (userId) {
            this.setState({loading: true})
            const user = await Users.get(userId)
            const role = await Users.findRoleByUserId(userId)

            this.setState({
                companies: [],
                formFields: {
                    role: role.name,
                    companies: !user.companies ? [] : user.companies.map(company => ({
                        value: company.id,
                        label: company.data?.businessName || company.companyCode!,
                    })),
                    name: user.firstName,
                    surname: user.lastName,
                    phone: user.phone,
                    email: user.email
                },
                user: user,
                userRole: role.name
            })
            this.setState({loading: false})
        } else {
            this.setState({
                companies: [],
                formFields: {
                    ...this.state.formFields,
                    companies: isAdminSupplier() ?
                        [
                            {
                                value: AppStore.loggedUser?.company?.id,
                                label: AppStore.loggedUser?.company?.data?.businessName || AppStore.loggedUser?.company?.companyCode!,
                            }
                        ] :
                        []
                }
            })
        }
    }

    userEdit = async () => {
        const {formFields, user} = this.state

        const companies = formFields.role === 'admin' || formFields.role === 'viewer' ? [] : formFields.companies
        const companyIds = companies.map(company => company.value)

        this.setState({loading: true})

        if (!user) {
            const dbUser = await Users.findByUsername(formFields.email!)
            if (dbUser) {
                showNotification(__(T.messages.user_already_exist), undefined, 'error')
            } else {
                await Users.createByCommand({
                    companyIds,
                    email: formFields.email!,
                    name: formFields.name!,
                    surname: formFields.surname!,
                    phone: formFields.phone,
                    role: formFields.role
                })
                showNotification(__(T.messages.user_added))

                navigate('/reports/users')
            }
        } else {
            await Users.updateByCommand({
                userId: user!.id,
                companyIds,
                name: formFields.name!,
                surname: formFields.surname!,
                phone: formFields.phone,
                role: formFields.role
            })
            showNotification(__(T.messages.user_updated))

            navigate('/reports/users')
        }

        this.setState({loading: false})
    }

    getRoles = () => {
        let visibleRoles = AppStore.appSettings.visibleRoles;
        const visibleRolesArray = this.splitCommaSeparatedValues(visibleRoles);

        let roles = [
            {label: 'Admin supplier', value: 'admin-supplier'},
            {label: 'Supplier', value: 'supplier'}
        ];

        if (isAdmin()) {
            roles.unshift({label: 'Viewer Supplier', value: 'viewer-supplier'});
            roles.unshift({label: 'Viewer', value: 'viewer'});
            roles.unshift({label: 'Admin', value: 'admin'});
        }

        return visibleRolesArray.length === 0 ?
            // se la lista dei ruoli definiti nei setting è vuota di default i mostriamo tutti
            roles :
            // altrimenti filtriamo
            roles.filter(role => visibleRolesArray.includes(role.value));
    }

    splitCommaSeparatedValues(input: string | undefined): string[] {
        if (input === undefined || input.trim() === "") {
            return [];
        }
        return input.split(',').map(value => value.trim());
    }

    onValuesChange = (changedValues: Partial<UserEditFormFields>, values: UserEditFormFields) => {
        this.setState({
            formFields: {
                ...values,
                companies: values.role === 'admin' || values.role === 'viewer' ? [] : this.retrieveCompanies(values)
            }
        })
    }

    retrieveCompanies = (values: UserEditFormFields) => {
        return values.companies ? values.companies : this.state.formFields.companies;
    }

    addCompany = (companyId: string) => {
        const {formFields, companies} = this.state

        const formCompanies = formFields.companies ?? []
        if (!formCompanies.some(c => companyId === c.value)) {
            const company = companies.filter(c => companyId === c.value)[0];
            this.setState({
                formFields: {
                    ...formFields,
                    companies: [...formCompanies, company]
                }
            })
        }
    }

    removeCompany = (company: UserEditCompany) => {
        const {formFields} = this.state

        if (formFields.companies.some(c => c.value === company.value)) {
            this.setState({
                formFields: {
                    ...formFields,
                    companies: formFields.companies.filter(c => c.value !== company.value)
                }
            })
        }
    }

    getSchema = (): Schema[] => {
        const {formFields, companies, user, defaultCompanyValue, userRole} = this.state
        let companiesId;
        if (Array.isArray(formFields.companies)) {
            companiesId = formFields?.companies?.map((c: any) => c.value) ?? [];
        }

        return [
            {
                name: 'name',
                type: 'text',
                label: __(T.fields.name),
                placeholder: __(T.misc.insert),
                col: {xs: 24, sm: {span: 12}},
                rules: [{required: true, message: __(T.messages.field_required)}]
            },
            {
                name: 'surname',
                type: 'text',
                label: __(T.fields.surname),
                placeholder: __(T.misc.insert),
                col: {xs: 24, sm: {span: 12, pull: 26, offset: 26}},
                rules: [{required: true, message: __(T.messages.field_required)}]
            },
            {
                name: 'email',
                type: 'text',
                label: __(T.fields.email),
                placeholder: __(T.misc.insert),
                defaultValue: formFields.email,
                col: {xs: 24, sm: {span: 12, pull: 26, offset: 26}},
                rules: [{required: true, message: __(T.messages.field_required)}],
                disabled: user !== undefined && user.email !== undefined
            },
            {
                name: 'phone',
                type: 'text',
                label: __(T.fields.phone),
                placeholder: __(T.misc.insert),
                col: {xs: 24, sm: {span: 12}}
            },
            {
                name: 'role',
                type: 'select',
                label: __(T.misc.role),
                options: this.getRoles(),
                col: {xs: 24, sm: {span: 24, pull: 26, offset: 26}},
                defaultValue: 'supplier',
                initialValue: formFields.role,
                rules: [{required: true, message: __(T.messages.field_required)}],
                disabled: !isAdmin() && (userRole === 'admin' || userRole === 'viewer')
            },
            {
                name: 'companies',
                label: __(T.fields.companies),
                type: 'autocomplete',
                placeholder: __(T.misc.select),
                urlSearch: api.getBaseURL() + Companies.getEndpoint() + '/select?' + Companies.getAutocompleteQueryParam() + '=-X-&withOnBoardingCreatorCompanyId=true',
                header: setOffGlobalSpinningOnHeader(),


                rules: [
                    () => ({
                        required: formFields.role !== 'admin',
                        message: __(T.messages.field_required)
                    }),
                ],
                hide: formFields.role === 'admin' || formFields.role === 'viewer',
                render: () => <>
                    <Select
                        style={{width: '100%'}}
                        showSearch={true}
                        filterOption={false}
                        placeholder={__(T.misc.select)}
                        value={defaultCompanyValue}
                        disabled={!isAdmin()}
                        onSearch={(search) => { this.searchCompanies(search) }}
                        onFocus={() => { this.searchCompanies('') }}
                        onChange={item => this.addCompany(item)}
                    >
                        {companies
                            .filter(company => !companiesId.includes(company.value))
                            .map(company => (
                                <Select.Option key={company.value} value={company.value}>
                                    {company.label}
                                </Select.Option>
                            ))}
                    </Select>
                    <List
                        size="large"
                        itemLayout="horizontal"
                        dataSource={formFields.companies}
                        renderItem={item => (
                            <List.Item>
                                <View className="sc-step-box">
                                    <Text onClick={() => this.openCompanyDetail(item.value)}
                                          style={{flex: 1, cursor: "pointer"}}>{item.label}</Text>
                                    {isAdmin() &&
                                        <DeleteOutlined
                                            style={{fontSize: 18}}
                                            onClick={() => this.removeCompany(item)}/>
                                    }
                                </View>
                            </List.Item>
                        )}
                    />
                </>
            }
        ]
    }

    async searchCompanies(search) {
        let values: { label: any; value: any; }[] = []
        let params: QueryParams = {
            page: 0,
            size: 100,
            fullText: search
        }

        params = {
            ...params,
            withOnBoardingCreatorCompanyId: isSupplier(),
            onBoardingCreatorCompanyId: AppStore.loggedUser?.company?.id
        }

        const companies = await Companies.search(params)

        if (companies && companies.content) {
            for (let s = 0; s < companies.content.length; s++) {
                values.push({
                    value: companies.content[s].id,
                    label: companies.content[s].data?.businessName || companies.content[s].companyCode,
                })
            }
        }
        values.sort((a, b) => a.label.localeCompare(b.label));
        this.setState({ companies: values })
    }

    openConfirmDialog = () => {
        this.setState({showConfirmDialog: true})
    }

    updateUserStatus = async () => {
        const {user} = this.state
        try {
            if (user && user.enabled) {
                await Users.disable(user.id)
                showNotification(__(T.messages.user_disabled))
            } else if (user && !user.enabled) {
                await Users.enable(user.id)
                showNotification(__(T.messages.user_enabled))
            }
            navigate('/reports/users')
        } catch (error) {
            this.setState({showConfirmDialog: false})
            errorPrint.printMessage(error)
        }
    }

    showEnable = (): boolean => {
        const {user} = this.state

        return user !== undefined &&
            (isAdmin() || isAdminSupplier()) &&
            user.id !== AppStore.loggedUser?.id
    }

    openCompanyDetail(id: string) {
        const permission = getOperationsForRole()
        if (permission.companiesReport) {
            navigate('/reports/companies/:companyId', {companyId: id})
        } else {
            navigate('/reports/company/:companyId', {companyId: id})
        }
    }

    render() {
        const {loading, formFields, user, showConfirmDialog} = this.state
        return (
            <Layout>
                <Spin spinning={loading}>
                    <View style={{padding: 20}}>
                        <View className="page-table">
                            {this.showEnable() &&
                                <Row gutter={24} justify={'end'}>
                                    <Col span={6}>
                                        <Form.Item style={{marginBottom: 0}}>
                                            <Button
                                                type="primary"
                                                htmlType="button"
                                                style={{...{width: '100%'}, ...theme.temeraPrimaryGreenButton}}
                                                onClick={this.openConfirmDialog}
                                            >
                                                {__(!!user?.enabled ? T.buttons.suspend : T.buttons.enable)}
                                            </Button>
                                        </Form.Item>
                                    </Col>
                                </Row>
                            }
                            <AdvancedForm2
                                formRef={this.formRef}
                                schema={this.getSchema()}
                                values={formFields}
                                onValuesChange={this.onValuesChange}
                            />
                            <Row gutter={24} justify={'end'}>
                                <Col span={6}>
                                    <Form.Item style={{marginBottom: 0}}>
                                        <Button
                                            type="default"
                                            htmlType="button"
                                            style={{...{width: '100%'}, ...theme.temeraSecondaryDarkButton}}
                                            onClick={() => {
                                                navigate('/reports/users')
                                            }}
                                        >
                                            {__(T.buttons.cancel)}
                                        </Button>
                                    </Form.Item>
                                </Col>
                                <Col span={6}>
                                    <Form.Item style={{marginBottom: 0}}>
                                        <Button
                                            type="primary"
                                            htmlType="button"
                                            style={{...{width: '100%'}, ...theme.temeraPrimaryGreenButton}}
                                            onClick={() => {
                                                this.formRef.current?.validateFields().then(
                                                    () => this.userEdit()
                                                )
                                            }}
                                        >
                                            {__(!user ? T.buttons.new_user : T.buttons.update_user)}
                                        </Button>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </View>
                    </View>
                </Spin>
                <Modal
                    title={__(T.confirms.confirm_operation)}
                    message={__(T.confirms.confirm_or_go_back_to_change_the_data)}
                    visible={showConfirmDialog}
                    confirmButtonTitle={__(T.buttons.confirm)}
                    closeButtonTitle={__(T.buttons.back)}
                    onConfirmClick={this.updateUserStatus}
                    onCloseClick={() => {
                        this.setState({showConfirmDialog: false})
                    }}
                />
            </Layout>
        )
    }
}
