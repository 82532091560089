import { Page, QueryParams } from 'api/types';
import { AxiosRequestConfig } from 'axios'
import api, { responseErrorCheck } from './api'
import genericTools from '../tools/GenericTools';
import { showError, showNotification } from '../helpers/Notifications';
import { __, T } from '../config/i18n';

const endpoint = 'filemanager/v1/exportdata'

class ExportData {

  async newFileDownload(
      configuration: string,
      nameOfFile: string,
      type: string,
      path: string,
      size: number,
      params: QueryParams,
      axiosConfig?: AxiosRequestConfig): Promise<void> {

    const res = await api.post<any>(`${endpoint}/newFileDownload`, {
      'requestParams': genericTools.createRequestParams(params),
      'type': type,
      'configuration': configuration,
      'fileName': nameOfFile,
      'size': size,
      'requestUrl': window.location.protocol + '//' + window.location.host +'/' + path
    },
        axiosConfig);

    if (!!res && !!res.status && res.status > 204) {
      showError(String(res.status), __(T.error.generic_be_error))
    } else {
      showNotification(__(T.success.export_xls_title), __(T.success.export_xls))
    }
  }

  async search(params: QueryParams, axiosConfig?: AxiosRequestConfig) {

    const res = await api.get<Page<any>>(`${endpoint}`, params, axiosConfig)
    return responseErrorCheck(res)
  }

  async getFile(fileId: string, onDownloadProgress?: (ProgressEvent) => void, axiosConfig?: AxiosRequestConfig) {

      let axiosConfigMerged: AxiosRequestConfig = {
          responseType: 'blob',
          timeout: 200000,
          headers: {
              ... axiosConfig
          }
      }

    await api.get(`${endpoint}/getFile/${fileId}`, {},
        axiosConfigMerged)
      .then(response => {
        let filename;
        const disposition = response.headers!['content-disposition'];
        filename = disposition.split('filename=')[1];
        const a = document.createElement('a');
        a.href = window.URL.createObjectURL(response.data);
        a.download = filename;
        document.body.appendChild(a);
        a.click();
      })
      .catch(error => {
        // console.error(error);
        showError("Error", error.message)
      });

  }

}

const exportData = new ExportData()
export { exportData as default }
