import { AxiosRequestConfig } from 'axios'
import api, { responseErrorCheck } from './api'
import { QueryParams, Page } from 'api/types'

class ImportRule<T = any> {
  protected endpoint = 'tracemanager/v1/importrules'

  async search(params?: QueryParams, axiosConfig?: AxiosRequestConfig) {
    const res = await api.get<Page<T>>(`${this.endpoint}`, params, axiosConfig)
    return responseErrorCheck(res)
  }

  async save(data: any, axiosConfig?: AxiosRequestConfig) {
    const res = await api.post<T>(`${this.endpoint}`, data, axiosConfig)
    return responseErrorCheck(res)
  }

  async delete(id: string, axiosConfig?: AxiosRequestConfig) {
    const res = await api.delete<T>(`${this.endpoint}/${id}`, {}, axiosConfig)
    return responseErrorCheck(res)
  }

  async enable(id: string, axiosConfig?: AxiosRequestConfig) {
    const res = await api.put<T>(`${this.endpoint}/${id}/enable`, {}, axiosConfig)
    return responseErrorCheck(res)
  }

  async disable(id: string, axiosConfig?: AxiosRequestConfig) {
    const res = await api.put<T>(`${this.endpoint}/${id}/disable`, {}, axiosConfig)
    return responseErrorCheck(res)
  }
}

const importRule = new ImportRule()
export { importRule as default }
