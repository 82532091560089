import React, { Component } from 'react'
import { Gauge } from '@ant-design/charts';


interface Props {
    forCent: number
    total: number
    active: number
    footerLabel: string
    franctionDigits?: number
}




export default class GradientGaugePlot extends Component<Props, {}> {

    runGradientGauge = () => {
        let config: any = {
            height: 250,
            percent: this.props.forCent === 0 ? 0.0000001 : this.props.forCent / 100,
            range: {
                color: 'l(1) 0:#3D76DD 1:#3D76DD',
            },
            startAngle: Math.PI,
            endAngle: 2 * Math.PI,
            indicator: null,
            statistic: {
                title: {
                    offsetY: -36,
                    style: {
                        fontSize: '36px',
                        color: '#4B535E',
                    },
                    formatter: () => (this.props.forCent)
                    .toFixed(this.props.franctionDigits === undefined ? 0 : this.props.franctionDigits) + '%',
                },
                content: {
                    style: {
                        fontSize: '15px',
                        lineHeight: '100px',
                        color: '#4B535E',
                    },
                    formatter: () => this.props.active + '/' + this.props.total + this.props.footerLabel,
                },
            },
        };
        return <Gauge {...config} />;
    };

    render() {
        return (
            this.runGradientGauge()
        )
    }
}




