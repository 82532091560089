const labels = {
  buttons: {
    yes: 'Si',
    no: 'No',
    addItem: 'Aggiungi',
    removeItem: 'Rimuovi',
    all: 'Tutti',
    unread: 'Da leggere',
    libraryNew_TRACE: 'Nuovo articolo',
    libraryNew_DOCUMENT: 'Nuovo documento',
    library_upload_document: 'Inserisci documento',
    libraryEdit_TRACE: 'Dettaglio articolo',
    libraryEdit_DOCUMENT: 'Modifica il documento della libreria',
    libraryList_TRACE: 'Elenco articoli libreria',
    libraryList_DOCUMENT: 'Elenco documentazione',
    add: 'Aggiungi',
    add_certificate: 'Aggiungi certificato',
    add_user: 'Aggiungi utente',
    add_new_order: 'Aggiungi nuova richiesta',
    analytic: 'Analitica',
    reopen: 'Riapri',
    all_reopen: 'Riapri tutto',
    reject: 'Rifiuta',
    rejectSubOrder: 'Rifiuta sotto-ordine',
    all_reject: 'Rifiuta tutto',
    reset: 'Ripristina',
    approve: 'Approva',
    all_approve: 'Approva tutto',
    assign: 'Assegna',
    assign_now: 'Assegna Ora',
    base_template_xls: 'Template di base .xls',
    bulk: 'Bulk actions',
    close: 'Chiudi',
    edit: 'Modifica',
    modify_attributes: 'Modifica attributi',
    enquiries_xls: 'richieste .xls',
    export: 'Esporta',
    export_xls: 'Esporta .xls',
    from_library: 'da libreria',
    import_from_template_xls: 'Importa da modello .xls',
    import_from_library: 'Importa da libreria',
    import_from_trace: 'da traccia',
    import_trace_xls: 'Importa file tracce',
    items_library: 'Libreria articoli',
    download_xls_template: 'Scarica Template',
    download_qr_code: 'Scarica QR code',
    back: 'Indietro',
    cancel: 'Cancella',
    companies_detail: 'Dettaglio Aziende',
    companies_management: 'Gestione Fornitori',
    company_detail: 'Dettaglio Azienda',
    company_management: 'Profilo Azienda',
    confirm: 'Conferma',
    downloads_management: 'Dettaglio Downloads',
    next: 'Avanti',
    create: 'Crea',
    create_new: 'Crea nuovo',
    create_new_by_vat_code: 'Crea o trova con P.IVA CF',
    find_or_create_by_vatCode: 'Crea/Cerca per P.IVA',
    generate: 'Genera',
    save_without_generate: 'Chiudi',
    create_trace: 'Crea traccia',
    complete_trace: 'Completa traccia',
    complete_all_trace: 'Completa tutte le trace',
    dashboard: 'Home',
    delete: 'Elimina',
    disable: 'Disabilita',
    disapprove: 'Disapprova',
    all_disapprove: 'Disapprova tutto',
    documentation: 'Documentazioni',
    download_pdf: 'Download pdf',
    enable: 'Attiva',
    export_trace_in_CSV: 'Esporta CSV traccia',
    forgot_pwd: 'Password dimenticata',
    fulfill: 'Fulfill',
    invite: 'Invita',
    invite_new: 'Invita Nuova',
    new: 'Nuovo',
    modify: 'Modifica',
    modify_library: 'Modifica libreria',
    login: 'Accedi',
    login_now: 'Accedi ora!',
    logout: 'Esci',
    exit: 'Esci',
    modify_trace: 'Modifica traccia',
    mark_as_read: 'Segna come letto',
    mark_as_unread: 'Segna come da leggere',
    new_subsupplier: 'Nuovo Subfornitore',
    new_user: 'Nuovo utente',
    onboarding: 'Onboarding',
    open: 'Apri',
    order_assignment: 'Assegnazione Richiesta',
    order_detail: 'Dettaglio Richiesta',
    order_fulfillment: 'Completa Richiesta',
    order_handmade: 'Modifica ordine',
    orders_report: 'Gestione Richieste',
    orders_report_dynamic: 'Gestione',
    preview: 'Anteprime',
    profile_managment: 'Profilo',
    proceed: 'Procedere',
    registration_submit: 'Avanti',
    save: 'Salva',
    import_and_complete: 'Importa e Completa',
    import_and_save_draft: 'Importa e Salva Bozza',
    select_file: 'Seleziona file',
    send: 'Spedisci',
    import: 'Importa',
    import_library: 'Importa la libreria',
    import_trace: 'Import Trace',
    save_draft: 'Salva Bozza',
    send_by_email_pdf: 'Invia pdf via e.mail',
    subsuppliers: 'Subfornitori',
    suspend: 'Sospendi',
    trace_detail: 'Dettaglio traccia',
    traces_report: 'Controllo tracce',
    users_detail: 'Dettaglio utente',
    users_management: 'Gestione Utenti',
    view: 'Vedi',
    show_logs: 'Eventi',
    split: 'Splitta',
    remove: 'Rimuovi',
    reset_filter: 'Resetta filtro',
    reset_password: 'Resetta password',
    hide_trace: 'Nascondi traccia',
    show_trace: 'Mostra traccia',
    form_builder: 'Form builder',
    new_invitation: 'Nuovo Invito',
    traceability_settings: 'Traceability Settings',
    all_notifications: 'Tutte le notifiche',
    clone_order: 'Clona ordine',
    delete_order: 'Cancella ordine',
    block_order: 'Blocca ordine',
    mark_all_as_read: 'Segna tutto come letto',
    view_all_notifications: 'Visualizza tutte le notifiche',
    overrideExistingDataInput: 'Sostituisci l\'input di dati esistente nella traccia',
    more_unread: 'altro da leggere',
    override_existing_data_input_sub_title: 'La nuova traccia sostituirà tutti i dati esistenti inseriti',
    add_order: 'Crea un nuovo split',
    validation_test: 'Testa Validazione',
    import_onboarding_from_xls: 'Importa',
    export_onboarding_template: 'Esporta il template del onboarding',
    unlock_order: 'Sblocca ordine',
    download: 'Download'
  },
  confirms: {
    add_user: 'Sei sicuro di voler aggiungere l’utente?',
    cancel_subsupplier: 'Confermi la cancellazione del subfornitore',
    activate_subsupplier: 'Confermi l\' attivazione del subfornitore',
    confirm_operation: 'Sei sicuro di voler completare l’operazione?',
    confirm_or_go_back_to_change_the_data: 'Conferma oppure torna indietro per modifcare i dati',
    delete_user: 'Sei sicuro di voler cancellare l’utente?',
    delete_users: 'Sei sicuro di voler cancellare gli utenti?',
    delete_certificate: 'Sei sicuro di voler cancellare il certificato?',
    invite_company: 'Stai creando un’azienda. Verificare che il VAT number esista già o inserire un nuovo record.',
    delete_assignment: 'Sei sicuro di voler cancellare l\'ordine dall\'assegnazione? Tutti gli altri ordini con lo stesso ordine padre verranno cancellati.',
    delete_order: 'Sei sicuro di voler eliminare questo ordine?',
    reset_password: 'Sei sicuro di voler resettare la password?',
    warning_on_company_create: 'La partita iva è già presente nella tua lista. Sei sicuro di voler continuare?',
    block_order: 'Sei sicuro di bloccare l\'ordine',
    cancel_order: 'Sei sicuro di voler bloccare questo ordine?',
    unlock_order: 'Sei sicuro di voler sbloccare questo ordine?'
  },
  errors: {
    no_file_selected: 'Carica un file per continuare',
    password_not_valid: 'La password deve contenere da 6 a 20 caratteri che contengono almeno una cifra numerica, una maiuscola e una lettera minuscola.',
    the_passwords_are_different: 'La password e la password di conferma devono essere uguali',
    insert_company_code_or_piva: 'Occorre specificare almeno un valore tra Codice Azienda o P. IVA / C.F.',
    identifier_creation_failed: 'Creazione Identificatore Traccia fallito',
    trace_creation_failed: 'Creazione Traccia fallito',
    max_file_size_exceeded: 'Dimensione massima del file superata',
    file_not_found: 'File non trovato',
    file_upload_failed: 'Caricamento del file fallito',
    select_almost_one_reason: 'Seleziona almeno un motivo'
  },
  network: {
    CO: 'Cottone',
    WO: 'Lana',
    PL: 'Poliestere',
    AF: 'Altre fibre',
    WD: 'Super kid mohair',
    WM: 'Mohair',
    NY: 'Nylon'
  },
  orderType: {
    BUY_FINISH_PRODUCT: 'Buy',
    MAKE_RAW_MATERIAL: 'Make material',
    MAKE_FINISH_PRODUCT: 'Make product',
    SINGLE_MATERIAL: 'Singolo materiale'
  },
  fields: {
    productMatchCode: 'Codice match',
    supplier: 'Fornitore',
    orderType: 'Tipo ordine',
    enquiryId: 'Id riga',
    firstname: 'Nome',
    lastname: 'Cognome',
    inviteCompany: 'Invita Fornitore',
    action: 'Azione',
    actions: 'Azioni',
    address: 'Via / Piazza o Viale',
    address_number: 'N°',
    adherence_ethics_code: 'Aderenza codice etico',
    assignee_company: 'Assegnatario',
    business_name: 'Ragione Sociale',
    legal_code: 'Partita iva',
    category: 'Categoria',
    categories: 'Categorie',
    certifications: 'Certificazioni',
    city: 'Città',
    certificate_number: 'Numero del certificato',
    code: 'Codice',
    collector: 'Raccoglitore',
    color: 'Colore',
    color_code: 'Codice Colore',
    company: 'Azienda',
    companies: 'Azienda',
    company_datas: 'Dati azienda',
    company_name: 'Nome azienda',
    company_name_vat: 'Nome azienda  - VAT',
    company_type: 'Tipologia Azienda',
    washington_attestation_data: 'Dati di certificazione di Washington',
    country: 'Paese',
    creation_company: 'Supplier',
    last_login_date: 'Data ultima login',
    creation_date: 'Data creazione',
    destination: 'Destinazione',
    document_expiration_date: 'Data scadenza',
    document_item_article_name: 'Nome articolo',
    document_item_name: 'Nome',
    document_number: 'Numero documento',
    documents_and_certifications: 'Documenti e certificazioni',
    documents_for_nutrition: 'Documenti per Alimentazione',
    document_type: 'Tipo documento',
    email: 'Email',
    email_address: 'Indirizzo email',
    email_pec: 'Pec Email',
    error_validation: 'Errore di validazione',
    es_10: 'es. 10',
    expiration_date: 'Data di scadenza',
    farmer: 'Allevatore',
    feeding_documents: 'Documenti per alimentazione',
    file: 'File',
    filename: 'Nome file',
    health_certificate: 'Certificato Sanitario',
    hunter: 'Cacciatore',
    id: 'Id',
    intermediary_role: 'Ruolo intermediario',
    insert_reference_data: 'Inserisci i dati di riferimento',
    insert_file_name: 'Inserisci il nome del file',
    history: 'Eventi',
    library_item_name: 'Nome articolo',
    label: 'Cartellino',
    last_modify: 'Ultima modifica',
    license_concession: 'Licenza / Concessioni',
    load_certificates: 'Carica Documenti',
    load_scan_certificates: 'Carica doc. scannerizzati',
    load_ethic_certificate: 'Carica Codice Etico',
    load_here: 'Fare clic o trascina il file in quest\'area per caricarlo',
    lot: 'Lot',
    note: 'Note',
    not_matching_farmer: 'Agricoltori non corrispondenti',
    not_matching_slaughter: 'Macellazioni non corrispondenti',
    manual: 'Manuali',
    product: 'Material Descr. and Code',
    product_code: 'Material Code',
    product_composition: 'Material Composition',
    product_description: 'Material Description',
    rejected: 'Rifiutate',
    excel_row_counter: 'Numero di righe excel lette',
    updated_enquiry_counter: 'Numero di richieste aggiornate',
    not_updated_enquiry_counter: 'Numero di richieste non aggiornate',
    validation_error_enquiry_counter: 'Numero di righe non valide',
    not_update_number_row: 'Lista di numeri di righe non aggiornate',
    season: 'Season',
    shared: 'Condiviso',
    shared_with_brand: 'Condiviso con il brand',
    splitted: 'Splitted',
    event: 'Event',
    membership_association: 'Associazione di appartenenza',
    message: 'Messaggio',
    name: 'Nome',
    name_and_surname: 'Nome e Cognome',
    order_fiber: 'Componente',
    order_networks: 'Supply Chain',
    status: 'Stato',
    order_step: 'Step',
    other_certificates: 'Altri Certificati',
    password: 'Password',
    password_confirm: 'Conferma Password',
    phone: 'Telefono',
    phone_number: 'Cellulare',
    piva_cf: 'P. IVA / C.F.',
    postal_code: 'CAP',
    purchase_order: 'Ordinazione d\'acquisto',
    parent_order_code: 'Codice ordine genitore',
    purchase_order_code: 'Codice dell\'ordine di acquisto',
    purchase_order_date: 'Data dell\'ordine di acquisto',
    purchase_order_type: 'Tipo',
    purchase_date: 'Data di acquisto',
    quantity: 'Quantità',
    quantity_allowed: 'Quantità Permessa',
    referent: 'Referente',
    t1_filter: 'Onboarded By',
    sent_date: 'Data invio',
    slaughterhouse: 'Macello',
    style_code: 'Style Code',
    style_description: 'Style Description',
    subsupplier_name: 'Nome Subfornitore',
    suppression_method: 'Metodo di soppressione (opzionale: indica la legislazione rilevante)',
    surname: 'Cognome',
    tannery: 'Conceria',
    territory_of_competence: 'Territorio di competenza',
    trace_identifier: 'Identificativo Traccia',
    trace_type: 'Tipologia Traccia',
    type: 'Tipologia',
    typology: 'Tipologia',
    upload_date: 'Upload Data',
    user: 'Utente',
    username: 'Nome utente',
    veterinary: 'Veterinario',
    previous_traces: 'Tracce correlate',
    existing_company: 'Azienda esistente',
    existing_trace: 'Traccia esistente',
    new_trace_identifier: 'Nuovo Identificativo Traccia',
    company_code: 'Codice Azienda',
    notification_filter: 'Ricerca',
    or: 'Oppure',
    personal: 'Aziendale',
    third_part: 'Conto terzi',
    new_id: 'ID Nuovo',
    existing_id: 'ID Esistente',
    supplyChainName: 'Nome del componente',
    supplyChainFiber: 'Specifica i codici dei componenti',
    activate: 'Attiva',
    start_date: 'Data inizio',
    end_date: 'Data fine',
    rule_description: 'Descrizione regola',
    property: 'Proprietà',
    value: 'Valore',
    data: 'Data',
    date: 'Date',
    material_description: 'Descrizione Materiale',
    material_code: 'Codice Materiale',
    approve_order_modal_title: 'Approve order',
    approve_orders_modal_title: 'Approve orders',
    approve_order_modal_body: 'Do you want to approve the order?',
    approve_order_with_children_modal_body: 'Sicuro di voler approvare l ordine?<br> Procedendo saranno approvati anche i figli',
    approve_orders_modal_body: 'Do you want to approve <b>{{count}}</b> orders?',
    reopen_order_modal_title: 'Riapri la richiesta',
    reopen_orders_modal_title: 'Riapri {{count}} richieste',
    reopen_order_modal_body: 'Vuoi riaprire la richiesta?',
    reopen_orders_modal_body: 'Vuoi riaprire <b>{{count}}</b> richieste?',
    reject_order_modal_title: 'Stai rifiutando una richiesta<br>Per favore lascia un feedback',
    reject_orders_modal_title: 'Stai rifiutando {{count}} richieste<br>Per favore lascia un feedback',
    reject_order_modal_body: 'Inserisci qui il feedback (max 400 caratteri)',
    reject_orders_modal_body: 'Inserisci qui il feedback (max 400 caratteri)',
    delete_order_modal_title: 'Eliminazione ordine',
    delete_order_modal_body: 'Vuoi eliminare questo ordine?',
    disapprove_order_modal_title: 'Disapprovare la richiesta',
    disapprove_orders_modal_title: 'Disapprovare le richiesta',
    disapprove_order_modal_body: 'Stai disapprovando la richiesta?',
    disapprove_orders_modal_body: 'Stai disapprovando <b>{{count}}</b> richiesta?',
    confirmModalTitle: 'Conferma ',
    confirmModalBody: 'Stai per inviare la traccia, dopo non potrai più modificarla. Confermi l\'invio?',
    confirmModalButton: 'Invia adesso',
    cancelModalButton: 'Continua la modifica',
    remove_library_title: 'Rimuovi elemento',
    remove_library: 'Stai per cancellare questo elemento, confermi?',
    productionCity: 'Città di produzione',
    count_to_do: 'Da fare',
    count_rejected: 'Rifiutate',
    inviteCompanyAndUser: 'Invita un\'azienda e utente tramite onboarding',
    addCompany: 'Aggiungi un\'azienda',
    addCompanyAndUser: 'aggiunge una nuova azienda e un utente. Il nuovo utente compilerà i dati nella piattaforma per conto dell’azienda aggiunta',
    addCompanyNoAccess: 'aggiunge una nuova azienda senza nessun accesso alla piattaforma. La compilazione dei dati verrà fatta dagli utenti attuali per conto della nuova azienda',
    createdAt: 'Creato il:',
    updatedAt: 'Aggiornato il:',
    new: 'NUOVO',
    inProgress: 'IN CORSO',
    inError: 'IN ERRORE',
    completed: 'COMPLETATO',
    unknownState: 'STATO SCONOSCIUTO',
    withoutUsers: 'Senza utenti',
    neverLoggedUsers: 'Utenti mai connessi',
    suspended: 'Sospeso'
  },
  messages: {
    cloned: '[Clonato] ',
    account_login: 'Accedi al tuo account',
    complete_order_by_bulk_title: 'Esito completamento',
    complete_order_by_bulk_body: 'Richieste <b>completate</b>: {{completedEnquiries}}<br>Richieste <b>non validate</b>: {{notValidEnquiries}}<br>',
    company_added: 'Azienda aggiunta',
    company_confirmed: 'Azienda confermata',
    company_datas_updated: 'Dati azienda aggiornati',
    company_disabled: 'Azienda disabilitata',
    company_enabled: 'Azienda abilitata',
    confirm_or_go_back: 'Conferma oppure torna indietro per controllare i dati',
    field_required: 'Campo richiesto',
    email_sent: 'Email spedita',
    order_assignment_error: 'Si è verificato un errore nell\'assegnazione',
    order_assignment_success: 'La tua richiesta è stata assegnata',
    order_assignment_draft: 'Il tuo ordine di acquisto è stato salvato',
    order_fulfillment_error: 'Si è verificato un errore nell\'invio della tua richiesta',
    order_fulfillment_success: 'La tua richiesta è stata inviata',
    order_fulfillment_draft: 'La tua richiesta è stata salvata',
    order_complete_success: 'La tua richiesta è stata completata',
    order_remove_success: 'La tua richiesta è stata eliminata',
    order_reopen_success: 'La tua richiesta è stata riaperta',
    orders_reopen_success: 'Le tue richiesta sono state riaperte',
    order_reject_success: 'La tua richiesta è stata respinta',
    orders_reject_success: 'Le tue richiesta sono state respinte',
    order_approve_success: 'La tua richiesta è stata approvata',
    orders_approve_success: 'Le tue richieste sono state approvate',
    nothing_to_approve: 'Nessun ordine da approvare',
    orders_saved: 'La tua richiesta è stata salvata',
    order_saved_draft_with_errors: 'La tua traccia è stata salvata in draft con errori di validazione',
    password_required: 'Password richiesta',
    subsupplier_deleted: 'Subfornitore cancellato',
    subsupplier_disabled: 'Subfornitore disabilitato',
    subsupplier_enabled: 'Subfornitore abilitato',
    successfully_registered: 'Hai completato la registrazione con successo.',
    successfully_registered_email: 'Riceverai una conferma sulla tua casella di posta.',
    successfully_import_trace: 'La tua traccia è stata importata',
    successfully_import_library: 'La tua libreria è stata importata',
    user_added: 'Utente aggiunto',
    user_confirmed: 'Utente confermato',
    user_datas_updated: 'Dati utente aggiornati',
    user_deleted: 'Utente cancellato',
    user_disabled: 'Utente sospeso',
    user_enabled: 'Utente attivato',
    user_updated: 'Utente aggiornato',
    username_required: 'Nome utente richiesto',
    users_deleted: 'Utenti cancellati',
    no_certificate_found: 'Certificato non trovato',
    no_data_default: 'Nessun elemento',
    no_trace_found: 'Nessuna Traccia trovata',
    no_order_found: 'Nessun Ordiner trovato',
    invitation_successfully_created: 'Invito creato con successo',
    onboarding_approved: 'Onboarding approvato!',
    onboarding_business_name_already_exist_on_create: 'Non è possibile aggiungere questo fornitore perché esiste già un fornitore con la stessa ragione sociale. Se si desidera aggiungerlo tra i preferiti utilizzare la sezione “Gestione fornitori".',
    onboarding_company_exists: 'La company esiste già',
    onboarding_user_already_exist: 'Esiste già un fornitore con la stessa email.',
    onboarding_company_already_exist: 'Esiste già un fornitore con la stessa p.IVA.',
    onboarding_byVatCode_company_already_exist: 'Questo VAT number è già a sistema, verifica i dati inseriti per utilizzarli.',
    onboarding_business_name_already_exist_on_update: 'Esiste già un fornitore con la stessa ragione sociale.',
    business_name_cannot_be_identical_to_yours: 'La ragione sociale non può essere identica alla tua.',
    profile_in_revision: 'Profilo in approvazione',
    email_invitation_sent: 'Invito per Onboarding inoltrato nuovamente',
    onboarding_loaded: 'Onboarding creato con successo',
    attachment_loaded: 'Allegato caricato: ',
    select_trace_identifier_to_continue: 'Seleziona un identificativo traccia per continuare',
    create_trace_expired_certifications: 'Almeno uno dei tuoi certificati è scaduto. Puoi comunque portare a termine questa operazione ma il sistema terrà traccia di questa problematica.',
    create_trace_subsupplier_expired_certifications: 'Almeno uno dei certificati del tuo subfornitore è scaduto. Puoi comunque portare a termine questa operazione ma il sistema terrà traccia di questa problematica.',
    new_id_tooltip: 'Permette di creare una traccia che può essere correlata ad altre presenti nel sistema. L\'identificativo della traccia da correlare può essere ricercato dal menù a tendina "Id tracce correlate"',
    existing_id_tooltip: 'Permette di aggiornare le informazioni di una traccia presente nel sistema. L\'identificativo della traccia può essere ricercato dal menù a tendina.',
    trace_created: 'Traccia creata!',
    certificate_deleted: 'Certificato cancellato',
    supply_chain_saved: 'Dati Supply chain salvati correttamente',
    rule_saved: 'La nuova regola è stata correttamente salvata',
    new_item: 'Nuovo item',
    new_item_saved: 'Nuovo item è stato correttamente salvato',
    item_saved_title: 'Salvataggio item',
    item_saved: 'L\' item è stato correttamente aggiornato',
    import_onboarding_from_xls_error_count_max_line: 'Il file ha più di 5 righe',
    error: 'Errore'
  },
  category: {
    breeder: 'Allevatore',
    hunter: 'Cacciatore',
    tannery: 'Conceria',
    slaughterhouse: 'Macello',
    farmer: 'Allevatore',
    trader: 'Trader',
    picker: 'Raccoglitore',
    veterinary: 'Veterinario',
    manufacturer: 'Produttore',
    processor: 'Processore',
    weaver: 'Tessitrice',
    spinner: 'Spinner',
    ginner: 'Ginner',
    raw_material_source: 'Raw material source',
    scourer: 'Raschietto'
  },
  misc: {
    today: 'Oggi',
    previous: 'Precedenti',
    thisWeek: 'Questa settimana',
    minutesAgo: 'minuti fa',
    hoursAgo: 'ore fa',
    daysAgo: 'giorni fa',
    monthsAgo: 'mesi fa',
    active: 'Attivi',
    all_orders: 'Tutte le richieste',
    assigned: 'Assegnato',
    attached_max_sixe: 'Max 10MB (pdf, jpg, word, eps)',
    cancelled: 'Bloccato',
    completed: 'Completato',
    approved: 'Approvato',
    choose_rejects_reasons: 'Scegli il motivo del rifiuto',
    create_new_document: 'Crea nuovo documento',
    create_new_library_item: 'Crea nuovo articolo per la libreria',
    confirmed: 'Confermati',
    congratulations: 'Congratulazioni',
    delete_selected: 'Cancella selezionati',
    deselect: 'Deseleziona',
    draft: 'Bozza',
    new: 'Nuova',
    expiration: 'Scadenza',
    expired: 'Scaduto',
    expiring: 'In scadenza',
    error_change_current_user: 'La company è stata cambiata, la pagina verrà ricaricata in 10 secondi con l\'altra company',
    free_search: 'Ricerca libera',
    fulfilled: 'Fulfilled',
    id_lot_etc: 'ID, Lotto, etc.',
    insert: 'Inserisci',
    logo: 'Logo',
    no: 'No',
    personal_datas: 'Dati personali',
    powered_by: 'fornito da',
    remember_me: 'Ricordami',
    select: 'Seleziona',
    select_all: 'Seleziona tutto',
    select_an_option_and_proceed: 'Seleziona un\'opzione e procedi',
    select_the_type_of_track: 'Seleziona la tipologia di traccia',
    select_or_insert_new: 'Seleziona o Inserisci nuova',
    select_scope_certificate: 'Seleziona PDF, JPG, Word oppure EPS file (Max 5MB)',
    select_trace_order: 'Seleziona file xlsx (Max 5MB)',
    show_selected: 'Mostra i selezionati',
    supplier: 'Fornitore',
    temera_com: 'temera.com',
    to_be_assigned: 'Da assegnare',
    users: 'Utenti',
    valid: 'Valido',
    write: 'Scrivi',
    yes: 'Sì',
    username_name_email: 'Username, nome, email, etc.',
    start: 'Inizio',
    end: 'Fine',
    role: 'Ruolo',
    subsupplier: 'Subfornitore',
    representative: 'Rappresentante',
    business_name_email_etc: 'Ragione sociale, email, etc.',
    invited: 'Invitati',
    sent: 'Inviati',
    disabled: 'Sospesi',
    to_confirm: 'Da confermare',
    or: 'Oppure',
    personal: 'Aziendale',
    third_part: 'Conto terzi',
    expired_certifications: 'Certificati Non Validi',
    warning: 'Attenzione',
    click_to_download: 'Clicca per scaricare il certificato',
    download_file: 'Scarica il file',
    show_filtered: 'Mostra filtrati',
    show_all: 'Mostra tutti',
    loading: 'Caricamento...',
    error: 'Errore',
    empty: 'Vuoto',
    insertSupplierMsg: 'Inserire le informazioni del fornitore e della richiesta',
    main_configurations: 'Main configurations',
    main_configurations_title: 'Supply chain set up',
    main_configurations_subtitle: 'In questa sezione puoi gestire la supply chain t!Care',
    style_traceability: 'Style traceability',
    style_traceability_title: 'Style traceability',
    style_traceability_subtitle: 'In questa sezione puoi creare diverse eccezioni per selezionare cosa tracciare',
    new_fiber: 'Nuovo componente',
    edit_fiber: 'Modifica componente',
    edit_attributes: 'Modifica attributi',
    add_fiber: '+ Aggiunta componente',
    add_step: 'Add step',
    add_step_selected: 'Aggiungi step selezionati',
    modal_step_title: 'Aggiungi nuovi step alla supply chain',
    search: 'Cerca',
    create_new_rule: 'Crea nuova regola',
    add_new_property: 'Aggiungi nuova proprietà',
    rules_active: 'Attive',
    rules_archived: 'Archiviate',
    to: 'al',
    archive_rule_title: 'Archivia regola',
    archive_rule_subtitle: 'Sei sicuro di voler archiviare questa regola?',
    reopen_rule_title: 'Attivazione regola',
    reopen_rule_subtitle: 'Sei sicuro di voler attivare questa regola?',
    delete_rule_title: 'Elimina regola',
    delete_rule_subtitle: 'Sei sicuro di voler eliminare questa regola?',
    modal_detail_rule_title: 'Dettaglio regola',
    supply_chain_network: 'Supply chain network',
    remove_step_title: 'Rimuovi step',
    remove_step_subtitle: 'Sei sicuro di voler rimuovere questo step?',
    remove_supply_chain_title: 'Rimuovi Supply chain',
    remove_supply_chain_subtitle: 'Sei sicuro di voler rimuovere questa supply chain?',
    seasonEvents: 'Evento stagionale',
    styleCodes: 'Codice di stile',
    companyIds: 'Nome del fornitore',
    percentage: 'Percentuale',
    fiberCodes: 'Componente specifico',
    supplyChains: 'Supply chain',
    traceTypeIds: 'Step',
    chooseSupplier: 'Choose the supplier',
    insertPurchaseOrderAndDate: 'Insert purchase order codes and dates',
    insert_new_element: 'Inserisci un nuovo elemento'
  },
  steps: {
    trace_data: 'Dati della traccia',
    complete_trace: 'Tracia completata',
    confirm_data: 'Conferma i dati',
    fill_form: 'Compila tutti i campi',
    new_company: 'Nuova Azienda',
    company: 'Azienda',
    user: 'Utente',
    new_user: 'Nuovo Utente',
    summary: 'Conclusione'
  },
  titles: {
    split: 'Gestione Split',
    library_item_section_main: 'Dettagli articolo',
    library_item_section_new_main: 'Dati di riferimento',
    library_item_section_data: 'Dati della traccia',
    actions: 'Azioni',
    attach_document: 'Allega documento',
    companies_to_confirm: 'Aziende da confermare',
    company_data: 'Dati dell\'azienda',
    company_profile: 'Profilo Azienda',
    company: 'Azienda',
    create_personal_trace: 'Crea la tua traccia personale',
    create_third_part_trace: 'Stai creando una traccia per conto di ',
    document_item_section_main: 'Dati di riferimento',
    document_item_section_new_main: 'Inserisci i dati della referenza',
    document_item_section_data: 'Inserisci allegato',
    downloads: 'Downloads',
    order_assignment_error: 'Errore',
    order_assignment_success: 'Successo',
    order_fulfillment_error: 'Errore',
    order_fulfillment_success: 'Successo',
    order_complete_success: 'Successo',
    purchase_order_details: 'Dettagli dell\'ordine',
    purchase_order_fulfillment: 'Trace data',
    scope_certificates: 'Certificati',
    scope_certificate: 'Scope Certificate',
    success: 'Success',
    trace_identifier: 'Identificativo traccia',
    trace_informations: 'Informazioni traccia',
    trace_report: 'Trace report',
    users: 'Utenti',
    notification: 'Notifica',
    notificationTitle: 'Notifiche',
    import_trace_dialog: 'Seleziona l\'ordine da cui duplicare la traccia',
    import_library_dialog: 'Seleziona l\'articolo da cui duplicare la traccia',
    insert_email: 'Inserisci l\' indirizzo email',
    new_library_dialog: 'Seleziona il tipo di articolo da creare'
  },
  error: {
    splitOriginalOrderCompleted: 'Non posso \'splittare\' l\'ordine perché l\'originale è completato!',
    forbidden: 'Non hai i permessi richiesti per accedere a questa risorsa',
    not_found: 'La risorsa richiesta non esiste',
    unauthorized: 'Autenticazione fallita. Effettua il login e riprova',
    missing_trace_identifier: 'Identificativo mancante',
    identifier_already_existing: 'Questo identificatore esiste già',
    generic_be_error: 'Qualcosa non ha funzionato, riprova più tardi o contatta l\'amministratore',
    supply_chain_steps: 'Per proseguire devi selezionare almeno uno step',
    user_unauthorized_operation: 'L\'utente non è autorizzato a completare questa operazione'
  },
  success: {
    export_xls_title: 'Esportazione',
    export_xls: 'L\'esportazione è stata presa in carico; riceverai la notifica a breve.'
  },

  warnings: {
    feedback_reject: 'Questa traccia è stata rifiutata, leggi il feedback:'
  },
  user_not_found: {
    login_failed: 'Login non riuscito',
    user_does_not_exist: 'il sistema non riesce a trovare la tua utenza.',
    user_not_found_title: 'L’autenticazione potrebbe fallire per diversi motivi:',
    user_not_found_reason1: ' L’utenza non è attivata correttamente',
    user_not_found_reason2: 'Le credenziali non sono pronte nel sistema',
    user_not_found_contact_admin: 'Si prega di contattare l’amministratore di sistema. '
  },
  tags: {
    split: 'Split',
    rejected: 'Respinto',
    handmade: 'Manuale',
    errorValidation: 'Non valido'
  },
  orderLog: {
    IMPORT: 'Richiesta <b>Importata</b>',
    SPLIT_NEW_ROW: '{{user}} ha <b>Splittato</b> la richiesta nella quantità {{quantity}}',
    SPLIT_UPDATE: '{{user}} ha <b>Aggiornato </b> la quantità in {{quantity}}',
    REJECT: '{{user}} ha <b>Rifiutato</b> la richiesta<div class="order_log_citation">{{ message }}</div>',
    ASSIGN_CHILD: '{{user}} ha <b>Assegnato</b> la richiesta child',
    ASSIGNED: '{{user}} ha <b>Assegnato</b> la richiesta a {company}',
    DRAFT: '{{user}} ha <b>Salvato come Bozza</b> la richiesta',
    FULFILL: '{{user}} ha <b>Compilato</b> la richiesta',
    FULFILL_THIRD_PARTY: '{{user}} ha<b>Compilato</b> la richiesta',
    COMPLETE: '{{user}} ha <b>Completato</b> la richiesta',
    CANCEL: '{{user}} ha <b>Cancellato</b> la richiesta',
    APPROVE: '{{user}} ha <b>Approvato</b> la richiesta',
    REOPEN: '{{user}} ha <b>Riaperto</b> la richiesta',
    DISAPPROVE: '{{user}} ha <b>Annullato l\'approvazione</b> della richiesta'
  },
  GOLD: 'Leather Working Group (LWG) - Gold',
  SILVER: 'Leather Working Group (LWG) - Silver',
  BRONZE: 'Leather Working Group (LWG) - Bronze',
  UNI_11427: 'Pelli Ecologiche UNI 11427',
  EMAS: 'Dichiarazione Ambientale EMAS',
  UNI_EN_ISO_14001_2015: 'Sistema di gestione ambientale UNI EN ISO 14001:2015',
  UNI_EN_ISO_45001: 'Sistema di gestione salute e sicurezza sul lavoro UNI EN ISO 45001',
  UNI_CEI_EN_ISO_50001_2018: 'Sistema di gestione dell\'energia UNI CEI EN ISO 50001:2018',
  SA8000: 'Certificazione Etica SA8000',
  UNIC: 'Codice di Condotta e Responsabilità sociale UNIC',
  ICEC: 'Codice di condotta e Responsabilità sociale ICEC',
  UNI_EN_16484: 'Denominazione di origine della produzione della pelle UNI EN 16484',
  ICEC_TS_SC_410___ICEC_TS_PC412: 'Tracciabilità materie prime a monte della conceria ICEC TS SC 410 / ICEC TS PC412',
  CENTRIC: 'CENTRIC Tool di misurazione della circolarità',
  UNI_EN_ISO_14040__14044_o_14072: 'Valutazione del ciclo di vita (LCA) secondo norme UNI EN ISO 14040, 14044 o 14072',
  other_certification: 'Altra certificazione',
  bulkApproveDialog: {
    all_order_approved: 'Tutti i  {{approved}} ordini sono stati approvati',
    title: 'Approvazione in Massa',
    approved: ' ordini approvati su ',
    notApproved: ' ordini non approvati, perché i figli non sono',
    approved_key: 'APPROVATI',
    forceApproveQuestion: 'Vuoi forzare l approvazione (e dei loro figli)?'
  }
};

export default labels;
