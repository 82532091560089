export const validateSum = (validationRule, data, error) => {
    const sumFields = validationRule.fields;
    const sumValue = parseFloat(validationRule.value);

    // Calculate the sum
    const sum = sumFields.reduce((acc, field) => {
        const fieldPath = field.split('.');
        return acc + calculateSum(validationRule, data, fieldPath);
    }, 0);

    if (sum !== sumValue) {

        sumFields.forEach(field => {
            // const completeFieldPath = pathError.length > 0 ? pathError + '.' + field : field
            const fieldPath = field.split('.');
            markAsError(fieldPath, error, data, validationRule.errorMessage);
        })

        // error.fiber1.source1.weightPercentage.addError
        // error.fiber1.source1.weightPercentage.addError("Passwords don't match");
        //console.log(`Validation error: Sum of fields ${sumFields.join(', ')} should be equal to ${sumValue}`);
    }
}

export const validateSumLeq = (validationRule, data, error) => {
    const sumFields = validationRule.fields;
    const sumValue = parseFloat(validationRule.value);

    // Calculate the sum
    const sum = sumFields.reduce((acc, field) => {
        const fieldPath = field.split('.');
        return acc + calculateSum(validationRule, data, fieldPath);
    }, 0);

    if (sum > sumValue) {

        sumFields.forEach(field => {
            // const completeFieldPath = pathError.length > 0 ? pathError + '.' + field : field
            const fieldPath = field.split('.');
            markAsError(fieldPath, error, data, validationRule.errorMessage);
        })

        // error.fiber1.source1.weightPercentage.addError
        // error.fiber1.source1.weightPercentage.addError("Passwords don't match");
        //console.log(`Validation error: Sum of fields ${sumFields.join(', ')} should be equal to ${sumValue}`);
    }
}

export const validateSplitSumLeq = (validationRule, data, error) => {
    const fields = validationRule.fields;

    const nullIsPresent = fields.reduce((acc, field) => {
        const fieldPath = field.split('.');
        return acc || checkNullValuePresence(validationRule, data, fieldPath);
    }, false);
    if(nullIsPresent){
        validateSumLeq(validationRule, data, error);
    }else{
        validateSum(validationRule, data, error);
    }
}

const calculateSum = (validationRule: any, currentValue: any, fieldPaths: string[]): number => {
    const currentPath = fieldPaths[0];
    const residualPaths = fieldPaths.splice(1);

    currentValue = currentValue[currentPath];
    if (residualPaths.length === 0) {
        if (currentValue === undefined) {
            return 0;
        } else if (Array.isArray(currentValue)) {
            return currentValue.reduce((add, value) => add + parseFloat(value), 0)
        } else {
            return parseFloat(currentValue)
        }
    }

    if (currentValue === undefined) {
        return 0;
    } else if (Array.isArray(currentValue)) {
        return currentValue.reduce((acc, itemData) => acc + calculateSum(validationRule, itemData, residualPaths), 0)
    } else {
        return calculateSum(validationRule, currentValue, residualPaths)
    }
}

const checkNullValuePresence = (validationRule: any, currentValue: any, fieldPaths: string[]): boolean => {
    const currentPath = fieldPaths[0];
    const residualPaths = fieldPaths.splice(1);

    currentValue = currentValue[currentPath];
    if (residualPaths.length === 0) {
        if (currentValue === undefined) {
            return true;
        } else if (Array.isArray(currentValue)) {
            return currentValue.reduce((add, value) => add || value, false)
        } else {
            return false
        }
    }

    if (currentValue === undefined) {
        return true;
    } else if (Array.isArray(currentValue)) {
        return currentValue.reduce((acc, itemData) => acc || checkNullValuePresence(validationRule, itemData, residualPaths), false)
    } else {
        return checkNullValuePresence(validationRule, currentValue, residualPaths)
    }
}
export const validateMinDate = (validationRule: any, data: any, error: any) => {
    validateDate(validationRule, data, error, (date, compareDate) => date < compareDate)
}

export const validateMaxDate = (validationRule: any, data: any, error: any) => {
   validateDate(validationRule, data, error, (date, compareDate) => date > compareDate)
}

type DateCompare = (date: Date, compareDate: Date) => boolean

const validateDate = (validationRule: any, data: any, error: any, compare: DateCompare ) => {
    const dateFields: ReadonlyArray<string> = validationRule.fields;
    const compareDate = validationRule.value === 'today' ? new Date() : new Date(validationRule.value)

    dateFields
        .flatMap(field => {
            const fieldPath = field.split('.')
            return getDates(validationRule, data, fieldPath)
        })
        .filter(record => compare(record.date, compareDate))
        .forEach(record => {
            const fieldPath = record.field.split('.');
            markAsError(fieldPath, error, data, validationRule.errorMessage);
        })
}

interface DateWrapper{
    readonly field: string
    readonly date: Date
}

const getDates = (validationRule: any, currentValue: any, fieldPaths: string[]): ReadonlyArray<DateWrapper> => {
    const currentPath = fieldPaths[0];
    const residualPaths = fieldPaths.splice(1);

    currentValue = currentValue[currentPath];
    if (residualPaths.length === 0) {
        if (currentValue === undefined) {
            return [];
        } else {
            return [{
                field: currentPath,
                date: new Date(currentValue as string)
            }]
        }
    }

    if (currentValue === undefined) {
        return [];
    } else {
        return getDates(validationRule, currentValue, residualPaths)
    }
}

const markAsError = (fieldPaths: string[], errors, data, errorMessage: string): void => {
    const currentPath = fieldPaths[0];
    const residualPaths = fieldPaths.splice(1);

    errors = errors[currentPath];
    data = data[currentPath];

    if (errors === undefined || data === undefined) {
        return;
    }
    if (residualPaths.length === 0) {
        errors.addError("\n"+errorMessage)
        return;
    }

    if (Array.isArray(data)) {
        for (let dataItem of data) {
            let errorItem = errors[data.indexOf(dataItem)]
            markAsError(residualPaths, errorItem, dataItem, errorMessage)
        }
    } else {
        markAsError(residualPaths, errors, data, errorMessage)
    }
}