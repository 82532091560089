import {__DEF_VALUE} from '../../config/i18n';
import {admin, getUserRole} from "../../helpers/permission";
import {Schema} from "../AdvancedForm";
import moment from "moment/moment";

export const buildParamsByConfiguration = (filters: any, configuration: string | any): any => {
  if(typeof configuration === 'string'){
    configuration = JSON.parse(configuration.replaceAll('\'', '"'))
  }
  let params = {}
  configuration.forEach(item => {
    switch (item.type) {
      case 'text':
      case 'select':
      case 'checkbox':
        params[item.name] = filters[item.name]
        break
      case 'autocomplete':
      case 'autocomplete_company':
        if(item.multipleAutocomplete) {
          params[item.name] = (filters[item.name] ?? [])
              .map(stepOptionString => stepOptionString.value)
        } else {
          let selected = filters[item.name]
          params[item.name] = selected?.value;
        }
        break
      case 'daterange':
        if (filters[item.name] && filters[item.name].length > 0) {
          let from:any = moment(filters[item.name][0].format("YYYY-MM-DD") + 'T00:00:00.000Z');
          let to:any = moment(filters[item.name][1].format("YYYY-MM-DD") + 'T00:00:00.000Z');
          params[item.name+'From'] = from._d;
          params[item.name+'To'] = to._d;
        }
      break
    }
  });

  return params;
}


export const buildFiltersByConfiguration = (filterConfiguration: string | any): Schema[] => {
  if(typeof filterConfiguration === 'string'){
    filterConfiguration = JSON.parse(filterConfiguration.replaceAll('\'', '"'))
  }
  let filters: any[] = []

  if (!!filterConfiguration) {
    let isAdmin = getUserRole() === admin
    filterConfiguration
    .filter(item => {
      return isAdmin ? !item.hideForAdmin : !item.hideForSupplier
    })
    .forEach((item: any) => {
      filters.push({
        key: 'filter_' + item.name,
        name: item.name,
        type: item.type,
        label: __DEF_VALUE(item.label, item.label),
        placeholder: __DEF_VALUE(item.placeholder, item.placeholder),
        col: item.col ?? {xs: 24, sm: {span: 4}},
        descriptionKey: item.descriptionKey,
        multipleAutocomplete: item.multipleAutocomplete,
        urlSearch: item.urlSearch,
        header: item.header ?? undefined,
        dateRangePlaceholders: item.dateRangePlaceholders,
        options: item.options,
        mode: item.mode,
        additionalOptions: item.additionalOptions,
        maxTagCount: item.maxTagCount
      });

    });
  }
  return filters
}
