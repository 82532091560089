import React, { Component } from "react";
import { Column } from "@ant-design/charts";
import { Card, Col, Spin } from "antd";


interface Props {
  config: any
  isLoading: boolean
  title: string
}


export default class VerticalBasicBulletPlotCard extends Component<Props, {}> {

  render() {
    return <Col xl={8} sm={24} lg={12}>
      <Card
        title={this.props.title}
        bordered={true}
        style={{ width: "100%", height: 500 }}
      >
        <Spin spinning={this.props.isLoading} size="large">
        <Column {...this.props.config} xAxis={{
          label: {
          autoHide: true,
          autoRotate: false,
        }}
        } />
        </Spin>
      </Card>
    </Col>;
  }
}





