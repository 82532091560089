import React, {RefObject} from 'react';
import {Layout, ModalConfirm, View} from 'components';
import {
    DetailedOrder,
    DetailedOrderWithOwnerCompanyInfo,
    LibraryItem,
    Order,
    OrderCloneAssignment, OrderDetailQrCodeSetting,
    SubOrder
} from '../../api/types';
import {Alert, Button, Col, Divider, Dropdown, Menu, MenuProps, Row, Space, Tag} from 'antd';
import {__, T} from 'config/i18n';
import Title from 'antd/es/typography/Title';
import {navigate} from '../../router';
import Orders from '../../api/Orders';
import EntityPageHeader from '../../components/orders/EntityPageHeader';
import {getOrderDetailSchema} from '../../components/layouts/schemas';
import OrderTraceReport from '../../components/orders/OrderTraceReport';
import {getUserRole, isAdmin, isViewer} from '../../helpers/permission';
import OrderCard, {getOrderCardDetail} from '../../components/orders/OrderCard';
import OrderTraceDetailModal from '../../components/orders/OrderTraceDetailModal';
import OrderCardShipments from '../../components/orders/OrderCardShipments';
import AppStore from '../../AppStore';
import OrderFulfillmentForm from '../../components/orders/OrderFulfillmentForm';
import {showNotification} from '../../helpers/Notifications';
import TraceDetailForm from '../../components/traces/TraceDetailForm';
import {DownloadOutlined, DownOutlined} from '@ant-design/icons';
import {CSVLink} from 'react-csv';
import generalExports from '../../tools/GeneralExports';
import ImportTraceFromAnotherPO from '../../tools/ImportTraceFromAnotherPO';
import OrderLogsModal from '../../components/orderlogs/OrderLogsModal';
import DOMPurify from 'dompurify';
import ImportLibraryDialog from '../../components/orders/ImportLibraryDialog';
import ImportTraceDialog from '../../components/orders/ImportTraceDialog';
import AdvancedForm2 from '../../components/AdvancedForm2';
import {resolveReportOrdersBasePath} from '../../routes';
import RejectModal from '../../components/modals/RejectModal';
import EnquiryRejectReasonsSettingsTools from '../../tools/EnquiryRejectReasonsSettingsTools';
import ReassignModal from "../../components/modals/ReassignModal";
import OrderCloneModal from "./OrderCloneModal";
import {AssignmentForm} from "./OrderAssign";
import moment from "moment/moment";
import TCareQrCode from 'components/tCareQrCodes/TCareQrCode';
import PreviewModal from "../../components/modals/PreviewModal";
import qrCodeTools from '../../tools/QrCodeTools';


interface Props {
    match: { params: { orderId: string, type?: string } };
}

interface State {
    order?: DetailedOrderWithOwnerCompanyInfo;
    orderTraceDetailModalVisible: boolean;
    orderTraceDetailModalOrderId?: string;
    isImportTraceModalVisible: boolean;
    isImportLibraryModalVisible: boolean;
    isDraft: boolean;
    fulfillmentOrDraftFormSubmitButtonRef: RefObject<HTMLButtonElement>;
    rejectModalVisible: boolean;
    orderIdToReject?: string;
    reassignModalVisible: boolean;
    orderIdToReassign?: string;
    orderToClone?: SubOrder;
    cancelModalVisible: boolean;
    unlockModalVisible: boolean;
    orderIdToCancel?: string;
    orderIdToUnlock?: string;
    approveModalVisible: boolean;
    logOrderIdToShow?: string;
    askToUpdate: number;
    allAdvanceForm: boolean,
    isPreviewModalVisible: boolean
}

export default class OrderDetail extends React.Component<Props, State> {

    state: State = {
        orderTraceDetailModalVisible: false,
        isImportTraceModalVisible: false,
        isImportLibraryModalVisible: false,
        isDraft: false,
        fulfillmentOrDraftFormSubmitButtonRef: React.createRef<HTMLButtonElement>(),
        rejectModalVisible: false,
        approveModalVisible: false,
        reassignModalVisible: false,
        cancelModalVisible: false,
        unlockModalVisible: false,
        askToUpdate: 0,
        allAdvanceForm: false,
        isPreviewModalVisible: false,
    };

    componentDidMount = async () => {
        await this.reloadComponent(this.props.match.params.orderId);
    };

    componentDidUpdate = async (prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any) => {
        if (!window.location.pathname.includes(prevProps.match.params.orderId)) {
            try {
                let orderId = window.location.pathname.split('/')[3];
                if (!!orderId) {
                    await this.reloadComponent(orderId);
                }
            } catch (e) {
            }
        }
    };

    reloadComponent = async (orderId: string) => {
        let order: DetailedOrderWithOwnerCompanyInfo | undefined = await Orders.get(orderId);
        if (order && !order.data) {
            order.data = {};
        }
        order = await getOrderCardDetail(order) as DetailedOrderWithOwnerCompanyInfo;
        this.setState({
            order: {
                ...order
            },
            askToUpdate: this.state.askToUpdate + 1
        });
    };

    showLogs = (orderId?: string) => {
        this.setState({
            logOrderIdToShow: orderId
        });
    }

    closeLogs = () => {
        this.setState({logOrderIdToShow: undefined});
    }

    getPageHeaderExtras = (order: DetailedOrder | undefined): JSX.Element[] => {
        let extras: JSX.Element[] = [];
        if (order === undefined) {
            return extras;
        }

        if(!isViewer()){
            extras.push(
                <Button
                    key={'key_showLogs'}
                    type="ghost"
                    style={{width: '200px', backgroundColor: '#fff', border: '1px solid black'}}
                    onClick={() => this.showLogs(this.state.order?.id)}>
                    <div style={{display: 'flex', justifyContent: 'space-between'}}>
                        <img style={{height: '20px', justifyContent: 'center', display: 'flex', marginTop: 2}}
                             src={require('assets/images/history.svg').default}
                             alt={__(T.buttons.show_logs)}/>
                        <a style={{
                            minWidth: '150px',
                            justifyContent: 'center',
                            display: 'flex'
                        }}>
                            {__(T.buttons.show_logs)}
                        </a>
                    </div>
                </Button>
            );
        }

        if(isAdmin() && AppStore.appSettings.enableAdminJsonSchemPreview) {
            extras.push(
                <Button
                    onClick={() => this.openPreviewModal()}
                >
                    {__(T.buttons.preview)}
                </Button>
            )
        }
        if ((order.status === 'COMPLETED' || order.status === 'APPROVED') && AppStore.appSettings.enableExportCSVOnTrace) {
            let csvData: any = [];
            generalExports.exportViewCsv(csvData, this.getSchema(order), order);
            // TODO: add system to create csv on runtime (when click on button).
            extras.push(
                <CSVLink data={csvData}
                         key={'key_csv'}
                         style={{borderColor: '#2F80ED', color: '#2F80ED'}}
                         className="ant-btn ant-btn-default">{__(T.buttons.export_trace_in_CSV)}<DownloadOutlined/>
                </CSVLink>
            );
        }


        if (!isViewer()) {
            if ((order.status === 'ASSIGNED' || order.status === 'DRAFT' || order.status === 'FULFILLED') && getUserRole() !== 'admin') {

                if (!(AppStore.appSettings.enableOrderDetailSmart && order.orders.length === 0)) {
                    if (!isViewer()) {
                        extras.push(
                            <Button
                                key={'key_complete'}
                                type="primary"
                                style={{width: '200px'}}
                                onClick={() => navigate(resolveReportOrdersBasePath(this.props.match.params.type) + '/detail/:parentOrderId/fulfill/:orderId', {
                                    orderId: order.id,
                                    parentOrderId: this.props.match.params.orderId
                                })}
                            >
                                {__(T.buttons.complete_trace)}
                            </Button>
                        );
                    }
                } else if (this.shouldShowCompleteTraceButton(order)) {
                    if (AppStore.appSettings.enableTraceLibrary) {

                        const items: MenuProps['items'] = [
                            {
                                label: __(T.buttons.import_from_trace),
                                key: 'importTrace',
                                onClick: this.openImportTraceModal
                                //  icon: <UserOutlined />,
                            },
                            {
                                label: __(T.buttons.from_library),
                                key: 'importLibrary',
                                onClick: this.openImportLibraryModal
                                // icon: <UserOutlined />,
                            }
                        ];

                        const menuProps = {
                            items
                            // onClick: handleImportButtonClick
                        };

                        extras.push(
                            <Dropdown key={'key_fropdown'}
                                      overlay={<Menu {...menuProps} />}>
                                <Button>
                                    <Space>
                                        {__(T.buttons.import)}
                                        <DownOutlined/>
                                    </Space>
                                </Button>
                            </Dropdown>
                        );

                    } else {
                        extras.push(
                            <Button
                                key={'key_importtrace'}

                                type="ghost"
                                style={{width: '200px', backgroundColor: __(T.colors.gray_button)}}
                                onClick={() => this.openImportTraceModal()}
                            >
                                {__(T.buttons.import_trace)}
                            </Button>);
                    }

                    extras.push(
                        <Button
                            key={'key_fullfill'}
                            type="ghost"
                            style={{width: '200px', backgroundColor: __(T.colors.gray_button)}}
                            onClick={() => this.fulfillOrder(true)}
                        >
                            {__(T.buttons.save_draft)}
                        </Button>);
                    extras.push(
                        <Button
                            key={'key_completetrace'}
                            type="primary" style={{width: '200px'}} onClick={() => this.fulfillOrder(false)}>
                            {__(T.buttons.complete_trace)}
                        </Button>);
                } else if (this.shouldShowModifyTraceButton(order)) {
                    if (AppStore.appSettings.enableTraceLibrary) {
                        extras.push(
                            <Dropdown.Button
                                key={'key_openimport_dropdown'}

                                type="default"
                                className={'btn-secondary'}
                                overlay={
                                    <Menu>
                                        <Menu.Item onClick={() => this.openImportTraceModal()}>
                                            {__(T.buttons.import_trace)}
                                        </Menu.Item>
                                        <Menu.Item onClick={() => this.openImportLibraryModal()}>
                                            {__(T.buttons.import_library)}
                                        </Menu.Item>
                                    </Menu>}>
              <span className="ant-dropdown-link" style={{marginLeft: 8}}
                    onClick={e => e.preventDefault()}>{__(T.buttons.import)}</span>
                            </Dropdown.Button>);
                    } else {
                        extras.push(
                            <Button
                                key={'key_openImportTraceModal'}

                                type="ghost"
                                style={{width: '200px', backgroundColor: __(T.colors.gray_button)}}
                                onClick={() => this.openImportTraceModal()}
                            >
                                {__(T.buttons.import_trace)}
                            </Button>);
                    }

                    extras.push(
                        <Button key={'key_fulfillOrder'}
                                type="primary" style={{width: '200px'}} onClick={() => this.fulfillOrder(false)}>
                            {__(T.buttons.modify_trace)}
                        </Button>);
                }

            }
            if (!AppStore.appSettings.enableAutoComplete && this.state.order?.status === 'FULFILLED' && getUserRole() !== 'admin') {
                extras.push(
                    <Button
                        key={'key_completeOrder'}

                        type="primary"
                        style={{width: '200px', backgroundColor: __(T.colors.gray_button)}}
                        onClick={this.completeOrder}
                    >
                        {__(T.buttons.approve)}
                    </Button>);
            }
            if (AppStore.appSettings.enableRejectOrder && this.state.order?.status === 'COMPLETED' && getUserRole() === 'admin') {
                extras.push(
                    <Button
                        key={'key_rejectOrder'}
                        type="primary"
                        style={{width: '200px', backgroundColor: __(T.colors.gray_button)}}
                        onClick={() => this.onRejectOrder()}>
                        <div style={{display: 'flex', justifyContent: 'space-between'}}>
                            <img style={{
                                height: '22px',
                                justifyContent: 'center',
                                display: 'flex',
                                marginTop: 1,
                                color: 'red'
                            }}
                                 src={require('assets/images/Reject.svg').default}
                                 alt={__(T.buttons.reject)}
                            />
                            <a style={{
                                minWidth: '150px',
                                justifyContent: 'center',
                                display: 'flex'
                            }}>
                                {__(T.buttons.reject)}
                            </a>
                        </div>
                    </Button>);

            }
            if (AppStore.appSettings.enableApproveOrder && this.state.order?.status === 'COMPLETED' && getUserRole() === 'admin') {
                extras.push(
                    <Button
                        key={'key_approveOrder'}
                        type="primary"
                        style={{width: '200px', backgroundColor: __(T.colors.gray_button)}}
                        onClick={this.approveOrder}>
                        <div style={{display: 'flex', justifyContent: 'space-between'}}>
                            <img style={{height: '22px', justifyContent: 'center', display: 'flex', marginTop: 1}}
                                 src={require('assets/images/Approve.svg').default}
                                 alt={__(T.buttons.approve)}/>
                            <a style={{
                                minWidth: '150px',
                                justifyContent: 'center',
                                display: 'flex'
                            }}
                            >
                                {__(T.buttons.approve)}
                            </a>
                        </div>
                    </Button>);

            }
            if (this.shouldShowOrderAssignmentButton(order)) {
                extras.push(
                    <Button
                        key={'key_assign'}
                        type="primary"
                        style={{width: '200px', margin: 'auto'}}
                        onClick={() => navigate(resolveReportOrdersBasePath(this.props.match.params.type) + '/detail/:orderId/assign', {orderId: order.id})}
                    >
                        {__(T.buttons.assign)}
                    </Button>
                );
            }
        }

        return extras;
    };


    private completeOrder = () => {
        Orders.complete(this.state.order!.id!).then(
            this.handleFulfillmentSuccess
        );
    };

    onRejectOrder = (orderId?: string) => {
        this.setState({rejectModalVisible: true, orderIdToReject: orderId ?? this.state.order!.id!});
    };

    onReassignOrder = (orderId: string) => {
        this.setState({reassignModalVisible: true, orderIdToReassign: orderId});
    };

    onCloneOrder = (orderId: string) => {
        const {order} = this.state
        if (order && order.orders) {
            const suborder = order.orders.filter(o => o.id === orderId)[0]
            this.setState({orderToClone: suborder})
        }
    }

    onCancel = (orderId: string) => {
        this.setState({cancelModalVisible: true, orderIdToCancel: orderId});
    };

    onCancelClose = () => {
        this.setState({cancelModalVisible: false, orderIdToCancel: undefined});
    };

    onUnlock = (orderId: string) => {
        this.setState({unlockModalVisible: true, orderIdToUnlock: orderId});
    };

    onUnlockClose = () => {
        this.setState({unlockModalVisible: false, orderIdToUnlock: undefined});
    };

    private approveOrder = () => {
        this.setState({approveModalVisible: true});
    };


    private confirmApproveOrder = (confirm: boolean) => {
        this.setState({approveModalVisible: false},
            () => {
                if (confirm) {
                    Orders.approve(this.state.order!.id!).then(
                        order => {
                            window.location.reload(false);
                        }
                    );
                }
            });
    };

    private confirmCancel = (confirm: boolean) => {
        if (confirm) {
            Orders.cancel(this.state.orderIdToCancel!).then(
                order => {
                    window.location.reload(false);
                }
            );
        } else {
            this.onCancelClose()
        }
    };

    private confirmUnlock = (confirm: boolean) => {
        if (confirm) {
            Orders.unlock(this.state.orderIdToUnlock!).then(
                order =>
                    window.location.reload(false)
            );
        } else {
            this.onUnlockClose()
        }
    };

    handleAssignmentSubmit = (assignmentForm?: AssignmentForm) => {
        this.setState({
            allAdvanceForm: false
        });
        if (assignmentForm) {
            const formInstanceValidation = assignmentForm.formRef.current?.validateFields()
            if (formInstanceValidation) {
                assignmentForm.buttonFormRefValid = false;
                assignmentForm.buttonFormRef.current?.click();

                formInstanceValidation.then(() => {
                    this.setState({
                        allAdvanceForm: true
                    });
                    this.confirmClone(assignmentForm)
                })
            }
        } else {
            this.setState({orderToClone: undefined})
        }
    }

    onJsonSchemaSubmit = (assignmentForm: AssignmentForm) => {
        assignmentForm.buttonFormRefValid = true
        this.confirmClone(assignmentForm)
    }

    confirmClone = (assignmentForm: AssignmentForm) => {
        if (assignmentForm.buttonFormRefValid && this.state.allAdvanceForm) {
            const data: OrderCloneAssignment = {
                originalOrderId: assignmentForm.originalOrderId,
                companyId: assignmentForm.company?.id,
                orderCode: assignmentForm.orderCode,
                orderDate: assignmentForm.orderDate ? assignmentForm.orderDate + 'T00:00:00.000Z' : undefined,
                attributes: assignmentForm.attributes
            }

            Orders.cloneAssigned(this.state.order!.id, data).then(
                () => {
                    showNotification(
                        __(T.titles.order_assignment_success),
                        __(T.messages.order_assignment_success),
                        'success',
                        3
                    )

                    this.setState({orderToClone: undefined})
                    this.reloadComponent(this.state.order!.id)
                },
                () => showNotification(__(T.titles.order_assignment_error), __(T.messages.order_assignment_error), 'error', 3)
            )
        }
    }

    viewDetail = (orderId: string): void => {
        this.setState({orderTraceDetailModalOrderId: orderId, orderTraceDetailModalVisible: true});
    };

    navigateToDetail = async (orderId: string): Promise<void> => {
        navigate(resolveReportOrdersBasePath(this.props.match.params.type) + '/detail/:orderId', {orderId: orderId});
    };


    handleFulfillmentSuccess = (order) => {
        const {isDraft} = this.state;
        if (!!order.errorValidation) {
            showNotification(
                __(T.titles.order_fulfillment_error),
                __(T.messages.order_saved_draft_with_errors),
                'error',
                3
            );
        } else {
            showNotification(
                __(T.titles.order_fulfillment_success),
                isDraft ? __(T.messages.order_fulfillment_draft) : __(T.messages.order_fulfillment_success),
                'success',
                3
            );
        }
        this.setState({order: order, askToUpdate: this.state.askToUpdate + 1});
    };

    handleReject = async (order) => {
        if (!!order.errorValidation) {
            showNotification(
                __(T.titles.order_reject_error),
                __(T.messages.order_saved_draft_with_errors),
                'error',
                3
            );
        } else {
            showNotification(
                __(T.titles.order_reject_success),
                __(T.messages.order_reject_success),
                'success',
                3
            );
        }
        // TODO: fix reload OrderCard
        // if (order.id === this.state.order?.id) {
        //   this.setState({ order: order, rejectModalVisible: false, askToUpdate: this.state.askToUpdate + 1 });
        // } else {
        //   const currentOrder = await Orders.get(this.state.order!.id);
        //   this.setState({ order: currentOrder, rejectModalVisible: false, askToUpdate: this.state.askToUpdate + 1 });
        // }
        window.location.reload(false);
    };

    handleReassign = async () => {
        // TODO: fix reload OrderCard
        // let order = await Orders.get(this.state.order?.id)
        // this.setState({order: order, reassignModalVisible: false, askToUpdate: this.state.askToUpdate + 1})
        window.location.reload(false);
    };

    handleCancel = async () => {
        // TODO: fix reload OrderCard
        // let order = await Orders.get(this.state.order?.id)
        // this.setState({order: order,  cancelModalVisible: false, askToUpdate: this.state.askToUpdate + 1})
        window.location.reload(false);
    }

    render = () => {
        const {
            order,
            orderTraceDetailModalVisible,
            orderTraceDetailModalOrderId,
            orderToClone
        } = this.state

        if (!order) {
            return (
                <Layout children={null}/>
            );
        }

        const orderDetailQrCodeSetting: OrderDetailQrCodeSetting|undefined =
          qrCodeTools.getParsedOrderDetailQrCodeSetting(order.traceType.code);

        return (
            <Layout>
                <View style={{margin: 0, marginBottom: 1}}>
                    <EntityPageHeader
                        config={'orderPageHeader'}
                        data={order}
                        extras={this.getPageHeaderExtras(order)}
                        onBack={() => navigate(resolveReportOrdersBasePath(this.props.match.params.type))}
                        extraConfigType={order.traceType.code}
                    />
                </View>
                {this.shouldShowOrderDetail(order) &&
                    <View className="page-table" style={{margin: 10, marginTop: 0, borderRadius: '0px'}}>
                        <Title level={4}>{__(T.titles.purchase_order_details)}</Title>
                        <Divider/>
                        <Row>
                            <Col span={16}>
                                <AdvancedForm2 schema={getOrderDetailSchema(order.traceType.code)} values={order}
                                               onValuesChange={() => {
                                               }}/>
                            </Col>
                            {!!orderDetailQrCodeSetting && orderDetailQrCodeSetting.onlyRoot === order.root &&
                                <Col span={8} style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <TCareQrCode order={order} size={160} setting={orderDetailQrCodeSetting.value} format={AppStore.appSettings.formatDownloadQrCode}/>
                                </Col>
                            }
                        </Row>
                    </View>
                }
                {(!this.shouldShowOrderDetail(order) && !!orderDetailQrCodeSetting && orderDetailQrCodeSetting.onlyRoot === order.root) &&
                    <View className="page-table" style={{margin: 10, marginTop: 0, borderRadius: '0px'}}>
                        <Row>
                            <Col span={8} style={{display: 'flex'}}>
                                <TCareQrCode order={order} size={160} setting={orderDetailQrCodeSetting.value} format={AppStore.appSettings.formatDownloadQrCode}/>
                            </Col>
                        </Row>
                    </View>
                }
                {(order?.status === 'TO_BE_ASSIGNED' || !(AppStore.appSettings.enableOrderDetailSmart && order.orders.length === 0))
                    && (
                        <>
                            <View className="page-table"
                                  style={{margin: 10, marginTop: 0, paddingLeft: 0, borderRadius: '0px'}}>
                                <Title level={4} style={{paddingLeft: 20}}>
                                    {__(T.titles.trace_report)}
                                </Title>
                                {order.orderType !== 'MAKE_FINISH_PRODUCT' && <>
                                    <OrderCard
                                        isSubOrder={false}
                                        order={order}
                                        pageType={this.props.match.params.type}
                                        onViewDetail={this.viewDetail}
                                        noFulfillment
                                        showFulfillmentDate
                                        showAssignmentDate
                                        askToUpdate={this.state.askToUpdate}
                                        style={{
                                            margin: '30px 40px 20px 40px',
                                            width: 'auto',
                                            backgroundColor: '#efefef'
                                        }}
                                    />
                                    <Divider style={{marginBottom: 0}}/>
                                    <OrderTraceReport
                                        pageType={this.props.match.params.type}
                                        order={order}
                                        askToUpdate={this.state.askToUpdate}
                                        onViewSubOrderDetail={this.viewDetail}
                                        viewDetail={this.viewDetail}
                                        onRejectOrder={this.onRejectOrder}
                                        onReassignOrder={this.onReassignOrder}
                                        onCloneOrder={this.onCloneOrder}
                                        onCancel={this.onCancel}
                                        onUnlock={this.onUnlock}
                                        onShowLogs={this.showLogs}
                                    />
                                    <OrderTraceDetailModal
                                        orderId={orderTraceDetailModalOrderId}
                                        visible={orderTraceDetailModalVisible}
                                        onCancel={() => this.setState({orderTraceDetailModalVisible: false})}
                                    />
                                </>}
                                {// Mostrare i dettagli dei Raw Material ossia degli ordini.
                                    order.orderType === 'MAKE_FINISH_PRODUCT' && <>
                                        {order.orders.map(weaving => (
                                            <>

                                                <OrderCardShipments
                                                    navigateToDetail={this.navigateToDetail}
                                                    showPurchaseOrder={true}
                                                    order={weaving}
                                                    shipmentList={order?.shipmentMap[weaving.id]}/>
                                            </>
                                        ))}
                                    </>}
                            </View>
                        </>)}

                {order.status === 'DRAFT' && !!order.reject /*&& order.reject.note*/ && (
                    <View className="page-table" style={{margin: 10, marginTop: 0, borderRadius: '0px'}}>
                        <Alert
                            message={(__(T.warnings.feedback_reject))}
                            description={
                                <div>
                                    <div style={{whiteSpace: 'pre-wrap'}}>
                                        {DOMPurify.sanitize(order.reject.note)}
                                    </div>
                                    {order.reject.reasons.map(item => (
                                        <Tag key={item + '_tag_errorValidation'}
                                             color={EnquiryRejectReasonsSettingsTools.getColorByCode(item)}>
                                            {__(item)}
                                        </Tag>
                                    ))}
                                </div>
                            }
                            type="warning"
                            showIcon
                        />
                    </View>)}

                {((AppStore.appSettings.enableOrderDetailSmart && order.orders.length === 0) &&
                    (order?.status === 'ASSIGNED' || order?.status === 'DRAFT' || order?.status === 'FULFILLED') && getUserRole() !== 'admin') && (
                    <>
                        <View className="page-table" style={{margin: 10, marginTop: 0, borderRadius: '0px'}}>
                            <Title level={4}>{__(T.titles.purchase_order_fulfillment)}</Title>
                            <Divider/>
                            <Col span={16}>
                                <OrderFulfillmentForm
                                    order={order}
                                    onFulfillmentSuccess={this.handleFulfillmentSuccess}
                                    fulfillmentOrDraftFormSubmitButtonRef={this.state.fulfillmentOrDraftFormSubmitButtonRef}
                                    thirdPart={false}
                                    draft={this.state.isDraft}
                                />
                            </Col>
                        </View>
                        {order &&
                            <ImportTraceDialog
                                isVisible={this.state.isImportTraceModalVisible}
                                onCancel={this.onCancelImportTrace}
                                onSuccess={this.onConfirmImportTrace}
                                traceTypeCode={order.traceType.code}
                                title={__(T.titles.import_library_dialog)}/>
                        }

                        {order &&
                            <ImportLibraryDialog
                                isVisible={this.state.isImportLibraryModalVisible}
                                onCancel={this.onCancelImportLibrary}
                                onSuccess={this.onConfirmImportLibrary}
                                traceTypeCode={order.traceType.code}
                                title={__(T.titles.import_library_dialog)}/>
                        }
                    </>
                )}
                {((AppStore.appSettings.enableOrderDetailSmart && order.orders.length === 0) && (order?.status === 'COMPLETED' || order?.status === 'APPROVED' || getUserRole() === 'admin')) && (
                    <>
                        <View className="page-table" style={{margin: 10, marginTop: 0, borderRadius: '0px'}}>
                            <Col span={24}>
                                <TraceDetailForm schema={this.getSchema(order)} order={order}
                                                 isShowExportCsvButton={false}/>
                            </Col>
                        </View>
                    </>)}

                <RejectModal
                    visible={this.state.rejectModalVisible}
                    title={__(T.fields.reject_order_modal_title)}
                    subtitle={__(T.fields.reject_order_modal_body)}
                    hasNote={true}
                    order={this.getByOrderId(this.state.orderIdToReject!)}
                    callbackRejectOrder={(orderDetail) => this.handleReject(orderDetail)}
                    callbackCancelRejectOrder={() => this.setState({rejectModalVisible: false})}
                />
                <ReassignModal
                    visible={this.state.reassignModalVisible}
                    orderId={this.state.orderIdToReassign!}
                    onSuccess={(orderDetail) => this.handleReassign()}
                    onCancel={() => this.setState({reassignModalVisible: false})}
                />
                {orderToClone &&
                    <OrderCloneModal
                        attributes={orderToClone?.attributes}
                        order={this.state.order!}
                        orderCode={orderToClone.code}
                        orderDate={
                            orderToClone.orderDate ?
                                moment(orderToClone.orderDate).format('YYYY-MM-DD')
                                : moment().format('YYYY-MM-DD')
                        }
                        subOrderId={orderToClone.id}
                        companyId={orderToClone.ownerCompany}
                        onClickButton={this.handleAssignmentSubmit}
                        onJsonSchemaSubmit={this.onJsonSchemaSubmit}
                    />
                }
                <ModalConfirm
                    visible={this.state.approveModalVisible}
                    title={__(T.fields.approve_order_modal_title)}
                    subtitle={AppStore.appSettings.enableLockIfChildrenAreNotApproved ? __(T.fields.approve_order_modal_body) : __(T.fields.approve_order_with_children_modal_body)}
                    hasFeedback={false}
                    callback={this.confirmApproveOrder}
                />
                <ModalConfirm
                    visible={this.state.cancelModalVisible}
                    title={__(T.fields.cancel_order_modal_title)}
                    subtitle={__(T.fields.cancel_order_modal_body)}
                    cancel={__(T.buttons.no)}
                    confirm={__(T.buttons.yes)}
                    hasFeedback={false}
                    callback={this.confirmCancel}
                />
                <ModalConfirm
                    visible={this.state.unlockModalVisible}
                    title={__(T.fields.unlock_order_modal_title)}
                    subtitle={__(T.fields.unlock_order_modal_body)}
                    cancel={__(T.buttons.no)}
                    confirm={__(T.buttons.yes)}
                    hasFeedback={false}
                    callback={this.confirmUnlock}
                />

                <PreviewModal
                    visible={this.state.isPreviewModalVisible}
                    onCancel={() => this.setState({isPreviewModalVisible: false})}
                    schema={this.getSchema(order)}
                    values={JSON.parse(JSON.stringify({...order.data,orderAttributes: order.attributes}))}
                    filters={{
                        "creationCompanyId": order.creationCompany
                    }}
                />
                <OrderLogsModal onClose={() => this.closeLogs()} orderId={this.state.logOrderIdToShow}/>
            </Layout>
        );
    };

    private shouldShowOrderAssignmentButton = (order: DetailedOrder) => {
        return (
            getUserRole() === 'admin-supplier' ||
            getUserRole() === 'representative' ||
            getUserRole() === 'supplier' ||
            getUserRole() === 'guest') && order.status === 'TO_BE_ASSIGNED';
    };

    private shouldShowOrderDetail = (order: DetailedOrder) => {
        return order.attributes && Object.keys(order.attributes).length > 0;
    };

    private shouldShowCompleteTraceButton = (order: DetailedOrder) => {
        return (order.status === 'ASSIGNED' || order.status === 'DRAFT');
    };

    private shouldShowModifyTraceButton = (order: DetailedOrder) => {
        return order.status === 'FULFILLED';
    };

    private fulfillOrder = (draft: boolean) => {
        this.setState({
            isDraft: draft,
            askToUpdate: this.state.askToUpdate + 1
        }, () => this.state.fulfillmentOrDraftFormSubmitButtonRef.current?.click());
    };

    shouldShowSaveDraftButton = (order: DetailedOrder) => {
        return order.status !== 'FULFILLED' && order.status !== 'COMPLETED';
    };

    onConfirmImportTrace = (order: Order, overrideExistingDataInput: boolean) => {
        this.setState({isImportTraceModalVisible: false, askToUpdate: this.state.askToUpdate + 1}, async () => {
            await this.importTraceFromAnotherPO(order, overrideExistingDataInput);
        });
    };

    openImportTraceModal = () => {
        this.setState({isImportTraceModalVisible: true});
    };

    onCancelImportTrace = (): void => {
        this.setState({isImportTraceModalVisible: false});
    };

    openImportLibraryModal = () => {
        this.setState({isImportLibraryModalVisible: true});
    };

    openPreviewModal = () => {
        this.setState({
            isPreviewModalVisible: true,
        });
    };

    onCancelImportLibrary = () => {
        this.setState({isImportLibraryModalVisible: false});
    };

    onConfirmImportLibrary = (item: LibraryItem, override) => {
        let tempOrder = JSON.parse(JSON.stringify(this.state.order));
        ImportTraceFromAnotherPO.importData(tempOrder, item.data, override);
        // In order to force the refresh of the page;
        this.setState({order: undefined});
        this.setState({order: tempOrder, isImportLibraryModalVisible: false, askToUpdate: this.state.askToUpdate + 1});
    };

    private async importTraceFromAnotherPO(orderFromDialogImport: Order, overrideExistingDataInput: boolean) {
        let orderById = await Orders.get(orderFromDialogImport.id!);
        let tempOrder = JSON.parse(JSON.stringify(this.state.order));
        ImportTraceFromAnotherPO.importData(tempOrder, orderById.data, overrideExistingDataInput);
        //Non rimuovere il set undefined
        this.setState({order: undefined});
        this.setState({order: tempOrder, isImportLibraryModalVisible: false, askToUpdate: this.state.askToUpdate + 1});
    }

    private getSchema = (order: DetailedOrder) => {
        const schema = JSON.parse(order.traceType.schema);
        if (schema) {
            schema.title = undefined;
        }
        return schema;
    };

    private getByOrderId(orderId: string): Order | SubOrder | undefined {
        if (this.state.order?.id === orderId) {
            return this.state.order;
        }
        return this.state.order?.orders.find(order => {
            return order.id === orderId;
        });
    }

}
