import {__, __DEF_VALUE, T} from 'config/i18n'
import { category } from 'types'

export const describeCategory = (category?: category): string  => {
  if (category === undefined) {
    return ''
  }
  return __DEF_VALUE(T.category[category], category)
}

export const describeCategories = (categories?: string[]): string => {
  let result: string = ''
  if (!!categories) {
    let  isFirst: boolean = true
    categories.sort(function (a: string, b: string)  {
        if(a < b) { return -1; }
        if(a > b) { return 1; }
        return 0;
    })
    for (let category of categories) {
      if (isFirst) {
        isFirst = false
        result += describeCategory(category)
      } else {
        result += ' - ' + describeCategory(category)
      }
    }
  }
  return result
}

export const describeCategoriesReturnArray = (categories?: string[]): string[] => {
  return describeCategories(categories).split(' -')
}


export const describeCompanyType = (backendType?: string) => {
  switch (backendType) {
    case 'it.temera.stw.tcare.profilemanagerservice.dto.SupplierDto':
      return __(T.misc.supplier)
    case 'it.temera.stw.tcare.profilemanagerservice.dto.SubSupplierDto':
      return __(T.misc.subsupplier)
    case 'it.temera.stw.tcare.profilemanagerservice.dto.RepresentativeDto':
      return __(T.misc.representative)
    default:
      return ''
  }
}

export const getCompanyType = (backendType?: string) => {
  switch (backendType) {
    case 'it.temera.stw.tcare.profilemanagerservice.dto.SupplierDto':
      return 'supplier'
    case 'it.temera.stw.tcare.profilemanagerservice.dto.SubSupplierDto':
      return 'subsupplier'
    case 'it.temera.stw.tcare.profilemanagerservice.dto.RepresentativeDto':
      return 'representative'
    default:
      return ''
  }
}
