import { Certification, CertificationUpload } from 'api/types'
import { AxiosRequestConfig } from 'axios'
import api, { responseErrorCheck } from './api'
import moment from 'moment'

class Certifications<T = Certification> {
  protected endpoint = 'profilemanager/v1/certifications'

  async add(certification: CertificationUpload, axiosConfig?: AxiosRequestConfig) {
    const formData = new FormData()
    certification.id && formData.append('id', certification.id)
    certification.companyId && formData.append('companyId', certification.companyId)
    certification.file && formData.append('file', certification.file)
    certification.expiration && formData.append('expiration', moment(certification.expiration).format('YYYY-MM-DD'))
    certification.certificateType && formData.append('certificateType', certification.certificateType)
    certification.certificateNumber && formData.append('certificateNumber', certification.certificateNumber)
    certification.companyName && formData.append('companyName', certification.companyName);

    let axiosConfigMerged: AxiosRequestConfig = {
      headers: {
        'content-type': 'multipart/form-data',
        ... axiosConfig
      }
    }

    return api
      .post(`${this.endpoint}`, formData, axiosConfigMerged)
      .then(responseErrorCheck)
  }

  async delete(id: string, axiosConfig?: AxiosRequestConfig) {
    const res = await api.delete<T>(`${this.endpoint}/${id}`,{}, axiosConfig)
    return responseErrorCheck(res)
  }

  async getDownloadLink(certificationId, axiosConfig?: AxiosRequestConfig) {
    return api.get(`${this.endpoint}/${certificationId}/download`,  {}, axiosConfig)
  }
}

const certifications = new Certifications()
export { certifications as default }
