export class DelaysTools {

    static sleep(milliseconds: number) {
        return new Promise(resolve => {
            setTimeout(resolve, milliseconds);
        });
    }

    // Utilizzo della funzione sleep per attendere 50ms senza bloccare il browser
    static async delay(milliseconds?: number) {
        await DelaysTools.sleep(milliseconds ?? 100);
    }
}



export default DelaysTools;
