import React, {Component} from 'react';
import {Button, Col, Row, Spin, Upload} from 'antd';
import {__, T} from 'config/i18n';
import {DeleteOutlined, DownloadOutlined} from '@ant-design/icons';
import ModalJsonSchema from '../ModalJsonSchema';
import Templates from '../../api/Templates';
import filemanager from '../../api/Files';
import orders from '../../api/Orders';
import PdfTools from '../../tools/PdfTools';
import companies from "../../api/Companies";


interface Props {
    inputProps: any;
}


interface State {
    isModalOpen: boolean
    isLoading: boolean
}

const nameDataPdf = '_DATA';

export default class TCareUploadWidget extends Component<Props, State> {
    // attributesFormSubmitButtonRef: RefObject<HTMLButtonElement> = React.createRef<HTMLButtonElement>();

    state: State = {
        isModalOpen: false,
        isLoading: false,
    };

    readFileAsBase64 = (file): Promise<{ base64Data: string, filename: string, fileType: string }> => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = () => {
                if (typeof reader.result === 'string') {
                    const [, base64Data] = reader.result.split(',');
                    resolve({
                        base64Data,
                        filename: file.name,
                        fileType: file.type
                    });
                } else {
                    reject('Failed to read file as Base64.');
                }
            };
            reader.onerror = (error) => {
                reject(error);
            };
            reader.readAsDataURL(file);
        });
    };

    handleFileChange = async (file) => {
        const {base64Data, filename, fileType} = await this.readFileAsBase64(file);
        this.props.inputProps.onChange('data:' + fileType + ';name=' + filename + ';base64,' + base64Data);
        return false;
    };

    getLoading() {
        if (this.state.isLoading) {
            return (
                <div
                    style={{
                        position: 'absolute',
                        top: 0,
                        right: 0,
                        left: 0,
                        bottom: 0,
                        width: '100%',
                        height: '100%',
                        backgroundColor: 'gray',
                        opacity: 0.5,
                        zIndex: 100,
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center'
                    }}>
                    <div style={{display: 'flex', alignItems: 'center', flexDirection: 'column', width: '100%'}}>
                        <Spin size="large"/>
                    </div>
                </div>

            );
        }
        return (<div/>);
    }

    private getData() {
        if (this.isSchemaPresent()) {
            const dataName = this.extractedPdfName();
            if (!!this.props.inputProps
                && !!this.props.inputProps.formContext
                && !!this.props.inputProps.formContext.data
                && !!this.props.inputProps.formContext.data[dataName]) {
                return JSON.parse(JSON.stringify(this.props.inputProps.formContext.data[dataName]));
            }
        }
        return {};
    }

    private extractedPdfName() {
        const parts = this.props.inputProps.id.split('_');
        return parts[parts.length - 1] + nameDataPdf;
    }

    getSchema() {
        if (this.isSchemaPresent()) {
            return this.props.inputProps.schema.extra.generatePdf.schema;
        }
        return {};
    }

    private async onSuccessModalTemplate(e: Object, isDraft: boolean) {
        let parentObject = this.props.inputProps.formContext.data;
        const paths = this.props.inputProps.id.split('_');
        // TODO: fix array (gli array non sono supportati)
        for (let i = 1; i < paths.length - 1; i++) {

            if (Number.isInteger(Number(paths[i]))) {
                const index = parseInt(paths[i]);
                parentObject = parentObject[index];
            } else {
                parentObject = parentObject[paths[i]];
            }
        }

        if (!!e) {
            parentObject[this.extractedPdfName()] = JSON.parse(JSON.stringify(e));
        } else {
            parentObject[this.extractedPdfName()] = {};
        }

        if (!isDraft) {
            const pdf: string | undefined = await this.generateBody(this.props.inputProps.formContext);
            if (!!pdf) {
                const fileName = 'generated.pdf';
                this.props.inputProps.onChange('data:application/pdf;name=' + fileName + ';base64,' + pdf);
            }
        } else {
            this.props.inputProps.onChange(null);
        }


        this.setState({isModalOpen: false, isLoading: false});
    }


    private async onPreview() {
        const pdf: string | undefined = await this.generateBody(this.props.inputProps.formContext);
        if (!!pdf) {
            const fileName = 'preview.pdf';
            await PdfTools.downloadPdf(pdf, fileName);
        } else {

        }
    }

    private async generateBody(order: any): Promise<any> {
        //TODO: logica troppo custom per gli ordini, probabilmente nel formContext dovrebbe arrivare tutto già pronto dal chiamante
        const children = this.props.inputProps.formContext.orders ? await Promise.all(this.props.inputProps.formContext.orders.map(async o => await orders.get(o.id))) : undefined;
        const company = order.creationCompany ? await companies.get(order.creationCompany!) : undefined;
        return await Templates.getPdfOnBase64Format(this.props.inputProps.schema.extra.generatePdf.templateCode, {
            order: order,
            children: children,
            company: company
        });
    }

    isSchemaPresent = (): boolean => {
        return !!this.props.inputProps
            && !!this.props.inputProps.schema
            && !!this.props.inputProps.schema.extra
            && !!this.props.inputProps.schema.extra.generatePdf
            && !!this.props.inputProps.schema.extra.generatePdf.schema
    }

    isPreview = (): boolean => {
        return !!this.props.inputProps
            && !!this.props.inputProps.schema
            && !!this.props.inputProps.schema.extra
            && !!this.props.inputProps.schema.extra.generatePdf
            && !!this.props.inputProps.schema.extra.generatePdf.preview
    }

    isGenerateOnComplete = (): boolean => {
        return !!this.props.inputProps
            && !!this.props.inputProps.schema
            && !!this.props.inputProps.schema.extra
            && !!this.props.inputProps.schema.extra.generatePdf
            && (this.props.inputProps.schema.extra.generatePdf.generateOnComplete
            || this.props.inputProps.schema.extra.generatePdf.disableUpload)
    }

  accept = (): string => {
    if (!!this.props.inputProps
      && !!this.props.inputProps.schema
      && !!this.props.inputProps.schema.extra
      && !!this.props.inputProps.schema.extra.accept) {
      return this.props.inputProps.schema.extra.accept;
    }
    return "*/*";
  }


    private getModalJsonSchema() {

        return (<ModalJsonSchema
            schema={this.getSchema()}
            data={this.getData()}
            visible={this.state.isModalOpen}
            onCancel={() => {
                this.setState({isModalOpen: false});
            }}
            onSuccess={(e: any) => {
                this.onSuccessModalTemplate(e, false);
            }}
            onDraft={(e: any) => {
                this.onSuccessModalTemplate(e, true);
            }}
            title={__(T.fields.washington_attestation_data)}
        />);
    }

    render() {
        let fileTmp: any[] = []; //{ name: '', response: undefined, uid: '', xhr: undefined }


        if (!!this.props.inputProps.value && typeof this.props.inputProps.value === 'string') {
            // "data:text /plain;name=1&2Aconia.txt:hase64. TWFkYW1lLA0KIEnlIHN1aXMaTWFkYW1lIF31bm5hLCBobnNicml0zsBndSBDb25zdwxhdCBKZSBGcmFuYZUr
            const parts = this.props.inputProps.value.split(';');

            let data = parts[0].substring(5);
            let name = parts[1].substring(5);
            let base64 = parts[1].substring(7);


            fileTmp.push({
                name: name,
                fileType: data,
                base64: base64,
                status: 'done',
                uid: name,
                xhr: undefined,
                url: this.props.inputProps.value
            });
        }

        const isUrlBase64 = (url: string | undefined) => {
            if (!url) {
                return true;
            }

            // Verifica se la stringa sembra essere una stringa Base64 valida
            const base64Regex = /^data:([^;]+);name=([^;]+);base64,([a-zA-Z0-9+/]+={0,2})$/;
            return base64Regex.test(url);
        };

        return (<>
                {this.getLoading()}

                <Upload
                    disabled={this.isGenerateOnComplete()}
                  accept={this.accept()}
                  itemRender={(item, file) =>
                        <div style={{display: 'flex', marginTop: '15px'}}>
                            <div style={{
                                flex: '1',
                                overflow: 'hidden',
                                whiteSpace: 'nowrap',
                                textOverflow: 'ellipsis',
                                paddingTop: '5px'
                            }}>
                                {file.name}
                            </div>
                            <Button style={{flexBasis: '25px', color: '#000000', marginLeft: '10px'}}
                                    disabled={this.props.inputProps.disabled}
                                    onClick={() => this.props.inputProps.onChange(undefined)}
                            >
                                <DeleteOutlined/>
                            </Button>
                            {isUrlBase64(file.url) &&
                                <Button style={{flexBasis: '25px', color: '#000000', marginLeft: '10px'}}
                                        href={file.url!} target="_blank" download={file.name}>
                                    <DownloadOutlined/>
                                </Button>}
                            {!isUrlBase64(file.url) &&
                                <Button style={{flexBasis: '25px', color: '#000000', marginLeft: '10px'}}
                                        onClick={() => filemanager.downloadFileByTraceUrl(file.url!)}
                                        target="_blank"
                                        download={file.name}>
                                    <DownloadOutlined/>
                                </Button>}
                        </div>
                    }
                    beforeUpload={this.handleFileChange}
                    // onChange={(event) => {}}
                    fileList={fileTmp}
                    className={this.props.inputProps.disabled ? 'upload-readonly' : ''}
                >
                    <Row gutter={16} justify="space-between" align="middle" style={{marginBottom: '10px'}}>
                        <Col>
                            {!this.isGenerateOnComplete() &&
                              <Button type="default"
                                      onClick={() => {}}
                                      disabled={this.props.inputProps.disabled}> {__(T.buttons.select_file)}</Button>
                            }
                            {this.isGenerateOnComplete() &&
                              <small style={{fontStyle: 'italic', color: 'black'}}>{__('fields.fileGeneratedOnComplete')}</small>
                            }
                        </Col>
                    </Row>
                </Upload>
                {this.isSchemaPresent() && !this.props.inputProps.disabled &&
                    <Button type="default" style={{background: 'yellow', position: 'absolute', top: 0, right: 0}}
                            disabled={this.props.inputProps.disabled}
                            onClick={() => {
                                this.setState({isModalOpen: true});
                            }}>
                        Generate
                    </Button>
                }
                {this.isPreview() && !this.props.inputProps.disabled &&
                    <Button type="default" className={'ant-btn-primary'} style={{position: 'absolute', top: 0, right: 0}}
                            disabled={this.props.inputProps.disabled}
                            onClick={() => this.onPreview()}>
                        {__(T.buttons.preview)}
                    </Button>
                }
                {this.getModalJsonSchema()}
            </>
        );
    }
}
