import api, { responseErrorCheck } from './api'
import { AxiosRequestConfig } from 'axios';

interface Settings {
  ssoUrl: string,
}

class AppSettings {
  protected endpoint = 'profilemanager/public/v1'

  async getSettings(axiosConfig?: AxiosRequestConfig): Promise<Settings> {
    let axiosConfigMerged: AxiosRequestConfig = {
      headers: {
        tenant: 'no-tenant',
        ...axiosConfig
      }
    }
    const res = await api.get<Settings>(`${this.endpoint + "/appSettings"}`, {}, axiosConfigMerged)
    return responseErrorCheck(res)
  }

  async getTenant(clientTenant: string, axiosConfig?: AxiosRequestConfig): Promise<string> {
    let axiosConfigMerged: AxiosRequestConfig = {
      headers: {
        tenant: 'no-tenant',
        ...axiosConfig
      }
    }
    let host = window.location.host;
    const res = await api.get<string>(`${this.endpoint + "/currentTenant"}`, {clientTenant, host}, axiosConfigMerged)
    return responseErrorCheck(res)
  }
}

const appSettings = new AppSettings()
export { appSettings as default }
