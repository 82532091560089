//TODO: refactor for OrderReport
import { ColumnsType } from 'antd/es/table';
import { __, __DEF_VALUE, T } from '../../config/i18n';
import { Text } from '../index';
import OrderNetworkTag from './OrderNetworkTag';
import moment from 'moment';
import React from 'react';
import { Tag } from 'antd';
import { admin, getUserRole } from '../../helpers/permission';
import { describeCategories } from '../../helpers/describer';
import { CheckCircleTwoTone, ExclamationCircleTwoTone } from '@ant-design/icons';
import EnquiryRejectReasonsSettingsTools from '../../tools/EnquiryRejectReasonsSettingsTools';
import OrderRejectsTools from '../../tools/OrderRejectsTools';


export const getValueByPath = (record: any, dataIndex): any => {
  if (dataIndex) {
    const paths = dataIndex.split('.');
    return getValueByPathRecursive(record, paths);
    //
    // let value = record
    // for (let p of paths) {
    //   value = value[p]
    //   if (!value) {
    //     return null
    //   }
    // }
    // return value
  } else {
    return '';
  }
};

export const getValueByPathRecursive = (currentValue: any, paths: string[]): any => {
  let p = paths[0];
  if (!currentValue) {
    return null;
  }
  currentValue = currentValue[p];
  if (paths.length === 1) {
    return currentValue;
  }

  paths = paths.slice(1, paths.length);

  if (Array.isArray(currentValue)) {
    let values: any[] = [];
    for (let x of currentValue) {
      let v = getValueByPathRecursive(x, paths);
      if (!!v) {
        if (Array.isArray(v)) {
          let arrayV: any[] = v;
          arrayV.forEach(arrayItem => {
            if (values.indexOf(arrayItem) === -1) {
              values.push(arrayItem);
            }
          });
        } else {
          if (values.indexOf(v) === -1) {
            values.push(v);
          }
        }
      }
    }
    return values;
  } else {
    return getValueByPathRecursive(currentValue, paths);
  }
};

export const buildColumnsByConfiguration = (tableConfiguration: string | any): ColumnsType<any> => {
  if (typeof tableConfiguration === 'string') {
    tableConfiguration = JSON.parse(tableConfiguration.replaceAll('\'', '"'));
  }

  let columns: ColumnsType<any> = [];
  let isAdmin = getUserRole() === admin;
  if (!!tableConfiguration) {
    // try {
    tableConfiguration
      .filter(item => {
        return isAdmin ? !item.hideForAdmin : !item.hideForSupplier;
      })
      .forEach((item: any) => {
        columns.push({
          ellipsis: item.ellipsis,
          width: item.width,
          fixed: item.fixed,
          key: 'col_' + item.dataIndex,
          title: __DEF_VALUE(item.title, item.title),
          dataIndex: item.dataIndex,
          sorter: item.sorter,
          sortDirections: item.sortDirections,
          render: (text, record: any) => {
            switch (item.renderType) {
              case 'quantityOnTotal':
                let quantity = getValueByPath(record, 'attributes.quantity');
                let totalQuantity = getValueByPath(record, 'attributes.originalQuantity');
                if (!totalQuantity) {
                  totalQuantity = getValueByPath(record, 'attributes.quantity');
                }
                return <Text
                  key={item.dataIndex + '_col'}>{quantity === totalQuantity ? quantity : quantity + ' / ' + totalQuantity}  </Text>;
              case 'network':
                return (
                  <div key={item.dataIndex + '_col'}>
                    {record.networks?.map(network => (
                      <OrderNetworkTag network={network} key={item.dataIndex + '_network_' + network} />
                    ))}
                  </div>
                );
              case 'date': {
                const paths = item.renderValue ? item.renderValue : item.dataIndex;
                let value: any = getValueByPath(record, paths);
                return (<Text key={item.dataIndex + '_col'}>{value && moment(value).format('DD/MM/YYYY')}</Text>);
              }
              case 'dateTime': {
                const paths = item.renderValue ? item.renderValue : item.dataIndex;
                let value: any = getValueByPath(record, paths);
                return (
                  <Text key={item.dataIndex + '_col'}>{value && moment(value).format('DD/MM/YYYY HH:mm:ss')}</Text>);
              }
              case 'boolean': {
                const paths = item.renderValue ? item.renderValue : item.dataIndex;
                let value: any = getValueByPath(record, paths);
                return (<Text key={item.dataIndex + '_col'}>{value ? __(T.misc.yes) : __(T.misc.no)}</Text>);
              }
              case 'tag': {
                let view: any = [];
                if (!!record.splittedOrderId) {
                  view.push(<Tag key={item.dataIndex + '_tag_split'}
                                 color={__(T.colors.split)}>{__(T.tags.split)}</Tag>);
                }
                if (!!record.reject) {
                  view.push(<Tag key={item.dataIndex + '_tag_reject'}
                                 color={__(T.colors.rejected)}>{__(T.tags.rejected)}</Tag>);
                }
                if (!!record.handmade) {
                  view.push(<Tag key={item.dataIndex + '_tag_handmade'}
                                 color={__(T.colors.handmade)}>{__(T.tags.handmade)}</Tag>);
                }
                if (!!record.errorValidation) {
                  view.push(<Tag key={item.dataIndex + '_tag_errorValidation'}
                                 color={__(T.colors.errorValidation)}>{__(T.tags.errorValidation)}</Tag>);
                }
                return view;
              }
              case'rejectTag': {
                let view: any = [];
                if (!!record.reject && !!record.reject.reasons) {
                  if (record.status === 'COMPLETED' || record.status === 'APPROVED') {
                    record.reject.reasons.forEach(item => {
                      view.push(<Tag key={item + '_tag_errorValidation'} color={'GREEN'}>{__(item)}</Tag>);
                    });
                  } else {
                    record.reject.reasons.forEach(item => {
                      view.push(<Tag key={item + '_tag_errorValidation'}
                                     color={EnquiryRejectReasonsSettingsTools.getColorByCode(item)}>{__(item)}</Tag>);
                    });
                  }
                }
                return view;
              }
              case'rejectChildTag': {
                let view: any = [];
                if (!!record.childDataList) {
                  const openRejectReasons: string[] = OrderRejectsTools.getAllOpenReasons(record);
                  const closeRejectReasons: string[] = OrderRejectsTools.getAllClosedReasons(record)
                    .filter(s => openRejectReasons.indexOf(s) === -1);

                  openRejectReasons.forEach(item => {
                    view.push(<Tag key={item + '_tag_errorValidation'}
                                   color={EnquiryRejectReasonsSettingsTools.getColorByCode(item)}>{item}</Tag>);
                  });

                  closeRejectReasons.forEach(item => {
                    view.push(<Tag key={item + '_tag_errorValidation'} color={"GREEN"}>{item}</Tag>);
                  });
                }
                return view;
              }
              case 'categories' : {
                const paths = item.renderValue ? item.renderValue : item.dataIndex;
                let value: any = getValueByPath(record, paths);
                return <Text key={item.dataIndex + '_col'}>{describeCategories(value)}</Text>;
              }
              case 'expireDate': {
                const paths = item.renderValue ? item.renderValue : item.dataIndex;
                let value: any = getValueByPath(record, paths);
                if (!value) {
                  return <Text key={item.dataIndex + '_col'}></Text>;
                }
                value = moment(value);
                if (moment().diff(value) > 0) {
                  return (<Text key={item.dataIndex + '_col'}>{value && moment(value).format('DD/MM/YYYY')}
                    <ExclamationCircleTwoTone twoToneColor="#ff1e1e" style={{ fontSize: '18px', marginLeft: '4px' }} />
                  </Text>);
                } else {
                  return (<Text key={item.dataIndex + '_col'}>{value && moment(value).format('DD/MM/YYYY')}
                    <CheckCircleTwoTone twoToneColor="#30d130" style={{ fontSize: '18px', marginLeft: '4px' }} />
                  </Text>);
                }

              }
              case 'valueList':
                const paths = item.renderValue ? item.renderValue : item.dataIndex;
                let values: any[] = getValueByPath(record, paths);
                return (
                  <div key={item.dataIndex + '_col'}>
                    {values && values.join(', ')}
                  </div>
                );
              default: {
                const paths = item.renderValue ? item.renderValue : item.dataIndex;
                let value = getValueByPath(record, paths);
                return <Text key={item.dataIndex + '_col'}>{value}</Text>;
              }
            }
          }
        });
      });
    // } catch (e) {
    //   console.log(e)
    // }
  }
  return columns;
};
