import { Card, Col, Row, Spin } from 'antd';
import React, { Component } from 'react';
import statisticApiTCare from '../../../api/StatisticApiTCare';
import AppStore from '../../../AppStore';
import { OrderStatusUtil, StatusesComputation } from '../ant-d-modules/tools/OrderStatusUtil';
import { isAdmin } from '../../../helpers/permission';


interface Props {
}

interface State {
  supplierOnboarded: string,
  supplierOnboardedIsLoading: boolean
  referencesImported: number
  completedComputation: number
  orderDataLoading: boolean
}


export default class AnalyticsSummariesGeneral extends Component<Props, State> {
  state: State = {
    supplierOnboarded: '0',
    supplierOnboardedIsLoading: true,
    referencesImported: 0,
    completedComputation: 0,
    orderDataLoading: true
  };

  async componentDidMount() {
    await this.refreshComponent();
  }


  async refreshComponent() {
    let getNumberOfOnBoarding: StatusesComputation;
    let onBoarding: string;
    if (!isAdmin()) {
      onBoarding = await statisticApiTCare.getNumberOfOnBoarding(AppStore.loggedUser!.company!.id,
        '',
        false);
      getNumberOfOnBoarding = await OrderStatusUtil.getAllByComputationByIdCompany(
        AppStore.loggedUser!.company!.id,
        false,
        null,
        null);

    } else {
      onBoarding = await statisticApiTCare.getNumberOfOnBoarding('null', '',
        true);
      getNumberOfOnBoarding = await OrderStatusUtil.getAllByComputationByIdCompany(
        'null',
        true,
        null,
        null);
    }
    this.setState({
      supplierOnboarded: onBoarding,
      completedComputation: getNumberOfOnBoarding.completedComputation + getNumberOfOnBoarding.approvedComputation,
      referencesImported: OrderStatusUtil.getTotalByComputation(
        getNumberOfOnBoarding),
      supplierOnboardedIsLoading: false,
      orderDataLoading: false
    });
  }


  render() {
    return (
      <>
        <Card style={{ marginBottom: 10 }}>
          <Row gutter={[10, 20]}>
            <Col xl={4} style={{ minWidth: 300 }}>
              <Spin spinning={this.state.supplierOnboardedIsLoading} size="large">
                <Row>
                  <Col style={{ minWidth: 100 }}>
                    <img
                      alt="example"
                      style={{ height: 75, justifyContent: 'center', display: 'flex' }}
                      src={require('./../../../assets/images/T1-supplier-onboarded.svg').default}
                    />
                  </Col>
                  <Col style={{ minWidth: 10 }}>
                    <Row>
                      <Col span={24}>
                        <div style={{ fontSize: '2em' }}>{this.state.supplierOnboarded}</div>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={24}>
                        Supplier onboarded
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Spin>
            </Col>
            <Col xl={4} style={{ minWidth: 300 }}>
              <Spin spinning={this.state.orderDataLoading} size="large">
                <Row>
                  <Col style={{ minWidth: 100 }}>
                    <img
                      alt="example"
                      style={{ height: 75, justifyContent: 'center', display: 'flex' }}
                      src={require('./../../../assets/images/references-imported.svg').default}
                    />
                  </Col>
                  <Col style={{ minWidth: 10 }}>
                    <Row>
                      <Col span={24}>
                        <div style={{ fontSize: '2em' }}>{this.state.referencesImported}</div>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={24}>
                        References imported
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Spin>
            </Col>
            <Col xl={4} style={{ minWidth: 300 }}>
              <Spin spinning={this.state.orderDataLoading} size="large">
                <Row>
                  <Col style={{ minWidth: 100 }}>
                    <img
                      alt="example"
                      style={{ height: 75, justifyContent: 'center', display: 'flex' }}
                      src={require('./../../../assets/images/references-completed.svg').default}
                    />
                  </Col>
                  <Col style={{ minWidth: 10 }}>
                    <Row>
                      <Col span={24}>
                        <div style={{ fontSize: '2em' }}>{this.state.completedComputation}</div>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={24}>
                        Reference completed
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Spin>
            </Col>
          </Row>
        </Card>
      </>
    );
  }
}


/*
Dividendo per mille i millesecondi ottieni i secondi totali, chiamiamo st il numero di secondi totali.
La parte intera di st/3600 ti dà il numero di ore, chiamiamola h;
il numero di minuti è dato dalla parte intera di (st/3600−h)⋅60, chiamiamola m;
il numero di secondi è dato dalla parte intera di (((st/3600−h)⋅60)−m)⋅60, chiamiamola s.
La parte decimale di s è il resto.

Esempio: 137982282 millisecondi.
Secondi totali = 1379822821000=137982.282
Ore = parte intera di 137982.2823600=38
Minuti = parte intera di (parte decimale del risultato di prima⋅60)=19
Secondi = parte decimale del risultato di prima⋅60=42,282
Risultato:  137982282=38 ore, 19 minuti, 42 secondi, 282 millisecondi.
 */




