import React from "react";
import AppStore from "../../../AppStore";
import {StatisticData} from "../../../api/types";
import statisticApiTCare from "../../../api/StatisticApiTCare";
import StatisticApiTCare from "../../../api/StatisticApiTCare";
import {Card, Col} from "antd";
import PiePlot from '../ant-d-modules/pie-plot/PiePlot';
import {OrderStatusUtil} from "../ant-d-modules/tools/OrderStatusUtil";
import { setOffGlobalSpinningOnHeader } from '../../../api/api';


interface Props {
  startYearMonthFilter: string | null;
  endYearMonthFilter: string | null;
  title: string
  creationCompany?: string
  statistics: { statisticName: string, label: string } []
}

interface State {
  data: any;
  isLoading: boolean
}


export default class MultiStatPieOrderData extends React.Component<Props, State> {
  state: State = {
    data: [],
    isLoading: true
  };

  async componentDidMount() {
    await this.refreshComponent();
  }

  async componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any) {
    if (prevProps.startYearMonthFilter !== this.props.startYearMonthFilter ||
        prevProps.endYearMonthFilter !== this.props.endYearMonthFilter ||
        prevProps.creationCompany !== this.props.creationCompany) {
      await this.refreshComponent();
    }
  }

  async refreshComponent() {
    if (!!AppStore.loggedUser?.company) {
      await this.loadData(AppStore.loggedUser?.company.id, false);
    } else {
      await this.loadData(null, true);
    }

  }

  async loadData(idCompany: string | null, isAdmin: boolean) {

    let data: [{
      type: string,
      value: number
    }?] = [];

    for (const stat of this.props.statistics) {
      const valueStatisticData: StatisticData =
          await statisticApiTCare.getStatisticDataByUrl(stat.statisticName + "?"
              + OrderStatusUtil.getCreationOrAssigneeCompanyFilter(isAdmin, idCompany)
              + StatisticApiTCare.getDataRangeFilter(this.props.startYearMonthFilter, this.props.endYearMonthFilter)
              + StatisticApiTCare.getCreationCompanyT1Filter(this.props.creationCompany)
              + 'groupBy=status',
              setOffGlobalSpinningOnHeader())
      ;
      let value = 0
      if (!!valueStatisticData) {
        for (let key in valueStatisticData) {
          value = value + (valueStatisticData[key].key.status === 'REJECTED' ? -valueStatisticData[key].value : valueStatisticData[key].value)
        }
      }
      data.push({
        type: stat.label,
        value: value
      });
    }

    this.setState({data, isLoading: false});
  }

  render() {
    return (
        <Col xl={8} sm={24} lg={12}>
          <Card
              title={this.props.title}
              bordered={true}
              style={{width: "100%", height: 500}}
          >
            <PiePlot data={this.state.data}/>
          </Card>
        </Col>
    );
  }
}




