import {Order} from './types';
import {AxiosRequestConfig} from 'axios'
import api, {responseErrorCheck} from './api'

class OrderHandmadeApi {
    protected endpoint = 'tracemanager/v1/orders/handmade'

    async newOrder(command: {
        handmadeConfigId: string,
        orderCode: string,
        orderType: string,
        companyId: string,
        productMatchCode: string,
        attributes: any,
        data?: any
        customerCompanyId: string,
        action: 'STANDARD' | 'FULFILL' | 'DRAFT'
    }, axiosConfig?: AxiosRequestConfig) {
        const res = await api.post<Order>(`${this.endpoint}`, command, axiosConfig)
        return responseErrorCheck(res)
    }

    async saveOrder(orderId: string, attributes: any, axiosConfig?: AxiosRequestConfig) {
        const res = await api.put<Order>(`${this.endpoint}/${orderId}`, attributes, axiosConfig)
        return responseErrorCheck(res)
    }

}

const orderHandmadeApi = new OrderHandmadeApi()
export {orderHandmadeApi as default}
