import React from "react";
import AppStore from "../../../AppStore";
import TinyProgressStyleColCard from "../cards/TinyProgressStyleColCard";
import {OrderStatusUtil, StatusesComputation} from "../ant-d-modules/tools/OrderStatusUtil";
import { __, T } from '../../../config/i18n';


interface Props {
  startYearMonthFilter: string | null;
  endYearMonthFilter: string | null;
  creationCompany?: string
}

interface State extends StatusesComputation{
  isLoading: boolean
}


export default class StatusOfTheReferences extends React.Component<Props, State> {
  state: State = {
    assignedComputation: 0,
    draftComputation: 0,
    completedComputation: 0,
    approvedComputation: 0,
    completedThirdPartComputation: 0,
    fullFilledComputation: 0,
    toBeAssignedComputation: 0,
    isLoading: true
  };

  private orderAvailableStatuses: string [] = [];

  async componentDidMount() {
    await this.refreshComponent();
  }

  async componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any) {
    if (prevProps.startYearMonthFilter !== this.props.startYearMonthFilter ||
        prevProps.endYearMonthFilter !== this.props.endYearMonthFilter ||
        prevProps.creationCompany !== this.props.creationCompany) {
      await this.refreshComponent();
    }
  }

  async refreshComponent() {
    let getNumberOfOnBoarding: StatusesComputation

    this.orderAvailableStatuses =
      AppStore.appSettings.orderAvailableStatuses.split(',');

    if (!!AppStore.loggedUser?.company) {
      getNumberOfOnBoarding = await OrderStatusUtil.getAllByComputationByIdCompany(
        AppStore.loggedUser!.company.id,
        false,
        this.props.startYearMonthFilter,
        this.props.endYearMonthFilter);

    } else {
      if (this.props.creationCompany) {
        getNumberOfOnBoarding = await OrderStatusUtil.getAllByComputationByIdCompany(this.props.creationCompany,
            false,
            this.props.startYearMonthFilter,
            this.props.endYearMonthFilter);
      } else {
        getNumberOfOnBoarding = await OrderStatusUtil.getAllByComputationByIdCompany(null,
            true,
            this.props.startYearMonthFilter,
            this.props.endYearMonthFilter);
      }


    }
    this.setState({
      ...getNumberOfOnBoarding,
      isLoading: false
    })
  }



  render() {
    let total: number = OrderStatusUtil.getTotalByComputation(
        this.state)

    let labelTotal = total;
    if (total === 0) {
      total = 1
    }
    //total : 100 = completed : x => x = 100 * completed / total


    let tinyProgressStyleColRender: any = [];

    if (this.orderAvailableStatuses.includes('APPROVED')) {
      tinyProgressStyleColRender.push({
        'label': __(T.misc.approved),
        'labelValue': this.state.approvedComputation + ' / ' + labelTotal,
        'value': parseInt((this.state.approvedComputation / total * 100) + ''),
        suffix: '%'
      })
    }

    if (this.orderAvailableStatuses.includes('COMPLETED')) {
      tinyProgressStyleColRender.push({
        'label': __(T.misc.completed),
        'labelValue': this.state.completedComputation + ' / ' + labelTotal,
        'value': parseInt((this.state.completedComputation / total * 100) + ''),
        suffix: '%'
      })
    }

    if(this.orderAvailableStatuses.includes('DRAFT')) {
      tinyProgressStyleColRender.push({
        'label': __(T.misc.draft),
        'labelValue': this.state.draftComputation + ' / ' + labelTotal,
        'value': parseInt((this.state.draftComputation / total * 100) + ''),
        suffix: '%'
      })
    }

    if(this.orderAvailableStatuses.includes('FULFILLED')) {
      tinyProgressStyleColRender.push({
        'label': __(T.misc.fulfilled),
        'labelValue': this.state.fullFilledComputation + ' / ' + labelTotal,
        'value': parseInt((this.state.fullFilledComputation / total * 100) + ''),
        suffix: '%'
      })
    }

    if(this.orderAvailableStatuses.includes('ASSIGNED')) {
      tinyProgressStyleColRender.push({
        'label': __(T.misc.assigned),
        'labelValue': this.state.assignedComputation + ' / ' + labelTotal,
        'value': parseInt((this.state.assignedComputation / total * 100) + ''),
        suffix: '%'
      })
    }

    if(this.orderAvailableStatuses.includes('TO_BE_ASSIGNED')) {
      tinyProgressStyleColRender.push({
        'label': __(T.misc.to_be_assigned),
        'labelValue': this.state.toBeAssignedComputation + ' / ' + labelTotal,
        'value': parseInt((this.state.toBeAssignedComputation / total * 100) + ''),
        suffix: '%'
      })
    }






    return (
      <TinyProgressStyleColCard
                                labels={tinyProgressStyleColRender}
                                title={'Status of the references'}
                                isLoading={this.state.isLoading}/>
    );
  }
}






