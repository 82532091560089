import React from "react";
import { Card, Col, Row, Spin } from "antd";

interface Props {
  title: string,
  labels: [{
    label: string,
    labelValue?: string,
    value: number,
  }]
  isLoading: boolean
}


export default class TinyProgressStyleColCard extends React.Component<Props> {


  async componentDidMount() {
  }


  render() {
    // https://codepen.io/psibal/pen/BaoGxMm
    const circleStyle = {
      padding: 0,
      margin: 0,
      display: "inline-block",
      // position:'absolute',
      backgroundColor: "#0066FF",
      borderRadius: "20%",
      width: 12,
      height: 10,
      left: 0,
      top: 0
    };
    return (
      <Col xl={8} sm={24} lg={12}>
        <Card
            key={'tinyKey1'}
          title={this.props.title}
          bordered={true}
          style={{ width: "100%", height: 500 }}
          className={"tyny-progress-style-col-card"}
        >
          <Spin spinning={this.props.isLoading} size="large">
            {this.props.labels.map(label => (
                // <div key={label.label}>
                <Row
                    //    style={{height: 25}}
                >
                  <Col span={2}>
                    <div style={circleStyle}/>
                  </Col>
                  <Col span={10}>{label.label}</Col>
                  <Col style={{textAlign: 'right'}} span={12}>{label.labelValue ?? label.value}</Col>
                  <Col span={24}>
                    <div style={{
                      width: '100%',
                      backgroundColor: '#E8EDF3',
                      height: 10,
                      borderRadius: 5,
                      overflow: 'hidden'
                    }}>
                      <div style={{
                        width: label.value + '%',
                        backgroundColor: '#0066FF',
                        height: '100%',
                        textIndent: -3000,
                        borderRadius: 5
                      }}>
                        .
                      </div>
                    </div>


                    {/*<TinyProgressStyle value={label.value}/>*/}
                  </Col>

                </Row>

                // </div>
            ))}
          </Spin>
        </Card>
      </Col>
    );
  }
}




