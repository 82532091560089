import React from 'react'
import { OrderStatus } from '../../api/types'
import { __, T } from '../../config/i18n'
import { Tag } from 'antd'

interface Props {
  status: OrderStatus
  style?: React.CSSProperties
}

export default class OrderStatusTag extends React.Component<Props> {
  render = () => {
    const { status, style } = this.props
    return (
      <Tag
        style={{
          textAlign: 'center',
          color: __(T.colors[`${status.toLowerCase()}_text`]),
          ...(style ?? {}),
        }}
        color={__(T.colors[`${status.toLowerCase()}`])}
      >
        {__(T.misc[status.toLowerCase()])}
      </Tag>
    )
  }
}
