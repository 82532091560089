import { Tag } from 'antd'
import { __, T } from '../../config/i18n'
import React from 'react'

interface Props {
  network: string
  style?: React.CSSProperties
}

export default class OrderNetworkTag extends React.Component<Props> {
  render = () => {
    return (
      <Tag
        color={__(T.colors.network_background)}
        style={{ ...this.props.style, color: __(T.colors.network_text), borderRadius: '5px' }}
      >
        <b>{this.props.network}</b>
      </Tag>
    )
  }
}
