import { DetailedOrderWithOwnerCompanyInfo } from '../api/types';
import moment from 'moment';

type TraceData = {
  data?: TraceData[]
  title: string
  type: 'string' | 'file' | 'array'
  span: number
  value?: string | any
}

export class GeneralExports {

  public exportViewCsv(csvData: any = [], schema: any, order: DetailedOrderWithOwnerCompanyInfo) {
    csvData.push(['PO Number', order.code]);
    csvData.push(['Supplier Name', order.ownerCompanyName]);
    csvData.push(['Date of fulfillment', moment(order.fulfillmentDate).format('DD.MM.YYYY')]);
    this.getCsv(order.data, csvData);
  }


  public getCsv(data: object, csvData: any) {
    for (var key in data) {
      if (typeof data[key] === 'object') {
        this.getCsv(data[key], csvData);
      } else {
        csvData.push([key, data[key]]);
      }
    }
  }


  public extractData(item: any, properties: any): TraceData[] {
    return Object.entries<any>(properties).reduce((result: TraceData[], [propertyKey, propertyValue]) => {
      const title = propertyValue.title;
      const span = propertyValue.span;
      if (propertyValue.type === 'array' && item[propertyKey]) {
        const type = 'array';
        if (properties[propertyKey].items.format === 'data-url') {
          const data = item[propertyKey].map(file => ({ title: '', type: 'file', value: file }));
          result.push({ title, type, data, span });
        } else {
          item[propertyKey].forEach(child => {
            const data = this.extractData(properties[propertyKey].items.properties, child);
            result.push({ title, type, data, span });
          });
        }
      } else {
        const type = propertyValue.format === 'data-url' ? 'file' : 'string';
        const value = item[propertyKey];
        result.push({ title, type, value, span });
      }
      return result;
    }, [] as TraceData[]);
  };
}


const generalExports = new GeneralExports();
export { generalExports as default };
