import React, { Component } from 'react'


interface State {
    onBoardingId?: string
    currentStep: number
    loading: boolean
}


export default class DropLiquidPlot extends Component<{}, State> {
    state: State = {
        currentStep: 0,
        loading: false,
    }

    render() {
        return (
            <div style={{marginTop: 40, justifyContent: 'center', display: 'flex'}}>
                <img
                    alt='example'
                    style={{height: 300, justifyContent: 'center', display: 'flex'}}
                    src={require('../../../../assets/images/drop.png')}
                />
                <div style={{position: 'relative',
                    right: 150,
                    top: 50}}>
                    <div style={{fontSize: 100, color: 'black', margin: 0}}>4</div>
                    <div style={{fontSize: 20,  color: 'white', position: 'relative', justifyContent: 'center'}}>
                       Million Lt
                    </div>
                </div>
            </div>
        )
    }
}




