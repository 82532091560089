import { QueryParams } from '../api/types';

export class GenericTools {


  public getTodayDate(format: string, goBackMonth: number): string|null {
    const today = new Date();
    today.setMonth(today.getMonth() - goBackMonth);
    const dd = String(today.getDate()).padStart(2, "0");
    const mm = String(today.getMonth() + 1).padStart(2, "0");
    const yyyy = today.getFullYear();
    switch (format) {
      case ('mm-dd-yyyy'):
        return mm + '-' + dd + '-' + yyyy
      case ('yyyy-mm'):
        return yyyy + '-' + mm
    }
    return ''
  }
  public  generateGetUrl(endpoint: string, queryParams: QueryParams): string {
    const queryString = Object.entries(queryParams)
      .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
      .join('&');

    return `${endpoint}?${queryString}`;
  }
  createRequestParams(queryParams: QueryParams): { [key: string]: string[] } {
    const requestParams: { [key: string]: string[] } = {};

    for (const key in queryParams) {
      if (queryParams[key] !== undefined) {
        if (key !== 'page' && key !== 'size') {
          const value = queryParams[key];
          if (Array.isArray(value)) {
            requestParams[key] = value;
          } else {

            if(!!value.getTime){
              requestParams[key] = [String(value.getTime())]
            } else {
              requestParams[key] = [String(value)];
            }
          }
        }
      }
    }
    return requestParams;
  }
}


const genericTools = new GenericTools()
export { genericTools as default }
