import React, { Component } from 'react'
import { Modal, Row, Col } from 'antd'
import { __, T } from 'config/i18n'
import { DetailRuleModalElement, ModalHeader, View } from 'components'
import { Rule } from 'api/types'

interface Props {
  rule: Rule
  visible: boolean
  closed: () => void
}

export default class DetailRuleModal extends Component<Props> {
  view = exception => {
    if (Array.isArray(exception.values)) {
      return exception.values.length > 0
    } else {
      return exception.values !== ''
    }
  }

  render = () => {
    const { visible, closed, rule } = this.props
    return (
      <Modal
        title={<ModalHeader close={closed} title={__(T.misc.modal_detail_rule_title)} />}
        centered
        visible={visible}
        footer={null}
        closable={false}
        onCancel={() => closed()}
        width="60%"
        className="sc-rule-detail"
      >
        <Row gutter={[16, 16]}>
          <DetailRuleModalElement label={__(T.fields.rule_description)} value={rule.name} col={24} type="text" />
        </Row>
        <Row gutter={[16, 16]}>
          <DetailRuleModalElement label={__(T.fields.start_date)} value={rule.startDate} col={12} type="date" />
          <DetailRuleModalElement label={__(T.fields.end_date)} value={rule.endDate} col={12} type="date" />
        </Row>
        {rule.exceptions.map((exception, i) => {
          return this.view(exception) ? (
            <Row key={'exception_' + i} gutter={[16, 16]}>
              <Col xs={24} sm={24}>
                <View className="sc-rule-detail-box" style={{ flexDirection: 'row' }}>
                  <DetailRuleModalElement
                    label={__(T.fields.property)}
                    value={__(T.misc[exception.property || ''] || exception.property)}
                    type="text"
                    isColumn
                  />
                  <DetailRuleModalElement label={__(T.fields.value)} value={exception.values} type="array" isColumn />
                </View>
              </Col>
            </Row>
          ) : null
        })}
      </Modal>
    )
  }
}
