import React from "react";
import AppStore from "../../../AppStore";
import { StatisticData } from "../../../api/types";
import statisticApiTCare from "../../../api/StatisticApiTCare";
import { getCanvasPattern } from "@ant-design/charts";
import { deepMix } from "@antv/util";
import VerticalBasicBulletPlotCard from "../cards/VerticalBasicBulletPlotCard";



interface Props {
}

interface State {
  data: [{
    state: string,
    type: string,
    value: number
  }?];
  isLoading: boolean
}


export default class SuppliersOnboarded extends React.Component<Props, State> {
  state: State = {
    data: [],
    isLoading: true
  };

  async componentDidMount() {
    if (!!AppStore.loggedUser?.company) {
      await this.getOnboard(AppStore.loggedUser?.company.id);
    } else {
      await this.getOnboard("null");
    }
  }


  async getOnboard(idCompany: string) {
    let data: [{
      state: string,
      type: string,
      value: number
    }?] = [];
    const statisticData: StatisticData = await statisticApiTCare.getOnBoarding(idCompany);
    if (!!statisticData) {

      for (let key in statisticData) {
        data.push({
          state: statisticData[key].key["payload.categories"] ?? "Empty",
          type: statisticData[key].key["payload.categories"] ?? "Empty",
          value: statisticData[key].value
        });
      }
    }
    this.setState({ data: data, isLoading: false });


  }


  getConfigData(): any {
    const PATTERN_MAP = {
      Gas: {
        type: "dot"
      },
      Wasserkraft: {
        type: "line"
      },
      Biomasse: {
        type: "square"
      },
      Wind: {
        type: "line",
        cfg: {
          rotation: 90
        }
      },
      Solar: {
        type: "square",
        cfg: {
          size: 5,
          padding: 2,
          rotation: 45,
          isStagger: false
        }
      }
    };
    const pattern = ({ type }, color) =>
      getCanvasPattern(
        deepMix({}, PATTERN_MAP[type], {
          cfg: {
            backgroundColor: color
          }
        })
      );
    return {
      data: this.state.data,
      xField: "state",
      yField: "value",
      seriesField: "type",
      isPercent: false,
      isStack: true,
      slider: {
        start: 0,
        end: 10
      },

      pattern: ({ type }, color) =>
        pattern(
          {
            type
          },
          color
        ),
      legend: {
        marker: (text, index, item) => {
          const color = item.style.fill;
          return {
            style: {
              fill: pattern(
                {
                  type: text
                },
                color
              ),
              r: 8
            }
          };
        }
      },
      interactions: [
        {
          type: "element-highlight-by-color"
        },
        {
          type: "element-link"
        }
      ]
    };
  }

  render() {
    return (
      <VerticalBasicBulletPlotCard
                                   config={this.getConfigData()} isLoading={this.state.isLoading}
                                   title={"Types of suppliers"}/>
    );
  }
}




