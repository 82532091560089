import React from 'react';
import { OrderHandmadeConfig } from 'api/types';
import { __, T } from 'config/i18n';
import { ColumnsType } from 'antd/es/table';
import { Schema } from '../AdvancedForm';
import ModalTableSelectRow from '../ModalTableSelectRow';
import { buildColumnsByConfiguration } from './ColumnBuilderUtil';
import { buildFiltersByConfiguration } from './FilterBuilderUtil';
import OrderHandmade from '../../api/OrderHandmades';
import AppStore from '../../AppStore';
import { isAdmin } from '../../helpers/permission';

interface Props {
  isVisible: boolean;
  onCancelCallback: () => void;
  onSuccessCallback: (libraryConfig: any) => void;
}

interface State {
  rowsSelected?: any[];
  currentPage: number;
  sortField: string;
  sortDirection: 'asc' | 'desc';
  pageSize: number;
  totalItems: number;
}

export default class NewOrderModal extends React.Component<Props, State> {

  state: State = {
    currentPage: 1,
    sortField: 'label',
    sortDirection: 'asc',
    pageSize: 10,
    totalItems: 0,
  };

  async componentDidMount() {
    await this.fetchData();
  }

  fetchData = async () => {
    const { currentPage, sortField, sortDirection, pageSize } = this.state;
    const result = await this.search({}, currentPage, sortField, sortDirection, pageSize);
    this.setState({ totalItems: result.totalElements });
  };

  getFilters = (): Schema[] => {
    return buildFiltersByConfiguration([
      {
        name: 'label',
        type: 'text',
        label: T.fields.typology,
        placeholder: T.misc.write,
      },
    ]);
  };

  getColumns = (): ColumnsType<OrderHandmadeConfig> => {
    return buildColumnsByConfiguration([
      {
        title: __(T.fields.typology),
        dataIndex: 'label',
        sorter: true,
        sortDirections: ['ascend', 'descend', 'ascend'],
      },
    ]);
  };

  search = async (params: any, currentPage: number, sortField: string, sortDirection: 'asc' | 'desc', size: number) => {
    if (!isAdmin()) {
      const categories = AppStore.appSettings.handmadeSupplierCompany.split(',');
      params['categories'] = AppStore.loggedUser?.company?.categories?.filter(c => categories.includes(c));
    }

    const result = await OrderHandmade.search({
      ...params,
      page: currentPage,
      size,
      sort: `${sortField},${sortDirection}`,
    });

    if (result.totalPages === 1 && result.content.length === 1) {
      this.props.onSuccessCallback(result.content[0]);
    }

    return result;
  };

  handlePageChange = async (page: number, pageSize?: number) => {
    this.setState({ currentPage: page, pageSize: pageSize || this.state.pageSize }, this.fetchData);
  };

  handleTableChange = async (pagination: any, filters: any, sorter: any) => {
    const { current, pageSize } = pagination;
    const sortField = sorter.field || this.state.sortField;
    const sortDirection = sorter.order === 'ascend' ? 'asc' : 'desc';

    this.setState(
      {
        currentPage: current,
        sortField,
        sortDirection,
        pageSize,
      },
      this.fetchData
    );
  };

  render() {
    const { isVisible, onCancelCallback, onSuccessCallback } = this.props;
    const { rowsSelected } = this.state;

    return (
      <ModalTableSelectRow<OrderHandmadeConfig>
        rowsSelected={rowsSelected}
        isVisible={isVisible}
        onCancel={onCancelCallback}
        onSuccess={onSuccessCallback}
        onSearchElements={this.search}
        getColumnsConfig={this.getColumns}
        getFiltersConfig={this.getFilters}
        title={__(T.titles.new_library_dialog)}
      />
    );
  }
}
