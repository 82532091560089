import { Certification, Company } from 'api/types'
import { CertificationFields, CompanyFields } from 'types'
import { getCompanyType } from './describer'
import moment from 'moment'

export const getCompanyFieldsFromCompany = (
  company?: Company
): CompanyFields => {
  if (!company) return {}
  return {
    companyType: getCompanyType(company.type),
    categories: company.categories,
    businessName: company.data.businessName,
    pivaCf: company.data.legalCode,
    country: company.data.country,
    city: company.data.city,
    postalCode: company.data.cap,
    address: company.data.address,
    addressNumber: company.data.addressNumber,
    companyPhone: company.data.phone,
    companyMobilePhone: company.data.mobilePhone,
    companyEmail: company.data.email,
    companyEmailPec: company.data.pec,
    allowedQuantity: company.data.quantityAllowed,
    suppressionMethod: company.data.suppressionMethod,
    adherenceEthicsCode: company.data.ethicalCode,
    competenceTerritory: company.data.competenceArea,
    companyCode: company.companyCode,
    certifications: getCertificationFieldsFromCertification(company.certifications),
    attributes: company.attributes
  }
}

export const getCertificationFieldsFromCertification = (certifications: Certification[]): CertificationFields[] =>
  certifications.map(certification => ({
    id: certification.id,
    expiration: moment(certification.data.expiration).format('YYYY-MM-DD'),
    certificateHash: certification.data.certificateHash,
    filename: certification.data.filename,
    fileId: certification.data.fileId,
    certificateType: certification.data.certificateType,
    certificateNumber: certification.data.certificateNumber,
    companyName: certification.data.companyName,
    certificateStatus: certification.data.certificateStatus,
  }))


