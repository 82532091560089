import React from 'react';
import {DetailedOrder, SubOrder, SubOrderAssignment} from '../../api/types';
import Title from 'antd/es/typography/Title';
import {Button, Card, Col, Row} from 'antd';
import {AdvancedForm, AppForm} from '../index';
import {getSubOrdersByComponentArray, noComposant} from '../../helpers/orders';
import moment from 'moment';
import {__, T} from '../../config/i18n';
import { CopyOutlined, DeleteOutlined, LockFilled, UnlockFilled } from '@ant-design/icons'
import {AssignmentForm} from '../../pages/orders/OrderAssign';
import {Schema} from '../AdvancedForm';
import AppStore from '../../AppStore';
import api from '../../api/api';
import {Companies} from '../../api';
import TypesSettingsTools from '../../tools/TypesSettingsTools';

interface Props {
  order: DetailedOrder;
  assignments: { [subOrderId: string]: AssignmentForm[] };
  onAssignmentChange: (assignment: AssignmentForm, changedValues, values) => void;
  onInviteCompanyClick: (subOrder: SubOrder) => void;
  onAssignmentClone?: (subOrderAssignmentToClone: SubOrderAssignment) => void;
  onAssignmentRemove?: (subOrderAssignmentToRemove: SubOrderAssignment) => void;
  onAssignmentUnlockSubOrders?: (subOrderAssignmentToRemove: SubOrderAssignment) => void;
  onAssignmentCancelSubOrders?: (subOrderAssignmentToRemove: SubOrderAssignment) => void;   // Per bloccare gli ordini in modo da superare l'assegnazione senza doverli assegnare
  onJsonSchemaFormSuccess: (assignment: AssignmentForm) => void;
    width?: number;
    disableInviteButton?: boolean;
    showCancelOrderButton?: boolean;
}

function getOrderAssignSchema(
  order: SubOrder,
    inviteCompanyClickHandler: (subOrder: SubOrder) => void,
    disableInviteButton?: boolean
): Schema[] {
    const loggedUserCompanyId = AppStore.loggedUser?.company?.id ?? undefined;
    return [
        {
      name: 'company',
      type: 'autocomplete',
            label: __(T.fields.company_name_vat),
            placeholder: __(T.misc.select),
      descriptionKey: 'label',
            urlSearch: `${api.getBaseURL()}${Companies.getEndpoint()}/selectAvailableCompany?categories=${
                order.category
            }&withOnBoardingCreatorCompanyId=true&onBoardingCreatorCompanyId=${
                loggedUserCompanyId //
            }&${Companies.getAutocompleteQueryParam()}=-X-`,
            withButton: !AppStore.appSettings.supplierDetailReadonly && !disableInviteButton,
            buttonLabel: __(T.buttons.invite),
            onButtonClick: () => inviteCompanyClickHandler(order),
            col: 24,
            rules: [{required: true}]
        }
    ];
}

export default class OrderAssignmentForm extends React.Component<Props> {
    handleValuesChanged = (assignment: AssignmentForm) => (changedValues, values) =>
    this.props.onAssignmentChange(assignment, changedValues, values);


    getAttributesSchema = () => {
    return JSON.parse(this.props.order.traceType.attributesSchema as string);
  };

  getPartDescription(parentOrder: DetailedOrder, subOrder: SubOrder) {
    let description: string = '';
    const setting = TypesSettingsTools.getSettingByTraceType(parentOrder.traceType.code, 'traceTypeDescription');
    if (setting === undefined) {
      return subOrder.step;
    }

    const labelPaths: string[] = JSON.parse(setting);
     labelPaths.forEach(labelPath => {
        let currentObject: any = subOrder;
        const pathArray = labelPath.split('.');
        for (const part of pathArray) {
          if (currentObject.hasOwnProperty(part)) {
            currentObject = currentObject[part];
          } else {
            // Se una delle parti non esiste nell'oggetto, esci dal loop e gestisci l'errore
            console.error(`La proprietà '${part}' non esiste`);
            break;
          }
        }
        if (currentObject !== null && currentObject !== undefined) {
          description += currentObject + ' ';
        }
      });
      return description;

    }

    render = () => {
        const {
            order,
            onInviteCompanyClick,
            assignments,
            onAssignmentClone,
            onAssignmentRemove,
            onAssignmentCancelSubOrders,
            onAssignmentUnlockSubOrders,
            width,
            disableInviteButton,
            showCancelOrderButton
        } = this.props;

        return getSubOrdersByComponentArray(order).map(([composant, subOrders]) => {
            return (
                <>
                    {composant !== noComposant && (
                        <Title level={4} style={{fontWeight: 'bold'}}>
                            {__(T.misc.insertSupplierMsg)}
                        </Title>
                    )}
                    {composant !== noComposant && <Title level={4}>{composant}</Title>}
                    {subOrders.map(subOrder => {
                            const assignment = assignments[subOrder.id]
                            return assignment ?
                                assignment
                                    .filter(ass => !ass.deleted)
                                    .map(
                                        assignment =>
                                            (
                                                <Row>
                                                    <Col span={width ?? 12}>
                                                        <Card
                                                            size="small"
                                                            bordered={false}
                                                            style={{
                                                                backgroundColor: '#F5F5F5',
                                                                borderRadius: '10px',
                                                                padding: '10px 20px 10px 20px',
                                                                marginBottom: '20px',
                          marginTop: '10px'
                                                            }}
                                                        >
                        <h2>{this.getPartDescription(order, subOrder)}</h2>
                                                          {!assignment.cancelOrder &&
                                                            <AdvancedForm
                                                                formRef={assignment.formRef}
                                                                title={(assignment.originalOrderId ? (__(T.messages.cloned) + (subOrder.productComposition ?? ' ')) : subOrder.productComposition)}
                                                                schema={getOrderAssignSchema(subOrder, onInviteCompanyClick, disableInviteButton)}
                                                                values={this.toAdvancedFormValues(assignment)}
                                                                onValuesChange={this.handleValuesChanged(assignment)}
                                                            />}
                                                          {!assignment.cancelOrder &&
                                                            <AppForm
                                                                onSuccess={() => {
                                                                    this.props.onJsonSchemaFormSuccess(assignment)
                                                                ;
                          }}
                                                                formSubmitButtonRef={assignment.buttonFormRef} // RefObject<HTMLButtonElement>
                                                                data={{
                                                                    orderCode: assignment.orderCode,
                                                                    orderDate: assignment.orderDate,
                                                                    attributes: {...assignment.attributes}
                                                                }}
                                                                schema={this.getAttributesSchema()}
                                                                onChange={value => {
                                                                    if (!!value.attributes) {
                                                                        if (!assignment.attributes) {
                                assignment.attributes = {};
                                                                        }
                                                                        Object.keys(value.attributes).forEach(key => assignment.attributes![key] = value.attributes[key]);
                                                                    }
                                                                    assignment.orderCode = value.orderCode;
                                                                    assignment.orderDate = value.orderDate;
                                                                }}
                                                            />}
                                                          {!assignment.cancelOrder && showCancelOrderButton && onAssignmentCancelSubOrders &&
                                                            <Button type="ghost"
                                                                    style={{
                                                                      width: '200px',
                                                                      float: 'right',
                                                                      backgroundColor: __(T.colors.cancelled)
                                                                    }}
                                                                    onClick={() => onAssignmentCancelSubOrders(assignment)}>
                                                              <LockFilled/> {__(T.buttons.block_order)}
                                                            </Button>}


                                                          {assignment.cancelOrder && showCancelOrderButton && onAssignmentUnlockSubOrders &&
                                                            <Button type="ghost"
                                                                    style={{
                                                                      width: '200px',
                                                                      backgroundColor: __(T.colors.reopen),
                                                                      float: 'right'
                                                                    }}
                                                                    onClick={() => onAssignmentUnlockSubOrders(assignment)}>
                                                              <UnlockFilled/> {__(T.buttons.unlock_order)}
                                                            </Button>}


                                                            {!assignment.originalOrderId && onAssignmentClone &&
                                                                <Button type="ghost"
                                                                        style={{
                                                                            width: '200px',
                                                                            backgroundColor: __(T.colors.gray_button),
                                                                            float: 'right'
                                                                        }}
                                                                        onClick={() => onAssignmentClone(assignment)}>
                                                                    <CopyOutlined/> {__(T.buttons.clone_order)}
                                                                </Button>}


                                                            {assignment.originalOrderId && onAssignmentRemove &&
                                                                <Button type="ghost"
                                                                        style={{
                                                                            width: '200px',
                                                                            float: 'right',
                                                                            borderColor: 'black'
                                                                        }}
                                                                        onClick={() => onAssignmentRemove(assignment)}>
                                                                    <DeleteOutlined/> {__(T.buttons.delete_order)}
                                                                </Button>
                                                            }
                                                        </Card>
                                                    </Col>
                                                </Row>
                                            )
                                    )
                                :
                                <></>
                        }
                    )}
                </>
            )
        })
    }

    toAdvancedFormValues = ({orderId, orderCode, orderDate, company}: SubOrderAssignment) => {
        return {
            orderId,
            orderCode,
            orderDate: orderDate ? moment(orderDate) : null,
            company: company ? {value: company.id, label: company.data?.businessName} : undefined
    };
  };


}
