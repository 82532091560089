import api, {responseErrorCheck} from './api'
import {TemplateSendEmailCommand} from './types';
import { AxiosRequestConfig } from 'axios';

const endpoint = 'filemanager/v1/templates'

class Templates {

  async getPdf(code: string, body: any, axiosConfig?: AxiosRequestConfig): Promise<string | undefined> {
    const res = await api.post<any>(`${endpoint}/download/${code}`, body, axiosConfig)
    return responseErrorCheck(res)
  }

  async getPdfOnBase64Format(code: string, body: any, axiosConfig?: AxiosRequestConfig): Promise<string | undefined> {
    const res = await api.post<any>(`${endpoint}/download/${code}/base64`, body, axiosConfig)
    return responseErrorCheck(res)
  }

  async sendEmail(code: string, emailAddress: string[], payload: any, fileName: string, axiosConfig?: AxiosRequestConfig): Promise<string | undefined> {
    const body: TemplateSendEmailCommand = {
      emailAddress,
      fileName,
      payload
    }

    const res = await api.post<any>(`${endpoint}/sendEmail/${code}`, body, axiosConfig)
    return responseErrorCheck(res)
  }

}

const templates = new Templates()
export { templates as default }
