import React, { Component } from 'react';
import { Menu } from 'antd';
import AppStore from '../../AppStore';
import { CheckOutlined } from '@ant-design/icons';
import Users from '../../api/Users';
import { navigate } from '../../router';


interface Props {
}


interface State {
}


const getHeaderDropDownMenu = () => {
  let headerDropDownMenu: any[] = [];

  AppStore.loggedUser?.companies?.forEach(company => {
    headerDropDownMenu.push({
      key: company.id,
      label: company.data.businessName,
      icon: AppStore.loggedUser?.company?.id === company.id ? <CheckOutlined /> : '',
      onClick: async () => {
        await Users.updateCurrentCompany(company.id);
        navigate('/')
        window.location.reload();
      }
    });
  });


  return headerDropDownMenu;
};


export default class CompanyMenu extends Component<Props, State> {
  state: State = {
    data: null
  };


  render() {
    return <Menu items={getHeaderDropDownMenu()} />;
  }
}
