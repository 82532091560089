import React from 'react';
import { NotificationTCare, Page } from '../../api/types';
import { Avatar, Badge, Popover } from 'antd';
import { BellOutlined } from '@ant-design/icons';
import AppStore from 'AppStore';
import NotificationsList from './NotificationsList';
import Notifications from '../../api/Notifications';
import appStore from 'AppStore';
import { setOffGlobalSpinningOnHeader } from '../../api/api';


interface Props {
  style?: React.CSSProperties,
}

interface State {
}

let timestamp;


function areDifferentList(notificationList: NotificationTCare[], listFromDB: NotificationTCare[]) {
  for (let i = 0; i < notificationList.length; i++) {
    if (notificationList[i].id !== listFromDB[i].id) {
      return true;
    } else if (notificationList[i].read !== listFromDB[i].read) {
      return true;
    } else if (notificationList[i].creationTimeAgo !== listFromDB[i].creationTimeAgo) {
      return true;
    }
  }
  return false;
}

export default class Ringbell extends React.Component<Props, State> {


  state: State = {};

  callBack = () => {
    this.updateCounterAndNotificationList()
  };

  notificationList: NotificationTCare[] = [];

  updateCounterAndNotificationList(isForceUpdate?: boolean) {
    const lastTimerStamp = appStore.notificationsAndCount?.timstamp;
    if (lastTimerStamp && timestamp < lastTimerStamp || isForceUpdate === true) {
      this.updateList(false);
      timestamp = lastTimerStamp;
    }
    this.setState({});
  }


  componentWillUnmount() {
    // Verificati memory leak, non si vedono sovrapposizioni e commentando la riga sparisce un errore random
    AppStore.eventEmitter.unSubscribe('notificationChange', 'Ringbell');
  }

  componentDidMount() {
    AppStore.eventEmitter.subscribe('notificationChange', 'Ringbell', this.callBack);
    this.updateList(true);
  }

  markAsAllRead = async () => {
    await Notifications.markAllAsRead(setOffGlobalSpinningOnHeader());
    this.notificationList.forEach(notification => {
      notification.read = true;
    });
    this.setState({});
    await this.updateList(true);
  };

  render = () => {
    return (
      <>
        <Badge count={AppStore.notificationsAndCount?.count} offset={[-3, -3]}>

          <Popover
            placement="bottomRight"
            content={<NotificationsList
              notificationList={this.notificationList}
              markAsRead={this.markAsRead}
              markAllAsRead={this.markAsAllRead} />}
            trigger="click"
            afterVisibleChange={() => this.updateList(true)}

          >
            <Avatar
              style={{ color: '#f56a00', marginLeft: 25, marginRight: 5 }}
              icon={<BellOutlined />}
            />
          </Popover>
        </Badge>


      </>

    );
  };

  updateList = async (flag: boolean) => {
    let res: Page<NotificationTCare> | undefined = await Notifications.find({ size: 8, page: 0 }, setOffGlobalSpinningOnHeader());
    let notificationListFromDB = res === undefined ? [] : res.content;

    if (notificationListFromDB && this.notificationList) {
      if (notificationListFromDB.length !== this.notificationList.length) {
        this.updateListAndState(notificationListFromDB, flag);
      } else {
        if (notificationListFromDB.length > 0 && this.notificationList.length > 0 &&
          notificationListFromDB.length === this.notificationList.length) {
          if (areDifferentList(this.notificationList, notificationListFromDB)) {
            this.updateListAndState(notificationListFromDB, flag);
          }
        }
      }
    }
  };


  private updateListAndState(notificationList: NotificationTCare[], flag: boolean) {
    this.notificationList = notificationList;
    if (flag) {
      this.setState({});
    }
  }

  markAsRead = async (notification: NotificationTCare) => {
    notification.read
        ? await Notifications.markAsUnread(notification, setOffGlobalSpinningOnHeader())
        : await Notifications.markAsRead(notification, setOffGlobalSpinningOnHeader());
    await this.updateList(true);
  };
}
