import React, { Component } from 'react'
import { DeleteOutlined, DragOutlined } from '@ant-design/icons'
import { View } from 'components'
import { Typography } from 'antd'
import { SupplyChainStep } from 'api/types'

interface Props {
  step: SupplyChainStep
  index: number
  removeStep?: (index: number) => void
}

const { Text } = Typography

export default class Step extends Component<Props> {
  openModal = () => {}

  render = () => {
    const { step, index, removeStep } = this.props
    return (
      <View className="sc-step">
        <View className="sc-step">
          <View className="sc-step-number-ball"/>
        </View>
        <View className="sc-step-box">
          <Text style={{ flex: 1 }}>{step.name || step.code}</Text>
          <DeleteOutlined style={{ fontSize: 18 }} onClick={() => (removeStep ? removeStep(index) : {})} />
          <DragOutlined style={{ fontSize: 18, marginLeft: 10 }} />
        </View>
      </View>
    )
  }
}
