import React, { Component } from 'react'
import { __, T } from 'config/i18n'
import { Button } from 'antd'
import { View, PropertyRule } from 'components'
import { Properties, RuleForm } from 'api/types'

interface Props {
  properties: Properties[]
  rule: RuleForm
  remove?: (index: number) => void
  addException?: () => void
  uniqueProperty?: boolean
}

export default class PropertyRules extends Component<Props> {
  render = () => {
    const { rule, properties, remove, addException, uniqueProperty } = this.props
    return (
      <>
        {rule.exceptions.map(exception => (
          <PropertyRule
            key={'exception_' + exception.index}
            indexRule={exception.index}
            rule={rule}
            properties={properties}
            exception={exception}
            remove={remove}
            uniqueProperty={uniqueProperty}
          />
        ))}
        {rule.exceptions.length < properties.length && (
          <View center style={{ marginBottom: 25 }}>
            <Button
              style={{ width: '100%', fontWeight: 700, backgroundColor: '#7AB6F6' }}
              type="default"
              onClick={() => (addException ? addException() : {})}
            >
              {__(T.misc.add_new_property)}
            </Button>
          </View>
        )}
      </>
    )
  }
}
