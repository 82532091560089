import React, { useEffect, useState } from 'react';
import { Text, View } from 'components';
import { Alert, Avatar, Breadcrumb, Button, Divider, Dropdown, Layout, Menu, Modal, Spin } from 'antd';
import { getMenuItems, MenuItem } from 'menu';
import { navigate } from 'router';
import { useLocation } from 'react-router';
import {
  DownOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  SettingOutlined,
  UserOutlined
} from '@ant-design/icons';
import AppStore from 'AppStore';
import { __, T } from 'config/i18n';
import { getRoutesMap, routes } from 'routes';
import { isAdmin, isSupplier, isUserConfirmed } from 'helpers/permission';
import Ringbell from '../notifications/Ringbell';
import NotificationPopup from '../notifications/NotificationPopup';
import MenuLanguage from '../menu/MenuLanguage';
import CompanyMenu from '../menu/CompanyMenu';
import Users from '../../api/Users';
import { setOffGlobalSpinningOnHeader } from '../../api/api';
import { AxiosRequestConfig } from 'axios';



const { Header } = Layout;

const renderMenuItem = (item: MenuItem) => {
  return {
    key: item.routePath ?? item.label,
    onClick: item.onClick ?? (() => navigate(item.routePath! as routes)),
    icon: item.icon ? <img
      src={item.icon}
      style={{ width: '20px', height: '20px', marginRight: '4px', marginBottom: '4px' }}
      alt="" /> : undefined,
    label: <Text className={'menu-label'}>{item.label}</Text>
  };
};

interface Props {
  children;
  hideTopBar?: boolean;
  hideSideBar?: boolean;
  hideCrumbs?: boolean;
  fullScreen?: boolean;
  style?: any;
}

const SidebarLayout: React.FC<Props> = ({ children, hideTopBar, hideSideBar, hideCrumbs, fullScreen, style }) => {
  const { pathname } = useLocation();
  const selectedKeys = [pathname];
  const [, setCollapsed] = useState(AppStore.collapsedBarMenu);
  const [showAlertChangeCompany, setShowAlertChangeCompany] = useState(false)
  const [isSpinningRunning, setIsSpinningRunning] = useState(false)


  function callback() {
    if (AppStore.globalSpin === 0) {
        setIsSpinningRunning(false)
      return;
    }
    if (AppStore.globalSpin > 0) {
        setIsSpinningRunning(true)
    }
  }

  useEffect(() => {

    // Sottoscrizione all'evento "notificationChange" quando il componente viene montato
    AppStore.eventEmitter.subscribe("globalSpinningChange", 'GlobalSpinningChange', callback);


    if (!!AppStore.loggedUser?.companies && AppStore.loggedUser?.companies?.length > 1) {
      // Avvia il polling ogni 5 secondi
      const pollingIntervalId = setInterval(() => {
        pollingCore(setOffGlobalSpinningOnHeader()).then();
      }, 5000);

      // Pulisci l'intervallo del polling quando il componente viene smontato
      return () => {
        AppStore.eventEmitter.unSubscribe("globalSpinningChange", 'GlobalSpinningChange');
        clearInterval(pollingIntervalId);
      }
    }
  }, []); // L'array delle dipendenze è vuoto per eseguire l'effetto solo al montaggio e smontaggio del componente




  const toggleCollapsedButton = async () => {
    const newCollapsed = !AppStore.collapsedBarMenu;
    await AppStore.setCollapsedMenuBar(newCollapsed);
    setCollapsed(newCollapsed);
  };

  const getRenderCompany = () => {
    if (isAdmin()) {
      return (<></>);
    }

    if (!!AppStore.loggedUser?.companies && AppStore.loggedUser?.companies?.length > 1) {
      return (
        <Dropdown overlay={<CompanyMenu></CompanyMenu>}>
                  <span className="ant-dropdown-link" style={{ marginLeft: 8 }} onClick={e => e.preventDefault()}>
                     {AppStore.loggedUser?.company?.data.businessName}
                    <DownOutlined />
                  </span>
        </Dropdown>

      );
    } else {
      return (
        <span>
          {AppStore.loggedUser?.company?.data.businessName}
        </span>
      )
    }
  }

  const renderCollapsedButton = (collapsed?: boolean) =>
    AppStore.collapsedBarMenu ? (
      <MenuUnfoldOutlined onClick={toggleCollapsedButton} />
    ) : (
      <MenuFoldOutlined onClick={toggleCollapsedButton} />
    );


  // Example of url to parser.
  // '/reports/orders/blabla'
  // '/reports/orders/:orderId:/blabla'
  // let elementToReplace: string[] = [];
  let params: any = {};

  const crumbs = Object.entries(getRoutesMap())
    // Get all routes that contain the current one.
    .filter(([key, value]) => {
      const pathnameSplit = pathname.split('/');
      const keySplit = key.split('/');


      if (pathnameSplit.length < keySplit.length) {
        return false;
      }
      for (let a = 0; a < keySplit.length && a < pathnameSplit.length; a++) {
        if (!keySplit[a].includes(':')) {
          if (keySplit[a] !== pathnameSplit[a]) {
            return false;
          }
        } else {
          // elementToReplace.push( valueInPath )
          params[keySplit[a].substr(1)] = pathnameSplit[a];
        }
      }
      return true;
    })
    .map(([key, value]) => ({
      path: key,
      breadcrumbName: value.breadcrumbName ? value.breadcrumbName(params) : undefined
    })) as { path: routes, breadcrumbName: string }[];


  if (crumbs[0].path !== '/') {
    crumbs.unshift({
      path: '/',
      breadcrumbName: __(T.buttons.dashboard)
    });
  }

  const regex = /:[^/]+/g;
  crumbs.forEach(item => {
    const matches = item.path.match(regex);
    for (let index in matches) {
      let key = matches[index];
      let newPath: string = item.path;
      newPath = newPath.replace(key, params[key.substr(1)]);
      item.path = newPath as routes;
    }
  });
  if (fullScreen)
    return (
      <View style={{ minHeight: '100vh', display: 'flex' }}>
        {children} {}
      </View>
    );

  async function pollingCore(header: AxiosRequestConfig) {
    let company = await Users.getCurrentCompany(header);
    if (company.id !== AppStore.loggedUser?.company?.id) {
      setShowAlertChangeCompany(true);
    }
  }

  function startPageRefresh() {
    setTimeout(() => {
      navigate('/')
      window.location.reload();
    }, 10000);
  }

  function userNotConfirmedMessage() {
    if(!isSupplier()){
      return __(T.messages.user_disabled)
    }
    if(!AppStore.loggedUser?.enabled) {
      return __(T.messages.user_disabled)
    }
    if(!AppStore.loggedUser?.company?.enabled) {
      return __(T.messages.company_disabled)
    }
    return  __(T.messages.profile_in_revision)
  }

  return (
        <Layout style={{ minHeight: '100vh', minWidth: '900px', ...style }}>
          {!hideSideBar && (
              <Layout.Sider width={'auto'} collapsedWidth={'auto'} collapsed={AppStore.collapsedBarMenu}>
                {!AppStore.collapsedBarMenu && (
                    <img
                        src={AppStore.getImage(AppStore.appSettings.bigLogo)}
                        alt={__(T.misc.logo)}
                        //style="width: auto; height: auto; margin-bottom: 25px; margin-top: 21px; margin-left: 16px; margin-right: 16px;"
                        style={{
                          width: 'auto',
                          height: 'auto',
                          marginBottom: 25,
                          marginTop: 21,
                          marginLeft: 16,
                          marginRight: 16
                        }}
                        onClick={() => navigate('/')}
                    />
                )}
                {AppStore.collapsedBarMenu && (
                    <img
                        src={AppStore.getImage(AppStore.appSettings.smallLogo)}
                        alt={__(T.misc.logo)}
                        style={{ width: '31px', height: 'auto', marginBottom: 25, marginTop: 21, marginLeft: 24 }}
                        onClick={() => navigate('/')}
                    />
                )}
                <Menu theme="dark" mode="inline" selectedKeys={selectedKeys} items={getMenuItems().map(renderMenuItem)}>

                </Menu>
              </Layout.Sider>
          )}

      <Layout className="site-layout">
        {!hideTopBar && (
          <Header
            style={{
              backgroundColor: '#FFFFFF',
              display: 'inline-flex',
              alignItems: 'center',
              paddingLeft: 20,
              paddingRight: 20
            }}
          >
            <View>{renderCollapsedButton(AppStore.collapsedBarMenu)}</View>
            {isUserConfirmed() && (
              <Text h2 style={{ paddingTop: 12, marginLeft: 20 }}>
                {AppStore.appSettings.headerTitle}
              </Text>
            )}
            {!isUserConfirmed() && (
              <Text h2 style={{ paddingTop: 12, marginLeft: 20 }}>
                {userNotConfirmedMessage()}
              </Text>
            )}
            <View style={{ display: 'flex', flex: 1, justifyContent: 'flex-end', alignItems: 'center' }}>
              {isAdmin() && AppStore.appSettings.enableOrderManagement && (
                <SettingOutlined onClick={() => navigate('/admin/settings')} style={{ fontSize: 30 }} />
              )}
              {/* <QuestionCircleOutlined onClick={() => window.open('https://www.temera.it/it/solutions', '_blank')} /> */}
              <Ringbell></Ringbell>
              <NotificationPopup></NotificationPopup>
              <Divider type="vertical" style={{ color: 'black' }} />
              <Avatar
                style={{ color: '#f56a00', marginLeft: 5, marginRight: 5 }}
                icon={<UserOutlined />}
              />

              {getRenderCompany()}

                    <Dropdown overlay={<MenuLanguage></MenuLanguage>}>
                  <span className="ant-dropdown-link" style={{ marginLeft: 8 }} onClick={e => e.preventDefault()}>
                      {AppStore.loggedUser?.userName}
                    <DownOutlined /></span>
                    </Dropdown>
                  </View>
                </Header>
            )}
            {crumbs.length > 1 && !hideCrumbs && (
                <Breadcrumb style={{ marginLeft: 10, marginTop: 10 }}>
                  {crumbs.map(crumb => (
                      <Breadcrumb.Item key={crumb.path}>
                        <Button type="link" onClick={() => navigate(crumb.path)}>
                          {crumb.breadcrumbName}
                        </Button>
                      </Breadcrumb.Item>
                  ))}
                </Breadcrumb>
            )}

        <Spin spinning={isSpinningRunning} size="large"
              style={{ position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
            <Layout.Content>{children}</Layout.Content>
            </Spin>
            {showAlertChangeCompany &&
                <Modal
                    visible={true}
                    onCancel={() => {
                    }}
                    footer={null}
                    closable={false}
                    maskClosable={false}
                    bodyStyle={{ background: 'rgba(0, 0, 0, 0.5)', padding: 0 }}
                >
                  {/* Contenuto del Modal con l'Alert */}
                  <Alert
                      message="Error"
                      description={__(T.misc.error_change_current_user)}
                      type="error"
                      showIcon
                  />
                  {startPageRefresh()}
                </Modal>
            }
          </Layout>
        </Layout>
  );
};


export default SidebarLayout;

