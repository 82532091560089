import {getUserRole, isAdmin, isUserConfirmed} from 'helpers/permission';
import AppStore from './AppStore';
import {ExtraMenuItem} from "./types";
import {__} from "./config/i18n";
import {BaseMenuConfiguration} from "./api/types";
import {isAnExternalLink} from "./helpers/menuHelper";

export interface MenuItem {
    label: string;
    icon?: string;
    routePath?: string;
    onClick?: () => void;
    customRender?: () => void;
}

export const getMenuItems = () => {
    const menu: MenuItem[] = [];
    AppStore.menuConfiguration
    .filter(conf => conf.visibleInMenu)
    .filter(conf => {
        const userRole = getUserRole()
        return userRole ? conf.roles.includes(userRole) : false
    })
    .filter(conf => {
        if (isUserConfirmed()) {
            return true;
        }
        switch (conf.type) {
            case "HOME":
            case "ANALYTICS":
            case "DOWNLOADS_MANAGEMENT":
            case "COMPANY_REPORT":
                return true;
            default:
                return false;
        }
    })
        .forEach(conf => menu.push(buildMenuItem(conf)))

    // E' ancora usato?
    if (AppStore.appSettings.extraMenu) {
        const extraMenuItems: ExtraMenuItem[] = JSON.parse(AppStore.appSettings.extraMenu)

        extraMenuItems.forEach(item => {
            if (item.visibleForAdmin && isAdmin() ||
                item.visibleForSupplier && !isAdmin()
            ) {
                menu.push({
                    label: item.label,
                    onClick: () => {
                        if (item.type === 'page') {
                            window.open(item.url, '_blank', 'noreferrer')
                        } else {
                            console.warn('iframe not implemented: ' + item.url)
                        }
                    },
                    icon: item.icon ?? require('assets/images/external-link.png').default
                })
            }
        })
    }

    return menu;
};

const buildMenuItem = (conf: BaseMenuConfiguration): MenuItem => {
    if (isAnExternalLink(conf)) {
        return (
            {
                label: conf.label,
                onClick: () => {
                    window.open(conf.url, '_blank', 'noreferrer')
                },
                icon: conf.iconMenu ? require(`/${conf.iconMenu}`).default : undefined
            }
        )
    } else {
        return {
            label: __(conf.label),
            icon: conf.iconMenu ? require(`/${conf.iconMenu}`).default : undefined,
            routePath: conf.url
        }
    }
}
