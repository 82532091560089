import React from 'react';
import {
    BulkApproveOrderDetailDto,
    Company,
    DetailedOrder,
    FulfilResult,
    LibraryItem,
    Mail,
    ModalDownloadConfig,
    ModalDownloadData,
    Order,
    OrderHandmadeConfig,
    OrderStatus,
    Page,
    ReportOrder,
    SubOrder,
    User
} from '../../api/types';
import Orders from '../../api/Orders';
import orders from '../../api/Orders';

import {Badge, Button, Dropdown, Menu, Modal, Space} from 'antd';
import {Layout, ModalConfirm} from 'components';
import {__, T} from 'config/i18n';
import AppStore from '../../AppStore';
import {admin, getUserRole, isAdmin, isSupplier, isViewer} from '../../helpers/permission';
import {addQueryParams, getQueryParams, navigate} from '../../router';
import {Props} from '../../components/layouts/CompanyFormLayout';
import UploadTraceDataFileModal from '../../components/orders/UploadTraceDataFileModal';
import {ColumnsType} from 'antd/es/table';
import SplitOrderModal from '../../components/orders/SplitOrderModal';
import {showNotification} from '../../helpers/Notifications';
import OrderLogsModal from '../../components/orderlogs/OrderLogsModal';
import {buildColumnsByConfiguration} from 'components/orders/ColumnBuilderUtil';
import {buildFiltersByConfiguration} from 'components/orders/FilterBuilderUtil';
import {DownloadOutlined, DownOutlined, MailOutlined} from '@ant-design/icons';
import ImportTraceDialog from '../../components/orders/ImportTraceDialog';
import ImportLibraryDialog from '../../components/orders/ImportLibraryDialog';
import FilteredTable, {SHOW_SELECTED_KEY} from '../../components/FilteredTable';
import NewOrderModal from '../../components/orders/NewOrderModal';
import ModalModifyAttributes from '../../components/orders/ModalModifyAttributes';
import Templates from '../../api/Templates';
import templates from '../../api/Templates';
import PdfTools from '../../tools/PdfTools';
import exportData from '../../api/ExportData';
import {Suppliers, Users} from '../../api';
import {Schema} from '../../components/AdvancedForm';
import {resolveReportOrdersBasePath} from '../../routes';
import ModalDownloads from '../../components/modals/ModalDownloads';
import OrderSendEmailModal, {OrderSendEmailModalValues} from './OrderSendEmailModal';
import companies from '../../api/Companies';
import typesSettingsTools from '../../tools/TypesSettingsTools';
import RejectModal from '../../components/modals/RejectModal';
import mails from '../../api/Mails';
import {setOffGlobalSpinningOnHeader} from '../../api/api';
import BulkApproveDialog from "../../components/orders/BulkApproveDialog";
import orderTools from '../../tools/OrderTools';

const ORDER_REPORT_SESSION_STORAGE = 'orderReportSessionStorage';
const ALL_ORDERS_KEY = 'ALL_ORDERS';

const INITIAL_STATE: State = {
    isUploadTraceReportModalVisible: false,
    isSplitModalVisible: false,
    splittedOrderId: undefined,
    isVisibleConfirmModal: false,
    tmpIdOrder: null,
    isImportTraceModalVisible: false,
    isImportLibraryModalVisible: false,
    isVisibleRejectModal: false,
    isVisibleOrderSendEmailModal: false,
    isVisibleModifyAttributesModal: false,
    isVisibileBulkCompleteModal: false,
    componentMount: false,
    selectedOrders: [],
    lastParams: {},
    isShowAddNewOrder: false,
    askToUpdateData: 1,
    tenantCompanyMap: {},
    isLoading: false,
    bulkApproveOrderDetail: {
        orders: [],
        totalToApprove: 0,
        approved: 0
    },
    isVisibleBulkApproveDialog: false // Nuovo stato per il dialog di feedback sull'approvazione massiva
};

interface ModalConfirmConfig {
    title: () => string,
    subtitle: () => string,
    hasMessage: boolean,
    confirm?: () => string,
    callback: (confirm: boolean, message?: string) => void
}

interface State {
    isUploadTraceReportModalVisible: boolean,
    isSplitModalVisible: boolean;
    splittedOrderId: string | undefined;
    logOrderIdToShow?: string;
    isVisibleConfirmModal: boolean;
    // Is used in order to store temporary an id order when the user wish for example to approve a single order.
    tmpIdOrder: string | null;
    modalConfirmConfig?: ModalConfirmConfig,
    isImportTraceModalVisible: boolean,
    isImportLibraryModalVisible: boolean,
    isVisibleRejectModal: boolean,
    isVisibleOrderSendEmailModal: boolean,
    isVisibleModifyAttributesModal: boolean,
    isVisibileBulkCompleteModal: boolean,
    modalDownloadsData?: ModalDownloadData[],
    activeTabKey?: string
    componentMount: boolean
    selectedOrders: { id: string, status: OrderStatus, traceTypeId: string, traceTypeCode: string }[]
    lastParams: any
    isShowAddNewOrder: boolean
    askToUpdateData: number
    fulfilResult?: FulfilResult
    tmpRecord?: Order | SubOrder;
    tmpCompanyCode?: string;
    tmpUser?: User;
    tmpBase64?: string,
    tenantCompanyMap: Record<string, Company>,
    isLoading: boolean,
    fileId?: any,
    bulkApproveOrderDetail: BulkApproveOrderDetailDto // Nuovo stato per i dettagli dell'approvazione
    isVisibleBulkApproveDialog: boolean
}

interface OrdersReportProps extends Props {
    match: { params: { type: string } };
}

export default class OrdersReport extends React.Component<OrdersReportProps, State> {

    private orderStatusTabKeys: string[] = [];
    private tmpRecord: Order | undefined;
    private totalElements: number = 0;

    state: State = {...INITIAL_STATE};

    componentDidMount = async () => {
        //******************************** Configuration filter
        // Configuration status
        this.initTabOnLoadingPage();

        //TODO: Prima c'era una gestione degli ids tra i query params...

        this.refreshData();
    };

    getConfigurationTemplateFile(): string {
        return !!this.props.match.params.type && !!AppStore.appSettings[`ordersXlsImportTraceSchema.${this.props.match.params.type}`]
          ? `ordersXlsImportTraceSchema.${this.props.match.params.type}`
          : 'ordersXlsImportTraceSchema';
    }


    componentDidUpdate(prevProps, prevState) {
        if (this.props['location']['search'] !== prevProps.location.search) {
            //******************************** Configuration filter
            // Configuration status
            this.orderStatusTabKeys = [];
            this.initTabOnLoadingPage();
        }

        if (prevProps.match.params.type !== this.props.match.params.type) {
            this.refreshData();
        }
    }

    initTabOnLoadingPage() {
        this.orderStatusTabKeys.push(ALL_ORDERS_KEY);
        let orderAvailableStatusesSetting = AppStore.appSettings.orderAvailableStatuses
        const type = this.props.match.params.type

        if (type && AppStore.appSettings['orderAvailableStatuses.' + type] !== undefined) {
            orderAvailableStatusesSetting = AppStore.appSettings['orderAvailableStatuses.' + type]
        }

        const orderAvailableStatuses: string[] = orderAvailableStatusesSetting.split(',');

        orderAvailableStatuses.forEach((item: string) => {
            this.orderStatusTabKeys.push(item);
        });

    }

    refreshData() {
        let activeTab = getQueryParams().status;
        if (!!activeTab) {
            addQueryParams({'status': activeTab}, false);
            this.setState({
                askToUpdateData: this.state.askToUpdateData + 1,
                activeTabKey: activeTab,
                componentMount: true
            });
        } else {
            this.setState({
                askToUpdateData: this.state.askToUpdateData + 1,
                activeTabKey: undefined,
                componentMount: true
            });
        }
    }

    private modalConfirmMultipleApproveOrders: ModalConfirmConfig = {
        title: () => __(T.fields.approve_orders_modal_title),
        subtitle: () => __(T.fields.approve_orders_modal_body,
            {count: this.state.selectedOrders.length === 0 ? this.totalElements : this.state.selectedOrders.length}),
        confirm: () => __(T.buttons.proceed),
        hasMessage: false,
        callback: (confirm) => {
            this.handleMultipleApproveSubmit(confirm).then();
        }
    };

    private modalConfirmSingleApproveOrder: ModalConfirmConfig = {
        title: () => __(T.fields.approve_order_modal_title),
        subtitle: () => __(T.fields.approve_order_modal_body),
        confirm: () => __(T.buttons.proceed),
        hasMessage: false,
        callback: (confirm) => {
            this.handleSingleApproveSubmit(confirm).then();
        }
    };

    private modalConfirmSingleApproveOrderWithChildren: ModalConfirmConfig = {
        title: () => __(T.fields.approve_order_modal_title),
        subtitle: () => AppStore.appSettings.enableLockIfChildrenAreNotApproved ? __(T.fields.approve_order_modal_body) : __(T.fields.approve_order_with_children_modal_body),
        confirm: () => __(T.buttons.proceed),
        hasMessage: false,
        callback: (confirm) => {
            this.handleSingleApproveSubmit(confirm).then();
        }
    };


    private modalConfirmSingleDisapproveOrder: ModalConfirmConfig = {
        title: () => __(T.fields.disapprove_order_modal_title),
        subtitle: () => __(T.fields.disapprove_order_modal_body),
        confirm: () => __(T.buttons.proceed),
        hasMessage: false,
        callback: (confirm) => {
            this.handleSingleDisapproveSubmit(confirm).then();
        }
    };

    private modalConfirmMultipleDisapproveOrders: ModalConfirmConfig = {
        title: () => __(T.fields.disapprove_orders_modal_title),
        subtitle: () => __(T.fields.disapprove_orders_modal_body,
            {count: this.state.selectedOrders.length === 0 ? this.totalElements : this.state.selectedOrders.length}),
        confirm: () => __(T.buttons.proceed),
        hasMessage: false,
        callback: (confirm) => {
            this.handleMultipleDisapproveSubmit(confirm).then();
        }
    };

    private modalConfirmMultipleRejectOrders: ModalConfirmConfig = {
        title: () => __(T.fields.reject_orders_modal_title,
            {count: this.state.selectedOrders.length === 0 ? this.totalElements : this.state.selectedOrders.length}),
        subtitle: () => __(T.fields.reject_orders_modal_body),
        confirm: () => __(T.buttons.proceed),
        hasMessage: true,
        callback: (confirm, message) => {
            this.handleMultipleRejectSubmit(confirm, message!).then();
        }
    };

    private modalConfirmMultipleReopenOrders: ModalConfirmConfig = {
        title: () => __(T.fields.reopen_orders_modal_title,
            {count: this.state.selectedOrders.length === 0 ? this.totalElements : this.state.selectedOrders.length}),
        subtitle: () => __(T.fields.reopen_orders_modal_body,
            {count: this.state.selectedOrders.length === 0 ? this.totalElements : this.state.selectedOrders.length}),
        confirm: () => __(T.buttons.proceed),
        hasMessage: false,
        callback: (confirm, message) => {
            this.handleMultipleReopenSubmit(confirm).then();
        }
    };

    private modalConfirmMultipleCompleteOrders: ModalConfirmConfig = {
        title: () => __(T.fields.complete_orders_modal_title),
        subtitle: () => __(T.fields.complete_orders_modal_body,
            {count: this.state.selectedOrders.length === 0 ? this.totalElements : this.state.selectedOrders.length}),
        confirm: () => __(T.buttons.proceed),
        hasMessage: false,
        callback: (confirm, message) => {
            this.handleMultipleCompleteSubmit(confirm).then();
        }
    };


    private modalConfirmSingleReopenOrder: ModalConfirmConfig = {
        title: () => __(T.fields.reopen_order_modal_title),
        subtitle: () => __(T.fields.reopen_order_modal_body),
        confirm: () => __(T.buttons.proceed),
        hasMessage: false,
        callback: (confirm, message) => {
            this.handleSingleReopenSubmit(confirm).then();
        }
    };

    private modalConfirmDeleteOrder: ModalConfirmConfig = {
        title: () => __(T.fields.delete_order_modal_title),
        subtitle: () => __(T.fields.delete_order_modal_body),
        confirm: () => __(T.buttons.proceed),
        hasMessage: false,
        callback: (confirm, message) => {
            this.handleDeleteOrderSubmit(confirm).then();
        }
    };


    // onRouteChanged() {
    //   if (this.orderStatusTabKeys.includes(getQueryParams().status)) {
    //     this.setState({
    //       activeTabKey: getQueryParams().status
    //     }, () => this.search(this.state.filters));
    //   } else {
    //     addQueryParams({ 'status': DEFAULT_SELECTED_KEY }, false);
    //     this.setState({
    //       activeTabKey: DEFAULT_SELECTED_KEY
    //     }, () => this.search(this.state.filters));
    //   }
    // }

    timeoutStarted: boolean = false;

    showLogs(orderId: string) {
        this.setState({
            logOrderIdToShow: orderId
        });
    }


    closeLogs() {
        this.setState({logOrderIdToShow: undefined});
    }

    showAddNewOrder() {
        this.setState({
            isShowAddNewOrder: true
        });
    }

    onCancelNewOrder = () => {
        this.setState({isShowAddNewOrder: false});
    };


    onSuccessNewOrder = async (configurationHandMade: OrderHandmadeConfig) => {
        this.setState({isShowAddNewOrder: false});
        navigate(resolveReportOrdersBasePath(this.props.match.params.type) + '/handmade/new/:orderHandmadeConfigCode', {
            orderHandmadeConfigCode: configurationHandMade.code
        });
    };

    getFilters = (): Schema[] => {
        const filters = typesSettingsTools.getSettingByMatchParamsType(this.props.match.params.type, 'orderListFilters');
        return buildFiltersByConfiguration(filters);
    };


    getColumns = (): ColumnsType<ReportOrder> => {
        const type = this.props.match.params.type;
        const columns: any = buildColumnsByConfiguration(type !== undefined ? AppStore.appSettings['orderListColumns.' + type] : AppStore.appSettings.orderListColumns);

        if (this.state.activeTabKey === ALL_ORDERS_KEY || this.state.activeTabKey === SHOW_SELECTED_KEY) {
            columns.push({
                key: 'col_status',
                title: __(T.fields.status),
                width: 150,
                ellipsis: true,
                dataIndex: 'status',
                fixed: 'right',
                render: (text, record: Order) => {
                    return (
                        <Badge
                            color={__(T.colors[`${record.status.toLowerCase()}`])}
                            text={__(T.misc[record.status.toLowerCase()])}
                        />
                    );
                },
                sorter: true,
                sortDirections: ['ascend', 'descend', 'ascend']
            });
        }

        columns.push({
            title: __(T.fields.actions),
            key: 'col_action',
            align: 'left',
            fixed: 'right',
            width: 1,
            render: (text, record: Order) => {
                let actionButtons: any[] = [];

                actionButtons.push({
                    key: 'actionview',
                    onClick: () => navigate(resolveReportOrdersBasePath(this.props.match.params.type) + '/detail/:orderId', {orderId: record.id}),
                    label: <div style={{display: 'flex', justifyContent: 'space-between'}}>
                        <img style={{height: '25px', justifyContent: 'center', display: 'flex'}}
                             src={require('assets/images/View.svg').default}
                             alt={__(T.buttons.view)}/>
                        <a style={{minWidth: '150px', justifyContent: 'center', display: 'flex'}}>
                            {__(T.buttons.view)}
                        </a>
                    </div>
                });

                if (!isViewer()) {
                    if (record.status === 'APPROVED' && isAdmin() && AppStore.appSettings.enableEnquiryDisapprove) {
                        actionButtons.push({
                            key: 'actiondisapprove',
                            onClick: () => {
                                this.disapproveOrder(record.id!);
                            },
                            label: <div style={{display: 'flex', justifyContent: 'space-between'}}>
                                <img style={{
                                    height: '25px',
                                    justifyContent: 'left',
                                    display: 'flex'
                                }}
                                     src={require('assets/images/Reject_gray.svg').default}
                                     alt={__(T.buttons.disapprove)}/>
                                <a style={{minWidth: '150px', justifyContent: 'center', display: 'flex'}}>
                                    {__(T.buttons.disapprove)}
                                </a>
                            </div>
                        });
                    }

                    if (record.status === 'COMPLETED' && isAdmin() && AppStore.appSettings.enableApproveOrder) {
                        actionButtons.push({
                            key: 'actionapprove',
                            onClick: () => {
                                this.approveOrder(record);
                            },
                            label: <div style={{display: 'flex', justifyContent: 'space-between'}}>
                                <img style={{
                                    height: '25px',
                                    justifyContent: 'left',
                                    display: 'flex'
                                }}
                                     src={require('assets/images/Approve_grey.svg').default}
                                     alt={__(T.buttons.approve)}/>
                                <a style={{minWidth: '150px', justifyContent: 'center', display: 'flex'}}>
                                    {__(T.buttons.approve)}
                                </a>
                            </div>
                        });
                    }

                    if (!!record.childDataList && record.childDataList.length > 0 && record.childDataList.filter(child => child.status !== 'COMPLETED').length === 0
                        && typesSettingsTools.getSettingByMatchParamsType(this.props.match.params.type, 'enableChildReject')) {
                        actionButtons.push({
                            key: 'actionRejectChildOrder',
                            onClick: async () => {
                                const orderDetail: DetailedOrder[] = await Orders.getOrderDetails(record.id);
                                this.rejectChildOrder(orderDetail[0].orders[0]);
                            },
                            label: <div style={{display: 'flex', justifyContent: 'space-between'}}>
                                <img style={{
                                    height: '25px',
                                    justifyContent: 'center',
                                    display: 'flex'
                                }}
                                     src={require('assets/images/Reject_gray.svg').default}
                                     alt={__(T.buttons.rejectSubOrder)}/>
                                <a style={{minWidth: '150px', justifyContent: 'center', display: 'flex'}}>
                                    {__(T.buttons.rejectSubOrder)}
                                </a>
                            </div>
                        });
                    }


                    if (record.status === 'COMPLETED' && isAdmin() && AppStore.appSettings.enableRejectOrder) {
                        actionButtons.push({
                            key: 'actioncomplete',
                            onClick: () => {
                                this.rejectOrder(record);
                            },
                            label: <div style={{display: 'flex', justifyContent: 'space-between'}}>
                                <img style={{
                                    height: '25px',
                                    justifyContent: 'center',
                                    display: 'flex'
                                }}
                                     src={require('assets/images/Reject_gray.svg').default}
                                     alt={__(T.buttons.reject)}/>
                                <a style={{minWidth: '150px', justifyContent: 'center', display: 'flex'}}>
                                    {__(T.buttons.reject)}
                                </a>
                            </div>
                        });
                    }

                    if (record.status === 'COMPLETED' && !isAdmin() &&
                        typesSettingsTools.isSettingEnableTraceByMatchParamsType(this.props.match.params.type, 'enableReopenOrder')) {
                        actionButtons.push({
                            key: 'actionreopen',
                            onClick: () => {
                                this.reopenOrder(record.id!);
                            },
                            label: <div style={{display: 'flex', justifyContent: 'space-between'}}>
                                <img style={{
                                    height: '25px',
                                    justifyContent: 'center',
                                    display: 'flex'
                                }}
                                     src={require('assets/images/Reject_gray.svg').default}
                                     alt={__(T.buttons.reopen)}/>
                                <a style={{minWidth: '150px', justifyContent: 'center', display: 'flex'}}>
                                    {__(T.buttons.reopen)}
                                </a>
                            </div>
                        });
                    }

                    if ((record.status !== 'COMPLETED' && record.status !== 'APPROVED' && !isAdmin()) && AppStore.appSettings.enableSplit) {
                        actionButtons.push({
                            key: 'actionsplit',
                            onClick: () => {
                                this.openSplitModal(record.splittedOrderId ? record.splittedOrderId : record.id);
                            },
                            label: <div style={{display: 'flex', justifyContent: 'space-between'}}>
                                <img style={{
                                    height: '25px',
                                    justifyContent: 'center',
                                    display: 'flex',
                                    paddingLeft: '4px'
                                }}
                                     src={require('assets/images/Split_grey.svg').default}
                                     alt={__(T.buttons.split)}/>
                                <a style={{minWidth: '150px', justifyContent: 'center', display: 'flex'}}>
                                    {__(T.buttons.split)}
                                </a>
                            </div>
                        });
                    }

                    actionButtons.push({
                        key: 'actionlogs',
                        onClick: () => {
                            this.showLogs(record.id!);
                        },
                        label: <div style={{display: 'flex', justifyContent: 'space-between'}}>
                            <img style={{height: '25px', justifyContent: 'center', display: 'flex'}}
                                 src={require('assets/images/history_grey.svg').default}
                                 alt={__(T.buttons.show_logs)}/>
                            <a style={{minWidth: '150px', justifyContent: 'center', display: 'flex'}}>
                                {__(T.buttons.show_logs)}
                            </a>
                        </div>
                    });

                    if ((record.status === 'TO_BE_ASSIGNED' ||
                            record.status === 'ASSIGNED' ||
                            record.status === 'DRAFT') &&
                        AppStore.appSettings.enableHandmade &&
                        isAdmin() &&
                        record.handmade) {
                        actionButtons.push({
                            key: 'actionedit',
                            onClick: () => {
                                navigate(resolveReportOrdersBasePath(this.props.match.params.type) + '/handmade/edit/:orderId', {
                                    orderId: record.id
                                });
                            },
                            label: <div style={{display: 'flex', justifyContent: 'space-between'}}>
                                <img style={{height: '25px', justifyContent: 'center', display: 'flex'}}
                                     src={require('assets/images/Edit_gray.svg').default}
                                     alt={__(T.buttons.edit)}/>
                                <a style={{minWidth: '150px', justifyContent: 'center', display: 'flex'}}>
                                    {__(T.buttons.modify)}
                                </a>
                            </div>
                        });
                    }

                    if (record.status !== 'APPROVED' && !!AppStore.appSettings.enquireAttributesEditJsonSchema &&
                        !(record.handmade && isAdmin())) {
                        actionButtons.push({
                            key: 'actionmodify',
                            onClick: () => {
                                this.modifyAttributes(record.id!);
                            },
                            label: <div style={{display: 'flex', justifyContent: 'space-between'}}>
                                <img style={{
                                    height: '25px',
                                    justifyContent: 'left',
                                    display: 'flex'
                                }}
                                     src={require('assets/images/Edit_gray.svg').default}
                                     alt={__(T.buttons.modify_attributes)}/>
                                <a style={{minWidth: '150px', justifyContent: 'center', display: 'flex'}}>
                                    {__(T.buttons.modify_attributes)}
                                </a>
                            </div>
                        });
                    }

                    if ((record.status === 'TO_BE_ASSIGNED' ||
                            record.status === 'ASSIGNED' ||
                            record.status === 'DRAFT') &&
                        ((AppStore.appSettings.enableHandmade && isAdmin() && record.handmade) ||
                            (AppStore.appSettings.enableAdminDeleteEnquiry && isAdmin()))) {
                        actionButtons.push({
                            key: 'actiondelete',
                            onClick: () => {
                                this.deleteOrder(record.id);
                            },
                            label: <div style={{display: 'flex', justifyContent: 'space-between'}}>
                                <img style={{height: '25px', justifyContent: 'center', display: 'flex'}}
                                     src={require('assets/images/Delete_gray.svg').default}
                                     alt={__(T.buttons.delete)}/>
                                <a style={{minWidth: '150px', justifyContent: 'center', display: 'flex'}}>
                                    {__(T.buttons.delete)}
                                </a>
                            </div>
                        });
                    }

                    if (AppStore.appSettings.enableOrderPdf &&
                        (record.status === 'COMPLETED' || record.status === 'APPROVED')) {
                        if (!AppStore.appSettings.enquiryDownloads) {
                            actionButtons.push({
                                key: 'actionDownloadPdf',
                                onClick: () => {
                                    this.downloadPdf(record);
                                },
                                label: <div style={{display: 'flex', justifyContent: 'space-between'}}>
                                    <DownloadOutlined style={{fontSize: '25px', display: 'flex', color: '#828282'}}
                                                      alt={__(T.buttons.download_pdf)}/>
                                    <a style={{minWidth: '150px', justifyContent: 'center', display: 'flex'}}>
                                        {__(T.buttons.download_pdf)}
                                    </a>
                                </div>
                            });
                        }
                        actionButtons.push({
                            key: 'actionSendPdfEmail',
                            onClick: async () => {
                                this.tmpRecord = record;

                                const user = record.operativeUsername ? await Users.findByUsername(record.operativeUsername) : undefined;
                                const company = record.creationCompany ? await Suppliers.findTenantCompanyCodeByCompanyId(record.creationCompany) : undefined;

                                this.setState({
                                    tmpRecord: record,
                                    tmpUser: user,
                                    tmpCompanyCode: company,
                                    isVisibleOrderSendEmailModal: true
                                });
                            },
                            label: <div style={{display: 'flex', justifyContent: 'space-between'}}>
                                <MailOutlined style={{fontSize: '25px', display: 'flex', color: '#828282'}}
                                              alt={__(T.buttons.download_pdf)}/>
                                <a style={{minWidth: '150px', justifyContent: 'center', display: 'flex'}}>
                                    {__(T.buttons.send)}
                                </a>
                            </div>
                        });
                    }
                }
                if (!!AppStore.appSettings.enquiryDownloads) {
                    actionButtons.push({
                        key: 'actionOpenDownloads',
                        onClick: async () => {
                            this.tmpRecord = record;
                            const modalDownloadsData: ModalDownloadData[] = [];
                            //const detailedOrder = await Orders.get(record.id);
                            modalDownloadsData.push({
                                id: record.id,
                                label: record.step + ' ' + record.code,
                                payload: record
                            });
                            const orders = await Orders.search({
                                parentOrderId: record.id,
                                page: 0,
                                size: 100
                            });

                            for (let subOrder of orders.content) {
                                modalDownloadsData.push({
                                    id: subOrder.id,
                                    label: subOrder.step + ' ' + subOrder.code,
                                    payload: subOrder
                                });
                            }

                            this.setState({modalDownloadsData: modalDownloadsData});
                        },
                        label: <div style={{display: 'flex', justifyContent: 'space-between'}}>
                            <DownloadOutlined style={{fontSize: '25px', display: 'flex', color: '#828282'}}
                                              alt={__(T.buttons.download_pdf)}/>
                            <a style={{minWidth: '150px', justifyContent: 'center', display: 'flex'}}>
                                {__(T.buttons.open_downloads)}
                            </a>
                        </div>
                    });
                }

                return (<>
                    <Dropdown.Button overlay={
                        <Menu items={actionButtons}/>
                    }>
                    </Dropdown.Button>
                </>);
            }
        });

        //TODO: fa apparire la freccetta
        // columns
        //   .filter(c => c.dataIndex && c.dataIndex === this.state.sorterField)
        //   .forEach(c => c.sortOrder = this.state.sorterOrder);

        return columns;
    };

    private async getChildren(orderDetail: any): Promise<any[]> {
        const childIds: string[] = orderDetail.orders.map((o: any) => o.id);
        if (childIds.length > 0) {
            return await Orders.getOrderDetails(childIds);
        }
        return [];
    }


    private async downloadPdf(order: Order) {
        const orderDetail = await Orders.get(order.id);
        const children = await this.getChildren(orderDetail);
        const company = await companies.get(orderDetail.creationCompany!);
        const blob = await Templates.getPdfOnBase64Format(order.step, {
            order: orderDetail,
            children: children,
            company
        });
        const tenantCompanyCode = await Suppliers.findTenantCompanyCodeByCompanyId(order.creationCompany!);
        //TODO: scablare nome
        let workorder = order.step === 'Workshop' ? order.code : order.attributes!.workOrder;
        let filename = 'FPDB_' + tenantCompanyCode + '_' + workorder + '_' + order.completionVersion + '.pdf';
        await PdfTools.downloadPdf(blob, filename);
    }

    onDownloadGenerated = async (downloadConfig: ModalDownloadConfig, data: ModalDownloadData) => {
        const orderDetail = await Orders.get(data.id);
        const children = await this.getChildren(orderDetail);
        const company = await companies.get(orderDetail.creationCompany!);
        const blob = await Templates.getPdfOnBase64Format(downloadConfig.templateCode!, {
            order: orderDetail,
            children: children,
            company
        });
        let filename = await this.getDownloadGenerateFileName(downloadConfig, data);
        await PdfTools.downloadPdf(blob, !!downloadConfig.filename ? downloadConfig.filename : filename);
    };
    getDownloadGenerateFileName = async (downloadConfig: ModalDownloadConfig, data: ModalDownloadData) => {
        const tenantCompanyCode = await Suppliers.findTenantCompanyCodeByCompanyId(data.payload.creationCompany);
        //TODO: scablare nome
        let workorder = data.payload.step === 'Workshop' ? data.payload.code : data.payload.attributes.workOrder;
        return 'FPDB_' + tenantCompanyCode + '_' + workorder + '_' + data.payload.completionVersion + '.pdf';
    };

    getDownloadGeneratedBase64 = async (downloadConfig: ModalDownloadConfig, data: ModalDownloadData) => {
        const children = await this.getChildren(data.payload);
        const company = await companies.get(data.payload.creationCompany);
        return await templates.getPdfOnBase64Format(downloadConfig.templateCode!, {
            order: data.payload,
            children: children,
            company
        });
    };

    addExtraParams(params: any, activeTabKey?: string) {
        const type = this.props.match.params.type;

        if (type === 'VIEWER') {
            params['status'] = 'COMPLETED'
        } else {
            params['status'] = activeTabKey !== ALL_ORDERS_KEY && activeTabKey !== SHOW_SELECTED_KEY ? activeTabKey : AppStore.appSettings.orderAvailableStatuses.split(',');
        }

        orderTools.addDisableRootFilterOption(this.props.match.params.type, params);

        if (type !== undefined) {
            let extraFiltersSetting = AppStore.appSettings['orderListExtraFilters.' + type];
            if (!!extraFiltersSetting) {
                if (typeof extraFiltersSetting === 'string' && !!AppStore.loggedUser?.company && !!AppStore.loggedUser?.company?.id) {
                    extraFiltersSetting = extraFiltersSetting.replace(/\$currentCompanyId/g, AppStore.loggedUser?.company?.id);
                }
                const extraFilters = JSON.parse(extraFiltersSetting);
                Object.keys(extraFilters).forEach(key => params[key] = extraFilters[key]);
            }
        }
    }

    onSearch = async (params: any, currentPage: number, sortField: string, sortDirection: 'asc' | 'desc', size: number, activeTabKey?: string): Promise<Page<ReportOrder>> => {
        this.addExtraParams(params, activeTabKey);

        let orderPage = await Orders.search({
            page: currentPage,
            size: size,
            ...params,
            sort: sortField + ',' + sortDirection
        }, setOffGlobalSpinningOnHeader());

        const companies: Record<string, Company> = (
            await Orders.getCompaniesWithAdminCompanyCode(orderPage, setOffGlobalSpinningOnHeader())).reduce((acc, company) => {
            acc[company.id] = company;
            return acc;
        }, {});

        orderPage.content = orderPage.content.map(order => ({
            ...order,
            assigneeCompanyName: order.assigneeCompany ? companies[order.assigneeCompany].data.businessName : undefined,
            creationCompanyName: order.creationCompany ? companies[order.creationCompany].data.businessName : undefined
        }));
        this.totalElements = orderPage.totalElements;

        this.setState({
            lastParams: {...params, sort: sortField + ',' + sortDirection},
            tenantCompanyMap: companies
        });

        return orderPage;
    };

    private existElementOfOthersStatusThanX(statuses: OrderStatus | OrderStatus[]): boolean {
        return (typeof statuses) === 'string' ?
            !!this.state.selectedOrders.find(item => item.status !== statuses) :
            !!this.state.selectedOrders.find(item => statuses.indexOf(item.status) === -1);
    }

    private checkCurrentActiveTab(tabs: string | string[]): boolean {
        if (!this.state.activeTabKey) {
            return false;
        }
        return (typeof tabs) === 'string' ?
            this.state.activeTabKey === tabs
            :
            tabs.indexOf(this.state.activeTabKey) >= 0;
    }

    private rejectOrders = () => {
        this.setState({isVisibleConfirmModal: true, modalConfirmConfig: this.modalConfirmMultipleRejectOrders});
    };

    private reopenOrders = () => {
        this.setState({isVisibleConfirmModal: true, modalConfirmConfig: this.modalConfirmMultipleReopenOrders});
    };

    private completeOrders = () => {
        this.setState({isVisibleConfirmModal: true, modalConfirmConfig: this.modalConfirmMultipleCompleteOrders});
    };

    private disapproveOrders = () => {
        this.setState({isVisibleConfirmModal: true, modalConfirmConfig: this.modalConfirmMultipleDisapproveOrders});
    };


    private rejectOrder = (order: Order) => {
        this.setState({
            isVisibleRejectModal: true,
            tmpRecord: order
        });
    };

    private rejectChildOrder = (order: SubOrder) => {
        this.setState({
            isVisibleRejectModal: true,
            tmpRecord: order
        });
    };

    private reopenOrder = (id: string) => {
        this.setState({
            isVisibleConfirmModal: true,
            tmpIdOrder: id,
            modalConfirmConfig: this.modalConfirmSingleReopenOrder
        });
    };


    private approveOrders = () => {
        this.setState({isVisibleConfirmModal: true, modalConfirmConfig: this.modalConfirmMultipleApproveOrders});
    };

    private approveOrder = (order: Order) => {
        let hasChildren = order.childDataList && order.childDataList.length > 0 ? true : false
        this.setState({
            isVisibleConfirmModal: true,
            tmpIdOrder: order.id,
            modalConfirmConfig: hasChildren ? this.modalConfirmSingleApproveOrderWithChildren : this.modalConfirmSingleApproveOrder
        });
    };

    private disapproveOrder = (id: string) => {
        this.setState({
            isVisibleConfirmModal: true,
            tmpIdOrder: id,
            modalConfirmConfig: this.modalConfirmSingleDisapproveOrder
        });
    };


    private modifyAttributes = (id: string) => {
        this.setState({
            isVisibleModifyAttributesModal: true,
            tmpIdOrder: id,
            modalConfirmConfig: this.modalConfirmDeleteOrder
        });
    };

    private deleteOrder = (id: string) => {
        this.setState({
            isVisibleConfirmModal: true,
            tmpIdOrder: id,
            modalConfirmConfig: this.modalConfirmDeleteOrder
        });
    };

    isXlsImportEnables = () => {
        return !!this.props.match.params.type ? AppStore.appSettings['enableXlsImport.' + this.props.match.params.type] : AppStore.appSettings.enableXlsImport;
    };
    isEnableXlsExport = () => {
        return !!this.props.match.params.type ? AppStore.appSettings['enableXlsExport.' + this.props.match.params.type] : AppStore.appSettings.enableXlsImport;
    };


    getTabBarExtraContent = (activeTab?: string): JSX.Element[] => {
        const countApprovedOrders: number = this.state.selectedOrders.filter(element => element.status === 'APPROVED').length;
        const countToBeAssignedOrders: number = this.state.selectedOrders.filter(element => element.status === 'TO_BE_ASSIGNED').length;
        const countToAssignedOrders: number = this.state.selectedOrders.filter(element => element.status === 'ASSIGNED').length;
        const countDraftOrders: number = this.state.selectedOrders.filter(element => element.status === 'DRAFT').length;
        const countToFulfilledOrders: number = this.state.selectedOrders.filter(element => element.status === 'FULFILLED').length;
        const countCompletedOrders: number = this.state.selectedOrders.filter(element => element.status === 'COMPLETED').length;
        const countSelectedOrders: number = this.state.selectedOrders.length;
        const isThereTraceTypeIdDuplication: boolean = new Set(this.state.selectedOrders.map(order => order.traceTypeId)).size > 1;
        const isThereTraceTypeCodeDuplication: boolean = new Set(this.state.selectedOrders.map(order => order.traceTypeCode)).size > 1;
        const buttons: JSX.Element[] = [];

        if (!isViewer()) {
            const bulkMenu: any[] = [];

            if (this.shouldEnableCompleteBulk()) {
                bulkMenu.push({
                        key: T.buttons.complete_trace,
                        onClick: this.completeOrders,
                        disabled: this.isDisabledCompleteBulk(countDraftOrders),
                        label: this.completeLabelBulk(countDraftOrders)
                    }
                );
            }

            if (this.shouldEnableDisapproveBulk()) {
                bulkMenu.push({
                    key: T.buttons.disapprove,
                    onClick: this.disapproveOrders,
                    disabled: this.isDisabledDisapproveBulk(countApprovedOrders),
                    label: this.disapproveLabelBulk(countApprovedOrders)
                });
            }


            if (this.shouldEnableBulkReject()) {
                bulkMenu.push({
                    key: T.buttons.reject,
                    onClick: this.rejectOrders,
                    disabled: this.isDisabledRejectBulk(countDraftOrders),
                    label: this.rejectLabelBulk(countCompletedOrders)
                });
            }

            if (this.shouldEnableBulkReopen()) {
                bulkMenu.push({
                    key: T.buttons.reopen,
                    onClick: this.reopenOrders,
                    disabled: this.isDisabledReopenBulk(countCompletedOrders),
                    label: this.reopenLabelBulk(countCompletedOrders)
                });
            }

            if (this.shouldEnableBulkApprove()) {
                bulkMenu.push({
                    key: T.buttons.approve,
                    onClick: this.approveOrders,
                    disabled: this.isDisabledApproveBulk(countCompletedOrders),
                    label: this.approveLabelBulk(countCompletedOrders)
                });
            }
            // Don't touch it for selezione-export-sufiltri
            if (this.shouldEnableBulkAssign()) {
                bulkMenu.push({
                    key: T.buttons.assign_now,
                    onClick: this.handleAssignmentSubmit,
                    disabled: countToBeAssignedOrders <= 0 || this.existElementOfOthersStatusThanX('TO_BE_ASSIGNED'),
                    label: __(T.buttons.assign_now) + (countToBeAssignedOrders > 0 ? ` (${countToBeAssignedOrders})` : '')
                });
            }

            if (bulkMenu.length > 0) {
                buttons.push(
                    <Dropdown key="bulk_actions" overlay={
                        <Menu items={bulkMenu}/>}>
                        <Button>
                            <Space>
                                {__(T.buttons.bulk)}
                                <DownOutlined/>
                            </Space>
                        </Button>
                    </Dropdown>
                );

            }
        }

        if (this.isEnableXlsExport() || (this.isXlsImportEnables() && !isAdmin() && !isViewer())) {
            buttons.push(
                <Dropdown key="export" overlay={<Menu items={[
                    {
                        label: `${__(T.buttons.export_xls)}${countSelectedOrders > 0 ? ` (${countSelectedOrders})` : ''}`,
                        key: 'enquiriesXlsExport',
                        onClick: this.handleXlsExport,
                        style: {display: (this.isEnableXlsExport()) ? 'block' : 'none'}
                    },
                    {
                        label: `${__(T.buttons.base_template_xls)}${countSelectedOrders > 0 && !this.existElementOfOthersStatusThanX(['ASSIGNED', 'DRAFT', 'FULFILLED'])
                            ? ` (${countSelectedOrders})` : ''}`,
                        key: 'baseTemplateXls',
                        onClick: this.downloadTraceTemplate,
                        style: {display: (getUserRole() !== admin && this.isXlsImportEnables()) ? 'block' : 'none'},
                        disabled: countSelectedOrders > 0 ? this.existElementOfOthersStatusThanX(['ASSIGNED', 'DRAFT', 'FULFILLED'])
                            : !this.checkCurrentActiveTab(['ASSIGNED', 'DRAFT', 'FULFILLED'])
                    }
                ]}/>}>
                    <Button>
                        <Space>
                            {__(T.buttons.export)}
                            <DownOutlined/>
                        </Space>
                    </Button>
                </Dropdown>
            );
        }

        if (!isAdmin() && !isViewer() &&
            (this.isXlsImportEnables() || AppStore.appSettings.enableTraceLibrary || AppStore.appSettings.enableDocumentLibrary)) {
            const isDisabledImportTraceModalOrLibraryModal: boolean = ((countToAssignedOrders === 0 && countDraftOrders === 0 && countToFulfilledOrders === 0
                || (countSelectedOrders !== (countToAssignedOrders + countDraftOrders + countToFulfilledOrders)
                    || isThereTraceTypeIdDuplication || isThereTraceTypeCodeDuplication)));

            buttons.push(
                <Dropdown key="import" overlay={<Menu items={[
                    {
                        label: __(T.buttons.import_from_template_xls),
                        key: 'importFromTemplateXls',
                        onClick: this.imporTrace,
                        style: {display: (this.isXlsImportEnables()) ? 'block' : 'none'}
                    },
                    {
                        label: `${__(T.buttons.import_from_trace)}${isDisabledImportTraceModalOrLibraryModal ?
                            '' : ` (${countSelectedOrders})`}`,
                        key: 'importFromTrace',
                        onClick: this.openImportTraceModal,
                        style: {display: (AppStore.appSettings.enableTraceLibrary) ? 'block' : 'none'},
                        disabled: isDisabledImportTraceModalOrLibraryModal
                    },
                    {
                        label: `${__(T.buttons.from_library)}${isDisabledImportTraceModalOrLibraryModal ?
                            '' : ` (${countSelectedOrders})`}`,
                        key: 'importFromLibrary',
                        onClick: this.openImportLibraryModal,
                        style: {display: (AppStore.appSettings.enableDocumentLibrary) ? 'block' : 'none'},
                        disabled: isDisabledImportTraceModalOrLibraryModal
                    }
                ]}/>}>
                    <Button>
                        <Space>
                            {__(T.buttons.import)}
                            <DownOutlined/>
                        </Space>
                    </Button>
                </Dropdown>
            );
        }
        if (isAdmin() && AppStore.appSettings.enableHandmade
            || (isSupplier() && AppStore.appSettings.enableHandmade
                && !!AppStore.appSettings.handmadeSupplierCompany
                && AppStore.loggedUser?.company?.categories?.some(
                    category => AppStore.appSettings.handmadeSupplierCompany.split(',').includes(category)))) {
            buttons.push(
                <Button
                    key={T.buttons.add_new_order}
                    type={'primary'}
                    // type={countCompletedOrders > 0 ? 'primary' : 'default'}
                    // style={{ width: '200px' }}
                    onClick={() => this.showAddNewOrder()}
                >
                    {__(T.buttons.add_new_order)}
                </Button>
            );
        }

        return buttons;
    };


    handleSingleCancelReject = async () => {
        this.setState({isVisibleRejectModal: false});
    };

    handleSingleRejectSubmit = async (orderDetail: DetailedOrder) => {
        this.setState({isVisibleRejectModal: false},
            async () => {
                if (!!orderDetail.errorValidation) {
                    showNotification(
                        __(T.titles.order_reject_error),
                        __(T.messages.order_saved_draft_with_errors),
                        'error',
                        3
                    );
                } else {
                    showNotification(
                        __(T.titles.order_reject_success),
                        __(T.messages.order_reject_success),
                        'success',
                        3
                    );
                }
                this.setState({
                    tmpIdOrder: null,
                    isVisibleRejectModal: false
                });
                this.refreshData();
            });
    };

    handleSingleReopenSubmit = async (confirm: boolean) => {
        this.setState({isVisibleConfirmModal: false},
            async () => {
                if (confirm) {
                    await Orders.reopen(this.state.tmpIdOrder!);
                    showNotification(
                        __(T.titles.success),
                        __(T.messages.order_reopen_success),
                        'success',
                        3
                    );
                    this.setState({
                        tmpIdOrder: null
                    });
                    this.refreshData();
                }
            });
    };

    handleDeleteOrderSubmit = async (confirm: boolean) => {
        this.setState({isVisibleConfirmModal: false},
            async () => {
                if (confirm) {
                    await orders.deleteOrder(this.state.tmpIdOrder!);
                    showNotification(
                        __(T.titles.success),
                        __(T.messages.order_remove_success),
                        'success',
                        3
                    );
                    this.setState({
                        tmpIdOrder: null
                    }, () => {
                        this.refreshData();
                    });
                }
            });
    };

    handleMultipleRejectSubmit = async (confirm: boolean, messageRejected?: string) => {
        this.setState({isVisibleConfirmModal: false},
            async () => {
                if (confirm) {
                    let ids = this.state.selectedOrders.filter(element => element.status === 'COMPLETED').map(element => element.id);

                    if (ids.length !== this.state.selectedOrders.length) {
                        throw new Error('Selected rows are not on complete state');
                    }

                    if (ids.length > 0) {
                        await Orders.rejects(
                            {ids: ids},
                            messageRejected
                        );
                    } else {
                        await Orders.rejects(
                            {
                                ...this.state.lastParams
                            },
                            messageRejected);
                    }

                    showNotification(
                        __(T.titles.success),
                        ids.length > 1 ? __(T.messages.orders_reject_success) : __(T.messages.order_reject_success),
                        'success',
                        3
                    );
                    this.setState({});
                    this.refreshData();
                }
            });
    };

    handleMultipleReopenSubmit = async (confirm: boolean) => {
        this.setState({isVisibleConfirmModal: false},
            async () => {
                if (confirm) {
                    let ids = this.state.selectedOrders.filter(element => element.status === 'COMPLETED').map(element => element.id);
                    if (ids.length !== this.state.selectedOrders.length) {
                        throw new Error('Selected rows are not in completed state');
                    }

                    if (ids.length > 0) {
                        await Orders.reopens({
                            ids: ids
                        });
                    } else {
                        await Orders.reopens({
                            ...this.state.lastParams
                        });
                    }

                    showNotification(
                        __(T.titles.success),
                        ids.length > 1 ? __(T.messages.orders_reopen_success) : __(T.messages.order_reopen_success),
                        'success',
                        3
                    );
                    this.setState({});
                    this.refreshData();
                }
            });
    };


    handleMultipleCompleteSubmit = async (confirm: boolean) => {
        let fulfilResult: FulfilResult;
        this.setState({isVisibleConfirmModal: false},
            async () => {
                if (confirm) {
                    let ids: string[] = this.state.selectedOrders.filter(element => element.status === 'DRAFT').map(element => element.id);
                    if (ids.length !== this.state.selectedOrders.length) {
                        throw new Error('Selected rows are not in draft state');
                    }
                    if (ids.length > 0) {
                        fulfilResult = await Orders.fulfills({
                            ids: ids
                        });
                    } else {
                        fulfilResult = await Orders.fulfills({
                            ...this.state.lastParams
                        });
                    }

                    showNotification(
                        __(T.titles.success),
                        ids.length > 1 ? __(T.messages.order_completes_success) : __(T.messages.order_complete_success),
                        'success',
                        3
                    );
                    this.setState({isVisibileBulkCompleteModal: true, fulfilResult: fulfilResult});
                    this.refreshData();
                }
            });
    };


    handleSingleApproveSubmit = async (confirm: boolean) => {
        this.setState({isVisibleConfirmModal: false},
            async () => {
                if (confirm) {
                    await Orders.approve(this.state.tmpIdOrder!);
                    showNotification(
                        __(T.titles.success),
                        __(T.messages.order_approve_success),
                        'success',
                        3
                    );
                    this.setState({});
                    this.refreshData();
                }
            });
    };


    handleSingleDisapproveSubmit = async (confirm: boolean) => {
        this.setState({isVisibleConfirmModal: false},
            async () => {
                if (confirm) {
                    await Orders.disapprove(this.state.tmpIdOrder!);
                    showNotification(
                        __(T.titles.success),
                        __(T.messages.order_approve_success),
                        'success',
                        3
                    );
                    this.setState({});
                    this.refreshData();
                }
            });
    };

    handleMultipleDisapproveSubmit = async (confirm: boolean) => {
        this.setState({isVisibleConfirmModal: false},
            async () => {
                if (confirm) {
                    let ids = this.state.selectedOrders.filter(element => element.status === 'APPROVED').map(element => element.id);
                    if (ids.length !== this.state.selectedOrders.length) {
                        throw new Error('Selected rows are not on approved state');
                    }
                    if (ids.length > 0) {
                        await Orders.disapproveList({
                            ids: ids
                        });
                    } else {
                        await Orders.disapproveList({
                            ...this.state.lastParams
                        });
                    }

                    showNotification(
                        __(T.titles.success),
                        ids.length > 1 ? __(T.messages.orders_disapprove_success) : __(T.messages.order_disapprove_success),
                        'success',
                        3
                    );
                    this.setState({});
                    this.refreshData();
                }
            });
    };

    handleMultipleApproveSubmit = async (confirm: boolean) => {
        this.setState({isVisibleConfirmModal: false},
            async () => {
                if (confirm) {
                    let ids = this.state.selectedOrders.filter(element => element.status === 'COMPLETED').map(element => element.id);
                    if (ids.length !== this.state.selectedOrders.length) {
                        throw new Error('Selected rows are not on completed state');
                    }
                    let bulkApproveResponse: any;
                    if (ids.length > 0) {
                        bulkApproveResponse = await Orders.approveList({
                            ids: ids,
                            forceApprove: false
                        });
                    } else {
                        bulkApproveResponse = await Orders.approveList({
                            ...this.state.lastParams,
                            forceApprove: false
                        });
                    }

                    if (bulkApproveResponse) {
                        const bulkApproveOrderDetail: BulkApproveOrderDetailDto = {
                            orders: bulkApproveResponse.orders || [],
                            totalToApprove: bulkApproveResponse.totalToApprove || 0,
                            approved: bulkApproveResponse.approved || 0
                        };
                        this.setState({
                            isVisibleBulkApproveDialog: true,
                            bulkApproveOrderDetail: bulkApproveOrderDetail
                        });

                    } else {
                        console.error("Errore nella risposta dell'API", bulkApproveResponse);
                    }


                }
            });
    };

    handleCancelBulkApproveDialog = () => {
        this.setState({isVisibleBulkApproveDialog: false});
        this.setState({});
        this.refreshData();
    };

    handleForceApproveFromDialog = async () => {
        this.setState({isVisibleBulkApproveDialog: false});
        let ids = this.state.selectedOrders.filter(element => element.status === 'COMPLETED').map(element => element.id);
        if (ids.length !== this.state.selectedOrders.length) {
            throw new Error('Selected rows are not on completed state');
        }
        if (ids.length > 0) {
            await Orders.approveList({
                ids: ids,
                forceApprove: true
            });
        } else {
            await Orders.approveList({
                ...this.state.lastParams,
                forceApprove: true
            });
        }
        this.showNotificationAfterApproveAndRefreshData()
    };

    private showNotificationAfterApproveAndRefreshData() {
        showNotification(
            __(T.titles.success),
            this.state.bulkApproveOrderDetail.approved > 1 ? __(T.messages.orders_approve_success) : __(T.messages.orders_approve_success),
            'success',
            3
        );
        this.setState({});
        this.refreshData();
    }


    handleAssignmentSubmit = () => {
        const navigationPath = this.props.match.params.type ? `/reports/enquiryMultiAssign/${this.props.match.params.type}/` : '/reports/ordersMultiAssign/';

        navigate(navigationPath, {
            ids:
                this.state.selectedOrders.filter(element => element.status === 'TO_BE_ASSIGNED').map(element => element.id)
        });
    };

    handleXlsExport = () => {
        if (!!this.state.selectedOrders && this.state.selectedOrders.length > 0) {
            exportData.newFileDownload(
                this.getConfigurationExportData(),
                'tCare-Enquiry Export_data.xls',
                'XLS',
                orders.getEndpointSearchOrderApi(),
                20,
                {
                    ids: this.state.selectedOrders.map(o => o.id),
                    sort: this.state.lastParams.sort,
                    page: 0,
                    size: 0
                });
        } else {
            exportData.newFileDownload(
                this.getConfigurationExportData(),
                'tCare-Enquiry Export_data.xls',
                'XLS',
                orders.getEndpointSearchOrderApi(),
                20,
                {
                    ...this.state.lastParams
                });
        }
    };

    getConfigurationExportData(): string {
        return !!this.props.match.params.type && !!AppStore.appSettings[`ordersXlsSchema.${this.props.match.params.type}`]
          ? `ordersXlsSchema.${this.props.match.params.type}`
          : 'ordersXlsSchema';
    }



    downloadTraceTemplate = () => {
        if (!!this.state.selectedOrders && this.state.selectedOrders.length > 0) {
            Orders.downloadTraceTemplate({
                ids: this.state.selectedOrders.map(o => o.id),
                sort: this.state.lastParams.sort,
                page: 0,
                size: 0,
                configurationFile: this.getConfigurationTemplateFile()
            });
        } else {
            Orders.downloadTraceTemplate({
                ...this.state.lastParams,
                page: 0,
                size: 0,
                configurationFile: this.getConfigurationTemplateFile()
            });
        }
    };

    imporTrace = () => {
        this.setState({isUploadTraceReportModalVisible: true});
    };

    openSplitModal = (splittedOrderId: string | undefined) => {
        this.setState({isSplitModalVisible: true, splittedOrderId: splittedOrderId});
    };


    handleUploadOrderTraceModalCancel = () => {
        this.setState({isUploadTraceReportModalVisible: false});
    };

    handleUploadOrderTraceModalConfirm = async () => {
        this.setState({isUploadTraceReportModalVisible: false}, () => this.refreshData());
    };


    handleModifyAttributesConfirm = async () => {
        this.setState({isVisibleModifyAttributesModal: false}, () => this.refreshData());
    };

    handleModifyAttributesCancel = async () => {
        this.setState({isVisibleModifyAttributesModal: false});
    };


    handleSplitCancel = () => {
        this.setState({isSplitModalVisible: false});
    };

    handleSplitConfirm = async () => {
        this.setState({isSplitModalVisible: false}, () => this.refreshData());
    };

    isOrderStatusTabKey = (activeKey: string): activeKey is string =>
        this.orderStatusTabKeys.some(orderStatusTabKey => orderStatusTabKey === activeKey);

    shouldEnableMultipleAssignment = () => {
        return (this.shouldEnableBulkAssign() || this.shouldEnableBulkReject());
    };

    shouldEnableBulkApprove = () => {
        return (getUserRole() === 'admin' && AppStore.appSettings.enableApproveOrder
            && (this.state.activeTabKey === ALL_ORDERS_KEY
                || this.state.activeTabKey === 'COMPLETED'
                || this.state.activeTabKey === SHOW_SELECTED_KEY));
    };

    shouldEnableDisapproveBulk = () => {
        return (getUserRole() === 'admin' && AppStore.appSettings.enableEnquiryDisapprove
            && (this.state.activeTabKey === ALL_ORDERS_KEY
                || this.state.activeTabKey === 'APPROVED'
                || this.state.activeTabKey === SHOW_SELECTED_KEY));
    };

    shouldEnableBulkReject = () => {
        return (getUserRole() === 'admin' && AppStore.appSettings.enableBulkReject
            && (this.state.activeTabKey === ALL_ORDERS_KEY
                || this.state.activeTabKey === 'COMPLETED'
                || this.state.activeTabKey === SHOW_SELECTED_KEY));
    };

    shouldEnableBulkReopen = () => {
        return (getUserRole() !== 'admin'
                && AppStore.appSettings.enableReopenOrder
                && typesSettingsTools.isSettingEnableTraceByMatchParamsType(this.props.match.params.type, 'enableReopenOrder'))
            && (this.state.activeTabKey === ALL_ORDERS_KEY
                || this.state.activeTabKey === 'COMPLETED'
                || this.state.activeTabKey === SHOW_SELECTED_KEY);
    };

    shouldEnableCompleteBulk = () => {
        return (getUserRole() !== 'admin' && AppStore.appSettings.enableBulkComplete
            && (this.state.activeTabKey === ALL_ORDERS_KEY
                || this.state.activeTabKey === 'DRAFT'
                || this.state.activeTabKey === SHOW_SELECTED_KEY));
    };

    shouldEnableBulkAssign = () => {
        return (getUserRole() !== 'admin' && AppStore.appSettings.enableBulkAssign
            && (this.state.activeTabKey === ALL_ORDERS_KEY
                || this.state.activeTabKey === 'TO_BE_ASSIGNED'
                || this.state.activeTabKey === SHOW_SELECTED_KEY));
    };

    /* **************** Complete **********************************************/

    isDisabledCompleteBulk = (countDraftOrders: number): boolean => {
        return (this.state.activeTabKey === ALL_ORDERS_KEY
            && countDraftOrders <= 0
            || this.existElementOfOthersStatusThanX('DRAFT'));
    };

    completeLabelBulk = (countDraftOrders: number): string | void => {
        if (countDraftOrders === undefined) {
            countDraftOrders = 0;
        }

        const moreInfo = ` (${countDraftOrders} / ${this.state.selectedOrders.length})`;

        if (this.state.activeTabKey === ALL_ORDERS_KEY ||
            this.state.activeTabKey === SHOW_SELECTED_KEY ||
            this.state.selectedOrders.length > countDraftOrders) {
            return __(T.buttons.complete_trace) + moreInfo;
        }

        if (countDraftOrders === 0) {
            return __(T.buttons.complete_all_trace);
        }

        return (__(T.buttons.complete_trace) + ` (${countDraftOrders})`);


        /*
        if (this.state.activeTabKey === ALL_ORDERS_KEY || this.state.activeTabKey === SHOW_SELECTED_KEY) {
          return (__(T.buttons.complete_trace) + ' (' + countDraftOrders + ' / ' + this.state.selectedOrders.length + ')');
        }
        if (this.state.selectedOrders.length > countDraftOrders) {
          return (__(T.buttons.complete_trace) + ' (' + countDraftOrders + ' / ' + this.state.selectedOrders.length + ')');
        }
        if (countDraftOrders === 0) {
          return (__(T.buttons.complete_all_trace));
        } else {
          return (__(T.buttons.complete_trace) + (countDraftOrders > 0 ? ` (${countDraftOrders})` : ''));
        }*/
    };

    /* **************** Disapprove **********************************************/

    isDisabledDisapproveBulk = (countDraftOrders: number): boolean => {
        return (this.state.activeTabKey === ALL_ORDERS_KEY
            && countDraftOrders <= 0
            || this.existElementOfOthersStatusThanX('APPROVED'));
    };

    disapproveLabelBulk = (countApprovedOrders: number): string => {

        if (countApprovedOrders === undefined) {
            countApprovedOrders = 0;
        }

        const moreInfo = ` (${countApprovedOrders} / ${this.state.selectedOrders.length})`;

        if (this.state.activeTabKey === ALL_ORDERS_KEY ||
            this.state.activeTabKey === SHOW_SELECTED_KEY ||
            this.state.selectedOrders.length > countApprovedOrders) {
            return __(T.buttons.disapprove) + moreInfo;
        }

        if (countApprovedOrders === 0) {
            return __(T.buttons.all_disapprove);
        }

        return (__(T.buttons.disapprove) + ` (${countApprovedOrders})`);

    };

    /* **************** Reject **********************************************/

    isDisabledRejectBulk = (countCompletedOrders: number): boolean => {
        return (this.state.activeTabKey === ALL_ORDERS_KEY
            && countCompletedOrders <= 0
            || this.existElementOfOthersStatusThanX('COMPLETED'));
    };

    rejectLabelBulk = (countCompletedOrders: number): string => {

        if (countCompletedOrders === undefined) {
            countCompletedOrders = 0;
        }


        const moreInfo = ` (${countCompletedOrders} / ${this.state.selectedOrders.length})`;

        if (this.state.activeTabKey === ALL_ORDERS_KEY ||
            this.state.activeTabKey === SHOW_SELECTED_KEY ||
            this.state.selectedOrders.length > countCompletedOrders) {
            return __(T.buttons.reject) + moreInfo;
        }

        if (countCompletedOrders === 0) {
            return __(T.buttons.all_reject);
        }

        return (__(T.buttons.reject) + ` (${countCompletedOrders})`);

    };

    /* **************** Reopen **********************************************/

    isDisabledReopenBulk = (countCompletedOrders: number): boolean => {
        return (this.state.activeTabKey === ALL_ORDERS_KEY
            && countCompletedOrders <= 0
            || this.existElementOfOthersStatusThanX('COMPLETED'));
    };

    reopenLabelBulk = (countCompletedOrders: number): string => {
        if (countCompletedOrders === undefined) {
            countCompletedOrders = 0;
        }

        const moreInfo = ` (${countCompletedOrders} / ${this.state.selectedOrders.length})`;

        if (this.state.activeTabKey === ALL_ORDERS_KEY ||
            this.state.activeTabKey === SHOW_SELECTED_KEY ||
            this.state.selectedOrders.length > countCompletedOrders) {
            return __(T.buttons.reopen) + moreInfo;
        }

        if (countCompletedOrders === 0) {
            return __(T.buttons.all_reopen);
        }

        return (__(T.buttons.reopen) + ` (${countCompletedOrders})`);


    };

    /* **************** Approve **********************************************/

    isDisabledApproveBulk = (countCompletedOrders: number): boolean => {
        return (this.state.activeTabKey === ALL_ORDERS_KEY
            && countCompletedOrders <= 0
            || this.existElementOfOthersStatusThanX('COMPLETED'));
    };

    approveLabelBulk = (countCompletedOrders: number): string => {
        if (countCompletedOrders === undefined) {
            countCompletedOrders = 0;
        }

        const moreInfo = ` (${countCompletedOrders} / ${this.state.selectedOrders.length})`;

        if (this.state.activeTabKey === ALL_ORDERS_KEY ||
            this.state.activeTabKey === SHOW_SELECTED_KEY ||
            this.state.selectedOrders.length > countCompletedOrders) {
            return __(T.buttons.approve) + moreInfo;
        }

        if (countCompletedOrders === 0) {
            return __(T.buttons.all_approve);
        }

        return (__(T.buttons.approve) + ` (${countCompletedOrders})`);

    };


    openImportTraceModal = () => {
        this.setState({isImportTraceModalVisible: true});
    };

    onConfirmImportTrace = async (order: Order, overrideExistingDataInput: boolean) => {
        this.setState({isImportTraceModalVisible: false});
        if (null === await Orders.importBulkFromTrace({
            orderIds: this.state.selectedOrders.map(order => order.id),
            orderIdToImport: order.id!,
            override: overrideExistingDataInput
        })) {
            showNotification(
                __(T.titles.success),
                __(T.messages.successfully_import_trace),
                'success',
                3
            );
        }
        this.setState({isImportTraceModalVisible: false}, () => this.refreshData());
    };

    openImportLibraryModal = () => {
        this.setState({isImportLibraryModalVisible: true});
    };

    onCancelImportTrace = (): void => {
        this.setState({isImportTraceModalVisible: false});
    };

    onCancelImportLibrary = () => {
        this.setState({isImportLibraryModalVisible: false});
    };

    onConfirmImportLibrary = async (libraryItem: LibraryItem, override: boolean) => {
        if (null === await Orders.importBulkFromLibrary({
            orderIds: this.state.selectedOrders.map(order => order.id),
            libraryIdToImport: libraryItem.id!,
            override: override
        })) {
            showNotification(
                __(T.titles.success),
                __(T.messages.successfully_import_trace),
                'success',
                3
            );
        }
        this.setState({isImportLibraryModalVisible: false}, () => this.refreshData());
    };

    handleBulkCompleteModalInformation = () => {
        this.setState({isVisibileBulkCompleteModal: false});
    };


    async refreshOrderList(orders: ReportOrder[]): Promise<ReportOrder[]> {
        const ids: string[] = orders.map(order => order.id);
        const size = 50;
        const ordersReturn: ReportOrder[] = [];

        for (let i = 0; i < ids.length; i += size) {
            const chunk = ids.slice(i, i + size);
            const pageReportOrder = await Orders.search({
                page: 0,
                size: size,
                ids: chunk
            }, setOffGlobalSpinningOnHeader());
            ordersReturn.push(...pageReportOrder.content);
        }
        return ordersReturn;
    }

    closeSendEmailModal = (): void => {
        this.setState({
            tmpRecord: undefined,
            tmpUser: undefined,
            tmpCompanyCode: undefined,
            isVisibleOrderSendEmailModal: false
        });
    };

    buildEnquirySendEmailDefaultFieldValues = (): OrderSendEmailModalValues => {
        const {tmpRecord, tmpCompanyCode, tmpUser} = this.state;

        if (!this.props.match.params.type || !tmpRecord) {
            return {
                email: undefined,
                note: undefined
            };
        }

        const setting = AppStore.appSettings['enquirySendEmailDefaultFieldNote.' + this.props.match.params.type];

        let emails: string[] = [];
        if (!!tmpUser?.email) {
            emails =  [tmpUser?.email];
        } else if (!!tmpRecord['operativeUsername']) {
            emails =  [tmpRecord['operativeUsername']];
        }

        return {
            email: emails,
            note: this.resolveNote(tmpRecord, tmpCompanyCode, setting)
        };
    };

    private resolveNote = (obj: any, tmpCompanyCode?: string, setting?: string): string | undefined => {
        if (!setting) {
            return undefined;
        }

        const fields = setting.match(/\{(.*?)\}/g);

        if (!fields) {
            return undefined;
        }

        let note: string = setting;
        fields.forEach(field => {
            if (field === 'adminCompanyCode') {
                note = note.replace(field, tmpCompanyCode ?? '')
            } else {
                const paramValue = this.extractFieldValue(obj, field);
                note = note.replace(field, paramValue ?? '')
            }
        });
        return note;

    };

    private extractFieldValue = (currentObj: any, field: string): string | undefined => {
        const cleanedField = field.substr(1, field.length - 2);
        return this.getFieldValue(currentObj, cleanedField.split('.'), 0);
    };

    private getFieldValue = (currentObj: any, fields: string[], index: number): string | undefined => {
        if (fields.length - 1 === index) {
            return currentObj[fields[index]];
        }

        const field = currentObj[fields[index]];

        if (!field) {
            return undefined;
        }

        // index++ not working in this case
        const newIndex = index + 1;

        return this.getFieldValue(field, fields, newIndex);
    };

    sendMail = async (emails: string[], note?: string) => {
        if (this.state.tmpBase64) {
            await this.sendMailEmailWithFile(emails, note);
        } if (this.state.fileId){
            await this.sendMailEmailWithFileFileId(emails, note);
        } else {
            await this.sendMailByGenerationPdf(emails, note);
        }
    };

    async sendMailEmailWithFileFileId(emailAddress: string[], note: string | undefined){
        this.setState({isLoading: true});
        const order = await Orders.get(this.tmpRecord!.id);
        let mail: Mail = {
            emailAddress: emailAddress,
            fileId: this.state.fileId!,
            params: {
                note: note,
                order: order
            },
            codeTemplate: this.tmpRecord!.step
        };
        await mails.send(mail);

        this.setState({
            tmpRecord: undefined,
            tmpUser: undefined,
            tmpCompanyCode: undefined,
            isVisibleOrderSendEmailModal: false,
            isLoading: false
        });
    }

    async sendMailEmailWithFile(emailAddress: string[], note: string | undefined) {
        this.setState({isLoading: true});
        const dataUrl = this.state.tmpBase64!;

        const base64Index = dataUrl.indexOf(';base64,');
        // Estrai il base64 dalla stringa
        const base64String = dataUrl.slice(base64Index + ';base64,'.length);
        // Estrai il tipo di dati (application/pdf) dalla stringa
        const dataTypeIndex = dataUrl.indexOf(';name=');
        const dataType = dataUrl.slice(5, dataTypeIndex);
        // Estrai il nome del file dalla stringa
        const fileName = dataUrl.slice(dataUrl.indexOf(';name=') + 6, base64Index);


        const order = await Orders.get(this.tmpRecord!.id);


        let mail: Mail = {
            emailAddress: emailAddress,
            fileBase64Encoded: base64String,
            fileName: fileName,
            fileType: dataType,
            params: {
                note: note,
                order: order
            },
            codeTemplate: this.tmpRecord!.step
        };
        await mails.send(mail);

        this.setState({
            tmpRecord: undefined,
            tmpUser: undefined,
            tmpCompanyCode: undefined,
            isVisibleOrderSendEmailModal: false,
            isLoading: false
        });
    }


    async sendMailByGenerationPdf(emails: string[], note?: string) {
        this.setState({isLoading: true});
        const order = await Orders.get(this.tmpRecord!.id);
        const tenantCompanyCode = this.state.tmpCompanyCode;

        //TODO: scablare nome
        let workorder = order.step === 'Workshop' ? order.code : order.attributes!.workOrder;
        const filename = 'FPDB_' + tenantCompanyCode + '_' + workorder + '_' + order.completionVersion + '.pdf';

        const parsedNote = note ? this.parseNode(note) : undefined;

        if (!emails.includes(AppStore.loggedUser!.email)) {
            emails.push(AppStore.loggedUser!.email);
        }

        const children = await this.getChildren(order);
        const company = await companies.get(order.creationCompany!);
        await Templates.sendEmail(this.tmpRecord!.step, emails, {
            order,
            note: parsedNote,
            children,
            company
        }, filename);

        this.setState({
            tmpRecord: undefined,
            tmpUser: undefined,
            tmpCompanyCode: undefined,
            isVisibleOrderSendEmailModal: false,
            isLoading: false
        });
    };

    private parseNode = (note: string): string => note.replaceAll('\n', '<br>');
    manageSendMail = async (base64: string) => {

        const record = this.tmpRecord!;
        const user = record.operativeUsername ? await Users.findByUsername(record.operativeUsername) : undefined;
        const company = record.creationCompany ? await Suppliers.findTenantCompanyCodeByCompanyId(record.creationCompany) : undefined;

        this.setState({
            tmpRecord: record,
            tmpUser: user,
            tmpCompanyCode: company,
            tmpBase64: base64,
            isVisibleOrderSendEmailModal: true
        });

    };

    manageSendMailFileId = async (fileId: any) => {

        const record = this.tmpRecord!;
        const user = record.operativeUsername ? await Users.findByUsername(record.operativeUsername) : undefined;
        const company = record.creationCompany ? await Suppliers.findTenantCompanyCodeByCompanyId(record.creationCompany) : undefined;

        this.setState({
            tmpRecord: record,
            tmpUser: user,
            tmpCompanyCode: company,
            fileId: fileId,
            isVisibleOrderSendEmailModal: true
        });

    };

    manageSendMailGenerated = async () => {

        const record = await Orders.get(this.tmpRecord!.id);
        const user = record.operativeUsername ? await Users.findByUsername(record.operativeUsername) : undefined;
        const company = record.creationCompany ? await Suppliers.findTenantCompanyCodeByCompanyId(record.creationCompany) : undefined;

        this.setState({
            tmpRecord: record,
            tmpUser: user,
            tmpCompanyCode: company,
            isVisibleOrderSendEmailModal: true
        });

    };


    private parseNode = (note: string): string => note.replaceAll('\n', '<br>');

    render = () => {

        let storeKey = ORDER_REPORT_SESSION_STORAGE + (!!this.props.match.params.type ? '_' + this.props.match.params.type : '');

        return (
            <Layout key={'orderReportView'}>
                {!!this.state.componentMount && <FilteredTable
                    onSearchElements={this.onSearch}
                    getColumnsConfig={this.getColumns}
                    getExtraButtons={this.getTabBarExtraContent}
                    askToUpdateData={this.state.askToUpdateData}
                    tab={{
                        tabKeys: this.orderStatusTabKeys,
                        initialActiveTabKey: this.state.activeTabKey,
                        onTabChange: (tab) => {
                            this.setState({
                                activeTabKey: tab
                            }, () => {
                                addQueryParams({'status': tab}, false);
                            });
                        }
                    }}
                    getFiltersConfig={this.getFilters}
                    storeKey={storeKey}
                    rowSelection={{
                        multipleSelection: true,
                        onChangeSelection: (elements: ReportOrder[]) => {
                            this.setState({
                                selectedOrders: elements.map(o => {
                                    return {
                                        id: o.id,
                                        traceTypeId: o.traceTypeId,
                                        traceTypeCode: o.traceTypeCode,
                                        status: o.status
                                    };
                                })
                            });

                        }
                    }}
                    refreshSelectedElements={async (elements: ReportOrder[]) => {
                        return await this.refreshOrderList(elements);
                    }}
                />
                }

                {(this.state.splittedOrderId !== null) &&
                    <SplitOrderModal
                        motherOrderId={this.state.splittedOrderId}
                        visible={this.state.isSplitModalVisible}
                        onConfirm={this.handleSplitConfirm}
                        onCancel={this.handleSplitCancel}

                    />
                }
                <OrderLogsModal onClose={() => this.closeLogs()} orderId={this.state.logOrderIdToShow}/>
                <UploadTraceDataFileModal
                    visible={this.state.isUploadTraceReportModalVisible}
                    onConfirm={this.handleUploadOrderTraceModalConfirm}
                    onCancel={this.handleUploadOrderTraceModalCancel}
                    configurationUpload={this.getConfigurationTemplateFile()}
                />
                {!!AppStore.appSettings.enquireAttributesEditJsonSchema &&
                    <ModalModifyAttributes
                        visible={this.state.isVisibleModifyAttributesModal}
                        orderId={this.state.tmpIdOrder!}
                        onConfirm={this.handleModifyAttributesConfirm}
                        onCancel={this.handleModifyAttributesCancel}
                    />
                }

                {this.state.modalConfirmConfig &&
                    <ModalConfirm
                        visible={this.state.isVisibleConfirmModal}
                        title={this.state.modalConfirmConfig.title()}
                        subtitle={this.state.modalConfirmConfig.subtitle()}
                        hasFeedback={this.state.modalConfirmConfig.hasMessage}
                        callback={this.state.modalConfirmConfig.callback}
                        confirm={this.state.modalConfirmConfig.confirm !== undefined ? this.state.modalConfirmConfig.confirm() : undefined}
                    />
                }
                {
                    this.state.selectedOrders.length > 0 &&
                    <>
                        <ImportTraceDialog
                            isVisible={this.state.isImportTraceModalVisible}
                            onCancel={this.onCancelImportTrace}
                            onSuccess={this.onConfirmImportTrace}
                            traceTypeCode={this.state.selectedOrders[0].traceTypeCode}
                            title={__(T.titles.import_trace_dialog)}
                        />
                        <ImportLibraryDialog
                            isVisible={this.state.isImportLibraryModalVisible}
                            onCancel={this.onCancelImportLibrary}
                            onSuccess={this.onConfirmImportLibrary}
                            traceTypeCode={this.state.selectedOrders[0].traceTypeCode}
                            title={__(T.titles.import_library_dialog)}
                        />
                    </>
                }
                <NewOrderModal
                    isVisible={this.state.isShowAddNewOrder}
                    onCancelCallback={this.onCancelNewOrder}
                    onSuccessCallback={this.onSuccessNewOrder}
                />
                <Modal
                    visible={this.state.isVisibileBulkCompleteModal}
                    title={__(T.messages.complete_order_by_bulk_title)}
                    footer={[
                        <Button key="ok" type="primary" onClick={this.handleBulkCompleteModalInformation}>
                            OK
                        </Button>
                    ]}
                >
                    <div dangerouslySetInnerHTML={{
                        __html: __(T.messages.complete_order_by_bulk_body,
                            {
                                completedEnquiries: this.state.fulfilResult?.completeOrder ?? 0,
                                notValidEnquiries: this.state.fulfilResult?.notValidOrder ?? 0
                            })
                    }}>

                    </div>
                </Modal>
                {this.state.tmpRecord && this.state.isVisibleOrderSendEmailModal &&
                    <OrderSendEmailModal
                        isLoading={this.state.isLoading}
                        defaultValues={this.buildEnquirySendEmailDefaultFieldValues()}
                        onConfirm={(email, note?: string) => this.sendMail(email, note)}
                        onCancel={this.closeSendEmailModal}/>
                }
                {AppStore.appSettings.enquiryDownloads && this.state.modalDownloadsData &&
                    <ModalDownloads
                        data={this.state.modalDownloadsData}
                        visible={!!this.state.modalDownloadsData}
                        config={JSON.parse(AppStore.appSettings.enquiryDownloads)}
                        onClose={() => this.setState({modalDownloadsData: undefined})}
                        getDownloadGenerateFileName={(config, data) => this.getDownloadGenerateFileName(config, data)}
                        onDownloadGenerated={(config, data) => this.onDownloadGenerated(config, data)}
                        sendMailGenerated={() => this.manageSendMailGenerated()}
                        getDownloadGeneratedBase64={async (config, data) => await this.getDownloadGeneratedBase64(config, data)}
                        sendMail={((base64: string) => this.manageSendMail(base64))}
                        sendMailFileId={((fileId: any) => this.manageSendMailFileId(fileId))}
                    />}
                <RejectModal
                    visible={this.state.isVisibleRejectModal}
                    title={__(T.fields.reject_order_modal_title)}
                    subtitle={__(T.fields.reject_order_modal_body)}
                    hasNote={true}
                    order={this.state.tmpRecord}
                    callbackRejectOrder={(orderDetail) => this.handleSingleRejectSubmit(orderDetail)}
                    callbackCancelRejectOrder={this.handleSingleCancelReject}
                />

                <BulkApproveDialog
                    isVisible={this.state.isVisibleBulkApproveDialog}
                    onCancel={this.handleCancelBulkApproveDialog}
                    onForceApprove={this.handleForceApproveFromDialog}
                    bulkApproveOrderDetail={this.state.bulkApproveOrderDetail}
                    canUserForceApprove={!AppStore.appSettings.enableLockIfChildrenAreNotApproved}
                />

            </Layout>
        );
    };

}
