const labels = {
  modal: {
    downloads: {
      title: 'Téléchargements',
      empty: 'Aucun téléchargement disponible'
    }
  },
  buttons: {
    addItem: 'Ajouter',
    removeItem: 'Retirer',
    all: 'Tous',
    unread: 'À lire',
    libraryNew_TRACE: 'Nouvel article',
    libraryNew_DOCUMENT: 'Nouveau document',
    library_upload_document: 'Télécharger un document',
    libraryEdit_TRACE: 'Détail de l\'article',
    libraryEdit_DOCUMENT: 'Modifier le document de la bibliothèque',
    libraryList_TRACE: 'Liste des articles de la bibliothèque',
    libraryList_DOCUMENT: 'Liste de documentation',
    add: 'Ajouter',
    add_certificate: 'Ajouter un certificat',
    add_user: 'Ajouter un utilisateur',
    add_new_order: 'Ajouter une nouvelle demande',
    analytic: 'Analytique',
    reopen: 'Réouvrir',
    all_reopen: 'Tout réouvrir',
    reject: 'Rejeter',
    reassign: 'Réaffecter',
    rejectSubOrder: 'Rejeter la sous-commande',
    all_reject: 'Tout rejeter',
    reset: 'Réinitialiser',
    approve: 'Approuver',
    all_approve: 'Tout approuver',
    assign: 'Assigner',
    assign_now: 'Assigner maintenant',
    base_template_xls: 'Modèle de base .xls',
    bulk: 'Actions groupées',
    actions: 'actions',
    close: 'Fermer',
    edit: 'Modifier',
    modify_attributes: 'Modifier les attributs',
    enquiries_xls: 'Demandes .xls',
    export: 'Exporter',
    export_xls: 'Exporter .xls',
    from_library: 'Depuis la bibliothèque',
    import_from_template_xls: 'Importer depuis un modèle .xls',
    import_from_library: 'Importer depuis la bibliothèque',
    import_from_trace: 'Depuis la trace',
    import_trace_xls: 'Importer un fichier de traces',
    items_library: 'Articles de la bibliothèque',
    download_xls_template: 'Télécharger le modèle',
    download_qr_code: 'Télécharger le QR code',
    back: 'Retour',
    cancel: 'Annuler',
    companies_detail: 'Détail des entreprises',
    companies_management: 'Gestion des fournisseurs',
    company_detail: 'Détail de l\'entreprise',
    company_management: 'Profil de l\'entreprise',
    confirm: 'Confirmer',
    downloads_management: 'Détail des téléchargements',
    next: 'Suivant',
    create: 'Créer',
    create_new: 'Créer nouveau',
    create_new_by_vat_code: 'Créer ou trouver avec le numéro de TVA/NIR',
    find_or_create_by_vatCode: 'Créer/Rechercher',
    generate: 'Générer',
    save_without_generate: 'Fermer',
    create_trace: 'Créer une trace',
    complete_trace: 'Compléter la trace',
    complete_all_trace: 'Compléter toutes les traces',
    dashboard: 'Accueil',
    delete: 'Supprimer',
    disable: 'Désactiver',
    disapprove: 'Désapprouver',
    all_disapprove: 'Tout désapprouver',
    documentation: 'Documentation',
    download_pdf: 'Télécharger le PDF',
    enable: 'Activer',
    export_trace_in_CSV: 'Exporter la trace au format CSV',
    forgot_pwd: 'Mot de passe oublié',
    fulfill: 'Satisfaire',
    invite: 'Inviter',
    invite_new: 'Inviter Nouveau',
    new: 'Nouveau',
    modify: 'Modifier',
    modify_library: 'Modifier la bibliothèque',
    login: 'Se connecter',
    login_now: 'Connectez-vous maintenant !',
    logout: 'Déconnexion',
    exit: 'Sortir',
    modify_trace: 'Modifier la trace',
    mark_as_read: 'Marquer comme lu',
    mark_as_unread: 'Marquer comme non lu',
    new_subsupplier: 'Nouveau sous-traitant',
    new_user: 'Nouveau utilisateur',
    onboarding: 'Intégration',
    open: 'Ouvrir',
    order_assignment: 'Attribution de la demande',
    order_detail: 'Détail de la demande',
    order_fulfillment: 'Exécution de la demande',
    order_handmade: 'Modifier la commande',
    order_handmade_new: 'Créer une demande',
    orders_report: 'Gestion des demandes',
    orders_report_dynamic: 'Gestion des',
    preview: 'Aperçu',
    customer_orders_report: 'Demande client',
    raw_material_source_orders_report: 'Demande client',
    profile_managment: 'Profil',
    proceed: 'Continuer',
    registration_submit: 'Enregistrer',
    save: 'Enregistrer',
    import_and_complete: 'Importer et Compléter',
    import_and_save_draft: 'Importer et Enregistrer le brouillon',
    select_file:'Sélection fichier',
    send: 'Envoyer',
    open_downloads: 'Téléchargements',
    import: 'Importer',
    import_library: 'Importer la bibliothèque',
    import_trace: 'Importer la trace',
    save_draft: 'Enregistrer le brouillon',
    send_by_email_pdf: 'Envoyer par e-mail le fichier PDF',
    subsuppliers: 'Sous-traitants',
    suspend: 'Suspendre',
    trace_detail: 'Détail de la trace',
    traces_report: 'Rapport des traces',
    users_detail: 'Détail de l\'utilisateur',
    users_management: 'Gestion des utilisateurs',
    view: 'Voir',
    show_logs: 'Événements',
    split: 'Diviser',
    remove: 'Supprimer',
    reset_filter: 'Réinitialiser le filtre',
    reset_password: 'Réinitialiser le mot de passe',
    hide_trace: 'Masquer la trace',
    show_trace: 'Afficher la trace',
    form_builder: 'Constructeur de formulaires',
    new_invitation: 'Nouvelle invitation',
    traceability_settings: 'Paramètres de traçabilité',
    all_notifications: 'Toutes les notifications',
    clone_order: 'Cloner la commande',
    delete_order: 'Supprimer la commande',
    mark_all_as_read: 'Tout marquer comme lu',
    view_all_notifications: 'Voir toutes les notifications',
    overrideExistingDataInput: 'Remplacer les données d\'entrée existantes dans la trace',
    more_unread: 'plus de non lus',
    override_existing_data_input_sub_title: 'La nouvelle trace remplacera toutes les données d\'entrée existantes',
    add_order: 'Créer une nouvelle commande',
    validation_test: 'Teste la validation',
    import_onboarding_from_xls: 'Importe',
    export_onboarding_template: 'Exporte template onboarding',
    download: 'Télécharger'
  },
  confirms: {
    add_user: 'Êtes-vous sûr de vouloir ajouter l\'utilisateur ?',
    cancel_subsupplier: 'Confirmez la suppression du sous-traitant',
    activate_subsupplier: 'Confirmez l\'activation du sous-traitant',
    confirm_operation: 'Êtes-vous sûr de vouloir finaliser l\'opération ?',
    confirm_or_go_back_to_change_the_data: 'Confirmez ou revenez en arrière pour modifier les données',
    delete_user: 'Êtes-vous sûr de vouloir supprimer l\'utilisateur ?',
    delete_users: 'Êtes-vous sûr de vouloir supprimer les utilisateurs ?',
    delete_certificate: 'Êtes-vous sûr de vouloir supprimer le certificat ?',
    invite_company: 'Vous êtes en train de créer une entreprise. Veuillez vérifier si le numéro de TVA existe déjà ou insérer un nouvel enregistrement.',
    delete_assignment: 'Êtes-vous sûr de vouloir supprimer la commande de l\'affectation ? Toutes les autres commandes avec le même numéro de commande parent seront également supprimées.',
    delete_order: 'Êtes-vous sûr de vouloir supprimer cette commande ?',
    reset_password: 'Êtes-vous sûr de vouloir réinitialiser le mot de passe ?',
    warning_on_company_create: 'La partita iva è già presente nella tua lista. Sei sicuro di voler continuare?',
    cancel_order: 'Êtes-vous sûr de vouloir bloquer cette commande?',
    unlock_order: 'Êtes-vous sûr de vouloir débloquer cette commande?'
  },
  errors: {
    no_file_selected: 'Chargez un fichier pour continuer',
    password_not_valid: 'Le mot de passe doit contenir entre 6 et 20 caractères, avec au moins un chiffre, une lettre majuscule et une lettre minuscule.',
    the_passwords_are_different: 'Les mots de passe ne correspondent pas',
    insert_company_code_or_piva: 'Vous devez spécifier au moins une valeur, soit le code de l\'entreprise, soit le numéro de TVA / NIF.',
    identifier_creation_failed: 'Échec de la création de l\'identifiant de trace',
    trace_creation_failed: 'Échec de la création de la trace',
    max_file_size_exceeded: 'La taille maximale du fichier a été dépassée',
    file_not_found: 'Fichier non trouvé',
    file_upload_failed: 'Échec du téléchargement du fichier',
    select_almost_one_reason: 'Sélectionnez au moins une raison'
  },
  network: {
    CO: 'Coton',
    WO: 'Laine',
    PL: 'Polyester',
    AF: 'Autres fibres',
    WD: 'Mohair super kid',
    WM: 'Mohair',
    NY: 'Nylon'
  },
  orderType: {
    BUY_FINISH_PRODUCT: 'Acheter',
    MAKE_RAW_MATERIAL: 'Fabriquer la matière première',
    MAKE_FINISH_PRODUCT: 'Fabriquer le produit',
    SINGLE_MATERIAL: 'Matériau unique'
  },
  fields: {
    productMatchCode: 'Code de correspondance du produit',
    supplier: 'Fournisseur',
    orderType: 'Type de commande',
    enquiryId: 'ID de la demande',
    firstname: 'Prénom',
    lastname: 'Nom de famille',
    inviteCompany: 'Inviter le fournisseur',
    action: 'Action',
    actions: 'Actions',
    address: 'Adresse',
    address_number: 'Numéro',
    adherence_ethics_code: 'Adhésion au code éthique',
    assignee_company: 'Entreprise assignée',
    business_name: 'Raison sociale',
    legal_code: 'Numéro de TVA',
    category: 'Catégorie',
    categories: 'Catégories',
    certifications: 'Certifications',
    city: 'Ville',
    certificate_number: 'Numéro de certificat',
    code: 'Code',
    collector: 'Collecteur',
    color: 'Couleur',
    color_code: 'Code couleur',
    company: 'Entreprise',
    company_datas: 'Données de l\'entreprise',
    company_name: 'Nom de l\'entreprise',
    company_name_vat: 'Nom de l\'entreprise - TVA',
    company_type: 'Type d\'entreprise',
    washington_attestation_data: 'Données de certification de Washington',
    country: 'Pays',
    creation_company: 'Fournisseur',
    last_login_date: 'Date de dernière connexion',
    creation_date: 'Date de création',
    destination: 'Destination',
    document_expiration_date: 'Date d\'expiration du document',
    document_item_article_name: 'Nom de l\'article',
    document_item_name: 'Nom',
    document_number: 'Numéro du document',
    documents_and_certifications: 'Documents et certifications',
    documents_for_nutrition: 'Documents pour la nutrition',
    document_type: 'Type de document',
    email: 'Email',
    email_address: 'Adresse e-mail',
    email_pec: 'E-mail PEC',
    error_validation: 'Erreur de validation',
    es_10: 'par exemple 10',
    expiration_date: 'Date d\'expiration',
    farmer: 'Éleveur',
    feeding_documents: 'Documents pour l\'alimentation',
    file: 'Fichier',
    filename: 'Nom du fichier',
    health_certificate: 'Certificat de santé',
    hunter: 'Chasseur',
    id: 'ID',
    intermediary_role: 'Rôle d\'intermédiaire',
    insert_reference_data: 'Insérer les données de référence',
    insert_file_name: 'Insérer le nom du fichier',
    history: 'Historique',
    library_item_name: 'Nom de l\'article de bibliothèque',
    label: 'Étiquette',
    last_modify: 'Dernière modification',
    license_concession: 'Licence / Concessions',
    load_certificates: 'Charger les documents',
    load_scan_certificates: 'Charger les documents numérisés',
    load_ethic_certificate: 'Charger le code éthique',
    load_here: 'Cliquez ou faites glisser le fichier dans cette zone pour le charger',
    lot: 'Lot',
    not_matching_farmer: 'Agriculteurs non correspondants',
    not_matching_slaughter: 'Abattages non correspondants',
    manual: 'Manuels',
    product: 'Description du matériau et code',
    product_code: 'Code du matériau',
    product_composition: 'Composition du matériau',
    product_description: 'Description du matériau',
    rejected: 'Rejetées',
    excel_row_counter: 'Nombre de lignes Excel lues',
    updated_enquiry_counter: 'Nombre de demandes mises à jour',
    season: 'Saison',
    shared: 'Partagé',
    shared_with_brand: 'Partagé avec la marque',
    splitted: 'Divisé',
    event: 'Événement',
    membership_association: 'Association d\'adhésion',
    message: 'Message',
    name: 'Nom',
    name_and_surname: 'Nom et prénom',
    order_fiber: 'Composant',
    order_networks: 'Chaîne d\'approvisionnement',
    status: 'Statut',
    order_step: 'Étape de la commande',
    other_certificates: 'Autres certificats',
    password: 'Mot de passe',
    password_confirm: 'Confirmer le mot de passe',
    phone: 'Téléphone',
    phone_number: 'Numéro de téléphone',
    piva_cf: 'N° TVA / N° SIRET',
    postal_code: 'Code postal',
    purchase_order: 'Commande d\'achat',
    parent_order_code: 'Code de commande parent',
    purchase_order_code: 'Code de commande d\'achat',
    purchase_order_date: 'Date de la commande d\'achat',
    purchase_order_type: 'Type de commande d\'achat',
    purchase_date: 'Date d\'achat',
    quantity: 'Quantité',
    quantity_allowed: 'Quantité autorisée',
    referent: 'Référent',
    t1_filter: 'Membres de l\'équipe',
    sent_date: 'Date d\'envoi',
    slaughterhouse: 'Abattoir',
    style_code: 'Code de style',
    style_description: 'Description du style',
    subsupplier_name: 'Nom du sous-traitant',
    suppression_method: 'Méthode de suppression (facultatif : indiquer la législation applicable)',
    surname: 'Nom de famille',
    tannery: 'Tannerie',
    territory_of_competence: 'Territoire de compétence',
    trace_identifier: 'Identifiant de traçabilité',
    trace_type: 'Type de traçabilité',
    type: 'Type',
    typology: 'Typologie',
    upload_date: 'Date de téléchargement',
    user: 'Utilisateur',
    username: 'Nom d\'utilisateur',
    veterinary: 'Vétérinaire',
    previous_traces: 'Traces précédentes',
    existing_company: 'Entreprise existante',
    existing_trace: 'Traçabilité existante',
    new_trace_identifier: 'Nouvel identifiant de traçabilité',
    company_code: 'Code de l\'entreprise',
    notification_filter: 'Recherche',
    or: 'Ou',
    personal: 'Personnel',
    third_part: 'Compte tiers',
    new_id: 'Nouvel ID',
    existing_id: 'ID existant',
    supplyChainName: 'Nom du composant',
    supplyChainFiber: 'Indiquer les codes des composants',
    activate: 'Activer',
    start_date: 'Date de début',
    end_date: 'Date de fin',
    rule_description: 'Description de la règle',
    property: 'Propriété',
    value: 'Valeur',
    data: 'Données',
    date: 'Date',
    material_description: 'Description du matériau',
    material_code: 'Code du matériau',
    approve_order_modal_title: 'Approuver la commande',
    approve_orders_modal_title: 'Approuver les commandes',
    approve_order_modal_body: 'Voulez-vous approuver la commande ?',
    approve_order_with_children_modal_body: 'Voulez-vous approuver la commande?<br> En procédant, toutes les demandes enfants complètes seront également approuvées.',
    approve_orders_modal_body: 'Voulez-vous approuver <b>{{count}}</b> commandes ?',
    reopen_order_modal_title: 'Réouvrir la demande',
    reopen_orders_modal_title: 'Réouvrir {{count}} demandes',
    reopen_order_modal_body: 'Voulez-vous rouvrir la demande ?',
    reopen_orders_modal_body: 'Voulez-vous rouvrir <b>{{count}}</b> demandes ?',
    reject_order_modal_title: 'Vous êtes en train de refuser une demande<br> Veuillez laisser un commentaire',
    reject_orders_modal_title: 'Vous êtes en train de refuser {{count}} demandes<br> Veuillez laisser un commentaire',
    reject_order_modal_body: 'Saisissez ici votre commentaire (maximum 400 caractères)',
    reject_orders_modal_body: 'Saisissez ici votre commentaire (maximum 400 caractères)',
    delete_order_modal_title: 'Suppression de la commande',
    delete_order_modal_body: 'Voulez-vous supprimer cette commande ?',
    disapprove_order_modal_title: 'Désapprouver la demande',
    disapprove_orders_modal_title: 'Désapprouver les demandes',
    disapprove_order_modal_body: 'Vous êtes en train de désapprouver la demande ?',
    disapprove_orders_modal_body: 'Vous êtes en train de désapprouver <b>{{count}}</b> demandes ?',
    confirmModalTitle: 'Confirmer',
    confirmModalBody: 'Vous êtes sur le point d\'envoyer la traçabilité, vous ne pourrez plus la modifier par la suite. Confirmez-vous l\'envoi ?',
    confirmModalButton: 'Envoyer maintenant',
    cancelModalButton: 'Continuer la modification',
    remove_library_title: 'Supprimer l\'élément',
    remove_library: 'Vous êtes sur le point de supprimer cet élément, confirmez-vous ?',
    productionCity: 'Ville de production',
    count_to_do: 'À faire',
    count_rejected: 'Rejetées',
    inviteCompanyAndUser: 'Inviter l\'entreprise et l\'utilisateur par onboarding',
    addCompany: 'Ajouter une entreprise',
    addCompanyAndUser: 'ajouter une nouvelle entreprise et un utilisateur. Le nouvel utilisateur remplira les données sur la plateforme pour le compte de l\'entreprise ajoutée',
    addCompanyNoAccess: 'ajouter une nouvelle entreprise sans aucun accès à la plateforme. Les utilisateurs actuels rempliront les données pour le compte de la nouvelle entreprise',
    createdAt: 'Créé le:',
    updatedAt: 'Mis à jour le:',
    new: 'NOUVEAU',
    inProgress: 'EN COURS',
    inError: 'EN ERREUR',
    completed: 'COMPLÉTÉ',
    unknownState: 'ÉTAT INCONNU',
    withoutUsers: 'Sans utilisateurs',
    neverLoggedUsers: 'Utilisateurs jamais connectés',
    suspended: 'Suspendu'
  },
  messages: {
    cloned: '[Cloné] ',
    account_login: 'Connectez-vous à votre compte',
    complete_order_by_bulk_title: 'Résultat de la complétion',
    complete_order_by_bulk_body: 'Demandes <b>complétées</b>: {{completedEnquiries}}<br>Demandes <b>non validées</b>: {{notValidEnquiries}}<br>',
    company_added: 'Entreprise ajoutée',
    company_confirmed: 'Entreprise confirmée',
    company_datas_updated: 'Données de l\'entreprise mises à jour',
    company_disabled: 'Entreprise désactivée',
    company_enabled: 'Entreprise activée',
    confirm_or_go_back: 'Confirmez ou retournez en arrière pour vérifier les données',
    field_required: 'Champ requis',
    email_sent: 'E-mail envoyé',
    order_assignment_error: 'Une erreur est survenue lors de l\'attribution de la commande',
    order_assignment_success: 'Votre demande a été attribuée',
    order_assignment_draft: 'Votre commande d\'achat a été enregistrée',
    order_fulfillment_error: 'Une erreur est survenue lors de l\'envoi de votre demande',
    order_fulfillment_success: 'Votre demande a été envoyée',
    order_fulfillment_draft: 'Votre demande a été enregistrée',
    order_complete_success: 'Votre demande a été complétée',
    order_remove_success: 'Votre demande a été supprimée',
    order_reopen_success: 'Votre demande a été réouverte',
    orders_reopen_success: 'Vos demandes ont été réouvertes',
    order_reject_success: 'Votre demande a été refusée',
    orders_reject_success: 'Vos demandes ont été refusées',
    order_approve_success: 'Votre demande a été approuvée',
    orders_approve_success: 'Vos demandes ont été approuvées',
    nothing_to_approve: 'Aucune trace à approuver',
    orders_saved: 'Votre demande a été enregistrée',
    order_saved_draft_with_errors: 'Votre demande a été enregistrée en tant que brouillon avec des erreurs de validation',
    password_required: 'Mot de passe requis',
    subsupplier_deleted: 'Sous-traitant supprimé',
    subsupplier_disabled: 'Sous-traitant désactivé',
    subsupplier_enabled: 'Sous-traitant activé',
    successfully_registered: 'Votre enregistrement a été effectué avec succès.',
    successfully_registered_email: 'Vous recevrez une confirmation dans votre boîte de réception.',
    successfully_import_trace: 'Votre traçabilité a été importée',
    successfully_import_library: 'Votre bibliothèque a été importée',
    user_added: 'Utilisateur ajouté',
    user_confirmed: 'Utilisateur confirmé',
    user_datas_updated: 'Données utilisateur mises à jour',
    user_deleted: 'Utilisateur supprimé',
    user_disabled: 'Utilisateur désactivé',
    user_enabled: 'Utilisateur activé',
    user_updated: 'Utilisateur mis à jour',
    username_required: 'Nom d\'utilisateur requis',
    users_deleted: 'Utilisateurs supprimés',
    no_certificate_found: 'Certificat non trouvé',
    no_data_default: 'Aucun élément',
    no_trace_found: 'Aucune traçabilité trouvée',
    no_order_found: 'Aucune commande trouvée',
    invitation_successfully_created: 'Invitation créée avec succès',
    onboarding_approved: 'Onboarding approuvé !',
    onboarding_business_name_already_exist_on_create: 'Il n\'est pas possible d\'ajouter ce fournisseur car il existe déjà un fournisseur avec le même nom d\'entreprise.. Si vous souhaitez l\'ajouter aux favoris, utilisez la section "Gestion des fournisseurs".',
    onboarding_company_exists: 'L\'entreprise existe déjà',
    onboarding_user_already_exist: 'Il existe déjà un fournisseur avec le même email.',
    onboarding_company_already_exist: 'Il existe déjà un fournisseur avec le même code TVA.',
    onboarding_byVatCode_company_already_exist: 'Ce numéro de TVA est déjà enregistré, veuillez vérifier les informations que vous avez fournies.',
    onboarding_business_name_already_exist_on_update: 'Esiste già un fornitore con la stessa ragione sociale.',
    business_name_cannot_be_identical_to_yours: 'Le nom de l\'entreprise ne peut pas être identique au vôtre',
    profile_in_revision: 'Profil en cours de révision',
    email_invitation_sent: 'L\'invitation pour l\'onboarding a été renvoyée',
    onboarding_loaded: 'Onboarding créé avec succès',
    attachment_loaded: 'Pièce jointe chargée : ',
    select_trace_identifier_to_continue: 'Sélectionnez un identifiant de traçabilité pour continuer',
    create_trace_expired_certifications: 'Au moins l\'un de vos certificats a expiré. Vous pouvez néanmoins terminer cette opération, mais le système en tiendra compte.',
    create_trace_subsupplier_expired_certifications: 'Au moins un des certificats de votre sous-traitant a expiré. Vous pouvez néanmoins terminer cette opération, mais le système en tiendra compte.',
    new_id_tooltip: 'Permet de créer une traçabilité qui peut être liée à d\'autres traçabilités existantes dans le système. L\'identifiant de traçabilité à lier peut être recherché dans le menu déroulant "Traçabilités liées".',
    existing_id_tooltip: 'Permet de mettre à jour les informations d\'une traçabilité déjà existante dans le système. L\'identifiant de traçabilité peut être recherché dans le menu déroulant.',
    trace_created: 'Traçabilité créée !',
    certificate_deleted: 'Certificat supprimé',
    supply_chain_saved: 'Données de la chaîne d\'approvisionnement enregistrées avec succès',
    rule_saved: 'La nouvelle règle a été enregistrée avec succès',
    new_item: 'Nouvel élément',
    new_item_saved: 'Le nouvel élément a été enregistré avec succès',
    item_saved_title: 'Enregistrement de l\'élément',
    item_saved: 'L\'élément a été mis à jour avec succès',
    import_onboarding_from_xls_error_count_max_line: 'Le fichier a plus de 5 lignes',
    error: 'Erreur'
  },
  category: {
    BREEDER: 'Éleveur',
    HUNTER: 'Chasseur',
    TANNERY: 'Tannerie',
    SLAUGHTERHOUSE: 'Abattoir',
    FARMER: 'Agriculteur',
    TRADER: 'Négociant',
    PICKER: 'Cueilleur',
    VETERINARY: 'Vétérinaire',
    MANUFACTURER: 'Fabricant',
    PROCESSOR: 'Transformateur',
    WEAVER: 'Tisserand',
    SPINNER: 'Fileur',
    GINNER: 'Ginner',
    RAW_MATERIAL_SOURCE: 'Source de matières premières',
    SCOURER: 'Fouineur',
    TRASPORTATORE: 'Transporteur',
    LAVANDERIA: 'Blanchisserie',
    FORNITORE: 'Fournisseur',
    TESSITORE: 'Tisseur',
    MANIFATTORE: 'Fabricant',
    FILATORE: 'Fileur',
    SMINUZZATORE: 'Broyeur'
  },
  misc: {
    today: 'Aujourd\'hui',
    previous: 'Précédent',
    thisWeek: 'Cette semaine',
    minutesAgo: 'minutes auparavant',
    hoursAgo: 'heures auparavant',
    daysAgo: 'jours auparavant',
    monthsAgo: 'mois auparavant',
    active: 'Actif',
    all_orders: 'Toutes les demandes',
    assigned: 'Assigné',
    attached_max_sixe: 'Max 10MB (pdf, jpg, word, eps)',
    cancelled: 'Annulé',
    completed: 'Complété',
    approved: 'Approuvé',
    choose_rejects_reasons: 'Choisissez la/les raison(s) du refus',
    create_new_document: 'Créer un nouveau document',
    create_new_library_item: 'Créer un nouvel article de bibliothèque',
    confirmed: 'Confirmé',
    congratulations: 'Félicitations',
    delete_selected: 'Supprimer la sélection',
    deselect: 'Désélectionner',
    draft: 'Brouillon',
    new: 'Nouveau',
    expiration: 'Expiration',
    expired: 'Expiré',
    expiring: 'En cours d\'expiration',
    error_change_current_user: 'La société a été changée, la page se rechargera dans 10 secondes avec l\'autre société',
    free_search: 'Recherche libre',
    fulfilled: 'Réalisé',
    id_lot_etc: 'ID, Lot, etc.',
    insert: 'Insérer',
    logo: 'Logo',
    no: 'Non',
    personal_datas: 'Données personnelles',
    powered_by: 'propulsé par',
    remember_me: 'Se souvenir de moi',
    select: 'Sélectionner',
    select_all: 'Tout sélectionner',
    select_an_option_and_proceed: 'Sélectionnez une option et procédez',
    select_the_type_of_track: 'Sélectionnez le type de traçabilité',
    select_or_insert_new: 'Sélectionnez ou insérez un nouveau',
    select_scope_certificate: 'Sélectionnez un fichier PDF, JPG, Word ou EPS (Max 5 Mo)',
    select_trace_order: 'Sélectionnez un fichier xlsx (Max 5 Mo)',
    show_selected: 'Afficher la sélection',
    supplier: 'Fournisseur',
    temera_com: 'temera.com',
    to_be_assigned: 'À attribuer',
    users: 'Utilisateurs',
    valid: 'Valide',
    write: 'Écrire',
    yes: 'Oui',
    username_name_email: 'Nom d\'utilisateur, nom, e-mail, etc.',
    start: 'Début',
    end: 'Fin',
    role: 'Rôle',
    subsupplier: 'Sous-fournisseur',
    representative: 'Représentant',
    business_name_email_etc: 'Raison sociale, e-mail, etc.',
    invited: 'Invités',
    sent: 'Envoyés',
    disabled: 'Désactivés',
    to_confirm: 'À confirmer',
    or: 'Ou',
    personal: 'Personnel',
    third_part: 'Tiers',
    expired_certifications: 'Certifications expirées',
    warning: 'Avertissement',
    click_to_download: 'Cliquez pour télécharger le certificat',
    download_file: 'Télécharger le fichier',
    show_filtered: 'Afficher les filtrés',
    show_all: 'Tout afficher',
    loading: 'Chargement...',
    error: 'Erreur',
    empty: 'Vide',
    insertSupplierMsg: 'Entrez les informations du fournisseur et de la demande',
    main_configurations: 'Configurations principales',
    main_configurations_title: 'Configuration de la chaîne d\'approvisionnement',
    main_configurations_subtitle: 'Dans cette section, vous pouvez gérer la configuration principale de la chaîne d\'approvisionnement t!Care',
    style_traceability: 'Traçabilité du style',
    style_traceability_title: 'Traçabilité du style',
    style_traceability_subtitle: 'Dans cette section, vous pouvez créer différentes exceptions pour choisir ce que vous souhaitez tracer',
    new_fiber: 'Nouveau composant',
    edit_fiber: 'Modifier le composant',
    edit_attributes: 'Modifier les attributs',
    add_fiber: '+ Ajouter un composant',
    add_step: 'Ajouter une étape',
    add_step_selected: 'Ajouter les étapes sélectionnées',
    modal_step_title: 'Ajouter de nouvelles étapes à la chaîne d\'approvisionnement',
    search: 'Rechercher',
    create_new_rule: 'Créer une nouvelle règle',
    add_new_property: 'Ajouter une nouvelle propriété',
    rules_active: 'Actives',
    rules_archived: 'Archivées',
    to: 'à',
    archive_rule_title: 'Archiver la règle',
    archive_rule_subtitle: 'Êtes-vous sûr de vouloir archiver cette règle ?',
    reopen_rule_title: 'Réactiver la règle',
    reopen_rule_subtitle: 'Êtes-vous sûr de vouloir réactiver cette règle ?',
    delete_rule_title: 'Supprimer la règle',
    delete_rule_subtitle: 'Êtes-vous sûr de vouloir supprimer cette règle ?',
    modal_detail_rule_title: 'Détail de la règle',
    supply_chain_network: 'Réseau de la chaîne d\'approvisionnement',
    remove_step_title: 'Supprimer l\'étape',
    remove_step_subtitle: 'Êtes-vous sûr de vouloir supprimer cette étape ?',
    remove_supply_chain_title: 'Supprimer la chaîne d\'approvisionnement',
    remove_supply_chain_subtitle: 'Êtes-vous sûr de vouloir supprimer cette chaîne d\'approvisionnement ?',
    seasonEvents: 'Événement saisonnier',
    styleCodes: 'Codes de style',
    companyIds: 'Nom du fournisseur',
    percentage: 'Pourcentage',
    fiberCodes: 'Composants spécifiques',
    supplyChains: 'Chaînes d\'approvisionnement',
    traceTypeIds: 'Étapes',
    chooseSupplier: 'Choisissez le fournisseur',
    insertPurchaseOrderAndDate: 'Insérez les codes de commande et les dates d\'achat',
    insert_new_element: 'Insérer un nouvel élément'
  },
  steps: {
    trace_data: 'Données de la trace',
    complete_trace: 'Trace complète',
    confirm_data: 'Confirmez les données',
    fill_form: 'Remplissez tous les champs',
    new_company: 'Nouvelle entreprise',
    company: 'Entreprise',
    user: 'Utilisateur',
    new_user: 'Nouvel utilisateur',
    summary: 'Résumé'
  },
  titles: {
    split: 'Gestion des splits',
    library_item_section_main: 'Détails de l\'article',
    library_item_section_new_main: 'Données de référence',
    library_item_section_data: 'Données de la trace',
    actions: 'Actions',
    attach_document: 'Joindre un document',
    companies_to_confirm: 'Entreprises à confirmer',
    company_data: 'Données de l\'entreprise',
    company_profile: 'Profil de l\'entreprise',
    company: 'Entreprise',
    create_personal_trace: 'Créer votre propre trace',
    create_third_part_trace: 'Vous créez une trace pour le compte de ',
    document_item_section_main: 'Données de référence',
    document_item_section_new_main: 'Insérer les données de la référence',
    document_item_section_data: 'Joindre un document',
    downloads: 'Téléchargements',
    order_assignment_error: 'Erreur',
    order_assignment_success: 'Succès',
    order_fulfillment_error: 'Erreur',
    order_fulfillment_success: 'Succès',
    order_complete_success: 'Succès',
    purchase_order_details: 'Détails de la commande d\'achat',
    purchase_order_fulfillment: 'Données de la trace',
    scope_certificates: 'Certificats de portée',
    scope_certificate: 'Certificat de portée',
    success: 'Succès',
    trace_identifier: 'Identifiant de la trace',
    trace_informations: 'Informations de la trace',
    trace_report: 'Rapport de la trace',
    users: 'Utilisateurs',
    notification: 'Notification',
    notificationTitle: 'Notifications',
    import_trace_dialog: 'Sélectionnez la commande à partir de laquelle dupliquer la trace',
    import_library_dialog: 'Sélectionnez l\'élément de la bibliothèque pour dupliquer les données de la trace',
    insert_email: 'Entrez l\'adresse e-mail',
    new_library_dialog: 'Sélectionnez le type d\'article à créer'
  },
  error: {
    splitOriginalOrderCompleted: 'Je ne peux pas scinder la commande car l\'originale est terminée !',
    forbidden: 'Vous n\'avez pas les autorisations requises pour accéder à cette ressource',
    not_found: 'La ressource demandée n\'existe pas',
    unauthorized: 'Authentification échouée. Veuillez vous connecter et réessayer',
    missing_trace_identifier: 'Identifiant manquant',
    identifier_already_existing: 'Cet identifiant existe déjà',
    generic_be_error: 'Quelque chose n\'a pas fonctionné, veuillez réessayer plus tard ou contacter l\'administrateur',
    supply_chain_steps: 'Pour continuer, vous devez sélectionner au moins une étape',
    user_unauthorized_operation: 'Vous n\'êtes pas autorisé à effectuer cette opération',
  },
  success: {
    export_xls_title: 'Exportation',
    export_xls: 'L\'exportation a été prise en charge ; vous recevrez la notification sous peu.'
  },
  warnings: {
    feedback_reject: 'Cette trace a été refusée, lisez le feedback :'
  },
  user_not_found: {
    login_failed: 'Échec de la connexion',
    user_does_not_exist: 'Le système ne parvient pas à trouver votre compte.',
    user_not_found_title: 'L\'authentification peut échouer pour plusieurs raisons :',
    user_not_found_reason1: 'Le compte n\'est pas correctement activé.',
    user_not_found_reason2: 'Les informations d\'identification ne sont pas présentes dans le système.',
    user_not_found_contact_admin: 'Veuillez contacter l\'administrateur système.'
  },
  tags: {
    split: 'Diviser',
    rejected: 'Rejeté',
    handmade: 'Manuel',
    errorValidation: 'Non valide'
  },
  orderLog: {
    IMPORT: 'Demande <b>Importée</b>',
    SPLIT_NEW_ROW: '{{user}} a <b>divisé</b> la demande en quantité de {{quantity}}',
    SPLIT_UPDATE: '{{user}} a <b>mis à jour</b> la quantité en {{quantity}}',
    REJECT: '{{user}} a <b>rejeté</b> la demande<div class="order_log_citation">{{ message }}</div>',
    ASSIGN_CHILD: '{{user}} a <b>assigné</b> la demande enfant',
    ASSIGNED: '{{user}} a <b>assigné</b> la demande à {{company}}',
    DRAFT: '{{user}} a <b>enregistré comme brouillon</b> la demande',
    FULFILL: '{{user}} a <b>rempli</b> la demande',
    FULFILL_THIRD_PARTY: '{{user}} a <b>rempli</b> la demande',
    COMPLETE: '{{user}} a <b>complété</b> la demande',
    CANCEL: '{{user}} a <b>annulé</b> la demande',
    APPROVE: '{{user}} a <b>approuvé</b> la demande',
    REOPEN: '{{user}} a <b>réouvert</b> la demande',
    DISAPPROVE: '{{user}} a <b>désapprouvé</b> la demande'
  },
  bulkApproveDialog: {
    all_order_approved: 'Tous les {{approved}} demandes ont été approuvés',
    title: 'Approbation en Masse',
    approved: ' commandes approuvées le ',
    notApproved: ' commandes non approuvées, car les enfants ne sont pas',
    approved_key: 'APPROUVÉ',
    forceApproveQuestion: 'Voulez-vous forcer l approbation (et de leurs enfants)?'
  }
};

export default labels;
