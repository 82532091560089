import React, { Component } from 'react'
import { __, T } from 'config/i18n'
import { Modal, notification } from 'antd'
import { ModalHeader, AdvancedForm } from 'components'
import { Rule, RuleForm, Properties } from 'api/types'
import theme from 'theme/theme'
import { Schema } from 'components/AdvancedForm'
import { FormInstance } from 'antd/es/form'


interface Props {
  visible: boolean
  closed: () => void
  confirm: (rule: Rule) => void
  properties: Properties[]
}

interface State {
  rule: RuleForm
  formSchema?: Schema[]
  indexRules: number
}

export default class AddRuleModal extends Component<Props> {
  formRef = React.createRef<FormInstance>()

  state: State = {
    rule: {
      name: '',
      startDate: '',
      endDate: '',
      exceptions: [
        {
          index: 0,
        },
      ],
      exception0: {
        property: undefined,
        values: undefined,
      },
    },
    formSchema: [],
    indexRules: 0,
  }

  componentDidMount = () => {
    this._init()
  }

  shouldComponentUpdate = nextProps => {
    const { visible } = this.props
    if (nextProps.visible !== visible && nextProps.visible) {
      this.setState({
        rule: {
          name: '',
          startDate: '',
          endDate: '',
          exceptions: [
            {
              index: 0,
            },
          ],
          exception0: {
            property: undefined,
            values: undefined,
          },
        },
      })
    }
    return true
  }

  _init = async () => {
    this.setState({
      formSchema: [
        {
          name: 'name',
          type: 'text',
          label: __(T.fields.rule_description),
          placeholder: __(T.misc.write),
          col: { xs: 24 },
          rules: [{ required: true }],
        },
        {
          name: 'startDate',
          type: 'date',
          label: __(T.fields.start_date),
          placeholder: __(T.misc.write),
          col: { xs: 24, sm: 12 },
          rules: [{ required: true }],
        },
        {
          name: 'endDate',
          type: 'date',
          label: __(T.fields.end_date),
          placeholder: __(T.misc.write),
          col: { xs: 24, sm: 12 },
          rules: [{ required: true }],
        },
        {
          name: 'properties-rule',
          type: 'properties-rule',
          notInput: true,
        },
      ],
    })
  }

  addException = () => {
    const indexRules = this.state.indexRules + 1
    let rule = this.state.rule
    rule.exceptions.push({ index: indexRules })
    rule['exception' + indexRules] = {
      property: undefined,
      values: undefined,
    }
    this.setState({ rule: rule, indexRules: indexRules })
  }

  removeException = (indexRule: number) => {
    let rule = this.state.rule
    if (rule.exceptions.length > 0) {
      const index = rule.exceptions.findIndex(object => object.index === indexRule)
      if (index !== -1) {
        rule['exception' + indexRule].deleted = true
        rule.exceptions.splice(index, 1)
        this.setState({ rule: rule })
      }
    }
  }

  check = () => {
    const { confirm } = this.props
    const { rule } = this.state
    if (rule.exceptions.length === 0) {
      notification.error({
        message: __(T.error.supply_chain_steps),
        placement: 'bottomRight',
        duration: 3,
      })
    } else {
      let ruleToSave: Rule = {
        name: rule.name,
        startDate: rule.startDate,
        endDate: rule.endDate,
        exceptions: [],
      }
      const keys = Object.keys(rule)
      for (let k = 0; k < keys.length; k++) {
        if (keys[k].indexOf('exception') !== -1 && keys[k].indexOf('exceptions') === -1 && rule[keys[k]].property) {
          ruleToSave.exceptions.push({
            property: rule[keys[k]].property,
            values: rule[keys[k]].values,
          })
        }
      }
      confirm(ruleToSave)
    }
  }

  onValuesChange = (changedValues: any, values: Rule) => {
    const { properties } = this.props
    const keys = Object.keys(changedValues)
    for (let k = 0; k < keys.length; k++) {
      if (keys[k].indexOf('exception') !== -1 && changedValues[keys[k]].property) {
        const obj = properties.find(object => object.value === changedValues[keys[k]].property)
        values[keys[k]].values = obj && obj.type === 'NUMBER' ? 0 : undefined
      }
    }
    this.setState({ rule: values })
  }

  render = () => {
    const { visible, closed, properties } = this.props
    const { rule, formSchema } = this.state
    return (
      <Modal
        title={<ModalHeader close={closed} title={__(T.misc.create_new_rule)} />}
        centered
        visible={visible}
        footer={null}
        closable={false}
        onCancel={() => closed()}
        width="60%"
        className={'sc-modal-rule'}
      >
        <AdvancedForm
          formRef={this.formRef}
          schema={formSchema || []}
          values={rule}
          onValuesChange={this.onValuesChange}
          buttonJustify="center"
          submitButton={{
            text: __(T.buttons.save),
            onPress: () => {
              this.check()
            },
            col: 5,
            style: theme.temeraPrimaryGreenButton,
          }}
          style={{ flex: 1 }}
          addException={this.addException}
          removeElement={this.removeException}
          properties={properties}
          uniqueProperty={true}
        />
      </Modal>
    )
  }
}
