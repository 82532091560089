import React from 'react';
import {Layout, Text, View} from 'components';
import {navigate} from 'router';
import {__, T} from 'config/i18n';
import {Card} from 'antd';
import { getUserRole, isUserConfirmed } from 'helpers/permission';
import AppStore from 'AppStore';
import {getDefaultMenu, isAnExternalLink, mapMenuConfiguration} from "../helpers/menuHelper";
import Contactors from '../components/contactors/contactors';
import {BaseMenuConfiguration} from "../api/types";
import { orderTools } from '../tools/OrderTools';

const Dashboard: React.FC = () => {
    AppStore.setGoToDashboard(false);

    const configurations = AppStore.appSettings.menuConfiguration ?
        mapMenuConfiguration(JSON.parse(AppStore.appSettings.menuConfiguration)) :
        getDefaultMenu();

    const getParams = (url: string, params: {} = {}): {} => {
        const type: string | undefined = url.split('/').pop();
        const extraFiltersSetting = AppStore.appSettings['orderListExtraFilters.' + type];
         const extraFilters = JSON.parse(extraFiltersSetting);

        orderTools.addDisableRootFilterOption(type, params);


        Object.keys(extraFilters).forEach(key => {
      if (typeof extraFilters[key] === 'string' && !!AppStore.loggedUser?.company && !!AppStore.loggedUser?.company?.id) {
          extraFilters[key] = extraFilters[key].replace(/\$currentCompanyId/g, AppStore.loggedUser?.company?.id);
      }
      params[key] = extraFilters[key];
    });
            return params;
    }

    const isActiveCounter = (url: string, type: string): boolean => {
        return !!url && !!type && type === 'DYNAMIC_ENQUIRY_MANAGEMENT' && AppStore.appSettings.enableDashBoardCounter;
    }

    const navigateTo = (conf: BaseMenuConfiguration) => {
        if (isAnExternalLink(conf)) {
            window.open(conf.url, '_blank', 'noreferrer')
        } else {
            navigate(conf.url)
        }
    }

    return (
        <Layout>
            <View
                style={{
                    flexDirection: 'row',
                    flexWrap: 'wrap',
                    justifyContent: 'flex-start',
                    flex: 1,
                    display: 'flex',
                    marginBottom: 50,
                    marginRight: 30
                }}
            >
                {configurations
                    .filter(conf => conf.visibleInDashboard)
                    .filter(conf => {
                        const userRole = getUserRole();
                        return userRole ? conf.roles.includes(userRole) : false;
                    })
                    .filter(conf => {
                        if (isUserConfirmed()) {
                            return true;
                        }
                        switch (conf.type) {
                            case "HOME":
                            case "ANALYTICS":
                            case "DOWNLOADS_MANAGEMENT":
                            case "COMPANY_REPORT":
                                return true;
                            default:
                                return false;
                        }
                    })
                    .map((conf, index) =>
                        <Card hoverable style={c.card} onClick={() => navigateTo(conf)} key={conf.type + '-' + index}>
                            {conf.iconDashboard &&
                                <img src={require(`../${conf.iconDashboard!}`).default} alt={__(T.misc.logo)} style={c.image}/>
                            }
                            <div style={c.textContainer}>
                            <Text style={c.text}>{__(conf.label)}</Text>
                            </div>
                            {isActiveCounter(conf.url, conf.type) &&
                                <div style={c.contactorsContainer}>
                                    <Card style={{...c.contactors}}>
                                        {__(T.fields.count_to_do)}: <Contactors
                                        params={getParams(conf.url, {status: ['TO_BE_ASSIGNED', 'ASSIGNED', 'DRAFT']})}/>
                                    </Card>
                                    <Card style={c.contactors}>
                                        {__(T.fields.count_rejected)}: <Contactors
                                        params={
                      getParams(
                        conf.url, {status: ['TO_BE_ASSIGNED','DRAFT'], 'rejected': true})}/>
                                    </Card>
                                </div>
                            }
                        </Card>
                    )}

                <View style={c.emptyCard}/>
                <View style={c.emptyCard}/>
                <View style={c.emptyCard}/>
            </View>
        </Layout>
    );
};

export default Dashboard;

const c: Record<string, React.CSSProperties> = {
    card: {
        margin: 30,
        display: 'flex',
        flexDirection: 'column',
        borderRadius: '5px',
        boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
        justifyContent: 'center',
        alignItems: 'center',
        flex: 1,
        minWidth: 400,
        height: 300,
        marginBottom: 0,
        marginRight: 0
    },
    emptyCard: {
        flex: 1,
        margin: 15,
        minWidth: 400
    },
    textContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flex: 1,
        width: '100%'
    },
    text: {
        fontSize: 30,
        fontWeight: 600,
        color: '#000000',
        textAlign: 'center'
    },
    image: {
        width: 70,
        height: 70,
        display: 'block',
        marginLeft: 'auto',
        marginRight: 'auto'
    },
    contactorsContainer: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%'
    },
    contactors: {
        flexGrow: 1,
        height: 50,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: 20,
        textAlign: 'center',
        margin: '0 10px', // Aggiungi spazio tra i card
        borderRadius: '10px', // Angoli smussati
        // border: '1px solid #000' // Bordo più scuro
    }
};
