import React, { Component } from 'react'
import { Typography, Col } from 'antd'
import moment from 'moment'
import { View } from 'components'

interface Props {
  label: string
  value: any
  type: 'text' | 'date' | 'array'
  col?: number
  last?: boolean
  isColumn?: boolean
}

const { Text } = Typography

export default class DetailRuleModalElement extends Component<Props> {
  getValue = () => {
    const { value, type } = this.props
    if (type === 'text') {
      return value
    } else if (type === 'date') {
      return value ? moment(value).format('DD.MM.YYYY') : '---'
    } else if (type === 'array') {
      if (Array.isArray(value)) {
        return value.join(', ')
      } else {
        return value
      }
    }
  }

  render = () => {
    const { label, col, isColumn } = this.props
    return isColumn ? (
      <View className="sc-rule-detail-box-column">
        <Text>
          <b>{label}</b>
        </Text>
        <Text>{this.getValue()}</Text>
      </View>
    ) : (
      <Col xs={24} sm={col}>
        <View className="sc-rule-detail-box">
          <Text>
            <b>{label}</b>
          </Text>
          <Text>{this.getValue()}</Text>
        </View>
      </Col>
    )
  }
}
