import { Page, Subsupplier, QueryParams } from 'api/types'
import { AxiosRequestConfig } from 'axios'
import api, { responseErrorCheck } from './api'

class Subsuppliers<T = any> {
  protected endpoint = 'profilemanager/v1/subSuppliers'

  async findAll(companyId: string, params: QueryParams, axiosConfig?: AxiosRequestConfig) {
    const res = await api.get<Page<T>>(`${this.endpoint}/referee/${companyId}`, params, axiosConfig)
    return responseErrorCheck(res)
  }

  async findOne(companyId: string, subsupplierId: string, axiosConfig?: AxiosRequestConfig) {
    const res = await api.get<Page<T>>(`${this.endpoint}/referee/${companyId}?id=${subsupplierId}`, axiosConfig)
    return responseErrorCheck(res)
  }

  async enable(subsupplierId: string, axiosConfig?: AxiosRequestConfig) {
    const res = await api.put<Page<T>>(`${this.endpoint}/${subsupplierId}/enabled`, axiosConfig)
    return responseErrorCheck(res)
  }

  async update(subsupplier: Partial<Subsupplier>, axiosConfig?: AxiosRequestConfig) {
    const res = await api.put(`${this.endpoint}/${subsupplier.id}`, subsupplier, axiosConfig)
    return responseErrorCheck(res)
  }

  async add(subsupplier: Partial<Subsupplier>, axiosConfig?: AxiosRequestConfig) {
    const res = await api.post<T>(`${this.endpoint}`, subsupplier, axiosConfig)
    return responseErrorCheck(res)
  }

  async delete(subsupplierId: string, axiosConfig?: AxiosRequestConfig) {
    const res = await api.delete<Page<T>>(`${this.endpoint}/${subsupplierId}`, axiosConfig)
    return responseErrorCheck(res)
  }
}

const subsuppliers = new Subsuppliers()
export { subsuppliers as default }
