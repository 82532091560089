
import {AxiosRequestConfig} from 'axios'
import api, {responseErrorCheck} from './api'
import { Mail } from './types';
class Mails {
  public endpoint = 'profilemanager/v1/mails'


  async send(mail: Mail, axiosConfig?: AxiosRequestConfig) {

    const res = await api.post<void>(`${this.endpoint}`, mail, axiosConfig)
    return responseErrorCheck(res)
  }

}

const mails = new Mails()
export { mails as default }
