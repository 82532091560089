import React from 'react';
import { Col, Divider, Row } from 'antd';
import moment from 'moment';
import { CSVLink } from 'react-csv';
import { DownloadOutlined } from '@ant-design/icons';
import { __, T } from '../../config/i18n';
import { DetailedOrderWithOwnerCompanyInfo } from '../../api/types';
import JsonFormOnReadView from '../views/JsonFormOnReadView';


interface Props {
  schema: any;
  order: DetailedOrderWithOwnerCompanyInfo;
  isShowExportCsvButton: boolean;
  hideSupplier?: boolean
  isModal?: boolean
}


export default class TraceDetailForm extends React.Component<Props> {

  private getCsv(data: object, csvData: any) {
    for (var key in data) {
      if (typeof data[key] === 'object') {
        this.getCsv(data[key], csvData);
      } else {
        csvData.push([key, data[key]]);
      }
    }
  }

  render = () => {
    const { order, schema } = this.props;
    let csvData: any = [];
    const date = moment(order.fulfillmentDate).format('DD.MM.YYYY');

    if (this.props.isShowExportCsvButton) {
      this.getCsv(order.data, csvData);
    }

    return (
      <div style={{ position: 'relative' }}>
        <h1>
          {order?.step}
        </h1>
        {this.props.isShowExportCsvButton &&(
          <><CSVLink data={csvData}
                     style={{ position: 'absolute', top: 0, right: 30, borderColor: '#2F80ED', color: '#2F80ED' }}
                     className="ant-btn ant-btn-default">{ __(T.buttons.export_trace_in_CSV) }<DownloadOutlined /> </CSVLink>
            <Divider style={{ marginLeft: 0, marginRight: 0, width: 'auto' }} />
            <Row style={{ paddingBottom: 10 }}>
              <Col span={8}>
                <Row>
                  PO Number
                </Row>
                <Row>
                  <b>
                    {this.props.order.code}
                  </b>
                </Row>
              </Col>
              {!this.props.hideSupplier && <Col span={8}>
                <Row>
                  Supplier Name
                </Row>
                <Row>
                  <b>
                    {this.props.order.ownerCompanyName}
                  </b>
                </Row>
              </Col>
              }
              <Col span={8}>
                <Row>
                  Date of fulfillment
                </Row>
                <Row>
                  <b>
                    {date}
                  </b>
                </Row>
              </Col>
            </Row>
          </>
        )}
        <Divider style={{ marginLeft: 0, marginRight: 0, width: 'auto' }} />
        <Row>
          <Col span={this.props.isModal ? 24 : 16}>
            <JsonFormOnReadView data={order.data} schema={schema}/>
          </Col>
        </Row>
      </div>
    );
  };
}
