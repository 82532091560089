import {BaseMenuConfiguration, MenuConfiguration, MenuConfigurationMapping} from "../api/types"
import {getOperationsForRole, isAdmin, isSupplier, isViewer} from "./permission"
import {T} from "../config/i18n"
import AppStore from "../AppStore"
import {role} from "../types";

export const mapMenuConfiguration = (menuConfigurations: MenuConfiguration[]): BaseMenuConfiguration[] => {
    const menuConfigurationMapper = getDefaultItemMenuConfiguration()

    return menuConfigurations
        .filter(configuration => menuConfigurationMapper[configuration.type])
        .filter(configuration => isAdmin() || isViewer() || !configuration.categories || (isSupplier() &&
            configuration.categories &&
            configuration.categories
                .filter(category => AppStore.loggedUser!.company!.categories!.indexOf(category) >= 0)
                .length > 0)
        )
        .map((userConfiguration: MenuConfiguration): BaseMenuConfiguration => {
            const type = userConfiguration.type
            const defaultConfiguration = menuConfigurationMapper[type]

            return ({
                type,
                url: getUrlFromConfiguration(userConfiguration, defaultConfiguration),
                label: userConfiguration.label ? userConfiguration.label : defaultConfiguration.label,
                visibleInDashboard: userConfiguration.visibleInDashboard ? userConfiguration.visibleInDashboard : defaultConfiguration.visibleInDashboard,
                visibleInMenu: userConfiguration.visibleInMenu ? userConfiguration.visibleInMenu : defaultConfiguration.visibleInMenu,
                roles: userConfiguration.roles ? userConfiguration.roles : defaultConfiguration.roles,
                iconMenu: userConfiguration.iconMenu ? userConfiguration.iconMenu : defaultConfiguration.iconMenu,
                iconDashboard: userConfiguration.iconDashboard ? userConfiguration.iconDashboard : defaultConfiguration.iconDashboard
            })
        })
}

export const isAnExternalLink = (userConfiguration: MenuConfiguration): boolean => {
    return userConfiguration.type === 'GENERIC_LINK' || userConfiguration.type === 'TINSIGHT';
}

const getUrlFromConfiguration = (userConfiguration: MenuConfiguration, defaultConfiguration: BaseMenuConfiguration): string => {
    if (isAnExternalLink(userConfiguration)) {
        return userConfiguration.url ?? defaultConfiguration.url
    }

    if (userConfiguration.extra) {
        if (userConfiguration.extra.path) {
            return userConfiguration.extra.path
        }

        if (userConfiguration.extra.code) {
            return defaultConfiguration.url + '/' + userConfiguration.extra.code
        }
    }

    return defaultConfiguration.url
}

export const getDefaultMenu = (): BaseMenuConfiguration[] => {
    const permission = getOperationsForRole()

    const enableTraceManagement = AppStore.appSettings.enableTraceManagement
    const enableOrderManagement = AppStore.appSettings.enableOrderManagement

    return [
        {
            ...getDefaultItemMenuConfiguration().HOME
        },
        {
            ...getDefaultItemMenuConfiguration().TRACE_CREATE,
            roles: [...(
                enableTraceManagement && (permission.traceCreatePersonal || permission.traceCreateThirdParts) ?
                    ["admin", "viewer", "admin-supplier", "supplier", "viewer-supplier"] as role[] :
                    []
            )]
        },
        {
            ...getDefaultItemMenuConfiguration().REPORT_TRACES,
            roles: [...(
                enableTraceManagement && permission.traceReport ?
                    ["admin", "viewer", "admin-supplier", "supplier", "viewer-supplier"] as role[] :
                    []
            )]
        },
        {
            ...getDefaultItemMenuConfiguration().ENQUIRY_MANAGEMENT,
            roles: [...(
                enableOrderManagement && permission.ordersReport ?
                    ["admin", "viewer", "admin-supplier", "supplier", "viewer-supplier"] as role[] :
                    []
            )]
        },
        {
            ...getDefaultItemMenuConfiguration().CUSTOMER_ORDERS_REPORT,
            roles: [...(
                enableOrderManagement && permission.customerOrdersReport ?
                    ["admin", "viewer", "admin-supplier", "supplier", "viewer-supplier"] as role[] :
                    []
            )]
        },
        {
            ...getDefaultItemMenuConfiguration().RAW_MATERIAL_ORDERS,
            roles: [...(
                enableOrderManagement && permission.rawMaterialSourceOrdersReport ?
                    ["admin", "viewer", "admin-supplier", "supplier", "viewer-supplier"] as role[] :
                    []
            )]
        },
        {
            ...getDefaultItemMenuConfiguration().COMPANY_REPORT,
            roles: [...(permission.companyReport ? ["admin", "viewer", "admin-supplier", "supplier", "viewer-supplier"] as role[] : [])]
        },
        {
            ...getDefaultItemMenuConfiguration().ON_BOARDING,
            roles: [...(permission.onboardingReport ? ["admin", "viewer", "admin-supplier", "supplier", "viewer-supplier"] as role[] : [])]
        },
        {
            ...getDefaultItemMenuConfiguration().COMPANIES_REPORT,
            roles: [...(permission.companiesReport ? ["admin", "viewer", "admin-supplier", "supplier", "viewer-supplier"] as role[] : [])]
        },
        {
            ...getDefaultItemMenuConfiguration().USER_REPORT,
            roles: [...(permission.userReport ? ["admin", "viewer", "admin-supplier", "supplier", "viewer-supplier"] as role[] : [])]
        },
        {
            ...getDefaultItemMenuConfiguration().PROFILE_MANAGEMENT,
            visibleInDashboard: false,
            roles: [...(permission.userProfileManagment ? ["admin", "viewer", "admin-supplier", "supplier", "viewer-supplier"] as role[] : [])]
        },
        {
            ...getDefaultItemMenuConfiguration().FORM_BUILDER,
            visibleInDashboard: false,
            roles: [...(permission.formBuilder ? ["admin", "viewer", "admin-supplier", "supplier", "viewer-supplier"] as role[] : [])]
        },
        {
            ...getDefaultItemMenuConfiguration().TRACE_LIBRARY,
            roles: [...(AppStore.appSettings.enableTraceLibrary ? ["admin", "viewer", "admin-supplier", "supplier", "viewer-supplier"] as role[] : [])]
        },
        {
            ...getDefaultItemMenuConfiguration().DOCUMENT_LIBRARY,
            roles: [...(AppStore.appSettings.enableDocumentLibrary ? ["admin", "viewer", "admin-supplier", "supplier", "viewer-supplier"] as role[] : [])]
        },
        {
            ...getDefaultItemMenuConfiguration().ANALYTICS,
            roles: ["admin"]
        },
        {
            ...getDefaultItemMenuConfiguration().DOWNLOADS_MANAGEMENT
        }
    ]
}

export const getDefaultItemMenuConfiguration = (): MenuConfigurationMapping => {
    return {
        HOME: {
            type: 'HOME',
            url: '/',
            label: T.buttons.dashboard,
            visibleInDashboard: false,
            visibleInMenu: true,
            roles: ["admin", "admin-supplier", "supplier", "viewer-supplier"],
            iconMenu: 'assets/images/Homepage-2.svg'
        },
        TRACE_CREATE: {
            type: 'TRACE_CREATE',
            url: '/traces/create',
            label: T.buttons.create_trace,
            visibleInDashboard: true,
            visibleInMenu: true,
            roles: ["admin", "admin-supplier", "supplier", "viewer-supplier"],
            iconMenu: 'assets/images/Create Trace-2.svg',
            iconDashboard: 'assets/images/Create Trace.svg'
        },
        REPORT_TRACES: {
            type: 'REPORT_TRACES',
            url: '/reports/traces',
            label: T.buttons.traces_report,
            visibleInDashboard: true,
            visibleInMenu: true,
            roles: ["admin", "admin-supplier", "supplier", "viewer-supplier"],
            iconMenu: 'assets/images/Trace Report-2.svg',
            iconDashboard: 'assets/images/Trace Report.svg'
        },
        ENQUIRY_MANAGEMENT: {
            type: 'ENQUIRY_MANAGEMENT',
            url: '/reports/orders',
            label: T.buttons.orders_report,
            visibleInDashboard: true,
            visibleInMenu: true,
            roles: ["admin", "admin-supplier", "supplier", "viewer-supplier"],
            iconMenu: 'assets/images/Order management-2.svg',
            iconDashboard: 'assets/images/Order management.svg'
        },
        DYNAMIC_ENQUIRY_MANAGEMENT: {
            type: 'DYNAMIC_ENQUIRY_MANAGEMENT',
            url: '/reports/enquiry',
            label: T.buttons.orders_report,
            visibleInDashboard: true,
            visibleInMenu: true,
            roles: ["admin", "admin-supplier", "supplier", "viewer-supplier"],
            iconMenu: 'assets/images/Order management-2.svg',
            iconDashboard: 'assets/images/Order management.svg'
        },
        CUSTOMER_ORDERS_REPORT: {
            type: 'CUSTOMER_ORDERS_REPORT',
            url: '/reports/customerorders',
            label: T.buttons.customer_orders_report,
            visibleInDashboard: true,
            visibleInMenu: true,
            roles: ["admin", "admin-supplier", "supplier", "viewer-supplier"],
            iconMenu: 'assets/images/Order management-2.svg',
            iconDashboard: 'assets/images/Order management.svg'
        },
        RAW_MATERIAL_ORDERS: {
            type: 'RAW_MATERIAL_ORDERS',
            url: '/reports/rawmaterialorders',
            label: T.buttons.raw_material_source_orders_report,
            visibleInDashboard: true,
            visibleInMenu: true,
            roles: ["admin", "admin-supplier", "supplier", "viewer-supplier"],
            iconMenu: 'assets/images/Order management-2.svg',
            iconDashboard: 'assets/images/Order management.svg'
        },
        COMPANY_REPORT: {
            type: 'COMPANY_REPORT',
            url: '/reports/company',
            label: T.buttons.company_management,
            visibleInDashboard: true,
            visibleInMenu: true,
            roles: ["admin", "admin-supplier", "supplier", "viewer-supplier"],
            iconMenu: 'assets/images/Company-2.svg',
            iconDashboard: 'assets/images/Company.svg'
        },
        ON_BOARDING: {
            type: 'ON_BOARDING',
            url: '/reports/onboarding',
            label: T.buttons.onboarding,
            visibleInDashboard: true,
            visibleInMenu: true,
            roles: ["admin", "admin-supplier", "supplier", "viewer-supplier"],
            iconMenu: 'assets/images/Onboarding-2.svg',
            iconDashboard: 'assets/images/onboarding.svg'
        },
        COMPANIES_REPORT: {
            type: 'COMPANIES_REPORT',
            url: '/reports/companies',
            label: T.buttons.companies_management,
            visibleInDashboard: true,
            visibleInMenu: true,
            roles: ["admin", "admin-supplier", "supplier", "viewer-supplier"],
            iconMenu: 'assets/images/Company-2.svg',
            iconDashboard: 'assets/images/Company.svg'
        },
        USER_REPORT: {
            type: 'USER_REPORT',
            url: '/reports/users',
            label: T.buttons.users_management,
            visibleInDashboard: true,
            visibleInMenu: true,
            roles: ["admin", "admin-supplier", "supplier", "viewer-supplier"],
            iconMenu: 'assets/images/User Management-2.svg',
            iconDashboard: 'assets/images/User Management.svg'
        },
        PROFILE_MANAGEMENT: {
            type: 'PROFILE_MANAGEMENT',
            url: '/reports/profileManagment',
            label: T.buttons.profile_managment,
            visibleInDashboard: false,
            visibleInMenu: true,
            roles: ["admin", "admin-supplier", "supplier", "viewer-supplier"],
        },
        FORM_BUILDER: {
            type: 'FORM_BUILDER',
            url: '/form/builder',
            label: T.buttons.form_builder,
            visibleInDashboard: false,
            visibleInMenu: true,
            roles: ["admin", "admin-supplier", "supplier", "viewer-supplier"],
        },
        TRACE_LIBRARY: {
            type: 'TRACE_LIBRARY',
            url: '/reports/libraries/TRACE',
            label: T.buttons.items_library,
            visibleInDashboard: true,
            visibleInMenu: true,
            roles: ["admin", "admin-supplier", "supplier", "viewer-supplier"],
            iconMenu: 'assets/images/Library-menu.svg',
            iconDashboard: 'assets/images/Library.svg'
        },
        DOCUMENT_LIBRARY: {
            type: 'DOCUMENT_LIBRARY',
            url: '/reports/libraries/DOCUMENT',
            label: T.buttons.documentation,
            visibleInDashboard: true,
            visibleInMenu: true,
            roles: ["admin", "admin-supplier", "supplier", "viewer-supplier"],
            iconMenu: 'assets/images/Document-menu.svg',
            iconDashboard: 'assets/images/Document.svg'
        },
        ANALYTICS: {
            type: 'ANALYTICS',
            url: '/analytics',
            label: T.buttons.analytic,
            visibleInDashboard: true,
            visibleInMenu: true,
            roles: ["admin"],
            iconMenu: 'assets/images/Analytics-1.svg',
            iconDashboard: 'assets/images/Analytics.svg'
        },
        DOWNLOADS_MANAGEMENT: {
            type: 'DOWNLOADS_MANAGEMENT',
            url: '/reports/downloads',
            label: T.buttons.downloads_management,
            visibleInDashboard: true,
            visibleInMenu: true,
            roles: ["admin", "admin-supplier", "supplier", "viewer-supplier"],
            iconMenu: 'assets/images/Company-2.svg',
            iconDashboard: 'assets/images/Document.svg'
        },
        PATH: {
            type: 'PATH',
            url: '',
            label: '',
            visibleInDashboard: true,
            visibleInMenu: true,
            roles: ["admin", "admin-supplier", "supplier", "viewer-supplier"],
        },
        TINSIGHT: {
            type: 'TINSIGHT',
            url: '',
            label: '',
            visibleInDashboard: true,
            visibleInMenu: true,
            roles: ["admin", "admin-supplier", "supplier", "viewer-supplier"],
            iconMenu: 'assets/images/Analytics-1.svg',
            iconDashboard: 'assets/images/Analytics.svg'
        },
        GENERIC_LINK: {
            type: 'GENERIC_LINK',
            url: '',
            label: '',
            visibleInDashboard: true,
            visibleInMenu: true,
            roles: ["admin", "admin-supplier", "supplier", "viewer-supplier"]
        }
    }
}
