import React  from 'react'
import { Progress } from '@ant-design/plots';

interface Props {
    value: string
}



export default class TinyProgressStyle extends React.Component<Props> {


    shoot = () => {
        this.setState({});
    }

    demoLine = () => {
        const config = {
            height: 10,
            width: 350,
            autoFit: false,
            percent: (+this.props.value)/100,
            color: ['#0066FF', '#E8EDF3'],
        };
        return <Progress {...config} />;
    };

    render() {
        return (
            this.demoLine()
        )
    }
}






