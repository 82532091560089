import React from 'react'
import {Order, Page} from 'api/types';
import {Checkbox} from 'antd'
import {__, T} from 'config/i18n';
import {ColumnsType} from "antd/es/table";
import Orders from "../../api/Orders";
import {Schema} from "../../components/AdvancedForm";
import {CheckboxChangeEvent} from 'antd/lib/checkbox'
import {getUserRole} from "../../helpers/permission";
import AppStore from "../../AppStore";
import {buildFiltersByConfiguration} from "./FilterBuilderUtil";
import {buildColumnsByConfiguration} from "./ColumnBuilderUtil";
import ModalTableSelectRow from "../ModalTableSelectRow";



const ORDER_STATUS_FILTERS =
    // [] as const
    ['FULFILLED', 'COMPLETED', 'APPROVED'] as const

type ReportOrder =
    Order
    & { assigneeCompanyName: string; creationCompanyName?: string, orderNumber: number }


interface Props {
    isVisible: boolean
    onCancel: () => void, //deve chiudere il componente.
    onSuccess: (order: Order, overrideAllFields: boolean) => void, //deve chiudere il componente + mandare i dati al padre.
    traceTypeCode: string,
    filters?: any,
    title: string
}

interface State {
    overrideExistingDataInput: boolean,
}

export default class ImportTraceDialog extends React.Component<Props, State> {

    state: State = {
        overrideExistingDataInput: false
    }

    async componentDidMount() {
    }

    getFilters = ():Schema[] => {
        let filtersSchema: Schema[] = buildFiltersByConfiguration(AppStore.appSettings.orderListFilters);
        filtersSchema = filtersSchema.filter(s => s.name !== 'step')
        return filtersSchema;
    }

    onSuccess = async (element) => {
        let order = await Orders.get(element.id)
        this.props.onSuccess(order, this.state.overrideExistingDataInput);
    };

    getColumns = (): ColumnsType<ReportOrder> => {
        return buildColumnsByConfiguration(AppStore.appSettings.orderListColumns);
    }

    searchElements = async (params: any, currentPage: number, sorField: string, sortDirection: 'asc' | 'desc', size: number): Promise<Page<ReportOrder>> => {
        if (getUserRole() === 'admin') {
            params['rootOrder'] = true
        } else {
            params['ownerCompany'] = AppStore.loggedUser?.company?.id ?? undefined
        }

        params['status'] = AppStore.appSettings.orderImportableFormTraceStatuses ? AppStore.appSettings.orderImportableFormTraceStatuses.split(',') : ORDER_STATUS_FILTERS;
        params['step'] = this.props.traceTypeCode;

        let orders: Page<ReportOrder> = await Orders.search({
            ...params,
            page: currentPage,
            size: size,
            sort: sorField + ',' + sortDirection
        });

        const companies = await Orders.getCompanies(orders);
        orders.content = orders.content.map(order => ({
            ...order,
            assigneeCompanyName: companies[order.assigneeCompany],
            creationCompanyName: order.creationCompany ? companies[order.creationCompany] : undefined
        }));
        return orders
    }

    render() {
        const onChange = (e: CheckboxChangeEvent) => {
            // console.log(`checked = ${e.target.checked}`);
            this.setState({overrideExistingDataInput: e.target.checked})
        };

        return (
            <ModalTableSelectRow<ReportOrder>
                isVisible={this.props.isVisible}
                onCancel={this.props.onCancel}
                onSuccess={this.onSuccess}
                onSearchElements={this.searchElements}
                getColumnsConfig={this.getColumns}
                getFiltersConfig={this.getFilters}
                title={this.props.title}
            >
                <Checkbox onChange={onChange}>
                    {__(T.buttons.overrideExistingDataInput)}
                    <br></br>
                    <p style={{color: '#afaaaa'}}>{__(T.buttons.override_existing_data_input_sub_title)}</p>
                </Checkbox>
            </ModalTableSelectRow>
        )
    }
}
