import React from 'react'
import {DetailedOrder, SubOrderWithOwnerCompanyInfo} from '../../api/types'
import OrderTraceReportSubOrderCardStepsBody from './OrderTraceReportSubOrderCardStepsBody'
import OrderTraceReportSubOrderCardListBody from './OrderTraceReportSubOrderCardListBody'

interface Props {
  parent: DetailedOrder
  subOrder: SubOrderWithOwnerCompanyInfo
  onViewSubOrderDetail?: (subOrderId: string) => void
  onRejectOrder?: (orderId: string) => void
  onCancel?: (orderId: string) => void
  onUnlock?: (orderId: string) => void
  onReassignOrder?: (orderId: string) => void
  onCloneOrder?: (orderId: string) => void
  onShowLogs?: (orderId: string) => void
  hideSupplier: boolean
  askToUpdate: number
  pageType?: string
}

interface State {
}

export default class OrderTraceReportSubOrderCardBody extends React.Component<Props, State> {

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any) {
    if(this.props.askToUpdate !== prevProps.askToUpdate){
      this.refresh();
    }
  }

  refresh(){
    this.setState({})
  }

  shouldRenderSteps = (order: DetailedOrder) => {
    return order.status === 'TO_BE_ASSIGNED'
  }

  shouldRenderList = (order: DetailedOrder) => {
    return order.status === 'ASSIGNED' || order.status === 'FULFILLED' || order.status === 'COMPLETED' || order.status === 'APPROVED' || order.status === 'DRAFT'
  }

  render = () => {
    const { parent, subOrder, onViewSubOrderDetail, onRejectOrder } = this.props
    const subOrders = this.getAllTierSubOrdersFrom(subOrder)
    return (
      <>
        {subOrder && this.shouldRenderSteps(parent) && <OrderTraceReportSubOrderCardStepsBody subOrders={subOrders} />}
        {subOrder && this.shouldRenderList(parent) && (
          <OrderTraceReportSubOrderCardListBody askToUpdate={this.props.askToUpdate} subOrders={subOrders}
                                                onViewSubOrderDetail={onViewSubOrderDetail}
                                                onRejectOrder={onRejectOrder}
                                                onCancel={this.props.onCancel}
                                                onUnlock={this.props.onUnlock}
                                                onReassignOrder={this.props.onReassignOrder}
                                                onCloneOrder={this.props.onCloneOrder}
                                                onShowLogs={this.props.onShowLogs}
                                                parentOrderStatus={this.props.parent.status}
                                                hideSupplier={this.props.hideSupplier}
                                                pageType={this.props.pageType}
                                                parentOrderId={this.props.parent.id}
          />
        )}
      </>
    )
  }

  getAllTierSubOrdersFrom = (subOrder: SubOrderWithOwnerCompanyInfo) => {
    return subOrder.orders.length === 0 ? [subOrder] : [subOrder, ...this.getAllTierSubOrdersFrom(subOrder.orders[0])]
  }

}
