import React from 'react'
import AppStore from 'AppStore'
import { notification } from 'antd';
import appStore from "AppStore";
import NoticeCard from "./NoticeCard";

interface Props {
  style?: React.CSSProperties,
  timestamp?: number
}

interface State {
  notificationsListVisible?: boolean
}

//let timestamp


export default class NotificationPopup  extends React.Component<Props, State> {

  callback: (event) => void;

  constructor(props) {
    super(props);
    this.callback = (event) => this.openPopup(event.firstCall)
  }


  state: State = {
  }

  componentDidMount() {
    AppStore.eventEmitter.subscribe("notificationChange", 'NotificationPopup', this.callback)
  }

  componentWillUnmount() {
    AppStore.eventEmitter.unSubscribe("notificationChange", 'NotificationPopup')
  }
  private openPopup(firstCall : boolean) {
    if (firstCall) {
      return
    }
    const notificationAndCount = appStore.notificationsAndCount
    //const lastTimerStamp = appStore.notificationsAndCount?.timstamp
    if (!!notificationAndCount?.notifications && notificationAndCount?.notifications.length > 0) {
      notificationAndCount?.notifications.forEach(n => {
            notification.open({
              message: (<NoticeCard notificationTCare={n}></NoticeCard>),
              className: 'notificationPopup'
            })
          },
      );
    }

  }

  render = () => {
    return (
        <>
        </>
    )
  }
}
