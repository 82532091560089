import {
  OrderLog,
  Page,
} from './types';
import {AxiosRequestConfig} from 'axios'
import api, {responseErrorCheck} from './api'

class OrderLogs {
  protected endpoint = 'tracemanager/v1/orderlogs'

  async getByOrderId(orderId: string, axiosConfig?: AxiosRequestConfig) {
    const res = await api.get<Page<OrderLog>>(`${this.endpoint}/${orderId}?sort=creationDate,desc`, {}, axiosConfig)
    return responseErrorCheck(res)
  }

}

const orderLogs = new OrderLogs()
export { orderLogs as default }
