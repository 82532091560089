import React from 'react'
import { CompanyFormLayout, Layout, Modal, View } from 'components'
import { __, T } from 'config/i18n'
import { showNotification } from 'helpers/Notifications'
import { CompanyFields } from 'types'
import { Spin } from 'antd'
import theme from 'theme/theme'


interface Props {}
interface State {
  companyFields: CompanyFields
  loading: boolean
  showConfirmDialog: boolean
}

export default class CreateSubSupplier extends React.Component<Props, State> {
  state: State = {
    companyFields: { companyType: 'supplier' },
    showConfirmDialog: false,
    loading: false,
  }

  deleteSubsupplier = () => {
    this.setState({ showConfirmDialog: true })
  }

  addSubsupplier = () => {
    this.setState({ showConfirmDialog: true })
  }

  goBack = () => {
    // navigate('/reports/subsupplier')
  }

  createSubsupplier = async () => {
    /*
     const { companyFields } = this.state
    const subsupplier: Partial<Subsupplier> = {
      type: 'it.temera.stw.tcare.profilemanagerservice.dto.SubSupplierDto',
      referee: AppStore.loggedUser?.company,
      enabled: true,
      companyCode: companyFields.companyCode ?? '',
      data: {
        businessName: companyFields.businessName ?? '',
        address: companyFields.address ?? '',
        addressNumber: companyFields.addressNumber ?? '',
        cap: companyFields.postalCode ?? '',
        email: companyFields.companyEmail ?? '',
        legalCode: companyFields.pivaCf ?? '',
        mobilePhone: companyFields.companyMobilePhone ?? '',
        pec: companyFields.companyEmailPec ?? '',
        phone: companyFields.companyPhone ?? '',
        ethicalCode: companyFields.adherenceEthicsCode,
        suppressionMethod: companyFields.suppressionMethod,
      },
    }*/
    try {
      this.setState({ loading: true })
      // const company = await Subsuppliers.add(subsupplier)
      this.setState({ showConfirmDialog: false })
      showNotification(__(T.messages.company_added))
      // TODO WHAT TO DO HERE?
      // await uploadCertificates(company?.id, this.state.companyFields)
      // navigate('/reports/subsupplier')
    } catch (e) {}
    this.setState({ loading: false })
  }

  render() {
    const { companyFields } = this.state
    return (
      <Layout>
        <View style={{ padding: 20 }}>
          <Spin spinning={this.state.loading} delay={500}>
            <View className="page-table">
              <CompanyFormLayout
                fieldsVisibility={{ companyCategory: true, companyDatas: true, certifications: true }}
                onValuesChange={(changedValues, values) => {
                  this.setState({ companyFields: values })
                }}
                companyFields={companyFields}
                submitButton={{
                  text: __(T.buttons.confirm),
                  col: 6,
                  style: theme.temeraPrimaryGreenButton,
                  onPress: this.addSubsupplier,
                }}
                secondaryButton={{
                  text: __(T.buttons.back),
                  col: 6,
                  style: theme.temeraSecondaryDarkButton,
                  onPress: this.goBack,
                }}
              />
            </View>
          </Spin>
        </View>
        <Modal
          title={__(T.confirms.confirm_operation)}
          message={__(T.confirms.confirm_or_go_back_to_change_the_data)}
          visible={this.state.showConfirmDialog}
          confirmButtonTitle={__(T.buttons.confirm)}
          closeButtonTitle={__(T.buttons.back)}
          onConfirmClick={this.createSubsupplier}
          onCloseClick={() => {
            this.setState({ showConfirmDialog: false })
          }}
        />
      </Layout>
    )
  }
}
