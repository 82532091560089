import React, { Component } from 'react';
import { Modal, Button, Typography } from 'antd';
import { __, T } from 'config/i18n';
import { View } from 'components';
import theme from 'theme/theme';
import TextArea from 'antd/lib/input/TextArea';
import DOMPurify from 'dompurify';

interface Props {
  visible: boolean;
  title: string;
  subtitle?: string;
  confirm?: string;
  cancel?: string;
  hasFeedback?: boolean;
  callback: (confirm: boolean, feedback?: string) => void;
}

const { Title, Text } = Typography;

interface State {
  feedback?: string;
}

export default class ModalConfirm extends Component<Props, State> {

  state: State = {};


  handleChange = (event): void => {
    this.setState({ feedback: event.target.value });
  };

  render() {
    const { title, visible, subtitle, callback, hasFeedback } = this.props;
    return (
      <Modal centered visible={visible} closable={false} footer={null} onCancel={() => callback(false)}>
        <Title style={{ textAlign: 'center' }} level={4}>
          <div dangerouslySetInnerHTML={{ __html: __('modal-confirm-title', DOMPurify.sanitize(title))}}/>
        </Title>

        {subtitle && subtitle !== '' && (
          <Text style={{ display: 'inline-block', width: '100%' }}>
            <div dangerouslySetInnerHTML={{ __html: __('modal-confirm-subtitle', DOMPurify.sanitize(subtitle))}}/>
          </Text>
        )}

        {hasFeedback && (
          <TextArea rows={4}
                    showCount
                    maxLength={400}
                    onChange={this.handleChange}
          />
        )}
        <View center style={{ marginTop: 25 }}>
          <Button
            style={{ ...{ fontWeight: 700 }, ...theme.temeraSecondaryDarkButton }}
            type="default"
            onClick={() => callback(false)}
          >
            {this.props.cancel ?? __(T.buttons.cancel)}
          </Button>
          <Button
            style={{ ...{ fontWeight: 700, marginLeft: 25 }, ...theme.temeraPrimaryGreenButton }}
            type="default"
            onClick={() => callback(true, this.state.feedback)}
          >
            {this.props.confirm ?? __(T.buttons.confirm)}
          </Button>
        </View>
      </Modal>
    );
  }
}
