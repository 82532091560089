import React, { Component } from 'react'
import { __, T } from 'config/i18n'
import { Button } from 'antd'
import { View } from 'components'
import { SupplyChain } from 'api/types'

interface Props {
  supplyChain: SupplyChain[]
  onclick: any
  currentSupply?: SupplyChain
}

export default class SupplyChainList extends Component<Props> {
  render = () => {
    const { supplyChain, onclick, currentSupply } = this.props
    return (
      <View className="supply-chain-col list">
        {supplyChain.map(supply => (
          <View
            className={'supply-element' + (currentSupply && currentSupply.id === supply.id ? ' selected' : '')}
            key={'supply_' + supply.id}
          >
            <Button
              className={supply.enabled ? 'active' : 'inactive'}
              type="link"
              onClick={() => (currentSupply && currentSupply.id === supply.id ? {} : onclick(supply))}
            >
              {supply.name}
            </Button>
            <View className="border" />
          </View>
        ))}
        <View className={'supply-element' + (!currentSupply || currentSupply.id === '' ? ' selected' : '')}>
          <Button style={{ color: '#595959' }} type="link" onClick={() => onclick(undefined)}>
            {__(T.misc.add_fiber)}
          </Button>
          <View className="border" />
        </View>
      </View>
    )
  }
}
