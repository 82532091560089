import React from 'react';
import { LibraryConfig, LibraryItem, Order, Page } from '../../api/types';
import { Button, Dropdown, Menu } from 'antd';
import { Layout, ModalConfirm } from 'components';
import { __, T } from 'config/i18n';
import AppStore from '../../AppStore';
import { admin, getUserRole, isAdmin, isSupplier } from '../../helpers/permission';
import { navigate } from '../../router';
import { ColumnsType } from 'antd/es/table';
import { showNotification } from '../../helpers/Notifications';
import LibraryItems from '../../api/LibraryItems';
import NewLibraryItemModal from '../../components/library/NewLibraryItemModal';
import { Companies } from '../../api';
import { buildColumnsByConfiguration } from '../../components/orders/ColumnBuilderUtil';
import { buildFiltersByConfiguration } from '../../components/orders/FilterBuilderUtil';
import FilteredTable from '../../components/FilteredTable';
import { setOffGlobalSpinningOnHeader } from '../../api/api';



const LIBRARY_REPORT_SESSION_STORAGE = 'libraryReportSessionStorage';

interface Props {
  match: { params: { type: string } };
}

const INITIAL_STATE: State = {
  isVisibleConfirmModal: false,
  isVisibleNewLibraryItemModal: false,
  tmpIdLibraryItem: null,
  askToUpdateData: 1
};

interface ModalConfirmConfig {
  title: () => string,
  subtitle: () => string,
  hasMessage: boolean,
  callback: (confirm: boolean, message?: string) => void
}


interface State {
  isVisibleConfirmModal: boolean;
  isVisibleNewLibraryItemModal: boolean;
  // Is used in order to store temporary an id order when the user wish for example to approve a single order.
  tmpIdLibraryItem: string | null;
  modalConfirmConfig?: ModalConfirmConfig;
  askToUpdateData: number;
}

export default class LibrariesReport extends React.Component<Props, State> {


  private modalConfirmSingleRemove: ModalConfirmConfig = {
    title: () => __(T.fields.remove_library_title),
    subtitle: () => __(T.fields.remove_library),
    hasMessage: false,
    callback: (confirm) => {
      this.handleSingleRemoveSubmit(confirm).then();
    }
  };

  state: State = { ...INITIAL_STATE };

  componentDidMount = async () => {
  };

  askToUpdateData = () => {
    this.setState({ askToUpdateData: this.state.askToUpdateData + 1 });
  };

  async componentDidUpdate(prevProps, prevState) {
    if (prevProps.match.params.type !== this.props.match.params.type) {
      this.askToUpdateData();
    }
  }

  newItem = () => {
    this.setState({
      isVisibleNewLibraryItemModal: true
    });
  };

  onCancelNewItem = () => {
    this.setState({
      isVisibleNewLibraryItemModal: false
    });
  };

  onSuccessNewItem = (libraryConfig: LibraryConfig) => {
    this.setState({
      isVisibleNewLibraryItemModal: false
    });
    navigate('/reports/libraries/:type/new/:libraryConfigId', {
      type: this.props.match.params.type,
      libraryConfigId: libraryConfig.id
    });
  };

  getColumns = (): ColumnsType<LibraryItem> => {
    let configuration = AppStore.appSettings['libraryItemColumns.' + this.props.match.params.type];
    const columns: any = buildColumnsByConfiguration(configuration);
    columns.push({
      title: __(T.fields.actions),
      key: 'col_action',
      align: 'left',
      fixed: 'right',
      width: 1,
      render: (text, record: Order) => {
        return (<>
          <Dropdown.Button overlay={
            <Menu>
              <Menu.Item
                onClick={() => navigate('/reports/libraries/:type/edit/:libraryItemId', {
                  type: this.props.match.params.type,
                  libraryItemId: record.id
                })}
              >
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <img style={{ height: '20px', justifyContent: 'center', display: 'flex' }}
                       src={require('assets/images/View.svg').default} alt={__(T.buttons.view)} />
                  <a style={{ minWidth: '150px', justifyContent: 'center', display: 'flex' }}>
                    {__(T.buttons.view)}
                  </a>
                </div>
              </Menu.Item>

              {isSupplier() &&
                <Menu.Item
                  onClick={() => {
                    this.confirmToRemove(record.id!);
                  }}
                >
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <img style={{
                      height: '25px',
                      justifyContent: 'left',
                      display: 'flex'
                    }}
                         src={require('assets/images/Reject_gray.svg').default} alt={''} />
                    <a style={{ minWidth: '150px', justifyContent: 'center', display: 'flex' }}>
                      {__(T.buttons.remove)}
                    </a>
                  </div>
                </Menu.Item>
              }
            </Menu>
          }>
          </Dropdown.Button>
        </>);
      }
    });

    return columns;
  };

  private confirmToRemove = (id: string) => {
    this.setState({
      isVisibleConfirmModal: true,
      tmpIdLibraryItem: id,
      modalConfirmConfig: this.modalConfirmSingleRemove
    });
  };

  handleSingleRemoveSubmit = async (confirm: boolean) => {
    this.setState({ isVisibleConfirmModal: false },
      async () => {
        if (confirm) {
          await LibraryItems.delete(this.state.tmpIdLibraryItem!);
          this.askToUpdateData();
          showNotification(
            __(T.titles.order_fulfillment_success),
            __(T.messages.order_approve_success),
            'success',
            3
          );
        }
      });
  };


  getTabBarExtraContent = (): JSX.Element[] => {
    const buttons: JSX.Element[] = [];

    if (getUserRole() !== admin) {
      buttons.push(
        <Button
          key={this.props.match.params.type === 'TRACE' ? T.buttons.libraryNew_TRACE: T.buttons.library_upload_document}
          type={'primary'}
          style={{ width: '200px' }}
          onClick={this.newItem}
        >
          {__(this.props.match.params.type === 'TRACE' ? T.buttons.libraryNew_TRACE: T.buttons.library_upload_document)}
        </Button>
      );
    }
    return buttons;
  };

  onSearch = async (params: any, currentPage: number, sortField: string, sortDirection: 'asc' | 'desc', size: number): Promise<Page<LibraryItem>> => {

    if (isAdmin()) {
      params['visibleForAdmin'] = true;
    } else {
      params['creationCompany'] = AppStore.loggedUser?.company?.id;
    }

    let libraryItemsPage = await LibraryItems.search({
      page: currentPage,
      size: size,
      ...params,
      libraryConfigType: this.props.match.params.type,
      sort: sortField + ',' + sortDirection
    }, setOffGlobalSpinningOnHeader());

    let companyContent = (await Companies.search({
      ids: Array.from(new Set(libraryItemsPage.content.map(item => item.creationCompany))),
      page: 0,
      size: 100
    }, setOffGlobalSpinningOnHeader())).content;

    let companyMap = {};
    companyContent.forEach(item => {
      companyMap[item.id] = item;
    });

    libraryItemsPage.content = libraryItemsPage.content.map(item => {
      return {
        ...item,
        creationCompanyName: companyMap[item.creationCompany].data.businessName
      };
    });
    return libraryItemsPage;
  };


  render = () => {
    let filtersConfiguration = AppStore.appSettings['libraryItemFilters.' + this.props.match.params.type];
    let filtersSchema = buildFiltersByConfiguration(filtersConfiguration);
    if (filtersSchema.length === 0) {
      return <Layout children={null} />;
    }

    return (
      <Layout key={'orderReportView'}>
        <FilteredTable
          onSearchElements={this.onSearch}
          getColumnsConfig={this.getColumns}
          getFiltersConfig={() => filtersSchema}
          storeKey={LIBRARY_REPORT_SESSION_STORAGE + this.props.match.params.type}
          askToUpdateData={this.state.askToUpdateData}
          getExtraButtons={this.getTabBarExtraContent}
        />
        {this.state.modalConfirmConfig &&
          <ModalConfirm
            visible={this.state.isVisibleConfirmModal}
            title={this.state.modalConfirmConfig.title()}
            subtitle={this.state.modalConfirmConfig.subtitle()}
            hasFeedback={this.state.modalConfirmConfig.hasMessage}
            callback={this.state.modalConfirmConfig.callback}
          />
        }
        <NewLibraryItemModal
          isVisible={this.state.isVisibleNewLibraryItemModal}
          libraryType={this.props.match.params.type}
          onCancelCallback={this.onCancelNewItem}
          onSuccessCallback={this.onSuccessNewItem} />
      </Layout>
    );
  };

}
