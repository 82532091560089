import React from "react";
import {__, T} from "config/i18n";
import { category, CompanyFields, UserFields } from "types";
import { Typography } from "antd";
import { FilePickerProps } from "components/FilePicker";
import { Schema } from "components/AdvancedForm";
import { HardCoded } from "api";
import { companyFieldsVisible } from "helpers/permission";
import { describeCategory } from "helpers/describer";
import AppStore from "../../AppStore";

const leftHalf = { xs: 24, sm: { span: 6 } };
const rightHalf = { xs: 24, sm: { span: 6, offset: 12, pull: 12 } };
const full = { xs: 24, sm: { span: 12, offset: 12, pull: 12 } };

// const half = { xs: 24, sm: { span: 6, offset: 12, pull: 12 } }

interface UserFieldsVisibility {
  userData?: boolean;
  userPassword?: boolean;
  userMail?: boolean;
}

interface CompanyTypesVisibility {
  companyType?: boolean;
  companyCategory?: boolean;
}

interface CompanyFieldsVisibility {
  companyDatas?: boolean;
  certifications?: boolean;
}

export function getUserSchema(
  initialValues?: UserFields,
  fieldsVisibility?: UserFieldsVisibility,
  disabled?: UserFieldsVisibility
): Schema[] {
  //TODO remove refuse fieldsVisibility === 'boolean'
  let hideUserData = false,
    hideUserPassword = false;

  if (typeof fieldsVisibility === "boolean") hideUserData = !fieldsVisibility;
  else hideUserData = !(fieldsVisibility?.userData !== undefined && fieldsVisibility?.userData);

  if (typeof fieldsVisibility === "boolean") hideUserPassword = !fieldsVisibility;
  else hideUserPassword = !(fieldsVisibility?.userPassword !== undefined && fieldsVisibility?.userPassword);

  return [
    {
      name: "name",
      type: "text",
      label: __(T.fields.name),
      placeholder: __(T.misc.insert),
      initialValue: initialValues?.name,
      rules: [{ required: true, message: __(T.messages.field_required) }],
      col: leftHalf,
      disabled: disabled?.userData,
      hide: hideUserData
    },
    {
      name: "surname",
      type: "text",
      label: __(T.fields.surname),
      placeholder: __(T.misc.insert),
      initialValue: initialValues?.surname,
      rules: [{ required: true, message: __(T.messages.field_required) }],
      col: rightHalf,
      disabled: disabled?.userData,
      hide: hideUserData
    },
    {
      name: "phone",
      type: "text",
      label: __(T.fields.phone),
      placeholder: __(T.misc.insert),
      initialValue: initialValues?.phone,
      col: leftHalf,
      disabled: disabled?.userData,
      hide: hideUserData
    },
    {
      name: "email",
      type: "text",
      label: __(T.fields.email_address),
      placeholder: __(T.misc.insert),
      initialValue: initialValues?.email,
      rules: [{ required: true, message: __(T.messages.field_required) }],
      col: rightHalf,
      disabled: disabled?.userMail || disabled?.userData,
      hide: hideUserData
    },
    {
      name: "password",
      type: "password",
      label: __(T.fields.password),
      placeholder: __(T.misc.insert),
      initialValue: initialValues?.password,
      rules: [{ required: true, message: __(T.messages.field_required) }],
      col: leftHalf,
      disabled: disabled?.userPassword,
      hide: hideUserPassword
    },
    {
      name: "confirmPassword",
      type: "password",
      label: __(T.fields.password_confirm),
      placeholder: __(T.misc.insert),
      initialValue: initialValues?.confirmPassword,
      col: rightHalf,
      rules: [{ required: true, message: __(T.messages.field_required) }],
      disabled: disabled?.userPassword,
      hide: hideUserPassword
    }
  ];
}

export function getUserDetailSchema(): Schema[] {
  return [
    {
      name: "name",
      type: "text",
      label: __(T.fields.name),
      placeholder: __(T.misc.insert),
      col: leftHalf,
      disabled: true
    },
    {
      name: "surname",
      type: "text",
      label: __(T.fields.surname),
      placeholder: __(T.misc.insert),
      col: rightHalf,
      disabled: true
    },
    {
      name: "phone",
      type: "text",
      label: __(T.fields.phone),
      placeholder: __(T.misc.insert),
      col: leftHalf,
      disabled: true
    },
    {
      name: "email",
      type: "text",
      label: __(T.fields.email_address),
      placeholder: __(T.misc.insert),
      col: rightHalf,
      disabled: true
    },
    {
      name: "categories",
      type: "text",
      label: __(T.fields.categories),
      placeholder: __(T.misc.insert),
      col: leftHalf,
      disabled: true
    },
    {
      name: "creationDate",
      type: "text",
      label: __(T.fields.creation_date),
      placeholder: __(T.misc.insert),
      col: rightHalf,
      disabled: true
    }
  ];
}

export function getCompanyTypeSchema(
  values?: { category?: category; companyType?: string },
  fieldsVisibility: CompanyTypesVisibility | boolean = true,
  disabled?: CompanyTypesVisibility
): Schema[] {
  const categories = HardCoded.getCategoryOptions();
  const companyTypes = HardCoded.getCompanyTypes();

  let hideType = false,
    hideCategory = false,
    allVisible = false;

  if (typeof fieldsVisibility === "boolean") {
    allVisible = fieldsVisibility;
  } else {
    hideType = !(fieldsVisibility?.companyType !== undefined && fieldsVisibility?.companyType);
    hideCategory =
      (values?.companyType && values?.companyType !== "supplier") ||
      !(fieldsVisibility?.companyCategory !== undefined && fieldsVisibility?.companyCategory);
  }

  return [
    {
      name: "companyType",
      type: "radio",
      label: __(T.fields.company_type),
      options: companyTypes,
      initialValue: values && values.companyType,
      col: full,
      rules: disabled ? undefined : [{ required: true, message: __(T.messages.field_required) }],
      hide: (!allVisible && hideType) || disabled?.companyType
    },
    {
      name: "companyTypeDescription",
      type: "radio",
      label: __(T.fields.company_type),
      col: full,
      disabled: true,
      hide: !disabled?.companyType
    },
    {
      name: "category",
      label: __(T.fields.category),
      type: "select",
      placeholder: __(T.misc.select),
      options: categories,
      initialValue: values && describeCategory(values.category),
      col: full,
      rules: disabled?.companyCategory ? undefined : [{ required: true, message: __(T.messages.field_required) }],
      hide: (!allVisible && hideCategory) || disabled?.companyCategory
    },
    {
      name: "categoryDescription",
      label: __(T.fields.category),
      type: "select",
      col: full,
      disabled: true,
      hide: !disabled?.companyCategory || hideCategory
    }
  ];
}

export function getCompanySchema(
  companyFields: CompanyFields,
  fieldsVisibility: CompanyFieldsVisibility | boolean = true,
  renderFilePicker: (props: FilePickerProps) => JSX.Element,
  disabled?: CompanyFieldsVisibility
): Schema[] {
  const visible = companyFieldsVisible(companyFields);

  let hideCompanyData = false,
    hideCertifications = false;

  if (typeof fieldsVisibility === "boolean") hideCompanyData = !fieldsVisibility;
  else hideCompanyData = !(fieldsVisibility?.companyDatas !== undefined && fieldsVisibility?.companyDatas);

  if (typeof fieldsVisibility === "boolean") hideCertifications = !fieldsVisibility;
  else hideCertifications = !(fieldsVisibility?.certifications !== undefined && fieldsVisibility?.certifications);
  return [
    {
      name: "",
      notInput: true,
      hide: hideCompanyData,
      render: () => <Typography.Title level={3}>{__(T.fields.company_datas)}</Typography.Title>
    },
    {
      name: "businessName",
      label: __(T.fields.business_name),
      type: "text",
      placeholder: __(T.misc.insert),
      initialValue: companyFields.businessName,
      col: full,
      rules: [{ required: true, message: __(T.messages.field_required) }],
      disabled: disabled?.companyDatas,
      hide: hideCompanyData
    },
    {
      name: "companyCode",
      label: __(T.fields.company_code),
      type: "text",
      placeholder: __(T.misc.insert),
      initialValue: companyFields.companyCode,
      col: full,
      disabled: disabled?.companyDatas,
      hide: hideCompanyData
    },
    {
      name: "pivaCf",
      label: __(T.fields.piva_cf),
      type: "text",
      placeholder: __(T.misc.insert),
      initialValue: companyFields.pivaCf,
      col: full,
      disabled: disabled?.companyDatas,
      hide: hideCompanyData
    },
    {
      name: "city",
      label: __(T.fields.city),
      type: "google-places-autocomplete",
      placeholder: __(T.misc.select),
      showSearch: true,
      initialValue: companyFields.city,
      col: full,
      rules: [{ required: true, message: __(T.messages.field_required) }],
      disabled: disabled?.companyDatas,
      hide: hideCompanyData
    },
    {
      name: "postalCode",
      label: __(T.fields.postal_code),
      type: "text",
      placeholder: __(T.misc.insert),
      initialValue: companyFields.postalCode,
      col: { xs: 24, sm: { span: 3 } },
      rules: [{ required: true, message: __(T.messages.field_required) }],
      disabled: disabled?.companyDatas,
      hide: hideCompanyData
    },
    {
      name: "address",
      label: __(T.fields.address),
      type: "text",
      placeholder: __(T.misc.insert),
      initialValue: companyFields.address,
      col: rightHalf,
      rules: [{ required: true, message: __(T.messages.field_required) }],
      disabled: disabled?.companyDatas,
      hide: hideCompanyData
    },
    {
      name: "addressNumber",
      label: __(T.fields.address_number),
      type: "text",
      placeholder: __(T.misc.insert),
      initialValue: companyFields.addressNumber,
      col: { xs: 24, sm: { span: 3, pull: 12 } },
      rules: [{ required: true, message: __(T.messages.field_required) }],
      disabled: disabled?.companyDatas,
      hide: hideCompanyData
    },
    {
      name: "companyPhone",
      label: __(T.fields.phone),
      type: "text",
      placeholder: __(T.misc.insert),
      initialValue: companyFields.companyPhone,
      col: leftHalf,
      disabled: disabled?.companyDatas,
      hide: hideCompanyData
    },
    {
      name: "companyMobilePhone",
      type: "text",
      label: __(T.fields.phone_number),
      initialValue: companyFields.companyMobilePhone,
      col: rightHalf,
      disabled: disabled?.companyDatas,
      hide: hideCompanyData
    },
    {
      name: "companyEmail",
      label: __(T.fields.email),
      type: "text",
      placeholder: __(T.misc.insert),
      initialValue: companyFields.companyEmail,
      col: leftHalf,
      disabled: disabled?.companyDatas,
      hide: hideCompanyData
    },
    {
      name: "companyEmailPec",
      label: __(T.fields.email_pec),
      type: "text",
      placeholder: __(T.misc.insert),
      initialValue: companyFields.companyEmailPec,
      col: rightHalf,
      disabled: disabled?.companyDatas,
      hide: hideCompanyData
    },
    {
      name: "",
      notInput: true,
      hide: hideCertifications,
      render: () => <Typography.Title level={3}>{__(T.fields.documents_and_certifications)}</Typography.Title>
    },
    {
      name: "adherenceEthicsCode",
      label: __(T.fields.adherence_ethics_code),
      type: "radio",
      placeholder: __(T.misc.insert),
      options: [
        { label: __(T.misc.yes), value: true },
        { label: __(T.misc.no), value: false }
      ],
      initialValue: companyFields.adherenceEthicsCode,
      col: full,
      rules: [{ required: true, message: __(T.messages.field_required) }],
      hide: hideCertifications || !visible.adherenceEthicsCode || disabled?.certifications,
      disabled: disabled?.certifications
    },
    {
      name: "adherenceEthicsCodeDescription",
      type: "radio",
      label: __(T.fields.adherence_ethics_code),
      col: full,
      disabled: true,
      hide: !disabled?.certifications
    },
    // TODO WHAT TO DO HERE?
    // {
    //   name: '',
    //   col: half,
    //   hide: hideCertifications || !visible.ethicsCertificate,
    //   notInput: true,
    //   render: () =>
    //     renderFilePicker({
    //       initialValue: companyFields.certifications?.filter(cer => cer.certificateKey === 'ethicsCertificate'),
    //       certificateKey: 'ethicsCertificate',
    //       disabled: disabled?.certifications,
    //       placeHolder: __(T.fields.load_ethic_certificate),
    //     }),
    //   disabled: disabled?.certifications,
    // },
    // {
    //   name: '',
    //   col: half,
    //   notInput: true,
    //   hide: hideCertifications || !visible.licenseCertificate,
    //   render: () =>
    //     renderFilePicker({
    //       initialValue: companyFields.certifications?.filter(cer => cer.certificateKey === 'licenseCertificate'),
    //       disabled: disabled?.certifications,
    //       certificateKey: 'licenseCertificate',
    //       label: __(T.fields.license_concession),
    //       placeHolder: __(T.fields.load_certificates),
    //     }),
    // },
    // {
    //   name: '',
    //   col: half,
    //   notInput: true,
    //   hide: hideCertifications || !visible.membershipCertificate,
    //   render: () =>
    //     renderFilePicker({
    //       initialValue: companyFields.certifications?.filter(cer => cer.certificateKey === 'membershipCertificate'),
    //       disabled: disabled?.certifications,
    //       certificateKey: 'membershipCertificate',
    //       label: __(T.fields.membership_association),
    //       placeHolder: __(T.fields.load_certificates),
    //     }),
    // },
    // {
    //   name: '',
    //   col: half,
    //   notInput: true,
    //   hide: hideCertifications || !visible.tanneryCertifications,
    //   render: () =>
    //     renderFilePicker({
    //       initialValue: companyFields.certifications?.filter(cer => cer.certificateKey === 'tanneryCertifications'),
    //       disabled: disabled?.certifications,
    //       certificateKey: 'tanneryCertifications',
    //       label: __(T.fields.certifications),
    //       placeHolder: __(T.fields.load_scan_certificates),
    //     }),
    // },
    {
      name: "competenceTerritory",
      label: __(T.fields.territory_of_competence),
      type: "text",
      placeholder: __(T.misc.insert),
      initialValue: companyFields.competenceTerritory,
      col: full,
      hide: hideCertifications || !visible.competenceTerritory,
      disabled: disabled?.certifications
    },
    // TODO WHAT TO DO HERE?
    // {
    //   name: '',
    //   col: half,
    //   notInput: true,
    //   render: () =>
    //     renderFilePicker({
    //       initialValue: companyFields.certifications?.filter(cer => cer.certificateKey === 'healthCertificate'),
    //       disabled: disabled?.certifications,
    //       certificateKey: 'healthCertificate',
    //       label: __(T.fields.health_certificate),
    //       placeHolder: __(T.fields.load_certificates),
    //     }),
    //   hide: hideCertifications || !visible.healthCertificate,
    // },
    // {
    //   name: '',
    //   col: half,
    //   notInput: true,
    //   render: () =>
    //     renderFilePicker({
    //       initialValue: companyFields.certifications?.filter(cer => cer.certificateKey === 'otherCertificates'),
    //       disabled: disabled?.certifications,
    //       certificateKey: 'otherCertificates',
    //       label: __(T.fields.other_certificates),
    //       placeHolder: __(T.fields.load_certificates),
    //     }),
    //   hide: hideCertifications || !visible.otherCertificates,
    // },
    {
      name: "allowedQuantity",
      label: __(T.fields.quantity_allowed),
      type: "number",
      placeholder: __(T.fields.es_10),
      initialValue: companyFields.allowedQuantity,
      col: full,
      hide: hideCertifications || !visible.allowedQuantity,
      disabled: disabled?.certifications
    },
    {
      name: "suppressionMethod",
      label: __(T.fields.suppression_method),
      type: "text",
      placeholder: __(T.misc.insert),
      initialValue: companyFields.suppressionMethod,
      col: full,
      hide: hideCertifications || !visible.suppressionMethod,
      disabled: disabled?.certifications
    }
    // TODO WHAT TO DO HERE?
    // {
    //   name: '',
    //   col: half,
    //   notInput: true,
    //   render: () =>
    //     renderFilePicker({
    //       initialValue: companyFields.certifications?.filter(cer => cer.certificateKey === 'feedingDocuments'),
    //       disabled: disabled?.certifications,
    //       certificateKey: 'feedingDocuments',
    //       label: __(T.fields.feeding_documents),
    //       placeHolder: __(T.fields.load_certificates),
    //     }),
    //   hide: hideCertifications || !visible.feedingDocuments,
    // },
  ];
}

function transformSchema(schema: Schema): void {
  if (!!schema.label) {
    schema.label = __(schema.label)
  }
  schema.col = schema.col ?? {xs:24,sm:{span:12}}
  schema.disabled = true
  if(schema.type === 'array') {
    schema.items!.forEach((s: Schema) => {
      transformSchema(s)
    })
  }
}

export function getOrderDetailSchema(traceTypeCode: string): Schema[] {
  let schemas: Schema[] = []
  if(!!AppStore.appSettings['orderDetailInfo-' + traceTypeCode]) {
    schemas = JSON.parse(AppStore.appSettings['orderDetailInfo-' + traceTypeCode].replaceAll("'", "\""));
    schemas.forEach((schema: Schema) => {
      transformSchema(schema)
    });
  } else if (!!AppStore.appSettings.orderDetailInfo) {
    schemas = JSON.parse(AppStore.appSettings.orderDetailInfo.replaceAll("'", "\""));
    schemas.forEach((schema: Schema) => {
      transformSchema(schema)
    });
  }
  return schemas;
}
