import React from 'react';
import {Layout, Modal, View} from 'components';
import {__, T} from 'config/i18n';
import {Button, Space} from 'antd';
import {Companies, HardCoded, Users} from 'api';
import {Page, User} from 'api/types';
import {navigate} from 'router';
import api, {setOffGlobalSpinningOnHeader} from 'api/api';
import GeneralProfileApi from '../../api/GeneralProfileApi';
import {showNotification} from '../../helpers/Notifications';
import {buildColumnsByConfiguration} from '../../components/orders/ColumnBuilderUtil';
import {buildFiltersByConfiguration} from '../../components/orders/FilterBuilderUtil';
import FilteredTable from '../../components/FilteredTable';
import {isAdmin, isAdminSupplier} from "../../helpers/permission";

interface Props {
}

interface State {
    isShowResetPasswordModal: boolean;
    resetPasswordUserId?: string;
}

export default class UsersReport extends React.Component<Props, State> {
    state: State = {
        isShowResetPasswordModal: false
    };

    openUserDetail = (userId: number) => navigate('/reports/users/:userId', {userId})

    getFilters = () => {
        return buildFiltersByConfiguration([
            {
                name: 'fullText',
                label: T.misc.free_search,
                type: 'text',
                placeholder: T.misc.username_name_email
            },
            {
                name: 'companyId',
                type: 'autocomplete',
                label: T.fields.company,
                placeholder: T.misc.select,
                urlSearch: api.getBaseURL() + Companies.getEndpoint() + '/select?' + Companies.getAutocompleteQueryParam() + '=-X-&withOnBoardingCreatorCompanyId=true',
                header: setOffGlobalSpinningOnHeader(),
                descriptionKey: 'label'
            },
            {
                name: 'category',
                type: 'select',
                label: T.fields.category,
                placeholder: T.misc.select,
                options: HardCoded.getCategoryOptions()
            },
            {
                name: 'creationDate',
                type: 'daterange',
                label: T.fields.creation_date,
                dateRangePlaceholders: [__(T.misc.start), __(T.misc.end)]
            },
            {
                name: 'hasLastLoginDate',
                type: 'select',
                label: T.fields.last_login_date,
                options: [
                    { value: 'true', label: __(T.misc.yes) },
                    { value: 'false', label: __(T.misc.no) }
                ],
                placeholder: T.misc.select
            },
            {
                name: 'lastLoginDate',
                type: 'daterange',
                label: T.fields.last_login_date,
                dateRangePlaceholders: [__(T.misc.start), __(T.misc.end)]
            }
        ]);
    };

    private async launchResetPasswordModal(user: User) {
        this.setState({resetPasswordUserId: user.id, isShowResetPasswordModal: true});

    }

    resetPassword = async () => {
        if (!!this.state.resetPasswordUserId) {
            const userId: string = this.state.resetPasswordUserId;
            this.setState({resetPasswordUserId: undefined, isShowResetPasswordModal: false});
            await GeneralProfileApi.resetPassword(userId).then();
            showNotification(__(T.messages.email_sent));
        }
        this.setState({resetPasswordUserId: undefined, isShowResetPasswordModal: false});
    };

    getColumns = () => {
        let columns: any[] = [
            {
                title: T.fields.firstname,
                dataIndex: 'firstName'
            },
            {
                title: T.fields.lastname,
                dataIndex: 'lastName'
            },
            {
                title: T.fields.company,
                dataIndex: 'company.data.businessName',
                sorter: true,
                sortDirections: ["ascend", "descend", "ascend"]
            },
            {
                title: T.fields.categories,
                dataIndex: 'company.categories',
                renderType: 'categories'
            },
            {
                title: T.fields.phone,
                dataIndex: 'phone'
            },
            {
                title: T.fields.email,
                dataIndex: 'email'
            },
            {
                title: T.fields.last_login_date,
                dataIndex: 'lastLoginDate',
                renderType: 'dateTime',
                sorter: true,
                sortDirections: ["ascend", "descend", "ascend"]
            },
            {
                title: T.fields.creation_date,
                dataIndex: 'creationDate',
                renderType: 'dateTime',
                sorter: true,
                sortDirections: ["ascend", "descend", "ascend"]
            }
        ];

        columns = buildColumnsByConfiguration(columns);
        columns.push({
            title: __(T.fields.actions),
            key: 'action',
            render: (text, record) => (
              <Space size="middle">
                  <Button type="link" onClick={() => this.openUserDetail(record.id)}>
                      {__(T.buttons.view)}
                  </Button>
                  <Button type="link" onClick={() => this.launchResetPasswordModal(record)}>
                      {__(T.buttons.reset_password)}
                  </Button>
              </Space>
            )
        });
        return columns;
    };

    onSearch = async (params: any, currentPage: number, sortField: string, sortDirection: 'asc' | 'desc', size: number, activeTabKey?: string): Promise<Page<User>> => {
        return await Users.search({
            enabled: activeTabKey === 'active',
            ...params,
            page: currentPage,
            size: size,
            sort: sortField + ',' + sortDirection,
            tenant: api.headers.tenant
        }, setOffGlobalSpinningOnHeader());
    };

    render() {
        return (
          <Layout>
              {(isAdmin() || isAdminSupplier()) && <View
                style={{flex: 1, display: 'flex', justifyContent: 'flex-end', marginBottom: 12, marginRight: 20}}>
                  <Button type="primary" onClick={() => navigate('/reports/users/new')}
                          style={{minWidth: 200}}>
                      {__(T.buttons.new_user)}
                  </Button>
              </View>
              }
              <FilteredTable
                onSearchElements={this.onSearch}
                getColumnsConfig={this.getColumns}
                getFiltersConfig={this.getFilters}
                sorterField={'creationDate'}
                sorterOrder={'descend'}
                tab={{
                    tabKeys: ['active', 'disabled']
                }}
                storeKey={"userReport"}
              />
              <Modal
                visible={this.state.isShowResetPasswordModal}
                title={__(T.confirms.reset_password)}
                message={__(T.messages.confirm_or_go_back)}
                closeButtonTitle={__(T.buttons.back)}
                confirmButtonTitle={__(T.buttons.invite)}
                onCloseClick={() => {
                    this.setState({isShowResetPasswordModal: false});
                }}
                onConfirmClick={this.resetPassword}
              />
          </Layout>
        )
    }
}
