/**
 * The class is used for typescript version 4.x.x:
 * TypeScript 4.0 now lets you specify the type of catch clause variables as unknown instead.
 * unknown is safer than any because it reminds us that we need to perform some sorts of type-checks before operating on our values.
 * For more details:
 * https://stackoverflow.com/questions/64452484/how-can-i-safely-access-caught-error-properties-in-typescript
 */
export class ErrorPrint {

  public printMessage(err: unknown) {
    if (err instanceof Error) {
      console.log(err.message)
    } else {
      console.log(err)
    }
  }
  public returnMessage(err: unknown) {
    if (err instanceof Error) {
      return err.message
    } else {
      return err
    }
  }
}


const errorPrint = new ErrorPrint()
export { errorPrint as default }
