import React, {RefObject} from 'react';
import {AppForm, Layout, View} from 'components';
import {Button, Col, Row} from 'antd';
import {__, T} from 'config/i18n';
import {navigate} from '../../router';
import EntityPageHeader, {EntityPageHeaderConfig} from '../../components/orders/EntityPageHeader';
import Orders from "../../api/Orders";
import OrderHandmades from "../../api/OrderHandmades";
import Companies from "../../api/Companies";
import OrderHandmadeApi from "../../api/OrderHandmadeApi";
import {DetailedOrder, OrderHandmadeConfig, TraceType} from '../../api/types';
import AppStore from '../../AppStore';
import {isAdmin, isSupplier} from '../../helpers/permission';
import {resolveReportOrdersBasePath} from "../../routes";

interface OrderHandmade {
    code?: string,
    attributes: any,
    productMatchCode?: string
    customerCompany?: string
    // companyId?: string
    company?: string
}

interface Props {
    match: { params: { orderId?: string, orderHandmadeConfigCode?: string, type?: string } };
}

interface State {
    order?: DetailedOrder;
    data: OrderHandmade;
    attributesFormSubmitButtonRef: RefObject<HTMLButtonElement>;
    dataFormSubmitButtonRef: RefObject<HTMLButtonElement>;
    config?: OrderHandmadeConfig
    firstTraceType?: TraceType
    firstTraceTypeData: any
    action: 'STANDARD' | 'FULFILL' | 'DRAFT',
    companyId?: string
    company?:string
}

export default class OrderHandmadeEdit extends React.Component<Props, State> {

    private dataFormComplete: boolean = false;
    private attributeFormComplete: boolean = false;

    state: State = {
        attributesFormSubmitButtonRef: React.createRef<HTMLButtonElement>(),
        dataFormSubmitButtonRef: React.createRef<HTMLButtonElement>(),
        data: {attributes: {}},
        action: 'STANDARD',
        firstTraceTypeData: {}
    };

    componentDidMount = async () => {
        if (this.props.match.params.orderId) {
            // caricare l'ordine
            let order = await Orders.get(this.props.match.params.orderId!)
            this.setState({
                data: {
                    attributes: order.attributes,
                },
                order: order,
                config: order.orderHandmadeConfig
            });
        } else {
            let config = await OrderHandmades.getByCode(this.props.match.params.orderHandmadeConfigCode!)

            if (this.showFirstTraceType(config)) {
                let firstTraceType = await Orders.retrieveTraceType({
                    orderType: config.orderType,
                    productMatchCode: config.productMatchCode
                })

                this.setState({
                    firstTraceType: firstTraceType,
                    data: {
                        attributes: {},
                        company: AppStore.loggedUser?.company?.data.businessName
                    },
                    config: config,
                    companyId: AppStore.loggedUser?.company?.id
                });
            } else {
                this.setState({
                    data: {
                        attributes: {},
                        company: AppStore.loggedUser?.company?.data.businessName
                    },
                    config: config,
                    companyId: AppStore.loggedUser?.company?.id
                });
            }
        }
    }
    // componentDidUpdate = async (prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any) => {
    //
    // };

    getPageHeaderExtras = (): JSX.Element[] => {
        let extras: JSX.Element[] = [];
        if (this.props.match.params.orderId) {
            extras.push(
                <Button type="primary" style={{width: '200px'}} onClick={() => this.save()}>
                    {__(T.buttons.save)}
                </Button>);
        } else {
            if (this.showFirstTraceType(this.state.config)) {
                extras.push(
                    <>
                        <Button type="default" style={{width: '200px'}} onClick={() => this.saveAsDraft()}>
                            {__(T.buttons.import_and_save_draft)}
                        </Button>
                        <Button type="primary" style={{width: '200px'}} onClick={() => this.createAndImport()}>
                            {__(T.buttons.import_and_complete)}
                        </Button>
                    </>
                );
            } else {
                extras.push(
                    <Button type="primary" style={{width: '200px'}} onClick={() => this.save()}>
                        {__(T.buttons.import)}
                    </Button>);
            }
        }

        return extras;
    };

    save = () => {
        this.attributeFormComplete = false;
        this.dataFormComplete = true;

        this.setState({
                action: "STANDARD"
            }, () => this.state.attributesFormSubmitButtonRef.current?.click()
        )
    };

    saveAsDraft = () => {
        this.attributeFormComplete = false;
        this.dataFormComplete = true;

        this.setState({
                action: "DRAFT"
            }, () => this.state.attributesFormSubmitButtonRef.current?.click()
        )
    };

    createAndImport = () => {
        this.attributeFormComplete = false;
        this.dataFormComplete = false;

        this.setState({
                action: "FULFILL"
            }, () => {
                this.state.attributesFormSubmitButtonRef.current?.click();
                this.state.dataFormSubmitButtonRef.current?.click();
            }
        )
    };

    showFirstTraceType = (config): boolean => {
        return config.supplierFulfillEnable && isSupplier();
    }

    getPageHeaderConfig = (): EntityPageHeaderConfig | string => {
        return this.props.match.params.orderId ? 'orderPageHeader' :
            {
                header: [{
                    label: __(T.misc.insert_new_element),
                    type: 'LABEL'
                }],
                footer: [{
                    label: __(T.fields.type),
                    type: 'LABEL_VALUE',
                    path: 'config.label'
                }]
            };
    };

    handleAttributesChange = async (values: any) => {
        let companyId = undefined;
        if (!!values.company && (this.state.company !== values.company)) {
            let companies = await Companies.search({
                  businessName: values.company,
                  size: 1,
                  page: 0
              }
            );
            if(companies.content.length > 0) {
                companyId = companies.content[0].id
            }
        }
        this.setState({
            data: values,
            companyId: companyId,
            company: values.company
        });
    }

    handleDataChange = (values: any) => {
        this.setState({
            firstTraceTypeData: values
        });
    }

    handleAttributesSuccess = () => {
        this.attributeFormComplete = true;
        this.performSave();
    }

    handleDataSuccess = () => {
        this.dataFormComplete = true;
        this.performSave();
    }

    performSave = () => {
        if (this.attributeFormComplete && this.dataFormComplete) {
            this.handleSave().then(() => {
            });
        }
    }

    handleSave = async () => {
        const values = this.state.data
        if (this.props.match.params.orderId) {

            await OrderHandmadeApi.saveOrder(this.props.match.params.orderId!, {...values.attributes, ...this.state.config!.extraAttributes});

        } else {
            const companies = await Companies.search({
                size: 1,
                page: 0,
                businessName: values.company,
                withOnBoardingCreatorCompanyId: true
            });

            let companyId = companies.content[0].id

            let rawMaterialCompanyId: any = undefined;
            if (this.state.config!.showRawMaterialSupplier) {
                const rawMaterialCompanies = await Companies.search({
                    size: 1,
                    page: 0,
                    businessName: values.customerCompany,
                    withOnBoardingCreatorCompanyId: true
                });
                rawMaterialCompanyId = rawMaterialCompanies.content[0].id
            }

            let traceTypeData = {
                ...this.state.firstTraceTypeData,
                orderAttributes: undefined
            }

            const order = await OrderHandmadeApi.newOrder({
                attributes: {...values.attributes, ...this.state.config?.extraAttributes},
                data: traceTypeData,
                orderType: this.state.config!.orderType,
                companyId: companyId,
                handmadeConfigId: this.state.config!.id,
                orderCode: values.code!,
                productMatchCode: this.state.config!.productMatchCode ? this.state.config!.productMatchCode : values.productMatchCode!,
                customerCompanyId: rawMaterialCompanyId,
                action: this.state.action
            });

            if (!isAdmin()) {
                navigate(resolveReportOrdersBasePath(this.props.match.params.type) + '/detail/:orderId', {orderId: order.id});
                return;
            }
        }
        navigate(resolveReportOrdersBasePath(this.props.match.params.type))
    };

    getAttributesSchema = () => {
        let value = this.state.config!.schema ? this.getSchema(this.state.config!.schema) : {
            type: 'object',
            required: [],
            properties: {}
        };


        // for (let key of Object.keys(value.properties)) {
        //     let prop = value.properties[key];
        //     prop.name = prop.name ? 'attributes.' + prop.name : undefined;
        // }

        let tmpProperty: any = {};
        let tmpRequired: string[] = [];


        if (this.props.match.params.orderHandmadeConfigCode) {
            tmpProperty.code = {
                type: 'string',
                span: 6,
                name: 'code',
                title: __(T.fields.enquiryId)
            }
            tmpRequired.push('code');

            if (!this.state.config?.orderType) {
                tmpProperty.orderType = {
                    type: 'string',
                    span: 6,
                    name: 'orderType',
                    title: __(T.fields.orderType),
                    enum: ['SINGLE_MATERIAL']
                }
                tmpRequired.push('orderType');
            }

            tmpProperty.company = {
                type: 'string',
                name: 'company',
                enum: [],
                extra: {
                    companyCategoriesEnum: this.state.config?.categories ?? []
                },
                title: __(T.fields.supplier),
                span: 6
            }

            if (!isAdmin()) {
                tmpProperty.company.readOnly = true;
            }
            tmpRequired.push('company');

            if (!this.state.config?.productMatchCode) {
                tmpProperty.productMatchCode = {
                    type: 'string',
                    name: 'productMatchCode',
                    enum: this.state.config?.productMatchCodes,
                    title: __(T.fields.productMatchCode),
                    span: 6
                }
                tmpRequired.push('productMatchCode');
            }
        }

        if (this.state.config?.showRawMaterialSupplier === true) {


            tmpProperty.customerCompany = {
                type: 'string',
                span: 6,
                name: 'customerCompany',
                enum: [],
                extra: {
                    companyCategoriesEnum: ['RAW_MATERIAL_SOURCE']
                },
                title: 'Cliente',
            }
            tmpRequired.push('customerCompany');
        }

        if (Object.keys(value.properties).length > 0) {
            tmpProperty.attributes = {
                type: 'object',
                title: 'Attributi',
                span: 12,
                properties: value.properties,
                required: value.required
            };
        }

        value.properties = tmpProperty;
        value.required = tmpRequired;

        return value;
    };

    getSchema = (schema: string) => {
        const schemaTmp = JSON.parse(schema);
        if (schemaTmp) {
            schemaTmp.title = undefined;
            schemaTmp.$id = undefined;
            schemaTmp.$schema = undefined;
        }
        return schemaTmp;
    };

    render = () => {
        if (!this.state.config) {
            return (<Layout> </Layout>)
        }

        return (
            <Layout>
                <View style={{margin: 0, marginBottom: 1}}>
                    <EntityPageHeader
                        data={{...this.state.order, ...{'config': {...this.state.config}}}}
                        extras={this.getPageHeaderExtras()}
                        onBack={() => navigate(resolveReportOrdersBasePath(this.props.match.params.type) )}
                        config={this.getPageHeaderConfig()}/>
                </View>
                <View className="page-table" style={{margin: 10, marginTop: 0, borderRadius: '0px'}}>
                    <Row>
                        <Col span={16}>
                            <AppForm
                                schema={this.getAttributesSchema()}
                                data={{
                                    ...this.state.data
                                }}
                                onChange={this.handleAttributesChange}
                                onSuccess={() => this.showFirstTraceType(this.state.config) ? this.handleAttributesSuccess() : this.handleSave()}
                                readonly={false}
                                noValidate={false}
                                formSubmitButtonRef={this.state.attributesFormSubmitButtonRef}
                            />
                        </Col>
                    </Row>
                    {this.state.firstTraceType && this.showFirstTraceType(this.state.config) && (
                        <Row>
                            <Col span={16}>
                                <label>{__(T.fields.data)}</label>
                                <AppForm
                                    schema={this.getSchema(this.state.firstTraceType!.schema)}
                                    data={{...this.state.firstTraceTypeData, orderAttributes: {...this.state.data.attributes}}}
                                    onChange={this.handleDataChange}
                                    onSuccess={this.handleDataSuccess}
                                    readonly={false}
                                    noValidate={false}
                                    formSubmitButtonRef={this.state.dataFormSubmitButtonRef}
                                    formContext={{
                                        creationCompany: this.state.companyId,
                                        data: this.state.firstTraceTypeData,
                                        code: this.state.data.code,
                                        attributes: this.state.data.attributes
                                    }}
                                />
                            </Col>
                        </Row>
                    )}
                </View>

            </Layout>
        );
    };

}
