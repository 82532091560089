import React from 'react';
import {LibraryItem, Page} from 'api/types';
import {Checkbox} from 'antd';
import {__, T} from 'config/i18n';
import {ColumnsType} from 'antd/es/table';
import {Schema} from '../AdvancedForm';
import {CheckboxChangeEvent} from 'antd/lib/checkbox';
import AppStore from '../../AppStore';
import LibraryItems from '../../api/LibraryItems';
import {buildColumnsByConfiguration} from "./ColumnBuilderUtil";
import {buildFiltersByConfiguration} from "./FilterBuilderUtil";
import ModalTableSelectRow from "../ModalTableSelectRow";


interface Props {
    isVisible: boolean
    onCancel: () => void,
    onSuccess: (data: LibraryItem, override: boolean) => void,
    traceTypeId?: string,
    traceTypeCode?: string,
    title: string
}

interface State {
    overrideExistingDataInput: boolean
}

export default class ImportLibraryDialog extends React.Component<Props, State> {

    state: State = {
        overrideExistingDataInput: false
    };

    async componentDidMount() {
    }

    onSuccess = async (selected: LibraryItem) => {
        let libraryItem = await LibraryItems.get(selected.id);
        this.props.onSuccess(libraryItem, this.state.overrideExistingDataInput);
    };

    getColumns = (): ColumnsType<LibraryItem> => {
        let columns: ColumnsType<LibraryItem> = buildColumnsByConfiguration(AppStore.appSettings['libraryItemColumns.TRACE'])
        return columns;
    };

    getFilters = (): Schema[] => {
        return buildFiltersByConfiguration(AppStore.appSettings['libraryItemFilters.TRACE']);
    }

    searchElements = async (params: any, currentPage: number, sortField: string, sortDirection: 'asc' | 'desc', size: number): Promise<Page<LibraryItem>> => {
        const {traceTypeId, traceTypeCode} = this.props
        params['creationCompany'] = AppStore.loggedUser!.company ? AppStore.loggedUser!.company!.id : undefined;
        params['libraryConfigType'] = 'TRACE';

        if (traceTypeCode) {
            params['traceTypeCode'] = traceTypeCode
        } else {
            params['traceTypeId'] = traceTypeId
        }

        return await LibraryItems.search({
            ...params,
            page: currentPage,
            size: size,
            sort: sortField + ',' + sortDirection
        });
    }

    render() {
        const onChange = (e: CheckboxChangeEvent) => {
            // console.log(`checked = ${e.target.checked}`);
            this.setState({overrideExistingDataInput: e.target.checked});
        };
        return (
            <ModalTableSelectRow
                isVisible={this.props.isVisible}
                onCancel={this.props.onCancel}
                onSuccess={this.onSuccess}
                onSearchElements={this.searchElements}
                getColumnsConfig={this.getColumns}
                getFiltersConfig={this.getFilters}
                title={this.props.title}
            >
                <Checkbox onChange={onChange}>
                    {__(T.buttons.overrideExistingDataInput)}
                    <br></br>
                    <p style={{color: '#afaaaa'}}>{__(T.buttons.override_existing_data_input_sub_title)}</p>
                </Checkbox>
            </ModalTableSelectRow>
        );
    }
}
