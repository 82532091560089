import React, { Component } from 'react';
import Orders from '../../api/Orders';
import { Spin } from 'antd';
import { setOffGlobalSpinningOnHeader } from '../../api/api';

interface Props {
  params?: {};
}

interface State {
  loading: boolean;
  data: any | undefined;
}


export default class Contactors extends Component<Props, State> {
  state: State = {
    loading: false,
    data: undefined
  };

  componentDidMount() {
    this.fetchData();
  }
  componentDidUpdate(prevProps: Props) {
    if (prevProps.params !== this.props.params) {
      this.fetchData();
    }
  }

  fetchData = () => {
    this.setState({ loading: true });
    Orders.count( {...this.props.params},  setOffGlobalSpinningOnHeader())
      .then((data) => {
        this.setState({ data: !!data ? data : 0, loading: false });
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
        this.setState({ loading: false });
      });
  };

  render() {
    const { loading, data } = this.state;

    return (
      <span>
        {loading ? (
        <Spin size="default" />
          ) : data
        }
      </span>
    );
  }
}
