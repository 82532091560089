import React, {createRef, RefObject} from 'react'
import {Layout, View} from '../../components'
import {Button, Modal} from 'antd'
import {__, T} from '../../config/i18n'
import {Company, DetailedOrder, SubOrder, SubOrderAssignment} from '../../api/types'
import Orders from '../../api/Orders'
import EntityPageHeader from '../../components/orders/EntityPageHeader'
import OrderAssignmentForm from '../../components/orders/OrderAssignmentForm'
import {FormInstance} from 'antd/es/form'
import {navigate} from '../../router'
import {showNotification} from '../../helpers/Notifications'
import {Companies} from '../../api'
import moment from 'moment'
import CreateOnboardingModal from '../onboarding/CreateOnboardingModal'
import {resolveReportOrdersBasePath} from "../../routes";
import typesSettingsTools from '../../tools/TypesSettingsTools'

interface Props {
  match: { params: { orderId: string, type?: string } }
}

export interface  AssignmentForm extends SubOrderAssignment{
  formRef : RefObject<FormInstance>
  buttonFormRef: RefObject<HTMLButtonElement>
  buttonFormRefValid: boolean
}


interface State {
  order?: DetailedOrder
  assignments: { [subOrderId: string]: AssignmentForm[] }
  companyOnboardingSubOrderId?: string
  companyOnboardingModalVisible: boolean
  allAdvanceForm: boolean
  showCancelButton: boolean
}

export default class OrderAssign extends React.Component<Props, State> {
  orderAssignmentFormRef = createRef<OrderAssignmentForm>();


  state: State = {
    assignments: {},
    companyOnboardingModalVisible: false,
    allAdvanceForm: false,
    showCancelButton: false
  }

  componentDidMount = () => {
     this.initPage()
  }


  initPage = async () => {
    const assignments  :   { [subOrderId: string]: AssignmentForm[] } = {};

    const order = await Orders.get(this.props.match.params.orderId)
    const companies = await this.getCompanies(order?.orders ?? [])
    order?.orders.forEach(subOrder => {
      assignments[subOrder.id] = [{
        originalOrderId: subOrder.originalOrderId,
        orderId: subOrder.id,
        company: subOrder.ownerCompany ? companies[subOrder.ownerCompany] : undefined,
        formRef: React.createRef<FormInstance>(),
        buttonFormRef: React.createRef<HTMLButtonElement>(),
        buttonFormRefValid: false,
        orderCode: subOrder.code,
        orderDate: subOrder.orderDate ? moment(subOrder.orderDate).format('YYYY-MM-DD')
          : moment().format('YYYY-MM-DD'),
        // Attributes will be used at the end on AppForm
        attributes: subOrder.attributes,
        cancelOrder: subOrder.attributes?.cancelOrder ? subOrder.attributes?.cancelOrder : false
      }];
    });
    const setting = typesSettingsTools.getSettingByTraceType(order.traceType.code,'showCancelButton')
    this.setState({showCancelButton: setting})
    this.setState({ order, assignments })
  }

  getCompanies = async (subOrders: SubOrder[]): Promise<{ [companyId: string]: Company }> => {
    const companyIds = this.getCompanyIds(subOrders)
    return Companies.search({
      page: 0,
      size: companyIds.length,
      ids: companyIds,
    })
      .then(companiesResponse => companiesResponse?.content ?? [])
      .then(companies =>
        companies.reduce((_companies, company) => {
          _companies[company.id] = company
          return _companies
        }, {})
      )
  }

  getCompanyIds = (subOrders: SubOrder[]) =>
    Array.from(new Set(subOrders.map(subOrder => subOrder.ownerCompany).filter(ownerCompany => ownerCompany)))

  getPageHeaderExtras = () => [
    <Button
      type="ghost"
      style={{ width: '200px', backgroundColor: __(T.colors.gray_button) }}
      onClick={() => this.handleAssignmentSubmit(true)}
    >
      {__(T.buttons.save_draft)}
    </Button>,
    <Button type="primary" style={{ width: '200px' }} onClick={() => this.handleAssignmentSubmit(false)}>
      {__(T.buttons.assign_now)}
    </Button>,
  ]

  handleAssignmentChange = async (assignment: AssignmentForm, changedValues, values) => {
    const changedValueKeys = Object.keys(changedValues)
    if (changedValueKeys.length > 0) {
      const { assignments } = this.state
      if (changedValueKeys.includes('company')) {
        try {
          const companyId = changedValues.company ? changedValues.company.value : undefined
          assignment.company = companyId ? await Companies.get(companyId) : undefined
        } catch (_e) {}
      }
      this.setState({ assignments })
    }
  }

  handleAssignmentError = () => {
    showNotification(__(T.titles.order_assignment_error), __(T.messages.order_assignment_error), 'error', 3)
  }

  handleAssignmentSubmit = async (draft: boolean) => {
    this.state.allAdvanceForm = false;
    const orderId = this.state.order!.id
    let assignmentFormValidations = !draft
      ? this.toArray(this.state.assignments)
          .map(([_, assignmentList]) =>
            assignmentList.map( assignment => assignment.formRef.current))
            .reduce((l1,l2)=> [...l1,...l2])
          .map(formInstance => formInstance?.validateFields())
          .filter(formInstanceValidation => formInstanceValidation !== undefined)
      : [];

    if (!draft) {
      Object.keys(this.state.assignments).forEach(key => {
        const assignments = this.state.assignments[key];
        assignments.forEach(assignment => {
          if(assignment.cancelOrder){
            assignment.buttonFormRefValid = true;
          }else{
            assignment.buttonFormRefValid = false;
            assignment.buttonFormRef.current?.click();
          }
        });
      });
    }

    Promise.all(assignmentFormValidations).then(async () => {
      if (draft) {
        const assignments = this.toArray(this.state.assignments)
          .map(([subOrderId, assignmentList]) => assignmentList
            .map(assignment => (
                {
                  orderId: assignment.orderId,
                  originalOrderId: assignment.originalOrderId,
                  company: assignment.company,
                  orderCode: assignment.orderCode,
                  orderDate: assignment.orderDate ? assignment.orderDate + 'T00:00:00.000Z' : undefined, // Used on AppForm component
                  deleted: assignment.deleted,
                  attributes: assignment.attributes,
                  cancelOrder: assignment.cancelOrder
                } as SubOrderAssignment
              )
            ))
          .reduce((l1, l2) => [...l1,...l2] );
        Orders.assign(orderId, { draft, assignments }).then(
          async () => await this.handleAssignmentSuccess(orderId, draft),
          () => this.handleAssignmentError()
        )
      } else {
        this.state.allAdvanceForm = true;
        this.completeAssignment();
      }
    });

  }

  onJsonSchemaSubmit = (assignmentForm: AssignmentForm) => {
    assignmentForm.buttonFormRefValid = true;
    this.completeAssignment();
  }

  completeAssignment = () => {
    const invalidKeys = Object.keys(this.state.assignments).filter(key => {
      const notValidAssignments = this.state.assignments[key].filter(assignment => !assignment.buttonFormRefValid)
      return notValidAssignments.length > 0;
    });

    if (invalidKeys.length === 0 &&  this.state.allAdvanceForm) {
      const assignments = this.toArray(this.state.assignments)
        .map(([subOrderId, assignmentList]) => assignmentList
          .map(assignment => (
              {
                orderId: assignment.orderId,
                originalOrderId: assignment.originalOrderId,
                company: assignment.company,
                orderCode: assignment.orderCode, // Used on AppForm component
                orderDate: assignment.orderDate ? assignment.orderDate + 'T00:00:00.000Z' : undefined, // Used on AppForm component
                deleted: assignment.deleted,
                attributes: assignment.attributes,
                cancelOrder: assignment.cancelOrder
              } as SubOrderAssignment
            )
          ))
        .reduce((l1, l2) => [...l1,...l2] );

      Orders.assign( this.state.order!.id, {draft: false, assignments }).then(
        async () => await this.handleAssignmentSuccess( this.state.order!.id, false),
        () => this.handleAssignmentError()
      )
    }
  }

  handleAssignmentSuccess = async (orderId: string, draft: boolean) => {
    showNotification(
      __(T.titles.order_assignment_success),
      draft ? __(T.messages.order_assignment_draft) : __(T.messages.order_assignment_success),
      'success',
      3
    )
    if (!draft) {
      this.navigateBackToDetail(orderId)
    }else{
      await this.initPage()
    }
  }

  handleCompanyOnboardingModalCancel = async () => {
    this.setState({ companyOnboardingSubOrderId: undefined, companyOnboardingModalVisible: false })
  }

  handleCompanyOnboardingModalConfirm = async (companyId?: string) => {
    const { assignments, companyOnboardingSubOrderId } = this.state
    if (companyOnboardingSubOrderId) {
      const company = companyId ? await Companies.get(companyId) : undefined
      assignments[companyOnboardingSubOrderId][0].company = company
    }
    this.setState({
      assignments,
      companyOnboardingSubOrderId: undefined,
      companyOnboardingModalVisible: false,
    });
  }

  cloneAssignment = (subOrderAssignmentToClone: SubOrderAssignment) => {
    const {assignments} = this.state

    var clonedSubOrderAssignment : AssignmentForm = {
      originalOrderId : subOrderAssignmentToClone.orderId ?? subOrderAssignmentToClone.originalOrderId,
      orderDate : subOrderAssignmentToClone.orderDate,
      company : subOrderAssignmentToClone.company,
      formRef : React.createRef<FormInstance>(),
      buttonFormRef: React.createRef<HTMLButtonElement>(),
      orderCode : "",
      buttonFormRefValid: false
    };


    assignments[clonedSubOrderAssignment.originalOrderId!].push(clonedSubOrderAssignment)

    this.setState({
      assignments : assignments
    })
  }

  removeOrder = (subOrderAssignmentToRemove: SubOrderAssignment) => {


    Modal.confirm({
      title: __(T.confirms.delete_order),
      okText: __(T.misc.yes),
      cancelText: __(T.misc.no),
      okType: 'primary',
      onOk: () => {
        const {assignments} = this.state
        if(subOrderAssignmentToRemove.orderId === undefined){
          assignments[subOrderAssignmentToRemove.originalOrderId!].forEach( (item, index) => {
            if(item === subOrderAssignmentToRemove) assignments[subOrderAssignmentToRemove.originalOrderId!].splice(index,1);
          });
        }else{
          subOrderAssignmentToRemove.deleted = true
        }

        this.setState({
          assignments : assignments
        })
      }
    });
  }

  cancelSubOrder = (subOrderAssignmentToBlock: SubOrderAssignment) => {

    Modal.confirm({
      title: __(T.confirms.cancel_order),
      okText: __(T.misc.yes),
      cancelText: __(T.misc.no),
      okType: 'primary',
      onOk: () => {
        const {assignments} = this.state
        assignments[subOrderAssignmentToBlock.orderId == null ? subOrderAssignmentToBlock.originalOrderId : subOrderAssignmentToBlock.orderId].forEach( (item, index) => {
          item.cancelOrder = true
        });
        this.setState({
          assignments : assignments
        })
      }
    });
  }

  unlockSubOrder = (subOrderAssignmentToBlock: SubOrderAssignment) => {

    Modal.confirm({
      title: __(T.confirms.unlock_order),
      okText: __(T.misc.yes),
      cancelText: __(T.misc.no),
      okType: 'primary',
      onOk: () => {
        const {assignments} = this.state
        assignments[subOrderAssignmentToBlock.orderId == null ? subOrderAssignmentToBlock.originalOrderId : subOrderAssignmentToBlock.orderId].forEach( (item, index) => {
          item.cancelOrder = false
        });
        this.setState({
          assignments : assignments
        })
      }
    });
  }


  handleInviteCompanyClick = async (subOrder: SubOrder) => {
    this.setState({ companyOnboardingSubOrderId: subOrder.id, companyOnboardingModalVisible: true })
  }

  navigateBackToDetail = (orderId: string) => navigate(resolveReportOrdersBasePath(this.props.match.params.type) + '/detail/:orderId', { orderId })

render = () => {
    const { order, assignments,showCancelButton } = this.state
    return (
      <Layout>
        {order && (
          <View style={{ margin: 0, marginBottom: 10 }}>
            <EntityPageHeader
              data={order}
              extras={this.getPageHeaderExtras()}
              onBack={_ => this.navigateBackToDetail(order.id)}
              config={'orderPageHeader'}
              extraConfigType={order.traceType.code}
            />
          </View>
        )}
        {order && (
          <View className="page-table" style={{ margin: 10, marginTop: 0, borderRadius: '0px' }}>
            <OrderAssignmentForm
              ref={this.orderAssignmentFormRef}
              order={order}
              assignments={assignments}
              onAssignmentChange={this.handleAssignmentChange}
              onInviteCompanyClick={this.handleInviteCompanyClick}
              onAssignmentClone={this.cloneAssignment}
              onAssignmentRemove={this.removeOrder}
              onAssignmentCancelSubOrders={this.cancelSubOrder}
              onAssignmentUnlockSubOrders={this.unlockSubOrder}
              onJsonSchemaFormSuccess={this.onJsonSchemaSubmit}
              showCancelOrderButton={showCancelButton}
            />
            <CreateOnboardingModal
              visible={this.state.companyOnboardingModalVisible}
              onConfirm={this.handleCompanyOnboardingModalConfirm}
              onCancel={this.handleCompanyOnboardingModalCancel}
            />
          </View>
        )}
      </Layout>
    )
  }

  toArray<T>(map: { [key: string]: T }): Array<[string, T]> {
    const mapObject = new Map<string, T>()
    for (const mapKey in map) {
      mapObject.set(mapKey, map[mapKey])
    }
    return Array.from(mapObject)
  }




}
