import React from 'react'
import {PlusCircleOutlined} from '@ant-design/icons'
import {Layout, View} from 'components'
import {__, T} from 'config/i18n'
import {Button, Space, Spin, Tabs} from 'antd'
import CompanyDataTab from './CompanyDataTab'
import ScopeCertificatesTab from './ScopeCertificatesTab'
import ScopeCertificateUploadModal from './ScopeCertificateUploadModal'
import {Company, CompanyData} from '../../api/types'
import {Companies, OnBoardings} from '../../api'
import AppStore from '../../AppStore'
import {getOperationsForRole, getUserRole, isAdmin} from '../../helpers/permission';
import {showNotification} from '../../helpers/Notifications'
import {addQueryParams, getQueryParams, navigateBack} from "../../router";
import errorPrint from "../../tools/ErrorPrint";
import {ParsedQs} from "qs";

interface Props {
    match: { params: { companyId?: string } }
    location: { pathname: string }
}

interface State {
    company?: Company
    activeKey: string
    loading: boolean
    isScopeCertificateUploadModalVisible: boolean
    isOnboarding: boolean
    activeTabKey: OrderStatusTabKey
}


const companyData = 'COMPANY_DATA'
const scopeCertificates = 'SCOPE_CERTIFICATES'
const showSelectedKey = companyData
const orderStatusTabKeys = [showSelectedKey, scopeCertificates] as const
type OrderStatusTabKey = typeof orderStatusTabKeys[number]

export default class CompanyDetail extends React.Component<Props, State> {
    state: State = {
        loading: true,
        activeKey: showSelectedKey,
        isScopeCertificateUploadModalVisible: false,
        isOnboarding: false,
        activeTabKey: showSelectedKey
    }
    private keyCount: number = 0;

    isANewCompany = (queryParams): boolean => !!queryParams.newCompany

    componentDidMount = async () => {
        this.setState({isOnboarding: this.props.location.pathname.includes('onboarding')})
        let queryParams: ParsedQs = getQueryParams()
        if (!!queryParams.tab && orderStatusTabKeys.includes(getQueryParams().tab)) {
            this.setState({activeTabKey: getQueryParams().tab});
        } else {
            addQueryParams({'tab': showSelectedKey}, false)
        }


        if (this.isANewCompany(queryParams)) {
            const company = {
                data: {} as CompanyData
            } as Company

            this.setState({company, loading: false})
        } else {
            await this.loadCompany();
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props['location']['search'] !== prevProps.location.search) {
            this.onRouteChanged();
        }
    }

    onRouteChanged() {
        this.setState({
            activeTabKey: getQueryParams().tab
        })
    }

    confirmCompany = async () => {
        const {isOnboarding, company} = this.state
        try {
            if (isOnboarding && company?.onBoardings && company?.onBoardings.length > 0) {
                await OnBoardings.approve(company?.onBoardings[0])
                showNotification(__(T.messages.onboarding_approved))
            } else {
                await Companies.enable(company?.id)
                showNotification(__(T.messages.company_confirmed))
            }
            navigateBack()
        } catch (error) {
            errorPrint.printMessage(error)
        }
    }

    getTabBarExtraContent = (activeTabKey: string) => {
        const buttons: JSX.Element[] = []
        if (this.shouldShowUploadCertificateButton(activeTabKey)) {
            buttons.push(
                <Button
                    type="primary"
                    key={this.getKey()} onClick={() => this.setState({isScopeCertificateUploadModalVisible: true})}
                    disabled={!isAdmin()
                        && AppStore.appSettings.supplierDetailReadonly
                        && this.state.company?.id !== AppStore.loggedUser?.company?.id}
                >
                    <PlusCircleOutlined/>
                    {__(T.buttons.add_certificate)}
                </Button>
            )
        }
        if (this.shouldShowConfirmCompanyButton()) {
            buttons.push(
                <Button type="primary" onClick={this.confirmCompany}>
                    {__(T.buttons.confirm)}
                </Button>
            )
        }
        if (this.shouldShowSuspendCompanyButton()) {
            buttons.push(
                <Button type="primary" onClick={this.suspendCompany}>
                    {__(T.buttons.suspend)}
                </Button>
            )
        }
        return <Space direction="horizontal">{buttons}</Space>
    }

    handleScopeCertificateUploadModalCancel = () => {
        this.setState({isScopeCertificateUploadModalVisible: false})
    }

    handleScopeCertificateUploadModalConfirm = async () => {
        const company = await Companies.get(AppStore.loggedUser?.company!.id)
        this.setState({company, isScopeCertificateUploadModalVisible: false})
    }

    loadCompany = async () => {
        const companyId = this.props.match.params.companyId ?? AppStore.loggedUser?.company!.id
        const company = await Companies.get(companyId)
        this.setState({company, loading: false})
    }

    private getKey() {
        return this.keyCount = this.keyCount + 1;
    }

    render() {
        const {company, loading} = this.state
        return (
            <Layout>
                <View style={{padding: 20}}>
                    <View className="page-table">
                        <Spin spinning={loading} delay={100}>
                            {company && (
                                <Tabs
                                    defaultActiveKey={this.state.activeTabKey}
                                    onChange={this.switchTab}
                                    style={{flexDirection: 'column'}}
                                    tabBarExtraContent={this.getTabBarExtraContent(this.state.activeTabKey)}
                                >
                                    <Tabs.TabPane tab={__(T.titles.company_data)} key={companyData}>
                                        <CompanyDataTab company={company}/>
                                    </Tabs.TabPane>
                                    {AppStore.appSettings.isDisabledSupplierTabCertification === false && company.id && (
                                        <Tabs.TabPane tab={__(T.titles.scope_certificates)} key={scopeCertificates}>
                                            <ScopeCertificatesTab certifications={company.certifications}/>
                                            <ScopeCertificateUploadModal
                                                companyId={company.id}
                                                visible={this.state.isScopeCertificateUploadModalVisible}
                                                onConfirm={this.handleScopeCertificateUploadModalConfirm}
                                                onCancel={this.handleScopeCertificateUploadModalCancel}
                                            />
                                        </Tabs.TabPane>
                                    )}
                                </Tabs>
                            )}
                        </Spin>
                    </View>
                </View>
            </Layout>
        )
    }

    shouldShowUploadCertificateButton(activeKey: string): boolean {
        return getUserRole() !== 'admin' && activeKey === 'SCOPE_CERTIFICATES'
    }

    shouldShowConfirmCompanyButton = () => {
        const {company, isOnboarding} = this.state
        return (
            getUserRole() === 'admin' &&
            getOperationsForRole().canConfirmCompany &&
            company &&
            company.id !== AppStore.loggedUser?.company?.id &&
            (isOnboarding ? !company.referent.companyConfirmed : !company.enabled)
        )
    }

    shouldShowSuspendCompanyButton = () => {
        const {company, isOnboarding} = this.state
        return (
            getUserRole() === 'admin' &&
            getOperationsForRole().companySuspend &&
            company &&
            company.id !== AppStore.loggedUser?.company?.id &&
            company.enabled &&
            !isOnboarding
        )
    }

    suspendCompany = async () => {
        try {
            await Companies.disable(this.state.company?.id)
            showNotification(__(T.messages.company_disabled))
            navigateBack()
        } catch (error) {
            errorPrint.printMessage(error)
        }
    }

    switchTab = (activeKey: string) => {
        addQueryParams({'tab': activeKey}, true)
        this.setState({activeKey})
    }
}
