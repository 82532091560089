import React from 'react'
import {OrderMultiAssignItem} from "../../pages/orders/OrderMultiAssign";
import Avatar from "antd/es/avatar";
import Title from 'antd/es/typography/Title'
import {Card, Col, Row} from 'antd'
import {AdvancedForm} from '../index'
import {__, T} from '../../config/i18n'
import AppStore from "../../AppStore";
import {Schema} from "../AdvancedForm";
import {Companies} from "../../api";
import {SubOrderAssignment, SubOrderMultiAssignment} from "../../api/types";
import api from '../../api/api'
import OrderMultiAssignmentTable from "./OrderMultiAssignmentTable";

interface Props {
  title: string
  orderMultiAssignItem: OrderMultiAssignItem
  onInviteCompanyClick: (orderMultiAssignItem: OrderMultiAssignItem) => void
  onDeleteAssignment: (subOrderAssignment: SubOrderMultiAssignment) => void
  onSupplierChange: (orderMultiAssignItem: OrderMultiAssignItem, changedValues, values) => void
  onAssignmentsValuesChange: (subOrderAssignment: SubOrderAssignment, changedValues, values) => void
}

export default class OrderMultiAssignmentBox extends React.Component<Props> {

  getSupplierFormValues = (orderMultiAssignItem: OrderMultiAssignItem) => {
      return {
          // orderId,
          company: {
              id: orderMultiAssignItem.company?.id ?? null,
              label: orderMultiAssignItem.company?.data.businessName ?? null
          },
      }
  }

  getSupplierFormSchema(
    orderMultiAssignItem: OrderMultiAssignItem,
    inviteCompanyClickHandler: (orderMultiAssignItem: OrderMultiAssignItem) => void
  ): Schema[] {
      const loggedUserCompanyId = AppStore.loggedUser?.company?.id ?? undefined
      return [
          {
              name: 'company',
              type: 'autocomplete',
              label: __(T.fields.company_name_vat),
              placeholder: __(T.misc.select),
              descriptionKey: 'label',
              urlSearch: `${api.getBaseURL()}${Companies.getEndpoint()}/select?category=${
                orderMultiAssignItem.category
                }&withOnBoardingCreatorCompanyId=true&onBoardingCreatorCompanyId=${
                loggedUserCompanyId //
                }&${Companies.getAutocompleteQueryParam()}=-X-`,
              withButton: true,
              buttonLabel: __(T.buttons.invite),
              onButtonClick: () => inviteCompanyClickHandler(orderMultiAssignItem),
              col: 24,
              rules: [{ required: true }]
          }
      ]
  }

  render = () => {
    const {title, orderMultiAssignItem, onInviteCompanyClick, onDeleteAssignment, onAssignmentsValuesChange} = this.props

    return <>
      <Row>
        <Col span={12}>
          <Card
            size="small"
            bordered={false}
            style={{
              backgroundColor: '#F5F5F5',
              borderRadius: '10px',
              padding: '10px 20px 10px 0px',
              marginBottom: '60px',
              marginTop: '10px',
            }}
          >
            <Title level={4} style={{fontWeight: 'bold'}}> {title}</Title>

            <div style={{display: "flex", paddingTop: 10, paddingBottom: 10}}>
              <Avatar style={{color: '#fff', backgroundColor: '#727272', marginRight: 10}}>1</Avatar>
              <Title level={3}> {__(T.misc.chooseSupplier)}</Title>
            </div>

            <AdvancedForm
              formRef={orderMultiAssignItem.parentFormRef}
              schema={this.getSupplierFormSchema(orderMultiAssignItem, onInviteCompanyClick)}
              values={this.getSupplierFormValues(orderMultiAssignItem)}
              onValuesChange={(changedValues, values) => this.props.onSupplierChange(orderMultiAssignItem, changedValues, values)}
            />

            <div style={{display: "flex", paddingTop: 10, paddingBottom: 10}}>
              <Avatar style={{color: '#fff', backgroundColor: '#727272', marginRight: 10}}>2</Avatar>
              <Title level={3}> {__(T.misc.insertPurchaseOrderAndDate)}</Title>
            </div>

            <OrderMultiAssignmentTable
              assignments={orderMultiAssignItem.assignments}
              onDeleteAssignment={onDeleteAssignment}
              onAssignmentsValuesChange = {onAssignmentsValuesChange}
            />
          </Card>
        </Col>
      </Row>
    </>
  }
}
