import React, { Component } from 'react';
import { Button, Col, Form, Modal, Row, Spin, Typography } from 'antd';
import { __, T } from 'config/i18n';
import { Schema } from '../../components/AdvancedForm';
import AdvancedForm2 from '../../components/AdvancedForm2';

const { Title } = Typography;

interface OrderSendEmailModalProps {
    defaultValues: OrderSendEmailModalValues;
    onConfirm: (email: string[], note?: string) => void;
    onCancel: () => void;
    isLoading: boolean;
}

export interface OrderSendEmailModalValues {
    email?: string[];
    note?: string;
}

export interface OrderSendEmailModalState {
    data: OrderSendEmailModalValues;
}

export default class OrderSendEmailModal extends Component<OrderSendEmailModalProps, OrderSendEmailModalState> {

    state: OrderSendEmailModalState = { data: this.props.defaultValues };

    formSchema: Schema[] = [
        {
            name: 'email',
            type: 'select',
            label: 'Email',
            col: { xs: 24 },
            mode: 'tags',
            rules: [
                {
                    required: true,
                    message: 'Inserisci almeno un indirizzo email'
                }
            ]
        },
        {
            name: 'note',
            type: 'textarea',
            label: 'Note',
            col: { xs: 24 }
        }
    ];

    onValuesChange = (changedValues: string[], values: OrderSendEmailModalValues): void => {
        this.setState({
            data: values
        });
    };


    emailsAreValid = (): boolean => {
        if (!!this.state.data.email && this.state.data.email.length > 0) {
            const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
            let isValid = true;  // Utilizziamo una variabile di flag

            this.state.data.email.forEach(email => {
                if (!emailRegex.test(email)) {
                    isValid = false;
                }
            });

            return isValid;
        }
        return true;
    };

    confirm = (): void => {
        const data = this.state.data;
        this.props.onConfirm(data.email!, data.note);
        this.setState({ data });
    };

    render() {
        const { onCancel } = this.props;
        return (
            <Modal centered visible={true} closable={false} footer={null} onCancel={onCancel}>
                <Title style={{ textAlign: 'center' }} level={4}>
                    {__(T.titles.insert_email)}
                </Title>
                <Spin spinning={this.props.isLoading}>
                    <AdvancedForm2
                        schema={this.formSchema}
                        values={this.state.data}
                        onValuesChange={this.onValuesChange}
                    />

                    <Row gutter={24} justify={'center'}>
                        <Col span={6} offset={0}>
                            <Form.Item style={{ marginBottom: 0 }}>
                                <Button
                                    type="primary"
                                    htmlType="button"
                                    style={{ width: '100%' }}
                                    onClick={this.confirm}
                                    disabled={!this.emailsAreValid()}
                                >
                                    {__(T.buttons.send)}
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Spin>
            </Modal>
        );
    }
}
